import React, { Fragment } from 'react'
import { Dialog, Transition } from '@headlessui/react'
//import { IOffer } from '@/shared/interfaces/interfaces'
import Typography from "@/components/baseComponents/Typography"
import Button from "@/components/baseComponents/Button/Button"
import ViewDocumentModal from "./ViewDocumentModal";
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Listing from "./viewOfferSubComponents/Listing"
import Buyers from "./viewOfferSubComponents/Buyers"
import Terms from './viewOfferSubComponents/Terms'
import Financing from './viewOfferSubComponents/Financing'
import Legal from './viewOfferSubComponents/Legal'
import { FormatFileSize } from '@/shared/config/constants'
import { XMarkIcon } from '@heroicons/react/24/outline'
import Scrollbars from 'react-custom-scrollbars';
import { getUser } from '@/redux/user/userSlice';
import { useAppSelector } from '@/redux/hooks';
import Preview from '../myListings/common/Preview';
import { formatAddress } from '@/shared/services/utils';

type IProps = {
  open: boolean
  changeViewDrawer: Function
  data?: any
  onUpdateStatus: Function
  onEditOffer: Function
}

const tabs = [
  { name: 'Overview' },
  { name: 'Listing' },
  { name: 'Buyers' },
  { name: 'Financing' },
  { name: 'Terms' },
  { name: 'Legal/Title' }
];

function classNames(...classes: any) {
  return classes.filter(Boolean).join(' ')
}

const ViewOfferDrawer = (props: IProps) => {
  const user = useAppSelector(getUser);
  const [currentTab, setCurrentTab] = React.useState<string>("Overview");
  const [openDocModal, setOpenDocModal] = React.useState<boolean>(false);

  React.useEffect(() => {
    setTimeout(() => {
      const main_body = document.getElementById("view_offer_drawer");
      if (main_body) {
        main_body.scrollTop = 0;
      }
    }, 500);
  }, [props.open]);

  const closeViewDocModal = () => {
    setOpenDocModal(false);
    props.changeViewDrawer(true);
  };

  return (
    <>
      <Transition.Root show={props.open} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-40"
          onClose={() => props.changeViewDrawer(false)}
        >
          <div className="fixed inset-0 overflow-hidden">
            <div className="absolute inset-0 overflow-hidden">
              <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
                <Transition.Child
                  as={Fragment}
                  enter="transform transition ease-in-out duration-500 sm:duration-300"
                  enterFrom="translate-x-full"
                  enterTo="translate-x-0"
                  leave="transform transition ease-in-out duration-500 sm:duration-300"
                  leaveFrom="translate-x-0"
                  leaveTo="translate-x-full"
                >
                  <Dialog.Panel className="pointer-events-auto w-screen max-w-[650px]">
                    <div
                      className="flex h-full flex-col bg-white pt-8 shadow-xl"
                      id="view_offer_drawer"
                    >
                      <div className="px-4 sm:px-6">
                        <div className="flex items-start justify-between">
                          <div className="flex h-3 items-center w-9/12">
                            <Dialog.Title className="text-[25px] font-medium truncate" title={formatAddress(props.data?.listing?.listingAddress) || "View Offer"}>
                              {formatAddress(props.data?.listing?.listingAddress) || "View Offer"}
                            </Dialog.Title>
                          </div>
                          <div className="flex h-3 items-center gap-x-5">
                            <Typography
                              variant="button2"
                              className="cursor-pointer text-[#6DA172] whitespace-nowrap"
                              onClick={() => setOpenDocModal(true)}
                            >
                              View Document
                            </Typography>
                            <button
                              type="button"
                              className="relative rounded-md bg-white text-gray-400 hover:text-[#C84156] focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                              onClick={() => props.changeViewDrawer(false)}
                            >
                              <span className="absolute -inset-2.5" />
                              <span className="sr-only">Close panel</span>
                              <XMarkIcon
                                className="h-6 w-6"
                                aria-hidden="true"
                              />
                            </button>
                          </div>
                        </div>
                      </div>
                      <div className="border-b mt-5"></div>
                      <Scrollbars autoHide>
                        <div className="flex justify-center w-full mt-2">
                          <nav
                            className="-mb-px flex justify-center mx-8 w-full border-b"
                            aria-label="Tabs"
                          >
                            <div className="flex justify-between items-end w-full overflow-y-auto">
                              {tabs.map((tab) => (
                                <div
                                  key={tab.name}
                                  className={classNames(
                                    tab.name === currentTab
                                      ? "border-indigo-500 text-indigo-600"
                                      : "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700",
                                    "whitespace-nowrap border-b-2 py-4 px-1 text-sm font-medium cursor-pointer"
                                  )}
                                  aria-current={
                                    tab.name === currentTab ? "page" : undefined
                                  }
                                  onClick={() => setCurrentTab(tab.name)}
                                >
                                  <Typography variant="h4">{tab.name}</Typography>
                                </div>
                              ))}
                            </div>
                          </nav>
                        </div>
                        <div className={currentTab !== "Listing" ? `px-8 pt-6` : ``}>
                          {(currentTab === "Overview") && (
                              <Listing data={props.data} />
                            )}
                          {(currentTab === "Listing") && (
                            <Preview data={props.data?.listing} isPublished={false} />
                          )}
                          {(currentTab === "Overview" ||
                            currentTab === "Buyers") && (
                              <Buyers data={props.data} />
                            )}
                          {(currentTab === "Overview" ||
                            currentTab === "Terms") && (
                              <Terms data={props.data} />
                            )}
                          {(currentTab === "Overview" ||
                            currentTab === "Financing") && (
                              <Financing data={props.data} />
                            )}
                          {(currentTab === "Overview" ||
                            currentTab === "Legal/Title") && (
                              <Legal data={props.data} />
                            )}
                          {(currentTab === "Overview" ||
                            currentTab === "Docs") && (
                              <>
                                <Typography
                                  variant="h3"
                                  color="primary"
                                >
                                  Documents
                                </Typography>
                                {props.data?.mainDocument && props.data?.mainDocument.name && props.data?.mainDocument.file && (
                                  <div className="flex justify-between">
                                    <div className="w-1/3 mr-2">
                                      <Typography variant="caption" className="text-[13px] truncate">
                                        {'main document'}
                                      </Typography>
                                    </div>
                                    <div className="w-1/3 mr-2">
                                      <Typography
                                        variant="body"
                                        className="break-all text-[13px] text-[#4C42D7] truncate"
                                      >
                                        {props.data?.mainDocument.name || '-'}
                                      </Typography>
                                    </div>
                                    <div className="w-1/3">
                                      <Typography variant="body" className="text-[13px]">
                                        {'-'}
                                      </Typography>
                                    </div>
                                  </div>        
                                )}
                                {props.data?.additionalDocuments && props.data?.additionalDocuments.length > 0 && props.data?.additionalDocuments.map((document: any, index: number) => {
                                  return (
                                    <div key={index}>
                                    {document.file && (
                                      <div className="flex justify-between" key={index}>
                                        <div className="w-1/3 mr-2">
                                          <Typography variant="body" className="text-[13px] truncate">
                                            {document.doc_type || '-'}
                                          </Typography>
                                        </div>
                                        <div className="w-1/3 mr-2">
                                          <Typography
                                            variant="body"
                                            className="break-all text-[13px] text-[#4C42D7] truncate"
                                          >
                                            {document.rename || '-'}
                                          </Typography>
                                        </div>
                                        <div className="w-1/3">
                                          <Typography variant="body" className="text-[13px]">
                                            {document.isFile ? FormatFileSize(document.file.size) : '' + '|' + document.uploadAt && document.uploadAt !== null ? document.uploadAt : ''}
                                          </Typography>
                                        </div>
                                      </div>
                                    )}
                                    </div>
                                  );
                                })}
                              </>
                            )}
                          {props.data?.status !== "Rescinded" && currentTab === "Overview" && (
                            <div className="mt-[50px]">
                              <Typography
                                variant="h3"
                                color="primary"
                              >
                                Offer Response
                              </Typography>
                              {props.data?.owner._id !== user._id ? (
                                <div className="flex gap-[15px] mt-[25px]">
                                  <Button variant="contained" color="success" className="flex-1" onClick={() => props.onUpdateStatus("Accepted")}>
                                    <Typography variant="button2">Accept</Typography>
                                  </Button>
                                  <Button variant="contained" color="warning" className="flex-1" onClick={() => props.onUpdateStatus("Countered")}>
                                    <Typography variant="button2">Counter</Typography>
                                  </Button>
                                  <Button variant="contained" color="danger" className="flex-1" onClick={() => props.onUpdateStatus("Declined")}>
                                    <Typography variant="button2">Decline</Typography>
                                  </Button>
                                </div>
                              ) : (
                                <div className="flex gap-[15px] mt-[25px]">
                                  <Button variant="contained" color="success" className="flex-1" onClick={() => props.onEditOffer(props.data)}>
                                    <Typography variant="button2">Update Offer</Typography>
                                  </Button>
                                  <Button variant="contained" color="danger" className="flex-1" onClick={() => props.onUpdateStatus("Rescinded")}>
                                    <Typography variant="button2">Rescind Offer</Typography>
                                  </Button>
                                </div>
                              )}
                            </div>
                          )}
                          {currentTab === "Overview" && (
                            <>
                              <div className="mt-[50px]">
                                <label className="flex items-center">
                                  <input
                                    type="checkbox"
                                    className="h-6 w-6 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                                    value={"send_flag"}
                                  />
                                  <Typography
                                    className="ml-3 select-none"
                                    variant="body"
                                    color="secondary"
                                  >
                                    Require signature from {props.data?.client?.firstName} {props.data?.client?.lastName}

                                  </Typography>
                                </label>
                                <div className="w-full text-justify my-[15px]">
                                  <Typography
                                    variant="body"
                                    color="secondary"
                                  >
                                    If checked, the generated offer document will
                                    be sent to the Buyer Client for review and
                                    signatures before being submitted to the
                                    Listing Agent. Signed offers are of higher
                                    quality and have a greater chance of being
                                    selected.
                                  </Typography>
                                </div>
                              </div>
                              <div className="flex gap-[15px] my-[50px]">
                                <Button
                                  variant="outlined"
                                  className="flex-1"
                                >
                                  <Typography variant="button2">Send to Client for Review</Typography>
                                </Button>
                                <Button
                                  variant="contained"
                                  className="flex-1"
                                  onClick={() => props.changeViewDrawer(false)}
                                >
                                  <Typography variant="button2">Submit Response to Offer</Typography>
                                </Button>
                              </div>
                            </>
                          )}
                        </div>
                      </Scrollbars>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
      <ViewDocumentModal
        open={openDocModal}
        previewDoc={props.data?.documents[0]?.file}
        pdf_data={props.data?.documents}
        closeModal={() => closeViewDocModal()}
      />
    </>
  );
};

export default ViewOfferDrawer
