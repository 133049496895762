// Define the type for the component props
type Props = {
    width: number;
    height: number;
    stroke?: string;
    className?: string;
};

// Define the IconPanelRightOpen component
const IconPanelRightOpen = (props: Props) => {
    // Return the SVG for the icon
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 25" fill="none" {...props}>
            <path d="M19 3.66602H5C3.89543 3.66602 3 4.56145 3 5.66602V19.666C3 20.7706 3.89543 21.666 5 21.666H19C20.1046 21.666 21 20.7706 21 19.666V5.66602C21 4.56145 20.1046 3.66602 19 3.66602Z" stroke="#D1D5DB" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M9 3.66602V21.666" stroke="#D1D5DB" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M14 9.66602L17 12.666L14 15.666" stroke="#D1D5DB" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    );
};

// Export the IconPanelRightOpen component
export default IconPanelRightOpen;