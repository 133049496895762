type Props = {
    width: number;
    height: number;
    stroke?: string;
    className?: string;
};

const IconUserRevert = (props: Props) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="none" {...props}>
            <path d="M1.6665 17.5C1.6666 16.4508 1.91433 15.4165 2.38955 14.4811C2.86477 13.5457 3.55406 12.7356 4.40136 12.1169C5.24866 11.4981 6.23004 11.088 7.2657 10.92C8.30136 10.752 9.36205 10.8308 10.3615 11.15" stroke="#1F2937" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M8.33317 10.8333C10.6344 10.8333 12.4998 8.96785 12.4998 6.66667C12.4998 4.36548 10.6344 2.5 8.33317 2.5C6.03198 2.5 4.1665 4.36548 4.1665 6.66667C4.1665 8.96785 6.03198 10.8333 8.33317 10.8333Z" stroke="#1F2937" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M13.1167 17.4336C13.5049 17.9513 14.0463 18.3337 14.664 18.5267C15.2817 18.7196 15.9444 18.7133 16.5584 18.5087C17.1723 18.3041 17.7063 17.9114 18.0847 17.3865C18.4631 16.8615 18.6667 16.2307 18.6667 15.5836C18.6667 14.8476 18.3743 14.1418 17.8539 13.6214C17.3335 13.101 16.6276 12.8086 15.8917 12.8086C15.0715 12.8086 14.3253 13.1416 13.7888 13.6781L12.5 14.9669" stroke="#1F2937" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M12.5 12.5V14.9667H14.9667" stroke="#1F2937" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    );
};

export default IconUserRevert;
