import Typography from "@/components/baseComponents/Typography";
import { IoArrowBack } from "react-icons/io5";
import IconWrapper from "@/components/baseComponents/IconWrapper";
import { Button } from "@/components/baseComponents/Button";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import PropertyDetailFeaturesCard from "@/components/mainComponents/Landing/PropertyDetailFeaturesCard";
import { IMyListing } from "@/shared/interfaces/interfaces";
import { useAppDispatch } from "@/redux/hooks";
import { getListingById } from "@/redux/myListings/myListingSlice";
import ListingImage from "@/components/mainComponents/Landing/LandingImage";
import DefaultAvatar from "@/assets/images/default_profile_picture.webp";
import PropertyDetailSkeleton from "@/components/mainComponents/Landing/PropertyDetailSkeleton";

const PropertyDetailPage = () => {
  const { id } = useParams();
  const [listing, setListing] = useState<IMyListing>();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getListingById({ id: id ?? "" })).then((data) => {
      console.log("listing data ", data.payload);
      if (data.payload.data && data.payload.data.length > 0) {
        setListing(data.payload.data[0]);
      }
    });
  }, [dispatch]);

  const [showMore, setShowMore] = useState<boolean>(false);

  const desc = listing?.propertyDescription ?? "";

  return (
    <>
      <div className="flex w-full bg-white">
        <div className="max-w-screen-xl mx-auto w-full px-6">
          {
            !listing&& <PropertyDetailSkeleton />
          }
          {
            listing && 
          <div className="flex flex-col w-full mt-10 pb-10">
            <div
              className="flex items-center gap-2 mb-4 cursor-pointer"
              onClick={() => navigate("/landing/property?loc=New York, USA")}
            >
              <IoArrowBack color="#7065F0" />
              <Typography
                variant="h1"
                className="whitespace-nowrap text-[16px] font-bold font-jakarta text-[#7065F0]"
              >
                Back to results
              </Typography>
            </div>
            <div className="flex gap-4 flex-col mb-10">
              <Typography
                variant="h1"
                color="black"
                className="whitespace-nowrap text-[40px] font-bold font-jakarta text-[#000929]"
              >
                {listing?.listingAddress.streetLine +
                  " " +
                  listing?.listingAddress.secondary}
              </Typography>
              <Typography
                variant="h1"
                color="black"
                className="whitespace-nowrap text-[20px] font-medium font-jakarta opacity-50"
              >
                {listing?.listingAddress.city +
                  ", " +
                  listing?.listingAddress.state +
                  " " +
                  listing?.listingAddress.zipcode}
              </Typography>
            </div>
            <div className="flex w-full lg:hidden gap-4 mb-6">
              <Button
                className={`py-3 !h-full text-[#7065F0] w-full border-[#E0DEF7] border-1 bg-[#F7F7FD] font-jakarta font-bold text-[16px] gap-2 flex justify-center`}
                variant="text"
                onClick={() => {}}
              >
                <IconWrapper width={16} name="share" />
                Share
              </Button>
              <Button
                className={`py-3 !h-full text-[#7065F0] w-full border-[#E0DEF7] border-1 bg-[#F7F7FD] font-jakarta font-bold text-[16px] gap-2 flex justify-center`}
                variant="text"
                onClick={() => {}}
              >
                <IconWrapper width={16} name="heart" />
                Favorite
              </Button>
            </div>
            <div className="flex lg:flex-row flex-col w-full lg:gap-6 gap-2">
              <div className="flex flex-col justify-end lg:w-2/3 w-full">
                <div
                  className={`flex w-full lg:h-[467px] h-[270px] rounded-xl`}
                >
                  <ListingImage
                    className="w-full lg:h-[467px] h-[270px] rounded-xl"
                    listing={listing}
                    index={0}
                  />
                </div>
                <div className="relative">
                  <div className="absolute lg:right-6 lg:bottom-6 left-6 lg:left-auto bottom-6">
                    <div className="flex gap-1">
                      <div className="w-4 h-1 bg-white rounded-full"></div>
                      <div className="w-4 h-1 bg-white rounded-full"></div>
                      <div className="w-4 h-1 bg-white rounded-full"></div>
                      <div className="w-4 h-1 bg-white rounded-full"></div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex lg:flex-col flex-row lg:justify-end lg:w-1/3 w-full">
                <div className="flex w-full mb-6 lg:h-[222px] h-[108px] bg-[#C4C4C4] rounded-xl ">
                  <ListingImage
                  className="w-full mb-6 lg:h-[222px] h-[108px] bg-[#C4C4C4] rounded-xl "
                  listing={listing} index={1} />
                </div>
                <div className="flex w-full lg:h-[222px] lg:ml-0 ml-2 h-[108px] bg-[#C4C4C4] rounded-xl ">
                  <ListingImage
                    className="w-full lg:h-[222px] lg:ml-0 ml-2 h-[108px] bg-[#C4C4C4] rounded-xl "
                    listing={listing}
                    index={2}
                  />
                </div>
                <div className="relative">
                  <div className="absolute lg:right-6 lg:bottom-6 right-1 bottom-[160px] min-w-[200px]">
                    <Button
                      color="inherit"
                      className=" border-1 !h-full border-[#D4D4D8] bg-white gap-2 py-3.5  px-6 flex justify-center items-center font-jakarta font-bold text-[14px]"
                      onClick={() => {}}
                    >
                      <IconWrapper width={16} name="photo" />
                      View all photos
                    </Button>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex lg:flex-row flex-col w-full gap-6 lg:mt-8 mt-6">
              <div className="flex flex-col lg:w-2/3 w-full gap-8">
                <div className="flex lg:flex-row gap-y-4 flex-wrap justify-between w-full py-6 px-6  rounded-xl border-[1px] border-gray-300">
                  <div className="flex flex-col gap-4">
                    <Typography
                      variant="h1"
                      color="black"
                      className="whitespace-nowrap text-[16px] font-medium font-jakarta opacity-50"
                    >
                      Status
                    </Typography>
                    <div className="flex gap-2">
                      <IconWrapper width={24} name="active" />
                      <Typography
                        variant="h1"
                        color="black"
                        className="whitespace-nowrap text-[16px] font-bold font-jakarta"
                      >
                        {listing?.status}
                      </Typography>
                    </div>
                  </div>
                  <div className="flex flex-col gap-4">
                    <Typography
                      variant="h1"
                      color="black"
                      className="whitespace-nowrap text-[16px] font-medium font-jakarta opacity-50"
                    >
                      Bedrooms
                    </Typography>
                    <div className="flex gap-2">
                      <IconWrapper width={24} name="bed" />
                      <Typography
                        variant="h1"
                        color="black"
                        className="whitespace-nowrap text-[16px] font-bold font-jakarta"
                      >
                        {listing?.propertyBedrooms}
                      </Typography>
                    </div>
                  </div>
                  <div className="flex flex-col gap-4">
                    <Typography
                      variant="h1"
                      color="black"
                      className="whitespace-nowrap text-[16px] font-medium font-jakarta opacity-50"
                    >
                      Bathrooms
                    </Typography>
                    <div className="flex gap-2">
                      <IconWrapper width={24} name="bath" />
                      <Typography
                        variant="h1"
                        color="black"
                        className="whitespace-nowrap text-[16px] font-bold font-jakarta"
                      >
                        {listing?.propertyBathrooms}
                      </Typography>
                    </div>
                  </div>
                  {listing?.propertyLotSqft &&
                    listing?.propertyLotSqft.length > 0 && (
                      <div className="flex flex-col gap-4">
                        <Typography
                          variant="h1"
                          color="black"
                          className="whitespace-nowrap text-[16px] font-medium font-jakarta opacity-50"
                        >
                          Square Feet
                        </Typography>

                        <div className="flex gap-2">
                          <IconWrapper width={24} name="sqft" />
                          <Typography
                            variant="h1"
                            color="black"
                            className="whitespace-nowrap text-[16px] font-bold font-jakarta"
                          >
                            {listing?.propertyLotSqft}
                          </Typography>
                        </div>
                      </div>
                    )}
                  <div className="flex flex-col gap-4">
                    <Typography
                      variant="h1"
                      color="black"
                      className="whitespace-nowrap text-[16px] font-medium font-jakarta opacity-50"
                    >
                      Property Type
                    </Typography>
                    <div className="flex gap-2">
                      <IconWrapper width={24} name="property-type" />
                      <Typography
                        variant="h1"
                        color="black"
                        className="whitespace-nowrap text-[16px] font-bold font-jakarta"
                      >
                        {listing?.propertyType}
                      </Typography>
                    </div>
                  </div>
                </div>
                {listing?.propertyDescription &&
                  listing?.propertyDescription.length > 0 && (
                    <div className="flex flex-col w-full gap-4 mt-6">
                      <Typography
                        variant="h1"
                        color="black"
                        className="whitespace-nowrap text-[24px] font-bold font-jakarta text-[#000929]"
                      >
                        About this home
                      </Typography>
                      <Typography
                        color="black"
                        className="text-[16px] font-medium font-jakarta opacity-70 leading-2"
                      >
                        {showMore
                          ? desc
                          : desc.length < 200
                          ? desc
                          : desc.substring(0, 200)}
                        {desc.length > 200 ? (
                          !showMore ? (
                            <span
                              className="pl-2 text-[#7065F0] font-bold cursor-pointer"
                              onClick={() => setShowMore(true)}
                            >
                              Show more
                            </span>
                          ) : (
                            <span
                              className="pl-2 text-[#7065F0] font-bold cursor-pointer"
                              onClick={() => setShowMore(false)}
                            >
                              Show less
                            </span>
                          )
                        ) : (
                          ""
                        )}
                      </Typography>
                    </div>
                  )}
                <div className="flex flex-col w-full gap-6 mt-6">
                  <Typography
                    variant="h1"
                    color="black"
                    className="whitespace-nowrap text-[24px] font-bold font-jakarta text-[#000929]"
                  >
                    Property Features
                  </Typography>
                  <div className="flex flex-col w-full gap-5">
                    <div className="flex lg:flex-row flex-col w-full justify-between lg:gap-20 gap-4">
                      <PropertyDetailFeaturesCard title="Bedrooms" value="4" />
                      <PropertyDetailFeaturesCard title="Bathrooms" value="3" />
                    </div>
                    <div className="flex lg:flex-row flex-col w-full justify-between lg:gap-20 gap-4">
                      <PropertyDetailFeaturesCard title="Unit" value="4" />
                      <PropertyDetailFeaturesCard
                        title="Structured SqFt"
                        value={listing?.propertyHomeSqft ?? ""}
                      />
                    </div>
                    <div className="flex lg:flex-row flex-col w-full justify-between lg:gap-20 gap-4">
                      <PropertyDetailFeaturesCard
                        title="Lot SqFt"
                        value={listing?.propertyLotSqft ?? ""}
                      />
                      <PropertyDetailFeaturesCard
                        title="Is Attached"
                        value={listing?.propertyAttached ?? ""}
                      />
                    </div>
                    <div className="flex lg:flex-row flex-col w-full justify-between lg:gap-20 gap-4">
                      <PropertyDetailFeaturesCard
                        title="Property Type"
                        value={listing?.propertyType ?? ""}
                      />
                      <PropertyDetailFeaturesCard
                        title="Property Sub-Type"
                        value={listing?.propertySubType ?? ""}
                      />
                    </div>
                  </div>
                </div>
                <div className="flex flex-col w-full gap-6 mt-6">
                  <Typography
                    variant="h1"
                    color="black"
                    className="whitespace-nowrap text-[24px] font-bold font-jakarta text-[#000929]"
                  >
                    Property Details
                  </Typography>
                  <div className="flex flex-col w-full gap-5">
                    <div className="flex lg:flex-row flex-col w-full justify-between lg:gap-20 gap-4">
                      <PropertyDetailFeaturesCard
                        title="Parking"
                        value={listing?.propertyParking ?? ""}
                      />
                      <PropertyDetailFeaturesCard
                        title="Garage"
                        value={listing?.propertyGarage ?? ""}
                      />
                    </div>
                    <div className="flex lg:flex-row flex-col w-full justify-between lg:gap-20 gap-4">
                      <PropertyDetailFeaturesCard
                        title="Heating"
                        value={listing?.propertyHeating ?? ""}
                      />
                      <PropertyDetailFeaturesCard
                        title="Cooling"
                        value={listing?.propertyCooling ?? ""}
                      />
                    </div>

                    <div className="flex lg:flex-row flex-col w-full justify-between lg:gap-20 gap-4">
                      <PropertyDetailFeaturesCard
                        title="Views"
                        value={
                          listing?.propertyViews &&
                          listing?.propertyViews.length > 0
                            ? listing?.propertyViews[0] ?? ""
                            : ""
                        }
                      />
                      <PropertyDetailFeaturesCard
                        title="Pool"
                        value={listing?.propertyPool ?? ""}
                      />
                    </div>
                  </div>
                </div>
                <div className="flex w-full h-0.5 bg-[#F0EFFB] mt-[22px] my-6"></div>
                <Typography
                  color="black"
                  className="text-[14px] font-medium font-jakarta leading-0 opacity-50"
                >
                  You agree to RealtyView’s Terms of Use & Privacy Policy. By
                  choosing to contact a property, you also agree that users of
                  RealtyView may call or text you about any inquiries you submit
                  through our platform, which may involve use of automated means
                  and prerecorded/artificial voices. You don't need to consent
                  as a condition of buying, selling or leasing any property, or
                  buying any other goods or services. Message/data rates may
                  apply.
                </Typography>
              </div>
              <div className="flex flex-col lg:w-1/3 w-full gap-6">
                <div className="flex flex-col justify-around w-full py-6 px-6  rounded-xl border-[1px] border-gray-300 gap-6">
                  <div className="flex w-full gap-2 justify-start">
                    <div className="flex w-20 h-20 bg-cover bg-center rounded-full">
                      <img
                        className="rounded-xl w-full h-full bg-[#C4C4C4] object-cover"
                        src={
                          listing?.owner.agent.avatarURL &&
                          listing?.owner.agent.avatarURL.length > 0
                            ? listing?.owner.agent.avatarURL.length
                            : DefaultAvatar
                        }
                        alt=""
                      />
                    </div>

                    <div className="flex flex-col justify-center">
                      <Typography
                        color="black"
                        className="text-[24px] font-bold  font-jakarta"
                      >
                        {listing?.owner.agent.firstName}{" "}
                        {listing?.owner.agent.lastName}
                      </Typography>
                      <Typography className="whitespace-nowrap w-48 overflow-ellipsis overflow-hidden text-[20px] text-[#878787] font-medium  font-jakarta">
                        {listing?.owner.company?.businessName}
                      </Typography>
                    </div>
                  </div>
                  <div className="flex flex-col gap-2">
                    <div className="flex gap-2 items-center">
                      <IconWrapper width={20} name="telephone" />
                      <Typography className="whitespace-nowrap text-[16px] font-medium text-[#878787] mt-1 font-jakarta">
                        {listing?.owner.agent.mobileNumber}
                      </Typography>
                    </div>
                    <div className="flex gap-2 items-center">
                      <IconWrapper width={20} name="message" />
                      <Typography className="whitespace-nowrap text-[16px] font-medium text-[#878787] mt-1 font-jakarta">
                        {listing?.owner.agent.contactEmail}
                      </Typography>
                    </div>
                  </div>
                  <div className="flex p-3 bg-[#F2F2F2]">
                    <Typography
                      color="black"
                      className="text-[16px] font-medium  mt-1 font-jakarta"
                    >
                      {listing?.owner.agent.firstName}, I’d like to learn more
                      about your real estate services.
                    </Typography>
                  </div>
                  <div className="flex flex-col gap-2 w-full">
                    <Button
                      className={`py-3 !h-full text-white w-full bg-[#7065F0] font-jakarta font-semibold text-[16px]  justify-center items-center`}
                      variant="text"
                      onClick={() => {}}
                    >
                      Send a Message
                    </Button>
                    <Button
                      color="inherit"
                      className="w-full border-1 !h-full border-[#7065F0] text-[#7065F0] py-3 justify-center items-center font-jakarta font-semibold text-[16px]"
                      onClick={() => {}}
                    >
                      Connect with Agent
                    </Button>
                  </div>
                </div>
              </div>
              {/* <div className="flex flex-col lg:hidden py-12 px-6 bg-[#F7F7FD] gap-6">
                <Typography
                  color="black"
                  className=" text-[24px] font-bold font-jakarta"
                >
                  Similar listings
                </Typography>
                <div className="flex flex-col gap-4">
                  <ListingCard
                    key={123}
                    isPopular={true}
                    price="2,400"
                    priceExtra="/month"
                    address="Faulkner Ave"
                    likeButton={true}
                    addressDetail="Woodland St, Michigan, IN"
                  />
                  <ListingCard
                    key={124}
                    isPopular={false}
                    price="2,400"
                    priceExtra="/month"
                    likeButton={true}
                    address="St. Crystal"
                    addressDetail="Highland Lake, FL"
                  />
                </div>
              </div> */}
            </div>
          </div>
          }

        </div>

      </div>
    </>
  );
};

export default PropertyDetailPage;
