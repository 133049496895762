type Props = {
  width: number;
  height: number;
  stroke?: string;
  className?: string;
};

const IconBath = (props: Props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 21 20" fill="none" {...props}>
      <path
        d="M7.99992 5L5.91659 2.91667C5.70288 2.67558 5.40435 2.52631 5.08325 2.5C4.40242 2.5 3.83325 3.06917 3.83325 3.75V14.1667C3.83325 14.6087 4.00885 15.0326 4.32141 15.3452C4.63397 15.6577 5.05789 15.8333 5.49992 15.8333H15.4999C15.9419 15.8333 16.3659 15.6577 16.6784 15.3452C16.991 15.0326 17.1666 14.6087 17.1666 14.1667V10"
        stroke="#6B7280"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.83341 4.16406L7.16675 5.83073"
        stroke="#6B7280"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2.16675 10H18.8334"
        stroke="#6B7280"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.33325 15.8359V17.5026"
        stroke="#6B7280"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.6667 15.8359V17.5026"
        stroke="#6B7280"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default IconBath;
