import { MdOutlineWrongLocation, MdOutlineAddLocationAlt } from 'react-icons/md';
import Typography from '@/components/baseComponents/Typography';
import { Button } from '@/components/baseComponents/Button';
import AdvancedAddressSearch from '@/components/baseComponents/AdvancedAddressSearch';
import { ListingAdvancedSearchDto } from '@/shared/interfaces/interfaces';
import { useAppSelector, useAppDispatch } from '@/redux/hooks';
import { getAddresses, getCities, fetchAddressAutocomplete } from '@/redux/user/userSlice';

type IProps = {
  value?: ListingAdvancedSearchDto;
  setValue?: Function;
}

const Locations = (props: IProps) => {
  const dispatch = useAppDispatch();
  const cities = useAppSelector(getCities);
  const addresses = useAppSelector(getAddresses);

  const onChangeAddressAutoComplete = (item: any) => {
		dispatch(fetchAddressAutocomplete({ address: item }));
	};

  const handleAddAddress = () => {
		if (props.value && props.value.additionalAddresses) {
			const additionalAddresses = [ ...props.value.additionalAddresses ];
			additionalAddresses.push('');
			props.setValue && props.setValue({
				...props.value,
				additionalAddresses
			});
		}
	};

	const handleDeleteAddress = (index: any) => {
		if (props.value && props.value.additionalAddresses) {
			const additionalAddresses = [ ...props.value.additionalAddresses ];
			additionalAddresses.splice(index, 1);
			props.setValue && props.setValue({
				...props.value,
				additionalAddresses
			});
		}
	};

	const handleChangeAddress = (value: string, index: number) => {
		if (props.value && props.value.additionalAddresses) {
			const additionalAddresses = [ ...props.value.additionalAddresses ];
			(additionalAddresses.length > index) && (additionalAddresses.splice(index, 1, value));
			props.setValue && props.setValue({
				...props.value,
				additionalAddresses
			});
		}
	};

  return (
    <div>
      <Typography variant="h3" color="primary">Locations</Typography>
      <div className="grid grid-cols-1 gap-3 mt-[15px]">
        <div className="col-span-1 relative flex flex-col">
          <Typography variant="caption" color="secondary" className=''>Address</Typography>
          {props.value?.additionalAddresses && props.value?.additionalAddresses?.map((item: string, index: any) => {
            return (
              <div key={index} className='flex mb-2 gap-2 relative w-full'>
                <AdvancedAddressSearch
                  value={item}
                  cities={cities}
                  addresses={addresses}
                  placeholder="Enter an Address, City, State or Zip Code"
                  onChange={onChangeAddressAutoComplete}
                  onAllChange={(value) => handleChangeAddress(value, index)}
                  className='w-full pr-8'
                  inputClassName={`!border-0 pl-8 ${
                    props.value?.address ? "bg-[#E5E7EB]" : "bg-[#F1F5F9]"
                  }`}
                />
                <MdOutlineWrongLocation
                  className='absolute right-2 top-2 text-[20px] cursor-pointer hover:text-secondary'
                  onClick={() => handleDeleteAddress(index)}
                />
              </div>
            )
          })}
          <div className="flex">
            <Button variant="text" className='flex bg-white border-[#E5E7EB] border-1 hover:bg-gray-300 mt-4' onClick={() => handleAddAddress()}>
              <div className='flex gap-2 items-center justify-center'>
                <div className='text-primary'><MdOutlineAddLocationAlt className='h-4 w-4' /></div>
                <Typography className='text-[14px]' color="primary">Add Another Location</Typography>
              </div>
            </Button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Locations;