import { Fragment, useState } from "react";
import { Transition, Popover } from "@headlessui/react";
import { Button } from "@/components/baseComponents/Button";
import Select from "@/components/baseComponents/Select";
import IconWrapper from "@/components/baseComponents/IconWrapper";
import Typography from "@/components/baseComponents/Typography";
import { IScrapeHeroCrawler } from "@/shared/interfaces/scrapeHero/IScrapeHeroCrawler";
import { DatePicker } from "@/components/baseComponents/DatePickers";
import Switch from "@/components/baseComponents/Switch";

type FilterProps = {
    crawlers: IScrapeHeroCrawler[],
    onApplyFilter: Function;
}

const Filter = ({
    crawlers,
    onApplyFilter
}: FilterProps) => {

    const filterDataInitialState = { crawler: "", startTimeFrom: "", startTimeTo: "", forImportOnly: false, forCampaignOnly: false };
    const [localFilterData, setLocalFilterData] = useState<any>(filterDataInitialState);

    return (
        <Popover as="div" className="relative inline-block text-left">
            <Popover.Button className="flex gap-2 items-center px-4 py-2 rounded-md hover:bg-gray-200">
                <IconWrapper name="filter" width={14} />
                <Typography variant="button1">
                    Filter
                </Typography>
            </Popover.Button>
            <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
            >
                <Popover.Panel className="absolute z-10 right-1 bg-white shadow-xl w-[450px] px-6 py-6 rounded-md">
                    {({ close }) => (
                        <div className="flex flex-col gap-4">
                            <div className="flex justify-between items-center">
                                <Typography variant="h4" className="whitespace-nowrap">
                                    Filter
                                </Typography>
                                <Button variant="icon" onClick={() => close()}>
                                    <IconWrapper name="close" />
                                </Button>
                            </div>
                            <div className="flex flex-col w-full gap-2">
                                <Typography variant="input-label" className="text-secondary">
                                    Crawler
                                </Typography>
                                <Select
                                    options={crawlers}
                                    filterOption={() => true}
                                    value={localFilterData?.crawler}
                                    placeholder="Select a crawler"
                                    onChange={(option: IScrapeHeroCrawler) => setLocalFilterData({ ...localFilterData, crawler: option })}
                                />
                            </div>
                            <div className="flex flex-col w-full gap-2">
                                <Typography variant="input-label" className="text-secondary">
                                    Job Date Range
                                </Typography>
                                <div className="flex items-center">
                                    <DatePicker
                                        className="flex-1"
                                        value={localFilterData?.startTimeFrom}
                                        onChange={(value) => setLocalFilterData({ ...localFilterData, startTimeFrom: value })}
                                    />
                                    <div className="w-2 text-center medium-text"></div>
                                    <DatePicker
                                        className="flex-1"
                                        value={localFilterData?.startTimeTo}
                                        minDate={localFilterData?.startTimeFrom}
                                        onChange={(value) => setLocalFilterData({ ...localFilterData, startTimeTo: value })}
                                    />
                                </div>
                            </div>
                            <div className="flex items-center">
                                <Switch
                                    value={true}
                                    checked={localFilterData.forImportOnly}
                                    className="flex-1"
                                    label="For Import Only"
                                    onChange={(event) => setLocalFilterData({ ...localFilterData, forImportOnly: event.currentTarget.checked, forCampaignOnly: false })}
                                />
                                <Switch
                                    value={true}
                                    checked={localFilterData.forCampaignOnly}
                                    className="flex-1"
                                    label="For Campaign Only"
                                    onChange={(event) => setLocalFilterData({ ...localFilterData, forCampaignOnly: event.currentTarget.checked, forImportOnly: false })}
                                />
                            </div>
                            <div className="flex gap-2">
                                <Button
                                    variant="contained"
                                    color="default"
                                    className="flex-1"
                                    onClick={() => {
                                        setLocalFilterData([]);
                                        onApplyFilter({});
                                        close();
                                    }}
                                >
                                    <Typography variant="button1" color="primary">
                                        Reset
                                    </Typography>
                                </Button>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    className="flex-1"
                                    onClick={() => {
                                        onApplyFilter(localFilterData);
                                        close();
                                    }}
                                >
                                    <Typography variant="button1" color="white">
                                        Apply
                                    </Typography>
                                </Button>
                            </div>
                        </div>
                    )}
                </Popover.Panel>
            </Transition>
        </Popover>
    )
}

export default Filter;