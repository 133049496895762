type Props = {
  width: number;
  height: number;
  stroke?: string;
  className?: string;
};

const IconHelp = (props: Props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 20 21"
      fill="none"
      {...props}
    >
      <path
        d="M2.23527 20.8832H9.65457C9.89887 20.8832 10.0977 20.6851 10.0977 20.4401C10.0977 20.1951 9.89957 19.997 9.65457 19.997H2.23527C2.01337 19.997 1.83277 19.8164 1.83277 19.5938V2.09659C1.83277 1.87469 2.01337 1.69409 2.23527 1.69409H10.944L10.9223 5.68269H14.9158V12.8598C14.9158 13.1041 15.1139 13.3029 15.3589 13.3029C15.6039 13.3029 15.802 13.1048 15.802 12.8598V5.03799L11.5726 0.808594H2.23527C1.52477 0.808594 0.947266 1.38609 0.947266 2.09659V19.5945C0.947266 20.305 1.52477 20.8832 2.23527 20.8832ZM11.8134 4.79719L11.826 2.38779L14.2907 4.79719H11.8127H11.8134Z"
        fill="#969696"
      />
      <path
        d="M3.15404 5.86667H9.07254C9.31684 5.86667 9.51564 5.66857 9.51564 5.42357C9.51564 5.17857 9.31754 4.98047 9.07254 4.98047H3.15404C2.90974 4.98047 2.71094 5.17857 2.71094 5.42357C2.71094 5.66857 2.90904 5.86667 3.15404 5.86667Z"
        fill="#969696"
      />
      <path
        d="M3.15404 9.25534H12.611C12.8553 9.25534 13.0541 9.05724 13.0541 8.81224C13.0541 8.56724 12.856 8.36914 12.611 8.36914H3.15404C2.90974 8.36914 2.71094 8.56724 2.71094 8.81224C2.71094 9.05724 2.90904 9.25534 3.15404 9.25534Z"
        fill="#969696"
      />
      <path
        d="M11.3538 12.1999C11.3538 11.9556 11.1557 11.7568 10.9107 11.7568H3.15404C2.90974 11.7568 2.71094 11.9549 2.71094 12.1999C2.71094 12.4449 2.90904 12.643 3.15404 12.643H10.9107C11.155 12.643 11.3538 12.4449 11.3538 12.1999Z"
        fill="#969696"
      />
      <path
        d="M3.15404 15.1455C2.90974 15.1455 2.71094 15.3436 2.71094 15.5886C2.71094 15.8336 2.90904 16.0317 3.15404 16.0317H8.41104C8.65534 16.0317 8.85414 15.8336 8.85414 15.5886C8.85414 15.3436 8.65604 15.1455 8.41104 15.1455H3.15404Z"
        fill="#969696"
      />
      <path
        d="M13.5794 19.4531H12.5518V20.8251H13.5794V19.4531Z"
        fill="#969696"
      />
      <path
        d="M13.5795 18.76C13.5795 18.3351 13.723 18.0194 14.1661 17.6127C14.8458 16.9932 15.0495 16.5746 15.0495 15.946C15.0495 15.2327 14.598 14.2744 13.1805 14.2695C11.6552 14.2646 11.2331 15.4035 11.1953 16.0461H12.1725C12.253 15.4707 12.5281 15.0654 13.1609 15.0654C13.7937 15.0654 14.0387 15.5029 14.0387 15.9208C14.0387 16.2855 13.9393 16.6201 13.4549 17.0541C12.736 17.6946 12.5526 18.1552 12.5526 18.6942V18.8454H13.5802V18.76H13.5795Z"
        fill="#969696"
      />
    </svg>
  );
};

export default IconHelp;
