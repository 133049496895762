import Typography from "@/components/baseComponents/Typography";


interface IProps {
  title: string;
  value: string;
  alignStart?: boolean;
}
const PropertyDetailFeaturesCard = ({
  title,
  value,
  alignStart = false,
}: IProps) => {
  return (
    <div className="flex w-full justify-between items-center">
      <Typography
        variant="h1"
        color="black"
        className="text-[16px] font-medium font-jakarta opacity-50"
      >
        {title}
      </Typography>
      <Typography
        variant="h1"
        color="black"
        className={`text-[18px] font-bold font-jakarta ${
          alignStart ? "text-start" : "text-end"
        }`}
      >
        {value}
      </Typography>
    </div>
  );
};

export default PropertyDetailFeaturesCard;
