type Props = {
  width: number;
  height: number;
  stroke?: string;
  className?: string;
};

const IconBed = (props: Props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 21 20" fill="none" {...props}>
      <path
        d="M2.16675 3.33594V16.6693"
        stroke="#6B7280"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2.16675 6.66406H17.1667C17.6088 6.66406 18.0327 6.83966 18.3453 7.15222C18.6578 7.46478 18.8334 7.8887 18.8334 8.33073V16.6641"
        stroke="#6B7280"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2.16675 14.1641H18.8334"
        stroke="#6B7280"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.5 6.66406V14.1641"
        stroke="#6B7280"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default IconBed;
