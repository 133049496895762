import IconWrapper from "@/components/baseComponents/IconWrapper";
import Typography from "@/components/baseComponents/Typography";
import LandingPropertyCarousel from "@/components/mainComponents/Landing/LandingPropertyCarousel";
import ListingProfileCard from "@/components/mainComponents/Landing/ListingProfileCard";
import PropertyDetailFeaturesCard from "@/components/mainComponents/Landing/PropertyDetailFeaturesCard";
import { IMyListing } from "@/shared/interfaces/interfaces";
import { Dialog, Transition } from "@headlessui/react";
import { Fragment } from "react";
import { MdArrowBackIosNew, MdArrowForwardIos } from "react-icons/md";


type IProps = {
  open: boolean;
  closeDrawer: Function;
  listing?: IMyListing;
  onNext: Function;
  onPrev: Function;
};

const ListingDrawer = ({
  listing,
  closeDrawer,
  open,
  onNext,
  onPrev,
}: IProps) => {
  return (
    <>
      <Transition.Root show={open} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-30 "
          onClose={() => {
            closeDrawer();
          }}
        >
          <div
            className="fixed inset-0 overflow-hidden"
            style={{ backgroundColor: "rgba(0,0,0,0.4)" }}
          >
            <div className="absolute inset-0 overflow-hidden">
              <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full md:pl-10 pl-0 sm:pl-16">
                <Transition.Child
                  as={Fragment}
                  enter="transform transition ease-in-out duration-500 sm:duration-300"
                  enterFrom="translate-x-full"
                  enterTo="translate-x-0"
                  leave="transform transition ease-in-out duration-500 sm:duration-300"
                  leaveFrom="translate-x-0"
                  leaveTo="translate-x-full"
                >
                  <Dialog.Panel className="pointer-events-auto h-screen max-sm:w-[414px] md:w-[900px] w-full overflow-y-auto hide-scrollbar ">
                    <div className="flex bg-white w-full h-screen flex-col overflow-y-auto hide-scrollbar shadow pb-10">
                      <div className="flex md:flex-row flex-col w-full md:pl-[60px] pl-[10px] md:pr-[76px] pr-[10px] md:items-center items-start py-6 md:justify-between justify-start">
                        <div
                          className="flex gap-1 cursor-pointer"
                          onClick={() => closeDrawer()}
                        >
                          <MdArrowBackIosNew size={20} color="#7065F0" />
                          <div className="flex text-[#7065F0] font-jakarta font-bold text-[18px]">
                            Back to Results
                          </div>
                        </div>
                        <div className="flex md:gap-[109px] justify-between w-full md:w-[250px] md:pt-0 pt-5">
                          <div
                            className="flex gap-1 cursor-pointer"
                            onClick={() => onPrev()}
                          >
                            <MdArrowBackIosNew size={20} color="#7065F0" />
                            <div className="flex text-[#7065F0] font-jakarta font-bold text-[18px]">
                              Previous
                            </div>
                          </div>
                          <div
                            className="flex gap-1 cursor-pointer"
                            onClick={() => onNext()}
                          >
                            <div className="flex text-[#7065F0] font-jakarta font-bold text-[18px]">
                              Next
                            </div>
                            <MdArrowForwardIos size={20} color="#7065F0" />
                          </div>
                        </div>
                      </div>
                      <div className="flex w-full h-0.5 bg-black"></div>
                      <div className="flex flex-col w-full md:pl-[60px] pl-2.5 md:pr-[46px] pr-2.5 md:pt-10 pt-0">
                        <div className="flex md:flex-row flex-col w-full justify-between items-end">
                          <div className="flex flex-col">
                            <Typography
                              variant="h1"
                              color="black"
                              className="text-[40px] font-bold font-jakarta text-[#000929]"
                            >
                              {listing?.listingAddress.streetLine +
                                " " +
                                listing?.listingAddress.secondary}
                            </Typography>
                            <Typography
                              variant="h1"
                              color="black"
                              className="text-[20px] font-medium font-jakarta opacity-50"
                            >
                              {listing?.listingAddress.city +
                                ", " +
                                listing?.listingAddress.state +
                                " " +
                                listing?.listingAddress.zipcode}
                            </Typography>
                          </div>
                          <div className="text-[#7065F0] font-jakarta text-[28px]">
                            <span className="font-bold">
                              ${listing?.listingListPrice?.toLocaleString()}
                            </span>
                          </div>
                        </div>
                        <div className="flex flex-col justify-end w-full mb-10 mt-1">
                          <div className="flex xl:min-w-[343px] w-full min-w-[300px] rounded-tr-lg rounded-tl-lg md:min-h-[480px] h-[240px] ">
                            <div className="xl:min-w-[343px] w-full min-w-[300px] rounded-tr-lg rounded-tl-lg md:h-[480px] h-[240px] ">
                              <LandingPropertyCarousel
                                slidesToShow={1}
                                isCenter={true}
                                data={listing?.propertyPhotos}
                                onClick={()=>{}}
                                className="w-[226px] md:h-[480px] h-[240px]"
                              />
                            </div>
                          </div>
                          <div className="relative w-full">
                            <div className="absolute w-full bottom-4">
                              <div className="flex w-full justify-center gap-1">
                                <div className="w-4 h-1 bg-white rounded-full cursor-pointer"></div>
                                <div className="w-4 h-1 bg-white rounded-full cursor-pointer"></div>
                                <div className="w-4 h-1 bg-white rounded-full cursor-pointer"></div>
                                <div className="w-4 h-1 bg-white rounded-full cursor-pointer"></div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <ListingProfileCard listing={listing} />
                        <div className="flex lg:flex-row gap-y-4 flex-wrap justify-between w-full mt-[38px] px-6 py-6  rounded-xl border-[1px] border-gray-300">
                          <div className="flex flex-col gap-4">
                            <Typography
                              variant="h1"
                              color="black"
                              className="whitespace-nowrap text-[16px] font-medium font``````````````````   -jakarta opacity-50"
                            >
                              Status
                            </Typography>
                            <div className="flex gap-2">
                              <IconWrapper width={24} name="active" />
                              <Typography
                                variant="h1"
                                color="black"
                                className="whitespace-nowrap text-[16px] font-bold font-jakarta"
                              >
                                {listing?.status}
                              </Typography>
                            </div>
                          </div>
                          <div className="flex flex-col gap-4">
                            <Typography
                              variant="h1"
                              color="black"
                              className="whitespace-nowrap text-[16px] font-medium font-jakarta opacity-50"
                            >
                              Bedrooms
                            </Typography>
                            <div className="flex gap-2">
                              <IconWrapper width={24} name="bed" />
                              <Typography
                                variant="h1"
                                color="black"
                                className="whitespace-nowrap text-[16px] font-bold font-jakarta"
                              >
                                {listing?.propertyBedrooms}
                              </Typography>
                            </div>
                          </div>
                          <div className="flex flex-col gap-4">
                            <Typography
                              variant="h1"
                              color="black"
                              className="whitespace-nowrap text-[16px] font-medium font-jakarta opacity-50"
                            >
                              Bathrooms
                            </Typography>
                            <div className="flex gap-2">
                              <IconWrapper width={24} name="bath" />
                              <Typography
                                variant="h1"
                                color="black"
                                className="whitespace-nowrap text-[16px] font-bold font-jakarta"
                              >
                                {listing?.propertyBathrooms}
                              </Typography>
                            </div>
                          </div>
                          {listing?.propertyLotSqft &&
                            listing?.propertyLotSqft.length > 0 && (
                              <div className="flex flex-col gap-4">
                                <Typography
                                  variant="h1"
                                  color="black"
                                  className="whitespace-nowrap text-[16px] font-medium font-jakarta opacity-50"
                                >
                                  Square Feet
                                </Typography>

                                <div className="flex gap-2">
                                  <IconWrapper width={24} name="sqft" />
                                  <Typography
                                    variant="h1"
                                    color="black"
                                    className="whitespace-nowrap text-[16px] font-bold font-jakarta"
                                  >
                                    {listing?.propertyLotSqft}
                                  </Typography>
                                </div>
                              </div>
                            )}
                          <div className="flex flex-col gap-4">
                            <Typography
                              variant="h1"
                              color="black"
                              className="whitespace-nowrap text-[16px] font-medium font-jakarta opacity-50"
                            >
                              Property Type
                            </Typography>
                            <div className="flex gap-2">
                              <IconWrapper width={24} name="property-type" />
                              <Typography
                                variant="h1"
                                color="black"
                                className="whitespace-nowrap text-[16px] font-bold font-jakarta"
                              >
                                {listing?.propertyType}
                              </Typography>
                            </div>
                          </div>
                        </div>
                        <div className="flex flex-col w-full gap-4 mt-6">
                          <Typography
                            variant="h1"
                            color="black"
                            className="whitespace-nowrap text-[24px] font-bold font-jakarta text-[#000929]"
                          >
                            About this home
                          </Typography>
                          <Typography
                            color="black"
                            className="text-[16px] font-medium font-jakarta opacity-70 leading-2"
                          >
                            {listing?.propertyDescription.length == 0
                              ? "Description"
                              : listing?.propertyDescription}
                          </Typography>
                        </div>
                        <div className="flex flex-col w-full gap-6 mt-6">
                          <Typography
                            variant="h1"
                            color="black"
                            className="whitespace-nowrap text-[24px] font-bold font-jakarta text-[#000929]"
                          >
                            Property Details
                          </Typography>
                          <div className="flex md:flex-row flex-col w-full gap-5">
                            <div className="flex w-full flex-col gap-5">
                              <PropertyDetailFeaturesCard
                                title="Property Type"
                                value={listing?.propertyType ?? ""}
                              />
                              <PropertyDetailFeaturesCard
                                title="Property Sub-Type"
                                value={listing?.propertySubType ?? ""}
                              />
                              <PropertyDetailFeaturesCard
                                title="Bedrooms"
                                value={listing?.propertyBedrooms ?? ""}
                              />
                              <PropertyDetailFeaturesCard
                                title="Bathrooms"
                                value={listing?.propertyBathrooms ?? ""}
                              />
                              <PropertyDetailFeaturesCard
                                title="Home SqFt"
                                value={listing?.propertyHomeSqft ?? ""}
                              />
                            </div>
                            <div className="flex w-full flex-col gap-5">
                              <PropertyDetailFeaturesCard
                                title="Lot SqFt"
                                value={listing?.propertyLotSqft ?? ""}
                              />
                              <PropertyDetailFeaturesCard
                                title="Parking"
                                value={listing?.propertyParking ?? ""}
                              />
                              <PropertyDetailFeaturesCard
                                title="Garage"
                                value={listing?.propertyGarage ?? ""}
                              />
                              <PropertyDetailFeaturesCard
                                title="Heating"
                                value={listing?.propertyHeating ?? ""}
                              />
                              <PropertyDetailFeaturesCard
                                title="Cooling"
                                value={listing?.propertyCooling ?? ""}
                              />
                              <PropertyDetailFeaturesCard
                                title="Pool"
                                value={listing?.propertyPool ?? ""}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="flex w-full h-0.5 bg-[#F0EFFB] mt-[22px] my-6"></div>
                        <Typography
                          color="black"
                          className="text-[14px] font-medium font-jakarta leading-0 opacity-50"
                        >
                          You agree to RealtyView’s Terms of Use & Privacy
                          Policy. By choosing to contact a property, you also
                          agree that users of RealtyView may call or text you
                          about any inquiries you submit through our platform,
                          which may involve use of automated means and
                          prerecorded/artificial voices. You don't need to
                          consent as a condition of buying, selling or leasing
                          any property, or buying any other goods or services.
                          Message/data rates may apply.
                        </Typography>
                      </div>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
};

export default ListingDrawer;


