import { Fragment, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Dialog, Transition } from "@headlessui/react";
import { CreateMessageDto, GetMessagesDto, IMessageDto, IMyInfo, IMyListing, IParticipantDto, UpdateMyInfoDto, UpdateReadStatus } from "@/shared/interfaces/interfaces";
import DefaultProfilePicture from "@/assets/images/default_avatar.jpg";
import XMark from "@/assets/icons/x-mark-white.png";

import defaultCoverPhoto from "@/assets/images/default_cover_image.jpg";
import Typography from "@/components/baseComponents/Typography";
import { useAppDispatch, useAppSelector } from "@/redux/hooks";
import { getUser, updateMyInfo } from "@/redux/user/userSlice";
import Scrollbars from "react-custom-scrollbars";
import IconWrapper from "@/components/baseComponents/IconWrapper";
import { Button } from "@/components/baseComponents/Button";
import ChatDrawer from "@/components/layouts/bars/Components/ChatDrawer";
import { createMessage, fetchMessages, getMessages, updateReadStatus } from "@/redux/chat/chatSlice";
import { getListingById } from "@/redux/myListings/myListingSlice";
import { follow, unfollow } from "@/redux/agent/agentSlice";
import { notify } from "@/shared/services/notify";

type IProps = {
  open: boolean;
  changeViewDrawer: Function;
  data: IMyInfo;
  listingData?: IMyListing;
};

const ViewDrawer = (props: IProps) => {
  const navigate = useNavigate();
  const user = useAppSelector(getUser);
  const [isFollowed, setIsFollowed] = useState<boolean>(false);

  const { data, listingData } = props;
  if (!data) {
    return;
  }

  const isCurrentUser = user.isVendor ? false : user.agent?.memberID === data.memberID;

  const licenseDate = data.licenseDate ? new Date(data.licenseDate).toLocaleDateString() : "";

  const licenseExpiration = data.licenseExpiration ? new Date(data.licenseExpiration).toLocaleDateString() : "";

  // Redux
  const dispatch = useAppDispatch();
  const messages = useAppSelector(getMessages);

  // Open Chat functionality
  const [openProfileChat, setOpenProfileChat] = useState<boolean>(false);
  const [chatParticipant, setChatParticipant] = useState<IParticipantDto>({} as IParticipantDto);
  const [listings, setListings] = useState<IMyListing[]>([]);
  const listing: any = listingData ?? (listings ? listings[0] : {});
  const [viewMessages, setViewMessages] = useState<IMessageDto[]>([]);
  const [content, setContent] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [followerCount, setFollowerCount] = useState<number>(props.data?.followers?.length || 0);

  useEffect(() => {
    setTimeout(() => {
      const main_body = document.getElementById("view_drawer");
      if (main_body) {
        main_body.scrollTop = 0;
      }
    }, 500);

    if (props.open) {
      setFollowerCount(props.data?.followers?.length || 0);
      if (user.agent?.following) {
        const following = user.agent?.following?.flatMap((current: any) => {
          if (current.hasOwnProperty("following")) {
            return [current.following];
          }
          return [];
        });
        setIsFollowed(following.includes(props.data._id));
      }
    }
  }, [props.open]);

  // Chat functionality
  useEffect(() => {
    dispatch(getListingById({ id: props.data?._id ?? "" })).then((data) => {
      setListings(data.payload.data);
    });
  }, [dispatch]);

  const fetchRecentMessages = () => {
    if (user && user?._id && user?._id !== "" && listing?.owner?._id !== "") {
      const data: GetMessagesDto = {
        userId: user?._id,
        participantId: listing?.owner?._id,
      };
      dispatch(fetchMessages(data));
    }
  };

  const handleFocusMainArea = () => {
    if (messages.length > 0) {
      const unreadMessages = messages.filter((message) => message.sender === listing?.owner?.agent?._id && !message.isRead);

      if (unreadMessages.length > 0) {
        const ids: string[] = unreadMessages
          .map((message) => message?._id)
          .filter((id) => id !== undefined)
          .map((id) => id as string);

        var data: UpdateReadStatus = {
          ids: ids,
          userId: user?._id,
        };
        dispatch(updateReadStatus(data));
      }
    }
  };

  const sendChatMessage = (content: string) => {
    if (listing?.owner?._id && listing?.owner?._id !== "") {
      const messageData: IMessageDto = {
        sender: user?._id,
        receiver: listing?.owner?._id,
        content: content,
      };

      const createData: CreateMessageDto = {
        userId: user?._id,
        data: messageData,
      };

      dispatch(createMessage(createData)).then(() => {
        fetchRecentMessages();
      });
    }
  };

  const handleFollow = () => {
    setLoading(true);
    setTimeout(() => {
      dispatch(
        follow({
          follower: user.agent._id,
          following: data._id as string,
        })
      ).then((response) => {
        notify(response.payload.success, response.payload.message);
        if (response.payload.success) {
          setIsFollowed(true);
          setFollowerCount(followerCount + 1);
          refreshUserData();
        }
        setLoading(false);
      });
    }, 2000);
  };

  const handleUnfollow = () => {
    setLoading(true);
    setTimeout(() => {
      dispatch(
        unfollow({
          follower: user.agent._id,
          following: data._id as string,
        })
      ).then((response) => {
        if (response.payload.success) {
          setIsFollowed(false);
          setFollowerCount(followerCount - 1);
          refreshUserData();
        }
        notify(true, response.payload.message);
        setLoading(false);
      });
    }, 2000);
  };

  const refreshUserData = () => {
    let data: UpdateMyInfoDto = {
      email: user.email,
      data: user.agent,
    };
    dispatch(updateMyInfo(data));
  };

  useEffect(() => {
    setChatParticipant({
      _id: listing?.owner?._id,
      name: listing?.owner?.agent?.firstName + " " + listing?.owner?.agent?.lastName,
      firstName: listing?.owner?.agent?.firstName ?? "",
      avatarURL: listing?.owner?.agent?.avatarURL ?? "",
      unreadCount: listing?.owner?.unreadCount ?? 0,
      latestMessage: listing?.owner?.latestMessage?.content ?? "",
      latestTime: listing?.owner?.latestMessage?.createdAt ?? "",
      phoneNumber: listing?.owner?.agent?.mobileNumber ?? "",
      email: listing?.owner?.agent?.contactEmail ?? "",
      licenseType: listing?.owner?.agent?.licenseType ?? "",
      isRead: listing?.owner?.latestMessage?.isRead ?? "",
      companyName: listing?.owner?.agent?.company?.businessName ?? "",
      companyLogo: listing?.owner?.agent?.company?.businessLogo ?? "",
      isOnline: listing?.owner?.isOnline,
    });

    fetchRecentMessages();
  }, [listing]);

  useEffect(() => {
    if (messages.length > 0) {
      const viewMessages = messages.filter((message: IMessageDto) => {
        return (message.sender === user?._id && message.receiver === listing?.owner?._id) || (message.receiver === user?._id && message.sender === listing?.owner?._id);
      });

      setViewMessages(viewMessages);
    }
  }, [messages]);

  return (
    <>
      {!isCurrentUser && (
        <ChatDrawer
          content={content}
          open={openProfileChat}
          setOpen={setOpenProfileChat}
          selectedParticipant={chatParticipant}
          viewMessages={viewMessages}
          setContent={setContent}
          handleFocusMainArea={handleFocusMainArea}
          sendChatMessage={sendChatMessage}
        />
      )}
      <Transition.Root show={props.open} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 z-30"
          onClose={() => {
            if (!openProfileChat) {
              props.changeViewDrawer(false);
            }
          }}
        >
          <div className="fixed inset-0" />
          <div className="fixed inset-0 overflow-hidden">
            <div className="absolute inset-0 overflow-hidden">
              <div className="fixed inset-y-0 right-0 flex max-w-full">
                <Transition.Child
                  as={Fragment}
                  enter="transform transition ease-in-out duration-500 sm:duration-300"
                  enterFrom="translate-x-full"
                  enterTo="translate-x-0"
                  leave="transform transition ease-in-out duration-500 sm:duration-300"
                  leaveFrom="translate-x-0"
                  leaveTo="translate-x-full"
                >
                  <Dialog.Panel className="w-screen max-w-[600px]">
                    <Scrollbars autoHide>
                      <div className="flex flex-col min-h-full bg-white py-0 px-0 shadow-xl" id="view_drawer">
                        <div
                          className="relative bg-cover bg-center bg-image w-full h-[186px]"
                          style={{
                            backgroundImage: "url('" + (data.coverPhotoURL !== undefined && data.coverPhotoURL !== "" ? data.coverPhotoURL : defaultCoverPhoto) + "')",
                          }}
                        >
                          {/* close drawer */}
                          <img src={XMark} alt="XMark" className="absolute w-4 h-4 top-8 right-8 cursor-pointer" onClick={() => props.changeViewDrawer(false)} />
                          <div className="absolute bottom-2.5 right-3.5 flex flex-row items-center gap-2 !z-40">
                            {isCurrentUser ? (
                              <Typography variant="body" className="text-white leading-[20px] px-2 py-1 hover:opacity-80 transition cursor-pointer" onClick={() => navigate("/profile/info")}>
                                Edit Profile
                              </Typography>
                            ) : isFollowed ? (
                              <Button variant="outlined" color="default" size="small" className="w-20" disabled={loading} onClick={() => handleUnfollow()}>
                                <IconWrapper name="unfollow" width={12} />
                                <Typography variant="button2">Unfollow</Typography>
                              </Button>
                            ) : (
                              <Button variant="outlined" color="blue" size="small" className="w-20" disabled={loading} onClick={() => handleFollow()}>
                                <IconWrapper name="follow" width={12} />
                                <Typography variant="button2">Follow</Typography>
                              </Button>
                            )}
                            {data.memberID && (
                              <Button variant="outlined" color="default" size="small">
                                <Typography variant="button2">{"Member " + data.memberID}</Typography>
                              </Button>
                            )}
                          </div>
                        </div>
                        <div className="personal-info">
                          <div className="relative px-4 -translate-y-8 flex flex-col md:flex-row md:items-end gap-2 !z-10">
                            <img
                              src={data.avatarURL !== undefined && data.avatarURL !== "" ? data.avatarURL : DefaultProfilePicture}
                              onError={(e) => e.currentTarget.src = DefaultProfilePicture}
                              className="object-cover object-top w-[80px] aspect-square rounded-full border-2 border-white"
                            />
                            <div className="flex flex-row w-full justify-between items-center gap-4 md:pt-10">
                              <div className="flex flex-col w-full ">
                                {(data.firstName || data.lastName) && (
                                  <Typography variant="h2" className="text-primary !leading-[-0.2px]">
                                    {data.firstName + " " + data.lastName}
                                  </Typography>
                                )}
                                {(data.licenseState || data.licenseType) && (
                                  <Typography variant="body" className="rounded-lg w-fit px-2 py-1 text-primary font-normal bg-[#D9D9D9] bg-opacity-50">
                                    {(data.licenseState ?? "") + " " + (data.licenseType ?? "")}
                                  </Typography>
                                )}
                              </div>
                              <Button
                                className="flex gap-3 border-2 !border-[#C7D2FE] bg-[#EEF2FF]"
                                variant="text"
                                // implement onCLick to openChat
                                onClick={() => {
                                  setOpenProfileChat(true);
                                }}
                              >
                                <IconWrapper name="chat-blue" />
                                <Typography className="font-normal text-[#4C42D7]">Message</Typography>
                              </Button>
                            </div>
                          </div>

                          <div className="flex justify-between">
                            {/* the data used here are placeholder text */}
                            <div className=" grid place-items-center w-full">
                              <Typography>Listings</Typography>
                              <Typography>{props.listingData?.owner?.listings || 0}</Typography>
                            </div>
                            <div className=" grid place-items-center border-x-1 w-full">
                              <Typography>Followers</Typography>
                              <Typography>{followerCount}</Typography>
                            </div>
                            <div className=" grid place-items-center w-full">
                              <Typography>Following</Typography>
                              <Typography>{props.data.following?.length || 0}</Typography>
                            </div>
                          </div>
                          <div className="px-8 pb-10 space-y-4 pt-4">
                            <div>
                              <Typography className=" font-bold text-primary py-5">Contact Information</Typography>
                              {data && (
                                <div>
                                  <div className="flex w-full justify-between py-[10px] border-b-1">
                                    <Typography variant="body" color="secondary">
                                      Phone Number
                                    </Typography>
                                    <Typography>{data.mobileNumber ? data.mobileNumber : "-"}</Typography>
                                  </div>
                                  <div className="flex w-full justify-between py-[10px] border-b-1">
                                    <Typography variant="body" color="secondary">
                                      Email Address
                                    </Typography>
                                    <Typography>{data.contactEmail ? data.contactEmail : "-"}</Typography>
                                  </div>
                                  <div className="flex w-full justify-between py-[10px] border-b-1">
                                    <Typography variant="body" color="secondary">
                                      Office Number
                                    </Typography>
                                    <Typography>{data.officeNumber ? data.officeNumber : "-"}</Typography>
                                  </div>
                                </div>
                              )}
                            </div>
                            <div>
                              <Typography className=" font-bold text-primary py-5">Company Information</Typography>
                              {data && (
                                <div>
                                  <div className="flex w-full justify-between py-[10px] border-b-1">
                                    <Typography variant="body" color="secondary">
                                      Company
                                    </Typography>
                                    <div className="flex items-center gap-2">
                                      {data.company?.businessLogo && data.company?.businessName && <img className="max-w-[20px]" src={data.company?.businessLogo} alt="" />}
                                      <Typography>{data.company?.businessName ? data.company?.businessName : "-"}</Typography>
                                    </div>
                                  </div>
                                  <div className="flex w-full justify-between py-[10px] border-b-1">
                                    <Typography variant="body" color="secondary">
                                      Phone Number
                                    </Typography>
                                    <Typography>{data.officeNumber ? data.officeNumber : "-"}</Typography>
                                  </div>
                                  <div className="flex w-full justify-between py-[10px] border-b-1">
                                    <Typography variant="body" color="secondary">
                                      Email Address
                                    </Typography>
                                    <Typography>{data.company?.principalBrokerEmail ? data.company?.principalBrokerEmail : "-"}</Typography>
                                  </div>
                                </div>
                              )}
                            </div>
                            <div>
                              <Typography className=" font-bold text-primary py-5">License Number</Typography>
                              {data && (
                                <div>
                                  <div className="flex w-full justify-between py-[10px] border-b-1">
                                    <Typography variant="body" color="secondary">
                                      License Number
                                    </Typography>
                                    <Typography>{data.licenseNumber ? data.licenseNumber : "-"}</Typography>
                                  </div>
                                  <div className="flex w-full justify-between py-[10px] border-b-1">
                                    <Typography variant="body" color="secondary">
                                      License State
                                    </Typography>
                                    <Typography>{data.licenseState ? data.licenseState : "-"}</Typography>
                                  </div>
                                  <div className="flex w-full justify-between py-[10px] border-b-1">
                                    <Typography variant="body" color="secondary">
                                      License Type
                                    </Typography>
                                    <Typography>{data.licenseType ? data.licenseType : "-"}</Typography>
                                  </div>
                                  <div className="flex w-full justify-between py-[10px] border-b-1">
                                    <Typography variant="body" color="secondary">
                                      License Date
                                    </Typography>
                                    <Typography>{licenseDate ? licenseDate : "-"}</Typography>
                                  </div>
                                  <div className="flex w-full justify-between py-[10px] border-b-1">
                                    <Typography variant="body" color="secondary">
                                      License Expiration
                                    </Typography>
                                    <Typography>{licenseExpiration ? licenseExpiration : "-"}</Typography>
                                  </div>
                                </div>
                              )}
                            </div>
                            <div>
                              <Typography className=" font-bold text-primary py-5">Professional Profiles</Typography>
                              {data && (
                                <div>
                                  <div className="flex w-full justify-between py-[10px] border-b-1">
                                    <Typography variant="body" color="secondary">
                                      Zillow
                                    </Typography>
                                    <a target="_blank" href={data.zillow}>
                                      <Typography className="flex items-center gap-1">
                                        {data.zillow ? (
                                          <>
                                            <span className="text-[#4C42D7]">{data.firstName + " " + data.lastName}</span>
                                            <IconWrapper name="external-link" width={16} />
                                          </>
                                        ) : (
                                          "-"
                                        )}
                                      </Typography>
                                    </a>
                                  </div>
                                  <div className="flex w-full justify-between py-[10px] border-b-1">
                                    <Typography variant="body" color="secondary">
                                      Homes.com
                                    </Typography>
                                    <a target="_blank" href={data.homes}>
                                      <Typography className="flex items-center gap-1">
                                        {data.homes ? (
                                          <>
                                            <span className="text-[#4C42D7]">{data.firstName + " " + data.lastName}</span>
                                            <IconWrapper name="external-link" width={16} />
                                          </>
                                        ) : (
                                          "-"
                                        )}
                                      </Typography>
                                    </a>
                                  </div>
                                  <div className="flex w-full justify-between py-[10px] border-b-1">
                                    <Typography variant="body" color="secondary">
                                      Realtors.com
                                    </Typography>
                                    <a target="_blank" href={data.realtor}>
                                      <Typography className="flex items-center gap-1">
                                        {data.realtor ? (
                                          <>
                                            <span className="text-[#4C42D7]">{data.firstName + " " + data.lastName}</span>
                                            <IconWrapper name="external-link" width={16} />
                                          </>
                                        ) : (
                                          "-"
                                        )}
                                      </Typography>
                                    </a>
                                  </div>
                                  <div className="flex w-full justify-between py-[10px] border-b-1">
                                    <Typography variant="body" color="secondary">
                                      RateMyAgent
                                    </Typography>
                                    <a target="_blank" href={data.ratemyagent}>
                                      <Typography className="flex items-center gap-1">
                                        {data.ratemyagent ? (
                                          <>
                                            <span className="text-[#4C42D7]">{data.firstName + " " + data.lastName}</span>
                                            <IconWrapper name="external-link" width={16} />
                                          </>
                                        ) : (
                                          "-"
                                        )}
                                      </Typography>
                                    </a>
                                  </div>
                                </div>
                              )}
                            </div>
                            <div>
                              <Typography className=" font-bold text-primary py-5">Social Profiles</Typography>
                              {data && (
                                <div>
                                  <div className="flex w-full justify-between py-[10px] border-b-1">
                                    <Typography variant="body" color="secondary">
                                      Instagram
                                    </Typography>
                                    <a target="_blank" href={data.instagram}>
                                      <Typography className="flex items-center gap-1">
                                        {data.instagram ? (
                                          <>
                                            <span className="text-[#4C42D7]">{data.firstName + " " + data.lastName}</span>
                                            <IconWrapper name="external-link" width={16} />
                                          </>
                                        ) : (
                                          "-"
                                        )}
                                      </Typography>
                                    </a>
                                  </div>
                                  <div className="flex w-full justify-between py-[10px] border-b-1">
                                    <Typography variant="body" color="secondary">
                                      Facebook
                                    </Typography>
                                    <a target="_blank" href={data.facebook}>
                                      <Typography className="flex items-center gap-1">
                                        {data.facebook ? (
                                          <>
                                            <span className="text-[#4C42D7]">{data.firstName + " " + data.lastName}</span>
                                            <IconWrapper name="external-link" width={16} />
                                          </>
                                        ) : (
                                          "-"
                                        )}
                                      </Typography>
                                    </a>
                                  </div>
                                  <div className="flex w-full justify-between py-[10px] border-b-1">
                                    <Typography variant="body" color="secondary">
                                      TikTok
                                    </Typography>
                                    <a target="_blank" href={data.tiktok}>
                                      <Typography className="flex items-center gap-1">
                                        {data.tiktok ? (
                                          <>
                                            <span className="text-[#4C42D7]">{data.firstName + " " + data.lastName}</span>
                                            <IconWrapper name="external-link" width={16} />
                                          </>
                                        ) : (
                                          "-"
                                        )}
                                      </Typography>
                                    </a>
                                  </div>
                                  <div className="flex w-full justify-between py-[10px] border-b-1">
                                    <Typography variant="body" color="secondary">
                                      LinkedIn
                                    </Typography>
                                    <a target="_blank" href={data.linkedin}>
                                      <Typography className="flex items-center gap-1">
                                        {data.linkedin ? (
                                          <>
                                            <span className="text-[#4C42D7]">{data.firstName + " " + data.lastName}</span>
                                            <IconWrapper name="external-link" width={16} />
                                          </>
                                        ) : (
                                          "-"
                                        )}
                                      </Typography>
                                    </a>
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </Scrollbars>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
};

export default ViewDrawer;
