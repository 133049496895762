import ChatSidebar from "@/pages/chat/components/ChatSidebar";
import {
  createMessage,
  fetchMessages,
  fetchParticipants,
  getMessages,
  getParticipantId,
  getParticipants,
  updateReadStatus,
} from "@/redux/chat/chatSlice";
import { useAppDispatch, useAppSelector } from "@/redux/hooks";
import { getListingById } from "@/redux/myListings/myListingSlice";
import { getUser } from "@/redux/user/userSlice";
import {
  CreateMessageDto,
  GetMessagesDto,
  GetParticipantsDto,
  IMessageDto,
  IParticipantDto,
  UpdateReadStatus,
} from "@/shared/interfaces/interfaces";
import { notify } from "@/shared/services/notify";
import { Dialog, Transition } from "@headlessui/react";
import { Fragment, useEffect, useState } from "react";

type IProps = {
  open: boolean;
  changeState: React.Dispatch<React.SetStateAction<boolean>>;
};

const ChatViewDrawer = (props: IProps) => {
  const [allUnread, setAllUnread] = useState(0);

  const dispatch = useAppDispatch();
  const user = useAppSelector(getUser);
  const participantList = useAppSelector(getParticipants);
  const messages = useAppSelector(getMessages);
  const curParticipantId = useAppSelector(getParticipantId);

  const initialParticipant: IParticipantDto = {
    _id: "",
    name: "",
    isOnline: false,
  };

  const [selectedParticipant, setSelectedParticipant] =
    useState<IParticipantDto>(initialParticipant);
  const [participants, setParticipants] = useState<Array<IParticipantDto>>([]);
  const [viewMessages, setViewMessages] = useState<IMessageDto[]>([]);
  const [listing, setListing] = useState<any>();

  const [content, setContent] = useState<string>("");
  const [isTyping, setIsTyping] = useState(false);

  const addNewParticipant = (id: string) => {
    const messageData: IMessageDto = {
      sender: user?._id,
      receiver: id,
      content: "Hi",
    };

    const createData: CreateMessageDto = {
      userId: user?._id,
      data: messageData,
    };

    dispatch(createMessage(createData));
  };

  const sendChatMessage = (content: string) => {
    if (curParticipantId && curParticipantId !== "") {
      const messageData: IMessageDto = {
        sender: user?._id,
        receiver: curParticipantId,
        content: content,
      };

      const createData: CreateMessageDto = {
        userId: user?._id,
        data: messageData,
      };

      dispatch(createMessage(createData));
    }
  };

  const exParticipants = (participantList: Array<any>) => {
    if (participantList && participantList.length > 0) {
      const participants: IParticipantDto[] = participantList.map(
        (participant: any) => {
          return {
            _id: participant?._id,
            name:
              (participant?.agent?.firstName ?? "") +
              (participant?.agent?.lastName
                ? " " + participant?.agent?.lastName
                : ""),
            firstName: participant?.agent?.firstName ?? "",
            avatarURL: participant?.agent?.avatarURL ?? "",
            unreadCount: participant?.unreadCount ?? 0,
            latestMessage: participant?.latestMessage?.content ?? "",
            latestTime: participant?.latestMessage?.createdAt ?? "",
            phoneNumber: participant?.agent?.mobileNumber ?? "",
            email: participant?.agent?.contactEmail ?? "",
            licenseType: participant?.agent?.licenseType ?? "",
            isRead: participant?.latestMessage?.isRead ?? "",
            companyName: participant?.agent?.company?.businessName ?? "",
            companyLogo: participant?.agent?.company?.businessLogo ?? "",
            isOnline: participant?.isOnline,
          };
        }
      );

      setParticipants(participants);
    }
  };

  const handleFocusMainArea = () => {
    if (messages.length > 0) {
      const unreadMessages = messages.filter(
        (message) => message.sender === curParticipantId && !message.isRead
      );

      if (unreadMessages.length > 0) {
        const ids: string[] = unreadMessages
          .map((message) => message?._id)
          .filter((id) => id !== undefined)
          .map((id) => id as string);

        var data: UpdateReadStatus = {
          ids: ids,
          userId: user?._id,
        };
        dispatch(updateReadStatus(data));
      }
    }
  };

  // get current listing, not well implemented but just to get some data i used a single bookmarked
  // Listing to represent some ui, this need to be changed to use the actual listing id thats coming in, if there is any

  const getListing = () => {
    dispatch(getListingById({ id: user.bookmarkedListings[0] }))
      .then((res) => {
        if (res.payload.success) {
          const data = res.payload.data;
          setListing(data[0]);
        } else {
          notify(false, "Something went wrong.");
        }
      })
      .catch(() => {
        notify(false, "Something went wrong.");
      });
  };

  useEffect(() => {
    exParticipants(participantList);
    getListing();
  }, [participantList]);

  useEffect(() => {
    if (user && user?._id && user?._id !== "" && curParticipantId !== "") {
      const data: GetMessagesDto = {
        userId: user?._id,
        participantId: curParticipantId,
      };
      // dispatch(setMessages([]));
      dispatch(fetchMessages(data));
    }
  }, [selectedParticipant, user]);

  useEffect(() => {
    if (
      curParticipantId !== null &&
      curParticipantId !== "" &&
      participants &&
      participants.length > 0
    ) {
      const curParticipant = participants.filter(
        (p) => p?._id === curParticipantId
      )[0];
      if (curParticipant && curParticipant?._id && curParticipant?._id !== "") {
        setSelectedParticipant(curParticipant);
      } else {
        addNewParticipant(curParticipantId);
      }
    }
  }, [curParticipantId, participants]);

  useEffect(() => {
    if (participants && participants.length > 0) {
      participants.map((participant) => {
        if (participant?._id === curParticipantId) {
          setSelectedParticipant(participant);
        }
      });
    }

    // console.log([participants, user]);
  }, [participants]);

  useEffect(() => {
    if (user && user?._id && user?._id !== "") {
      const data: GetParticipantsDto = {
        userId: user?._id,
      };
      dispatch(fetchParticipants(data));
    }
  }, [user]);

  // get all unread messages number
  const getAllUnread = () => {
    let totalUnread = 0;
    participants.forEach((participant) => {
      const isSender = user?._id === participant?._id;
      const participantUnreadCountExist = participant.unreadCount ?? 0;
      // set to 0 to see something
      if (!isSender) {
        if (participantUnreadCountExist >= 1) {
          totalUnread++; // Increment total count if unread is 1 or more
        }
      }
    });
    setAllUnread(totalUnread);
  };

  useEffect(() => {
    if (messages.length > 0) {
      const viewMessages = messages.filter((msg: IMessageDto) => {
        return (
          (msg.sender === user?._id && msg.receiver === curParticipantId) ||
          (msg.receiver === user?._id && msg.sender === curParticipantId)
        );
      });

      setViewMessages(viewMessages);
    }
    getAllUnread();
  }, [messages, user, curParticipantId]);

  // const triggerChatDrawer = () => {
  //   setOpenChatDrawer(true);
  // };

  //   const closeChatDrawer = () => {
  //     setOpenChatDrawer(false);
  //   };
  return (
    <>
      <Transition.Root show={props.open} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 z-30"
          onClose={() => props.changeState(false)}
        >
          <div className="fixed inset-0" />
          <div className="fixed inset-0 overflow-hidden">
            <div className="absolute inset-0 overflow-hidden">
              <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-0 sm:pl-16">
                <Transition.Child
                  as={Fragment}
                  enter="transform transition ease-in-out duration-500 sm:duration-300"
                  enterFrom="translate-x-full"
                  enterTo="translate-x-0"
                  leave="transform transition ease-in-out duration-500 sm:duration-300"
                  leaveFrom="translate-x-0"
                  leaveTo="translate-x-full"
                >
                  <Dialog.Panel className="pointer-events-auto w-screen max-w-[600px]">
                    {/* chat sidebar for mobile*/}
                    <div className="bg-white w-full h-full flex border-r border-border ">
                      <ChatSidebar
                        isForHeader={true}
                        isTyping={isTyping}
                        // openChatDrawer={() => triggerChatDrawer()}
                        closeChatDrawer={() => props.changeState(false)}
                        allUnread={allUnread}
                        participantList={participants}
                        listing={listing}
                        setIsTyping={setIsTyping}
                        content={content}
                        setContent={setContent}
                        handleFocusMainArea={() => handleFocusMainArea()}
                        viewMessages={viewMessages}
                        selectedParticipant={selectedParticipant}
                        sendChatMessage={sendChatMessage}
                      />
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
};

export default ChatViewDrawer;
