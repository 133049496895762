// React libraries and hooks
import React, { Fragment, useEffect, useRef, useState } from "react";

// Third-party libraries
import { Dialog, Transition } from "@headlessui/react";
import Scrollbars from "react-custom-scrollbars";
import { NumericFormat } from "react-number-format";

// Redux hooks and actions
import { useAppDispatch, useAppSelector } from "@/redux/hooks";
import {
  getUser,
  getAddresses,
  fetchAddressAutocomplete,
} from "@/redux/user/userSlice";
import {
  createNewLead,
  fetchAmenities,
  fetchKeywords,
  fetchSchoolDistricts,
  getAmenities,
  getKeywords,
  getSchoolDistricts,
  updateLead
} from "@/redux/lead/leadSlice";

// Base components
import Typography from "@/components/baseComponents/Typography";
import { Button } from "@/components/baseComponents/Button";
import IconWrapper from "@/components/baseComponents/IconWrapper";
import Textarea from "@/components/baseComponents/Textarea";
import Select from "@/components/baseComponents/Select";
import TextField from "@/components/baseComponents/TextField";
import AddressAutoComplete from "@/components/baseComponents/AddressAutoComplete";
import { DatePicker } from "@/components/baseComponents/DatePickers";

// Interfaces
import {
  CreateLeadDto,
  IAddress,
  ILead,
  UpdateLeadDto,
} from "@/shared/interfaces/interfaces";

// Services and utilities
import validation from "@/shared/services/validation";
import { formatAddress } from "@/shared/services/utils";
import { notify } from "@/shared/services/notify";

type IProps = {
  // Boolean properties
  open: boolean;

  // String properties
  keyword: string;
  sortType: string;
  sortField: string;

  // Number properties
  recordsPerPage: number;
  currentPage: number;

  // Function properties
  changeState: Function;
  setTotalCount: Function;
  setCurrentPage: Function;

  // Any properties
  data?: any;
};

const sellerTabs = [
  { name: 'General Detail', step: 1 },
  { name: 'Seller Info', step: 2 },
  { name: 'Property Detail', step: 3 },
  { name: 'Notes', step: 4 },
];

const buyerTabs = [
  { name: 'General Detail', step: 1 },
  { name: 'Buyer Info', step: 2 },
  { name: 'Buyer Preferences', step: 3 },
  { name: 'Financing', step: 4 },
  { name: 'Notes', step: 5 },
];

const propertyType = [
  { value: "Single Family Residence", label: "Single Family Residence" },
  { value: "Multi Family Residence", label: "Multi Family Residence" },
  { value: "Apartment", label: "Apartment" },
  { value: "Townhouse", label: "Townhouse" },
  { value: "Land", label: "Land" },
  { value: "Mobile", label: "Mobile" },
  { value: "Commercial", label: "Commercial" },
  { value: "Other", label: "Other" },
];

const SubTypeList = [
  {
    parent: "Single Family Residence", contents: [
      { value: "Cabin", label: "Cabin" },
      { value: "Manufactured Home", label: "Manufactured Home" },
      { value: "Manufactured On Land", label: "Manufactured On Land" },
      { value: "Mobile Home", label: "Mobile Home" },
      { value: "Own Your Own", label: "Own Your Own" },
      { value: "Single Family Residence", label: "Single Family Residence" },
      { value: "Mixed Use", label: "Mixed Use" },
    ]
  },
  {
    parent: "Multi Family Residence", contents: [
      { value: "Duplex", label: "Duplex" },
      { value: "Manufactured Home", label: "Manufactured Home" },
      { value: "Manufactured On Land", label: "Manufactured On Land" },
      { value: "Mobile Home", label: "Mobile Home" },
      { value: "Own Your Own", label: "Own Your Own" },
      { value: "Quadruplex", label: "Quadruplex" },
      { value: "Triplex", label: "Triplex" },
    ]
  },
  {
    parent: "Apartment", contents: [
      { value: "Apartment", label: "Apartment" },
      { value: "Condominium", label: "Condominium" },
      { value: "Own Your Own", label: "Own Your Own" },
      { value: "Stock Cooperative", label: "Stock Cooperative" },
    ]
  },
  {
    parent: "Townhouse", contents: [
      { value: "Own Your Own", label: "Own Your Own" },
      { value: "Townhouse", label: "Townhouse" },
    ]
  },
  {
    parent: "Land", contents: [
      { value: "Farm (PropertySubType)", label: "Farm (PropertySubType)" },
      { value: "Own Your Own", label: "Own Your Own" },
      { value: "Ranch", label: "Ranch" },
      { value: "Unimproved Land", label: "Unimproved Land" },
    ]
  },
  {
    parent: "Mobile", contents: [
      { value: "Mobile Home", label: "Mobile Home" },
      { value: "Own Your Own", label: "Own Your Own" },
    ]
  },
  {
    parent: "Commercial", contents: [
      { value: "Own Your Own", label: "Own Your Own" },
      { value: "Stock Cooperative", label: "Stock Cooperative" },
      { value: "Business (PropertySubType)", label: "Business (PropertySubType)" },
      { value: "Hotel-Motel", label: "Hotel-Motel" },
      { value: "Industrial (PropertySubType)", label: "Industrial (PropertySubType)" },
      { value: "Mixed Use", label: "Mixed Use" },
      { value: "Multi Family", label: "Multi Family" },
      { value: "Office (PropertySubType)", label: "Office (PropertySubType)" },
      { value: "Retail (PropertySubType)", label: "Retail (PropertySubType)" },
      { value: "Warehouse (PropertySubType)", label: "Warehouse (PropertySubType)" },
    ]
  },
  {
    parent: "Other", contents: [
      { value: "Boat Slip", label: "Boat Slip" },
      { value: "Cabin", label: "Cabin" },
      { value: "Deeded Parking", label: "Deeded Parking" },
      { value: "Farm (PropertySubType)", label: "Farm (PropertySubType)" },
      { value: "Manufactured Home", label: "Manufactured Home" },
      { value: "Manufactured On Land", label: "Manufactured On Land" },
      { value: "Own Your Own", label: "Own Your Own" },
      { value: "Ranch", label: "Ranch" },
      { value: "Timeshare", label: "Timeshare" },
      { value: "Agriculture (PropertySubType)", label: "Agriculture (PropertySubType)" },
      { value: "Business (PropertySubType)", label: "Business (PropertySubType)" },
    ]
  },
];

const Drawer = (props: IProps) => {

  const initialLead: ILead = {
    _id: props.data?._id ?? null,
    leadType: props.data?.leadType ?? "Buyer",
    leadStatus: props.data?.leadStatus ?? "Active",
    dateAdded: props.data?.dateAdded ? new Date(props.data?.dateAdded) : new Date(),
    leadSource: props.data?.leadSource ?? "Real Estate Platform",
    firstName: props.data?.firstName ?? "",
    lastName: props.data?.lastName ?? "",
    companyName: props.data?.companyName ?? "",
    phoneNumber: props.data?.phoneNumber ?? "",
    email: props.data?.email ?? "",
    address: props.data?.address ?? {},
    emailAddress: props.data?.emailAddress ?? {},
    secondaryFirstName: props.data?.secondaryFirstName ?? "",
    secondaryLastName: props.data?.secondaryLastName ?? "",
    secondaryPhoneNumber: props.data?.secondaryPhoneNumber ?? "",
    secondaryEmailAddress: props.data?.secondaryEmailAddress ?? "",

    // Buyer
    buyerIsPreApproved: props.data?.buyerIsPreApproved ?? "No",
    buyerMaximumPurchasPrice: props.data?.buyerMaximumPurchasPrice ?? "",
    buyerLocationsMulti: props.data?.buyerLocationsMulti ?? [],
    buyerSchoolDistrictsMulti: props.data?.buyerSchoolDistrictsMulti ?? [],
    buyerPropertyType: props.data?.buyerPropertyType ?? "Single Family Residence",
    buyerPropertySubType: props.data?.buyerPropertySubType ?? "",
    buyerUnitCount: props.data?.buyerUnitCount ?? 1,
    buyerMinimumBedrooms: props.data?.buyerMinimumBedrooms ?? 1,
    buyerMinimumBathrooms: props.data?.buyerMinimumBathrooms ?? 1,
    buyerMinimumHomeSqFt: props.data?.buyerMinimumHomeSqFt ?? 1,
    buyerMinimumLotSqFt: props.data?.buyerMinimumLotSqFt ?? 1,
    buyerStories: props.data?.buyerStories ?? "No",
    buyerParking: props.data?.buyerParking ?? "No",
    buyerHeating: props.data?.buyerHeating ?? "No",
    buyerHeatingTypeMulti: props.data?.buyerHeatingTypeMulti ?? [],
    buyerCooling: props.data?.buyerCooling ?? "No",
    buyerCoolingTypeMulti: props.data?.buyerCoolingTypeMulti ?? [],
    buyerGarage: props.data?.buyerGarage ?? "No",
    buyerViewsMulti: props.data?.buyerViewsMulti ?? [],
    buyerPool: props.data?.buyerPool ?? "No",
    buyerIsAttached: props.data?.buyerAttached ?? "Yes",
    buyerNewConstruction: props.data?.buyerNewConstruction ?? "No",
    buyerAmenitiesMulti: props.data?.buyerAmenitiesMulti ?? [],
    buyerKeywordsMulti: props.data?.buyerKeywordsMulti ?? [],

    buyerLoanOfficerFirstName: props.data?.buyerLoanOfficerFirstName ?? "",
    buyerLoanOfficerLastName: props.data?.buyerLoanOfficerLastName ?? "",
    buyerLoanOfficerPhone: props.data?.buyerLoanOfficerPhone ?? "",
    buyerLoanOfficerEmail: props.data?.buyerLoanOfficerEmail ?? "",
    buyerLenderCompany: props.data?.buyerLenderCompany ?? "",
    buyerPreApprovalIssueDate: props.data?.buyerPreApprovalIssueDate
      ? new Date(props.data?.buyerPreApprovalIssueDate)
      : undefined,
    buyerPreApprovalExpirationDate: props.data?.buyerPreApprovalExpirationDate
      ? new Date(props.data?.buyerPreApprovalExpirationDate)
      : undefined,
    buyerPrimaryBorrower: props.data?.buyerPrimaryBorrower ?? "",
    buyerSecondaryBorrower: props.data?.buyerSecondaryBorrower ?? "",
    buyerLoanType: props.data?.buyerLoanType ?? "",
    buyerPurchasPrice: props.data?.buyerPurchasPrice ?? "",
    buyerSellerConsession: props.data?.buyerSellerConsession ?? "",
    buyerDownPaymentAmount: props.data?.buyerDownPaymentAmount ?? "",
    buyerBaseLoanAmount: props.data?.buyerBaseLoanAmount ?? "",
    buyerLoanToValue: props.data?.buyerLoanToValue ?? "",
    buyerAnnualTaxes: props.data?.buyerAnnualTaxes ?? "",
    buyerAnnualInsurance: props.data?.buyerAnnualInsurance ?? "",
    buyerAnnualHOADues: props.data?.buyerAnnualHOADues ?? "",
    buyerMortgageRate: props.data?.buyerMortgageRate ?? "",
    buyerAnnualOtherExpenses: props.data?.buyerAnnualOtherExpenses ?? "",
    buyerLenderCredit: props.data?.buyerLenderCredit ?? "",
    // Seller
    sellerListPrice: props.data?.sellerListPrice ?? "",
    sellerPropertyAddress: props.data?.sellerPropertyAddress ?? {},
    sellerUnit: props.data?.sellerUnit ?? "",
    sellerSchoolDistrictsMulti: props.data?.sellerSchoolDistrictsMulti ?? [],
    sellerPropertyType: props.data?.sellerPropertyType ?? "Single Family Residence",
    sellerPropertySubType: props.data?.sellerPropertySubType ?? "",
    sellerUnitCount: props.data?.sellerUnitCount ?? 1,
    sellerStories: props.data?.sellerStories ?? 1,
    sellerHomeSqFt: props.data?.sellerHomeSqFt ?? "",
    sellerLotSqFt: props.data?.sellerLotSqFt ?? "",
    sellerBedrooms: props.data?.sellerBedrooms ?? 1,
    sellerBathrooms: props.data?.sellerBathrooms ?? 1,
    sellerParking: props.data?.sellerParking ?? "Yes",
    sellerGarage: props.data?.sellerGarage ?? "No",
    sellerHeating: props.data?.sellerHeating ?? "No",
    sellerHeatingTypeMulti: props.data?.sellerHeatingTypeMulti ?? [],
    sellerCooling: props.data?.sellerCooling ?? "No",
    sellerCoolingTypeMulti: props.data?.sellerCoolingTypeMulti ?? [],
    sellerViewsMulti: props.data?.sellerViewsMulti ?? [],
    sellerPool: props.data?.sellerPool ?? "No",
    sellerIsAttached: props.data?.sellerIsAttached ?? "No",
    sellerNewConstruction: props.data?.sellerNewConstruction ?? "No",
    sellerAmenitiesMulti: props.data?.sellerAmenitiesMulti ?? [],
    sellerKeywordsMulti: props.data?.sellerKeywordsMulti ?? [],
    sellerPropertyDescription: props.data?.sellerPropertyDescription ?? "",

    sellerOccupancyStatus: props.data?.sellerOccupancyStatus ?? "Owner Occupied",
    sellerAnnualHOADues: props.data?.sellerAnnualHOADues ?? "",
    sellerAnnualTaxes: props.data?.sellerAnnualTaxes ?? "",
    sellerAnnualOtherExpenses: props.data?.sellerAnnualOtherExpenses ?? "",
    sellerVillageAnnualTaxes: props.data?.sellerVillageAnnualTaxes ?? "",

    // Renter
    renterLocationsMulti: props.data?.renterLocationsMulti ?? [],
    renterSchoolDistrictsMulti: props.data?.renterSchoolDistrictsMulti ?? [],
    renterMinimumBedrooms: props.data?.renterMinimumBedrooms ?? 1,
    renterMinimumBathrooms: props.data?.renterMinimumBathrooms ?? 1,
    renterMinimumLotSize: props.data?.renterMinimumLotSize ?? 1,
    renterStories: props.data?.renterStories ?? "",
    renterPropertyType: props.data?.renterPropertyType ?? "",
    renterMaximumMonthlyPayment: props.data?.renterMaximumMonthlyPayment ?? "",
    renterMinimumHomeSqFt: props.data?.renterMinimumHomeSqFt ?? "",
    renterHeatingAndCooling: props.data?.renterHeatingAndcooling ?? "",
    renterGarage: props.data?.renterGarage ?? "",
    renterViewsMulti: props.data?.renterViewsMulti ?? [],
    renterParkingMulti: props.data?.renterParkingMulti ?? [],
    renterAttachedOk: props.data?.renterAttachedOk ?? "",
    renterNewConstructionOnly: props.data?.renterNewConstructionOnly ?? "",
    renterAmenities: props.data?.renterAmenities ?? "",
    renterKeywords: props.data?.renterKeywords ?? "",

    // Landlord
    landlordPropertyAddress: props.data?.landlordPropertyAddress ?? "", //
    landlordUnit: props.data?.landlordUnit ?? "", //
    landlordPropertyType: props.data?.landlordPropertyType ?? "Single Family Residence",
    landlordPropertySubType: props.data?.landlordPropertySubType ?? "",
    landlordListingType: props.data?.landlordListingType ?? "", //
    landlordOccupancyStatus: props.data?.landlordOccupancyStatus ?? 0, //
    landlordListPrice: props.data?.landlordListPrice ?? 0, //
    landlordAssertType: props.data?.landlordAssertType ?? "", //
    landlordBuildingClass: props.data?.landlordBuildingClass ?? "", //
    landlordNumberOfUnits: props.data?.landlordNumberOfUnits ?? 0,
    landlordBedrooms: props.data?.landlordBedrooms ?? 0,
    landlordBathrooms: props.data?.landlordBathrooms ?? 0,
    landlordHomeSqFt: props.data?.landlordHomeSqFt ?? 0,
    landlordLotSqFt: props.data?.landlordLotSqFt ?? 0,
    landlordStories: props.data?.landlordStories ?? "No",
    landlordParking: props.data?.landlordParking ?? "No",
    landlordHeatingAndCooling: props.data?.landlordHeatingAndCooling ?? "",
    landlordGarage: props.data?.landlordGarage ?? "No",
    landlordViews: props.data?.landlordViews ?? [],
    landlordPool: props.data?.landlordPool ?? "No",
    landlordAttached: props.data?.landlordAttached ?? "No",
    landlordNewConstruction: props.data?.landlordNewConstruction ?? "",
    landlordAmenities: props.data?.landlordAmenities ?? [],
    landlordKeywords: props.data?.landlordKeywords ?? [],
    landlordPropertyDescription: props.data?.landlordPropertyDescription ?? "", //
    //note

    sellerIsPreForeclosure: props.data?.sellerIsPreForeclosure ?? "No",
    about: props.data?.about ?? "",
    lastContact: props.data?.lastContact
      ? new Date(props.data?.lastContact)
      : undefined,
    nextContact: props.data?.nextContact
      ? new Date(props.data?.nextContact)
      : undefined,
    startOfTarget: props.data?.startOfTarget
      ? new Date(props.data?.startOfTarget)
      : undefined,
    endOfTarget: props.data?.endOfTarget
      ? new Date(props.data?.endOfTarget)
      : undefined,
    rating: props.data?.rating ?? undefined,
  };


  // Dispatch and selectors
  const dispatch = useAppDispatch();
  const user = useAppSelector(getUser);
  const addresses = useAppSelector(getAddresses);
  const schoolDistricts = useAppSelector(getSchoolDistricts);
  const amenities = useAppSelector(getAmenities);
  const keywords = useAppSelector(getKeywords);
  const drawerScrollbar = useRef<any>(null);

  // State variables
  const [currentStep, setCurrentStep] = React.useState<number>(1);
  const [values, setValues] = useState<ILead>(initialLead);
  const [buyerToggleSameAddress, setBuyerToggleSameAddress] = useState<boolean>(true);
  const [searchKeyword, setSearchKeyword] = React.useState("");
  const [searchAmenities, setSearchAmenities] = React.useState("");
  const [propertySubType, setPropertySubType] = React.useState<Array<any>>([]);

  // Error state variables
  const [errorFirstName, setErrorFirstName] = useState<boolean>(false);
  const [errorLastName, setErrorLastName] = useState<boolean>(false);
  const [errorPhoneNumber, setErrorPhoneNumber] = useState<boolean>(false);
  const [errorSecondaryPhoneNumber, setErrorSecondaryPhoneNumber] = useState<boolean>(false);
  const [errorBuyerLoanOfficerPhone, setErrorBuyerLoanOfficerPhone] = useState<boolean>(false);
  const [errorEmail, setErrorEmail] = useState<boolean>(false);
  const [errorSecondaryEmail, setErrorSecondaryEmail] = useState<boolean>(false);
  const [errorBuyerLoanOfficerEmail, setErrorBuyerLoanOfficerEmail] = useState<boolean>(false);

  const changePropertyType = (value: any) => {
    handleSelectChange(value, "buyerPropertyType")
    SubTypeList.map(item => {
      if (item.parent === value.value) {
        setPropertySubType(item.contents)
      }
    })
  }

  const handleInputChange = (e: any, type: string = "") => {
    const { name, value } = e.target;
    let tempValue = value;

    if (name.includes("Name") && name !== "companyName") {
      tempValue = value.replace(/[^a-z]/gi, "");
    }

    if (type === "number") {
      tempValue = value.replace(/,/g, "");
    }
    setValues({
      ...values,
      [name]: tempValue,
    });
    if (
      name === "phoneNumber" ||
      name === "buyerLoanOfficerPhone" ||
      name === "secondaryPhoneNumber"
    ) {
      const formattedPhoneNumber = validation.phoneNumberAutoFormat(value);
      if (
        name === "phoneNumber" &&
        formattedPhoneNumber.length > 0 &&
        formattedPhoneNumber.length < 12
      )
        setErrorPhoneNumber(true);
      else setErrorPhoneNumber(false);
      if (
        name === "secondaryPhoneNumber" &&
        formattedPhoneNumber.length > 0 &&
        formattedPhoneNumber.length < 12
      )
        setErrorSecondaryPhoneNumber(true);
      else setErrorSecondaryPhoneNumber(false);
      if (
        name === "buyerLoanOfficerPhone" &&
        formattedPhoneNumber.length > 0 &&
        formattedPhoneNumber.length < 12
      )
        setErrorBuyerLoanOfficerPhone(true);
      else setErrorBuyerLoanOfficerPhone(false);
      setValues({
        ...values,
        [name]: formattedPhoneNumber,
      });
    }
    switch (name) {
      case "firstName":
        setErrorFirstName(validation.IsEmptyString(value));
        break;
      case "lastName":
        setErrorLastName(validation.IsEmptyString(value));
        break;
      case "email":
        setErrorEmail(validation.IsInvalidEmail(value) && value.length > 0);
        break;
      case "secondaryEmailAddress":
        setErrorSecondaryEmail(
          validation.IsInvalidEmail(value) && value.length > 0
        );
        break;
      case "buyerLoanOfficerEmail":
        setErrorBuyerLoanOfficerEmail(
          validation.IsInvalidEmail(value) && value.length > 0
        );
        break;
    }
  };

  const handleSelectChange = (value: any, name: string) => {
    let tempValue = value.label;
    if (name.includes("Multi")) {
      tempValue = value.map((item: any) => {
        return item.label;
      });
    }
    console.log(name, tempValue);
    setValues({
      ...values,
      [name]: tempValue,
    });
  };

  const onInputChangeAutoComplete = (value: any, name: string) => {
    if (name === "buyerKeywordsMulti") {
      setSearchKeyword(value);
      dispatch(fetchKeywords({ query: value }))
    } else if (name === "buyerAmenitiesMulti") {
      setSearchAmenities(value)
      dispatch(fetchAmenities({ query: value }))
    } else if (name === "buyerLocationsMulti") {
      dispatch(fetchAddressAutocomplete({ address: value }))
    } else {
      dispatch(fetchSchoolDistricts({ query: value }))
    }
  }

  const handleDateChange = (value: any, name: string) => {
    console.log(
      name,
      value,
      new Date(value).toUTCString(),
      new Date(value).toISOString()
    );

    setValues({
      ...values,
      [name]: value,
    });
  };

  const submitNewLead = () => {
    setErrorFirstName(validation.IsEmptyString(values.firstName));
    setErrorLastName(validation.IsEmptyString(values.lastName));

    if (
      !validation.IsEmptyString(values.firstName) &&
      !validation.IsEmptyString(values.lastName) &&
      !errorPhoneNumber &&
      !errorEmail
    ) {
      if (values.leadType === "Buyer" && errorBuyerLoanOfficerPhone) return;
      if (values.leadType === "Buyer" && errorBuyerLoanOfficerEmail) return;
      let submitValues: ILead = { ...values };
      if (props.data && props.data._id) {
        let updateData: UpdateLeadDto = {
          data: submitValues,
          leadId: props.data._id,
          userId: user._id,
          search: {
            userId: user._id,
            keyword: props.keyword,
            sortType: props.sortType,
            sortField: props.sortField,
            recordsPerPage: props.recordsPerPage,
            currentPage: props.currentPage,
          },
        };
        dispatch(updateLead(updateData)).then((res) => {
          try {
            props.setTotalCount(res.payload.totalPages);
            notify(res.payload.success, res.payload.message);
            props.changeState(false);
          } catch (e) {
            notify(false, "Something went wrong.");
          }
        });
      } else {
        let data: CreateLeadDto = {
          email: user.email,
          data: submitValues,
          userId: user._id,
          search: {
            userId: user._id,
            keyword: props.keyword,
            sortType: props.sortType,
            sortField: props.sortField,
            recordsPerPage: props.recordsPerPage,
            currentPage: 1,
          },
        };
        props.setCurrentPage(1);
        dispatch(createNewLead(data)).then((res) => {
          try {
            notify(res.payload.success, res.payload.message);
            if (res.payload.success) {
              setValues(initialLead);
              setErrorFirstName(false);
              setErrorLastName(false);
              props.setTotalCount(res.payload.totalCount);
              props.changeState(false);
            }
          } catch (e) {
            notify(false, "Something went wrong.");
          }
        });
      }
    } else {
      notify(false, "Please fill up required fields.");
    }
  };

  const onChangeAddressAutoComplete = (value: any, name: string) => {
    setValues({
      ...values,
      [name]: value,
    });
    dispatch(fetchAddressAutocomplete({ address: value }));
  };

  const onSelectAddressAutoComplete = (value: any, name: string) => {
    let address: IAddress = {} as IAddress;

    if (value.fullAddress) {
      address = {
        streetLine: value.addressOne,
        secondary: value.addressTwo,
        city: value.city,
        state: value.state,
        zipcode: value.zipcode,
      };
    } else {
      address = { ...value };
    }

    if (name === "sellerPropertyAddress") {
      setValues({
        ...values,
        [name]: address,
        sellerUnit: address.secondary as string,
      });
    } else if (name === "address") {
      setValues({
        ...values,
        [name]: address,
        emailAddress: buyerToggleSameAddress ? address : values.emailAddress,
      });
    } else {
      setValues({
        ...values,
        [name]: address,
      });
    }
  };

  useEffect(() => {
    drawerScrollbar?.current?.scrollTop(0);
    if (typeof (values.address) === "string") {
      setValues({
        ...values,
        address: {} as IAddress
      })
    }
  }, [currentStep]);

  useEffect(() => {
    setValues(initialLead);
    setErrorFirstName(false);
    setErrorLastName(false);
    if (props.open) setCurrentStep(1);
  }, [props.open]);

  return (
    <Transition.Root show={props.open} as={Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 z-30"
        onClose={() => props.changeState(false)}
      >
        {/* The backdrop, rendered as a fixed sibling to the panel container */}
        <div className="fixed inset-0 bg-black/30" aria-hidden="true" />

        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <div className="pointer-events-none fixed inset-y-0 right-0 flex md:pl-10">
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-300"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-300"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <Dialog.Panel className="pointer-events-auto w-screen md:max-w-[600px] sm:max-w-full bg-white shadow-xl py-6 max-sm:pb-28">
                  <div className="flex items-center justify-between md:px-9 sm:px-5 max-sm:px-5">
                    <div className="flex items-center gap-[10px] justify-center">
                      <Typography
                        variant="h2"
                        color="primary"
                        className="my-0"
                      >
                        {props.data && props.data._id
                          ? "Edit a Lead"
                          : "Add a New Lead"}
                      </Typography>
                    </div>
                    <div className="flex gap-3 items-center">
                      <Button variant="icon" className="group !p-1" onClick={() => props.changeState(false)}>
                        <IconWrapper name="close" className="cursor-pointer group-hover:[&>svg>path]:!stroke-[#9333EA]" />
                      </Button>
                    </div>
                  </div>
                  <Scrollbars ref={drawerScrollbar} autoHide>
                    <div className="md:px-9 sm:px-5 max-sm:px-5 pb-12">
                      <div className="flex justify-center w-full mt-5">
                        <nav className="flex justify-center w-full pb-3" aria-label="Tabs">
                          <Scrollbars autoHide autoHeight={true} renderThumbHorizontal={() => { return <div></div> }}>
                            <div className="flex justify-between w-full py-2">
                              {(values.leadType === "Buyer" ? buyerTabs : sellerTabs).map((tab) => (
                                <div
                                  key={tab.name}
                                  className={`${tab.step <= currentStep
                                    ? (tab.step < currentStep ? "text-indigo-300" : "text-indigo-600") + " border-[#4F46E5]"
                                    : "text-gray-400 border-gray-300 [&>div>svg>path]:!fill-gray-400"}
                                      whitespace-nowrap cursor-pointer flex justify-center first:justify-start first:pl-0 last:justify-end last:pr-0 w-full gap-1 items-center border-t-2 px-3 py-1
                                  `}
                                  onClick={() => setCurrentStep(tab.step)}
                                >
                                  <IconWrapper name="indicator" width={18} className={tab.step < currentStep ? "[&>svg>path]:!fill-indigo-300" : ""} />
                                  <Typography variant="medium-text">{tab.name}</Typography>
                                </div>
                              ))}
                            </div>
                          </Scrollbars>
                        </nav>
                      </div>
                      {currentStep === 1 && (
                        <div className="mb-5">
                          <div className="py-3">
                            <Typography variant="h4" color="primary" className="mb-2">General Details</Typography>

                          </div>
                          <div className="flex gap-2 py-3">
                            <label className="flex flex-col w-full px-3 py-4 border-1 border-[#E5E7EB] rounded-lg cursor-pointer">
                              <div className="flex justify-between items-center">
                                <Typography variant="body" color="primary" className="text-18">Seller</Typography>
                                <input
                                  type="radio"
                                  name="leadType"
                                  value="Seller"
                                  checked={values.leadType === "Seller"}
                                  className="h-4 w-4 rounded-sm border-gray-200"
                                  onChange={(event) => handleInputChange(event)}
                                />
                              </div>
                              <Typography variant="body" color="secondary">Description option</Typography>
                            </label>
                            <label className="flex flex-col w-full px-3 py-4 border-1 border-[#E5E7EB] rounded-lg cursor-pointer">
                              <div className="flex justify-between items-center">
                                <Typography variant="body" color="primary" className="text-18">Buyer</Typography>
                                <input
                                  type="radio"
                                  name="leadType"
                                  value="Buyer"
                                  checked={values.leadType === "Buyer"}
                                  className="h-4 w-4 rounded-sm border-gray-200"
                                  onChange={(event) => handleInputChange(event)}
                                />
                              </div>
                              <Typography variant="body" color="secondary">Description option</Typography>
                            </label>
                          </div>
                          <div className="py-3">
                            <div className="flex justify-between mb-2">
                              <Typography variant="body" color="tertiary">About the Lead</Typography>
                              <Typography variant="body" color="tertiary">{values.about.length}/300</Typography>
                            </div>
                            <Textarea maxLength={300} rows={5} textareaClassName="resize-none" name="about" value={values.about} onChange={(event) => handleInputChange(event)}></Textarea>
                          </div>
                          <div className="flex gap-4 py-3">
                            <div className="w-full">
                              <Typography variant="body" color="tertiary" className="mb-2">Lead Status</Typography>
                              <Select
                                options={[
                                  { value: 'Active', label: 'Active' },
                                  { value: 'Inactive', label: 'Inactive' },
                                ]}
                                name="leadStatus"
                                value={{ value: values.leadStatus, label: values.leadStatus }}
                                onChange={(value) => handleSelectChange(value, "leadStatus")}
                              />
                            </div>
                            <div className="w-full">
                              <Typography variant="body" color="tertiary" className="mb-2">Lead Source</Typography>
                              <Select
                                options={[
                                  { value: 'Real Estate Platform', label: 'Real Estate Platform' },
                                  { value: 'Social Network', label: 'Social Network' },
                                  { value: 'Outreach', label: 'Outreach' },
                                  { value: 'Referral', label: 'Referral' },
                                  { value: 'Advertising', label: 'Advertising' },
                                  { value: 'Friends and Family', label: 'Friends and Family' },
                                  { value: 'Open House', label: 'Open House' },
                                ]}
                                name="leadSource"
                                value={{ value: values.leadSource, label: values.leadSource }}
                                onChange={(value) => handleSelectChange(value, "leadSource")}
                              />
                            </div>
                          </div>
                        </div>
                      )}
                      {currentStep === 2 && (
                        <div className="mb-5">
                          <div className="py-3">
                            <Typography variant="h4" color="primary" className="mb-2">Primary {values.leadType} Details</Typography>

                          </div>
                          <div className="flex gap-4 py-3">
                            <div className="w-full">
                              <Typography variant="body" color="tertiary" className="mb-2">First Name <span className="text-rose-600">*</span></Typography>
                              <TextField isInvalid={errorFirstName} name="firstName" value={values.firstName} onChange={(event) => handleInputChange(event)} />
                              {errorFirstName && <Typography variant='caption' className='text-rose-600 absolute mt-[2px]'>This field is required</Typography>}
                            </div>
                            <div className="w-full">
                              <Typography variant="body" color="tertiary" className="mb-2">Last Name <span className="text-rose-600">*</span></Typography>
                              <TextField isInvalid={errorLastName} name="lastName" value={values.lastName} onChange={(event) => handleInputChange(event)} />
                              {errorLastName && <Typography variant='caption' className='text-rose-600 absolute mt-[2px]'>This field is required</Typography>}
                            </div>
                          </div>
                          <div className="flex gap-4 py-3">
                            <div className="w-full">
                              <Typography variant="body" color="tertiary" className="mb-2">Phone Number</Typography>
                              <TextField maxLength={12} isInvalid={errorPhoneNumber} name="phoneNumber" value={values.phoneNumber} onChange={(event) => handleInputChange(event)} />
                              {errorPhoneNumber && <Typography variant='caption' className='text-rose-600 absolute mt-[2px]'>Please enter a valid phone number</Typography>}
                            </div>
                            <div className="w-full">
                              <Typography variant="body" color="tertiary" className="mb-2">Email Address</Typography>
                              <TextField isInvalid={errorEmail} name="email" value={values.email} onChange={(event) => handleInputChange(event)} />
                              {errorEmail && <Typography variant='caption' className='text-rose-600 absolute mt-[2px]'>Invalid Email Address</Typography>}
                            </div>
                          </div>
                          <div className="flex gap-4 py-3">
                            <div className="w-full">
                              <Typography variant="body" color="tertiary" className="mb-2">Company</Typography>
                              <TextField name="companyName" value={values.companyName} onChange={(event) => handleInputChange(event)} />
                            </div>
                          </div>
                          <div className="flex gap-4 py-3">
                            <div className="w-full">
                              <Typography variant="body" color="tertiary" className="mb-2">Current Address</Typography>
                              <AddressAutoComplete options={addresses} placeholder="Enter Lead's Current Address" value={formatAddress(values.address)} onChange={(e) => onChangeAddressAutoComplete(e, "address")} onAllChange={(e) => onSelectAddressAutoComplete(e, "address")} />
                            </div>
                          </div>
                          <div className="flex flex-col w-full py-3">
                            <Typography variant="body" color="tertiary" className="mb-2">Mailing Address</Typography>
                            <label className="flex gap-2 items-center w-full mb-2">
                              <input
                                type="checkbox"
                                className="h-4 w-4 rounded-sm border-gray-200"
                                checked={buyerToggleSameAddress}
                                onChange={() => {
                                  setValues({
                                    ...values,
                                    emailAddress: !buyerToggleSameAddress ? values.address : {} as IAddress,
                                  });
                                  setBuyerToggleSameAddress(!buyerToggleSameAddress);
                                }}
                              />
                              <Typography variant="body" color="tertiary" className="select-none">Same as current address</Typography>
                            </label>
                            <AddressAutoComplete className={buyerToggleSameAddress ? `invisible` : ``} options={addresses} placeholder="Enter Lead's Mailing Address" value={formatAddress(values.emailAddress)} onChange={(e) => onChangeAddressAutoComplete(e, "emailAddress")} onAllChange={(e) => onSelectAddressAutoComplete(e, "emailAddress")} />
                          </div>
                          <div className="py-3">
                            <Typography variant="h4" color="primary" className="mb-2">Secondary {values.leadType} Details</Typography>

                          </div>
                          <div className="flex gap-4 py-3">
                            <div className="w-full">
                              <Typography variant="body" color="tertiary" className="mb-2">First Name</Typography>
                              <TextField name="secondaryFirstName" value={values.secondaryFirstName} onChange={(event) => handleInputChange(event)} />
                            </div>
                            <div className="w-full">
                              <Typography variant="body" color="tertiary" className="mb-2">Last Name</Typography>
                              <TextField name="secondaryLastName" value={values.secondaryLastName} onChange={(event) => handleInputChange(event)} />
                            </div>
                          </div>
                          <div className="flex gap-4 py-3">
                            <div className="w-full">
                              <Typography variant="body" color="tertiary" className="mb-2">Phone Number</Typography>
                              <TextField isInvalid={errorSecondaryPhoneNumber} maxLength={12} name="secondaryPhoneNumber" value={values.secondaryPhoneNumber} onChange={(event) => handleInputChange(event)} />
                              {errorSecondaryPhoneNumber && <Typography variant='caption' className='text-rose-600 absolute mt-[2px]'>Please enter a valid phone number</Typography>}
                            </div>
                            <div className="w-full">
                              <Typography variant="body" color="tertiary" className="mb-2">Email Address</Typography>
                              <TextField isInvalid={errorSecondaryEmail} name="secondaryEmailAddress" value={values.secondaryEmailAddress} onChange={(event) => handleInputChange(event)} />
                              {errorSecondaryEmail && <Typography variant='caption' className='text-rose-600 absolute mt-[2px]'>Invalid Email Address</Typography>}
                            </div>
                          </div>
                        </div>
                      )}
                      {currentStep === 3 && (
                        <div className="mb-5">
                          <div className="py-3">
                            <Typography variant="h4" color="primary" className="mb-2">{values.leadType === "Buyer" ? "Buyer Preferences" : "Property Details"}</Typography>

                          </div>
                          {values.leadType === "Buyer" ? (
                            <>
                              <div className="flex gap-4 py-3">
                                <div className="w-full">
                                  <Typography variant="body" color="tertiary" className="mb-2">Locations</Typography>
                                  <Select
                                    options={addresses && addresses.map(item => { return { value: item.city + ", " + item.state + " " + item.zipcode, label: item.city + ", " + item.state + " " + item.zipcode } })
                                    }
                                    name="buyerLocationsMulti"
                                    isMulti={true}
                                    value={values.buyerLocationsMulti.map((item: any) => {
                                      return {
                                        value: item,
                                        label: item
                                      }
                                    })}
                                    onChange={(value) => handleSelectChange(value, "buyerLocationsMulti")}
                                    onInputChange={(value) => onInputChangeAutoComplete(value, "buyerLocationsMulti")}
                                  />
                                </div>
                              </div>
                              <div className="flex gap-4 py-3">
                                <div className="w-full">
                                  <Typography variant="body" color="tertiary" className="mb-2">School Districts</Typography>
                                  <Select
                                    options={schoolDistricts && schoolDistricts.map(item => { return { value: item.districtIDNumber + "-" + item.countyNames, label: item.schoolDistrictName + "-" + item.countyNames } })
                                    }
                                    name="buyerSchoolDistrictsMulti"
                                    isMulti={true}
                                    value={values.buyerSchoolDistrictsMulti.map((item: any) => {
                                      return {
                                        value: item,
                                        label: item
                                      }
                                    })}
                                    onChange={(value) => handleSelectChange(value, "buyerSchoolDistrictsMulti")}
                                    onInputChange={(value) => onInputChangeAutoComplete(value, "buyerSchoolDistrictsMulti")}
                                  />
                                </div>
                              </div>
                              <div className="flex gap-4 py-3">
                                <div className="w-full">
                                  <Typography variant="body" color="tertiary" className="mb-2">Property Type</Typography>
                                  <Select
                                    options={propertyType}
                                    name="buyerPropertyType"
                                    value={{ value: values.buyerPropertyType, label: values.buyerPropertyType }}
                                    onChange={(value) => changePropertyType(value)}
                                  />
                                </div>
                                <div className="w-full">
                                  <Typography variant="body" color="tertiary" className="mb-2">Property Sub-Type</Typography>
                                  <Select
                                    options={propertySubType}
                                    name="buyerPropertySubType"
                                    value={{ value: values.buyerPropertySubType, label: values.buyerPropertySubType }}
                                    onChange={(value) => handleSelectChange(value, "buyerPropertySubType")}
                                  />
                                </div>
                              </div>
                              <div className="flex gap-4 py-3">
                                <div className="w-full">
                                  <Typography variant="body" color="tertiary" className="mb-2">Unit Count</Typography>
                                  <NumericFormat
                                    allowLeadingZeros
                                    thousandSeparator=","
                                    name="buyerUnitCount"
                                    value={values.buyerUnitCount ? parseFloat(values.buyerUnitCount.toString()).toLocaleString("en") : ""} onChange={(e) => handleInputChange(e, "number")}
                                    className="body h-[42px] bg-netural rounded-sm w-full border-transparent focus:!border-[#4C42D7] focus:!ring-2 !ring-[#4C42D7]/25"
                                  />
                                </div>
                                <div className="w-full">
                                  <Typography variant="body" color="tertiary" className="mb-2">Stories</Typography>
                                  <NumericFormat
                                    allowLeadingZeros
                                    thousandSeparator=","
                                    name="buyerStories"
                                    value={values.buyerStories ? parseFloat(values.buyerStories.toString()).toLocaleString("en") : ""} onChange={(e) => handleInputChange(e, "number")}
                                    className="body h-[42px] bg-netural rounded-sm w-full border-transparent focus:!border-[#4C42D7] focus:!ring-2 !ring-[#4C42D7]/25"
                                  />
                                </div>
                              </div>
                              <div className="flex gap-4 py-3">
                                <div className="w-full">
                                  <Typography variant="body" color="tertiary" className="mb-2">Minimum Home Size (SqFt)</Typography>
                                  <NumericFormat
                                    allowLeadingZeros
                                    thousandSeparator=","
                                    name="buyerMinimumHomeSqFt"
                                    value={values.buyerMinimumHomeSqFt ? parseFloat(values.buyerMinimumHomeSqFt.toString()).toLocaleString("en") : ""} onChange={(e) => handleInputChange(e, "number")}
                                    className="body h-[42px] bg-netural rounded-sm w-full border-transparent focus:!border-[#4C42D7] focus:!ring-2 !ring-[#4C42D7]/25"
                                  />
                                </div>
                                <div className="w-full">
                                  <Typography variant="body" color="tertiary" className="mb-2">Minimum Lot Size (SqFt)</Typography>
                                  <NumericFormat
                                    allowLeadingZeros
                                    thousandSeparator=","
                                    name="buyerMinimumLotSqFt"
                                    value={values.buyerMinimumLotSqFt ? parseFloat(values.buyerMinimumLotSqFt.toString()).toLocaleString("en") : ""} onChange={(e) => handleInputChange(e, "number")}
                                    className="body h-[42px] bg-netural rounded-sm w-full border-transparent focus:!border-[#4C42D7] focus:!ring-2 !ring-[#4C42D7]/25"
                                  />
                                </div>
                              </div>
                              <div className="py-3">
                                <Typography variant="h4" color="primary">Property Features</Typography>
                              </div>
                              <div className="flex gap-4 py-3">
                                <div className="w-full">
                                  <Typography variant="body" color="tertiary" className="mb-2">Minimum Bedrooms</Typography>
                                  <NumericFormat
                                    allowLeadingZeros
                                    thousandSeparator=","
                                    name="buyerMinimumBedrooms"
                                    value={values.buyerMinimumBedrooms ? parseFloat(values.buyerMinimumBedrooms.toString()).toLocaleString("en") : ""} onChange={(e) => handleInputChange(e, "number")}
                                    className="body h-[42px] bg-netural rounded-sm w-full border-transparent focus:!border-[#4C42D7] focus:!ring-2 !ring-[#4C42D7]/25"
                                  />
                                </div>
                                <div className="w-full">
                                  <Typography variant="body" color="tertiary" className="mb-2">Minimum Bathrooms</Typography>
                                  <NumericFormat
                                    allowLeadingZeros
                                    thousandSeparator=","
                                    name="buyerMinimumBathrooms"
                                    value={values.buyerMinimumBathrooms ? parseFloat(values.buyerMinimumBathrooms.toString()).toLocaleString("en") : ""} onChange={(e) => handleInputChange(e, "number")}
                                    className="body h-[42px] bg-netural rounded-sm w-full border-transparent focus:!border-[#4C42D7] focus:!ring-2 !ring-[#4C42D7]/25"
                                  />
                                </div>
                              </div>
                              <div className="flex gap-4 py-3">
                                <div className="w-full">
                                  <Typography variant="body" color="tertiary" className="mb-2">Parking</Typography>
                                  <div className="flex gap-8">
                                    <label className="flex gap-2 items-center">
                                      <input
                                        type="radio"
                                        name="buyerParking"
                                        value="Yes"
                                        checked={values.buyerParking === "Yes" ? true : false}
                                        className="h-5 w-5 rounded-full border-gray-200"
                                        onChange={(e) => handleInputChange(e)}
                                      />
                                      <Typography variant="body" color={values.buyerParking === "Yes" ? `primary` : `tertiary`}>Yes</Typography>
                                    </label>
                                    <label className="flex gap-2 items-center">
                                      <input
                                        type="radio"
                                        name="buyerParking"
                                        value="No"
                                        checked={values.buyerParking === "No" ? true : false}
                                        className="h-5 w-5 rounded-full border-gray-200"
                                        onChange={(e) => handleInputChange(e)}
                                      />
                                      <Typography variant="body" color={values.buyerParking === "No" ? `primary` : `tertiary`}>No</Typography>
                                    </label>
                                  </div>
                                </div>
                                <div className="w-full">
                                  <Typography variant="body" color="tertiary" className="mb-2">Garage</Typography>
                                  <div className="flex gap-8">
                                    <label className="flex gap-2 items-center">
                                      <input
                                        type="radio"
                                        name="buyerGarage"
                                        value="Yes"
                                        checked={values.buyerGarage === "Yes" ? true : false}
                                        className="h-5 w-5 rounded-full border-gray-200"
                                        onChange={(e) => handleInputChange(e)}
                                      />
                                      <Typography variant="body" color={values.buyerGarage === "Yes" ? `primary` : `tertiary`}>Yes</Typography>
                                    </label>
                                    <label className="flex gap-2 items-center">
                                      <input
                                        type="radio"
                                        name="buyerGarage"
                                        value="No"
                                        checked={values.buyerGarage === "No" ? true : false}
                                        className="h-5 w-5 rounded-full border-gray-200"
                                        onChange={(e) => handleInputChange(e)}
                                      />
                                      <Typography variant="body" color={values.buyerGarage === "No" ? `primary` : `tertiary`}>No</Typography>
                                    </label>
                                  </div>
                                </div>
                              </div>
                              <div className="flex gap-4 py-3">
                                <div className="w-full">
                                  <Typography variant="body" color="tertiary" className="mb-2">Heating</Typography>
                                  <div className="flex gap-8">
                                    <label className="flex gap-2 items-center">
                                      <input
                                        type="radio"
                                        name="buyerHeating"
                                        value="Yes"
                                        checked={values.buyerHeating === "Yes" ? true : false}
                                        className="h-5 w-5 rounded-full border-gray-200"
                                        onChange={(e) => handleInputChange(e)}
                                      />
                                      <Typography variant="body" color={values.buyerHeating === "Yes" ? `primary` : `tertiary`}>Yes</Typography>
                                    </label>
                                    <label className="flex gap-2 items-center">
                                      <input
                                        type="radio"
                                        name="buyerHeating"
                                        value="No"
                                        checked={values.buyerHeating === "No" ? true : false}
                                        className="h-5 w-5 rounded-full border-gray-200"
                                        onChange={(e) => handleInputChange(e)}
                                      />
                                      <Typography variant="body" color={values.buyerHeating === "No" ? `primary` : `tertiary`}>No</Typography>
                                    </label>
                                  </div>
                                </div>
                                <div className="w-full">
                                  <Typography variant="body" color="tertiary" className="mb-2">Cooling</Typography>
                                  <div className="flex gap-8">
                                    <label className="flex gap-2 items-center">
                                      <input
                                        type="radio"
                                        name="buyerCooling"
                                        value="Yes"
                                        checked={values.buyerCooling === "Yes" ? true : false}
                                        className="h-5 w-5 rounded-full border-gray-200"
                                        onChange={(e) => handleInputChange(e)}
                                      />
                                      <Typography variant="body" color={values.buyerCooling === "Yes" ? `primary` : `tertiary`}>Yes</Typography>
                                    </label>
                                    <label className="flex gap-2 items-center">
                                      <input
                                        type="radio"
                                        name="buyerCooling"
                                        value="No"
                                        checked={values.buyerCooling === "No" ? true : false}
                                        className="h-5 w-5 rounded-full border-gray-200"
                                        onChange={(e) => handleInputChange(e)}
                                      />
                                      <Typography variant="body" color={values.buyerCooling === "No" ? `primary` : `tertiary`}>No</Typography>
                                    </label>
                                  </div>
                                </div>
                              </div>
                              <div className="flex gap-4 py-3">
                                <div className="w-1/2">
                                  <Typography variant="body" color="tertiary" className="mb-2">Heating Type</Typography>
                                  <Select
                                    options={[
                                      { value: "Active Solar", label: "Active Solar" },
                                      { value: "Baseboard", label: "Baseboard" },
                                      { value: "Ceiling", label: "Ceiling" },
                                      { value: "Central", label: "Central" },
                                      { value: "Coal", label: "Coal" },
                                      { value: "Coal Stove", label: "Coal Stove" },
                                      { value: "Ductless (Heating)", label: "Ductless (Heating)" },
                                      { value: "Electric (Heating)", label: "Electric (Heating)" },
                                      { value: "ENERGY STAR/ACCA RSI Qualified Installation", label: "ENERGY STAR/ACCA RSI Qualified Installation" },
                                      { value: "ENERGY STAR Qualified Equipment (Heating)", label: "ENERGY STAR Qualified Equipment (Heating)" },
                                      { value: "Exhaust Fan (Heating)", label: "Exhaust Fan (Heating)" },
                                      { value: "Fireplace(s)", label: "Fireplace(s)" },
                                      { value: "Fireplace Insert", label: "Fireplace Insert" },
                                      { value: "Floor Furnace", label: "Floor Furnace" },
                                      { value: "Forced Air", label: "Forced Air" },
                                      { value: "Geothermal (Heating)", label: "Geothermal (Heating)" },
                                      { value: "Gravity", label: "Gravity" },
                                      { value: "Heat Pump (Heating)", label: "Heat Pump (Heating)" },
                                      { value: "Hot Water (Heating)", label: "Hot Water (Heating)" },
                                      { value: "Humidity Control (Heating)", label: "Humidity Control (Heating)" },
                                      { value: "Kerosene", label: "Kerosene" },
                                      { value: "Natural Gas", label: "Natural Gas" },
                                      { value: "None (Heating)", label: "None (Heating)" },
                                      { value: "Oil", label: "Oil" },
                                      { value: "Other (Heating)", label: "Other (Heating)" },
                                      { value: "Passive Solar", label: "Passive Solar" },
                                      { value: "Pellet Stove (Heating)", label: "Pellet Stove (Heating)" },
                                      { value: "Propane (Heating)", label: "Propane (Heating)" },
                                      { value: "Propane Stove", label: "Propane Stove" },
                                      { value: "Radiant", label: "Radiant" },
                                      { value: "Radiant Ceiling", label: "Radiant Ceiling" },
                                      { value: "Radiant Floor", label: "Radiant Floor" },
                                      { value: "See Remarks (Heating)", label: "See Remarks (Heating)" },
                                      { value: "Separate Meters (Heating)", label: "Separate Meters (Heating)" },
                                      { value: "Solar (Heating)", label: "Solar (Heating)" },
                                      { value: "Space Heater", label: "Space Heater" },
                                      { value: "Steam", label: "Steam" },
                                      { value: "Varies by Unit (Heating)", label: "Varies by Unit (Heating)" },
                                      { value: "Wall Furnace", label: "Wall Furnace" },
                                      { value: "Wood (Heating)", label: "Wood (Heating)" },
                                      { value: "Wood Stove", label: "Wood Stove" },
                                      { value: "Zoned (Heating)", label: "Zoned (Heating)" },
                                    ]}
                                    name="buyerHeatingTypeMulti"
                                    isMulti={true}
                                    value={values.buyerHeatingTypeMulti.map((item: any) => {
                                      return {
                                        value: item,
                                        label: item
                                      }
                                    })}
                                    onChange={(value) => handleSelectChange(value, "buyerHeatingTypeMulti")}
                                  />
                                </div>
                                <div className="w-1/2">
                                  <Typography variant="body" color="tertiary" className="mb-2">Cooling Type</Typography>
                                  <Select
                                    options={[
                                      { value: "Attic Fan", label: "Attic Fan" },
                                      { value: "Ceiling Fan(s)", label: "Ceiling Fan(s)" },
                                      { value: "Central Air", label: "Central Air" },
                                      { value: "Dual", label: "Dual" },
                                      { value: "Ductless", label: "Ductless" },
                                      { value: "Electric", label: "Electric" },
                                      { value: "ENERGY STAR Qualified Equipment", label: "ENERGY STAR Qualified Equipment" },
                                      { value: "Evaporative Cooling", label: "Evaporative Cooling" },
                                      { value: "Exhaust Fan (Cooling)", label: "Exhaust Fan (Cooling)" },
                                      { value: "Gas (Cooling)", label: "Gas (Cooling)" },
                                      { value: "Geothermal", label: "Geothermal" },
                                      { value: "Heat Pump", label: "Heat Pump" },
                                      { value: "Humidity Control", label: "Humidity Control" },
                                      { value: "Multi Units", label: "Multi Units" },
                                      { value: "None (Cooling)", label: "None (Cooling)" },
                                      { value: "Other (Cooling)", label: "Other (Cooling)" },
                                      { value: "Roof Turbine(s)", label: "Roof Turbine(s)" },
                                      { value: "Separate Meters", label: "Separate Meters" },
                                      { value: "Varies by Unit", label: "Varies by Unit" },
                                      { value: "Wall/Window Unit(s)", label: "Wall/Window Unit(s)" },
                                      { value: "Wall Unit(s)", label: "Wall Unit(s)" },
                                      { value: "Whole House Fan", label: "Whole House Fan" },
                                      { value: "Window Unit(s)", label: "Window Unit(s)" },
                                    ]}
                                    isMulti={true}
                                    name="buyerCoolingTypeMulti"
                                    value={values.buyerCoolingTypeMulti.map((item: any) => {
                                      return {
                                        value: item,
                                        label: item
                                      }
                                    })}
                                    onChange={(value) => handleSelectChange(value, "buyerCoolingTypeMulti")}
                                  />
                                </div>
                              </div>
                              <div className="flex gap-4 py-3">
                                <div className="w-full">
                                  <Typography variant="body" color="tertiary" className="mb-2">Pool</Typography>
                                  <div className="flex gap-8">
                                    <label className="flex gap-2 items-center">
                                      <input
                                        type="radio"
                                        name="buyerPool"
                                        value="Yes"
                                        checked={values.buyerPool === "Yes" ? true : false}
                                        className="h-5 w-5 rounded-full border-gray-200"
                                        onChange={(e) => handleInputChange(e)}
                                      />
                                      <Typography variant="body" color={values.buyerPool === "Yes" ? `primary` : `tertiary`}>Yes</Typography>
                                    </label>
                                    <label className="flex gap-2 items-center">
                                      <input
                                        type="radio"
                                        name="buyerPool"
                                        value="No"
                                        checked={values.buyerPool === "No" ? true : false}
                                        className="h-5 w-5 rounded-full border-gray-200"
                                        onChange={(e) => handleInputChange(e)}
                                      />
                                      <Typography variant="body" color={values.buyerPool === "No" ? `primary` : `tertiary`}>No</Typography>
                                    </label>
                                  </div>
                                </div>
                                <div className="w-full">
                                  <Typography variant="body" color="tertiary" className="mb-2">Is Attached?</Typography>
                                  <div className="flex gap-8">
                                    <label className="flex gap-2 items-center">
                                      <input
                                        type="radio"
                                        name="buyerIsAttached"
                                        value="Yes"
                                        checked={values.buyerIsAttached === "Yes" ? true : false}
                                        className="h-5 w-5 rounded-full border-gray-200"
                                        onChange={(e) => handleInputChange(e)}
                                      />
                                      <Typography variant="body" color={values.buyerIsAttached === "Yes" ? `primary` : `tertiary`}>Yes</Typography>
                                    </label>
                                    <label className="flex gap-2 items-center">
                                      <input
                                        type="radio"
                                        name="buyerIsAttached"
                                        value="No"
                                        checked={values.buyerIsAttached === "No" ? true : false}
                                        className="h-5 w-5 rounded-full border-gray-200"
                                        onChange={(e) => handleInputChange(e)}
                                      />
                                      <Typography variant="body" color={values.buyerIsAttached === "No" ? `primary` : `tertiary`}>No</Typography>
                                    </label>
                                  </div>
                                </div>
                              </div>
                              <div className="flex gap-4 py-3">
                                <div className="w-full">
                                  <Typography variant="body" color="tertiary" className="mb-2">New Construction</Typography>
                                  <div className="flex gap-8">
                                    <label className="flex gap-2 items-center">
                                      <input
                                        type="radio"
                                        name="buyerNewConstruction"
                                        value="Yes"
                                        checked={values.buyerNewConstruction === "Yes" ? true : false}
                                        className="h-5 w-5 rounded-full border-gray-200"
                                        onChange={(e) => handleInputChange(e)}
                                      />
                                      <Typography variant="body" color={values.buyerNewConstruction === "Yes" ? `primary` : `tertiary`}>Yes</Typography>
                                    </label>
                                    <label className="flex gap-2 items-center">
                                      <input
                                        type="radio"
                                        name="buyerNewConstruction"
                                        value="No"
                                        checked={values.buyerNewConstruction === "No" ? true : false}
                                        className="h-5 w-5 rounded-full border-gray-200"
                                        onChange={(e) => handleInputChange(e)}
                                      />
                                      <Typography variant="body" color={values.buyerNewConstruction === "No" ? `primary` : `tertiary`}>No</Typography>
                                    </label>
                                  </div>
                                </div>
                              </div>
                              <div className="py-3">
                                <Typography variant="h4" color="primary">Community & Neighborhood</Typography>
                              </div>
                              <div className="flex gap-4 py-3">
                                <div className="w-full">
                                  <Typography variant="body" color="tertiary" className="mb-2">Views</Typography>
                                  <Select
                                    options={[
                                      { value: "Bay", label: "Bay" },
                                      { value: "Beach", label: "Beach" },
                                      { value: "Bridge(s)", label: "Bridge(s)" },
                                      { value: "Canal", label: "Canal" },
                                      { value: "Canyon", label: "Canyon" },
                                      { value: "City", label: "City" },
                                      { value: "City Lights", label: "City Lights" },
                                      { value: "Creek/Stream", label: "Creek/Stream" },
                                      { value: "Desert", label: "Desert" },
                                      { value: "Downtown", label: "Downtown" },
                                      { value: "Forest", label: "Forest" },
                                      { value: "Garden", label: "Garden" },
                                      { value: "Golf Course", label: "Golf Course" },
                                      { value: "Hills", label: "Hills" },
                                      { value: "Lake", label: "Lake" },
                                      { value: "Marina", label: "Marina" },
                                      { value: "Meadow", label: "Meadow" },
                                      { value: "Mountain(s)", label: "Mountain(s)" },
                                      { value: "Neighborhood", label: "Neighborhood" },
                                      { value: "None", label: "None" },
                                      { value: "Ocean", label: "Ocean" },
                                      { value: "Orchard", label: "Orchard" },
                                      { value: "Other", label: "Other" },
                                      { value: "Panoramic", label: "Panoramic" },
                                      { value: "Park/Greenbelt", label: "Park/Greenbelt" },
                                      { value: "Pasture", label: "Pasture" },
                                      { value: "Pond", label: "Pond" },
                                      { value: "Pool", label: "Pool" },
                                      { value: "Ridge", label: "Ridge" },
                                      { value: "River", label: "River" },
                                      { value: "Rural", label: "Rural" },
                                      { value: "See Remarks", label: "See Remarks" },
                                      { value: "Skyline", label: "Skyline" },
                                      { value: "Territorial", label: "Territorial" },
                                      { value: "Trees/Woods", label: "Trees/Woods" },
                                      { value: "Valley", label: "Valley" },
                                      { value: "Vineyard", label: "Vineyard" },
                                      { value: "Water", label: "Water" },

                                    ]}
                                    name="buyerViewsMulti"
                                    isMulti={true}
                                    value={values.buyerViewsMulti.map((item: any) => {
                                      return {
                                        value: item,
                                        label: item
                                      }
                                    })}
                                    onChange={(value) => handleSelectChange(value, "buyerViewsMulti")}
                                  />
                                </div>
                              </div>
                              <div className="flex gap-4 py-3">
                                <div className="w-full">
                                  <Typography variant="body" color="tertiary" className="mb-2">Amenities</Typography>
                                  <Select
                                    options={[{ value: searchAmenities, label: searchAmenities }, ...(amenities && amenities.map(item => { return { value: item.name, label: item.name } }))
                                    ]}
                                    name="buyerAmenitiesMulti"
                                    isMulti={true}
                                    value={values.buyerAmenitiesMulti.map((item: any) => {
                                      return {
                                        value: item,
                                        label: item
                                      }
                                    })}
                                    onChange={(value) => handleSelectChange(value, "buyerAmenitiesMulti")}
                                    onInputChange={(value) => onInputChangeAutoComplete(value, "buyerAmenitiesMulti")}
                                  />
                                </div>
                              </div>
                              <div className="flex gap-4 py-3">
                                <div className="w-full">
                                  <Typography variant="body" color="tertiary" className="mb-2">Keywords</Typography>
                                  <Select
                                    options={[{ value: searchKeyword, label: searchKeyword }, ...(keywords && keywords.map(item => { return { value: item.name, label: item.name } }))
                                    ]}
                                    name="buyerKeywordsMulti"
                                    isMulti={true}
                                    value={values.buyerKeywordsMulti.map((item: any) => {
                                      return {
                                        value: item,
                                        label: item
                                      }
                                    })}
                                    onChange={(value) => handleSelectChange(value, "buyerKeywordsMulti")}
                                    onInputChange={(value) => onInputChangeAutoComplete(value, "buyerKeywordsMulti")}
                                  />
                                </div>
                              </div>
                            </>
                          ) : (
                            <>
                              <div className="py-3">
                                <div className="flex justify-between mb-2">
                                  <Typography variant="body" color="tertiary">Property Description</Typography>
                                  <Typography variant="body" color="tertiary">{values.about.length}/100</Typography>
                                </div>
                                <Textarea maxLength={100} rows={5} textareaClassName="resize-none" name="sellerPropertyDescription" value={values.sellerPropertyDescription} onChange={(event) => handleInputChange(event)}></Textarea>
                              </div>
                              <div className="flex gap-4 py-3">
                                <div className="w-full relative">
                                  <Typography variant="body" color="tertiary" className="mb-2">Potential Listing Price</Typography>
                                  <NumericFormat
                                    allowLeadingZeros
                                    thousandSeparator=","
                                    name="sellerListPrice"
                                    value={values.sellerListPrice ? parseFloat(values.sellerListPrice.toString()).toLocaleString("en") : ""} onChange={(e) => handleInputChange(e, "number")}
                                    className="body h-[42px] bg-netural rounded-sm w-full border-transparent focus:!border-[#4C42D7] focus:!ring-2 !ring-[#4C42D7]/25 !pl-8"
                                  />
                                  <IconWrapper name="money" className="absolute top-[54%] left-1" width={20} />
                                </div>
                                <div className="w-full">
                                  <Typography variant="body" color="tertiary" className="mb-2">Occupancy Status</Typography>
                                  <Select
                                    options={[
                                      { value: "Owner Occupied", label: "Owner Occupied" },
                                      { value: "Tenant Occupied", label: "Tenant Occupied" },
                                      { value: "Vacant", label: "Vacant" },
                                      { value: "Unknown", label: "Unknown" },
                                      { value: "Other", label: "Other" },
                                    ]}
                                    name="sellerOccupancyStatus"
                                    value={{ value: values.sellerOccupancyStatus, label: values.sellerOccupancyStatus }}
                                    onChange={(value) => handleSelectChange(value, "sellerOccupancyStatus")}
                                  />
                                </div>
                              </div>
                              <div className="py-3">
                                <Typography variant="h4" color="primary">Property Description</Typography>
                              </div>
                              <div className="flex gap-4 py-3">
                                <div className="w-full">
                                  <Typography variant="body" color="tertiary" className="mb-2">Address</Typography>
                                  <AddressAutoComplete options={addresses} placeholder="Enter the Subject Property Address" value={values && formatAddress(values.sellerPropertyAddress)} onChange={(value) => onChangeAddressAutoComplete(value, "sellerPropertyAddress")} onAllChange={(value) => onSelectAddressAutoComplete(value, "sellerPropertyAddress")} />
                                </div>
                                <div className="w-1/3">
                                  <Typography variant="body" color="tertiary" className="mb-2">Unit</Typography>
                                  <TextField name="sellerUnit" value={values.sellerUnit} onChange={(e) => handleInputChange(e)} />
                                </div>
                              </div>
                              <div className="flex gap-4 py-3">
                                <div className="w-full">
                                  <Typography variant="body" color="tertiary" className="mb-2">School Districts</Typography>
                                  <Select
                                    options={schoolDistricts && schoolDistricts.map(item => { return { value: item.districtIDNumber + "-" + item.countyNames, label: item.schoolDistrictName + "-" + item.countyNames } })
                                    }
                                    name="sellerSchoolDistrictsMulti"
                                    placeholder="Select"
                                    isMulti={true}
                                    value={values.sellerSchoolDistrictsMulti.map((item: any) => {
                                      return {
                                        value: item,
                                        label: item
                                      }
                                    })}
                                    onChange={(value) => handleSelectChange(value, "sellerSchoolDistrictsMulti")}
                                    onInputChange={(value) => onInputChangeAutoComplete(value, "sellerSchoolDistrictsMulti")}
                                  />
                                </div>
                              </div>
                              <div className="flex gap-4 py-3">
                                <div className="w-full">
                                  <Typography variant="body" color="tertiary" className="mb-2">Property Type</Typography>
                                  <Select
                                    options={propertyType}
                                    name="sellerPropertyType"
                                    value={{ value: values.sellerPropertyType, label: values.sellerPropertyType }}
                                    onChange={(value) => changePropertyType(value)}
                                  />
                                </div>
                                <div className="w-full">
                                  <Typography variant="body" color="tertiary" className="mb-2">Property Sub-Type</Typography>
                                  <Select
                                    options={propertySubType}
                                    name="sellerPropertySubType"
                                    value={{ value: values.sellerPropertySubType, label: values.sellerPropertySubType }}
                                    onChange={(value) => handleSelectChange(value, "sellerPropertySubType")}
                                  />
                                </div>
                              </div>
                              <div className="flex gap-4 py-3">
                                <div className="w-full">
                                  <Typography variant="body" color="tertiary" className="mb-2">Unit Count</Typography>
                                  <NumericFormat
                                    allowLeadingZeros
                                    thousandSeparator=","
                                    name="sellerUnitCount"
                                    value={values.sellerUnitCount ? parseFloat(values.sellerUnitCount.toString()).toLocaleString("en") : ""} onChange={(e) => handleInputChange(e, "number")}
                                    className="body h-[42px] bg-netural rounded-sm w-full border-transparent focus:!border-[#4C42D7] focus:!ring-2 !ring-[#4C42D7]/25"
                                  />
                                </div>
                                <div className="w-full">
                                  <Typography variant="body" color="tertiary" className="mb-2">Stories</Typography>
                                  <NumericFormat
                                    allowLeadingZeros
                                    thousandSeparator=","
                                    name="sellerStories"
                                    value={values.sellerStories ? parseFloat(values.sellerStories.toString()).toLocaleString("en") : ""} onChange={(e) => handleInputChange(e, "number")}
                                    className="body h-[42px] bg-netural rounded-sm w-full border-transparent focus:!border-[#4C42D7] focus:!ring-2 !ring-[#4C42D7]/25"
                                  />
                                </div>
                              </div>
                              <div className="flex gap-4 py-3">
                                <div className="w-full">
                                  <Typography variant="body" color="tertiary" className="mb-2">Home Size (SqFt)</Typography>
                                  <NumericFormat
                                    allowLeadingZeros
                                    thousandSeparator=","
                                    name="sellerHomeSqFt"
                                    value={values.sellerHomeSqFt ? parseFloat(values.sellerHomeSqFt.toString()).toLocaleString("en") : ""} onChange={(e) => handleInputChange(e, "number")}
                                    className="body h-[42px] bg-netural rounded-sm w-full border-transparent focus:!border-[#4C42D7] focus:!ring-2 !ring-[#4C42D7]/25"
                                  />
                                </div>
                                <div className="w-full">
                                  <Typography variant="body" color="tertiary" className="mb-2">Lot Size (SqFt)</Typography>
                                  <NumericFormat
                                    allowLeadingZeros
                                    thousandSeparator=","
                                    name="sellerLotSqFt"
                                    value={values.sellerLotSqFt ? parseFloat(values.sellerLotSqFt.toString()).toLocaleString("en") : ""} onChange={(e) => handleInputChange(e, "number")}
                                    className="body h-[42px] bg-netural rounded-sm w-full border-transparent focus:!border-[#4C42D7] focus:!ring-2 !ring-[#4C42D7]/25"
                                  />
                                </div>
                              </div>
                              <div className="py-3">
                                <Typography variant="h4" color="primary">Property Features</Typography>
                              </div>
                              <div className="flex gap-4 py-3">
                                <div className="w-full">
                                  <Typography variant="body" color="tertiary" className="mb-2">Bedrooms</Typography>
                                  <NumericFormat
                                    allowLeadingZeros
                                    thousandSeparator=","
                                    name="sellerBedrooms"
                                    value={values.sellerBedrooms ? parseFloat(values.sellerBedrooms.toString()).toLocaleString("en") : ""} onChange={(e) => handleInputChange(e, "number")}
                                    className="body h-[42px] bg-netural rounded-sm w-full border-transparent focus:!border-[#4C42D7] focus:!ring-2 !ring-[#4C42D7]/25"
                                  />
                                </div>
                                <div className="w-full">
                                  <Typography variant="body" color="tertiary" className="mb-2">Bathrooms</Typography>
                                  <NumericFormat
                                    allowLeadingZeros
                                    thousandSeparator=","
                                    name="sellerBathrooms"
                                    value={values.sellerBathrooms ? parseFloat(values.sellerBathrooms.toString()).toLocaleString("en") : ""} onChange={(e) => handleInputChange(e, "number")}
                                    className="body h-[42px] bg-netural rounded-sm w-full border-transparent focus:!border-[#4C42D7] focus:!ring-2 !ring-[#4C42D7]/25"
                                  />
                                </div>
                              </div>
                              <div className="flex gap-4 py-3">
                                <div className="w-full">
                                  <Typography variant="body" color="tertiary" className="mb-2">Parking</Typography>
                                  <div className="flex gap-8">
                                    <label className="flex gap-2 items-center">
                                      <input
                                        type="radio"
                                        name="sellerParking"
                                        value="Yes"
                                        checked={values.sellerParking === "Yes" ? true : false}
                                        className="h-5 w-5 rounded-full border-gray-200"
                                        onChange={(e) => handleInputChange(e)}
                                      />
                                      <Typography variant="body" color={values.sellerParking === "Yes" ? `primary` : `tertiary`}>Yes</Typography>
                                    </label>
                                    <label className="flex gap-2 items-center">
                                      <input
                                        type="radio"
                                        name="sellerParking"
                                        value="No"
                                        checked={values.sellerParking === "No" ? true : false}
                                        className="h-5 w-5 rounded-full border-gray-200"
                                        onChange={(e) => handleInputChange(e)}
                                      />
                                      <Typography variant="body" color={values.sellerParking === "No" ? `primary` : `tertiary`}>No</Typography>
                                    </label>
                                  </div>
                                </div>
                                <div className="w-full">
                                  <Typography variant="body" color="tertiary" className="mb-2">Garage</Typography>
                                  <div className="flex gap-8">
                                    <label className="flex gap-2 items-center">
                                      <input
                                        type="radio"
                                        name="sellerGarage"
                                        value="Yes"
                                        checked={values.sellerGarage === "Yes" ? true : false}
                                        className="h-5 w-5 rounded-full border-gray-200"
                                        onChange={(e) => handleInputChange(e)}
                                      />
                                      <Typography variant="body" color={values.sellerGarage === "Yes" ? `primary` : `tertiary`}>Yes</Typography>
                                    </label>
                                    <label className="flex gap-2 items-center">
                                      <input
                                        type="radio"
                                        name="sellerGarage"
                                        value="No"
                                        checked={values.sellerGarage === "No" ? true : false}
                                        className="h-5 w-5 rounded-full border-gray-200"
                                        onChange={(e) => handleInputChange(e)}
                                      />
                                      <Typography variant="body" color={values.sellerGarage === "No" ? `primary` : `tertiary`}>No</Typography>
                                    </label>
                                  </div>
                                </div>
                              </div>
                              <div className="flex gap-4 py-3">
                                <div className="w-full">
                                  <Typography variant="body" color="tertiary" className="mb-2">Heating</Typography>
                                  <div className="flex gap-8">
                                    <label className="flex gap-2 items-center">
                                      <input
                                        type="radio"
                                        name="sellerHeating"
                                        value="Yes"
                                        checked={values.sellerHeating === "Yes" ? true : false}
                                        className="h-5 w-5 rounded-full border-gray-200"
                                        onChange={(e) => handleInputChange(e)}
                                      />
                                      <Typography variant="body" color={values.sellerHeating === "Yes" ? `primary` : `tertiary`}>Yes</Typography>
                                    </label>
                                    <label className="flex gap-2 items-center">
                                      <input
                                        type="radio"
                                        name="sellerHeating"
                                        value="No"
                                        checked={values.sellerHeating === "No" ? true : false}
                                        className="h-5 w-5 rounded-full border-gray-200"
                                        onChange={(e) => handleInputChange(e)}
                                      />
                                      <Typography variant="body" color={values.sellerHeating === "No" ? `primary` : `tertiary`}>No</Typography>
                                    </label>
                                  </div>
                                </div>
                                <div className="w-full">
                                  <Typography variant="body" color="tertiary" className="mb-2">Cooling</Typography>
                                  <div className="flex gap-8">
                                    <label className="flex gap-2 items-center">
                                      <input
                                        type="radio"
                                        name="sellerCooling"
                                        value="Yes"
                                        checked={values.sellerCooling === "Yes" ? true : false}
                                        className="h-5 w-5 rounded-full border-gray-200"
                                        onChange={(e) => handleInputChange(e)}
                                      />
                                      <Typography variant="body" color={values.sellerCooling === "Yes" ? `primary` : `tertiary`}>Yes</Typography>
                                    </label>
                                    <label className="flex gap-2 items-center">
                                      <input
                                        type="radio"
                                        name="sellerCooling"
                                        value="No"
                                        checked={values.sellerCooling === "No" ? true : false}
                                        className="h-5 w-5 rounded-full border-gray-200"
                                        onChange={(e) => handleInputChange(e)}
                                      />
                                      <Typography variant="body" color={values.sellerCooling === "No" ? `primary` : `tertiary`}>No</Typography>
                                    </label>
                                  </div>
                                </div>
                              </div>
                              <div className="flex gap-4 py-3">
                                <div className="w-1/2">
                                  <Typography variant="body" color="tertiary" className="mb-2">Heating Type</Typography>
                                  <Select
                                    options={[
                                      { value: "Active Solar", label: "Active Solar" },
                                      { value: "Baseboard", label: "Baseboard" },
                                      { value: "Ceiling", label: "Ceiling" },
                                      { value: "Central", label: "Central" },
                                      { value: "Coal", label: "Coal" },
                                      { value: "Coal Stove", label: "Coal Stove" },
                                      { value: "Ductless (Heating)", label: "Ductless (Heating)" },
                                      { value: "Electric (Heating)", label: "Electric (Heating)" },
                                      { value: "ENERGY STAR/ACCA RSI Qualified Installation", label: "ENERGY STAR/ACCA RSI Qualified Installation" },
                                      { value: "ENERGY STAR Qualified Equipment (Heating)", label: "ENERGY STAR Qualified Equipment (Heating)" },
                                      { value: "Exhaust Fan (Heating)", label: "Exhaust Fan (Heating)" },
                                      { value: "Fireplace(s)", label: "Fireplace(s)" },
                                      { value: "Fireplace Insert", label: "Fireplace Insert" },
                                      { value: "Floor Furnace", label: "Floor Furnace" },
                                      { value: "Forced Air", label: "Forced Air" },
                                      { value: "Geothermal (Heating)", label: "Geothermal (Heating)" },
                                      { value: "Gravity", label: "Gravity" },
                                      { value: "Heat Pump (Heating)", label: "Heat Pump (Heating)" },
                                      { value: "Hot Water (Heating)", label: "Hot Water (Heating)" },
                                      { value: "Humidity Control (Heating)", label: "Humidity Control (Heating)" },
                                      { value: "Kerosene", label: "Kerosene" },
                                      { value: "Natural Gas", label: "Natural Gas" },
                                      { value: "None (Heating)", label: "None (Heating)" },
                                      { value: "Oil", label: "Oil" },
                                      { value: "Other (Heating)", label: "Other (Heating)" },
                                      { value: "Passive Solar", label: "Passive Solar" },
                                      { value: "Pellet Stove (Heating)", label: "Pellet Stove (Heating)" },
                                      { value: "Propane (Heating)", label: "Propane (Heating)" },
                                      { value: "Propane Stove", label: "Propane Stove" },
                                      { value: "Radiant", label: "Radiant" },
                                      { value: "Radiant Ceiling", label: "Radiant Ceiling" },
                                      { value: "Radiant Floor", label: "Radiant Floor" },
                                      { value: "See Remarks (Heating)", label: "See Remarks (Heating)" },
                                      { value: "Separate Meters (Heating)", label: "Separate Meters (Heating)" },
                                      { value: "Solar (Heating)", label: "Solar (Heating)" },
                                      { value: "Space Heater", label: "Space Heater" },
                                      { value: "Steam", label: "Steam" },
                                      { value: "Varies by Unit (Heating)", label: "Varies by Unit (Heating)" },
                                      { value: "Wall Furnace", label: "Wall Furnace" },
                                      { value: "Wood (Heating)", label: "Wood (Heating)" },
                                      { value: "Wood Stove", label: "Wood Stove" },
                                      { value: "Zoned (Heating)", label: "Zoned (Heating)" },
                                    ]}
                                    name="sellerHeatingTypeMulti"
                                    isMulti={true}
                                    value={values.sellerHeatingTypeMulti.map((item: any) => {
                                      return {
                                        value: item,
                                        label: item
                                      }
                                    })}
                                    onChange={(value) => handleSelectChange(value, "sellerHeatingTypeMulti")}
                                  />
                                </div>
                                <div className="w-1/2">
                                  <Typography variant="body" color="tertiary" className="mb-2">Cooling Type</Typography>
                                  <Select
                                    options={[
                                      { value: "Attic Fan", label: "Attic Fan" },
                                      { value: "Ceiling Fan(s)", label: "Ceiling Fan(s)" },
                                      { value: "Central Air", label: "Central Air" },
                                      { value: "Dual", label: "Dual" },
                                      { value: "Ductless", label: "Ductless" },
                                      { value: "Electric", label: "Electric" },
                                      { value: "ENERGY STAR Qualified Equipment", label: "ENERGY STAR Qualified Equipment" },
                                      { value: "Evaporative Cooling", label: "Evaporative Cooling" },
                                      { value: "Exhaust Fan (Cooling)", label: "Exhaust Fan (Cooling)" },
                                      { value: "Gas (Cooling)", label: "Gas (Cooling)" },
                                      { value: "Geothermal", label: "Geothermal" },
                                      { value: "Heat Pump", label: "Heat Pump" },
                                      { value: "Humidity Control", label: "Humidity Control" },
                                      { value: "Multi Units", label: "Multi Units" },
                                      { value: "None (Cooling)", label: "None (Cooling)" },
                                      { value: "Other (Cooling)", label: "Other (Cooling)" },
                                      { value: "Roof Turbine(s)", label: "Roof Turbine(s)" },
                                      { value: "Separate Meters", label: "Separate Meters" },
                                      { value: "Varies by Unit", label: "Varies by Unit" },
                                      { value: "Wall/Window Unit(s)", label: "Wall/Window Unit(s)" },
                                      { value: "Wall Unit(s)", label: "Wall Unit(s)" },
                                      { value: "Whole House Fan", label: "Whole House Fan" },
                                      { value: "Window Unit(s)", label: "Window Unit(s)" },
                                    ]}
                                    isMulti={true}
                                    name="sellerCoolingTypeMulti"
                                    value={values.sellerCoolingTypeMulti.map((item: any) => {
                                      return {
                                        value: item,
                                        label: item
                                      }
                                    })}
                                    onChange={(value) => handleSelectChange(value, "sellerCoolingTypeMulti")}
                                  />
                                </div>
                              </div>
                              <div className="flex gap-4 py-3">
                                <div className="w-full">
                                  <Typography variant="body" color="tertiary" className="mb-2">Pool</Typography>
                                  <div className="flex gap-8">
                                    <label className="flex gap-2 items-center">
                                      <input
                                        type="radio"
                                        name="sellerPool"
                                        value="Yes"
                                        checked={values.sellerPool === "Yes" ? true : false}
                                        className="h-5 w-5 rounded-full border-gray-200"
                                        onChange={(e) => handleInputChange(e)}
                                      />
                                      <Typography variant="body" color={values.sellerPool === "Yes" ? `primary` : `tertiary`}>Yes</Typography>
                                    </label>
                                    <label className="flex gap-2 items-center">
                                      <input
                                        type="radio"
                                        name="sellerPool"
                                        value="No"
                                        checked={values.sellerPool === "No" ? true : false}
                                        className="h-5 w-5 rounded-full border-gray-200"
                                        onChange={(e) => handleInputChange(e)}
                                      />
                                      <Typography variant="body" color={values.sellerPool === "No" ? `primary` : `tertiary`}>No</Typography>
                                    </label>
                                  </div>
                                </div>
                                <div className="w-full">
                                  <Typography variant="body" color="tertiary" className="mb-2">Is Pre-Foreclosure?</Typography>
                                  <div className="flex gap-8">
                                    <label className="flex gap-2 items-center">
                                      <input
                                        type="radio"
                                        name="sellerIsPreForeclosure"
                                        value="Yes"
                                        checked={values.sellerIsPreForeclosure === "Yes" ? true : false}
                                        className="h-5 w-5 rounded-full border-gray-200"
                                        onChange={(e) => handleInputChange(e)}
                                      />
                                      <Typography variant="body" color={values.sellerIsPreForeclosure === "Yes" ? `primary` : `tertiary`}>Yes</Typography>
                                    </label>
                                    <label className="flex gap-2 items-center">
                                      <input
                                        type="radio"
                                        name="sellerIsPreForeclosure"
                                        value="No"
                                        checked={values.sellerIsPreForeclosure === "No" ? true : false}
                                        className="h-5 w-5 rounded-full border-gray-200"
                                        onChange={(e) => handleInputChange(e)}
                                      />
                                      <Typography variant="body" color={values.sellerIsPreForeclosure === "No" ? `primary` : `tertiary`}>No</Typography>
                                    </label>
                                  </div>
                                </div>
                              </div>
                              <div className="flex gap-4 py-3">
                                <div className="w-full">
                                  <Typography variant="body" color="tertiary" className="mb-2">Is Attached?</Typography>
                                  <div className="flex gap-8">
                                    <label className="flex gap-2 items-center">
                                      <input
                                        type="radio"
                                        name="sellerIsAttached"
                                        value="Yes"
                                        checked={values.sellerIsAttached === "Yes" ? true : false}
                                        className="h-5 w-5 rounded-full border-gray-200"
                                        onChange={(e) => handleInputChange(e)}
                                      />
                                      <Typography variant="body" color={values.sellerIsAttached === "Yes" ? `primary` : `tertiary`}>Yes</Typography>
                                    </label>
                                    <label className="flex gap-2 items-center">
                                      <input
                                        type="radio"
                                        name="sellerIsAttached"
                                        value="No"
                                        checked={values.sellerIsAttached === "No" ? true : false}
                                        className="h-5 w-5 rounded-full border-gray-200"
                                        onChange={(e) => handleInputChange(e)}
                                      />
                                      <Typography variant="body" color={values.sellerIsAttached === "No" ? `primary` : `tertiary`}>No</Typography>
                                    </label>
                                  </div>
                                </div>
                                <div className="w-full">
                                  <Typography variant="body" color="tertiary" className="mb-2">New Construction</Typography>
                                  <div className="flex gap-8">
                                    <label className="flex gap-2 items-center">
                                      <input
                                        type="radio"
                                        name="sellerNewConstruction"
                                        value="Yes"
                                        checked={values.sellerNewConstruction === "Yes" ? true : false}
                                        className="h-5 w-5 rounded-full border-gray-200"
                                        onChange={(e) => handleInputChange(e)}
                                      />
                                      <Typography variant="body" color={values.sellerNewConstruction === "Yes" ? `primary` : `tertiary`}>Yes</Typography>
                                    </label>
                                    <label className="flex gap-2 items-center">
                                      <input
                                        type="radio"
                                        name="sellerNewConstruction"
                                        value="No"
                                        checked={values.sellerNewConstruction === "No" ? true : false}
                                        className="h-5 w-5 rounded-full border-gray-200"
                                        onChange={(e) => handleInputChange(e)}
                                      />
                                      <Typography variant="body" color={values.sellerNewConstruction === "No" ? `primary` : `tertiary`}>No</Typography>
                                    </label>
                                  </div>
                                </div>
                              </div>
                              <div className="py-3">
                                <Typography variant="h4" color="primary">Community & Neighborhood</Typography>
                              </div>
                              <div className="flex gap-4 py-3">
                                <div className="w-full">
                                  <Typography variant="body" color="tertiary" className="mb-2">Views</Typography>
                                  <Select
                                    options={[
                                      { value: "Bay", label: "Bay" },
                                      { value: "Beach", label: "Beach" },
                                      { value: "Bridge(s)", label: "Bridge(s)" },
                                      { value: "Canal", label: "Canal" },
                                      { value: "Canyon", label: "Canyon" },
                                      { value: "City", label: "City" },
                                      { value: "City Lights", label: "City Lights" },
                                      { value: "Creek/Stream", label: "Creek/Stream" },
                                      { value: "Desert", label: "Desert" },
                                      { value: "Downtown", label: "Downtown" },
                                      { value: "Forest", label: "Forest" },
                                      { value: "Garden", label: "Garden" },
                                      { value: "Golf Course", label: "Golf Course" },
                                      { value: "Hills", label: "Hills" },
                                      { value: "Lake", label: "Lake" },
                                      { value: "Marina", label: "Marina" },
                                      { value: "Meadow", label: "Meadow" },
                                      { value: "Mountain(s)", label: "Mountain(s)" },
                                      { value: "Neighborhood", label: "Neighborhood" },
                                      { value: "None", label: "None" },
                                      { value: "Ocean", label: "Ocean" },
                                      { value: "Orchard", label: "Orchard" },
                                      { value: "Other", label: "Other" },
                                      { value: "Panoramic", label: "Panoramic" },
                                      { value: "Park/Greenbelt", label: "Park/Greenbelt" },
                                      { value: "Pasture", label: "Pasture" },
                                      { value: "Pond", label: "Pond" },
                                      { value: "Pool", label: "Pool" },
                                      { value: "Ridge", label: "Ridge" },
                                      { value: "River", label: "River" },
                                      { value: "Rural", label: "Rural" },
                                      { value: "See Remarks", label: "See Remarks" },
                                      { value: "Skyline", label: "Skyline" },
                                      { value: "Territorial", label: "Territorial" },
                                      { value: "Trees/Woods", label: "Trees/Woods" },
                                      { value: "Valley", label: "Valley" },
                                      { value: "Vineyard", label: "Vineyard" },
                                      { value: "Water", label: "Water" },

                                    ]}
                                    name="sellerViewsMulti"
                                    isMulti={true}
                                    value={values.sellerViewsMulti.map((item: any) => {
                                      return {
                                        value: item,
                                        label: item
                                      }
                                    })}
                                    onChange={(value) => handleSelectChange(value, "sellerViewsMulti")}
                                  />
                                </div>
                              </div>
                              <div className="flex gap-4 py-3">
                                <div className="w-full">
                                  <Typography variant="body" color="tertiary" className="mb-2">Amenities</Typography>
                                  <Select
                                    options={[{ value: searchAmenities, label: searchAmenities }, ...(amenities && amenities.map(item => { return { value: item.name, label: item.name } }))
                                    ]}
                                    name="sellerAmenitiesMulti"
                                    isMulti={true}
                                    value={values.sellerAmenitiesMulti.map((item: any) => {
                                      return {
                                        value: item,
                                        label: item
                                      }
                                    })}
                                    onChange={(value) => handleSelectChange(value, "sellerAmenitiesMulti")}
                                    onInputChange={(value) => onInputChangeAutoComplete(value, "sellerAmenitiesMulti")}
                                  />
                                </div>
                              </div>
                              <div className="flex gap-4 py-3">
                                <div className="w-full">
                                  <Typography variant="body" color="tertiary" className="mb-2">Keywords</Typography>
                                  <Select
                                    options={[{ value: searchKeyword, label: searchKeyword }, ...(keywords && keywords.map(item => { return { value: item.name, label: item.name } }))
                                    ]}
                                    name="sellerKeywordsMulti"
                                    isMulti={true}
                                    value={values.sellerKeywordsMulti.map((item: any) => {
                                      return {
                                        value: item,
                                        label: item
                                      }
                                    })}
                                    onChange={(value) => handleSelectChange(value, "sellerKeywordsMulti")}
                                    onInputChange={(value) => onInputChangeAutoComplete(value, "sellerKeywordsMulti")}
                                  />
                                </div>
                              </div>
                              <div className="py-3">
                                <Typography variant="h4" color="primary">Other Details</Typography>
                              </div>
                              <div className="flex gap-4 py-3">
                                <div className="w-full relative">
                                  <Typography variant="body" color="tertiary" className="mb-2">HOA Dues (Annual)</Typography>
                                  <NumericFormat
                                    allowLeadingZeros
                                    thousandSeparator=","
                                    name="sellerAnnualHOADues"
                                    value={values.sellerAnnualHOADues ? parseFloat(values.sellerAnnualHOADues.toString()).toLocaleString("en") : ""} onChange={(e) => handleInputChange(e, "number")}
                                    className="body h-[42px] bg-netural rounded-sm w-full border-transparent focus:!border-[#4C42D7] focus:!ring-2 !ring-[#4C42D7]/25 !pl-8"
                                  />
                                  <IconWrapper name="money" className="absolute top-[54%] left-1" width={20} />
                                </div>
                                <div className="w-full relative">
                                  <Typography variant="body" color="tertiary" className="mb-2">Taxes (Annual)</Typography>
                                  <NumericFormat
                                    allowLeadingZeros
                                    thousandSeparator=","
                                    name="sellerAnnualTaxes"
                                    value={values.sellerAnnualTaxes ? parseFloat(values.sellerAnnualTaxes.toString()).toLocaleString("en") : ""} onChange={(e) => handleInputChange(e, "number")}
                                    className="body h-[42px] bg-netural rounded-sm w-full border-transparent focus:!border-[#4C42D7] focus:!ring-2 !ring-[#4C42D7]/25 !pl-8"
                                  />
                                  <IconWrapper name="money" className="absolute top-[54%] left-1" width={20} />
                                </div>
                              </div>
                              <div className="flex gap-4 py-3">
                                <div className="w-full relative">
                                  <Typography variant="body" color="tertiary" className="mb-2">Other Expenses (Annual)</Typography>
                                  <NumericFormat
                                    allowLeadingZeros
                                    thousandSeparator=","
                                    name="sellerAnnualOtherExpenses"
                                    value={values.sellerAnnualOtherExpenses ? parseFloat(values.sellerAnnualOtherExpenses.toString()).toLocaleString("en") : ""} onChange={(e) => handleInputChange(e, "number")}
                                    className="body h-[42px] bg-netural rounded-sm w-full border-transparent focus:!border-[#4C42D7] focus:!ring-2 !ring-[#4C42D7]/25 !pl-8"
                                  />
                                  <IconWrapper name="money" className="absolute top-[54%] left-1" width={20} />
                                </div>
                                <div className="w-full relative">
                                  <Typography variant="body" color="tertiary" className="mb-2">Lender Credit</Typography>
                                  <NumericFormat
                                    allowLeadingZeros
                                    thousandSeparator=","
                                    name="sellerVillageAnnualTaxes"
                                    value={values.sellerVillageAnnualTaxes ? parseFloat(values.sellerVillageAnnualTaxes.toString()).toLocaleString("en") : ""} onChange={(e) => handleInputChange(e, "number")}
                                    className="body h-[42px] bg-netural rounded-sm w-full border-transparent focus:!border-[#4C42D7] focus:!ring-2 !ring-[#4C42D7]/25 !pl-8"
                                  />
                                  <IconWrapper name="money" className="absolute top-[54%] left-1" width={20} />
                                </div>
                              </div>
                            </>
                          )}
                        </div>
                      )}
                      {/* {currentStep === 3 && (
                        <div className="mb-5">
                          <div className="py-3">
                            <Typography variant="h4" color="primary" className="mb-2">Landlord Preference</Typography>
                          </div>
                          <div className="flex gap-4 py-3">
                            <div className="w-full">
                              <Typography variant="body" color="tertiary" className="mb-2">Property Type</Typography>
                              <Select
                                options={propertyType}
                                name="landlordPropertyType"
                                value={{ value: values.landlordPropertyType, label: values.landlordPropertyType }}
                                onChange={(value) => changePropertyType(value)}
                              />
                            </div>
                            <div className="w-full">
                              <Typography variant="body" color="tertiary" className="mb-2">Property Sub-Type</Typography>
                              <Select
                                options={propertySubType}
                                name="landlordPropertySubType"
                                value={{ value: values.landlordPropertySubType, label: values.landlordPropertySubType }}
                                onChange={(value) => handleSelectChange(value, "landlordPropertySubType")}
                              />
                            </div>
                          </div>
                          <div className="flex gap-4 py-3">
                            <div className="w-full">
                              <Typography variant="body" color="tertiary" className="mb-2">Unit Count</Typography>
                              <NumericFormat
                                allowLeadingZeros
                                thousandSeparator=","
                                name="landlordNumberOfUnits"
                                value={values.landlordNumberOfUnits ? parseFloat(values.landlordNumberOfUnits.toString()).toLocaleString("en") : ""} onChange={(e) => handleInputChange(e, "number")}
                                className="body h-[42px] bg-netural rounded-sm w-full border-transparent focus:!border-[#4C42D7] focus:!ring-2 !ring-[#4C42D7]/25"
                              />
                            </div>
                            <div className="w-full">
                              <Typography variant="body" color="tertiary" className="mb-2">Stories</Typography>
                              <NumericFormat
                                allowLeadingZeros
                                thousandSeparator=","
                                name="landlordStories"
                                value={values.landlordStories ? parseFloat(values.landlordStories.toString()).toLocaleString("en") : ""} onChange={(e) => handleInputChange(e, "number")}
                                className="body h-[42px] bg-netural rounded-sm w-full border-transparent focus:!border-[#4C42D7] focus:!ring-2 !ring-[#4C42D7]/25"
                              />
                            </div>
                          </div>
                          <div className="flex gap-4 py-3">
                                <div className="w-full">
                                  <Typography variant="body" color="tertiary" className="mb-2">Minimum Home Size (SqFt)</Typography>
                                  <NumericFormat
                                    allowLeadingZeros
                                    thousandSeparator=","
                                    name="landlordHomeSqFt"
                                    value={values.landlordHomeSqFt ? parseFloat(values.landlordHomeSqFt.toString()).toLocaleString("en") : ""} onChange={(e) => handleInputChange(e, "number")}
                                    className="body h-[42px] bg-netural rounded-sm w-full border-transparent focus:!border-[#4C42D7] focus:!ring-2 !ring-[#4C42D7]/25"
                                  />
                                </div>
                                <div className="w-full">
                                  <Typography variant="body" color="tertiary" className="mb-2">Minimum Lot Size (SqFt)</Typography>
                                  <NumericFormat
                                    allowLeadingZeros
                                    thousandSeparator=","
                                    name="landlordLotSqFt"
                                    value={values.landlordLotSqFt ? parseFloat(values.landlordLotSqFt.toString()).toLocaleString("en") : ""} onChange={(e) => handleInputChange(e, "number")}
                                    className="body h-[42px] bg-netural rounded-sm w-full border-transparent focus:!border-[#4C42D7] focus:!ring-2 !ring-[#4C42D7]/25"
                                  />
                                </div>
                              </div>
                          <div className="py-3">
                            <Typography variant="h4" color="primary">Property Features</Typography>
                          </div>
                          <div className="flex gap-4 py-3">
                            <div className="w-full">
                              <Typography variant="body" color="tertiary" className="mb-2">Minimum Bedrooms</Typography>
                              <NumericFormat
                                allowLeadingZeros
                                thousandSeparator=","
                                name="landlordBedrooms"
                                value={values.landlordBedrooms ? parseFloat(values.landlordBedrooms.toString()).toLocaleString("en") : ""} onChange={(e) => handleInputChange(e, "number")}
                                className="body h-[42px] bg-netural rounded-sm w-full border-transparent focus:!border-[#4C42D7] focus:!ring-2 !ring-[#4C42D7]/25"
                              />
                            </div>
                            <div className="w-full">
                              <Typography variant="body" color="tertiary" className="mb-2">Minimum Bathrooms</Typography>
                              <NumericFormat
                                allowLeadingZeros
                                thousandSeparator=","
                                name="landlordBathrooms"
                                value={values.landlordBathrooms ? parseFloat(values.landlordBathrooms.toString()).toLocaleString("en") : ""} onChange={(e) => handleInputChange(e, "number")}
                                className="body h-[42px] bg-netural rounded-sm w-full border-transparent focus:!border-[#4C42D7] focus:!ring-2 !ring-[#4C42D7]/25"
                              />
                            </div>
                          </div>
                          <div className="flex gap-4 py-3">
                            <div className="w-full">
                              <Typography variant="body" color="tertiary" className="mb-2">Parking</Typography>
                              <div className="flex gap-8">
                                <label className="flex gap-2 items-center">
                                  <input
                                    type="radio"
                                    name="landlordParking"
                                    value="Yes"
                                    checked={values.landlordParking === "Yes" ? true : false}
                                    className="h-5 w-5 rounded-full border-gray-200"
                                    onChange={(e) => handleInputChange(e)}
                                  />
                                  <Typography variant="body" color={values.landlordParking === "Yes" ? `primary` : `tertiary`}>Yes</Typography>
                                </label>
                                <label className="flex gap-2 items-center">
                                  <input
                                    type="radio"
                                    name="landlordParking"
                                    value="No"
                                    checked={values.landlordParking === "No" ? true : false}
                                    className="h-5 w-5 rounded-full border-gray-200"
                                    onChange={(e) => handleInputChange(e)}
                                  />
                                  <Typography variant="body" color={values.landlordParking === "No" ? `primary` : `tertiary`}>No</Typography>
                                </label>
                              </div>
                            </div>
                            <div className="w-full">
                              <Typography variant="body" color="tertiary" className="mb-2">Garage</Typography>
                              <div className="flex gap-8">
                                <label className="flex gap-2 items-center">
                                  <input
                                    type="radio"
                                    name="landlordGarage"
                                    value="Yes"
                                    checked={values.landlordGarage === "Yes" ? true : false}
                                    className="h-5 w-5 rounded-full border-gray-200"
                                    onChange={(e) => handleInputChange(e)}
                                  />
                                  <Typography variant="body" color={values.landlordGarage === "Yes" ? `primary` : `tertiary`}>Yes</Typography>
                                </label>
                                <label className="flex gap-2 items-center">
                                  <input
                                    type="radio"
                                    name="landlordGarage"
                                    value="No"
                                    checked={values.landlordGarage === "No" ? true : false}
                                    className="h-5 w-5 rounded-full border-gray-200"
                                    onChange={(e) => handleInputChange(e)}
                                  />
                                  <Typography variant="body" color={values.landlordGarage === "No" ? `primary` : `tertiary`}>No</Typography>
                                </label>
                              </div>
                            </div>
                          </div>
                          <div className="flex gap-4 py-3">
                            <div className="w-full">
                              <Typography variant="body" color="tertiary" className="mb-2">Heating & Cooling</Typography>
                              <div className="flex gap-8">
                                <label className="flex gap-2 items-center">
                                  <input
                                    type="radio"
                                    name="landlordHeatingAndCooling"
                                    value="Yes"
                                    checked={values.landlordHeatingAndCooling === "Yes" ? true : false}
                                    className="h-5 w-5 rounded-full border-gray-200"
                                    onChange={(e) => handleInputChange(e)}
                                  />
                                  <Typography variant="body" color={values.landlordHeatingAndCooling === "Yes" ? `primary` : `tertiary`}>Yes</Typography>
                                </label>
                                <label className="flex gap-2 items-center">
                                  <input
                                    type="radio"
                                    name="landlordHeatingAndCooling"
                                    value="No"
                                    checked={values.landlordHeatingAndCooling === "No" ? true : false}
                                    className="h-5 w-5 rounded-full border-gray-200"
                                    onChange={(e) => handleInputChange(e)}
                                  />
                                  <Typography variant="body" color={values.landlordHeatingAndCooling === "No" ? `primary` : `tertiary`}>No</Typography>
                                </label>
                              </div>
                            </div>
                            <div className="w-full">
                              <Typography variant="body" color="tertiary" className="mb-2">Pool</Typography>
                              <div className="flex gap-8">
                                <label className="flex gap-2 items-center">
                                  <input
                                    type="radio"
                                    name="landlordPool"
                                    value="Yes"
                                    checked={values.landlordPool === "Yes" ? true : false}
                                    className="h-5 w-5 rounded-full border-gray-200"
                                    onChange={(e) => handleInputChange(e)}
                                  />
                                  <Typography variant="body" color={values.landlordPool === "Yes" ? `primary` : `tertiary`}>Yes</Typography>
                                </label>
                                <label className="flex gap-2 items-center">
                                  <input
                                    type="radio"
                                    name="landlordPool"
                                    value="No"
                                    checked={values.landlordPool === "No" ? true : false}
                                    className="h-5 w-5 rounded-full border-gray-200"
                                    onChange={(e) => handleInputChange(e)}
                                  />
                                  <Typography variant="body" color={values.landlordPool === "No" ? `primary` : `tertiary`}>No</Typography>
                                </label>
                              </div>
                            </div>
                          </div>
                          <div className="flex gap-4 py-3">
                            <div className="w-full">
                              <Typography variant="body" color="tertiary" className="mb-2">Is Attached?</Typography>
                              <div className="flex gap-8">
                                <label className="flex gap-2 items-center">
                                  <input
                                    type="radio"
                                    name="landlordAttached"
                                    value="Yes"
                                    checked={values.landlordAttached === "Yes" ? true : false}
                                    className="h-5 w-5 rounded-full border-gray-200"
                                    onChange={(e) => handleInputChange(e)}
                                  />
                                  <Typography variant="body" color={values.landlordAttached === "Yes" ? `primary` : `tertiary`}>Yes</Typography>
                                </label>
                                <label className="flex gap-2 items-center">
                                  <input
                                    type="radio"
                                    name="landlordAttached"
                                    value="No"
                                    checked={values.landlordAttached === "No" ? true : false}
                                    className="h-5 w-5 rounded-full border-gray-200"
                                    onChange={(e) => handleInputChange(e)}
                                  />
                                  <Typography variant="body" color={values.landlordAttached === "No" ? `primary` : `tertiary`}>No</Typography>
                                </label>
                              </div>
                            </div>
                            <div className="w-full">
                              <Typography variant="body" color="tertiary" className="mb-2">New Construction</Typography>
                              <div className="flex gap-8">
                                <label className="flex gap-2 items-center">
                                  <input
                                    type="radio"
                                    name="landlordNewConstruction"
                                    value="Yes"
                                    checked={values.landlordNewConstruction === "Yes" ? true : false}
                                    className="h-5 w-5 rounded-full border-gray-200"
                                    onChange={(e) => handleInputChange(e)}
                                  />
                                  <Typography variant="body" color={values.landlordNewConstruction === "Yes" ? `primary` : `tertiary`}>Yes</Typography>
                                </label>
                                <label className="flex gap-2 items-center">
                                  <input
                                    type="radio"
                                    name="landlordNewConstruction"
                                    value="No"
                                    checked={values.landlordNewConstruction === "No" ? true : false}
                                    className="h-5 w-5 rounded-full border-gray-200"
                                    onChange={(e) => handleInputChange(e)}
                                  />
                                  <Typography variant="body" color={values.landlordNewConstruction === "No" ? `primary` : `tertiary`}>No</Typography>
                                </label>
                              </div>
                            </div>
                          </div>
                          <div className="py-3">
                            <Typography variant="h4" color="primary">Community & Neighborhood</Typography>
                          </div>
                          <div className="flex gap-4 py-3">
                            <div className="w-full">
                              <Typography variant="body" color="tertiary" className="mb-2">Views</Typography>
                              <Select
                                options={[
                                  { value: "Bay", label: "Bay" },
                                  { value: "Beach", label: "Beach" },
                                  { value: "Bridge(s)", label: "Bridge(s)" },
                                  { value: "Canal", label: "Canal" },
                                  { value: "Canyon", label: "Canyon" },
                                  { value: "City", label: "City" },
                                  { value: "City Lights", label: "City Lights" },
                                  { value: "Creek/Stream", label: "Creek/Stream" },
                                  { value: "Desert", label: "Desert" },
                                  { value: "Downtown", label: "Downtown" },
                                  { value: "Forest", label: "Forest" },
                                  { value: "Garden", label: "Garden" },
                                  { value: "Golf Course", label: "Golf Course" },
                                  { value: "Hills", label: "Hills" },
                                  { value: "Lake", label: "Lake" },
                                  { value: "Marina", label: "Marina" },
                                  { value: "Meadow", label: "Meadow" },
                                  { value: "Mountain(s)", label: "Mountain(s)" },
                                  { value: "Neighborhood", label: "Neighborhood" },
                                  { value: "None", label: "None" },
                                  { value: "Ocean", label: "Ocean" },
                                  { value: "Orchard", label: "Orchard" },
                                  { value: "Other", label: "Other" },
                                  { value: "Panoramic", label: "Panoramic" },
                                  { value: "Park/Greenbelt", label: "Park/Greenbelt" },
                                  { value: "Pasture", label: "Pasture" },
                                  { value: "Pond", label: "Pond" },
                                  { value: "Pool", label: "Pool" },
                                  { value: "Ridge", label: "Ridge" },
                                  { value: "River", label: "River" },
                                  { value: "Rural", label: "Rural" },
                                  { value: "See Remarks", label: "See Remarks" },
                                  { value: "Skyline", label: "Skyline" },
                                  { value: "Territorial", label: "Territorial" },
                                  { value: "Trees/Woods", label: "Trees/Woods" },
                                  { value: "Valley", label: "Valley" },
                                  { value: "Vineyard", label: "Vineyard" },
                                  { value: "Water", label: "Water" },

                                ]}
                                name="landlordViews"
                                isMulti={true}
                                value={values.landlordViews.map((item: any) => {
                                  return {
                                    value: item,
                                    label: item
                                  }
                                })}
                                onChange={(value) => handleSelectChange(value, "landlordViews")}
                              />
                            </div>
                          </div>
                          <div className="flex gap-4 py-3">
                            <div className="w-full">
                              <Typography variant="body" color="tertiary" className="mb-2">Amenities</Typography>
                              <Select
                                options={[{ value: searchAmenities, label: searchAmenities }, ...(amenities && amenities.map(item => { return { value: item.name, label: item.name } }))
                                ]}
                                name="landlordAmenities"
                                isMulti={true}
                                value={values.landlordAmenities.map((item: any) => {
                                  return {
                                    value: item,
                                    label: item
                                  }
                                })}
                                onChange={(value) => handleSelectChange(value, "landlordAmenities")}
                                onInputChange={(value) => onInputChangeAutoComplete(value, "landlordAmenities")}
                              />
                            </div>
                          </div>
                          <div className="flex gap-4 py-3">
                            <div className="w-full">
                              <Typography variant="body" color="tertiary" className="mb-2">Keywords</Typography>
                              <Select
                                options={[{ value: searchKeyword, label: searchKeyword }, ...(keywords && keywords.map(item => { return { value: item.name, label: item.name } }))
                                ]}
                                name="landlordKeywords"
                                isMulti={true}
                                value={values.landlordKeywords.map((item: any) => {
                                  return {
                                    value: item,
                                    label: item
                                  }
                                })}
                                onChange={(value) => handleSelectChange(value, "landlordKeywords")}
                                onInputChange={(value) => onInputChangeAutoComplete(value, "landlordKeywords")}
                              />
                            </div>
                          </div>
                        </div>
                      )} */}
                      {values.leadType === "Buyer" && currentStep === 4 && (
                        <div className="mb-5">
                          <div className="py-3">
                            <Typography variant="h4" color="primary" className="mb-2">Financing</Typography>

                          </div>
                          <div className="py-3">
                            <Typography variant="h4" color="primary" className="mb-2">Pre-Approval Details</Typography>
                          </div>
                          <div className="flex gap-4 py-3">
                            <div className="w-full">
                              <Typography variant="body" color="tertiary" className="mb-2">Is Pre Approved?</Typography>
                              <div className="flex gap-8">
                                <label className="flex gap-2 items-center">
                                  <input
                                    type="radio"
                                    name="buyerIsPreApproved"
                                    value="Yes"
                                    checked={values.buyerIsPreApproved === "Yes" ? true : false}
                                    className="h-5 w-5 rounded-full border-gray-200"
                                    onChange={(e) => handleInputChange(e)}
                                  />
                                  <Typography variant="body" color={values.buyerIsPreApproved === "Yes" ? `primary` : `tertiary`}>Yes</Typography>
                                </label>
                                <label className="flex gap-2 items-center">
                                  <input
                                    type="radio"
                                    name="buyerIsPreApproved"
                                    value="No"
                                    checked={values.buyerIsPreApproved === "No" ? true : false}
                                    className="h-5 w-5 rounded-full border-gray-200"
                                    onChange={(e) => handleInputChange(e)}
                                  />
                                  <Typography variant="body" color={values.buyerIsPreApproved === "No" ? `primary` : `tertiary`}>No</Typography>
                                </label>
                              </div>
                            </div>
                          </div>
                          <div className="flex gap-4 py-3">
                            <div className="w-full relative">
                              <Typography variant="body" color="tertiary" className="mb-2">Maximum Purchase Price</Typography>
                              <NumericFormat
                                allowLeadingZeros
                                thousandSeparator=","
                                name="buyerMaximumPurchasPrice"
                                value={values.buyerMaximumPurchasPrice ? parseFloat(values.buyerMaximumPurchasPrice.toString()).toLocaleString("en") : ""} onChange={(e) => handleInputChange(e, "number")}
                                className="body h-[42px] bg-netural rounded-sm w-full border-transparent focus:!border-[#4C42D7] focus:!ring-2 !ring-[#4C42D7]/25 !pl-8"
                              />
                              <IconWrapper name="money" className="absolute top-[54%] left-1" width={20} />
                            </div>
                          </div>
                          <div className="flex gap-4 py-3">
                            <div className="w-full">
                              <Typography variant="body" color="tertiary" className="mb-2">Loan Officer's First Name</Typography>
                              <TextField name="buyerLoanOfficerFirstName" value={values.buyerLoanOfficerFirstName} onChange={(event) => handleInputChange(event)} />
                            </div>
                            <div className="w-full">
                              <Typography variant="body" color="tertiary" className="mb-2">Loan Officer's Last Name</Typography>
                              <TextField name="buyerLoanOfficerLastName" value={values.buyerLoanOfficerLastName} onChange={(event) => handleInputChange(event)} />
                            </div>
                          </div>
                          <div className="flex gap-4 py-3">
                            <div className="w-full">
                              <Typography variant="body" color="tertiary" className="mb-2">Loan Officer's Phone</Typography>
                              <TextField maxLength={12} isInvalid={errorBuyerLoanOfficerPhone} name="buyerLoanOfficerPhone" value={values.buyerLoanOfficerPhone} onChange={(event) => handleInputChange(event)} />
                              {errorBuyerLoanOfficerPhone && <Typography variant='caption' className='text-rose-600 absolute mt-[2px]'>Please enter a valid phone number</Typography>}
                            </div>
                            <div className="w-full">
                              <Typography variant="body" color="tertiary" className="mb-2">Loan Officer's Email</Typography>
                              <TextField isInvalid={errorBuyerLoanOfficerEmail} name="buyerLoanOfficerEmail" value={values.buyerLoanOfficerEmail} onChange={(event) => handleInputChange(event)} />
                              {errorBuyerLoanOfficerEmail && <Typography variant='caption' className='text-rose-600 absolute mt-[2px]'>Invalid Email Address</Typography>}
                            </div>
                          </div>
                          <div className="flex gap-4 py-3">
                            <div className="w-full">
                              <Typography variant="body" color="tertiary" className="mb-2">Lender Company</Typography>
                              <TextField name="buyerLenderCompany" value={values.buyerLenderCompany} onChange={(event) => handleInputChange(event)} />
                            </div>
                          </div>
                          <div className="flex gap-4 py-3">
                            <div className="w-full">
                              <Typography variant="body" color="tertiary" className="mb-2">Pre-Approval Issue Date</Typography>
                              <DatePicker name="buyerPreApprovalIssueDate" value={values.buyerPreApprovalIssueDate} onChange={(date) => handleDateChange(date, "buyerPreApprovalIssueDate")} />
                            </div>
                            <div className="w-full">
                              <Typography variant="body" color="tertiary" className="mb-2">Pre-Approval Expiration Date</Typography>
                              <DatePicker name="buyerPreApprovalExpirationDate" value={values.buyerPreApprovalExpirationDate} onChange={(date) => handleDateChange(date, "buyerPreApprovalExpirationDate")} />
                            </div>
                          </div>
                          <div className="py-3">
                            <Typography variant="h4" color="primary">Loan Details</Typography>
                          </div>
                          <div className="flex gap-4 py-3">
                            <div className="w-full">
                              <Typography variant="body" color="tertiary" className="mb-2">Primary Borrower</Typography>
                              <TextField name="buyerPrimaryBorrower" value={values.buyerPrimaryBorrower} onChange={(event) => handleInputChange(event)} />
                            </div>
                            <div className="w-full">
                              <Typography variant="body" color="tertiary" className="mb-2">Secondary Borrower</Typography>
                              <TextField name="buyerSecondaryBorrower" value={values.buyerSecondaryBorrower} onChange={(event) => handleInputChange(event)} />
                            </div>
                          </div>
                          <div className="flex gap-4 py-3">
                            <div className="w-full">
                              <Typography variant="body" color="tertiary" className="mb-2">Loan Type</Typography>
                              <Select
                                options={[
                                  { value: "Fixed-Rate Mortgage (FRM)", label: "Fixed-Rate Mortgage (FRM)" },
                                  { value: "Adjustable-Rate Mortgage (ARM)", label: "Adjustable-Rate Mortgage (ARM)" },
                                  { value: "Interest-Only Mortgage", label: "Interest-Only Mortgage" },
                                  { value: "Balloon Mortgage", label: "Balloon Mortgage" },
                                  { value: "FHA Loan (Federal Housing Administration)", label: "FHA Loan (Federal Housing Administration)" },
                                  { value: "VA Loan (Veterans Affairs)", label: "VA Loan (Veterans Affairs)" },
                                  { value: "USDA Loan (U.S. Department of Agriculture)", label: "USDA Loan (U.S. Department of Agriculture)" },
                                  { value: "Jumbo Loan", label: "Jumbo Loan" },
                                  { value: "Conventional Loan", label: "Conventional Loan" },
                                  { value: "Home Equity Loan (Second Mortgage)", label: "Home Equity Loan (Second Mortgage)" },
                                  { value: "Home Equity Line of Credit (HELOC)", label: "Home Equity Line of Credit (HELOC)" },
                                  { value: "Reverse Mortgage (HECM)", label: "Reverse Mortgage (HECM)" },
                                  { value: "Construction Loan", label: "Construction Loan" },
                                  { value: "Rehab Loan (FHA 203(k))", label: "Rehab Loan (FHA 203(k))" },
                                  { value: "Buydown Mortgage", label: "Buydown Mortgage" },
                                  { value: "Graduated Payment Mortgage (GPM)", label: "Graduated Payment Mortgage (GPM)" },
                                  { value: "Biweekly Mortgage", label: "Biweekly Mortgage" },
                                  { value: "Assumable Mortgage", label: "Assumable Mortgage" },
                                  { value: "No-Documentation or Stated-Income Mortgage", label: "No-Documentation or Stated-Income Mortgage" },
                                  { value: "Portfolio Loan", label: "Portfolio Loan" },
                                  { value: "Chattel Mortgage", label: "Chattel Mortgage" },
                                  { value: "Shared Equity Mortgage", label: "Shared Equity Mortgage" },
                                  { value: "Community Land Trust Mortgage", label: "Community Land Trust Mortgage" },
                                ]}
                                name="buyerLoanType"
                                value={{ value: values.buyerLoanType, label: values.buyerLoanType }}
                                onChange={(value) => handleSelectChange(value, 'buyerLoanType')}
                              />                            </div>
                            <div className="w-full relative">
                              <Typography variant="body" color="tertiary" className="mb-2">Purchase Price</Typography>
                              <NumericFormat
                                allowLeadingZeros
                                thousandSeparator=","
                                name="buyerPurchasPrice"
                                value={values.buyerPurchasPrice ? parseFloat(values.buyerPurchasPrice.toString()).toLocaleString("en") : ""} onChange={(e) => handleInputChange(e, "number")}
                                className="body h-[42px] bg-netural rounded-sm w-full border-transparent focus:!border-[#4C42D7] focus:!ring-2 !ring-[#4C42D7]/25 !pl-8"
                              />
                              <IconWrapper name="money" className="absolute top-[54%] left-1" width={20} />
                            </div>
                          </div>
                          <div className="flex gap-4 py-3">
                            <div className="w-full relative">
                              <Typography variant="body" color="tertiary" className="mb-2">Seller Consession</Typography>
                              <NumericFormat
                                allowLeadingZeros
                                thousandSeparator=","
                                name="buyerSellerConsession"
                                value={values.buyerSellerConsession ? parseFloat(values.buyerSellerConsession.toString()).toLocaleString("en") : ""} onChange={(e) => handleInputChange(e, "number")}
                                className="body h-[42px] bg-netural rounded-sm w-full border-transparent focus:!border-[#4C42D7] focus:!ring-2 !ring-[#4C42D7]/25 !pl-8"
                              />
                              <IconWrapper name="money" className="absolute top-[54%] left-1" width={20} />
                            </div>
                            <div className="w-full relative">
                              <Typography variant="body" color="tertiary" className="mb-2">Down Payment</Typography>
                              <NumericFormat
                                allowLeadingZeros
                                thousandSeparator=","
                                name="buyerDownPaymentAmount"
                                value={values.buyerDownPaymentAmount ? parseFloat(values.buyerDownPaymentAmount.toString()).toLocaleString("en") : ""} onChange={(e) => handleInputChange(e, "number")}
                                className="body h-[42px] bg-netural rounded-sm w-full border-transparent focus:!border-[#4C42D7] focus:!ring-2 !ring-[#4C42D7]/25 !pl-8"
                              />
                              <IconWrapper name="money" className="absolute top-[54%] left-1" width={20} />
                            </div>
                          </div>
                          <div className="flex gap-4 py-3">
                            <div className="w-full relative">
                              <Typography variant="body" color="tertiary" className="mb-2">Base Loan Amount</Typography>
                              <NumericFormat
                                allowLeadingZeros
                                thousandSeparator=","
                                name="buyerBaseLoanAmount"
                                value={values.buyerBaseLoanAmount ? parseFloat(values.buyerBaseLoanAmount.toString()).toLocaleString("en") : ""} onChange={(e) => handleInputChange(e, "number")}
                                className="body h-[42px] bg-netural rounded-sm w-full border-transparent focus:!border-[#4C42D7] focus:!ring-2 !ring-[#4C42D7]/25 !pl-8"
                              />
                              <IconWrapper name="money" className="absolute top-[54%] left-1" width={20} />
                            </div>
                            <div className="w-full relative">
                              <Typography variant="body" color="tertiary" className="mb-2">Loan to Value</Typography>
                              <NumericFormat
                                allowLeadingZeros
                                name="buyerLoanToValue"
                                value={values.buyerLoanToValue ? parseFloat(values.buyerLoanToValue.toString()).toLocaleString("en") : ""} onChange={(e) => handleInputChange(e, "number")}
                                className="body h-[42px] bg-netural rounded-sm w-full border-transparent focus:!border-[#4C42D7] focus:!ring-2 !ring-[#4C42D7]/25 !pl-8"
                              />
                              <IconWrapper name="percentage" className="absolute top-[54%] left-1" width={20} />
                            </div>
                          </div>
                          <div className="flex gap-4 py-3">
                            <div className="w-full relative">
                              <Typography variant="body" color="tertiary" className="mb-2">Taxes (Annual)</Typography>
                              <NumericFormat
                                allowLeadingZeros
                                thousandSeparator=","
                                name="buyerAnnualTaxes"
                                value={values.buyerAnnualTaxes ? parseFloat(values.buyerAnnualTaxes.toString()).toLocaleString("en") : ""} onChange={(e) => handleInputChange(e, "number")}
                                className="body h-[42px] bg-netural rounded-sm w-full border-transparent focus:!border-[#4C42D7] focus:!ring-2 !ring-[#4C42D7]/25 !pl-8"
                              />
                              <IconWrapper name="money" className="absolute top-[54%] left-1" width={20} />
                            </div>
                            <div className="w-full relative">
                              <Typography variant="body" color="tertiary" className="mb-2">Insurance (Annual)</Typography>
                              <NumericFormat
                                allowLeadingZeros
                                thousandSeparator=","
                                name="buyerAnnualInsurance"
                                value={values.buyerAnnualInsurance ? parseFloat(values.buyerAnnualInsurance.toString()).toLocaleString("en") : ""} onChange={(e) => handleInputChange(e, "number")}
                                className="body h-[42px] bg-netural rounded-sm w-full border-transparent focus:!border-[#4C42D7] focus:!ring-2 !ring-[#4C42D7]/25 !pl-8"
                              />
                              <IconWrapper name="money" className="absolute top-[54%] left-1" width={20} />
                            </div>
                          </div>
                          <div className="flex gap-4 py-3">
                            <div className="w-full relative">
                              <Typography variant="body" color="tertiary" className="mb-2">HOA Dues (Annual)</Typography>
                              <NumericFormat
                                allowLeadingZeros
                                thousandSeparator=","
                                name="buyerAnnualHOADues"
                                value={values.buyerAnnualHOADues ? parseFloat(values.buyerAnnualHOADues.toString()).toLocaleString("en") : ""} onChange={(e) => handleInputChange(e, "number")}
                                className="body h-[42px] bg-netural rounded-sm w-full border-transparent focus:!border-[#4C42D7] focus:!ring-2 !ring-[#4C42D7]/25 !pl-8"
                              />
                              <IconWrapper name="money" className="absolute top-[54%] left-1" width={20} />
                            </div>
                            <div className="w-full relative">
                              <Typography variant="body" color="tertiary" className="mb-2">Mortgage Rate</Typography>
                              <NumericFormat
                                allowLeadingZeros
                                name="buyerMortgageRate"
                                value={values.buyerMortgageRate ? parseFloat(values.buyerMortgageRate.toString()).toLocaleString("en") : ""} onChange={(e) => handleInputChange(e, "number")}
                                className="body h-[42px] bg-netural rounded-sm w-full border-transparent focus:!border-[#4C42D7] focus:!ring-2 !ring-[#4C42D7]/25 !pl-8"
                              />
                              <IconWrapper name="percentage" className="absolute top-[54%] left-1" width={20} />
                            </div>
                          </div>
                          <div className="flex gap-4 py-3">
                            <div className="w-full relative">
                              <Typography variant="body" color="tertiary" className="mb-2">Other Expenses (Annual)</Typography>
                              <NumericFormat
                                allowLeadingZeros
                                thousandSeparator=","
                                name="buyerAnnualOtherExpenses"
                                value={values.buyerAnnualOtherExpenses ? parseFloat(values.buyerAnnualOtherExpenses.toString()).toLocaleString("en") : ""} onChange={(e) => handleInputChange(e, "number")}
                                className="body h-[42px] bg-netural rounded-sm w-full border-transparent focus:!border-[#4C42D7] focus:!ring-2 !ring-[#4C42D7]/25 !pl-8"
                              />
                              <IconWrapper name="money" className="absolute top-[54%] left-1" width={20} />
                            </div>
                            <div className="w-full relative">
                              <Typography variant="body" color="tertiary" className="mb-2">Lender Credit</Typography>
                              <NumericFormat
                                allowLeadingZeros
                                thousandSeparator=","
                                name="buyerLenderCredit"
                                value={values.buyerLenderCredit ? parseFloat(values.buyerLenderCredit.toString()).toLocaleString("en") : ""} onChange={(e) => handleInputChange(e, "number")}
                                className="body h-[42px] bg-netural rounded-sm w-full border-transparent focus:!border-[#4C42D7] focus:!ring-2 !ring-[#4C42D7]/25 !pl-8"
                              />
                              <IconWrapper name="money" className="absolute top-[54%] left-1" width={20} />
                            </div>
                          </div>
                        </div>
                      )}
                      {((values.leadType === "Seller" && currentStep === 4) || currentStep === 5) && (
                        <div className="mb-5">
                          <div className="py-3">
                            <Typography variant="h4" color="primary" className="mb-2">Notes</Typography>
                          </div>
                          <div className="flex gap-4 py-3">
                            <div className="w-full">
                              <Typography variant="body" color="tertiary" className="mb-2">Last Contact Date</Typography>
                              <DatePicker name="lastContact" value={values.lastContact} onChange={(date) => handleDateChange(date, "lastContact")} />
                            </div>
                            <div className="w-full">
                              <Typography variant="body" color="tertiary" className="mb-2">Next Contact Date</Typography>
                              <DatePicker name="nextContact" value={values.nextContact} onChange={(date) => handleDateChange(date, "nextContact")} />
                            </div>
                          </div>
                          <div className="py-3">
                            <Typography variant="h4" color="primary">Timeframe to {values.leadType === "Seller" ? "Sell" : "Buy"}</Typography>
                          </div>
                          <div className="flex gap-4 py-3">
                            <div className="w-full">
                              <Typography variant="body" color="tertiary" className="mb-2">Start of Target Date</Typography>
                              <DatePicker name="startOfTarget" value={values.startOfTarget} onChange={(date) => handleDateChange(date, "startOfTarget")} />
                            </div>
                            <div className="w-full">
                              <Typography variant="body" color="tertiary" className="mb-2">End of Target Date</Typography>
                              <DatePicker name="endOfTarget" value={values.endOfTarget} onChange={(date) => handleDateChange(date, "endOfTarget")} />
                            </div>
                          </div>
                        </div>
                      )}
                      <div className="flex gap-4 justify-end mt-6">
                        {currentStep > 1 && (
                          <Button variant="contained" color="default" onClick={() => setCurrentStep(currentStep - 1)}>
                            <Typography variant="button1">Back</Typography>
                          </Button>
                        )}
                        {(values.leadType === "Seller" && currentStep < 4) || (values.leadType === "Buyer" && currentStep < 5) ? (
                          <Button variant="contained" color="primary" className="flex gap-3" onClick={() => setCurrentStep(currentStep + 1)}>
                            <Typography variant="button1">Next</Typography>
                            <IconWrapper name="arrow-right" width={16} />
                          </Button>
                        ) : (
                          <Button variant="contained" color="primary" className="flex gap-3" onClick={() => submitNewLead()}>
                            <Typography variant="button1">Done</Typography>
                          </Button>
                        )}
                      </div>
                    </div>
                  </Scrollbars>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default Drawer;
