import Select from "@/components/baseComponents/Select";
import Typography from "@/components/baseComponents/Typography";
import TextField from "@/components/baseComponents/TextField";
import { ListingAdvancedSearchDto } from '@/shared/interfaces/interfaces';

const occupancyStatus = [
  { value: 'Owner Occupied', label: 'Owner Occupied' },
  { value: 'Renter Occupied', label: 'Renter Occupied' },
  { value: 'Squatters', label: 'Squatters' },
  { value: 'Vacant', label: 'Vacant' }
];

const lisPendensStatus = [
  { value: "Yes", label: "Yes" },
  { value: "No", label: "No" },
  { value: "Unknown", label: "Unknown" },
];

type IProps = {
  value?: ListingAdvancedSearchDto;
  setValue?: Function;
  handleInputChange?: Function;
  handleSelectChange?: Function;
}

const Others = (props: IProps) => {
  const handleSelectChange = (value: any, name: string, isMulti?: boolean) => {
    props.handleSelectChange && props.handleSelectChange(value, name, isMulti);
  }

  const handleInputChange = (value: any, name: string) => {
    props.handleInputChange && props.handleInputChange(value, name);
  }

  return (
    <div>
      <Typography variant="h3" color="primary">Other</Typography>
      <div className="grid grid-cols-1 sm:grid-cols-2 gap-3 mt-[15px]">
        <div className="col-span-2 md:col-span-1">
          <Typography variant="caption" color="secondary">Occupancy Status</Typography>
          <Select
            options={occupancyStatus}
            name="listingOccupancyStatus"
            value={{
              value: props.value?.listingOccupancyStatus,
              label: props.value?.listingOccupancyStatus,
            }}
            onChange={(value) =>
              handleSelectChange(value, "listingOccupancyStatus")
            }
          />  
        </div>
        <div className="col-span-2 md:col-span-1">
          <Typography variant="caption" color="secondary">Lis Pendens</Typography>
          <Select
            options={lisPendensStatus}
            name="listingLisPendens"
            value={{
              value: props.value?.listingLisPendens,
              label: props.value?.listingLisPendens,
            }}
            onChange={(value) =>
              handleSelectChange(value, "listingLisPendens")
            }
          />
        </div>
      </div>
      <div className="grid grid-cols-1 sm:grid-cols-2 gap-3 mt-[15px]">
        <div className="col-span-2 md:col-span-1">
          <Typography variant="caption" color="secondary">MLS Number</Typography>
          <TextField
            name="listingMlsNumber"
            value={props.value?.listingMlsNumber || ""}
            onChange={(e) => handleInputChange(e.target.value, "listingMlsNumber")}
          />
        </div>
      </div>
    </div>
  )
}

export default Others;