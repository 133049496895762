type Props = {
  width: number;
  height: number;
  stroke?: string;
  className?: string;
};

const IconDashboard = (props: Props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      fill="none"
      {...props}
    >
      <path
        d="M12 14L16 10"
        stroke="#A3A9BA"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.34 19C2.46222 17.4798 2.00007 15.7554 2 14C1.99993 12.2445 2.46195 10.52 3.33962 8.99979C4.21729 7.47954 5.47967 6.21711 6.99989 5.33938C8.52011 4.46166 10.2446 3.99957 12 3.99957C13.7554 3.99957 15.4799 4.46166 17.0001 5.33938C18.5203 6.21711 19.7827 7.47954 20.6604 8.99979C21.538 10.52 22.0001 12.2445 22 14C21.9999 15.7554 21.5378 17.4798 20.66 19"
        stroke="#A3A9BA"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default IconDashboard;
