import { useEffect, useState } from 'react'
import Typography from '@/components/baseComponents/Typography'
import IconWrapper from '@/components/baseComponents/IconWrapper'
import {
    DragDropContext,
    Droppable,
    DroppableProvided,
    Draggable,
    DraggableProvided,
    DraggableStateSnapshot
} from 'react-beautiful-dnd';
import { Button } from '@/components/baseComponents/Button';
import Drawer from './Drawer';
import Modal from './Modal';
import Client from './common/Client';
import Agent from './common/Agent';
import LoanOfficer from './common/LoanOfficer';
import Attorney from './common/Attorney';
import TitleRep from './common/TitleRep';

let tabs = [
    { name: 'All' },
    { name: 'On Track' },
    { name: 'In Danger' },
    { name: 'Off Track' }
]

let mockData = [
    {
        action: "Offer Negotiation",
        status: "In Danger",
        seller: "Kerr",
        buyer: "Minguez",
        address: "7520 Miami View Dr.North Bay Village, FL 33141",
        avatar: "https://s3-alpha-sig.figma.com/img/d1db/1a8d/0fa0e270d5e7202e0296db3923ac73a7?Expires=1703462400&Signature=MaEz58uUTYEil3hZvWtzka5Xt58VkEjoqtKiHg1oQwr2sGLHh3Me1cdecRUNB4YrsTEfwy1f0mNldtX2t9e9PxhmdlMgUMZwTMfUAG-QBfU~4WZ9Q3Ml9EjqAIW9Ya2H~RxNnwnYbKOyzUt~1~ZsAHfjmLh-NUtPfBtvaJX62klS5HTHUoYxg9IlNQw3jWAywWQ7S2VvdOYOKXer9rcGeUHelOH048OCWIrePJ2CThk8mO8L80C9RJcFPlu6-IEevcZ24zl2Q8fVBpYWFUI2kAics530wNNrEmX-odXLojc6yNx~cT9GprMNJ4HRaDPibSZGuewBI4i08nVJfW5L0A__&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4",
        due: "Due in 30 hours",
        onTrackProgress: "50",
        inDangerProgress: "75",
        offTrackProgress: "100",
        comments: 0,
        files: 28,
        order: 1,
    },
    {
        action: "Offer Negotiation",
        status: "On Track",
        seller: "Pierre",
        buyer: "Acosta",
        address: "7520 W Treasure Dr. North Bay Village, FL 33141",
        avatar: "https://s3-alpha-sig.figma.com/img/c7ad/17c1/6f1b5ebcd8e2add95ee64d15d77afb8d?Expires=1703462400&Signature=PCXzHVXL3RWyCO689ZVlV884xKu4x~wmhxhhMKPJwuB0k03FC-M7fOuEs~GvXrVoqckA~9bqmLAZJ-WmM6rrWsLL64MIMxYSowUjqaBQawUutEsGEX2QxJ~GrUIGyRob~BNE9n~BT6hFUhSq-x8gqmor7m645gjjmKmdtF5YDy~Z6Hm-ZDpb9bFKH9bp5o9bJ5K53SBPyQDYJE8WvrcBYJcxWfYIRVmpThzR75jkqgjaDFzv3mNXXMYd95ISEs9Pwt1lgdtoiyARSyh7x7x7du9E~snwMZOwU9Y87AqRqe4tbuhEXs12Hg~s0enojlU6d3TN7RxhMHM-XzjA2kA68Q__&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4",
        due: "Due in 30 hours",
        onTrackProgress: 25,
        inDangerProgress: 0,
        offTrackProgress: 0,
        comments: 3,
        files: 9,
        order: 3,
    },
    {
        action: "Offer Negotiation",
        status: "Off Track",
        seller: "West",
        buyer: "Rose",
        address: "7520 Miami View Dr. North Bay Village, FL 33141",
        avatar: "https://s3-alpha-sig.figma.com/img/3995/e7ce/0dc20fddbcd4e13d83e2796de556ddd9?Expires=1703462400&Signature=FeXGOuiIlVDNBybEBeDAV222qS1iz4lCQfIRdXStCsAcoQTsP1pKccXu6eWq7wzze~6GRlHS99zKbORR6YwBxs8phX2L4a2Ra5qYvTC36fPseLrOlEhqxXY8xonH9QDzMetKxmaMijXpwXebJdwX2WsKk19CyXwcaJhwmzZk2-MO3jouS1fuFEshGeLhY5Jkmgp~pACzvP4se4k40chPS5Cu6Sv5edxBVEItgdfYMkycbHdt1a6fu1OFk1QQ~YBcXgFlE6gpVa0Tl7rNs6si8rHU8NqOxdQ1vhEgQxg96MUETA14xHwkml2eY8-NVzPq3f~SLzPQUahniQ4cxmXzpA__&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4",
        due: "Due in 30 hours",
        onTrackProgress: 90,
        inDangerProgress: 0,
        offTrackProgress: 100,
        comments: 23,
        files: 0,
        order: 2,
    }
]

type ICardProps = {
    data: any,
    provided: DraggableProvided,
    snapshot: DraggableStateSnapshot,
}

const Transactions = () => {

    const [currentTab, setCurrentTab] = useState<string>("All")
    const [data, setData] = useState<any>([])
    const [drawer, setDrawer] = useState<{ open: boolean, type?: string }>({ open: false, type: "add" })
    const [isModalOpen, setModalOpen] = useState<boolean>(false)

    const getItemStyle = (draggableStyle: any) => ({
        userSelect: "none",
        ...draggableStyle
    });

    const reorder = (items: any, sourceItemIndex: number, destinationItemIndex: number) => {
        const sourceItem = { ...items[sourceItemIndex] }
        const destinationItem = { ...items[destinationItemIndex] }


        if (items.length > 0) {
            if (sourceItem.order !== destinationItem.order) {
                if (sourceItem.order < destinationItem.order) {
                    items.map((item: any) => {
                        if (item.order > sourceItem.order && item.order <= destinationItem.order) {
                            item.order--
                        }
                    })
                } else {
                    items.map((item: any) => {
                        if (item.order >= destinationItem.order && item.order <= sourceItem.order) {
                            item.order++
                        }
                    })
                }

                items[sourceItemIndex].order = destinationItem.order
            }
        } else {
            console.log(items)
        }

        return items;
    };

    const onDragEnd = (result: any) => {
        const { source, destination } = result;

        if (!destination) {
            return;
        }

        if (source.droppableId === destination.droppableId) {
            const filteredItems = data.filter((item: any) => item.action !== source.droppableId)
            const items = data.filter((item: any) => item.action === source.droppableId).sort((a: any, b: any) => a.order - b.order)

            const reorderedItems = reorder(
                items,
                source.index,
                destination.index
            );

            setData([...filteredItems, ...reorderedItems])
        }

        if (source.droppableId !== destination.droppableId) {
            const sourceItems = data.filter((item: any) => item.action === source.droppableId).sort((a: any, b: any) => a.order - b.order)
            const destinationItems = data.filter((item: any) => item.action === destination.droppableId).sort((a: any, b: any) => a.order - b.order)
            const sourceItem = sourceItems[source.index]
            const destinationItem = destinationItems[destination.index]

            sourceItem.action = destination.droppableId

            if (destinationItems.length === 0) {
                sourceItems.map((item: any) => {
                    if (item.order > sourceItem.order) {
                        item.order--
                    }
                })

                sourceItem.order = 1
            } else {
                if (destinationItem) {
                    sourceItems.map((item: any) => {
                        if (item.order > sourceItem.order) {
                            item.order--
                        }
                    })

                    sourceItem.order = destinationItem.order

                    destinationItems.map((item: any) => {
                        if (item.order >= destinationItem.order) {
                            item.order++
                        }
                    })
                } else {
                    sourceItem.order = (destinationItems.length + 1)
                }
            }
        }
    }

    const StatusBadge = ({ status }: { status: string }) => {
        let statusBadgeClassnames = "bg-[#B5E2C4] text-[#6DA172]"

        if (status === "In Danger") {
            statusBadgeClassnames = "bg-[#CCD01C40] bg-opacity-25 text-[#3C3C3CA6]"
        } else if (status === "Off Track") {
            statusBadgeClassnames = "bg-[#C84156] bg-opacity-25 text-[#C84156]"
        }

        let statusBadge = <Typography variant="caption" className={`font-semibold uppercase  rounded-md px-2 py-1 ${statusBadgeClassnames}`}>{status}</Typography>

        return statusBadge
    }

    const Card = ({ data, provided }: ICardProps) => {
        return (
            <div
                ref={provided.innerRef}
                {...provided.draggableProps}
                {...provided.dragHandleProps}
                className={`flex flex-col p-[15px] rounded-md shadow-lg`}
                style={getItemStyle(
                    provided.draggableProps.style
                )}
                onClick={() => setDrawer({ open: true })}
            >
                <div className="flex justify-between items-center mb-[25px]">
                    <StatusBadge status={data.status} />
                    <div className="group w-5 h-5" onClick={(e) => { e.stopPropagation(); setModalOpen(true) }}>
                        <IconWrapper name="eye" className="group-hover:[&>svg>path]:!fill-[#294661] " width={15} />
                    </div>
                </div>
                <div className="flex items-center gap-1 mb-[10px]">
                    <IconWrapper name="transaction-note" />
                    <Typography variant="caption" color="secondary" className="font-semibold uppercase">{data.seller} to {data.buyer}</Typography>
                </div>
                <div className="mb-[10px]">
                    <Typography variant="body" color="primary" className="line-clamp-2">{data.address}</Typography>
                </div>
                <div className="flex justify-end">
                    <img src={data.avatar} className="rounded-full" width={30} />
                </div>
                <div className="flex flex-col mb-[10px]">
                    <Typography variant="caption" color="secondary">{data.due}</Typography>
                    <div className="relative w-full bg-gray-300 rounded-full h-1.5">
                        <div className={`absolute bg-red-300 h-1.5 rounded-full`} style={{ width: data.offTrackProgress + "%" }}></div>
                        <div className={`absolute bg-yellow-200 h-1.5 rounded-full`} style={{ width: data.inDangerProgress + "%" }}></div>
                        <div className={`absolute bg-indigo-600 h-1.5 rounded-full`} style={{ width: data.onTrackProgress + "%" }}></div>
                    </div>
                </div>
                <div className="flex gap-5">
                    <div className="flex items-center gap-1 cursor-pointer rounded-lg">
                        <IconWrapper name="comment" width={14} />
                        <Typography variant="small-text" color="secondary">{data.comments} comments</Typography>
                    </div>
                    <div className="flex items-center gap-1 cursor-pointer rounded-lg">
                        <IconWrapper name="folder" width={13} />
                        <Typography variant="small-text" color="secondary">{data.files} files</Typography>
                    </div>
                </div>
            </div>
        )
    }

    useEffect(() => {
        setData(mockData)
    }, [mockData])

    return (
        <div className="p-8">
            {(drawer.open || isModalOpen) && (
                <div className="bg-black bg-opacity-50 h-screen w-full fixed top-0 left-0 z-10"></div>
            )}

            <Drawer 
                open={drawer.open} 
                setDrawer={setDrawer}>
                <div className="p-8">
                    {drawer.type === "add" && (
                        <>
                            <Client />
                            <LoanOfficer addresses={[]} />
                            <Agent addresses={[]} />
                            <Attorney addresses={[]} />
                            <TitleRep addresses={[]} />
                        </>
                    )}
                </div>
            </Drawer>

            <Modal open={isModalOpen} onOpenModal={setModalOpen} />

            <div className="block">
                <div className="flex flex-col gap-2">
                    <div className='flex justify-between items-center'>
                        <Typography variant='h2' color='primary'>Transactions</Typography>
                        <Button onClick={() => setDrawer({ open: true, type: "add" })}>
                            <Typography variant='button2' className='whitespace-nowrap'>Add a Transaction</Typography>
                        </Button>
                    </div>
                    <nav className="flex justify-between" aria-label="Tabs">
                        <div className='flex items-end gap-3'>
                            {tabs.map((tab, index) => (
                                <div
                                    key={index}
                                    className={classNames(
                                        tab.name === currentTab
                                            ? 'border-indigo-500 text-indigo-600'
                                            : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700',
                                        'whitespace-nowrap border-b-2 p-1 cursor-pointer'
                                    )}
                                    aria-current={tab.name === currentTab ? 'page' : undefined}
                                    onClick={() => setCurrentTab(tab.name)}
                                >
                                    <Typography variant='page-menu'>{tab.name}</Typography>
                                </div>
                            ))}
                        </div>
                    </nav>
                </div>
                <div className="grid grid-cols-4 gap-8 mt-8">
                    <DragDropContext onDragEnd={onDragEnd}>
                        <Droppable droppableId="Offer Negotiation">
                            {(provided: DroppableProvided) => (
                                <div ref={provided.innerRef}>
                                    <Typography variant="h4">Offer Negotiation</Typography>
                                    <div className="flex flex-col gap-[25px] mt-[25px]">
                                        {data.filter((item: any) => item.action === "Offer Negotiation").sort((a: any, b: any) => a.order - b.order).map((item: any, index: number) => (
                                            <Draggable key={"offer-negotiation-" + index} draggableId={"offer-negotiation-" + index} index={index}>
                                                {(provided: DraggableProvided, snapshot: DraggableStateSnapshot) => (
                                                    <Card key={index} data={item} provided={provided} snapshot={snapshot} />
                                                )}
                                            </Draggable>
                                        ))}
                                        {provided.placeholder}
                                    </div>
                                </div>
                            )}
                        </Droppable>
                        <Droppable droppableId="Accepted Offer">
                            {(provided: DroppableProvided) => (
                                <div ref={provided.innerRef}>
                                    <Typography variant="h4" className="max-[570px]:text-12">Accepted Offer</Typography>
                                    <div className="flex flex-col gap-[25px] mt-[25px]">
                                        {data.filter((item: any) => item.action === "Accepted Offer").sort((a: any, b: any) => a.order - b.order).map((item: any, index: number) => (
                                            <Draggable key={"accepted-offer-" + index} draggableId={"accepted-offer-" + index} index={index}>
                                                {(provided: DraggableProvided, snapshot: DraggableStateSnapshot) => (
                                                    <Card key={index} data={item} provided={provided} snapshot={snapshot} />
                                                )}
                                            </Draggable>
                                        ))}
                                        {provided.placeholder}
                                    </div>
                                </div>
                            )}
                        </Droppable>
                        <Droppable droppableId="Due Diligence">
                            {(provided: DroppableProvided) => (
                                <div ref={provided.innerRef}>
                                    <Typography variant="h4" className="max-[570px]:text-12">Due Diligence</Typography>
                                    <div className="flex flex-col gap-[25px] mt-[25px]">
                                        {data.filter((item: any) => item.action === "Due Diligence").sort((a: any, b: any) => a.order - b.order).map((item: any, index: number) => (
                                            <Draggable key={"due-diligence-" + index} draggableId={"due-diligence-" + index} index={index}>
                                                {(provided: DraggableProvided, snapshot: DraggableStateSnapshot) => (
                                                    <Card key={index} data={item} provided={provided} snapshot={snapshot} />
                                                )}
                                            </Draggable>
                                        ))}
                                        {provided.placeholder}
                                    </div>
                                </div>
                            )}
                        </Droppable>
                        <Droppable droppableId="Open Escrow">
                            {(provided: DroppableProvided) => (
                                <div ref={provided.innerRef}>
                                    <Typography variant="h4" className="max-[570px]:text-12">Open Escrow</Typography>
                                    <div className="flex flex-col gap-[25px] mt-[25px]">
                                        {data.filter((item: any) => item.action === "Open Escrow").sort((a: any, b: any) => a.order - b.order).map((item: any, index: number) => (
                                            <Draggable key={"open-escrow-" + index} draggableId={"open-escrow-" + index} index={index}>
                                                {(provided: DraggableProvided, snapshot: DraggableStateSnapshot) => (
                                                    <Card key={index} data={item} provided={provided} snapshot={snapshot} />
                                                )}
                                            </Draggable>
                                        ))}
                                        {provided.placeholder}
                                    </div>
                                </div>
                            )}
                        </Droppable>
                    </DragDropContext>
                </div>
            </div>
        </div>
    )
}

function classNames(...classes: any) {
    return classes.filter(Boolean).join(' ')
}

export default Transactions