type Props = {
  width: number;
  height: number;
  stroke?: string;
  className?: string;
};

const IconHome = (props: Props) => {
  return (
    <svg
      viewBox="0 0 21 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g id="home_20">
        <path
          id="Vector"
          d="M3 7.4974L10.5 1.66406L18 7.4974V16.6641C18 17.1061 17.8244 17.53 17.5118 17.8426C17.1993 18.1551 16.7754 18.3307 16.3333 18.3307H4.66667C4.22464 18.3307 3.80072 18.1551 3.48816 17.8426C3.17559 17.53 3 17.1061 3 16.6641V7.4974Z"
          stroke="#6B7280"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          id="Vector_2"
          d="M8 18.3333V10H13V18.3333"
          stroke="#6B7280"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  );
};

export default IconHome;
