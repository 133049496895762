import { Fragment, useEffect, useState } from 'react';
import classNames from 'classnames';
import { Combobox, Transition } from '@headlessui/react';
import Icon from '../IconWrapper';
import TextField from '../TextField';
import Typography from '../Typography';
import { Button } from '../Button';
import { formatAddress } from '@/shared/services/utils';
import IconWrapper from '../IconWrapper';
import { getUnixTime } from 'date-fns';

type Props = {
  options: Array<any>;
  value: any;
  placeholder?: string;
  className?: string;
  inputClassName?: string;
  disabled?: boolean;
  type?: string
  onChange: (value: any) => void;
  onAllChange: (value: any) => void;
  onClear?: VoidFunction;
  onDelete?: VoidFunction;
};

const AddressAutoComplete = ({
  options = [],
  value,
  placeholder,
  className,
  inputClassName,
  disabled = false,
  type,
  onChange,
  onAllChange,
  onClear,
  onDelete,
}: Props) => {

  const [query, setQuery] = useState("");
  const [isAutocompleteLoading, setIsAutocompleteLoading] = useState<boolean>(false);
  const timestamp = getUnixTime(new Date());

  const changeInput = (event: any) => {
    event.target.autocomplete = "disabled-off-" + timestamp;
    setQuery(event.target.value);
    setIsAutocompleteLoading(true);
  }

  const changeValue = (option: any) => {
    onAllChange(option);
  }

  useEffect(() => {
    if (query !== "") {
      const delayDebounceFn = setTimeout(() => {
        onChange(query);
      }, 1000);

      return () => clearTimeout(delayDebounceFn);
    }
  }, [query]);

  useEffect(() => {
    setTimeout(() => {
      setIsAutocompleteLoading(false);
    }, 500);
  }, [options]);

  return (
    <div className={classNames(className, 'relative')}>
      <Combobox disabled={disabled} value={value} onChange={(e) => changeValue(e)}>
        {({ open }) => (
          <div className="relative">
            <Combobox.Input
              as={TextField}
              inputClassName={"aria-expanded:rounded-b-none " + inputClassName + (className?.includes('bg-white') ? ' bg-white' : '')}
              displayValue={value}
              onChange={(event) => changeInput(event)}
              placeholder={placeholder}
              autoComplete={`disabled-off-${timestamp}`}
              // onFocus={(event) => event.currentTarget.autocomplete = "disabled-off-123"}
              {...(open && query.length > 0
                ? {
                  endAdornment: (
                    <Button variant="icon" color="inherit" onClick={onClear}>
                      <Icon name="close" />
                    </Button>
                  ),
                }
                : type === 'customize' ? {
                  endAdornment: (
                    <Button variant="icon" color="inherit" onClick={onDelete}>
                      <Icon name="close" />
                    </Button>
                  ),
                } :
                  null)
              }
            />

            <Transition
              show={open && query.length > 0}
              as={Fragment}
              leave="transition-all ease-in duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
              enterFrom="opacity-0"
              enterTo="opacity-100"
            >
              <Combobox.Options className="absolute z-10 min-w-full max-h-60 py-2 overflow-y-scroll transition-opacity bg-white rounded-b-5 shadow-xl !p-0">
                {isAutocompleteLoading ? (
                  <div className="flex items-center justify-center gap-2 py-4">
                    <IconWrapper name="spinner" className="w-5 h-5 text-gray-200 animate-spin dark:text-gray-600 fill-indigo-600" />
                    <Typography variant="body" color="secondary" className="mt-0.5">
                      Loading...
                    </Typography>
                  </div>
                ) : (
                  options.length === 0 && query !== '' ? (
                    <Typography variant="body" color="secondary" className="px-4 text-center">
                      <strong>No address found</strong> <br /> Try adding a specific street on your input.
                    </Typography>
                  ) : (
                    <>
                      {options.map((option, index) => (
                        <Combobox.Option
                          key={index}
                          value={option}
                          className="flex justify-between items-center gap-x-5 cursor-pointer select-none body text-primary whitespace-nowrap px-3 py-2 hover:bg-neutral-100"
                        >
                          {option.fullAddress ? option.fullAddress : formatAddress(option)} 
                          {/* {option.entries > 1 && <Typography variant="medium-text" color="indigo">+ {option.entries} Addresses</Typography>} */}
                        </Combobox.Option>
                      ))}
                    </>
                  )
                )}
              </Combobox.Options>
            </Transition>
          </div>
        )}
      </Combobox>
    </div>
  );
};

export default AddressAutoComplete;
