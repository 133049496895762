// React imports
import { Fragment, useEffect, useState } from "react";

// Third-party library imports
import { format } from "date-fns";
import { useLocation, useNavigate, useParams } from "react-router-dom";

// Redux-related imports
import { useAppDispatch, useAppSelector } from "@/redux/hooks";
import { getShowingById, getShowings, getShowingsByUserId, showingReschedule, updateShowingStatus, updateShowingViewedStatus } from "@/redux/showing/showingSlice";
import { getUser } from "@/redux/user/userSlice";

// Component imports
import { Button } from "@/components/baseComponents/Button";
import Typography from "@/components/baseComponents/Typography";
import Filter from "@/components/mainComponents/Filter";
import Drawer from "./Drawer";

// Interface imports
import { GetShowingsDto, UpdateShowingViewedStatusDto, showingRescheduleStatusDto, updateShowingStatusDto } from "@/shared/interfaces/interfaces";

// Asset imports
import DefaultProfilePicture from "@/assets/images/default_profile_picture.webp";
import ListingImagePlaceholder from "@/assets/images/listing_image_placeholder.png";

// Utility and service imports
import AdvancedTable from "@/components/baseComponents/AdvancedTable";
import IconWrapper from "@/components/baseComponents/IconWrapper";
import Sort from "@/components/mainComponents/Sort";
import { notify } from "@/shared/services/notify";
import { Menu, Transition } from "@headlessui/react";
import { toast } from "react-toastify";
import ViewListingDrawer from "../myListings/View";

const tabs = [{ name: "All" }, { name: "Pending" }, { name: "Confirmed" }, { name: "Denied" }, { name: "Past" }] as const;

const TableFields = [
  {
    name: "",
    type: "address_image",
    slug: "image_src",
    imageClass: "w-[4rem]",
    class_name: "text-left pl-[16px] min-w-[250px] !py-[20px]"
    // class_name: "text-left pl-[16px] !py-[20px] w-max"
  },
  {
    name: "",
    type: "text",
    slug: "is_received",
    class_name: "px-2 px-[16px] text-left !py-[20px] min-w-[50px] pl-10"
  },
  {
    name: "Status",
    type: "text",
    slug: "status",
    class_name: "text-left px-[16px] !py-[20px] min-w-[100px] pl-10"
  },
  {
    name: "My Client",
    type: "text",
    slug: "client",
    class_name: "text-left px-[16px] !py-[20px] min-w-[100px] pl-[40px]"
  },
  {
    name: "Showing Date",
    type: "text",
    slug: "date",
    class_name: "text-left px-[16px] !py-[20px] min-w-[100px] pl-[40px]"
  },
  {
    name: "Agent",
    type: "text",
    slug: "agent",
    // class_name: "text-left px-[16px] !py-[20px] min-w-[200px] pl-[40px]"
    class_name: "text-left px-[16px] !py-[20px] min-w-[200px] pl-[40px] [&>p]:w-[auto] [&>p]:!block"
  },
  // { name: "", type: "action", slug: "action", class_name: "" },
];

const MobileTableFields = [
  {
    name: "",
    type: "showing_image",
    slug: {
      imageSlug: "image_src",
      statusSlug: "status",
      clientSlug: "client",
      agentSlug: "agent",
      scheduleSlug: "schedule"
    },
    imageClass: "w-[4rem]",
    class_name: "text-left pl-[16px] !py-[20px] min-w-[300px]"
  }
];

const SortFieldOptions = [
  { value: "Address", label: "Address" },
  { value: "Status", label: "Status" },
  { value: "My Client", label: "My Client" },
  { value: "Showing Date", label: "Showing Date" },
  { value: "Agent", label: "Agent" }
];

const sent_status = [
  { id: "All", title: "All" },
  { id: "Sent", title: "Sent" },
  { id: "Received", title: "Received" }
];

// function classNames(...classes: any) {
//   return classes.filter(Boolean).join(" ");
// }
const Showing = () => {
  // Hooks
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const showings = useAppSelector(getShowings);
  const user = useAppSelector(getUser);

  // State variables
  const [selectedStatus, setSelectedStatus] = useState("All");
  const [currentTab, setCurrentTab] = useState<string>("All");
  const [openView, setOpenView] = useState<boolean>(false);
  const [viewData, setViewdata] = useState<any>(null);
  const [filteredData, setFilteredData] = useState<any[]>([]);
  const [keyword, setKeyword] = useState<string>("");
  const [sortType, setSortType] = useState<string>("Descending");
  const [sortField, setSortField] = useState<string>("Showing Date");
  const [totalCount, setTotalCount] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [recordsPerPage, setRecordsPerPage] = useState<number>(25);
  const [currentSentStatus, setCurrentSentStatus] = useState<string>("All");
  const [openViewDrawer, setOpenViewDrawer] = useState<boolean>(false);
  const [openRescheduleModal, setOpenRescheduleModal] = useState<boolean>(false);
  const [viewListingData, setViewListingData] = useState<any>(null);
  const [openViewListingDrawer, setOpenViewListingDrawer] = useState<boolean>(false);

  const onSetPage = (value: number) => {
    setCurrentPage(value);
  };

  const onClickRow = (value: any) => {
    setViewdata(value);
    setOpenViewDrawer(true);

    if (!value.viewed) {
      const data: UpdateShowingViewedStatusDto = {
        showingId: value._id,
        viewed: true,
        search: {
          userId: user._id,
          keyword: keyword,
          sortType: sortType,
          sortField: sortField,
          recordsPerPage: recordsPerPage,
          currentPage: currentPage,
          status: currentTab,
          sentStatus: currentSentStatus
        }
      };

      dispatch(updateShowingViewedStatus(data)).then((res) => {
        try {
          setTotalCount(res.payload.totalCount);
          const table_data = makeTableData(res.payload.showings, currentTab);
          setFilteredData(table_data);
        } catch (e) {
          notify(false, "Something went wrong.");
        }
      });
    }
  };

  const changeKeyword = (keyword: string) => {
    setKeyword(keyword);
  };

  const changeSortType = (value: string) => {
    setSortType(value);
  };

  const changeSortField = (value: any) => {
    setSortField(value.value);
  };

  const filterData = () => {
    const data: GetShowingsDto = {
      userId: user._id,
      keyword: keyword,
      sortType: sortType,
      sortField: sortField,
      recordsPerPage: recordsPerPage,
      currentPage: currentPage,
      status: currentTab,
      sentStatus: currentSentStatus
    };
    dispatch(getShowingsByUserId(data)).then((res) => {
      try {
        setTotalCount(res.payload.totalCount);
        const table_data = makeTableData(res.payload.showings, currentTab);
        setFilteredData(table_data);
      } catch (e) {
        notify(false, "Something went wrong.");
      }
    });
  };

  const onRescheduleShowing = (item: any) => {
    setViewdata(item);
    setOpenViewDrawer(false);
    setOpenRescheduleModal(true);
  };

  // function to edit status border color and text color
  const getShowingStatusColor = (status: string | undefined) => {
    return status == "Pending"
      ? "border-pending text-pending"
      : status === "Denied"
      ? "border-denied text-denied"
      : status === "Confirmed"
      ? "border-confirmed text-confirmed"
      : "";
  };
  const makeTableData = (data: object[], currentTab: string) => {
    const res: any[] = [];
    data.map((item: any) => {
      item = JSON.parse(JSON.stringify(item));

      if (currentTab == "All" || currentTab == "Past" || currentTab == item.status) {
        // new_item.status = STATUS[item.status];
        const fontWeight = item.viewed ? "" : "!font-semibold";
        const listing = item.listing ?? {},
          listingAddress = listing.listingAddress,
          addressSub = `${listingAddress?.streetLine} ${listing.listingUnit ?? listingAddress?.secondary}`,
          addressMain = `${listingAddress?.city}, ${listingAddress.state} ${listingAddress.zipcode}`;
        let clientName = "";
        let agentAlt = "";
        let agentImage = "";
        let agentName = "";

        if (item?.owner?.agent?._id === user.agent._id) {
          clientName = `${item?.client?.firstName || ""} ${item?.client?.lastName || ""}`;
          agentAlt = "Profile Picture";
          agentImage = item?.listing?.owner?.agent.avatarURL;
          agentName = item.listing?.owner?.agent?.firstName + " " + item.listing?.owner?.agent?.lastName;
        } else {
          clientName = `${item?.listing?.client?.firstName || ""} ${item?.listing?.client?.lastName || ""}`;
          agentAlt = "Agent Avatar";
          agentImage = item?.owner?.agent.avatarURL;
          agentName = item.owner?.agent?.firstName + " " + item.owner?.agent?.lastName;
        }

        item.is_received = (
          <span className={`${fontWeight} flex items-center border border-border rounded-full px-[8px]`}>
            {item.owner?._id === user._id ? (
              <>
                <IconWrapper name="sent" width={16} />
                <span className="leading-[100%]">Sent</span>
              </>
            ) : (
              <>
                <IconWrapper name="recieved" width={16} />
                <span>Received</span>
              </>
            )}
          </span>
        );
        item.status = <span className={`${fontWeight} ${getShowingStatusColor(item.status)} border px-[8px] rounded-full py-[4px]`}>{item.status}</span>;

        item.address = listingAddress ? (
          <span className={`block ${fontWeight}`}>
            {listing.isPublic === 1 && <span className="block truncate">{addressSub}</span>}
            <span className={`block truncate ${listing.isPublic === 1 ? `text-tertiary` : `text-primary`}`}>{addressMain}</span>
          </span>
        ) : (
          <span className={`block !line-clamp-2 !whitespace-normal ${fontWeight}`}>Address Kept Private</span>
        );

        item.client = (
          <span className="flex flex-col">
            <span className={`${fontWeight}`}>{clientName}</span>
            <span className={`${fontWeight}`}>{item?.listing?.client?.leadType ?? ""}</span>
          </span>
        );
        item.agent = (
          <span className={`flex items-center border border-border px-2 py-1 gap-1 rounded-full whitespace-nowrap w-full`}>
            <img
              alt={agentAlt}
              className="w-5 aspect-square rounded-full"
              src={agentImage || DefaultProfilePicture}
              onError={(e) => (e.currentTarget.src = DefaultProfilePicture)}
            />
            <span className={`${fontWeight}`}>{agentName}</span>
          </span>
        );
        item.date = item.dateTime ? (
          <span className={`flex flex-col ${fontWeight}`}>
            <span>{format(new Date(item.dateTime), "EEEE, MM/dd/yy")}</span>
            <span> at {item.dateTime ? format(new Date(item.dateTime), "p") : ""}</span>
          </span>
        ) : (
          ""
        );
        item.time = <span className={`${fontWeight}`}>{item.dateTime ? format(new Date(item.dateTime), "p") : ""}</span>;
        item.image_src = listing.propertyPhotos?.[0]?.file ?? ListingImagePlaceholder;
        item.createdAt = <span className={`${fontWeight}`}>{item.createdAt ? format(new Date(item.createdAt), "MM/dd/yy hh:mm a") : ""}</span>;

        res.push(item);
      }
    });
    return res;
  };

  const goPage = (page: string) => {
    navigate("/" + page);
  };

  const updateStatus = (status: string, remarks: string = "") => {
    if (status === "Confirmed" || status === "Denied") {
      let actionToast = toast.loading("Processing your request...");

      const data: updateShowingStatusDto = {
        showingId: viewData?._id,
        status: status,
        remarks: remarks,
        userId: user._id,
        search: {
          userId: user._id,
          keyword: keyword,
          sortType: sortType,
          sortField: sortField,
          recordsPerPage: recordsPerPage,
          currentPage: currentPage,
          status: currentTab,
          sentStatus: currentSentStatus
        }
      };

      dispatch(updateShowingStatus(data)).then((res: any) => {
        try {
          setTotalCount(res.payload.totalCount);
          const table_data = makeTableData(res.payload.showings, currentTab);
          setFilteredData(table_data);
          setOpenView(false);
          setOpenViewDrawer(false);
          toast.update(actionToast, {
            render: res.payload.message,
            type: "success",
            isLoading: false,
            autoClose: 3000
          });
        } catch (e) {
          toast.update(actionToast, {
            render: "Something went wrong while archiving your listing. Please try again.",
            type: "error",
            isLoading: false,
            autoClose: 3000
          });
        }
      });
    }
  };

  const reschedule = (dateTime: Date, remarks: string = "") => {
    let rescheduleToast = toast.loading("Rescheduling showing...");
    const data: showingRescheduleStatusDto = {
      showingId: viewData?._id,
      remarks: remarks,
      userId: user._id,
      dateTime: dateTime,
      search: {
        userId: user._id,
        keyword: keyword,
        sortType: sortType,
        sortField: sortField,
        recordsPerPage: recordsPerPage,
        currentPage: currentPage,
        status: currentTab,
        sentStatus: currentSentStatus
      }
    };

    dispatch(showingReschedule(data)).then((res: any) => {
      try {
        setTotalCount(res.payload.totalCount);
        const table_data = makeTableData(res.payload.showings, currentTab);
        setFilteredData(table_data);
        setOpenRescheduleModal(false);
        toast.update(rescheduleToast, {
          render: res.payload.message,
          type: "success",
          isLoading: false,
          autoClose: 3000
        });
      } catch (e) {
        toast.update(rescheduleToast, {
          render: "Something went wrong. Please try again later.",
          type: "error",
          isLoading: false,
          autoClose: 3000
        });
      }
    });
  };

  const sentStatusFilter = (value: string) => {
    //using the currentSentStatus to track currently selected status
    setSelectedStatus(value);
    setCurrentSentStatus(value);
  };

  const openShowingDrawerById = (id: string) => {
    if (id !== null || id !== "") {
      dispatch(getShowingById({ id: id }))
        .then((res) => {
          if (res.payload.success) {
            const data = res.payload.data;
            var showing;
            if (Array.isArray(data)) {
              showing = data;
            } else {
              showing = [data];
            }

            const showingData = makeTableData(showing, "All");

            if (showingData && showingData.length > 0 && showingData[0]) {
              setViewdata(showingData[0]);
              setOpenViewDrawer(true);
            }
          } else {
            notify(false, "Something went wrong.");
          }
        })
        .catch(() => {
          notify(false, "Something went wrong.");
        });
    }
  };

  const onViewListing = () => {
    setOpenViewListingDrawer(true);
    setViewListingData(viewData.listing);
  };

  const location = useLocation();
  const urlState = location.state;

  useEffect(() => {
    if (urlState && urlState.id && urlState.id !== "") {
      openShowingDrawerById(urlState.id);
    }
  }, [urlState]);

  useEffect(() => {
    if (id && id !== "") {
      openShowingDrawerById(id);
    }
  }, [id]);

  useEffect(() => {
    if (showings) {
      filterData();
    }
  }, [currentTab, currentSentStatus]);

  useEffect(() => {
    setRecordsPerPage(25);
    const data: GetShowingsDto = {
      userId: user._id,
      keyword: keyword,
      sortType: sortType,
      sortField: sortField,
      recordsPerPage: recordsPerPage,
      currentPage: currentPage,
      status: currentTab,
      sentStatus: currentSentStatus
    };
    dispatch(getShowingsByUserId(data)).then((res: any) => {
      try {
        const table_data = makeTableData(res.payload.showings, currentTab);
        setFilteredData(table_data);
        setTotalCount(res.payload.totalCount);
      } catch (e) {
        notify(false, "Something went wrong.");
      }
    });
  }, [currentPage]);

  return (
    <div>
      {(openView || openViewDrawer || openRescheduleModal) && (
        <div className="!bg-[#00000040] h-screen w-full fixed top-0 left-0 z-20" onClick={() => setOpenViewDrawer(false)}></div>
      )}
      <Drawer
        open={openViewDrawer}
        changeState={() => setOpenViewDrawer(false)}
        data={viewData}
        reschedule={reschedule}
        updateStatus={updateStatus}
        onViewListing={onViewListing}
      />

      <ViewListingDrawer data={viewListingData} open={openViewListingDrawer} changeState={setOpenViewListingDrawer} />

      <div className="px-6 pt-10 pb-[30vh] shadow-sm-light w-full">
        <div className="flex flex-col gap-3 w-full">
          <div className="flex justify-between lg:items-center w-full max-sm:flex-col">
            <div className="flex items-center gap-1 md:gap-3">
              <Typography variant="h2" color="primary" className="whitespace-nowrap">
                Showings
              </Typography>

              <Menu>
                <div className="relative w-full ">
                  <Menu.Button className="relative w-fit flex gap-1 cursor-default rounded-full text-left">
                    <div className="cursor-pointer text-[#9CA3AF] flex items-center gap-[4px] w-fit">
                      <Typography variant="body" color="secondary" className="whitespace-nowrap">
                        {currentTab} Status
                      </Typography>
                      <IconWrapper name="arrow-up" width={16} innerStroke="#6B7280" className=" rotate-90" />
                    </div>
                  </Menu.Button>

                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95">
                    <Menu.Items className="z-10 absolute top- origin-top-right left-0  min-h-60 w-fit min-w-[120px] overflow-hidden bg-white rounded-8 text-base shadow-lg">
                      {tabs.map((tab: any) => (
                        <Menu.Item as={Fragment} key={tab.name}>
                          {({ active }) => (
                            <div key={tab.name} className="flex flex-col items-start ">
                              <button
                                className={` p-3 w-full text-start text-primary text-14 ${active ? "bg-[#F3F4F6]" : "bg-white "}`}
                                onClick={() => {
                                  setCurrentTab(tab.name);
                                }}>
                                {tab.name}
                              </button>
                            </div>
                          )}
                        </Menu.Item>
                      ))}
                    </Menu.Items>
                  </Transition>
                </div>
              </Menu>
            </div>

            <div className="lg:ml-auto flex lg:items-center w-fit md:gap-3">
              <div className="flex items-center gap-3">
                <Menu>
                  <div className="relative w-full ">
                    <Menu.Button className="relative w-fit flex gap-1 cursor-default rounded-full text-left">
                      <div className="cursor-pointer text-[#9CA3AF] flex items-center px-1.5 py-2.5 gap-2.5 w-fit">
                        <Typography className="body" color="secondary">
                          Show:
                        </Typography>
                        <div className="flex items-center gap-0.5">
                          <Typography variant="body" color="secondary">
                            {selectedStatus}
                          </Typography>
                          <IconWrapper name="arrow-down" width={16} innerStroke="#6B7280" />
                        </div>
                      </div>
                    </Menu.Button>

                    <Transition
                      as={Fragment}
                      enter="transition ease-out duration-100"
                      enterFrom="transform opacity-0 scale-95"
                      enterTo="transform opacity-100 scale-100"
                      leave="transition ease-in duration-75"
                      leaveFrom="transform opacity-100 scale-100"
                      leaveTo="transform opacity-0 scale-95">
                      <Menu.Items className="z-10 absolute top- origin-top-right left-0  min-h-60 min-w-[160px] w-fit overflow-hidden bg-white rounded-8 text-base shadow-lg">
                        {sent_status.map((status: any) => (
                          <Menu.Item as={Fragment} key={status.id}>
                            {({ active }) => (
                              <div key={status.id} className="flex flex-col items-start text-primary text-14">
                                <button
                                  className={`p-3 w-full text-start ${active ? "bg-[#F3F4F6]" : "bg-white"}`}
                                  onClick={() => {
                                    sentStatusFilter(status.title);
                                  }}>
                                  {status.title}
                                </button>
                              </div>
                            )}
                          </Menu.Item>
                        ))}
                      </Menu.Items>
                    </Transition>
                  </div>
                </Menu>
                <div className="flex items-center justify-between w-full">
                  <div className="flex items-center gap-4">
                    <Filter changeKeyword={changeKeyword} keyword={keyword} filterLeads={filterData} />
                    <Sort
                      options={SortFieldOptions}
                      sortType={sortType}
                      sortField={sortField}
                      changeSortField={changeSortField}
                      changeSortType={changeSortType}
                      filterLeads={filterData}
                    />
                  </div>
                </div>
              </div>
              <div className="hidden md:block">
                <Button variant="contained" onClick={() => goPage("listings")}>
                  <Typography variant="button1">Search Listings</Typography>
                </Button>
              </div>
            </div>
          </div>

          <div className="block md:hidden">
            <Button className="w-full" onClick={() => goPage("listings")}>
              <Typography variant="button2">Search Listings</Typography>
            </Button>
          </div>
        </div>
        <div className="max-sm:pt-3 pt-8">
          <AdvancedTable
            minCellWidth={180}
            class_name="custom-table table items-center"
            data={filteredData}
            fields={TableFields}
            mobileFields={MobileTableFields}
            userID={user._id}
            onClickRow={onClickRow}
            totalPage={Math.ceil(totalCount / recordsPerPage)}
            totalCount={totalCount}
            currentPage={currentPage}
            recordsPerpage={recordsPerPage}
            onSetPage={onSetPage}
            rescheduleRow={onRescheduleShowing}
            emptyStateProps={{
              heading: "Request a Showing",
              content: "Search for the listing you want to request to show.",
              buttonText: "Search Listings",
              onButtonClick: () => goPage("listings")
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default Showing;
