type Props = {
  width: number;
  height: number;
  stroke?: string;
  className?: string;
};

const IconCool = (props: Props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      viewBox="0 0 20 21"
      fill="none"
    >
      <path
        d="M5.00008 10.207H3.33341C2.89139 10.207 2.46746 10.0314 2.1549 9.71888C1.84234 9.40631 1.66675 8.98239 1.66675 8.54036V4.3737C1.66675 3.93167 1.84234 3.50775 2.1549 3.19519C2.46746 2.88263 2.89139 2.70703 3.33341 2.70703H16.6667C17.1088 2.70703 17.5327 2.88263 17.8453 3.19519C18.1578 3.50775 18.3334 3.93167 18.3334 4.3737V8.54036C18.3334 8.98239 18.1578 9.40631 17.8453 9.71888C17.5327 10.0314 17.1088 10.207 16.6667 10.207H15.0001"
        stroke="#6B7280"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5 6.875H15"
        stroke="#6B7280"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.2501 14.957C15.6027 15.3257 15.8077 15.8109 15.8264 16.3207C15.8451 16.8305 15.6761 17.3294 15.3515 17.7229C15.0268 18.1163 14.5691 18.377 14.065 18.4555C13.561 18.534 13.0457 18.4248 12.6167 18.1487C12.3379 17.9658 12.1063 17.7194 11.9412 17.4297C11.7761 17.1399 11.682 16.8152 11.6667 16.482V10.207"
        stroke="#6B7280"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.50008 13.207C5.30317 13.4001 5.15724 13.639 5.07537 13.9023C4.99351 14.1657 4.97827 14.4452 5.03102 14.7159C5.08377 14.9866 5.20286 15.2399 5.37761 15.4533C5.55237 15.6666 5.77733 15.8332 6.03233 15.9382C6.28733 16.0432 6.5644 16.0833 6.8387 16.0549C7.113 16.0265 7.37596 15.9304 7.60401 15.7754C7.83206 15.6203 8.01808 15.4111 8.14538 15.1665C8.27268 14.9218 8.33729 14.6494 8.33341 14.3737V10.207"
        stroke="#6B7280"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default IconCool;
