// Import necessary modules and components
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from '../store';
import axios from '@/shared/services/axios';
import { IHomepageFeed, CreateHomepageFeedDto, GetHomepageFeedsDto, DeleteHomepageFeedsDto } from '@/shared/interfaces/interfaces';

// Define the state for homepage feeds
interface HomepageFeedState {
  homepageFeeds: Array<IHomepageFeed>;
}

// Define the initial state
const initialState: HomepageFeedState = {
  homepageFeeds: [],
};

// Define the slice for homepage feeds
export const homepageFeedSlice = createSlice({
  name: 'homepageFeeds',
  initialState,
  reducers: {
    setTest: (state, action: PayloadAction<any>) => {
      state.homepageFeeds = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(createHomepageFeed.fulfilled, (state, action) => {
      if(action.payload.success){
        state.homepageFeeds = action.payload.homepageFeeds;
      }
    });
    builder.addCase(getHomepageFeedsFromDB.fulfilled, (state, action) => {
      if(action.payload.success){
        state.homepageFeeds = action.payload.homepageFeeds;
      }
    });
    builder.addCase(updateHomepageFeed.fulfilled, (state, action) => {
      if(action.payload.success){
        state.homepageFeeds = action.payload.homepageFeeds;
      }
    });
    builder.addCase(deleteHomepageFeeds.fulfilled, (state, action) => {
      if(action.payload.success){
        state.homepageFeeds = action.payload.homepageFeeds;
      }
    });
  },
});

// Export the actions
export const homepageFeedsAction = homepageFeedSlice.actions;

// Define the selector for getting homepage feeds
export const getHomepageFeeds = (state: RootState) => 
  state.homepageFeed.homepageFeeds;

// Export the reducer
export default homepageFeedSlice.reducer;

// Define the async thunk for creating a homepage feed
export const createHomepageFeed = createAsyncThunk(
  'homepageFeed/create',
  async (data: CreateHomepageFeedDto) => {
    const response = await axios.post('homepage/feed/create', data);
    return response.data;
  }
);

// Define the async thunk for updating a homepage feed
export const updateHomepageFeed = createAsyncThunk(
  'homepageFeed/update',
  async (data: any) => {
    const response = await axios.put('homepage/feed/update', data);
    return response.data;
  }
);

// Define the async thunk for getting homepage feeds from the database
export const getHomepageFeedsFromDB = createAsyncThunk(
  'homepageFeed/get',
  async (data: GetHomepageFeedsDto) => {
    const response = await axios.post('homepage/feed/get', data);
    return response.data;
  }
);

// Define the async thunk for deleting homepage feeds
export const deleteHomepageFeeds = createAsyncThunk(
  'homepageFeed/delete',
  async (data: DeleteHomepageFeedsDto) => {
    const response = await axios.post('homepage/feed/delete', data);
    return response.data;
  }
);