import Typography from "@/components/baseComponents/Typography"
import TextField from '@/components/baseComponents/TextField'
import ListingImagePlaceholder from '@/assets/images/listing_image_placeholder.png'
import { MdOutlineSearch } from 'react-icons/md'
import { IMyListing } from '@/shared/interfaces/interfaces'
import Preview from "@/pages/myListings/common/Preview"
import Tooltip from "@/components/mainComponents/ToolTip"

type IProps = {
  selectedlisting?: IMyListing;
  search_list_flag?: boolean;
  addressKeyword?: string;
  searchListings: Function;
  listingsForOffer?: Array<IMyListing>;
  selectListingRecord: Function;
  selectedIndex?: any;
};

const Listing = (props: IProps) => {

  return (
    <div>
      {!props.selectedlisting && props.search_list_flag && (
        <div className="px-4 sm:px-[2.5rem] mt-[20px]">
          <div className="flex mb-4 relative">
            <TextField
              inputClassName={
                "!border-b-1 border-t-0 border-x-0 !border-[#8E9CB2] bg-transparent rounded-none px-2"
              }
              value={props.addressKeyword}
              onChange={(e) => props.searchListings(e.target.value)}
              placeholder="Enter an Address or MLS Number"
            />
            <MdOutlineSearch className="absolute top-3 right-2 text-[#8E9CB2] text-20" />
          </div>
          <div className="grid grid-cols-4 mt-[25px] bg-secondary bg-opacity-20 py-1 px-2">
            <Typography variant="table-headers" color="primary" className="col-span-2 px-2">Address</Typography>
            <Typography variant="table-headers" color="primary" className="px-2">MLS Number</Typography>
            <Typography variant="table-headers" color="primary" className="px-2">Listing Agent</Typography>
          </div>
          <div className="my-[25px]">
            {props.addressKeyword !== "" &&
              props.listingsForOffer &&
              props.listingsForOffer.length > 0 ?
              props.listingsForOffer.map((item: any, index: number) => {
                return (
                  <div className="grid grid-cols-4 items-center py-2 px-2 border-b-1 cursor-pointer hover:bg-gray-200" key={index} onClick={() => props.selectListingRecord(item, index)}>
                    <div className="col-span-2">
                      <Typography variant="body" color="primary" className="line-clamp-1 px-2">{item.listingAddress?.streetLine + (item.listingAddress?.streetLine ? " " + item.listingAddress?.secondary : "")}</Typography>
                      <Typography variant="body" color="primary" className="line-clamp-1 px-2">{item.listingAddress?.city + ", " + item.listingAddress?.state + (item.listingAddress?.zipcode ? " " + item.listingAddress?.zipcode : "")}</Typography>
                    </div>
                    <Typography variant="body" color="primary" className="px-2">{item.listingMlsNumber || "None"}</Typography>
                    <div className="flex items-center gap-2 px-2">
                      <img src={item.owner?.agent?.avatarURL || ListingImagePlaceholder} className="w-6 rounded-full" />
                      <Tooltip text={`${item.owner?.agent?.firstName} ${item.owner?.agent?.lastName}`}>
                        <Typography variant="body" color="primary" className="line-clamp-1">{item.owner?.agent?.firstName} {item.owner?.agent?.lastName}</Typography>
                      </Tooltip>
                    </div>
                  </div>
                );
              }) : (
                <Typography variant="medium-text" color="secondary" className="px-4 text-center">
                  <strong>No listings found</strong> <br /> Try being specific in your Address or MLS Number.
                </Typography>
              )}
          </div>
        </div>
      )}
      {props.selectedlisting && (
        <Preview data={props.selectedlisting} isPublished={false} />
      )}
    </div>
  );
};

export default Listing