import Typography from "@/components/baseComponents/Typography";
import LandingFilter from "@/components/mainComponents/Landing/LandingFilter";
import ListingCard from "@/components/mainComponents/Landing/ListingCard";
import PropertyFilter from "@/components/mainComponents/Landing/PropertyFilter";
import { BiSearch } from "react-icons/bi";
import { useAppDispatch } from "@/redux/hooks";
import React, { useEffect, useState } from "react";
import { IMyListing } from "@/shared/interfaces/interfaces";
import ListingCardSkeleton from "../../components/mainComponents/Landing/ListingCardSkeleton";
import { useSearchParams } from "react-router-dom";
import ListingDrawer from "./ListingDrawer";
import { getListingsByAdvancedSearch } from "@/redux/myListings/myListingSlice";
import { ListingAdvancedSearchDto } from "@/shared/interfaces/interfaces";
import LandingPagination from "../../components/mainComponents/Landing/LandingPagination";

const PropertyResultPage = () => {
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [searchParams] = useSearchParams();
  const loc = searchParams.get("loc") ?? "New York, USA";
  const itemsPerPage = 25;
  const [searchValue, setSearchValue] = useState<string>("");

  const [bedrooms, setBedrooms] = useState<number>(1);
  const [bathrooms, setBathrooms] = useState<number>(1);
  const [propertyType, setPropertyType] = useState<string>("Houses");
  const [maxPrice, setMaxPrice] = useState<number>(1000000);
  const [minPrice, setMinPrice] = useState<number>(0);

  const dispatch = useAppDispatch();
  const [isLoading, setIsLoading] = useState(true);
  const [filteredListings, setFilteredListings] = useState<IMyListing[]>([]);

  const [totalCount, setTotalCount] = React.useState<number>(0);

  const [openListingDrawer, setOpenListingDrawer] = useState<boolean>(false);
  const [selectedListing, setSelectedListing] = useState<
    IMyListing | undefined
  >(undefined);
  const [selectedIndex, setSelectedIndex] = useState<number>(1);
  const initialQuery: ListingAdvancedSearchDto = {
    address: "",
    propertyBedrooms: "",
    propertyBathrooms: "",
    listingListPriceMin: "",
    listingListPriceMax: "",
    recordsPerPage: itemsPerPage,
    currentPage: currentPage,
    status: "Active",
  };
  const [value, setValue] =
    React.useState<ListingAdvancedSearchDto>(initialQuery);

  useEffect(() => {
    onSearch();
  }, []);

  const onSearch = () => {
    setIsLoading(true);
    dispatch(getListingsByAdvancedSearch(value)).then((res) => {
      try {
        setTotalCount(res.payload.totalCount);
        setFilteredListings(res.payload.listingsForAdvancedSearch);
        setIsLoading(false);
      } catch (e) {}
    });
  };

  useEffect(() => {
    setValue({
      ...value,
      propertyBathrooms: bathrooms.toString(),
      propertyBedrooms: bedrooms.toString(),
      listingListPriceMax: maxPrice.toString(),
      listingListPriceMin: minPrice.toString(),
    });
    setMinPrice(0);
  }, [bedrooms, bathrooms, minPrice, maxPrice]);

  const onReset = () => {
    setBedrooms(1);
    setBathrooms(1);
    setPropertyType("Houses");
    setMaxPrice(1000000);
  };

  const listingSelected = (listing?: IMyListing) => {
    console.log("listing: ", listing);
    if (listing) {
      const index = filteredListings.findIndex((item) => item === listing);
      setSelectedIndex(index);
    }
    setSelectedListing(listing);
    setOpenListingDrawer(true);
  };

  return (
    <>
      {openListingDrawer && (
        <div className="!bg-[#00000075] h-screen w-full fixed top-0 left-0 z-20"></div>
      )}
      <ListingDrawer
        open={openListingDrawer}
        closeDrawer={() => setOpenListingDrawer(!openListingDrawer)}
        listing={selectedListing}
        onNext={() => {
          console.log("selectedIndex ", selectedIndex);
          if (filteredListings.length - 1 == selectedIndex) {
            setSelectedIndex(0);
          } else {
            setSelectedIndex(selectedIndex + 1);
          }
          setSelectedListing(filteredListings[selectedIndex]);
        }}
        onPrev={() => {
          console.log("selectedIndex ", selectedIndex);
          if (selectedIndex == 0) {
            setSelectedIndex(filteredListings.length - 1);
          } else {
            setSelectedIndex(selectedIndex - 1);
          }
          setSelectedListing(filteredListings[selectedIndex]);
        }}
      />
      <div className="flex w-full bg-[#F7F7FD]">
        <div className="max-w-screen-xl mx-auto w-full">
          <div className="flex flex-col w-full mt-10">
            <div className="flex w-full justify-between items-center px-6">
              <div className="relative w-full md:hidden ">
                <input
                  type="search"
                  name="search"
                  id=""
                  className={`w-full py-3 px-4 placeholder-black !bg-white border-none focus:border-none rounded-lg  text-[18px] font-bold font-jakarta`}
                  placeholder={"Search a location"}
                  value={searchValue}
                  onChange={(e) => setSearchValue(e.target.value)}
                />
                <button
                  type="submit"
                  className="text-white absolute end-2.5 bottom-2.5 bg-blue-700 font-jakarta p-[14px] rounded-lg"
                >
                  <BiSearch />
                </button>
              </div>
            </div>
            <div className="flex w-full  px-2">
              <div className="flex justify-between w-full items-center pr-2">
                <Typography
                  color="black"
                  className=" text-[32px] font-bold font-jakarta md:inline hidden"
                >
                  {filteredListings?.length} properties in {loc}
                </Typography>
                <LandingFilter
                  bedrooms={bedrooms}
                  bathrooms={bathrooms}
                  type={propertyType}
                  setType={setPropertyType}
                  setBedrooms={setBedrooms}
                  setBathrooms={setBathrooms}
                  setPrice={setMaxPrice}
                  setMinPrice={setMinPrice}
                  onReset={() => onReset()}
                  onApply={() => onSearch()}
                />
              </div>
            </div>
            <div className="flex px-2">
              <PropertyFilter
                bedrooms={bedrooms}
                bathrooms={bathrooms}
                type={propertyType}
                price={maxPrice}
                onSearch={onSearch}
              />
            </div>
            {isLoading && (
              <div className="flex w-full my-12 lg:px-4 px-6 xl:px-0">
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 w-full gap-8">
                  {Array.from({ length: 6 }).map((_, index) => (
                    <ListingCardSkeleton key={index} />
                  ))}{" "}
                </div>
              </div>
            )}
            {!isLoading && (
              <div className="flex w-full my-12 lg:px-4 px-6 xl:px-0">
                {filteredListings.length > 0 && (
                  <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 w-full gap-8">
                    {filteredListings.map((listing, index) => (
                      <ListingCard
                        listing={listing}
                        key={index}
                        isSelected={(listing) => listingSelected(listing)}
                      />
                    ))}
                  </div>
                )}
                {filteredListings.length == 0 && (
                  <div className="flex h-40 w-full justify-center items-center text-[36px] font-jakarta font-bold">
                    No propery found
                  </div>
                )}
              </div>
            )}
            <LandingPagination
              totalItems={totalCount}
              currentPage={currentPage}
              itemsPerPage={itemsPerPage}
              setCurrentPage={setCurrentPage}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default PropertyResultPage;
