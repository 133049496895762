// React import
import { Fragment, useEffect, useState } from "react";

// Component imports
import Table from "@/components/baseComponents/table";
import Typography from "@/components/baseComponents/Typography";
import { Button } from "@/components/baseComponents/Button";
import Drawer from "./Drawer";
import View from "./View";
import Filter from "@/components/mainComponents/Filter";
import Sort from "@/components/mainComponents/Sort";

// Redux imports
import {
  getLeadsByUserId,
  deleteLeads,
  convertToClientLeads,
  setLeadRating,
  setLeadStatus,
  unarchiveLeads,
} from "@/redux/lead/leadSlice";
import { getUser } from "@/redux/user/userSlice";
import { useAppSelector, useAppDispatch } from "@/redux/hooks";

// Service imports
import { notify } from "@/shared/services/notify";

// Icon imports
import { XMarkIcon } from "@heroicons/react/24/outline";

// Interface imports
import {
  ConvertToClientDto,
  DeleteLeadsDto,
  GetLeadsDto,
  SetLeadStatusDto,
  SetRatingDto,
} from "@/shared/interfaces/interfaces";

// Image imports
import RecycleBin from "@/assets/images/recylebin.svg";
import { Menu, Transition } from "@headlessui/react";
import IconWrapper from "@/components/baseComponents/IconWrapper";

let tabs = [
  { name: "All" },
  { name: "Buyer" },
  { name: "Seller" },
];

let sortOptions = [
  { label: "Name", value: "Name" },
  { label: "Email", value: "Email" },
  { label: "Phone", value: "Phone" },
  { label: "Lead Type", value: "Lead Type" },
  { label: "Status", value: "Status" },
  { label: "Rating", value: "Rating" },
];

const Lead = () => {
  // Redux hooks
  const dispatch = useAppDispatch();
  const user = useAppSelector(getUser);

  // Data states
  const [leads, setLeads] = useState<Array<any>>([]);
  const [viewData, setViewdata] = useState<any>(null);
  const [editData, setEditData] = useState<any>(null);
  const [selectedPeople, setSelectedPeople] = useState<Array<any>>([]);
  const [selectedLead, setSelectedLead] = useState<any>({});

  // UI states
  const [currentTab, setCurrentTab] = useState<string>("All");
  const [open, setOpen] = useState<boolean>(false);
  const [openView, setOpenView] = useState<boolean>(false);
  const [openConfirm, setOpenConfirm] = useState<boolean>(false);

  // Search and sort states
  const [keyword, setKeyword] = useState<string>("");
  const [sortType, setSortType] = useState<string>("Descending");
  const [sortField, setSortField] = useState<string>("Name");

  // Pagination states
  const [totalCount, setTotalCount] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [recordsPerPage, setRecordsPerPage] = useState<number>(25);

  const onSetPage = (value: number) => {
    setCurrentPage(value);
  };

  const openDrawer = () => {
    setEditData(null);
    setViewdata(null);
    setOpen(true);
  };

  const onClickRow = (value: any) => {
    setOpenView(true);
    setViewdata(value);
  };

  const editLead = (value: any) => {
    setEditData(value);
    setOpen(true);
  };

  const changeSelectedPeople = (value: any) => {
    setSelectedPeople(value);
  };

  const deleteOpenModal = () => {
    if (selectedPeople.length > 0) {
      setOpenConfirm(true);
    } else {
      notify(
        false,
        "Please select one or more leads that you wish to archive."
      );
    }
  };

  const deleteConfirm = () => {
    let ids: Array<string> = [];
    if (selectedPeople.length === 0 && selectedLead) {
      ids = [selectedLead._id];
    } else {
      ids = selectedPeople.map((lead: any) => {
        return lead._id;
      });
    }
    const data: DeleteLeadsDto = {
      ids: ids,
      userId: user._id,
      search: {
        userId: user._id,
        keyword: keyword,
        sortType: sortType,
        sortField: sortField,
        recordsPerPage: recordsPerPage,
        currentPage: currentPage,
        status: currentTab,
      },
    };
    dispatch(deleteLeads(data)).then((res) => {
      try {
        setTotalCount(res.payload.totalCount);
        notify(res.payload.success, res.payload.message);
      } catch (e) {
        notify(false, "Something went wrong.");
      }
    });
  };

  const unarchiveConfirm = () => {
    let ids: Array<string> = [];
    if (selectedPeople.length === 0 && selectedLead) {
      ids = [selectedLead._id];
    } else {
      ids = selectedPeople.map((lead: any) => {
        return lead._id;
      });
    }
    const data: DeleteLeadsDto = {
      ids: ids,
      userId: user._id,
      search: {
        userId: user._id,
        keyword: keyword,
        sortType: sortType,
        sortField: sortField,
        recordsPerPage: recordsPerPage,
        currentPage: currentPage,
        status: currentTab,
      },
    };
    dispatch(unarchiveLeads(data)).then((res) => {
      try {
        setTotalCount(res.payload.totalCount);
        notify(res.payload.success, res.payload.message);
      } catch (e) {
        notify(false, "Something went wrong.");
      }
    });
  };

  const changeKeyword = (keyword: string) => {
    setKeyword(keyword);
  };

  const changeSortType = (value: string) => {
    setSortType(value);
  };

  const changeSortField = (value: any) => {
    setSortField(value.value);
  };

  const filterLeads = () => {
    const data: GetLeadsDto = {
      userId: user._id,
      keyword: keyword,
      sortType: sortType,
      sortField: sortField,
      recordsPerPage: recordsPerPage,
      currentPage: currentPage,
      status: currentTab,
    };
    dispatch(getLeadsByUserId(data)).then((res) => {
      try {
        setTotalCount(res.payload.totalCount);
        setLeads(res.payload.leads);
      } catch (e) {
        notify(false, "Something went wrong.");
      }
    });
  };

  const archive = async (item: any) => {
    setSelectedLead(item);
    setOpenConfirm(true);
    setOpenView(false);
  };

  const convertToClient = async (item: any, toClient: boolean) => {
    const data: ConvertToClientDto = {
      leadId: item._id,
      toClient: toClient,
      search: {
        userId: user._id,
        keyword: keyword,
        sortType: sortType,
        sortField: sortField,
        recordsPerPage: recordsPerPage,
        currentPage: currentPage,
        status: currentTab,
      },
    };
    dispatch(convertToClientLeads(data)).then((res) => {
      try {
        setTotalCount(res.payload.totalCount);
        notify(res.payload.success, res.payload.message);
      } catch (e) {
        notify(false, "Something went wrong.");
      }
    });
    setOpenView(false);
  };

  const setRating = async (item: any, value: string) => {
    const data: SetRatingDto = {
      leadId: item._id,
      rating: value,
      search: {
        userId: user._id,
        keyword: keyword,
        sortType: sortType,
        sortField: sortField,
        recordsPerPage: recordsPerPage,
        currentPage: currentPage,
        status: currentTab,
      },
    };
    dispatch(setLeadRating(data)).then((res) => {
      try {
        fetchData();
        setTotalCount(res.payload.totalCount);
        notify(res.payload.success, res.payload.message);
      } catch (e) {
        notify(false, "Something went wrong.");
      }
    });
    setOpenView(false);
  };

  const setStatus = async (item: any, value: string) => {
    const data: SetLeadStatusDto = {
      leadId: item._id,
      leadStatus: value,
      search: {
        userId: user._id,
        keyword: keyword,
        sortType: sortType,
        sortField: sortField,
        recordsPerPage: recordsPerPage,
        currentPage: currentPage,
        status: currentTab,
      },
    };
    dispatch(setLeadStatus(data)).then((res) => {
      try {
        fetchData();
        setTotalCount(res.payload.totalCount);
        notify(res.payload.success, res.payload.message);
      } catch (e) {
        notify(false, "Something went wrong.");
      }
    });
    setOpenView(false);
  };

  const fetchData = () => {
    const data: GetLeadsDto = {
      userId: user._id,
      keyword: keyword,
      sortType: sortType,
      sortField: sortField,
      recordsPerPage: recordsPerPage,
      currentPage: currentPage,
      status: currentTab,
    };
    dispatch(getLeadsByUserId(data)).then((res) => {
      try {
        setTotalCount(res.payload.totalCount);
        setLeads(res.payload.leads);
      } catch (e) {
        notify(false, "Something went wrong.");
      }
    });
  };

  useEffect(() => {
    setRecordsPerPage(25);
    filterLeads();
  }, [currentPage, currentTab, totalCount]);

  useEffect(() => {
    setViewdata(leads.find((lead) => lead?._id === viewData?._id));
  }, [leads]);

  useEffect(() => {
    if (open && openView) {
      setOpenView(false);
    }

    if (!open) {
      if (viewData) {
        setOpenView(true);
      }
    }
  }, [open]);

  return (
    <div className="px-6 pt-10 lg:pb-24 md:pb-60 sm:pb-60 max-sm:pb-60">
      {openConfirm && (
        <div
          className="bg-[#00000040] w-full h-screen flex items-center justify-center fixed top-0 left-0 z-30"
          onClick={() => setOpenConfirm(false)}
        >
          <div className="rounded-lg max-w-[435px] w-full bg-white p-3">
            <div>
              <div className="flex justify-end w-full text-secondary hover:text-primary cursor-pointer">
                <XMarkIcon
                  className="h-6 w-6"
                  aria-hidden="true"
                  onClick={() => setOpenConfirm(false)}
                />
              </div>
              <div className="w-full flex justify-center mt-[10px]">
                <Typography variant="button2">
                  Are you sure you want to{" "}
                  {selectedPeople.length === 0 && selectedLead.isArchived === 1
                    ? "unarchive"
                    : "archive"}{" "}
                  {selectedPeople.length > 1 ? "these leads" : "this lead"}?
                </Typography>
              </div>
              <div className="flex justify-center mt-[20px] mb-[10px]">
                {selectedPeople.length === 0 &&
                  selectedLead.isArchived === 1 ? (
                  <div
                    className="bg-success hover:bg-success w-[146px] h-[40px] rounded flex items-center justify-center cursor-pointer"
                    onClick={() => unarchiveConfirm()}
                  >
                    <Typography variant="button2" className="text-[#6DA172]">
                      Unarchive
                    </Typography>
                  </div>
                ) : (
                  <div
                    className="bg-[#C18193] hover:bg-[#B17183] w-[146px] h-[40px] rounded flex items-center justify-center cursor-pointer"
                    onClick={() => deleteConfirm()}
                  >
                    <Typography variant="button2" className="text-[#B32F43]">
                      Archive
                    </Typography>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
      <Drawer
        open={open}
        changeState={setOpen}
        data={editData}
        keyword={keyword}
        sortType={sortType}
        sortField={sortField}
        currentPage={currentPage}
        recordsPerPage={recordsPerPage}
        setTotalCount={setTotalCount}
        setCurrentPage={setCurrentPage}
      />
      <View
        open={openView}
        changeState={setOpenView}
        convertToClient={convertToClient}
        archive={archive}
        editLead={editLead}
        data={viewData}
      />
      <div className="flex justify-between items-center">
        <div className="flex items-center gap-2">
          <Typography variant="h2" color="primary">
            Leads
          </Typography>
          <Menu>
            <div className="relative w-full ">
              <Menu.Button className="relative w-fit flex gap-1 cursor-default rounded-full text-left">
                <div className="cursor-pointer text-[#9CA3AF] flex items-center px-[8px] py-[4px] gap-[4px] w-fit">
                  <Typography
                    variant="body"
                    color="secondary"
                  >
                    {currentTab === "Archived" ? "All Type" : `${currentTab} ${currentTab === "All" ? "Type" : ""}`}
                  </Typography>
                  <IconWrapper
                    name="arrow-down"
                    width={16}
                    innerStroke="#6B7280"
                  />
                </div>
              </Menu.Button>
              <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <Menu.Items className="z-10 absolute top- origin-top-right left-0  min-h-60 w-fit min-w-[120px] overflow-hidden bg-white rounded-8 text-base shadow-lg">
                  {tabs.map((tab, index) => (
                    <Menu.Item as={Fragment} key={index}>
                      {({ active }) => (
                        <div
                          key={index}
                          className="flex flex-col items-start text-primary text-14"
                        >
                          <button
                            className={` p-3 w-full text-start ${active ? "bg-[#F3F4F6]" : "bg-white"
                              }`}
                            onClick={() => {
                              setCurrentTab(tab.name);
                            }}
                          >
                            {tab.name}
                          </button>
                        </div>
                      )}
                    </Menu.Item>
                  ))}
                </Menu.Items>
              </Transition>
            </div>
          </Menu>
        </div>
        <div className="flex max-sm:flex-col sm:items-center justify-between">
          <div className="flex items-center gap-3">
            {selectedPeople.length > 0 && (
              <img
                src={RecycleBin}
                alt="Archive"
                className="cursor-pointer"
                onClick={() => deleteOpenModal()}
              />
            )}
            <Button variant="contained" color="secondary" size="small" className="flex items-center gap-2 !py-0" onClick={() => setCurrentTab("Archived")}>
              <IconWrapper name="archive" innerStroke="#6B7280" width={16} />
              <Typography variant="button1" className="lg:block sm:hidden max-sm:hidden">Archived</Typography>
            </Button>
            <Filter
              changeKeyword={changeKeyword}
              keyword={keyword}
              filterLeads={filterLeads}
            />
            <Sort
              options={sortOptions}
              sortType={sortType}
              sortField={sortField}
              changeSortField={changeSortField}
              changeSortType={changeSortType}
              filterLeads={filterLeads}
            />
            <Button onClick={() => openDrawer()} className="max-sm:hidden">
              <Typography variant="button1">Add Lead</Typography>
            </Button>
          </div>
        </div>
      </div>
      <div className="md:hidden sm:block mt-6">
        <Button onClick={() => openDrawer()} className="w-full">
          <Typography variant="button1">Add Lead</Typography>
        </Button>
      </div>
      <div className="mt-6">
        <Table
          data={leads}
          archive={archive}
          convertToClient={convertToClient}
          setRating={setRating}
          setStatus={setStatus}
          onClickRow={onClickRow}
          editLead={editLead}
          changeSelectedPeople={changeSelectedPeople}
          totalPage={Math.ceil(totalCount / recordsPerPage)}
          totalCount={totalCount}
          currentPage={currentPage}
          recordsPerpage={recordsPerPage}
          onSetPage={onSetPage}
        />
      </div>
    </div>
  );
};

export default Lead;
