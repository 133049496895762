type Props = {
  width: number;
  height: number;
  stroke?: string;
  className?: string;
};

const IconDocument = (props: Props) => {
  return (
    <svg
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M17.5 5.83464V14.168C17.5 16.668 16.25 18.3346 13.3333 18.3346H6.66667C3.75 18.3346 2.5 16.668 2.5 14.168V5.83464C2.5 3.33464 3.75 1.66797 6.66667 1.66797H13.3333C16.25 1.66797 17.5 3.33464 17.5 5.83464Z"
        stroke="white"
        strokeWidth="2"
        stroke-miterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.084 3.75V5.41667C12.084 6.33333 12.834 7.08333 13.7507 7.08333H15.4173"
        stroke="white"
        strokeWidth="2"
        stroke-miterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.66602 10.832H9.99935"
        stroke="white"
        strokeWidth="2"
        stroke-miterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.66602 14.168H13.3327"
        stroke="white"
        strokeWidth="2"
        stroke-miterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default IconDocument;
