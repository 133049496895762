import { Fragment } from "react";
import Scrollbars from "react-custom-scrollbars";
import { Dialog, Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import ListingDrawerFooter from "@/pages/myListings/common/ListingDrawerFooter";
import ShowingDetailsSection from "./components/ShowingDetailsSection";
import InfoSection from "./components/InfoSection";
import { getUser } from "@/redux/user/userSlice";
import { useAppSelector } from "@/redux/hooks";
import "react-slideshow-image/dist/styles.css";
import PropertyCarousel from "@/components/mainComponents/PropertyCarousel";
import Typography from "@/components/baseComponents/Typography";
import IconWrapper from "@/components/baseComponents/IconWrapper";
import { Button } from "@/components/baseComponents/Button";

type IProps = {
  open: boolean;
  changeState: Function;
  data: any;
  reschedule: Function;
  updateStatus: Function;
  onViewListing: Function;
};

const Drawer = (props: IProps) => {
  const user = useAppSelector(getUser);
  const showingOwner = props.data?.owner;
  const closeDrawer = () => {
    props.changeState(false);
  };

  return (
    <Transition.Root show={props.open} as={Fragment}>
      <Dialog as="div" className="fixed inset-0 z-30" onClose={() => { }}>
        <div className="fixed inset-0" />
        <div className="fixed inset-0 overflow-hidden">
          <div
            className="absolute inset-0 overflow-hidden"
            onClick={() => props.changeState(false)}
          >
            <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-0 sm:pl-16">
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-500 sm:duration-300"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-500 sm:duration-300"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <Dialog.Panel className="pointer-events-auto max-sm:w-[414px] w-[600px]">
                  <div className=" flex h-full flex-col bg-white shadow-xl">
                    <div className="sticky top-0 w-full bg-white z-30 pt-5">
                      <div className="px-4 sm:px-8 w-full bg-white">
                        <div className="flex items-start justify-between">
                          <Dialog.Title
                            className="text-[25px] font-bold truncate w-11/12 text-primary flex flex-col"
                            title={
                              props.data?.listings?.listingAddress.fullAddress ||
                              "Showing Details"
                            }
                          >
                            {(
                              props.data?.listings?.isPublic === 1 ? (
                                <div className="flex flex-col w-full">
                                  <Typography
                                    variant="h1"
                                    className="w-full whitespace-pre-wrap"
                                  >
                                    {props.data?.listing?.listingAddress?.streetLine ? props.data?.listing?.listingAddress?.streetLine + " " : ""}
                                    {props.data?.listing?.listingAddress?.secondary ? props.data?.listing?.listingAddress?.secondary : ""}
                                  </Typography>
                                  <span className="text-14 font-medium">
                                    {props.data?.listing?.listingAddress?.city},{" "}
                                    {props.data?.listing?.listingAddress?.state}{" "}
                                    {props.data?.listing?.listingAddress?.zipcode}
                                  </span>
                                </div>
                              ) : (
                                <div className="flex flex-col w-full">
                                  <Typography
                                    variant="h1"
                                    className="w-full whitespace-pre-wrap"
                                  >
                                    {props.data?.listing?.listingAddress?.city},{" "}
                                    {props.data?.listing?.listingAddress?.state}{" "}
                                    {props.data?.listing?.listingAddress?.zipcode}
                                  </Typography>
                                </div>
                              )
                            ) || "Showing Detail"}
                          </Dialog.Title>
                          <div className="ml-3 flex h-7 items-center gap-3">
                            <button
                              type="button"
                              className="relative rounded-md bg-white text-gray-400 hover:text-[#C84156] focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                              onClick={() => props.changeState(false)}
                            >
                              <span className="absolute -inset-2.5" />
                              <span className="sr-only">Close Panel</span>
                              <XMarkIcon
                                className="h-6 w-6"
                                aria-hidden="true"
                              />
                            </button>
                          </div>
                        </div>

                        <div className="py-3 flex items-center content-start flex-wrap gap-2">
                          {/* drawer is_recieved */}
                          <div className="border bg-white border-border w-fit rounded-full flex items-center">
                            <Typography className="flex gap-[4px] px-[8px] items-center leading-[110%]">
                              {props.data?.is_received.props.children}
                            </Typography>
                          </div>
                          {/* drawer status */}
                          <Typography
                            className={`whitespace-nowrap border w-fit px-2 rounded-full ${props.data?.status.props.children === "Pending"
                                ? "border-pending text-pending"
                                : props.data?.status.props.children === "Denied"
                                  ? "border-denied text-denied"
                                  : props.data?.status.props.children ===
                                    "Confirmed"
                                    ? "border-confirmed text-confirmed"
                                    : ""
                              }`}
                          >
                            {props.data?.status.props.children}
                          </Typography>
                          {/* drawer agent */}
                          <Typography className="flex gap-[4px] pr-[6px] py-[4px] items-center border rounded-full px-[4px] leading-[110%] border-border">
                            {props.data?.agent.props.children}
                          </Typography>
                          <Button
                            variant="text"
                            className="!px-0"
                            onClick={() =>
                              props.onViewListing(props.data.listing)
                            }
                          >
                            <Typography className="flex items-center text-indigo-600 cursor-pointer">
                              <span>View Listing</span>
                              <IconWrapper name="link" width={14} />
                            </Typography>
                          </Button>
                        </div>
                      </div>
                    </div>
                    <Scrollbars autoHide className="h-full scroll-smooth">
                      <div className="px-4 md:px-8 mt-3">
                        <PropertyCarousel
                          data={props.data?.listing?.propertyPhotos}
                        />
                      </div>
                      <div className="flex flex-col px-4 md:px-8 gap-[20px] pt-[20px]">
                        {/* <AddressSection data={props.data?.listing} /> */}
                        <ShowingDetailsSection
                          data={props.data}
                          closeDrawer={closeDrawer}
                          reschedule={props.reschedule}
                          updateStatus={props.updateStatus}
                        />
                        <InfoSection data={props.data} />
                        {showingOwner && user._id === showingOwner._id ? (
                          <ListingDrawerFooter
                            data={(props.data.listing as any)?.owner?.agent}
                            isSelf={false}
                            ownerId={(props.data.listing as any)?.owner._id}
                          />
                        ) : (
                          showingOwner && (
                            <ListingDrawerFooter
                              data={showingOwner.agent}
                              isSelf={false}
                              ownerId={showingOwner._id}
                            />
                          )
                        )}
                      </div>
                      <div className="pb-9"></div>
                    </Scrollbars>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default Drawer;