type Props = {
  width: number;
  height: number;
  stroke?: string;
  className?: string;
};

const IconTelephone = (props: Props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 25"
      fill="none"
      {...props}
    >
      <path
        d="M21.9999 17.4201V20.4201C22.0011 20.6986 21.944 20.9743 21.8324 21.2294C21.7209 21.4846 21.5572 21.7137 21.352 21.902C21.1468 22.0902 20.9045 22.2336 20.6407 22.3228C20.3769 22.412 20.0973 22.4452 19.8199 22.4201C16.7428 22.0857 13.7869 21.0342 11.1899 19.3501C8.77376 17.8148 6.72527 15.7663 5.18993 13.3501C3.49991 10.7413 2.44818 7.77109 2.11993 4.6801C2.09494 4.40356 2.12781 4.12486 2.21643 3.86172C2.30506 3.59859 2.4475 3.35679 2.6347 3.15172C2.82189 2.94665 3.04974 2.78281 3.30372 2.67062C3.55771 2.55843 3.83227 2.50036 4.10993 2.5001H7.10993C7.59524 2.49532 8.06572 2.66718 8.43369 2.98363C8.80166 3.30008 9.04201 3.73954 9.10993 4.2201C9.23656 5.18016 9.47138 6.12282 9.80993 7.0301C9.94448 7.38802 9.9736 7.77701 9.89384 8.15098C9.81408 8.52494 9.6288 8.86821 9.35993 9.1401L8.08993 10.4101C9.51349 12.9136 11.5864 14.9865 14.0899 16.4101L15.3599 15.1401C15.6318 14.8712 15.9751 14.6859 16.3491 14.6062C16.723 14.5264 17.112 14.5556 17.4699 14.6901C18.3772 15.0286 19.3199 15.2635 20.2799 15.3901C20.7657 15.4586 21.2093 15.7033 21.5265 16.0776C21.8436 16.4519 22.0121 16.9297 21.9999 17.4201Z"
        stroke="#4B5563"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default IconTelephone;
