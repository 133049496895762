import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import type { RootState } from '../store'
import axios from '@/shared/services/axios'
import { IDocument, CreateDocumentDto, GetDocumentsDto, DeleteDocumentsDto } from '@/shared/interfaces/interfaces'

interface DocumentState {
  documents: Array<IDocument>
}

const initialState: DocumentState = {
  documents: [],
};

export const documentSlice = createSlice({
  name: 'documents',
  initialState,
  reducers: {
    setTest: (state, action: PayloadAction<any>) => {
      state.documents = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(createDocument.fulfilled, (state, action) => {
      if(action.payload.success){
        state.documents = action.payload.documents;
      }
    });
    builder.addCase(getDocumentsFromDB.fulfilled, (state, action) => {
      if(action.payload.success){
        state.documents = action.payload.documents;
      }
    });
    builder.addCase(updateDocument.fulfilled, (state, action) => {
      if(action.payload.success){
        state.documents = action.payload.documents;
      }
    });
    builder.addCase(deleteDocuments.fulfilled, (state, action) => {
      if(action.payload.success){
        state.documents = action.payload.documents;
      }
    });
  },
});

export const documentsAction = documentSlice.actions;

// Other code such as selectors can use the imported `RootState` type
export const getDocuments = (state: RootState) => 
  state.document.documents;

export default documentSlice.reducer;

export const createDocument = createAsyncThunk(
  'document/create',
  async (data: CreateDocumentDto) => {
    const response = await axios.post('document/create', data);
    return response.data;
  }
);

export const updateDocument = createAsyncThunk(
  'document/update',
  async (data: any) => {
    const response = await axios.put('document/update', data);
    return response.data;
  }
);

export const getDocumentsFromDB = createAsyncThunk(
  'document/get',
  async (data: GetDocumentsDto) => {
    const response = await axios.post('document/get', data);
    return response.data;
  }
);

export const deleteDocuments = createAsyncThunk(
  'document/delete',
  async (data: DeleteDocumentsDto) => {
    const response = await axios.post('document/delete', data);
    return response.data;
  }
);
