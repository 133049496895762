import ChatInputBox from "@/pages/chat/components/ChatInputBox";
import ChatMessageArea from "@/pages/chat/components/ChatMessageArea";
import { IMyListing } from "@/shared/interfaces/interfaces";
import { Dialog, Transition } from "@headlessui/react";
import { Fragment } from "react";

type IProps = {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  handleFocusMainArea?: any;
  viewMessages?: any;
  selectedParticipant?: any;
  listing?: IMyListing;
  content?: string;
  setContent?: React.Dispatch<React.SetStateAction<string>>;
  setIsTyping?: React.Dispatch<React.SetStateAction<boolean>>;
  sendChatMessage?: any;
  isProfileChat?: boolean;
  handleProfileClose?: React.Dispatch<React.SetStateAction<boolean>>;
};

const ChatDrawer = (props: IProps) => {
  const {
    open,
    setOpen,
    handleFocusMainArea,
    viewMessages,
    selectedParticipant,
    listing,
    content,
    setContent,
    setIsTyping,
    sendChatMessage,
    isProfileChat,
    handleProfileClose,
  } = props;
  return (
    <>
      <Transition.Root show={open} as={Fragment}>
        <Dialog as="div" className="fixed inset-0 !z-40" onClose={() => setOpen(false)}>
          <div className="fixed inset-0" />
          <div className="fixed inset-0 overflow-hidden">
            <div className="absolute inset-0 overflow-hidden">
              <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-0 sm:pl-16">
                <Transition.Child
                  as={Fragment}
                  enter="transform transition ease-in-out duration-500 sm:duration-300"
                  enterFrom="translate-x-full"
                  enterTo="translate-x-0"
                  leave="transform transition ease-in-out duration-500 sm:duration-300"
                  leaveFrom="translate-x-0"
                  leaveTo="translate-x-full"
                >
                  <Dialog.Panel className="pointer-events-auto w-screen max-w-[600px]">
                    <div
                      className="relative flex flex-col h-full"
                      tabIndex={1}
                      onFocus={() => handleFocusMainArea()}
                    >
                      <ChatMessageArea
                        messages={viewMessages}
                        participant={selectedParticipant}
                        chatListing={listing}
                        isForHeader={true}
                        goBack={() => {
                          if (isProfileChat && handleProfileClose) {
                            handleProfileClose(true);
                          } else {
                            setOpen(false);
                          }
                        }}
                      />
                      <div className="flex w-full sticky bottom-0 rounded-br-lg bg-white p-4">
                        <ChatInputBox
                          content={{ content: content, set: setContent }}
                          setIsTyping={setIsTyping}
                          sendChatMessage={sendChatMessage}
                        />
                      </div>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
};

export default ChatDrawer;
