import { Fragment, useEffect, useRef, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import Preview from "./common/Preview";
import { XMarkIcon } from "@heroicons/react/24/outline";
import Scrollbars from "react-custom-scrollbars";
import TableActionPopover from "@/components/baseComponents/TableActionPopover";
import { Button } from "@/components/baseComponents/Button";
import IconWrapper from "@/components/baseComponents/IconWrapper";
import { useAppDispatch, useAppSelector } from "@/redux/hooks";
import { getUser, updateMyInfo } from "@/redux/user/userSlice";
import {
  BookmarkMyListingDto,
  GetMyListingsDto,
  UpdateMyInfoDto,
} from "@/shared/interfaces/interfaces";
import { bookmarkMyListings } from "@/redux/myListings/myListingSlice";
import { notify } from "@/shared/services/notify";
import Typography from "@/components/baseComponents/Typography";

type IProps = {
  open: boolean;
  changeState: React.Dispatch<React.SetStateAction<boolean>>;
  data: any;
  setAsFavorite?: Function;
  onEdit?: Function;
  onArchive?: Function;
  search?: GetMyListingsDto;
  setTotalCount?: any;
};

const View = (props: IProps) => {
  const dispatch = useAppDispatch();
  const user = useAppSelector(getUser);
  const scrollbars = useRef<any>(null);

  const [isBookmarked, setIsBookmarked] = useState<boolean>(false);

  const onBookmark = (isBookmarked: boolean) => {
    const data: BookmarkMyListingDto = {
      ids: [props.data?.id],
      bookmark: isBookmarked,
      userId: user._id,
      search: props.search ?? {
        userId: user._id,
				keyword: '',
				sortType: 'Descending',
				sortField: 'Latest',
				recordsPerPage: 1,
				currentPage: 1,
				status: '',
      },
    };
    dispatch(bookmarkMyListings(data)).then((res) => {
      try {
        setIsBookmarked(isBookmarked);
        notify(res.payload.success, res.payload.message);
        props.setTotalCount && props.setTotalCount(res.payload.totalCount);
        let data: UpdateMyInfoDto = {
          email: user.email,
          data: user.agent,
        };
        dispatch(updateMyInfo(data));
      } catch (e) {
        notify(false, "Something went wrong.");
      }
    });
  };

  useEffect(() => {
    if (props.open) {
      setIsBookmarked(
        user.bookmarkedListings?.includes(props.data?.id) || false
      );
    }
  }, [props.open]);

  return (
    <Transition.Root show={props.open} as={Fragment}>
      <Dialog as="div" className="fixed inset-0 z-30 " onClose={() => {props.changeState(false)}}>
        <div
          className="fixed inset-0 overflow-hidden"
          style={{ backgroundColor: "rgba(0,0,0,0.2)" }}
        >
          <div className="absolute inset-0 overflow-hidden">
            <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full md:pl-10 pl-0 sm:pl-16">
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-500 sm:duration-300"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-500 sm:duration-300"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <Dialog.Panel className="pointer-events-auto h-screen w-full max-sm:!w-screen sm:w-[414px] md:w-[600px] overflow-y-auto hide-scrollbar ">
                  <div className="flex h-full flex-col bg-white shadow-xl w-full">
                    <div className="relative w-full h-fit bg-white z-30 py-5">
                      <div className="px-4 sm:px-8 w-full bg-white">
                        <div className="flex items-start justify-between">
                          <Dialog.Title className="font-medium truncate w-fit flex flex-col text-indigo-600">
                            {props.data?.listingAddress ? (
                              props.data?.isPublic === 1 ? (
                                <div className="flex flex-col">
                                  <Typography
                                    className="whitespace-normal"
                                    variant="h1"
                                    color="primary"
                                  >
                                    {props.data?.listingAddress.streetLine + " " + (props.data?.listingUnit || props.data?.listingAddress.secondary)}
                                  </Typography>
                                  <Typography variant="body" color="primary">
                                    {props.data?.listingAddress.city},{" "}
                                    {props.data?.listingAddress.state}{" "}
                                    {props.data?.listingAddress.zipcode}
                                  </Typography>
                                </div>
                              ) : (
                                <div className="flex flex-col">
                                  <Typography
                                    className="whitespace-normal"
                                    variant="h1"
                                    color="primary"
                                  >
                                    {props.data?.listingAddress.city},{" "}
                                    {props.data?.listingAddress.state}{" "}
                                    {props.data?.listingAddress.zipcode}
                                  </Typography>
                                </div>
                              )
                            ) : (
                              <div>
                                <Typography variant="h1" color="primary">
                                  View Listings
                                </Typography>
                              </div>
                            )}
                          </Dialog.Title>
                          <div className="flex items-center gap-3">
                            {isBookmarked !== undefined && (
                              <Button
                                variant="icon"
                                className="group active:bg-gray-100 rounded-lg"
                                onClick={() => onBookmark(!isBookmarked)}
                              >
                                <IconWrapper
                                  width={20}
                                  name="bookmark"
                                  className={
                                    isBookmarked
                                      ? `[&>svg>path]:!stroke-[#4F46E5] [&>svg>path]:!fill-[#4F46E5] group-hover:[&>svg>path]:!fill-[#6366F1] group-hover:[&>svg>path]:!stroke-[#6366F1]`
                                      : `group-hover:[&>svg>path]:!stroke-[#6366F1] group-active:[&>svg>path]:!stroke-[#4F46E5]`
                                  }
                                />
                              </Button>
                            )}
                            {(props.onEdit || props.onArchive) && (
                              <TableActionPopover
                                data={props.data}
                                onEdit={props.onEdit}
                                onArchive={props.onArchive}
                              />
                            )}
                            <button
                              type="button"
                              className="relative rounded-md bg-white text-gray-400 hover:text-[#C84156] focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                              onClick={() => props.changeState(false)}
                            >
                              <span className="absolute -inset-2.5" />
                              <span className="sr-only">Close panel</span>
                              <XMarkIcon
                                className="h-6 w-6"
                                aria-hidden="true"
                              />
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <Scrollbars autoHide className="h-full" ref={scrollbars}>
                      <div className="px-4 md:px-8 w-full">
                        <Preview
                          data={props.data}
                          isPublished={true}
                          scrollbars={scrollbars}
                          closeAll={props.changeState}
                        />
                      </div>
                    </Scrollbars>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default View;
