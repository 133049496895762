import { useEffect, useState } from "react";
import Scrollbars from "react-custom-scrollbars";
import { useAppSelector, useAppDispatch } from "@/redux/hooks";
import Typography from "@/components/baseComponents/Typography";
import {
  IMyListing,
  IParticipantDto,
  SearchDto,
} from "@/shared/interfaces/interfaces";
import AutoComplete from "@/components/baseComponents/AutoComplete";
import {
  fetchUserAutocomplete,
  getUsers,
  getUser,
} from "@/redux/user/userSlice";
import { getFormattedDateString } from "../utils";
import { getParticipantId, setParticipantId } from "@/redux/chat/chatSlice";
import IconWrapper from "@/components/baseComponents/IconWrapper";
import { useNavigate } from "react-router-dom";
import ChatDrawer from "@/components/layouts/bars/Components/ChatDrawer";
import DefaultProfilePicture from "@/assets/images/default_profile_picture.webp";
import Skeleton from "react-loading-skeleton";

type IProps = {
  allUnread: number;
  participantList: Array<IParticipantDto>;
  openMobileChatDrawer?: () => void;
  closeChatDrawer?: () => void;
  isTyping?: boolean;
  isSelected?: boolean;
  isForHeader?: boolean;

  handleFocusMainArea?: any;
  viewMessages?: any;
  selectedParticipant?: any;
  listing?: IMyListing;
  content?: string;
  setContent?: React.Dispatch<React.SetStateAction<string>>;
  setIsTyping?: React.Dispatch<React.SetStateAction<boolean>>;
  sendChatMessage?: any;
  loading?: boolean;
};

type IParticipantProps = {
  participant: IParticipantDto;
  isSelected: boolean;
  handleItemClick: Function;
  openChatDrawerFn: () => void;
  isTyping?: boolean;
  isForHeader?: boolean;
  setIsSelected?: React.Dispatch<React.SetStateAction<boolean>>;
  loading?: boolean;
};

const ParticipantItem = (props: IParticipantProps) => {
  const {
    isSelected,
    handleItemClick,
    isTyping,
    participant,
    setIsSelected,
    openChatDrawerFn,
  } = props;

  const {
    avatarURL,
    name,
    latestMessage,
    unreadCount,
    latestTime,
    _id,
    isOnline,
  } = participant;
  const latestTimeStr = getFormattedDateString(latestTime);

  const user = useAppSelector(getUser);
  const isSelf = user._id === _id;

  useEffect(() => {
    if (setIsSelected) {
      if (isSelected) {
        setIsSelected(true);
      } else {
        setIsSelected(false);
      }
    }
  });

  return (
    <>
      {props.loading ? (
        <div className="flex flex-row gap-3 px-3 py-2">
          <Skeleton circle className="w-10 h-10" />
          <div className="flex flex-col gap-1 w-full justify-center">
            <Skeleton className="w-full h-5" />
            <Skeleton className="w-[150px] h-5" />
          </div>
        </div>
      ) : (
        <div
          className={"cursor-pointer" + (isSelected ? " bg-[#FAF5FF]" : "")}
          onClick={() => {
            handleItemClick(props.participant);
            props.isForHeader && openChatDrawerFn();
          }}
        >
          <div className="flex gap-6 p-6 w-full">
            <div className="relative flex items-start w-full overflow-hidden gap-2.5">
              <div className="relative">
                <img
                  onError={(e) => (e.currentTarget.src = DefaultProfilePicture)}
                  src={avatarURL && avatarURL !== "" ? avatarURL : DefaultProfilePicture}
                  className="w-9 aspect-square rounded-full"
                  alt="avatar"
                />
                <span
                  className={`w-3 aspect-square rounded-full border-2 border-white absolute bottom-0 right-0 translate-x-[5px] translate-y-[2px] ${isOnline ? `bg-green-500` : `bg-gray-500`
                    }`}
                ></span>
              </div>

              <div className="flex flex-col w-full justify-start">
                <div className="flex justify-between">
                  <Typography variant="body" color="primary" className="font-bold">
                    {name}
                  </Typography>
                  <Typography variant="caption" className="text-secondary">
                    {latestTimeStr}
                  </Typography>
                </div>
                <div className="flex justify-between">
                  {/* isTyping functionality */}
                  {!isSelf && isSelected && isTyping ? (
                    <Typography
                      variant="body"
                      className="text-purple-500 truncate line-clamp-1 leading-[110%]"
                    >
                      Typing...
                    </Typography>
                  ) : (
                    <Typography
                      variant="body"
                      color="secondary"
                      className="line-clamp-1"
                    >
                      {latestMessage}
                    </Typography>
                  )}

                  <div>
                    {unreadCount !== undefined &&
                      unreadCount !== null &&
                      unreadCount !== 0 &&
                      !isSelf && (
                        <div className="w-5 rounded-full bg-purple-100 text-purple-700 text-14 flex items-center justify-center  aspect-square">
                          <Typography variant="body">{unreadCount}</Typography>
                        </div>
                      )}
                  </div>
                </div>

                {/* will be changed with actual showing */}
                {/* <div className="bg-gray-100 px-2 rounded-full w-fit">
              <Typography variant="body" className="text-gray-700 line-clamp-1">
                7133 Bonita Dr, Miami Beach, FL 33141
              </Typography>
            </div> */}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

const ChatSidebar = (props: IProps) => {
  const [openChatDrawer, setOpenChatDrawer] = useState(false);

  const navigate = useNavigate();
  const goPage = (page: string) => {
    navigate("/" + page);
  };
  const {
    participantList,
    handleFocusMainArea,
    viewMessages,
    listing,
    selectedParticipant,
    content,
    setContent,
    setIsTyping,
    sendChatMessage,
    openMobileChatDrawer,
  } = props;
  participantList.sort(
    (a, b) =>
      new Date(b.latestTime ?? "").getTime() -
      new Date(a.latestTime ?? "").getTime()
  );
  const dispatch = useAppDispatch();
  const me = useAppSelector(getUser);
  const users = useAppSelector(getUsers);
  const curParticipantId = useAppSelector(getParticipantId);

  const [newParticipant, setNewParticipant] = useState<any>({});
  const [userOptions, setUserOptions] = useState<Array<any>>([]);

  const onChangeAutoComplete = (query: string) => {
    const data: SearchDto = {
      query: query,
    };
    dispatch(fetchUserAutocomplete(data));
  };

  const onAllChangeAutoComplete = (user: any) => {
    setNewParticipant({});
    if (me._id !== user._id) {
      dispatch(setParticipantId(user._id));
    }
  };

  const handleItemClick = (participant: IParticipantDto) => {
    dispatch(setParticipantId(participant._id));
    openMobileChatDrawer && openMobileChatDrawer();
  };

  const openChatDrawerFn = () => {
    setOpenChatDrawer(true);
  };

  const setUsersAutoComplete = (users: Array<any>) => {
    if (users && users !== null && users.length > 0) {
      const usersAutoComplete = users.map((user: any) => {
        return {
          ...user,
          name:
            (user.agent?.firstName ?? "") +
            (user.agent?.lastName ? " " + user.agent?.lastName : ""),
        };
      });
      setUserOptions(usersAutoComplete);
    }
  };

  useEffect(() => {
    setUsersAutoComplete(users);
  }, [users]);

  return (
    <>
      <ChatDrawer
        open={openChatDrawer}
        setOpen={setOpenChatDrawer}
        handleFocusMainArea={() => handleFocusMainArea()}
        viewMessages={viewMessages}
        selectedParticipant={selectedParticipant}
        listing={listing}
        content={content}
        setContent={setContent}
        setIsTyping={setIsTyping}
        sendChatMessage={sendChatMessage}
      />
      <Scrollbars className="flex bg-white" autoHide>
        <div className="flex flex-col">
          <div className="flex justify-between border-b border-border p-4 sticky top-0 z-5 bg-white">
            <div className="flex items-center gap-1">
              <Typography variant="h2">Messages</Typography>
              {props.allUnread ? (
                <div className="w-5 rounded-full bg-purple-100 text-purple-700 text-14 flex items-center justify-center  aspect-square">
                  <Typography variant="body">{props.allUnread}</Typography>
                </div>
              ) : (
                ""
              )}
            </div>
            <div>
              {!props.isForHeader ? (
                <IconWrapper name="filter" width={16} />
              ) : (
                <div className="flex gap-3">
                  <button
                    onClick={() => {
                      if (props.closeChatDrawer) {
                        props.closeChatDrawer();
                      }
                      goPage("chat");
                    }}
                  >
                    <IconWrapper name="expand" />
                  </button>
                  <button
                    onClick={() => {
                      if (props.closeChatDrawer) {
                        props.closeChatDrawer();
                      }
                    }}
                  >
                    <IconWrapper name="close" />
                  </button>
                </div>
              )}
            </div>
          </div>

          <div className="flex w-full gap-2 p-3">
            <AutoComplete
              className="w-full bg-[#F1F5F9]"
              options={userOptions ?? []}
              filterKey="name"
              placeholder="Search agent name"
              value={newParticipant.name ?? ""}
              onChange={onChangeAutoComplete}
              onAllChange={onAllChangeAutoComplete}
            />
          </div>

          <div className="flex flex-col h-full divide-y-1">
            {participantList &&
              participantList.length > 0 &&
              participantList
                .filter(
                  (participant: IParticipantDto) => participant.name !== ""
                )
                .map(
                  (participant: IParticipantDto, participantIndex: number) => {
                    const isSelected = curParticipantId === participant._id;
                    return (
                      <ParticipantItem
                        isTyping={props.isTyping}
                        key={participant._id + "__" + participantIndex}
                        participant={participant}
                        handleItemClick={handleItemClick}
                        isSelected={isSelected}
                        isForHeader={props.isForHeader}
                        openChatDrawerFn={() => openChatDrawerFn()}
                        loading={props.loading}
                      />
                    );
                  }
                )}
          </div>
        </div>
      </Scrollbars>
    </>
  );
};

export default ChatSidebar;
