import { NumericFormat } from "react-number-format";
import Typography from "@/components/baseComponents/Typography";
import { ListingAdvancedSearchDto } from '@/shared/interfaces/interfaces';

type IProps = {
  value?: ListingAdvancedSearchDto;
  setValue?: Function;
  handleInputChange?: Function;
}

const Expenses = (props: IProps) => {
  const handleInputChange = (value: any, name: string) => {
    props.handleInputChange && props.handleInputChange(value, name);
  }

  return (
    <div>
      <Typography variant="h3" color="primary">Expenses</Typography>
      <div className="grid grid-cols-1 sm:grid-cols-2 gap-3 mt-[15px]">
        <div className="col-span-2 md:col-span-1">
          <Typography variant="caption" color="secondary" className=''>List Price (Maximum)</Typography>
          <NumericFormat
            autoComplete="off"
            allowLeadingZeros={false}
            thousandSeparator=","
            placeholder="$"
            name="listingListPriceMin"
            value={props.value?.listingListPriceMin || ""}
            onChange={(e) => handleInputChange(e.target.value, "listingListPriceMin")}
            className={`body rounded-5 h-10 text-primary p-4 w-full placeholder:caption border-transparent focus:border-netural-dark ${props.value?.listingListPriceMin ? 'bg-[#E5E7EB]' : 'bg-[#F1F5F9]'}`}
          />
        </div>
        <div className="col-span-2 md:col-span-1">
          <Typography variant="caption" color="secondary" className=''>Annual Taxes (Maximum)</Typography>
          <NumericFormat
            autoComplete="off"
            allowLeadingZeros={false}
            thousandSeparator=","
            placeholder="$"
            name="listingAnnualTaxesMin"
            value={props.value?.listingAnnualTaxesMin || ""}
            onChange={(e) => handleInputChange(e.target.value, "listingAnnualTaxesMin")}
            className={`body rounded-5 h-10 text-primary p-4 w-full placeholder:caption border-transparent focus:border-netural-dark ${props.value?.listingAnnualTaxesMin ? 'bg-[#E5E7EB]' : 'bg-[#F1F5F9]'}`}
          />
        </div>
      </div>
      <div className="grid grid-cols-1 sm:grid-cols-2 gap-3 mt-[15px]">
        <div className="col-span-2 md:col-span-1">
          <Typography variant="caption" color="secondary" className=''>HOA Expenses (Maximum)</Typography>
          <NumericFormat
            autoComplete="off"
            allowLeadingZeros={false}
            thousandSeparator=","
            placeholder="$"
            name="listingHoaExpensesMin"
            value={props.value?.listingHoaExpensesMin || ""}
            onChange={(e) => handleInputChange(e.target.value, "listingHoaExpensesMin")}
            className={`body rounded-5 h-10 text-primary p-4 w-full placeholder:caption border-transparent focus:border-netural-dark ${props.value?.listingHoaExpensesMin ? 'bg-[#E5E7EB]' : 'bg-[#F1F5F9]'}`}
          />
        </div>
        <div className="col-span-2 md:col-span-1">
          <Typography variant="caption" color="secondary" className=''>Other Monthly Expenses (Maximum)</Typography>
          <NumericFormat
            autoComplete="off"
            allowLeadingZeros={false}
            thousandSeparator=","
            placeholder="$"
            name="listingOtherMonthlyExpensesMin"
            value={props.value?.listingOtherMonthlyExpensesMin || ""}
            onChange={(e) => handleInputChange(e.target.value, "listingOtherMonthlyExpensesMin")}
            className={`body rounded-5 h-10 text-primary p-4 w-full placeholder:caption border-transparent focus:border-netural-dark ${props.value?.listingOtherMonthlyExpensesMin ? 'bg-[#E5E7EB]' : 'bg-[#F1F5F9]'}`}
          />
        </div>
      </div>
    </div>
  )
}

export default Expenses;