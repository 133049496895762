import { Fragment, useEffect, useState } from "react";
import { Transition, Popover } from "@headlessui/react";
import { Button } from "@/components/baseComponents/Button";
import { useAppDispatch, useAppSelector } from "@/redux/hooks";
import { zipcodeLookup } from "@/redux/address/addressSlice";
import { useDebounce } from "use-debounce";
import { fetchAllClientsByUserId } from "@/redux/lead/leadSlice";
import { ILead, SearchAllClientsDto } from "@/shared/interfaces/interfaces";
import { getUser } from "@/redux/user/userSlice";
import { IDashboardPreferences } from "@/shared/interfaces/dashboardPreferences/IDashboardPreferences";
import Select from "@/components/baseComponents/Select";
import IconWrapper from "@/components/baseComponents/IconWrapper";
import Typography from "@/components/baseComponents/Typography";

interface Option {
    label: string;
    value: string;
}

type DashboardPreferencesProps = {
    dashboardPreferencesInitialState: IDashboardPreferences;
    dashboardPreferences: IDashboardPreferences;
    setDashboardPreferences: Function;
    applyDashboardPreferences: Function;
    resetDashboardPreferences: Function;
}

const DashboardPreferences = ({
    dashboardPreferences,
    setDashboardPreferences,
    applyDashboardPreferences,
    resetDashboardPreferences
}: DashboardPreferencesProps) => {

    const dispatch = useAppDispatch();
    const user = useAppSelector(getUser);

    const [locationOptions, setLocationOptions] = useState<Array<any>>([]);
    const [locationSearchTerm, setLocationSearchTerm] = useState<string>("");
    const [debouncedLocationSearchTerm] = useDebounce(locationSearchTerm, 1000);

    const [relationshipOptions, setRelationshipOptions] = useState<Array<Option>>([]);
    const [relationshipSearchTerm, setRelationshipSearchTerm] = useState<string>("");
    const [debouncedRelationshipSearchTerm] = useDebounce(relationshipSearchTerm, 1000);

    const onSearchLocation = (searchTerm: string) => {
        if (searchTerm !== "") {
            dispatch(zipcodeLookup(searchTerm)).then((_result: any) => {
                const options: Option[] = [];
                const result = _result.payload.data;
                if (result && result.length > 0) {
                    result.map((zipcode: any) => {
                        const option = `${zipcode.city}, ${zipcode.stateAbbreviation}`;
                        if (!options.some((m: Option) => m.value === option)) {
                            options.push({ label: option, value: option });
                        }
                    });
                    setLocationOptions(options);
                }
            });
        }
    };

    const onSearchRelationship = (searchTerm: string) => {
        if (searchTerm !== "") {
            const payload: SearchAllClientsDto = { userId: user._id, query: searchTerm, relationship: "", type: "" };
            dispatch(fetchAllClientsByUserId(payload)).then((_result: any) => {
                const options: Option[] = [];
                const result = _result.payload.clients as ILead[];
                if (result && result.length > 0) {
                    result.map((lead: ILead) => {
                        const label = `${lead.firstName} ${lead.lastName}`;
                        if (!options.some((m: Option) => m.value === lead._id)) {
                            options.push({ label, value: lead._id });
                        }
                    });
                    setRelationshipOptions(options);
                }
            })
        }
    }

    useEffect(() => {
        if (debouncedLocationSearchTerm !== "") {
            onSearchLocation(debouncedLocationSearchTerm);
        }
    }, [debouncedLocationSearchTerm])

    useEffect(() => {
        if (debouncedRelationshipSearchTerm !== "") {
            onSearchRelationship(debouncedRelationshipSearchTerm);
        }
    }, [debouncedRelationshipSearchTerm])

    return (
        <Popover as="div" className="relative inline-block text-left">
            <Popover.Button>
                <Button variant="contained" color="primary">
                    <Typography variant="button1">
                        Dashboard Preferences
                    </Typography>
                </Button>
            </Popover.Button>
            <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
            >
                <Popover.Panel className="absolute z-10 right-1 bg-white shadow-xl lg:w-[450px] max-sm:w-[340px] px-6 py-6 rounded-md">
                    {({ close }) => (
                        <div className="flex flex-col gap-4">
                            <div className="flex justify-between items-center">
                                <Typography variant="h4" className="whitespace-nowrap">
                                    Dashboard Preferences
                                </Typography>
                                <Button variant="icon" onClick={() => close()}>
                                    <IconWrapper name="close" />
                                </Button>
                            </div>
                            <div className="flex flex-col w-full gap-2">
                                <Typography variant="input-label" className="text-secondary">
                                    Locations
                                </Typography>
                                <Select
                                    isMulti={true}
                                    filterOption={() => true}
                                    options={locationOptions}
                                    value={dashboardPreferences.locations}
                                    onInputChange={(option) => setLocationSearchTerm(option)}
                                    onChange={(options: Option[]) => setDashboardPreferences({ ...dashboardPreferences, locations: options })}
                                />
                            </div>
                            <div className="flex flex-col w-full gap-2">
                                <div className="flex justify-between">
                                    <Typography variant="input-label" className="text-secondary">
                                        Relationships
                                    </Typography>
                                    <div className="flex gap-4 select-none">
                                        <label className="flex items-center">
                                            <input
                                                tabIndex={-1}
                                                type="checkbox"
                                                checked={dashboardPreferences.isAllLeads}
                                                className="rounded border-gray-300 border-2 text-indigo-600 focus:ring-indigo-600 mr-2"
                                                onChange={(event) => setDashboardPreferences({ ...dashboardPreferences, isAllLeads: event.currentTarget.checked })}
                                            />
                                            <Typography variant="body" color="primary">
                                                All Leads
                                            </Typography>
                                        </label>
                                        <label className="flex items-center">
                                            <input
                                                tabIndex={-1}
                                                type="checkbox"
                                                checked={dashboardPreferences.isAllClients}
                                                className="rounded border-gray-300 border-2 text-indigo-600 focus:ring-indigo-600 mr-2"
                                                onChange={(event) => setDashboardPreferences({ ...dashboardPreferences, isAllClients: event.currentTarget.checked })}
                                            />
                                            <Typography variant="body" color="primary">
                                                All Clients
                                            </Typography>
                                        </label>
                                    </div>
                                </div>
                                <Select
                                    isMulti={true}
                                    filterOption={() => true}
                                    options={relationshipOptions}
                                    value={dashboardPreferences.relationships}
                                    onInputChange={(option) => setRelationshipSearchTerm(option)}
                                    onChange={(options: Option[]) => setDashboardPreferences({ ...dashboardPreferences, relationships: options })}
                                />
                            </div>
                            <div className="flex gap-2">
                                <Button
                                    variant="contained"
                                    color="default"
                                    className="flex-1"
                                    onClick={() => {
                                        resetDashboardPreferences();
                                        close();
                                    }}
                                >
                                    <Typography variant="button1" color="primary">
                                        Reset
                                    </Typography>
                                </Button>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    className="flex-1"
                                    onClick={() => {
                                        applyDashboardPreferences(dashboardPreferences, true);
                                        close();
                                    }}
                                >
                                    <Typography variant="button1" color="white">
                                        Apply
                                    </Typography>
                                </Button>
                            </div>
                        </div>
                    )}
                </Popover.Panel>
            </Transition>
        </Popover>
    )
}

export default DashboardPreferences;