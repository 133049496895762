// Define the type for the component props
type Props = {
    width: number;
    height: number;
    stroke?: string;
    className?: string;
};

// Define the IconUnfollow component
const IconUnfollow = (props: Props) => {
    // Return the SVG for the icon
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12 12" fill="none" {...props}>
            <g clipPath="url(#clip0_11200_96329)">
                <path d="M1 10.5C0.999999 9.80404 1.18158 9.12012 1.52681 8.51582C1.87204 7.91151 2.36897 7.40774 2.9685 7.05428C3.56802 6.70082 4.24939 6.50989 4.94529 6.50037C5.64119 6.49086 6.32753 6.66307 6.9365 7" stroke="#6B7280" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M5 6.5C6.38071 6.5 7.5 5.38071 7.5 4C7.5 2.61929 6.38071 1.5 5 1.5C3.61929 1.5 2.5 2.61929 2.5 4C2.5 5.38071 3.61929 6.5 5 6.5Z" stroke="#6B7280" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M8.5 8.5L11 11" stroke="#6B7280" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M11 8.5L8.5 11" stroke="#6B7280" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
            </g>
            <defs>
                <clipPath id="clip0_11200_96329">
                    <rect width="12" height="12" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
};

// Export the IconUnfollow component
export default IconUnfollow;
