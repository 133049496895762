// Define the type for the component props
type Props = {
    width: number;
    height: number;
    stroke?: string;
    className?: string;
};

// Define the IconCompanyTeam component
const IconCompanyTeam = (props: Props) => {
    // Return the SVG for the icon
    return (
        <svg viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <g id="building-2_16">
                <path id="Vector" d="M4.5 14.6654V2.66536C4.5 2.31174 4.64048 1.9726 4.89052 1.72256C5.14057 1.47251 5.47971 1.33203 5.83333 1.33203H11.1667C11.5203 1.33203 11.8594 1.47251 12.1095 1.72256C12.3595 1.9726 12.5 2.31174 12.5 2.66536V14.6654H4.5Z" stroke="#1F2937" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
                <path id="Vector_2" d="M4.49992 8H3.16659C2.81296 8 2.47382 8.14048 2.22378 8.39052C1.97373 8.64057 1.83325 8.97971 1.83325 9.33333V13.3333C1.83325 13.687 1.97373 14.0261 2.22378 14.2761C2.47382 14.5262 2.81296 14.6667 3.16659 14.6667H4.49992" stroke="#1F2937" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
                <path id="Vector_3" d="M12.5 6H13.8333C14.187 6 14.5261 6.14048 14.7761 6.39052C15.0262 6.64057 15.1667 6.97971 15.1667 7.33333V13.3333C15.1667 13.687 15.0262 14.0261 14.7761 14.2761C14.5261 14.5262 14.187 14.6667 13.8333 14.6667H12.5" stroke="#1F2937" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
                <path id="Vector_4" d="M7.16675 4H9.83341" stroke="#1F2937" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
                <path id="Vector_5" d="M7.16675 6.66797H9.83341" stroke="#1F2937" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
                <path id="Vector_6" d="M7.16675 9.33203H9.83341" stroke="#1F2937" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
                <path id="Vector_7" d="M7.16675 12H9.83341" stroke="#1F2937" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
            </g>
        </svg>

    );
};

// Export the IconCompanyTeam component
export default IconCompanyTeam;