
import Typography from "@/components/baseComponents/Typography"
import { Button } from "@/components/baseComponents/Button"
import React, { useEffect } from 'react'
import { AiOutlineInfoCircle } from "react-icons/ai"
import Select from "@/components/baseComponents/Select"
import { useAppDispatch, useAppSelector } from "@/redux/hooks"
import { getUser, updateUserTimezone } from "@/redux/user/userSlice";
import { notify } from "@/shared/services/notify";
import { UpdateUserTimezoneDto } from "@/shared/interfaces/interfaces";
import { useTimezoneSelect, allTimezones } from 'react-timezone-select'

interface IProps {
    onSubmitClick: boolean,
    thereIsChange: (change: boolean) => void,
    revertChanges: number,
    changeSaved:()=>void
}

const Setting = ({ onSubmitClick, thereIsChange, revertChanges, changeSaved}: IProps) => {
    const dispatch = useAppDispatch()
    const user = useAppSelector(getUser);
    const labelStyle = 'original';
    const initialTimezone = (user.isVendor ? user.vendor?.timezone : user.agent?.timezone);
    const detectedTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const timezones = { ...allTimezones, [detectedTimezone]: detectedTimezone }
    const { options } = useTimezoneSelect({ labelStyle, timezones })
    const [selectedTimezone, setSelectedTimezone] = React.useState(initialTimezone || "")


    const submit = () => {
        let data: UpdateUserTimezoneDto = {
            email: user.email,
            timezone: selectedTimezone
        }
        dispatch(updateUserTimezone(data)).then((res) => {
            try {
                notify(res.payload.success, res.payload.message)
            } catch (e) {
                notify(false, 'Something went wrong.')
            }
        })
    }

    const autoDetect = () => {
        setSelectedTimezone(detectedTimezone);
        thereIsChange(initialTimezone !== detectedTimezone);
    }

    useEffect(() => {
        if (onSubmitClick) {
            submit();
            changeSaved();
        }
    }, [onSubmitClick])

    useEffect(() => {
        setSelectedTimezone(initialTimezone);
        thereIsChange(initialTimezone !== detectedTimezone);
    }, [revertChanges])

    return (
        <div className="flex flex-col gap-6">
            <div className="flex flex-col 2xl:flex-row items-start justify-between bg-white p-6 2xl:gap-20 xl:gap-8 rounded-lg">
                <div className='flex flex-col xl:min-w-[300px] xl:pb-0 pb-8'>
                    <Typography variant="h2" color="primary">Time Zone</Typography>
                    <Typography variant="input-label" color="primary">
                        This will be used for displaying times on RealtyView.
                    </Typography>
                </div>
                <div className="w-full flex flex-col gap-4">
                    <div className="flex flex-col w-full gap-2">
                        <div className='flex justify-between'>
                            <Typography variant='input-label' className='text-secondary'>Time Zone</Typography>
                            <Typography variant='caption' className='text-[#4F46E5] flex items-center cursor-pointer hover:text-gray-700' onClick={autoDetect}>
                                <AiOutlineInfoCircle className="h-4 w-4 mr-1" aria-hidden="true" />
                                Auto Detect Timezone
                            </Typography>
                        </div>
                        <div className="flex gap-3">
                            <div className='relative w-full'>
                                <Select
                                    placeholder="Select"
                                    options={options}
                                    name='buyerPropertyType'
                                    value={{ value: selectedTimezone, label: options.find(m => m.value === selectedTimezone)?.label }}
                                    onChange={(option) => {
                                        thereIsChange(initialTimezone !== option.value);
                                        setSelectedTimezone(option.value);
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="flex justify-end items-center mb-12 gap-4 md:hidden">
                <Button color="inherit" className='border-1 border-[#D4D4D8]'>Cancel</Button>
                <Button color="primary" onClick={() => submit()}>Save Changes</Button>
            </div>
        </div>
    )
}
export default Setting
