// React library for building user interfaces
import { useEffect, useState } from "react";

// Importing logo from assets
import Logo from "@/assets/images/logo_black.svg";

// Base components for Typography, TextField, Button, and Select
import Typography from "@/components/baseComponents/Typography";
import TextField from "@/components/baseComponents/TextField";
import { Button } from "@/components/baseComponents/Button";
import Select from "@/components/baseComponents/Select";

// Hook from react-router-dom to navigate between routes
import { useNavigate, useParams, useSearchParams } from "react-router-dom";

// Interfaces for JoinUserDto and SelectType
import { JoinUserDto, SelectType } from "@/shared/interfaces/interfaces";

// Redux actions from userSlice
import { invitationSignUp, invitationVerify, updateStates } from "@/redux/user/userSlice";

// Redux hooks for dispatching actions and selecting state
import { useAppDispatch } from "@/redux/hooks";

// Service for showing notifications
import { notify } from "@/shared/services/notify";

// Service for validation
import validation from "@/shared/services/validation";

// Library for handling cookies in React
import IconWrapper from "@/components/baseComponents/IconWrapper";

const Join = () => {

    // Hook for navigation
    const navigate = useNavigate();

    // Hook for params
    const params = useParams();

    // Hook for search params
    const [searchParams] = useSearchParams();

    // Hook for dispatching actions
    const dispatch = useAppDispatch();

    // Hook for selecting states from the Redux store
    // const states = useAppSelector(getStates);

    // State and setters for form fields
    const [email, setEmail] = useState<string>(searchParams.get("emailAddress") || "");
    const [password, setPassword] = useState<string>("");
    const [licenseState, setLicenseState] = useState<SelectType>({ value: "1", label: "" });
    const [licenseType, setLicenseType] = useState<SelectType>({ value: "1", label: "" });

    // State and setters for form validation errors
    const [errorEmail, setErrorEmail] = useState<boolean>(false);
    const [errorMessagePassword, setErrorMessagePassword] = useState<string>("");

    // State and setters for license state and type lists
    const [licenseStateList, setLicenseStateList] = useState<Array<any>>([]);
    const [licenseTypeList, setLicenseTypeList] = useState<Array<any>>([]);

    // State and setters for show password and show confirm password
    const [showPassword, setShowPassword] = useState<boolean>(false);

    const goLogin = () => {
        navigate("/auth/login");
    };

    const changeEmail = (value: string) => {
        setEmail(value);
        setErrorEmail(validation.IsInvalidEmail(value));
    };

    const changePassword = (value: string) => {
        setPassword(value);
        if (!validation.emptyPassword(value)) {
            setErrorMessagePassword("This field is required");
            return;
        }
        if (!validation.containsNumber(value)) {
            setErrorMessagePassword("Should contain at least a number");
            return;
        }
        if (!validation.containsUppercase(value)) {
            setErrorMessagePassword("Should contain at least a uppercase character");
            return;
        }
        if (!validation.containsSpecial(value)) {
            setErrorMessagePassword("Should contain at least a special character");
            return;
        }
        if (!validation.minLength(value)) {
            setErrorMessagePassword("Should be at least 8 characters");
            return;
        }
        setErrorMessagePassword("");
    };

    const handleFormSubmit = () => {
        changeEmail(email);
        changePassword(password);

        if (
            !errorEmail &&
            errorMessagePassword === ""
        ) {
            let data: JoinUserDto = {
                inviteCode: params.inviteCode,
                email: email.toLocaleLowerCase(),
                password: password,
                licenseState: licenseState.label,
                licenseType: licenseType.label
            };
            dispatch(invitationSignUp(data)).then((res) => {
                try {
                    if (res.payload && res.payload.success) {
                        navigate("/my-listings", { state: { fromInvitation: true } });
                        notify(res.payload.success, res.payload.message);
                    } else {
                        notify(false, "Something went wrong. Please try again or contact support.");
                    }
                } catch (e) {
                    notify(false, "Something went wrong. Please try again or contact support.");
                }
            });
        }
    };

    useEffect(() => {
        dispatch(updateStates()).then((res) => {
            if (res.payload) {
                setLicenseState({
                    value: res.payload.states[0].name,
                    label: res.payload.states[0].name,
                });
                setLicenseType({
                    value: res.payload.states[0].licenseType1,
                    label: res.payload.states[0].licenseType1,
                });
            }
            let tempTypes = [];
            if (res.payload.states[0].licenseType1) {
                tempTypes.push({
                    value: res.payload.states[0].licenseType1,
                    label: res.payload.states[0].licenseType1,
                });
            }
            if (res.payload.states[0].licenseType2) {
                tempTypes.push({
                    value: res.payload.states[0].licenseType2,
                    label: res.payload.states[0].licenseType2,
                });
            }
            if (res.payload.states[0].licenseType3) {
                tempTypes.push({
                    value: res.payload.states[0].licenseType3,
                    label: res.payload.states[0].licenseType3,
                });
            }
            setLicenseTypeList(tempTypes);
            let tempStates = res.payload.states
                .map((state: any) => {
                    return {
                        value: state.name,
                        label: state.name,
                        code: state.abbreviation
                    };
                })
                .sort((a: any, b: any) => {
                    if (a.value < b.value) {
                        return -1;
                    }
                    if (a.value > b.value) {
                        return 1;
                    }
                    return 0;
                });
            setLicenseStateList(tempStates);
        });
    }, []);

    useEffect(() => {
        dispatch(invitationVerify(params.inviteCode)).then((response: any) => {
            if (response.payload && response.payload.success) {
                const state = licenseStateList.find(state => state.code === response.payload.state);
                if (state) setLicenseState(state);
            } else {
                navigate("/404");
            }
        });
    }, [licenseStateList])

    return (
        <div className="flex items-start py-20 px-4 sm:px-6 w-full bg-white min-h-screen select-none">
            <div className="w-full flex flex-col gap-4">
                <div className=" self-center">
                    <img
                        src={Logo}
                        alt="logo"
                        className="  max-w-[197px] sm:max-w-[263px] flex"
                    />
                </div>
                <div className="flex justify-center">
                    <div className=" pt-5 sm:max-w-[600px] w-full space-y-2">
                        <div className=" space-y-2">
                            <Typography variant="h1" className="text-center leading-[141%]">
                                Sign up
                            </Typography>
                            <Typography
                                variant="body"
                                className="text-button-primary hover:text-button-primary-hover cursor-pointer mt-[10px] text-center mb-[20px]"
                                onClick={() => goLogin()}
                            >
                                Have an account? Sign In.
                            </Typography>
                        </div>

                        <div className="flex">
                            <div className="w-full relative py-3">
                                <Typography
                                    variant="caption"
                                    className="text-secondary mb-[9px]"
                                >
                                    Invite Code
                                </Typography>
                                <TextField value={params.inviteCode || ""} readOnly className="text-tertiary" />
                            </div>
                        </div>

                        <div className="relative py-3 space-y-2">
                            <Typography variant="caption" className="text-secondary mb-[9px]">
                                Update Email Address (Optional)
                            </Typography>
                            <TextField
                                className={`w-full ${errorEmail ? "outline outline-1 rounded outline-[#E01010]" : ""}`}
                                value={email}
                                onChange={(e) => changeEmail(e.target.value)}
                            />
                            {errorEmail && (
                                <Typography
                                    variant="caption"
                                    className="text-[#E01010] absolute mt-[2px]"
                                >
                                    Invalid Email Address
                                </Typography>
                            )}
                        </div>

                        <div className="flex">
                            <div className="w-full relative py-3">
                                <Typography
                                    variant="caption"
                                    className="text-secondary mb-[9px]"
                                >
                                    Choose a Password
                                </Typography>
                                <TextField
                                    className={`w-full ${errorMessagePassword !== ""
                                        ? "outline outline-1 rounded outline-[#E01010]"
                                        : ""
                                        }`}
                                    type={showPassword ? "text" : "password"}
                                    value={password}
                                    onChange={(e) => changePassword(e.target.value)}
                                    endAdornment={
                                        <div className="cursor-pointer" onClick={() => setShowPassword(!showPassword)} tabIndex={-1}>
                                            <IconWrapper name="eye" className={showPassword ? `[&>svg>path]:!stroke-indigo-600` : ``} width={20} />
                                        </div>
                                    }
                                />
                                {errorMessagePassword !== "" && (
                                    <Typography
                                        variant="caption"
                                        className="text-[#E01010] absolute mt-[2px]"
                                    >
                                        {errorMessagePassword}
                                    </Typography>
                                )}
                            </div>
                        </div>

                        <div className="grid sm:grid-cols-2 sm:gap-3">
                            <div className="col-span-1 space-y-2 py-3">
                                <Typography
                                    variant="caption"
                                    className="text-secondary mb-[9px]"
                                >
                                    License State
                                </Typography>
                                <Select
                                    options={licenseStateList}
                                    placeholder="Select"
                                    value={licenseState}
                                    onChange={(value) => setLicenseState(value)}
                                />
                            </div>
                            <div className="col-span-1 space-y-2 py-3">
                                <Typography
                                    variant="caption"
                                    className="text-secondary mb-[9px]"
                                >
                                    License Type
                                </Typography>
                                <Select
                                    options={licenseTypeList}
                                    placeholder=""
                                    value={licenseType}
                                    onChange={(value) => setLicenseType(value)}
                                />
                            </div>
                        </div>

                        <Button
                            className="w-full text-15 my-3"
                            onClick={() => handleFormSubmit()}
                        >
                            Sign Up
                        </Button>

                        <Typography
                            variant="body"
                            className=" text-secondary text-center pt-2"
                        >
                            RealtyView is forever-free. We don’t required your credit card.
                        </Typography>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Join;
