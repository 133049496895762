import Typography from "@/components/baseComponents/Typography"

type IProps = {
  data?: any
}

const Legal = (props: IProps) => {

  return (
    <div>
      <Typography variant="h3" color="primary">
        Buyer’s Attorney
      </Typography>
      <div className="flex w-full mt-[25px]">
        <div className="w-1/3 sm:w-full">
          <Typography variant="caption" color="secondary">
            First Name
          </Typography>
          <Typography variant="body" color="primary">
            {props.data?.buyeraAttorneyFirstName || "-"}
          </Typography>
        </div>
        <div className="w-1/3 sm:w-full">
          <Typography variant="caption" color="secondary">
            Last Name
          </Typography>
          <Typography variant="body" color="primary">
            {props.data?.buyerAttorneyLastName || "-"}
          </Typography>
        </div>
        <div className="w-1/3 sm:w-full">
          <Typography variant="caption" color="secondary">
            Phone Number
          </Typography>
          <Typography variant="body" color="primary">
            {props.data?.buyerAttorneyPhoneNumber || "-"}
          </Typography>
        </div>
      </div>
      <div className="flex w-full mt-[25px] mb-[50px]">
        <div className="w-1/3 sm:w-full">
          <Typography variant="caption" color="secondary">
            Email
          </Typography>
          <Typography variant="body" color="primary">
            {props.data?.buyerAttorneyEmailAddress || "-"}
          </Typography>
        </div>
        <div className="w-1/3 sm:w-full">
          <Typography variant="caption" color="secondary">
            Company
          </Typography>
          <Typography variant="body" color="primary">
            {props.data?.buyerAttorneyCompany || "-"}
          </Typography>
        </div>
        <div className="w-1/3 sm:w-full">
          <Typography variant="caption" color="secondary">
            Address
          </Typography>
          <div>
            {props.data?.buyerAttorneyAddress && props.data?.buyerAttorneyAddress.city && props.data?.buyerAttorneyAddress.state && props.data?.buyerAttorneyAddress.zipcode ? (
              <>
                <div>
                  <Typography variant="body" color="primary" className="line-clamp-2">
                    {props.data?.buyerAttorneyAddress?.streetLine ? props.data?.buyerAttorneyAddress?.streetLine + " " : ""}
                    {props.data?.buyerAttorneyAddress?.secondary ? props.data?.buyerAttorneyAddress?.secondary + " " : ""}
                  </Typography>
                </div>
                <div>
                  <Typography variant="body" color="primary" className="line-clamp-1">
                    {props.data?.buyerAttorneyAddress?.city + ", " + props.data?.buyerAttorneyAddress?.state + " " + props.data?.buyerAttorneyAddress?.zipcode}
                  </Typography>
                </div>
              </>
            ) : "-"}
          </div>
        </div>
      </div>

      <Typography variant="h3" color="primary">
        Seller’s Attorney
      </Typography>
      <div className="flex w-full mt-[25px]">
        <div className="w-1/3 sm:w-full">
          <Typography variant="caption" color="secondary">
            First Name
          </Typography>
          <Typography variant="body" color="primary">
            {props.data?.sellerAttorneyFirstName || "-"}
          </Typography>
        </div>
        <div className="w-1/3 sm:w-full">
          <Typography variant="caption" color="secondary">
            Last Name
          </Typography>
          <Typography variant="body" color="primary">
            {props.data?.sellerAttorneyLastName || "-"}
          </Typography>
        </div>
        <div className="w-1/3 sm:w-full">
          <Typography variant="caption" color="secondary">
            Phone Number
          </Typography>
          <Typography variant="body" color="primary">
            {props.data?.sellerAttorneyPhoneNumber || "-"}
          </Typography>
        </div>
      </div>
      <div className="flex w-full mt-[25px] mb-[50px]">
        <div className="w-1/3 sm:w-full">
          <Typography variant="caption" color="secondary">
            Email
          </Typography>
          <Typography variant="body" color="primary">
            {props.data?.sellerAttorneyEmailAddress || "-"}
          </Typography>
        </div>
        <div className="w-1/3 sm:w-full">
          <Typography variant="caption" color="secondary">
            Company
          </Typography>
          <Typography variant="body" color="primary">
            {props.data?.sellerAttorneyCompany || "-"}
          </Typography>
        </div>
        <div className="w-1/3 sm:w-full">
          <Typography variant="caption" color="secondary">
            Address
          </Typography>
          <div>
            {props.data?.sellerAttorneyAddress && props.data?.sellerAttorneyAddress.city && props.data?.sellerAttorneyAddress.state && props.data?.sellerAttorneyAddress.zipcode ? (
              <>
                <div>
                  <Typography variant="body" color="primary" className="line-clamp-2">
                    {props.data?.sellerAttorneyAddress?.streetLine ? props.data?.sellerAttorneyAddress?.streetLine + " " : ""}
                    {props.data?.sellerAttorneyAddress?.secondary ? props.data?.sellerAttorneyAddress?.secondary + " " : ""}
                  </Typography>
                </div>
                <div>
                  <Typography variant="body" color="primary" className="line-clamp-1">
                    {props.data?.sellerAttorneyAddress?.city + ", " + props.data?.sellerAttorneyAddress?.state + " " + props.data?.sellerAttorneyAddress?.zipcode}
                  </Typography>
                </div>
              </>
            ) : "-"}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Legal