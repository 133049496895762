interface IProps {
  title: string;
  selected?: boolean;
  onClick?: Function;
}

const PaginationItem = ({ title, selected = false, onClick }: IProps) => {
  return (
    <div
      onClick={()=>onClick}
      className={`flex ${
        selected ? "text-white" : "text-[#9EA3AE]"
      } text-[18px] font-bold rounded-full w-10 h-10 ${
        selected ? "bg-[#100A55]" : "bg-white"
      } justify-center items-center`}
    >
      {title}
    </div>
  );
};

export default PaginationItem;
