type Props = {
  width: number;
  height: number;
  stroke?: string;
  className?: string;
};

const IconHeartBeat = (props: Props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 16 16"
      fill="none"
      {...props}
    >
      <path
        d="M14.6667 8H12L10 14L6.00004 2L4.00004 8H1.33337"
        stroke="#F43F5E"
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default IconHeartBeat;
