import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import type { RootState } from "../store";
import axios from "@/shared/services/axios";
import { ISearch } from "@/shared/interfaces/search/ISearch";

interface AddressState {
  results: Array<any>;
}

const initialState: AddressState = {
  results: []
};

export const searchSlice = createSlice({
  name: "search",
  initialState,
  reducers: {}
});

export const searchActions = searchSlice.actions;

export const getResults = (state: RootState) => state.search.results;

export default searchSlice.reducer;

export const search = createAsyncThunk(
  "search",
  async (payload: ISearch) => {
    const response = await axios.post("search", payload);
    return response.data;
  }
);