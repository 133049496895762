import Typography from "@/components/baseComponents/Typography";
import Select from "@/components/baseComponents/Select";
import TextField from "@/components/baseComponents/TextField";
import Textarea from "@/components/baseComponents/Textarea";
import AddressAutoComplete from "@/components/baseComponents/AddressAutoComplete";
import { useAppDispatch, useAppSelector } from "@/redux/hooks";
import { fetchAllClientsByUserId } from "@/redux/lead/leadSlice";
import { IMyListing } from "@/shared/interfaces/interfaces";
import { getUser } from "@/redux/user/userSlice";
import React, { useState } from "react";
import { notify } from "@/shared/services/notify";
import { formatAddress } from "@/shared/services/utils";
import IconWrapper from "@/components/baseComponents/IconWrapper";
import { Button } from "@/components/baseComponents/Button";
import Label from "@/components/baseComponents/Label";

type IProps = {
  data?: IMyListing;
  handleInputChange: Function;
  handleSelectChange: Function;
  handleDateChange: Function;
  // errorPrimarySellerFirstName: boolean;
  // errorPrimarySellerLastName: boolean;
  errorPrimarySellerPhoneNumber: boolean;
  errorPrimarySellerEmailAddress: boolean;
  errorSecondarySellerPhoneNumber: boolean;
  errorSecondarySellerEmailAddress: boolean;
  addresses: Array<any>;
  onChangeAddressAutoComplete: Function;
  onSelectAddressAutoComplete: Function;
  isNewClient: boolean;
  setIsNewClient: React.Dispatch<React.SetStateAction<boolean>>;
};

const Client = (props: IProps) => {
  const dispatch = useAppDispatch();
  const user = useAppSelector(getUser);
  const onInputChangeAutoComplete = (value: any) => {
    dispatch(
      fetchAllClientsByUserId({
        query: value,
        type: "Seller",
        userId: user._id,
        relationship: "Client",
      })
    );
  };
  const [clientOptions, setClientOptions] = useState<any>([]);
  const [selectedClient, setSelectedClient] = useState<any>(null);
  const [sameAsCurrent, setSameAsCurrent] = useState<boolean>(false);

  // to test clientOptions, and clientOptions does not work properly

  // const clientNewOptions = [
  //   { value: "Single Family Residence", label: "Single Family Residence" },
  //   { value: "Multi Family Residence", label: "Multi Family Residence" },
  //   { value: "Apartment", label: "Apartment" },
  //   { value: "Townhouse", label: "Townhouse" },
  //   { value: "Land", label: "Land" },
  //   { value: "Mobile", label: "Mobile" },
  //   { value: "Commercial", label: "Commercial" },
  //   { value: "Other", label: "Other" },
  // ];

  React.useEffect(() => {
    dispatch(
      fetchAllClientsByUserId({
        query: "",
        type: "Seller",
        userId: user._id,
        relationship: "Client",
      })
    ).then((res: any) => {
      try {
        const clients = res.payload.clients;
        setClientOptions([
          ...clientOptions,
          ...clients.map((item: any) => {
            return { value: item, label: item.firstName + " " + item.lastName };
          }),
        ]);
      } catch (e) {
        notify(false, "Something went wrong.");
      }
    });
  }, [setClientOptions, user._id]);

  return (
    <div className="px-8 py-3">
      <div className="flex gap-1 flex-col w-full">
        <div>
          <Typography variant="caption" color="secondary" className="">
            Client
          </Typography>
          <Select
            options={clientOptions}
            name="client"
            placeholder="Search"
            // isValid={!props.errorPrimarySellerFirstName || selectedClient}
            notFoundText="Only Seller Leads and Clients will display here"
            value={
              props.data?.client
                ? {
                    value: props.data?.client,
                    label:
                      props.data?.primarySellerFirstName +
                      " " +
                      props.data?.primarySellerLastName,
                  }
                : { value: "", label: "Search" }
            }
            onChange={(value) => {
              props.handleSelectChange(value, "client");
              setSelectedClient(value.value);
              props.setIsNewClient(false);
            }}
            onInputChange={(value) => onInputChangeAutoComplete(value)}
          />
          {/* {(props.errorPrimarySellerFirstName && !selectedClient) && (
            <Typography
              variant="caption"
              className="text-[#E01010] absolute mt-[2px]"
            >
              Please select or add new client
            </Typography>
          )} */}
        </div>
        <Button
          variant="text"
          className="flex items-center gap-1 self-end !p-0"
          onClick={() => {
            props.handleSelectChange({ value: {}, label: "" }, "client");
            setSelectedClient(null);
            props.setIsNewClient(!props.isNewClient);
          }}
        >
          <IconWrapper
            name="plus"
            className="[&>svg>path]:!stroke-indigo-600"
            width={14}
          />
          <Typography variant="body" className="text-indigo-600">
            Add new
          </Typography>
        </Button>
      </div>
      {!props.isNewClient && selectedClient && (
        <div className="flex justify-between border-1 rounded-xl p-3 mt-2">
          <div className="flex flex-col w-full">
            <div className="flex gap-2">
              <Typography variant="body" color="primary" className="!font-bold">
                {selectedClient.firstName + " " + selectedClient.lastName}
              </Typography>
              <Label
                variant="contained"
                color="info"
                size="small"
                className="rounded-lg"
                title={selectedClient.isClient ? "Client" : "Lead"}
              />
            </div>
            <Typography variant="body" color="primary">
              {selectedClient.phoneNumber}
            </Typography>
            <Typography variant="body" color="primary">
              {selectedClient.email}
            </Typography>
          </div>
          {selectedClient.address && selectedClient.address.city && (
            <div className="flex flex-col w-full">
              <Typography variant="caption" color="tertiary">
                Current Address
              </Typography>
              <Typography variant="body">
                {selectedClient.address.streetLine +
                  " " +
                  selectedClient.address.secondary}
              </Typography>
              <Typography variant="body">
                {selectedClient.address.city +
                  ", " +
                  selectedClient.address.state +
                  " " +
                  selectedClient.address.zipcode}
              </Typography>
            </div>
          )}
        </div>
      )}
      {!selectedClient && props.isNewClient && (
        <div className="py-3">
          <Typography variant="h3" color="primary" className="py-3">
            Primary Seller Details
          </Typography>
          <div className="grid grid-cols-2 gap-4 py-3">
            <div className="flex flex-col col-span-2 sm:col-span-1 gap-2">
              <Typography variant="input-label" color="secondary">
                {/* First Name <span className="text-red-600">*</span> */}
                First Name
              </Typography>
              <TextField
                name="primarySellerFirstName"
                disabled={props.data?.client ? true : false}
                // className={`w-full ${props.errorPrimarySellerFirstName
                //   ? "outline outline-1 rounded outline-[#E01010]"
                //   : ""
                //   }`}
                className="w-full"
                value={props.data?.primarySellerFirstName}
                onChange={(e) => props.handleInputChange(e)}
              />
              {/* {props.errorPrimarySellerFirstName && (
                <Typography
                  variant="caption"
                  className="text-[#E01010] absolute mt-[2px]"
                >
                  This field is required
                </Typography>
              )} */}
            </div>
            <div className="flex flex-col col-span-2 sm:col-span-1 gap-2">
              <Typography variant="input-label" color="secondary">
                {/* Last Name <span className="text-red-600">*</span> */}
                Last Name
              </Typography>
              <TextField
                disabled={props.data?.client ? true : false}
                // className={`w-full ${props.errorPrimarySellerLastName
                //   ? "outline outline-1 rounded outline-[#E01010]"
                //   : ""
                //   }`}
                className="w-full"
                name="primarySellerLastName"
                value={props.data?.primarySellerLastName}
                onChange={(e) => props.handleInputChange(e)}
              />
              {/* {props.errorPrimarySellerLastName && (
                <Typography
                  variant="caption"
                  className="text-[#E01010] absolute mt-[2px]"
                >
                  This field is required
                </Typography>
              )} */}
            </div>
          </div>

          <div className="grid grid-cols-2 gap-4 py-3 relative">
            <div className="col-span-2 sm:col-span-1 flex flex-col gap-2">
              <Typography variant="input-label" color="secondary">
                Phone Number
              </Typography>
              <TextField
                disabled={props.data?.client ? true : false}
                className={`w-full ${
                  props.errorPrimarySellerPhoneNumber
                    ? "outline outline-1 rounded outline-[#E01010]"
                    : ""
                }`}
                maxLength={12}
                name="primarySellerPhoneNumber"
                value={props.data?.primarySellerPhoneNumber}
                onChange={(e) => props.handleInputChange(e)}
              />
              {props.errorPrimarySellerPhoneNumber && (
                <Typography
                  variant="caption"
                  className="text-[#E01010] absolute -bottom-2"
                >
                  Please enter a valid phone number
                </Typography>
              )}
            </div>
            <div className="col-span-2 sm:col-span-1 flex flex-col gap-2">
              <Typography variant="input-label" color="secondary">
                Email Address
              </Typography>
              <TextField
                disabled={props.data?.client ? true : false}
                className={`w-full ${
                  props.errorPrimarySellerEmailAddress
                    ? "outline outline-1 rounded outline-[#E01010]"
                    : ""
                }`}
                name="primarySellerEmailAddress"
                value={props.data?.primarySellerEmailAddress}
                onChange={(e) => props.handleInputChange(e)}
              />
              {props.errorPrimarySellerEmailAddress && (
                <Typography
                  variant="caption"
                  className="text-[#E01010] absolute -bottom-2"
                >
                  Invalid Email Address
                </Typography>
              )}
            </div>
          </div>
          <div className="flex flex-col py-3 w-full">
            <Typography variant="input-label" color="secondary">
              Company Name
            </Typography>
            <TextField
              placeholder=""
              disabled={props.data?.client ? true : false}
              name="primarySellerCompanyName"
              value={props.data?.primarySellerCompanyName}
              onChange={(e) => props.handleInputChange(e)}
            />
          </div>
          <div className="flex py-4 flex-col gap-4">
            <div className="flex flex-col w-full gap-2">
              <Typography variant="input-label" color="secondary">
                Current Address
              </Typography>
              <AddressAutoComplete
                disabled={props.data?.client ? true : false}
                options={props.addresses}
                placeholder="Enter Primary Seller's Current Address"
                value={formatAddress(props.data?.primarySellerCurrentAddress)}
                onChange={(e) =>
                  props.onChangeAddressAutoComplete(
                    e,
                    "primarySellerCurrentAddress"
                  )
                }
                onAllChange={(e) =>
                  props.onSelectAddressAutoComplete(
                    e,
                    "primarySellerCurrentAddress"
                  )
                }
              />
            </div>
            <div className="flex flex-col pb-3 gap-2">
              <Typography variant="input-label" color="secondary">
                Mailing Address
              </Typography>
              <div className="flex items-center">
                <input
                  id="mail"
                  type="checkbox"
                  checked={sameAsCurrent}
                  className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600 rounded-md"
                  onChange={() => {
                    setSameAsCurrent(!sameAsCurrent);
                  }}
                />
                <label htmlFor={`mail`} className="ml-1 block">
                  <Typography variant="page-menu">
                    Same as current address
                  </Typography>
                </label>
              </div>
              {!sameAsCurrent && (
                <AddressAutoComplete
                  disabled={props.data?.client ? true : false}
                  options={props.addresses}
                  placeholder="Enter Primary Seller's Mailing Address"
                  value={formatAddress(props.data?.primarySellerMailingAddress)}
                  onChange={(e) =>
                    props.onChangeAddressAutoComplete(
                      e,
                      "primarySellerMailingAddress"
                    )
                  }
                  onAllChange={(e) =>
                    props.onSelectAddressAutoComplete(
                      e,
                      "primarySellerMailingAddress"
                    )
                  }
                />
              )}
            </div>
          </div>
          <Typography variant="h3" color="primary" className="py-3">
            Secondary Seller Details
          </Typography>
          <div className="grid grid-cols-2 gap-4 py-3">
            <div className="flex flex-col col-span-2 sm:col-span-1 gap-2">
              <Typography variant="input-label" color="secondary">
                First Name
              </Typography>
              <TextField
                disabled={props.data?.client ? true : false}
                name="secondarySellerFirstName"
                value={props.data?.secondarySellerFirstName}
                onChange={(e) => props.handleInputChange(e)}
              />
            </div>
            <div className="flex flex-col col-span-2 sm:col-span-1 gap-2">
              <Typography variant="input-label" color="secondary">
                Last Name
              </Typography>
              <TextField
                disabled={props.data?.client ? true : false}
                name="secondarySellerLastName"
                value={props.data?.secondarySellerLastName}
                onChange={(e) => props.handleInputChange(e)}
              />
            </div>
          </div>
          <div className="grid grid-cols-2 gap-4 py-3">
            <div className="flex flex-col col-span-2 sm:col-span-1 gap-2">
              <Typography variant="input-label" color="secondary">
                Phone Number
              </Typography>
              <TextField
                disabled={props.data?.client ? true : false}
                className={`w-full ${
                  props.errorSecondarySellerPhoneNumber
                    ? "outline outline-1 rounded outline-[#E01010]"
                    : ""
                }`}
                maxLength={12}
                name="secondarySellerPhoneNumber"
                value={props.data?.secondarySellerPhoneNumber}
                onChange={(e) => props.handleInputChange(e)}
              />
              {props.errorSecondarySellerPhoneNumber && (
                <Typography
                  variant="caption"
                  className="text-[#E01010] absolute mt-[2px]"
                >
                  Please enter a valid phone number
                </Typography>
              )}
            </div>
            <div className="flex flex-col col-span-2 sm:col-span-1 gap-2">
              <Typography variant="input-label" color="secondary">
                Email Address
              </Typography>
              <TextField
                disabled={props.data?.client ? true : false}
                className={`w-full ${
                  props.errorSecondarySellerEmailAddress
                    ? "outline outline-1 rounded outline-[#E01010]"
                    : ""
                }`}
                name="secondarySellerEmailAddress"
                value={props.data?.secondarySellerEmailAddress}
                onChange={(e) => props.handleInputChange(e)}
              />
              {props.errorSecondarySellerEmailAddress && (
                <Typography
                  variant="caption"
                  className="text-[#E01010] absolute mt-[2px]"
                >
                  Invalid Email Address
                </Typography>
              )}
            </div>
          </div>
        </div>
      )}

      <div className="py-3">
        <Typography variant="h3" color="primary" className="py-3">
          Notes
        </Typography>
        <div className="">
          <div className="flex items-center justify-between py-2">
            <Typography variant="input-label" color="secondary">
              About the Listing
            </Typography>
            <Typography variant="input-label" color="secondary">
              0/100
            </Typography>
          </div>
          <Textarea
            name="listingNotes"
            value={props.data?.listingNotes}
            rows={5}
            onChange={(e) => props.handleInputChange(e)}
          />
        </div>
      </div>
    </div>
  );
};

export default Client;
