// Import necessary hooks and types from 'react-redux' and local 'store'
import { useDispatch, useSelector } from 'react-redux';
import type { TypedUseSelectorHook } from 'react-redux';
import type { RootState, AppDispatch } from './store';

// Define a custom hook for dispatching actions, typed with AppDispatch
// Use this instead of the plain `useDispatch` for type safety
export const useAppDispatch: () => AppDispatch = useDispatch;

// Define a custom hook for selecting state from the store, typed with RootState
// Use this instead of the plain `useSelector` for type safety
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;