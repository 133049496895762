type Props = {
  width: number;
  height: number;
  stroke?: string;
  className?: string;
};

const IconEye = (props: Props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="none" {...props}>
      <path d="M1.66675 9.9974C1.66675 9.9974 4.16675 4.16406 10.0001 4.16406C15.8334 4.16406 18.3334 9.9974 18.3334 9.9974C18.3334 9.9974 15.8334 15.8307 10.0001 15.8307C4.16675 15.8307 1.66675 9.9974 1.66675 9.9974Z" stroke="#1F2937" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M10 12.5C11.3807 12.5 12.5 11.3807 12.5 10C12.5 8.61929 11.3807 7.5 10 7.5C8.61929 7.5 7.5 8.61929 7.5 10C7.5 11.3807 8.61929 12.5 10 12.5Z" stroke="#1F2937" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};

export default IconEye;
