// Define the type for the component props
type Props = {
  width: number;
  height: number;
  stroke?: string;
  className?: string;
};

// Define the IconAgents component
const IconLink = (props: Props) => {
  // Return the SVG for the icon
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 16 17"
      fill="none"
      {...props}
    >
      <path
        d="M4.66699 4.87305H11.3337V11.5397"
        stroke="#4F46E5"
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.66699 11.5397L11.3337 4.87305"
        stroke="#4F46E5"
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

// Export the IconAgents component
export default IconLink;
