import React from "react";
import Logo from "@/assets/images/logo_black.svg";
import Typography from "@/components/baseComponents/Typography";
import TextField from "@/components/baseComponents/TextField";
import { Button } from "@/components/baseComponents/Button";
import { useNavigate } from "react-router-dom";
import { useAppDispatch } from "@/redux/hooks";
import { forgotPassword } from "@/redux/user/userSlice";
import { ForgotPasswordDto } from "@/shared/interfaces/interfaces";
import { notify } from "@/shared/services/notify";
const ForgotPassword = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [email, setEmail] = React.useState<string>("");

  const goLogin = () => {
    navigate("/auth/login");
  };

  const sendCode = () => {
    if (email.length === 0) {
      notify(false, "Email field is required");
    } else {
      let data: ForgotPasswordDto = {
        email: email.toLocaleLowerCase(),
      };
      dispatch(forgotPassword(data)).then((res) => {
        notify(res.payload.success, res.payload.message);
        if (res.payload.success) navigate("/auth/reset-password");
      });
    }
  };

  return (
    <div className="flex items-start py-20 px-4 sm:px-6 w-full bg-white min-h-screen select-none">
      <div className="w-full flex flex-col gap-4">
        <div className=" self-center">
          <img
            src={Logo}
            alt="logo"
            className="  max-w-[197px] sm:max-w-[263px] flex"
          />
        </div>
        <div className="flex justify-center">
          <div className=" pt-5 sm:max-w-[600px] w-full space-y-2">
            <div className="flex flex-col items-center gap-1">
              <Typography variant="h1" className="text-center">
                Forgot Password
              </Typography>
              <Typography
                variant="body"
                className="text-primary max-w-[400px] font-normal cursor-pointer text-center"
              >
                Enter your email address and we will send you a link to reset
                your password.
              </Typography>
            </div>

            <div className="py-3 space-y-2">
              <Typography variant="caption" className="text-secondary mb-[9px]">
                Email Address
              </Typography>
              <TextField
                className="w-full mb-[20px]"
                autoComplete="off"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>

            <Button className="w-full text-15 my-4" onClick={() => sendCode()}>
              Send Code
            </Button>

            <Typography
              variant="body"
              className="text-button-primary font-normal hover:text-button-primary-hover cursor-pointer text-center pt-2"
              onClick={() => goLogin()}
            >
              Return to Sign In
            </Typography>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
