// Define the type for the component props
type Props = {
    width: number;
    height: number;
    stroke?: string;
    className?: string;
};

// Define the IconSubmitOffer component
const IconSubmitOffer = (props: Props) => {
    // Return the SVG for the icon
    return (
        <svg viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <g id="file-input24">
                <path id="Vector" d="M3.16675 14.6693H12.5001C12.8537 14.6693 13.1928 14.5288 13.4429 14.2787C13.6929 14.0287 13.8334 13.6896 13.8334 13.3359V4.66927L10.5001 1.33594H4.50008C4.14646 1.33594 3.80732 1.47641 3.55727 1.72646C3.30722 1.97651 3.16675 2.31565 3.16675 2.66927V5.33594" stroke="#D1D5DB" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
                <path id="Vector_2" d="M9.83325 1.33594V4.0026C9.83325 4.35623 9.97373 4.69536 10.2238 4.94541C10.4738 5.19546 10.813 5.33594 11.1666 5.33594H13.8333" stroke="#D1D5DB" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
                <path id="Vector_3" d="M1.83325 10H8.49992" stroke="#D1D5DB" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
                <path id="Vector_4" d="M6.5 12L8.5 10L6.5 8" stroke="#D1D5DB" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
            </g>
        </svg>

    );
};

// Export the IconSubmitOffer component
export default IconSubmitOffer;
