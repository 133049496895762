// Router imports
import { Navigate, createBrowserRouter } from "react-router-dom";

// Config imports
import { AUTH } from "../shared/config/constants";

// Layout imports
import FullLayout from "@/components/layouts/FullLayout";
import AuthLayout from "@/components/layouts/AuthLayout";
import MainLayout from "@/components/layouts/MainLayout";
import PublicListingLayout from "@/components/layouts/PublicListingLayout";

// Protected Route import
import Protected from "@/shared/config/Protected";

// Page imports
import Agent from "./agent";
import Client from "./client";
import Dashboard from "./dashboard";
import ForgotPassword from "./forgotPassword";
import Home from "./home";
import Lead from "./lead";
import Login from "./login";
import MyListings from "./myListings";
import NotFound from "./notFound";
import Offer from "./offer";
import Profile from "./profile";
import PublicListing from "./public_listing/PublicListing";
import Register from "./register";
import RegisterVendor from "./register/RegisterVendor";
import ResetPassword from "./resetPassword";
import SearchListings from "./SearchListings";
import Showing from "./showing";
import Test from "./test";
import Transactions from "./transactions";
import Vendor from "./vendor";
import Chat from "./chat";

// Admin page imports
import Advertisements from "./admin/advertisements";
import Affiliates from "./admin/affiliates";
import Agents from "./admin/users/agents";
import Amenities from "./admin/listings/amenities";
import Announcements from "./admin/announcements";
import Clients from "./admin/users/clients";
import Companies from "./admin/companies";
import CreateOfferDocument from "./admin/offerDocuments/CreateDocument";
import HomepageFeeds from "./admin/homepageFeed";
import Keywords from "./admin/listings/keywords";
import Leads from "./admin/users/leads";
import LocalAssociations from "./admin/localAssociations";
import MLSAssociations from "./admin/mlsAssociations";
import OfferDocuments from "./admin/offerDocuments";
import Posts from "./admin/posts";
import Schemas from "./admin/listings/schemas";
import SchoolDistricts from "./admin/listings/schoolDistricts";
import StateAssociations from "./admin/stateAssociations";
import Teams from "./admin/teams";
import USStates from "./admin/usStates";
import Welcome from "./admin/welcome/index";
import LandingLayout from "./landing/LandingLayout";
import LandingPage from "./landing";
import LandingLogin from "./landing/LandingLogin";
import LandingRegister from "./landing/LandingRegister";
import PropertyResultPage from "./landing/PropertyResults";
import PropertyDetailPage from "./landing/PropertyDetail";
import MainLandingLayout from "@/components/layouts/MainLandingLayout";
import Join from "./join";
import ScrapeHero from "./admin/scrapeHero";

const userRoutes = [
  { index: true, element: <Agents /> },
  { path: "agents", element: <Agents /> },
  { path: "leads", element: <Leads /> },
  { path: "clients", element: <Clients /> },
];

const offerDocumentRoutes = [
  { index: true, element: <OfferDocuments /> },
  { path: "create", element: <CreateOfferDocument /> },
];

const profileRoutes = [
  { index: true, element: <Profile /> },
  { path: "info", element: <Profile /> },
  { path: "company", element: <Profile /> },
  { path: "credentials", element: <Profile /> },
  { path: "settings", element: <Profile /> },
  { path: "verify", element: <Profile /> },
];

const listingRoutes = [
  { index: true, element: <SearchListings /> },
  { path: "company", element: <SearchListings /> },
  { path: "team", element: <SearchListings /> },
  { path: "saved", element: <SearchListings /> },
];

const agentRoutes = [
  { index: true, element: <Agent /> },
  { path: "all", element: <Agent /> },
  { path: "favorite", element: <Agent /> },
  { path: "company", element: <Agent /> },
  { path: "team", element: <Agent /> },
  { path: "myprofile", element: <Agent /> },
];

const landingRoutes = [
  {
    index: true,
    element: <LandingLayout Component={LandingPage} isHome={true} />,
  },
  {
    path: "login",
    element: <LandingLogin />,
  },
  {
    path: "register",
    element: <LandingRegister />,
  },
  {
    path: "property",
    element: <LandingLayout Component={PropertyResultPage} />,
  },
  {
    path: "property/:id",
    element: <LandingLayout Component={PropertyDetailPage} />,
  },
]

const adminRoutes = [
  { index: true, element: <Home /> },
  { path: "profile", children: profileRoutes },
  { path: "announcements", element: <Announcements /> },
  { path: "affiliates", element: <Affiliates /> },
  { path: "teams", element: <Teams /> },
  { path: "Companies", element: <Companies /> },
  { path: "users", children: userRoutes },
  { path: "listings/schemas", element: <Schemas /> },
  { path: "listings/amenities", element: <Amenities /> },
  { path: "listings/keywords", element: <Keywords /> },
  { path: "listings/school-districts", element: <SchoolDistricts /> },
  { path: "local-associations", element: <LocalAssociations /> },
  { path: "state-associations", element: <StateAssociations /> },
  { path: "mls-associations", element: <MLSAssociations /> },
  { path: "us-states", element: <USStates /> },
  { path: "posts", element: <Posts /> },
  { path: "advertisements", element: <Advertisements /> },
  { path: "homepage/feed", element: <HomepageFeeds /> },
  { path: "documents/offer", children: offerDocumentRoutes },
  { path: "welcome", element: <Welcome /> },
  { path: "scrape-hero", element: <ScrapeHero /> },
];

export const router = createBrowserRouter([
  {
    path: "/",
    element: <FullLayout />,
    children: [{ path: "test", element: <Test /> }],
  },
  {
    path: "auth",
    element: <AuthLayout />,
    children: [
      { index: true, element: <Navigate to="/auth/login" replace /> },
      { path: "login", element: <Login /> },
      { path: "register", element: <Register /> },
      { path: "join/:inviteCode", element: <Join /> },
      { path: "register/vendor", element: <RegisterVendor /> },
      { path: "forgot-password", element: <ForgotPassword /> },
      { path: "reset-password", element: <ResetPassword /> },
    ],
  },
  {
    path: "listings/:id",
    element: <PublicListingLayout />,
    children: [{ index: true, element: <PublicListing /> }],
  },
  {
    path: "listings/:id",
    element: (
      <Protected auth={[AUTH.USER, AUTH.ADMIN]} key={"public_listing"}>
        <MainLayout />
      </Protected>
    ),
    children: [{ index: true, element: <PublicListing /> }],
  },
  {
    path: "/",
    element: (
      <Protected auth={[AUTH.USER, AUTH.ADMIN]} key={"app"}>
        <MainLayout />
      </Protected>
    ),
    children: [
      { index: true, element: <Dashboard /> },
      { path: "welcome", children: [{ index: true, element: <Dashboard /> }] },
      {
        path: "dashboard",
        children: [{ index: true, element: <Dashboard /> }],
      },
      { path: "profile", children: profileRoutes },
      { path: "leads", element: <Lead /> },
      { path: "clients", element: <Client /> },
      { path: "agents", children: agentRoutes },
      { path: "vendor/*", element: <Vendor /> },
      { path: "my-listings", element: <MyListings /> },
      { path: "showings", element: <Showing /> },
      { path: "showings/:id", element: <Showing /> },
      { path: "listings", children: listingRoutes },
      // { path: "listings/company", element: <Company /> },
      // { path: "listings/team", element: <Team /> },
      // { path: "listings/saved", element: <Saved /> },
      { path: "transactions", element: <Transactions /> },
      { path: "offers", element: <Offer /> },
      { path: "chat", element: <Chat /> },
    ],
  },
  {
    path: "admin",
    element: (
      <Protected auth={[AUTH.ADMIN]} key={"admin"}>
        <MainLayout />
      </Protected>
    ),
    children: adminRoutes,
  },
  {
    path: "landing",
    element:(
      <MainLandingLayout />
    ),
    children: landingRoutes
  },
  {
    path: "*",
    element: <NotFound />,
  },
]);
