import { useState, useEffect, useRef } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { PDFDocument } from 'pdf-lib';
import { useAppDispatch } from '@/redux/hooks'
import { createDocument, updateDocument } from '@/redux/document/documentSlice'
import { CreateDocumentDto, UpdateDocumentDto, IDocument } from '@/shared/interfaces/interfaces'
// import validation from '@/shared/services/validation'
import { notify } from '@/shared/services/notify'
import { fileUpload } from "@/shared/services/utils";
import Typography from '@/components/baseComponents/Typography'
import TextField from "@/components/baseComponents/TextField"
import Select from '@/components/baseComponents/Select'
import { Button } from '@/components/baseComponents/Button'
import MatchSetting from './components/MatchSetting'
// import XMark from '@/assets/icons/XMark.png'

const statusOptions = [
  { label: "Published", value: 'published' },
  { label: "Draft", value: 'draft' },
  { label: "Achieved", value: 'achieved' },
];

const CreateDocument = () => {
	const dispatch = useAppDispatch()
	const navigate = useNavigate()
	const fileRef = useRef(null)
	const location = useLocation()
	const locationState = location.state
	const selectedDocument = locationState?.document
	const isNew = selectedDocument?._id === undefined
	const defaultValue: IDocument = {
		name: selectedDocument?.name ?? '',
		file: selectedDocument?.file ?? '',
		organization: selectedDocument?.organization ?? '',
		matchJson: selectedDocument?.matchJson ?? '',
		status: selectedDocument?.status ?? 'draft',
	}
	const initialMatchSetting = JSON.parse(selectedDocument?.matchJson ?? '{}')

	const [values, setValues] = useState<IDocument>(defaultValue)
	const [matchSetting, setMatchSetting] = useState<any>(initialMatchSetting ?? {})
	const [file, setFile] = useState<any>(null)
	const [fileUrl, setFileUrl] = useState<string>(selectedDocument?.file ?? '')
	const [previewFileUrl, setPreviewFileUrl] = useState<string>('')
	const [pdfDoc, setPdfDoc] = useState<any>(null)
	const [fieldNames, setFieldNames] = useState<Array<string>>([])

	const handleInputChange = (e: any) => {
		const { name, value } = e.target
		setValues({
			...values,
			[name]: value
		})
	}

	const handleSelectBox = (name: string, selected: any) => {
		const value = selected.value
		const tmpValues = values
		if (name === 'status') {
			if (value === 'achieved') {
				tmpValues.isArchived = 1
			} else {
				tmpValues.isArchived = 0
			}
		}
		setValues({
			...tmpValues,
			[name]: value
		})
	}

	const handleFileChange = (e: any) => {
		if (e.target?.files?.length > 0) {
			setFile(e.target.files[0])
			setFileUrl(URL.createObjectURL(e.target.files[0]))
		}
	}

	const openFileDialog = () => {
		fileRef && fileRef !== null && fileRef.current && (fileRef.current as any).click()
	}

	const handleMatchSetting = (name: string, value: string) => {
		setMatchSetting({
			...matchSetting,
			[name]: value
		})
	}

	const handleSubmitDocument = async () => {
		let uploadUrl: string | undefined = ''
		if (isNew) {
			uploadUrl = await fileUpload(
				file,
				"offerDocument",
				true
			)
		} else {
			uploadUrl = fileUrl
		}

		const documentData: IDocument = {
			...values,
			matchJson: JSON.stringify(matchSetting),
			file: uploadUrl ?? ''
		}

		if (isNew) {
			const data: CreateDocumentDto = {
				data: documentData,
				search: locationState?.search
			}

			dispatch(createDocument(data)).then((res) => {
				try {
					notify(res.payload.success, res.payload.message)
				} catch (e) {
					notify(false, 'Something went wrong.')
				}
			}).finally(() => {
				navigate('/admin/documents/offer')
			})
		} else if (selectedDocument._id !== undefined && selectedDocument._id !== '') {
			const data: UpdateDocumentDto = {
				data: documentData,
				id: selectedDocument._id,
				search: locationState?.search
			}

			dispatch(updateDocument(data)).then((res) => {
				try {
						notify(res.payload.success, res.payload.message)
				} catch (e) {
						notify(false, 'Something went wrong.')
				}
			}).finally(() => {
				navigate('/admin/documents/offer')
			})
		}
	}

	const loadPdf = async (fileUrl: string) => {
		if (fileUrl !== '') {
			const formPdfBytes = await fetch(fileUrl).then((res) =>
				res.arrayBuffer(),
			);
			const pdfDoc = await PDFDocument.load(formPdfBytes);
			setPdfDoc(pdfDoc)
		}
	}

	const previewPdf = async (doc: any) => {
		if (doc !== undefined && doc !== null) {
			const form = doc.getForm()
			const fields = form.getFields()
			const fieldNames = fields.map((field: any) => {
				// const type = field.constructor.name
				const name = field.getName()
				field.setText(name)
				return name
			})
			const pdfBytes = await doc.save()
			const blob = new Blob([pdfBytes], { type: 'application/pdf' })
			const url = URL.createObjectURL(blob)
			setFieldNames(fieldNames)
			setPreviewFileUrl(url)
		}
	}

	useEffect(() => {
		loadPdf(fileUrl)
	}, [fileUrl])

	useEffect(() => {
		previewPdf(pdfDoc)
	}, [pdfDoc])

	return (
		<>
			<div className="p-10">
				<div>
					<div className='flex flex-row justify-between items-center'>
						<Typography variant='h2' color='primary' className='py-1'>Create New Offer Document</Typography>
						<Button className='bottom-4' onClick={() => handleSubmitDocument()}>
							<Typography variant='button1'>Publish</Typography>
						</Button>
					</div>
				</div>
				<div className='grid  grid-cols-1 gap-12 md:grid-cols-2 mt-12'>
					<div className='col-span-1'>
						<div>
							<Typography variant='h3' className='text-primary'>Offer Document</Typography>
							<Typography variant='caption' className='text-secondary'>Select offer document to be generated </Typography>
						</div>
						<div className='relative mt-8'>
							<Typography variant='caption' className='text-secondary mb-4'>Organization</Typography>
							<TextField className='w-full mt-2' name="organization" value={values.organization} onChange={(e) => handleInputChange(e)} />
						</div>
						<div className='relative mt-8'>
							<Typography variant='caption' className='text-secondary mb-4'>Document Name</Typography>
							<TextField className='w-full mt-2' name="name" value={values.name} onChange={(e) => handleInputChange(e)} />
						</div>
						<div className='relative mt-8'>
							<Typography variant='caption' className='text-secondary mb-4'>Status</Typography>
							<Select
								className='w-full mt-2'
								options={statusOptions}
								value={statusOptions.filter(item => item.value === values.status).length > 0 ? statusOptions.filter(item => item.value === values.status)[0] : {label: '', value: ''}}
								name='status'
								onChange={(e) => handleSelectBox('status', e)}
							/>
						</div>
						{!(fileUrl && fileUrl !== '') && (
							<div className='relative mt-8'>
								<Typography variant='caption' className='text-secondary mb-4'>Upload Offer Document</Typography>
								<div className='flex w-full mt-2' onClick={() => openFileDialog()}>
									<TextField className='w-3/4' inputClassName='rounded-r-none' disabled value={file?.name ?? ''} />
									<Button className='w-1/4 rounded-l-none' color='secondary'>
										<Typography variant='button2'>Browse</Typography>
									</Button>
								</div>
								<input ref={fileRef} type='file' accept='application/pdf' hidden name='file' onChange={e => {handleFileChange(e)}} />
							</div>
						)}
						{(fileUrl && fileUrl !== '') && (
							<MatchSetting formFields={fieldNames} matchSetting={matchSetting} handleMatchSetting={handleMatchSetting} />
						)}
					</div>
					<div className='col-span-1'>
						<div className='sticky top-10 w-full'>
							{previewFileUrl !== '' && (
								<iframe
									title="PDF Viewer"
									src={previewFileUrl + '#toolbar=0&navpanes=0'}
									width="100%"
									height="calc(100vh - 100px)"
									className="h-[calc(100vh-80px)] mb-8"
								/>
							)}
						</div>
					</div>
				</div>
      </div>
		</>
	)
}
export default CreateDocument;