import Typography from "@/components/baseComponents/Typography"
import { formatSlashDate} from '@/shared/config/constants'
import { NumericFormat } from "react-number-format"

type IProps = {
    data?:any
}

const Financing = (props: IProps) => {

    return (
      <div>
        <Typography variant="h3" color="primary">
          Mortgage
        </Typography>
        <div className="flex w-full mt-[25px]">
          <div className="w-1/3 sm:w-full">
            <Typography variant="caption" color="secondary">
              Mortgage Held By
            </Typography>
            <Typography variant="body" color="primary">
              {props.data?.mortgageHeldBy || "-"}
            </Typography>
          </div>
          <div className="w-1/3 sm:w-full">
            <Typography variant="caption" color="secondary">
              Mortgage Type
            </Typography>
            <Typography variant="body" color="primary">
              {props.data?.mortgageType || "-"}
            </Typography>
          </div>
        </div>
        <div className="flex w-full mt-[25px]">
          <div className="w-1/3 sm:w-full">
            <Typography variant="caption" color="secondary">
              Mortgage Period
            </Typography>
            <Typography variant="body" color="primary">
              {props.data?.mortgagePeriod || 0} years
            </Typography>
          </div>
          <div className="w-1/3 sm:w-full">
            <Typography variant="caption" color="secondary">
              Interest Rate
            </Typography>
            <Typography variant="body" color="primary">
              {props.data?.interestRate ? props.data?.interestRate + "%" : "-"}
            </Typography>
          </div>
        </div>
        <div className="flex w-full mt-[25px]">
          <div className="w-1/3 sm:w-full">
            <Typography variant="caption" color="secondary">
              Monthly Payment
            </Typography>
            <Typography variant="body" color="primary">
              <NumericFormat
                displayType="text"
                thousandSeparator=","
                prefix="$"
                value={props.data?.monthlyPayment || 0}
              />
            </Typography>
          </div>
          <div className="w-1/3 sm:w-full">
            <Typography variant="caption" color="secondary">
              Proposed Closing Date
            </Typography>
            <Typography variant="body" color="primary">
              {formatSlashDate(props.data?.mortgageProposedClosingDate) || "-"}
            </Typography>
          </div>
        </div>
        <div className="flex w-full mt-[25px] mb-[50px]">
          <div className="w-1/3 sm:w-full">
            <Typography variant="caption" color="secondary">
              Mortgage Due (days from formal contract)
            </Typography>
            <Typography variant="body" color="primary">
              {props.data?.mortgageDays || 0} days
            </Typography>
          </div>
          <div className="w-1/3 sm:w-full">
            <Typography variant="caption" color="secondary">
              Payment Due Date
            </Typography>
            <Typography variant="body" color="primary">
              {formatSlashDate(props.data?.paymentDate) || "-"}
            </Typography>
          </div>
        </div>

        <Typography variant="h3" color="primary">
          Loan Officer
        </Typography>
        <div className="flex w-full mt-[25px]">
          <div className="w-1/3 sm:w-full">
            <Typography variant="caption" color="secondary">
              First Name
            </Typography>
            <Typography variant="body" color="primary">
              {props.data?.loanFirstName || "-"}
            </Typography>
          </div>
          <div className="w-1/3 sm:w-full">
            <Typography variant="caption" color="secondary">
              Last Name
            </Typography>
            <Typography variant="body" color="primary">
              {props.data?.loanLastName || "-"}
            </Typography>
          </div>
          <div className="w-1/3 sm:w-full">
            <Typography variant="caption" color="secondary">
              Phone Number
            </Typography>
            <Typography variant="body" color="primary">
              {props.data?.loanPhoneNumber || "-"}
            </Typography>
          </div>
        </div>
        <div className="flex w-full mt-[25px] mb-[50px]">
          <div className="w-1/3 sm:w-full">
            <Typography variant="caption" color="secondary">
              Email
            </Typography>
            <Typography variant="body" color="primary">
              {props.data?.loanEmailAddress || "-"}
            </Typography>
          </div>
          <div className="w-1/3 sm:w-full">
            <Typography variant="caption" color="secondary">
              Company
            </Typography>
            <Typography variant="body" color="primary">
              {props.data?.loanCompany || "-"}
            </Typography>
          </div>
          <div className="w-1/3 sm:w-full">
            <Typography variant="caption" color="secondary">
              Address
            </Typography>
            <div>
              {props.data?.loanCompanyAddress && props.data?.loanCompanyAddress.city && props.data?.loanCompanyAddress.state && props.data?.loanCompanyAddress.zipcode ? (
                <>
                  <div>
                    <Typography variant="body" color="primary" className="line-clamp-2">
                      {props.data?.loanCompanyAddress?.streetLine ? props.data?.loanCompanyAddress?.streetLine + " " : ""}
                      {props.data?.loanCompanyAddress?.secondary ? props.data?.loanCompanyAddress?.secondary + " " : ""}
                    </Typography>
                  </div>
                  <div>
                    <Typography variant="body" color="primary" className="line-clamp-1">
                      {props.data?.loanCompanyAddress?.city + ", " + props.data?.loanCompanyAddress?.state + " " + props.data?.loanCompanyAddress?.zipcode}
                    </Typography>
                  </div>
                </>
              ) : "-"}
            </div>
          </div>
        </div>
      </div>
    );
}

export default Financing