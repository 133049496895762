import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import Typography from "../Typography";
import Pagination from "@/components/baseComponents/Pagination";
import { MdMoreVert } from "react-icons/md";
import { Fragment } from "react";
import { Transition, Popover } from "@headlessui/react";
import TableEmptyState from "../TableEmptyState/index.tsx";
import { MyListingStatusMenu, MyListingStatus } from "../Menu/index.tsx";
import DefaultListingImage from "@/assets/images/default_listing_image.webp";
import IconWrapper from "../IconWrapper/index.tsx";
import { format } from "date-fns";

type IProps = {
  class_name: string;
  data: Array<any>;
  fields: Array<any>;
  mobileFields?: Array<any>;
  userID?: number;
  onClickRow?: Function;
  totalPage: number;
  currentPage: number;
  recordsPerpage: number;
  onSetPage: Function;
  totalCount: number;
  editRow?: Function;
  deleteRow?: Function;
  unarchiveRow?: Function;
  rescheduleRow?: Function;
  changeFavorite?: Function;
  convertToClient?: Function;
  emptyStateProps?: {
    attachment?: any;
    attachmentCondition?: boolean;
    heading: string;
    content: string;
    buttonText?: string;
    onButtonClick?: Function;
    drawer?: any;
  };
  setStatus?: Function;
  minCellWidth: number;
  onSelectRow?: Function;
  triggerResetSelectedRows?: Date;
  isDefaultMobile?: boolean;
};

const AdvancedTable = ({
  class_name,
  data,
  fields,
  mobileFields,
  userID,
  onClickRow,
  totalPage,
  currentPage,
  recordsPerpage,
  onSetPage,
  totalCount,
  editRow,
  deleteRow,
  unarchiveRow,
  rescheduleRow,
  changeFavorite,
  emptyStateProps,
  setStatus,
  minCellWidth,
  onSelectRow,
  triggerResetSelectedRows,
  isDefaultMobile = true,
}: IProps) => {
  const checkbox = useRef();
  const [checked, setChecked] = useState(false);
  const [indeterminate, setIndeterminate] = useState(false);
  const [selectedRows, setSelectedRows] = useState<Array<any>>([]);

  const createHeaders = (fields: any) => {
    return fields.map((item: any) => ({
      ...item,
      ref: React.useRef(),
    }));
  };
  const columns = createHeaders(fields);
  const [activeIndex, setActiveIndex] = useState<any>(null);
  const tableElement = useRef<any>(null);

  const mouseMove = React.useCallback(
    (e: any) => {
      const gridColumns = columns.map((col: any, index: number) => {
        if (index === activeIndex) {
          const width = e.clientX - col.ref.current.offsetLeft;

          if (col.type === "favoriteAction" || width >= minCellWidth) {
            return `${width}px`;
          }
        }
        return `${col.ref.current.offsetWidth}px`;
      });

      tableElement.current.style.gridTemplateColumns = `${gridColumns.join(
        " "
      )}`;
    },
    [activeIndex, columns, minCellWidth]
  );

  const removeListeners = React.useCallback(() => {
    window.removeEventListener("mousemove", mouseMove);
    window.removeEventListener("mouseup", removeListeners);
  }, [mouseMove]);

  const mouseUp = React.useCallback(() => {
    setActiveIndex(null);
    removeListeners();
  }, [setActiveIndex, removeListeners]);

  const toggleAll = () => {
    setIndeterminate(false);
    setChecked(!checked && !indeterminate);
    setSelectedRows(checked || indeterminate ? [] : data);
    onSelectRow && onSelectRow(checked || indeterminate ? [] : data);
  };

  const clickAction = (row_data: any, action_name: string) => {
    if (action_name == "Edit") {
      if (editRow) {
        editRow(row_data);
      }
    }

    if (action_name == "Archive") {
      if (deleteRow) {
        deleteRow(row_data);
      }
    }

    if (action_name === "Unarchive") {
      if (unarchiveRow) {
        unarchiveRow(row_data);
      }
    }

    if (action_name == "Reschedule") {
      if (rescheduleRow) {
        rescheduleRow(row_data);
      }
    }
  };

  const onChangeFavoriteStatus = (agentId: string, action_name: string) => {
    if (changeFavorite && agentId !== "" && action_name !== "") {
      changeFavorite(agentId, action_name);
    }
  };

  const checkedPerson = (event: any, selectedRow: any) => {
    const temp = event.target.checked
      ? [...selectedRows, selectedRow]
      : selectedRows.filter((item: any) => item !== selectedRow);

    setSelectedRows(temp);
    onSelectRow && onSelectRow(temp);
  };

  useLayoutEffect(() => {
    if (checkbox.current !== undefined) {
      const isIndeterminate =
        selectedRows.length > 0 && selectedRows.length < data.length;
      setChecked(
        selectedRows.length !== 0 &&
          data.length !== 0 &&
          selectedRows.length === data.length
      );
      setIndeterminate(isIndeterminate);
      (checkbox.current as any).indeterminate = isIndeterminate;
    }
  }, [selectedRows]);

  React.useEffect(() => {
    if (activeIndex !== null) {
      window.addEventListener("mousemove", mouseMove);
      window.addEventListener("mouseup", mouseUp);
    }

    return () => {
      removeListeners();
    };
  }, [activeIndex, mouseMove, mouseUp, removeListeners]);

  const getShowingStatusColor = (status: string | undefined) => {
    return status == "Pending"
      ? "border-pending text-pending"
      : status === "Denied"
      ? "border-denied text-denied"
      : status === "Confirmed"
      ? "border-confirmed text-confirmed"
      : "";
  };

  useEffect(() => {
    setSelectedRows([]);
    setIndeterminate(false);
  }, [triggerResetSelectedRows])

  const defaultTableElement = () => {
    return (
      <>
        <thead>
          <tr className="[&>th]:border-b-1 [&>th]:border-grey ">
            {columns.map((field_item: any, field_index: number) => {
              return (
                <th
                  ref={field_item.ref}
                  scope="col"
                  className={
                    field_item.class_name + " relative px-[16px] py-[20px]"
                  }
                  key={field_index}
                >
                  {field_item.type == "checkbox" ? (
                    <>
                      <input
                        type="checkbox"
                        className="h-4 w-4 ml-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                        ref={checkbox as any}
                        checked={checked}
                        onChange={toggleAll}
                      />
                    </>
                  ) : (
                    <Typography variant="table-headers" className="truncate">
                      {field_item.name}
                    </Typography>
                  )}
                </th>
              );
            })}
          </tr>
        </thead>

        <tbody className="bg-white ">
          {data.map((datarow, index) => {
            return (
              <tr
                key={index}
                className={`${onClickRow ? `cursor-pointer` : ``} group [&>td]:border-b-1 [&>td]:border-grey`}
              >
                {fields.map((field_item: any, field_index) => {
                  return (
                    <td
                      className={
                        field_item.slug +
                        " h-full group-hover:bg-gray-200 items-center relative " +
                        (field_item.type === "action" ? " justify-end" : "") +
                        (class_name.includes("custom-table")
                          ? field_item.class_name
                          : "")
                      }
                      key={field_index}
                      onClick={() =>
                        field_item.type !== "checkbox" &&
                        field_item.type !== "action" &&
                        field_item.type !== "status_menu" &&
                        field_item.type !== "favoriteAction" &&
                        field_item.type !== "menu" &&
                        onClickRow && onClickRow(datarow)
                      }
                    >
                      {field_item.type == "checkbox" && (
                        <>
                          {selectedRows.includes(datarow) && (
                            <div className="absolute inset-y-0 left-0 w-0.5 bg-indigo-600" />
                          )}
                          <input
                            type="checkbox"
                            className="h-4 w-4 ml-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                            value={datarow[field_item.slug]}
                            checked={selectedRows.includes(datarow)}
                            onChange={(e) => checkedPerson(e, datarow)}
                          />
                        </>
                      )}

                      {field_item.type == "address_image" ? (
                        <>
                          <div className="flex items-center gap-4 w-fit">
                            <img
                              src={datarow[field_item.slug]}
                              onError={(e) =>
                                (e.currentTarget.src = DefaultListingImage)
                              }
                              className={`rounded-md aspect-square object-cover bg-[#f4f5f6] ${
                                field_item.imageClass !== undefined
                                  ? field_item.imageClass
                                  : ``
                              }`}
                            />
                            {field_item.type == "address_image" && (
                              <Typography
                                variant="table-row-content"
                                color="primary"
                                className="whitespace-nowrap "
                              >
                                {datarow["address"].props.children}
                              </Typography>
                            )}
                          </div>
                        </>
                      ) : (
                        field_item.type == "image" && (
                          <img
                            src={datarow[field_item.slug]}
                            onError={(e) =>
                              (e.currentTarget.src = DefaultListingImage)
                            }
                            className={`rounded-md aspect-square object-cover bg-[#f4f5f6] ${
                              field_item.imageClass !== undefined
                                ? field_item.imageClass
                                : ``
                            }`}
                          />
                        )
                      )}

                      {field_item.type == "status_menu" && setStatus && (
                        <MyListingStatusMenu
                          datarow={datarow}
                          item={field_item}
                          setStatus={setStatus}
                        />
                      )}
                      {field_item.type == "status_show" && (
                        <MyListingStatus datarow={datarow} item={field_item} />
                      )}

                      {field_item.type == "icon" && (
                        <>{datarow[field_item.slug]}</>
                      )}

                      {field_item.type == "custom_image" && (
                        <img
                          src={datarow[field_item.slug]}
                          className={"rounded-md " + field_item.image_size}
                        />
                      )}

                      {field_item.type == "text" && (
                        <>
                          <Typography
                            variant="table-row-content"
                            color="primary"
                            className={`${field_item?.text_class_name} w-max `}
                          >
                            {datarow[field_item.slug]}
                          </Typography>
                        </>
                      )}
                      {field_item.type == "showing" && (
                        <Typography
                          variant="table-row-content"
                          color="primary"
                          className={`${field_item?.text_class_name} w-max `}
                        >
                          {datarow.showings?.props?.children?.props
                            ?.children[1] ?? index}
                          {datarow.showings?.props?.children?.props
                            ?.children[2] ?? "Showings"}
                        </Typography>
                      )}

                      {field_item.type == "menu" && datarow[field_item.slug]}

                      {field_item.type === "favoriteAction" && (
                        <>
                          <div className="text-right flex justify-end items-center">
                            <Popover as="div" className="relative">
                              <Popover.Button className="flex items-center justify-center ">
                                <img
                                  src={datarow.iconStar}
                                  className="w-[14px] h-[14px] max-w-none"
                                />
                              </Popover.Button>
                              <Transition
                                as={Fragment}
                                enter="transition ease-out duration-100"
                                enterFrom="transform opacity-0 scale-95"
                                enterTo="transform opacity-100 scale-100"
                                leave="transition ease-in duration-75"
                                leaveFrom="transform opacity-100 scale-100"
                                leaveTo="transform opacity-0 scale-95"
                              >
                                <Popover.Panel className="z-20 absolute left-[1rem] top-0 w-[8rem] origin-top-left divide-y divide-gray-100 rounded-md bg-white shadow-lg">
                                  {({ close }) => (
                                    <>
                                      {datarow[field_item.slug]?.map(
                                        (
                                          action_item: any,
                                          action_item_index: any
                                        ) => {
                                          return (
                                            <div
                                              key={action_item_index}
                                              className="cursor-pointer flex items-center hover:bg-neutral-100 first:rounded-t-md first:rounded-b-md px-3 py-1"
                                              onClick={() => {
                                                close();
                                                onChangeFavoriteStatus(
                                                  datarow.id,
                                                  action_item.name
                                                );
                                              }}
                                            >
                                              <div className="[&>img]:w-[14px] [&>img]:h-[14px]">
                                                {action_item.image}
                                              </div>
                                              <Typography
                                                className={`mr-2 ml-2 text-[${action_item.color}]`}
                                                variant="table-row-content"
                                                color="primary"
                                              >
                                                {action_item.name}
                                              </Typography>
                                            </div>
                                          );
                                        }
                                      )}
                                    </>
                                  )}
                                </Popover.Panel>
                              </Transition>
                            </Popover>
                          </div>
                        </>
                      )}

                      {field_item.type == "action" && (
                        <>
                          <div className="text-right flex justify-center px-[16px] items-center">
                            <Popover as="div" className={`relative`}>
                              <Popover.Button>
                                {({ open }) => (
                                  <>
                                    <div
                                      className={`relative grid place-items-center p-1 rounded-[8px] ${
                                        open === true ? "bg-gray-100" : ""
                                      }`}
                                    >
                                      <MdMoreVert
                                        className={`${
                                          datarow[field_item.slug]?.filter(
                                            (item: any) =>
                                              item.hidden === false ||
                                              item.hidden === undefined
                                          ).length === 0
                                            ? `text-secondary`
                                            : ``
                                        }`}
                                      />
                                    </div>
                                  </>
                                )}
                              </Popover.Button>

                              <Transition
                                as={Fragment}
                                enter="transition ease-out duration-100"
                                enterFrom="transform opacity-0 scale-95"
                                enterTo="transform opacity-100 scale-100"
                                leave="transition ease-in duration-75"
                                leaveFrom="transform opacity-100 scale-100"
                                leaveTo="transform opacity-0 scale-95"
                              >
                                <Popover.Panel className="z-20 absolute right-[1rem] top-full w-[8rem] origin-top-right divide-y divide-gray-100 rounded-md overflow-hidden bg-white shadow-lg">
                                  {datarow[field_item.slug]
                                    ?.filter((item: any) => !item.hidden)
                                    .map(
                                      (
                                        action_item: any,
                                        action_item_index: any
                                      ) => {
                                        return (
                                          <div
                                            key={action_item_index}
                                            className="cursor-pointer flex items-center hover:bg-neutral-100 p-[12px] gap-[12px]"
                                            onClick={() => {
                                              clickAction(
                                                datarow,
                                                action_item.name
                                              );
                                            }}
                                          >
                                            {action_item.icon}
                                            <Typography
                                              className={`mr-2 ${
                                                action_item.name ===
                                                  "Archive" && "text-red-600"
                                              }`}
                                              variant="table-row-content"
                                            >
                                              {action_item.name}
                                            </Typography>
                                          </div>
                                        );
                                      }
                                    )}
                                </Popover.Panel>
                              </Transition>
                            </Popover>
                          </div>
                        </>
                      )}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </>
    );
  };

  return (
    <>
      <div className="flex flex-col justify-between min-w-full min-h-[calc(100%-230px)] md:bg-white rounded-[8px] pb-0 md:mt-[12px] shadow">
        {data && data.length > 0 ? (
          <>
            <div className="remove-padding !pr-0 !m-0 md:flow-root md:border-0 w-full  h-full">
              {/* table */}
              <div className="w-full min-h-[calc(100vh-300px)]">
                {/* Desktop responsive starts here SHOWINGSTEST */}

                <div className="hidden md:inline-block w-full align-middle ">
                  <table
                    ref={tableElement}
                    className={"advanced-table " + class_name}
                  >
                    {defaultTableElement()}
                  </table>
                </div>

                {/* Mobile responsive starts here */}
                <div className="w-full block md:hidden ">
                  {isDefaultMobile && (
                    <table className="w-full">
                      <tbody className="flex flex-col gap-3">
                        {data.map((datarow, index) => {
                          return (
                            <tr
                              key={index}
                              className={`${onClickRow ? `cursor-pointer`: ``} group bg-white rounded-[8px] shadow-sm`}
                              onClick={() => {
                                onClickRow && onClickRow(datarow);
                              }}
                            >
                              {mobileFields?.map(
                                (field_item: any, field_index) => (
                                  <td key={field_index}>
                                    {/* this section is responsive code for showings tab */}
                                    {field_item.type == "showing_image" && (
                                      <>
                                        <div className="px-[16px] py-[20px] w-full space-y-[12px]">
                                          <div className="flex items-center gap-4 w-fit">
                                            <img
                                              src={
                                                datarow[
                                                  field_item.slug.imageSlug
                                                ]
                                              }
                                              onError={(e) =>
                                                (e.currentTarget.src =
                                                  DefaultListingImage)
                                              }
                                              className={`rounded-md aspect-square object-cover bg-[#f4f5f6] ${
                                                field_item.imageClass !==
                                                undefined
                                                  ? field_item.imageClass
                                                  : ``
                                              }`}
                                            />
                                            <div className="block">
                                              <Typography
                                                variant="table-row-content"
                                                color="primary"
                                                className="whitespace-nowrap "
                                              >
                                                {
                                                  datarow["address"].props
                                                    .children[0]
                                                }
                                              </Typography>
                                              <Typography
                                                variant="table-row-content"
                                                color="secondary"
                                                className="whitespace-nowrap "
                                              >
                                                {
                                                  datarow["address"].props
                                                    .children[1]
                                                }
                                              </Typography>
                                            </div>
                                          </div>

                                          <div className="flex flex-wrap gap-[4px] items-center">
                                            <span
                                              className={`${
                                                datarow.viewed
                                                  ? ``
                                                  : `font-semibold`
                                              } flex items-center border border-border rounded-full w-fit px-[8px]`}
                                            >
                                              {datarow.owner?._id == userID ? (
                                                <>
                                                  <IconWrapper
                                                    name="sent"
                                                    width={16}
                                                  />
                                                  <Typography variant="medium-text">
                                                    <span className="leading-[100%]">
                                                      Sent
                                                    </span>
                                                  </Typography>
                                                </>
                                              ) : (
                                                <>
                                                  <IconWrapper
                                                    name="recieved"
                                                    width={16}
                                                  />
                                                  <Typography variant="medium-text">
                                                    <span>Received</span>
                                                  </Typography>
                                                </>
                                              )}
                                            </span>
                                            <span
                                              className={`${
                                                datarow.viewed
                                                  ? ``
                                                  : `!font-semibold`
                                              } ${getShowingStatusColor(
                                                datarow.status
                                              )} px-[8px] rounded-full py-[4px]`}
                                            >
                                              <Typography variant="medium-text">
                                                {datarow.status}
                                              </Typography>
                                            </span>

                                            <span>
                                              <div className="flex items-center border border-border px-[4px] pr-[6px] py-[4px] gap-[4px] rounded-full">
                                                <img
                                                  className="w-[16px] aspect-square bg-slate-500 rounded-full"
                                                  src={
                                                    datarow?.listing?.owner
                                                      ?.agent.avatarURL
                                                  }
                                                  alt=""
                                                />
                                                <span
                                                  className={`${
                                                    datarow.viewed
                                                      ? ``
                                                      : `!font-semibold`
                                                  }`}
                                                >
                                                  <Typography variant="medium-text">
                                                    {datarow.listing?.owner
                                                      ?.agent?.firstName +
                                                      " " +
                                                      datarow.listing?.owner
                                                        ?.agent?.lastName}
                                                  </Typography>
                                                </span>
                                              </div>
                                            </span>
                                          </div>

                                          <div className="flex justify-between pt-[12px] border-t border-t-border">
                                            <div className="flex flex-col">
                                              <span
                                                className={`${
                                                  datarow.viewed
                                                    ? ``
                                                    : `!font-semibold`
                                                }`}
                                              >
                                                <Typography
                                                  variant="body"
                                                  className="flex flex-col even:[&>span]:opacity-50"
                                                >
                                                  {datarow?.client.props
                                                    .children || ""}
                                                </Typography>
                                              </span>
                                            </div>
                                            <div>
                                              <div
                                                className={`${
                                                  datarow.viewed
                                                    ? ``
                                                    : `font-semibold`
                                                } flex flex-col`}
                                              >
                                                <div>
                                                  <Typography variant="body">
                                                    {format(
                                                      new Date(
                                                        datarow.dateTime
                                                      ),
                                                      "EEEE"
                                                    )}
                                                    ,{" "}
                                                    {format(
                                                      new Date(
                                                        datarow.dateTime
                                                      ),
                                                      "MM/dd/yy"
                                                    )}
                                                  </Typography>
                                                </div>
                                                <div>
                                                  <Typography
                                                    variant="body"
                                                    color="secondary"
                                                  >
                                                    at{" "}
                                                    {datarow.dateTime
                                                      ? format(
                                                          new Date(
                                                            datarow.dateTime
                                                          ),
                                                          "p"
                                                        )
                                                      : ""}
                                                  </Typography>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </>
                                    )}

                                    {/* this section is responsive code for my-listings tab */}

                                    {field_item.type == "listing_image" && (
                                      <>
                                        <div className="px-[16px] py-[20px] w-full space-y-[12px] divide-y-1">
                                          <div className="flex items-center justify-between">
                                            {" "}
                                            <div className="flex items-center gap-2 w-full ">
                                              <img
                                                src={
                                                  datarow[
                                                    field_item.slug.imageSlug
                                                  ]
                                                }
                                                onError={(e) =>
                                                  (e.currentTarget.src =
                                                    DefaultListingImage)
                                                }
                                                className={`rounded-md aspect-square object-cover bg-[#f4f5f6] ${
                                                  field_item.imageClass !==
                                                  undefined
                                                    ? field_item.imageClass
                                                    : ``
                                                }`}
                                              />
                                              <div className=" w-full flex items-center justify-between flex-wrap gap-0">
                                                <Typography
                                                  variant="body"
                                                  color="primary"
                                                  className="whitespace-nowrap"
                                                >
                                                  {datarow["address"]}
                                                </Typography>
                                                {setStatus && (
                                                  <MyListingStatusMenu
                                                    datarow={datarow}
                                                    item={field_item.slug}
                                                    setStatus={setStatus}
                                                  />
                                                )}
                                              </div>
                                            </div>
                                          </div>
                                          <div className="flex justify-between items-center pt-[12px]">
                                            <div>
                                              <Typography variant="body">
                                                {
                                                  datarow[
                                                    field_item.slug.clientSlug
                                                  ]
                                                }
                                              </Typography>
                                            </div>
                                            <div>
                                              <Typography variant="body">
                                                {
                                                  datarow[
                                                    field_item.slug.showingSlug
                                                  ]
                                                }
                                              </Typography>
                                            </div>
                                          </div>
                                        </div>
                                      </>
                                    )}
                                  </td>
                                )
                              )}
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  )}
                  {!isDefaultMobile && (
                    <div className="overflow-x-auto">
                      <div className="overflow-x-scroll">
                        <table className="min-w-full w-full">
                          {defaultTableElement()}
                        </table>
                      </div>
                    </div>
                  )}
                </div>
              </div>

              {/* pagination */}
              <div className="w-full relative ">
                <nav className="bg-white px-4 max-sm:my-4 rounded-[8px] shadow-sm flex items-center justify-between w-full  py-5 z-0">
                  <Typography variant="medium-text" color="secondary">
                    Showing{" "}
                    {totalCount >= currentPage * recordsPerpage
                      ? currentPage * recordsPerpage
                      : totalCount}{" "}
                    of {totalCount} results
                  </Typography>
                  <Pagination
                    totalPage={totalPage}
                    currentPage={currentPage}
                    onSetPage={onSetPage}
                    loadingData={false}
                  />
                </nav>
              </div>
            </div>
          </>
        ) : (
          emptyStateProps &&
          (emptyStateProps.attachment &&
          !emptyStateProps.attachmentCondition ? (
            emptyStateProps.attachment
          ) : (
            <>
              {emptyStateProps.drawer}
              <TableEmptyState
                heading={emptyStateProps.heading}
                content={emptyStateProps.content}
                buttonText={emptyStateProps.buttonText}
                onButtonClick={emptyStateProps.onButtonClick}
              />
            </>
          ))
        )}
      </div>
    </>
  );
};

export default AdvancedTable;
