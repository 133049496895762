type Props = {
  width: number;
  height: number;
  stroke?: string;
  className?: string;
};

const IconGraph = (props: Props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 16 16"
      fill="none"
      {...props}
    >
      <path
        d="M3.33331 2V12.6667H14"
        stroke="#4B5563"
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.33331 12.6667L7.33331 8.66669"
        stroke="#4B5563"
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1.33331 4L3.33331 2L5.33331 4"
        stroke="#4B5563"
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12 10.6667L14 12.6667L12 14.6667"
        stroke="#4B5563"
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default IconGraph;
