// React imports
import React from "react";
import ReactDOM from "react-dom/client";

// Router imports
import { RouterProvider } from "react-router-dom";

// Redux imports
import { Provider } from "react-redux";
import { persistStore } from "redux-persist";
import { PersistGate } from "redux-persist/integration/react";
import { store } from "@/redux/store";

// Cookie imports
import { CookiesProvider } from "react-cookie";

// Router imports
import { router } from "@/pages/Router";

// Bugsnag imports
import Bugsnag from "@bugsnag/js";
import BugsnagPluginReact from "@bugsnag/plugin-react";

// Toast imports
import { ToastContainer } from "react-toastify";

// Styles imports
import "@/assets/scss/index.scss";
import "react-toastify/dist/ReactToastify.css";
import "react-datepicker/dist/react-datepicker.css";

// Bugsnag setup
Bugsnag.start({
  apiKey: import.meta.env.VITE_BUGSANG_API_KEY,
  plugins: [new BugsnagPluginReact()],
});

// Error boundary setup
// eslint-disable-next-line react-refresh/only-export-components, @typescript-eslint/no-explicit-any
const ErrorBoundary = (Bugsnag as any)
  .getPlugin("react")
  .createErrorBoundary(React);

// Persistor setup
const persistor = persistStore(store);

// Render application
ReactDOM.createRoot(document.getElementById("root")!).render(
  <ErrorBoundary>
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <CookiesProvider>
          <ToastContainer
            autoClose={3000}
            toastClassName={"shadow-lg"}
            progressClassName={`bg-indigo-600`}
          />
          <RouterProvider router={router} />
        </CookiesProvider>
      </PersistGate>
    </Provider>
  </ErrorBoundary>
);
