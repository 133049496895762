import AddressAutoComplete from "@/components/baseComponents/AddressAutoComplete"
import TextField from "@/components/baseComponents/TextField"
import Typography from "@/components/baseComponents/Typography"

type IProps = {
    addresses: any[]
}

const Attorney = ({
    addresses
}: IProps) => {

    return (
        <div>
            <div className="mt-[50px]">
            <Typography variant="h3" color="primary">Buyer’s Attorney</Typography>
                <div className="grid grid-cols-2 gap-[15px] mt-[25px]">
                    <div className="col-span-2 sm:col-span-1">
                        <Typography variant="caption" color="secondary">First Name</Typography>
                        <TextField name="buyerAttorneyFirstName" className={`w-full`} />
                    </div>
                    <div className="col-span-2 sm:col-span-1">
                        <Typography variant="caption" color="secondary">Last Name</Typography>
                        <TextField className={`w-full`} name="buyerAttorneyLastName" />
                    </div>
                </div>
                <div className="mt-[25px]">
                    <Typography variant="caption" color="secondary">Company Name</Typography>
                    <TextField name="buyerAttorneyCompanyName" />
                </div>
                <div className="grid grid-cols-2 gap-[15px] mt-[25px]">
                    <div className="col-span-2 sm:col-span-1 relative">
                        <Typography variant="caption" color="secondary">Phone Number</Typography>
                        <TextField className={`w-full`} maxLength={12} name="buyerAttorneyPhoneNumber" />
                    </div>
                    <div className="col-span-2 sm:col-span-1 relative">
                        <Typography variant="caption" color="secondary">Email Address</Typography>
                        <TextField className={`w-full`} name="buyerAttorneyEmailAddress" />
                    </div>
                </div>
                <div className="mt-[25px]">
                    <Typography variant="caption" color="secondary">Address</Typography>
                    <AddressAutoComplete options={addresses} placeholder="Enter Other Agents's Address" value={""} onChange={(value) => console.log(value)} onAllChange={(value) => console.log(value)} />
                </div>
            </div>
            <div className="mt-[50px]">
            <Typography variant="h3" color="primary">Seller’s Attorney</Typography>
                <div className="grid grid-cols-2 gap-[15px] mt-[25px]">
                    <div className="col-span-2 sm:col-span-1">
                        <Typography variant="caption" color="secondary">First Name</Typography>
                        <TextField name="sellerFirstName" className={`w-full`} />
                    </div>
                    <div className="col-span-2 sm:col-span-1">
                        <Typography variant="caption" color="secondary">Last Name</Typography>
                        <TextField className={`w-full`} name="sellerLastName" />
                    </div>
                </div>
                <div className="mt-[25px]">
                    <Typography variant="caption" color="secondary">Company Name</Typography>
                    <TextField name="sellerCompanyName" />
                </div>
                <div className="grid grid-cols-2 gap-[15px] mt-[25px]">
                    <div className="col-span-2 sm:col-span-1 relative">
                        <Typography variant="caption" color="secondary">Phone Number</Typography>
                        <TextField className={`w-full`} maxLength={12} name="sellerPhoneNumber" />
                    </div>
                    <div className="col-span-2 sm:col-span-1 relative">
                        <Typography variant="caption" color="secondary">Email Address</Typography>
                        <TextField className={`w-full`} name="sellerEmailAddress" />
                    </div>
                </div>
                <div className="mt-[25px]">
                    <Typography variant="caption" color="secondary">Address</Typography>
                    <AddressAutoComplete options={addresses} placeholder="Enter Other Agents's Address" value={""} onChange={(value) => console.log(value)} onAllChange={(value) => console.log(value)} />
                </div>
            </div>
        </div>
    )
}

export default Attorney