import { Fragment } from "react";
import { Transition, Popover } from "@headlessui/react";
import { CgAdd, CgRemove } from "react-icons/cg";
import MultiRangeSlider from "./MultiRangeSlider";
import RangeImg from "../../../assets/images/Range.png";
import Typography from "@/components/baseComponents/Typography";
import { Button } from "@/components/baseComponents/Button";
import IconWrapper from "@/components/baseComponents/IconWrapper";

// interface IProps {
//   keyword: string;
//   changeKeyword: (value: string) => void;
//   filterLeads: () => void;
// }
interface Props{
  bedrooms:number;
  setBedrooms: React.Dispatch<React.SetStateAction<number>>;
  bathrooms:number;
  setBathrooms: React.Dispatch<React.SetStateAction<number>>;
  type:string;
  setType: React.Dispatch<React.SetStateAction<string>>;
  setPrice: React.Dispatch<React.SetStateAction<number>>;
  setMinPrice: React.Dispatch<React.SetStateAction<number>>;
  onReset: Function;
  onApply: Function;
}
const LandingFilter = ({
  bedrooms, 
  setBedrooms,
  bathrooms, 
  setBathrooms,
  type, 
  setType,
  setPrice,
  setMinPrice,
  onReset,
  onApply,
}:Props) => {
  
  return (
    <div className="relative md:w-32 w-full text-right flex items-center">
      <Popover
        as="div"
        className=" w-full relative inline-block text-left  xl:px-0 px-4"
      >
        {() => (
          <>
            <div>
              <Popover.Button
                as="div"
                className="flex md:w-32 w-full items-center justify-center gap-2 py-[10px]"
              >
                <Button
                  color="inherit"
                  className="w-full border-1 !h-full border-[#D4D4D8]  py-3 flex md:justify-center justify-between items-center font-jakarta font-bold text-[16px]"
                  onClick={() => {}}
                >
                  Filter &nbsp;&nbsp;
                  <IconWrapper name="down-circular" width={24} height={24} />
                </Button>
              </Popover.Button>
            </div>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <Popover.Panel className="absolute right-auto md:right-20 mt-1 w-[278px] origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none z-50">
                {({ close }) => (
                  <div className="flex flex-col rounded-2xl min-w-[375px] bg-white">
                    <div className="flex pt-8 pb-4 px-6 border-b-1 border-gray-400 w-full">
                      <div
                        className="flex cursor-pointer "
                        onClick={() => {
                          close();
                        }}
                      >
                        <IconWrapper name="close" width={24} height={24} />
                      </div>
                      <div className="flex w-full justify-center">
                        <Typography
                          variant="h1"
                          color="black"
                          className="whitespace-nowrap text-[18px] font-bold font-jakarta"
                        >
                          Filters
                        </Typography>
                      </div>
                    </div>
                    <div className="flex flex-col w-full py-7 px-6">
                      <Typography
                        variant="h1"
                        color="black"
                        className="whitespace-nowrap text-[16px] font-bold font-jakarta"
                      >
                        Category
                      </Typography>
                      <div className="flex w-full mt-3 gap-3">
                        <Button
                          color="inherit"
                          className={` border-1 !h-full  border-[#D4D4D8] mb-4  py-3 flex justify-center items-center font-jakarta font-bold text-[14px]
                          ${
                            type == "Houses"
                              ? "bg-[#7065F0] text-white"
                              : ""
                          }
                          `}
                          onClick={() => {
                            setType("Houses");
                          }}
                        >
                          Houses
                        </Button>
                        <Button
                          color="inherit"
                          className={` border-1 !h-full  border-[#D4D4D8] mb-4  py-3 flex justify-center items-center font-jakarta font-bold text-[14px]
                          ${
                            type == "Condos"
                              ? "bg-[#7065F0] text-white"
                              : ""
                          }
                          `}
                          onClick={() => {
                            setType("Condos");
                          }}
                        >
                          Condos
                        </Button>
                        <Button
                          color="inherit"
                          className={`
                          ${
                            type == "Land"
                              ? "bg-[#7065F0] text-white"
                              : ""
                          }
                          border-1 !h-full border-[#D4D4D8] mb-4  py-3 flex justify-center items-center font-jakarta font-bold text-[14px]`}
                          onClick={() => {
                            setType("Land");
                          }}
                        >
                          Land
                        </Button>
                      </div>
                      <div className="flex my-6 h-[1px] w-full bg-gray-400"></div>
                      <div className="flex flex-col gap-3 w-full">
                        <Typography
                          variant="h1"
                          color="black"
                          className="whitespace-nowrap text-[16px] font-bold font-jakarta"
                        >
                          Price Range
                        </Typography>
                        <div className="flex flex-col w-full">
                          <div className="flex w-full justify-center items-center">
                            <img src={RangeImg} className="w-[80%]" />
                          </div>
                          <MultiRangeSlider
                            min={0}
                            max={10000000}
                            onChange={(values) => {
                              setPrice(values.max);
                              setMinPrice(values.min);
                            }}
                          />
                        </div>
                      </div>
                      <div className="flex my-6 h-[1px] w-full bg-gray-400"></div>
                      <div className="flex flex-col w-full gap-4 ">
                        <Typography
                          variant="h1"
                          color="black"
                          className="whitespace-nowrap text-[16px] font-bold font-jakarta"
                        >
                          Category
                        </Typography>
                        <div className="flex flex-col w-full gap-6">
                          <div className="flex w-full justify-between items-center">
                            <Typography
                              variant="h1"
                              color="black"
                              className="whitespace-nowrap text-[16px] font-medium font-jakarta"
                            >
                              Bedroom
                            </Typography>
                            <div className="flex gap-2.5 items-center">
                              <CgRemove
                                color="#E5E7EB"
                                size={32}
                                onClick={() => {
                                  if (bedrooms != 0) {
                                    setBedrooms(bedrooms - 1);
                                  }
                                }}
                              />
                              <Typography
                                variant="h1"
                                color="black"
                                className="whitespace-nowrap text-[16px] font-bold font-jakarta"
                              >
                                {bedrooms}
                              </Typography>
                              <CgAdd
                                size={32}
                                color="#7065F0"
                                onClick={() => {
                                  setBedrooms(bedrooms + 1);
                                }}
                              />
                            </div>
                          </div>
                          <div className="flex w-full justify-between items-center">
                            <Typography
                              variant="h1"
                              color="black"
                              className="whitespace-nowrap text-[16px] font-medium font-jakarta"
                            >
                              Bathroom
                            </Typography>
                            <div className="flex gap-2.5 items-center">
                              <CgRemove
                                color="#E5E7EB"
                                size={32}
                                onClick={() => {
                                  if (bathrooms != 0) {
                                    setBathrooms(bathrooms - 1);
                                  }
                                }}
                              />
                              <Typography
                                variant="h1"
                                color="black"
                                className="whitespace-nowrap text-[16px] font-bold font-jakarta"
                              >
                                {bathrooms}
                              </Typography>
                              <CgAdd
                                size={32}
                                color="#7065F0"
                                onClick={() => {
                                  setBathrooms(bathrooms + 1);
                                }}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="flex my-6 h-[1px] w-full bg-gray-400"></div>
                        <div className="flex w-full mt-[18px] gap-4">
                          <Button
                            className={`py-3 !h-full text-[#7065F0] w-full bg-[#F7F7FD] font-jakarta font-bold text-[16px]`}
                            variant="text"
                            onClick={() => {onReset(); close();}}
                          >
                            Reset
                          </Button>
                          <Button
                            className={`py-3 !h-full text-white w-full bg-[#7065F0] font-jakarta font-bold text-[16px]`}
                            variant="text"
                            onClick={() => {onApply(); close();}}
                          >
                            Apply
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>
                  //   <div className="px-0 py-0 w-full">
                  //     <div className="py-[15px] px-5 flex items-center cursor-pointer w-full">
                  //       <Typography variant="body" color="secondary">
                  //         Filter
                  //       </Typography>
                  //     </div>
                  //     <div className="px-5">
                  //       <TextField
                  //         placeholder="Enter filter term"
                  //         value={props.keyword}
                  //         onChange={(e) => props.changeKeyword(e.target.value)}
                  //       />
                  //     </div>
                  //     <div className="w-full flex justify-center py-[15px] pb-7">
                  //       <Button
                  //         className="w-[146px] text-15"
                  //         onClick={() => {
                  //           props.filterLeads();
                  //           close();
                  //         }}
                  //       >
                  //         Filter
                  //       </Button>
                  //     </div>
                  //   </div>
                )}
              </Popover.Panel>
            </Transition>
          </>
        )}
      </Popover>
    </div>
  );
};

export default LandingFilter;
