type Props = {
  width: number;
  height: number;
  stroke?: string;
  className?: string;
};

const IconFile = (props: Props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        d="M17.8666 9.21001L10.2082 16.8683C9.27005 17.8065 7.99757 18.3336 6.67075 18.3336C5.34393 18.3336 4.07145 17.8065 3.13325 16.8683C2.19505 15.9301 1.66797 14.6577 1.66797 13.3308C1.66797 12.004 2.19505 10.7315 3.13325 9.79334L10.2749 2.65168C10.9004 2.0251 11.7491 1.67266 12.6345 1.67188C13.5198 1.67109 14.3692 2.02204 14.9957 2.64751C15.6223 3.27298 15.9748 4.12174 15.9755 5.00706C15.9763 5.89239 15.6254 6.74177 14.9999 7.36834L7.84158 14.51C7.52885 14.8227 7.10469 14.9984 6.66242 14.9984C6.22014 14.9984 5.79598 14.8227 5.48325 14.51C5.17051 14.1973 4.99482 13.7731 4.99482 13.3308C4.99482 12.8886 5.17051 12.4644 5.48325 12.1517L12.5582 5.08501"
        stroke="#B9C0D4"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default IconFile;
