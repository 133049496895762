export const getFormattedDateString = (dateStr: string | undefined) => {
  if (!dateStr || dateStr === null || dateStr === "") return "";
  const date = new Date(dateStr);
  if (!isNaN(date.getTime())) {
    let options = {
      month: "short",
      day: "numeric",
      hour: "2-digit",
      minute: "2-digit",
    } as Intl.DateTimeFormatOptions; // month: 'short', day: 'numeric',
    let formattedDate = date.toLocaleString("en-US", options);
    return formattedDate;
  } else {
    return "";
  }
};
