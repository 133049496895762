type Props = {
  width: number;
  height: number;
  stroke?: string;
  className?: string;
};

const IconMinus = (props: Props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 12 13"
      fill="none"
      {...props}
    >
      <path
        d="M1 6.5H11"
        stroke="#6B7280"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default IconMinus;
