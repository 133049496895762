type Props = {
  width: number;
  height: number;
  stroke?: string;
  className?: string;
};

const IconSort = (props: Props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" fill="none" {...props}>
      <path d="M2 10.666L4.66667 13.3327L7.33333 10.666" stroke="#6B7280" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M4.66699 13.3327V2.66602" stroke="#6B7280" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M14.0003 5.33268L11.3337 2.66602L8.66699 5.33268" stroke="#6B7280" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M11.333 2.66602V13.3327" stroke="#6B7280" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};

export default IconSort;
