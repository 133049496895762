// Import necessary libraries and reducers
import { configureStore } from "@reduxjs/toolkit";
import {
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from "redux-persist";
import storage from "redux-persist/lib/storage";
import { combineReducers } from "redux";

import userReducer from "@/redux/user/userSlice";
import leadReducer from "@/redux/lead/leadSlice";
import clientReducer from "@/redux/client/clientSlice";
import myListingReducer from "@/redux/myListings/myListingSlice";
import offerReducer from "@/redux/offer/offerSlice";
import showingReducer from "@/redux/showing/showingSlice";
import agentReducer from "@/redux/agent/agentSlice";
import vendorReducer from "@/redux/vendor/vendorSlice";
import advertisementReducer from "@/redux/advertisement/advertisementSlice";
import homepageFeedReducer from "@/redux/homepageFeed/homepageFeedSlice";
import postReducer from "@/redux/post/postSlice";
import documentReducer from "@/redux/document/documentSlice";
import welcomeReducer from "@/redux/welcome/welcomeSlice";
import chatReducer from "@/redux/chat/chatSlice";
import addressSlice from "@/redux/address/addressSlice";
import searchSlice from "@/redux/search/searchSlice";
import dashboardSlice from "./dashboard/dashboardSlice";
import scrapeHeroSlice from "./scrapeHero/scrapeHeroSlice";

// Combine all reducers into a single root reducer
const reducers = combineReducers({
  user: userReducer,
  lead: leadReducer,
  client: clientReducer,
  myListing: myListingReducer,
  showing: showingReducer,
  offer: offerReducer,
  agent: agentReducer,
  vendor: vendorReducer,
  advertisement: advertisementReducer,
  post: postReducer,
  document: documentReducer,
  homepageFeed: homepageFeedReducer,
  welcome: welcomeReducer,
  chat: chatReducer,
  address: addressSlice,
  search: searchSlice,
  dashboard: dashboardSlice,
  scrapeHero: scrapeHeroSlice
});

// Configuration for redux persist
const persistConfig = {
  key: "root",
  version: 1,
  storage,
};

// Create a persisted reducer
const persistedReducer = persistReducer(persistConfig, reducers);

// Configure the store
export const store = configureStore({
  reducer: persistedReducer,
  devTools: import.meta.env.VITE_NODE_ENV !== "production",
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});

export default (state: any, action: any) =>
  reducers(action.type === "USER_LOGOUT" ? undefined : state, action);

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
