type Props = {
  width: number;
  height: number;
  stroke?: string;
  className?: string;
  innerstroke?: any;
};

const IconSingleCheck = (props: Props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      viewBox="0 0 16 16"
      fill="none"
    >
      <path
        d="M13.3333 4L6 11.3333L2.66666 8"
        stroke={props.innerstroke ? props.innerstroke : "#4C42D7"}
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default IconSingleCheck;
