import IconWrapper from "../../components/baseComponents/IconWrapper";
import Logo from "../../assets/images/logo_black.svg";
import FooterTextCard from "@/components/mainComponents/Landing/FooterTextCard";

const LandingFooter = () => {
  return (
    <div className="w-full border-t-[1px] border-gray-300">
      <div className="max-w-screen-xl w-full mx-auto">
        <div className="flex lg:flex-row flex-col  w-full bg-white pt-20 pb-12 items-start xl:px-0 px-6">
          <div className="flex lg:items-start lg:justify-start md:mb-0 mb-14 justify-center items-center">
            <img src={Logo} alt="Logo" className="h-9" />
          </div>
          <div className="flex w-full lg:justify-end justify-center">
            <div className="grid grid-cols-2 md:grid-cols-2 lg:grid-cols-3 lg:gap-20  md:gap-10 sm:gap-10 gap-6 gap-y-8 lg:gap-y-20 ">
              <FooterTextCard
                title="sell a home"
                details={["Request an offer", "Pricing", "Reviews", "Stories"]}
              />
              <FooterTextCard title="buy a home" details={["Buy", "Finance"]} />
              <FooterTextCard
                title="terms & privacy"
                details={[
                  "Trust & Safety",
                  "Terms of Service",
                  "Privacy Policy",
                ]}
              />
              <FooterTextCard
                title="buy rent and sell"
                details={[
                  "Buy and sell properties",
                  "Rent home",
                  "Builder trade-up",
                ]}
              />
              <FooterTextCard
                title="about"
                details={["Company", "How it works", "Contact", "Investors"]}
              />
              <FooterTextCard
                title="resources"
                details={["Blog", "Guides", "FAQ", "Help Center"]}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="flex w-full bg-white py-[30px] border-t-2 border-grey">
        <div className="max-w-screen-xl w-full mx-auto">
          <div className="flex md:flex-row xl:px-0 px-6 gap-6 flex-col w-full justify-between items-center">
            <div className="flex mt-2 font-jakarta font-medium text-[16px] text-[#000929] opacity-50">
              ©2024 RealtyView, Inc. All rights reserved.
            </div>
            <div className="flex gap-10">
              <IconWrapper width={20} name="facebook" />
              <IconWrapper width={20} name="instagram" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default LandingFooter;
