type Props = {
    width: number;
    height: number;
    stroke?: string;
    className?: string;
};

const IconPencil = (props: Props) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 21" fill="none" {...props}>
            <path d="M14.1666 3.00037C14.3789 2.74956 14.6414 2.54542 14.9375 2.40094C15.2336 2.25646 15.5567 2.17481 15.8863 2.16121C16.216 2.14761 16.5448 2.20235 16.8519 2.32194C17.1591 2.44153 17.4378 2.62335 17.6702 2.8558C17.9027 3.08826 18.0838 3.36625 18.2021 3.67207C18.3203 3.97788 18.3732 4.30481 18.3571 4.63202C18.3411 4.95922 18.2566 5.27952 18.109 5.57251C17.9615 5.86549 17.754 6.12473 17.5 6.3337L6.24996 17.5837L1.66663 18.8337L2.91663 14.2504L14.1666 3.00037Z" stroke="#1F2937" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M12.5 4.66699L15.8333 8.00033" stroke="#1F2937" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    );
};

export default IconPencil;
