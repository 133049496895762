import Typography from "@/components/baseComponents/Typography";
import { Button } from "@/components/baseComponents/Button";
import HeroImg from "@/assets/images/landing-hero.png";

import Line from "@/assets/images/lading-hero-line.png";
import Disc from "@/assets/images/lading-her-disc.svg";
import Loc from "@/assets/images/landing-hero-loc.png";
import { BiSearch } from "react-icons/bi";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import LandingHeroImg from '@/assets/images/landing_hero.png';

const LandingHero = () => {
  const navigate = useNavigate();
  const [searchType, setSearchType] = useState("Buy");
  const [searchValue,setSearchValue] = useState<string>("")

  return (
    <div className="relative flex w-full bg-[#F7F7FD]">
      <div className="max-w-screen-xl mx-auto w-full relative z-10 px-6">
        <div className="flex flex-col w-full lg:mt-10 h-full lg:pt-36 lg:pb-36 lg:px-0 pt-12 pb-0">
          <div className="hidden lg:flex flex-col gap-4">
            <Typography
              variant="h1"
              color="black"
              className="whitespace-nowrap text-[64px] font-bold font-jakarta"
            >
              Find Home
            </Typography>
            <Typography
              variant="input-label"
              className="whitespace-nowrap text-[20px] font-medium font-jakarta"
            >
              with the simplest home search platform.
            </Typography>
          </div>
          <div className="flex flex-col lg:hidden gap-8">
            <Typography
              variant="input-label"
              className=" text-[40px] font-bold font-jakarta text-center"
            >
              Buy, rent, or sell your property easily
            </Typography>
            <div className="flex justify-center w-full">
              <div className="flex w-full md:w-2/3 justify-center">
                <Typography
                  variant="input-label"
                  className=" text-[20px] font-medium font-jakarta text-center"
                >
                  A great platform to buy, sell, or even rent your properties
                  without any commisions.
                </Typography>
              </div>
            </div>
          </div>
          <div className="flex flex-col lg:hidden justify-center items-center w-full">
            <div className="flex flex-col justify-start min-w-80 w-2/3 mt-12">
              <div className="flex justify-center bg-white rounded-tl-lg rounded-tr-lg mt-5 w-80">
                <div className="flex py-4 px-8  border-b-[3px] border-[#7065F0]">
                  <Typography
                    variant="input-label"
                    className="whitespace-nowrap text-[#7065F0] text-[18px] font-bold font-jakarta"
                  >
                    Rent
                  </Typography>
                </div>
                <div className="flex py-4 px-8">
                  <Typography
                    variant="input-label"
                    className="whitespace-nowrap text-[18px] font-bold font-jakarta"
                  >
                    Buy
                  </Typography>
                </div>
                <div className="flex py-4 px-8">
                  <Typography
                    variant="input-label"
                    className="whitespace-nowrap text-[18px] font-bold font-jakarta"
                  >
                    Sell
                  </Typography>
                </div>
              </div>
              <div className="flex w-full justify-start items-start">
                <div className="relative w-full">
                  <input
                    type="search"
                    id="search"
                    className="block w-full font-jakarta p-5 font-medium text-[14px] text-sm bg-white"
                    placeholder="Search location"
                    required
                    value={searchValue}
                    onChange={(e)=>setSearchValue(e.target.value)}
                  />
                  <button
                    type="submit"
                    className="text-white absolute end-2.5 bottom-2.5 bg-blue-700 font-jakarta p-[14px] rounded-lg"
                    onClick={()=>navigate(`/landing/property?loc=${searchValue}`)}
                  >
                    <BiSearch />
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="flex lg:py-20 gap-20 py-12 w-full lg:justify-start justify-center">
            <div className="flex gap-6">
              <div className="flex h-16 w-[3px] bg-[#E0DEF7]"></div>
              <div className="flex flex-col">
                <Typography
                  variant="h1"
                  className="whitespace-nowrap text-[#7065F0] text-[32px] font-bold font-jakarta"
                >
                  50k+
                </Typography>
                <Typography
                  variant="input-label"
                  className="whitespace-nowrap text-[16px] text-[#6C727F] font-medium font-jakarta"
                >
                  Properties
                </Typography>
              </div>
            </div>
            <div className="flex gap-6">
              <div className="flex h-16 w-[3px] bg-[#E0DEF7]"></div>
              <div className="flex flex-col">
                <Typography
                  variant="h1"
                  className="whitespace-nowrap text-[#7065F0] text-[32px] font-bold font-jakarta"
                >
                  10k+
                </Typography>
                <Typography
                  variant="input-label"
                  className="whitespace-nowrap text-[16px] text-[#6C727F] font-medium font-jakarta"
                >
                  Agents
                </Typography>
              </div>
            </div>
          </div>
          <div className="lg:flex hidden">
            <div className="flex bg-white rounded-tl-lg rounded-tr-lg">
              <div
                className={`flex py-4 px-8 cursor-pointer ${
                  searchType == "Buy" ? " border-b-[3px] border-[#7065F0]" : ""
                }`}
                onClick={() => setSearchType("Buy")}
              >
                <Typography
                  variant="input-label"
                  className="whitespace-nowrap text-[#7065F0] text-[18px] font-bold font-jakarta"
                >
                  Buy
                </Typography>
              </div>
              <div
                className={`flex py-4 px-8 cursor-pointer ${
                  searchType == "Rent" ? "border-b-[3px] border-[#7065F0]" : ""
                } `}
                onClick={() => setSearchType("Rent")}
              >
                <Typography
                  variant="input-label"
                  className={`whitespace-nowrap text-[18px] font-bold font-jakarta`}
                >
                  Rent
                </Typography>
              </div>
            </div>
          </div>
          <div className="lg:flex bg-white py-6 px-8 hidden">
            <div className="flex w-full justify-between items-center rounded-tr-lg rounded-bl-lg rounded-br-lg">
              <input
                type="search"
                name="search"
                id=""
                className={`w-full py-3 px-4 placeholder-black !bg-white border-none focus:border-none rounded-lg  text-[18px] font-bold font-jakarta`}
                placeholder={"Search a City, State or Zip Code"}
                value={searchValue}
                onChange={(e)=>setSearchValue(e.target.value)}
              />
              <Button
                className={`py-4 px-8 !h-full text-white w-[300px] focus:bg-white bg-[#7065F0] font-jakarta font-bold text-[16px]`}
                variant="text"
                onClick={() => { navigate(`/landing/property?loc=${searchValue}`); }}
              >
                Search Properties
              </Button>
            </div>
          </div>
        </div>
      </div>
      <div
        className="lg:block lg:absolute top-0 right-0 w-1/2 h-full hidden"
        style={{
          background: `linear-gradient(90deg, rgba(247, 247, 253, 0.80) 0%, rgba(247, 247, 253, 0.20) 100%), url(${LandingHeroImg}) lightgray 50% / cover no-repeat`,
        }}
      >
        <div className="relative">
          <div className="absolute top-[230px] left-[120px]">
            <img src={HeroImg} alt="" />
          </div>
          <div className="absolute top-[440px] right-[120px]">
            <img src={HeroImg} alt="" />
          </div>
          <div className="absolute top-[80px] right-[240px]">
            <img src={Line} alt="" />
          </div>
          <div className="absolute top-[80px] right-[440px]">
            <img src={Disc} alt="" />
          </div>
          <div className="absolute top-[350px] right-[400px]">
            <img src={Loc} alt="" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default LandingHero;
