import React from "react";
import AdvancedTable from "@/components/baseComponents/AdvancedTable";
import Typography from "@/components/baseComponents/Typography";
import { Button } from "@/components/baseComponents/Button";
import SubmitOffer from "./SubmitOffer";
import { getUser } from "@/redux/user/userSlice";
import { useAppSelector, useAppDispatch } from "@/redux/hooks";
import { notify } from "@/shared/services/notify";
import { DeleteOffersDto, GetOffersDto, UpdateOfferStatusDto } from "@/shared/interfaces/interfaces";
import Filter from "@/components/mainComponents/Filter";
import SortAdvanced from "@/components/mainComponents/SortAdvanced";
import { MdEdit, MdOutlineMoveToInbox } from "react-icons/md";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { FaRegPaperPlane } from "react-icons/fa";
import ViewOfferDrawer from "./ViewOfferDrawer";
import {
  deleteOffers,
  getOffers,
  getOffersByUserId,
  updateOfferStatus,
} from "@/redux/offer/offerSlice";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { NumericFormat } from "react-number-format";
import Trash from "@/assets/icons/trash.png";
import ListingImagePlaceholder from "@/assets/images/listing_image_placeholder.png";

const tabs = [
  { name: "All" },
  { name: "Pending" },
  { name: "Accepted" },
  { name: "Countered" },
  { name: "Declined" },
  { name: "Archived" },
  { name: "Rescinded" },
];

const sent_status = [
  { id: "All", title: "All" },
  { id: "Sent", title: "Sent" },
  { id: "Received", title: "Received" },
];

const TableFields = [
  {
    name: "",
    type: "image",
    slug: "image_src",
    class_name:
      "text-left text-gray-900 text-sm font-semibold px-2 !py-[8px]",
    imageClass: "max-w-[4rem]"
  },
  {
    name: "",
    type: "text",
    slug: "sentStatus",
    class_name:
      "text-left text-gray-900 text-sm font-semibold px-2 pl-4 !py-[8px]",
  },
  {
    name: "Status",
    type: "text",
    slug: "status",
    class_name:
      "text-left text-gray-900 text-sm font-semibold px-2 !py-[8px]",
  },
  {
    name: "Address",
    type: "text",
    slug: "address",
    class_name:
      "text-left text-gray-900 text-sm font-semibold px-2 !py-[8px]",
  },
  {
    name: "Offer",
    type: "text",
    slug: "offer",
    class_name:
      "text-left text-gray-900 text-sm font-semibold px-2 !py-[8px]",
  },
  {
    name: "My Client",
    type: "text",
    slug: "client_name",
    class_name:
      "text-left text-gray-900 text-sm font-semibold px-2 !py-[8px]",
  },
  {
    name: "Representing",
    type: "text",
    slug: "representing",
    class_name:
      "text-left text-gray-900 text-sm font-semibold px-2 !py-[8px]",
  },
  {
    name: "Agent",
    type: "text",
    slug: "agent",
    class_name:
      "text-left text-gray-900 text-sm font-semibold px-2 !py-[8px]",
  },
  {
    name: "",
    type: "action",
    slug: "action",
    class_name:
      "text-gray-900 text-sm font-semibold px-2 !py-[8px]",
  },
];

const SortFieldOptions = [
  { value: "address", label: "Address" },
  { value: "client_name", label: "Client" },
  { value: "offer", label: "Offer" },
  { value: "representing", label: "Representing" },
];

function classNames(...classes: any) {
  return classes.filter(Boolean).join(" ");
}
const Offer = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const user = useAppSelector(getUser);
  const offers = useAppSelector(getOffers);
  const [currentTab, setCurrentTab] = React.useState<string>("All");
  const [openDrawer, setOpenDrawer] = React.useState<boolean>(false);
  const [openViewDrawer, setOpenViewDrawer] = React.useState<boolean>(false);
  // const [viewData, setViewdata] = React.useState<any>(null)
  const [openConfirm, setOpenConfirm] = React.useState<boolean>(false);
  const [filteredData, setFilteredData] = React.useState<Array<any>>([]);
  const [keyword, setKeyword] = React.useState<string>("");
  const [sortType, setSortType] = React.useState<string>("Descending");
  const [sortField, setSortField] = React.useState<string>("Address");
  const [totalCount, setTotalCount] = React.useState<number>(0);
  const [currentPage, setCurrentPage] = React.useState<number>(1);
  const [recordsPerPage, setRecordsPerPage] = React.useState<number>(25);
  const [selectedRowData, setSelectedRowData] = React.useState<any>(null);
  const [currentSentStatus, setCurrentSentStatus] = React.useState<string>("All");
  const [isRespondModalOpen, setRespondModalOpen] = React.useState<boolean>(false);

  const goPage = (page: string) => {
    navigate("/" + page);
  };

  const onSetPage = (value: number) => {
    setCurrentPage(value);
  };

  const onClickRow = (value: any) => {
    setOpenViewDrawer(true);
    setSelectedRowData(value);
    console.log(value)
  };

  const changeKeyword = (keyword: string) => {
    setKeyword(keyword);
  };
  const changeSortType = (value: string) => {
    setSortType(value);
  };
  const changeSortField = (value: string) => {
    setSortField(value);
  };
  const filterData = () => {
    const data: GetOffersDto = {
      userId: user._id,
      keyword: keyword,
      sortType: sortType,
      sortField: sortField,
      recordsPerPage: recordsPerPage,
      currentPage: currentPage,
      status: currentTab,
      sentStatus: currentSentStatus,
    };
    dispatch(getOffersByUserId(data)).then((res: any) => {
      try {
        setTotalCount(res.payload.totalCount);
        const table_data = makeTableData(res.payload.offers);
        setFilteredData(table_data);
      } catch (e) {
        notify(false, "Something went wrong.");
      }
    });
  };

  const sentStatusFilter = (value: string) => {
    setCurrentSentStatus(value);
  };
  const openNewSubmitDrawer = () => {
    setOpenDrawer(true);
    setSelectedRowData(null);
  };
  const editRow = (data: any) => {
    setSelectedRowData(data);
    setOpenViewDrawer(false);
    setOpenDrawer(true);
  };
  const deleteRow = (data: any) => {
    console.log(data?.status)
    setSelectedRowData(data);
    if (data?.status !== "Countered" && data?.status !== "Declined") {
      setRespondModalOpen(true);
    } else {
      setOpenConfirm(true);
    }
  };

  const makeTableData = (data: Array<object>) => {
    const res: Array<object> = [];
    data.map((item: any) => {
      const new_item = JSON.parse(JSON.stringify(item));
      new_item.status = item?.isArchived ? "Archived" : item?.status;
      new_item.sentStatus = item.owner?._id === user._id ? "Sent" : "Received";

      // temporary, to be phased out since we broke down the address by parts 12-20-2023
      if (new_item?.listing?.listingAddress) {
        new_item.address = (
          <div>
            <div className="truncate">{new_item?.listing?.listingAddress?.streetLine + " " + new_item?.listing?.listingAddress?.secondary}</div>
            <div className="truncate">{new_item?.listing?.listingAddress?.city + ", " + new_item?.listing?.listingAddress?.state + " " + new_item?.listing?.listingAddress?.zipcode}</div>
          </div>
        );
      } else {
        if (new_item?.listing?.listingCity && new_item?.listing?.listingState) {
          new_item.address = (
            <div>
              <div className="truncate">{new_item?.listing?.listingAddress1 + " " + new_item?.listing?.listingAddress2} </div>
              <div className="truncate">{new_item?.listing?.listingCity + ", " + new_item?.listing?.listingState + " " + new_item?.listing?.listingZipcode}</div>
            </div>
          );
        } else {
          new_item.address = (
            <div className="!line-clamp-2 !whitespace-normal">{new_item?.listing?.listingAddress}</div>
          );
        }
      }
      
      if (new_item?.owner?.agent?._id === user.agent._id) {
        new_item.client_name = item?.client?.firstName + " " + item?.client?.lastName;
        new_item.representing = item?.client?.leadType;
        new_item.agent = item.listing?.owner?.agent?.firstName + " " + item.listing?.owner?.agent?.lastName;
      } else {
        new_item.client_name = item?.listing?.client?.firstName + " " + item?.listing?.client?.lastName
        new_item.representing = item?.listing?.client?.leadType;
        new_item.agent = item.owner?.agent?.firstName + " " + item.owner?.agent?.lastName;
      }

      new_item.offer = <NumericFormat displayType="text" thousandSeparator="," prefix="$" value={new_item.offerAmount} />;
      new_item.image_src = new_item?.listing?.propertyPhotos.length > 0
        ? new_item?.listing?.propertyPhotos[0]?.file
        : ListingImagePlaceholder;
      new_item.action = [
        { name: "Edit", icon: <MdEdit className="mt-[2px] text-14" />, color: "black" },
        {
          name: "Archive",
          icon: <img
            src={Trash}
            width={14}
            alt="ThreeDots"
            className="object-none"
          />,
          color: "#C77E90",
        },
      ];
      if (item.icon == "paper") {
        new_item.icon = <FaRegPaperPlane />;
      }
      if (item.icon == "move_inbox") {
        new_item.icon = <MdOutlineMoveToInbox />;
      }
      res.push(new_item);
    });
    return res;
  };

  const archiveOffer = () => {
    let archivingToast = toast.loading("Archiving offer...");
    let ids: Array<string> = [];
    ids.push(selectedRowData._id);

    const data: DeleteOffersDto = {
      ids: ids,
      userId: user._id,
      search: {
        userId: user._id,
        keyword: keyword,
        sortType: sortType,
        sortField: sortField,
        recordsPerPage: recordsPerPage,
        currentPage: currentPage,
        status: currentTab,
        sentStatus: currentSentStatus,
      },
    };

    dispatch(deleteOffers(data)).then((res) => {
      try {
        setTotalCount(res.payload.totalCount);
        toast.update(archivingToast, {
          render: res.payload.message,
          type: "success",
          isLoading: false,
          autoClose: 3000,
        });
      } catch (e) {
        toast.update(archivingToast, {
          render:
            "Something went wrong while archiving your offer. Please try again.",
          type: "error",
          isLoading: false,
          autoClose: 3000,
        });
      }
    });
  };
  const onUpdateStatus = (status: string) => {
    let updatingStatusToast = toast.loading("Updating offer status...");
    setRespondModalOpen(false);
    setOpenViewDrawer(false);

    const data: UpdateOfferStatusDto = {
      offerId: selectedRowData?._id,
      status: status,
      userId: user._id,
      search: {
        userId: user._id,
        keyword: keyword,
        sortType: sortType,
        sortField: sortField,
        recordsPerPage: recordsPerPage,
        currentPage: currentPage,
        status: currentTab,
        sentStatus: currentSentStatus,
      },
    };

    dispatch(updateOfferStatus(data)).then((res: any) => {
      try {
        setTotalCount(res.payload.totalCount);
        const table_data = makeTableData(res.payload.offers);
        setFilteredData(table_data);
        toast.update(updatingStatusToast, {
          render: res.payload.message,
          type: "success",
          isLoading: false,
          autoClose: 3000,
        });
      } catch (e) {
        toast.update(updatingStatusToast, {
          render:
            "Something went wrong while archiving your offer. Please try again.",
          type: "error",
          isLoading: false,
          autoClose: 3000,
        });
      }
    });
  }
  React.useEffect(() => {
    setRecordsPerPage(25);
    filterData();
  }, [currentTab, currentSentStatus, currentPage]);

  React.useEffect(() => {
    const table_data = makeTableData(offers);
    setFilteredData(table_data);
  }, [offers]);

  return (
    <div>
      {(openDrawer || openViewDrawer || isRespondModalOpen) && (
        <div className="!bg-[#00000040] h-screen w-full fixed top-0 left-0 z-30"></div>
      )}
      <SubmitOffer
        search_list_flag={true}
        open={openDrawer}
        changeState={setOpenDrawer}
        data={selectedRowData}
        keyword={keyword}
        sortType={sortType}
        sortField={sortField}
        currentPage={currentPage}
        currentTab={currentTab}
        currentSentStatus={currentSentStatus}
        recordsPerPage={recordsPerPage}
        setTotalCount={setTotalCount}
        setCurrentPage={setCurrentPage}
      />
      <ViewOfferDrawer
        open={openViewDrawer}
        changeViewDrawer={setOpenViewDrawer}
        data={selectedRowData}
        onEditOffer={editRow}
        onUpdateStatus={onUpdateStatus}
      />
      {openConfirm && (
        <div
          className="bg-[#00000040] w-full h-screen flex items-center justify-center fixed top-0 left-0 z-30"
          onClick={() => setOpenConfirm(false)}
        >
          <div className="rounded-lg max-w-[435px] w-full bg-white p-3">
            <div>
              <div className="flex justify-end w-full text-secondary hover:text-primary cursor-pointer">
                <XMarkIcon
                  className="h-6 w-6"
                  aria-hidden="true"
                  onClick={() => setOpenConfirm(false)}
                />
              </div>
              <div className="w-full flex justify-center mt-[10px]">
                <Typography variant="button2">
                  Are you sure you want to archive this listing?
                </Typography>
              </div>
              <div className="flex justify-center mt-[20px] mb-[10px] gap-[10px]">
                <Button
                  variant="contained"
                  color="danger"
                  onClick={archiveOffer}
                  className="w-[146px]"
                >
                  <Typography variant="button2" className="text-[#B32F43]">
                    Archive Listing
                  </Typography>
                </Button>
                <Button variant="contained" color="success" className="w-[146px]">
                  <Typography variant="button2">
                    Keep Listing
                  </Typography>
                </Button>
              </div>
            </div>
          </div>
        </div>
      )}
      {isRespondModalOpen && (
        <div className="bg-[#00000040] w-full h-screen flex items-center justify-center fixed top-0 left-0 z-30">
          <div className="rounded-lg max-w-[435px] w-full bg-white p-3">
            <div>
              <div className="flex justify-end w-full text-secondary hover:text-primary cursor-pointer">
                <XMarkIcon
                  className="h-6 w-6"
                  aria-hidden="true"
                  onClick={() => setRespondModalOpen(false)}
                />
              </div>
              <div className="w-full flex justify-center mt-[10px]">
                <Typography variant="button2">
                  Offers must be responded to before archiving.
                </Typography>
              </div>
              <div className="flex gap-5 justify-center mt-[20px] mb-[10px]">
                <Button variant="contained" color="danger" className="w-28" onClick={() => onUpdateStatus("Declined")}>
                  <Typography variant="button2">
                    Decline
                  </Typography>
                </Button>
                <Button variant="contained" color="warning" className="w-28" onClick={() => onUpdateStatus("Countered")}>
                  <Typography variant="button2">
                    Counter
                  </Typography>
                </Button>
              </div>
            </div>
          </div>
        </div>
      )}
      <div className="p-8">
      <div className="flex flex-col gap-2">
          <div className="flex justify-between items-center">
            <Typography
              variant="h2"
              color="primary"
              className="whitespace-nowrap"
            >
              Offers
            </Typography>
            <Button
              onClick={() => openNewSubmitDrawer()}
            >
              <Typography variant="button2">Submit an Offer</Typography>
            </Button>

          </div>
          <div className="flex justify-between items-center">
            <nav aria-label="Tabs">
              <div className="flex items-center gap-3">
                {tabs.map((tab, index) => (
                  <div
                    key={index}
                    className={classNames(
                      tab.name === currentTab
                        ? "border-indigo-500 text-indigo-600"
                        : "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700",
                      "whitespace-nowrap border-b-2 p-1 cursor-pointer"
                    )}
                    aria-current={tab.name === currentTab ? "page" : undefined}
                    onClick={() => setCurrentTab(tab.name)}
                  >
                    <Typography variant="page-menu">{tab.name}</Typography>
                  </div>
                ))}
              </div>
            </nav>
            <div className="flex items-center gap-5">
              <div className="flex gap-5">
                {sent_status.map((status) => (
                  <div key={status.id} className="flex items-center">
                    <input
                      id={status.id}
                      name="sent-method"
                      type="radio"
                      defaultChecked={status.id === "All"}
                      className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                      onChange={(e) => sentStatusFilter(e.target.id)}
                    />
                    <label
                      htmlFor={status.id}
                      className="ml-1 block"
                    >
                      <Typography variant="page-menu">{status.title}</Typography>
                    </label>
                  </div>
                ))}
              </div>
              <Filter
                changeKeyword={changeKeyword}
                keyword={keyword}
                filterLeads={filterData}
              />
              <SortAdvanced
                sortFieldOptions={SortFieldOptions}
                sortType={sortType}
                sortField={sortField}
                changeSortField={changeSortField}
                changeSortType={changeSortType}
                filterData={filterData}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="px-6 lg:pb-24 md:pb-60 sm:pb-60 max-sm:pb-60">
        <AdvancedTable
          minCellWidth={100}
          class_name="custom-table table offer-table items-center"
          data={filteredData}
          fields={TableFields}
          onClickRow={onClickRow}
          totalPage={Math.ceil(totalCount / recordsPerPage)}
          totalCount={totalCount}
          currentPage={currentPage}
          recordsPerpage={recordsPerPage}
          onSetPage={onSetPage}
          editRow={editRow}
          deleteRow={deleteRow}
          emptyStateProps={{
            heading: "Submit an Offer",
            content:
              "Search for the listing you will submit an offer on.",
            buttonText: "Search Listings",
            onButtonClick: () => goPage('listings'),
          }}
        />
      </div>
    </div>
  );
};

export default Offer;
