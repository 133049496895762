import IconWrapper from "@/components/baseComponents/IconWrapper";
import Select from "@/components/baseComponents/Select";
import TextField from "@/components/baseComponents/TextField";
import Textarea from "@/components/baseComponents/Textarea";
import Typography from "@/components/baseComponents/Typography";
import { Button } from "@/components/baseComponents/Button";
import { useAppSelector } from "@/redux/hooks";
import { getUser } from "@/redux/user/userSlice";
import { MyListingShowingAvailabilityTime } from "@/shared/interfaces/interfaces";
import { NumericFormat } from "react-number-format";

type IProps = {
  data?: any;
  handleInputChange: Function;
  handleSelectChange: Function;
  onAddAvailability: Function;
  onRemoveAvailability: Function;
  handleSelectAvailability: Function;
  handleChangeAvailabilityTime: Function;
};

const Showings = (props: IProps) => {
  const user = useAppSelector(getUser);

  return (
    <div className="my-[30px] px-4 md:px-8">
      <div>
        <Typography variant="h3" color="primary">
          Procurement Agreement
        </Typography>
        <div className="col-span-2 sm:col-span-1 space-y-2 py-3">
          <Typography variant="body" color="secondary">
            Payment Terms
          </Typography>
          <div className="flex items-center gap-8">
            <div className="flex items-center">
              <input
                type="radio"
                value="Percentage"
                id="procurementAgreementPaymentTermsPercentage"
                name="procurementAgreementPaymentTerms"
                checked={
                  props.data?.procurementAgreementPaymentTerms === "Percentage"
                }
                className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                onChange={(e) =>
                  props.handleInputChange(e, "procurementAgreementPaymentTerms")
                }
              />
              <label
                htmlFor="procurementAgreementPaymentTermsPercentage"
                className="ml-1 block"
              >
                <Typography
                  variant="page-menu"
                  className={
                    props.data?.procurementAgreementPaymentTerms ===
                    "Percentage"
                      ? "text-primary"
                      : "text-[#8E9CB2]"
                  }
                >
                  Percentage (%)
                </Typography>
              </label>
            </div>
            <div className="flex items-center">
              <input
                type="radio"
                value="Fixed Amount"
                id="procurementAgreementPaymentTermsFixedAmount"
                name="procurementAgreementPaymentTerms"
                checked={
                  props.data?.procurementAgreementPaymentTerms ===
                  "Fixed Amount"
                }
                className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                onChange={(e) =>
                  props.handleInputChange(e, "procurementAgreementPaymentTerms")
                }
              />
              <label
                htmlFor="procurementAgreementPaymentTermsFixedAmount"
                className="ml-1 block"
              >
                <Typography
                  variant="page-menu"
                  className={
                    props.data?.procurementAgreementPaymentTerms ===
                    "Fixed amount"
                      ? "text-primary"
                      : "text-[#8E9CB2]"
                  }
                >
                  Fixed amount ($)
                </Typography>
              </label>
            </div>
          </div>
          <div className=" space-y-2 py-3">
            <Typography variant="body" color="secondary">
              Amount
            </Typography>
            <NumericFormat
              autoComplete="off"
              thousandSeparator=","
              allowLeadingZeros={false}
              name="procurementAgreementAmount"
              value={props.data?.procurementAgreementAmount || ""}
              onChange={(e) => props.handleInputChange(e, "number")}
              placeholder={
                props.data?.procurementAgreementPaymentTerms === "Percentage"
                  ? "%"
                  : "$"
              }
              className="body bg-netural rounded-5 h-10 text-primary p-4 w-full placeholder:caption border-transparent focus:border-netural-dark"
            />
          </div>
          <Typography variant="body" color="secondary">
            For the successful procurement of a Buyer for my listing,
            <b>
              {" "}
              {props.data?.owner?.agent?.company?.businessName ?? "My company"}
            </b>{" "}
            is willing to pay{" "}
            <b>
              {user?.agent?.company?.businessName ?? "your company"}{" "}
              {props.data?.procurementAgreementPaymentTerms === "Fixed Amount"
                ? "$"
                : ""}
              {props.data?.procurementAgreementAmount > 0 ? (
                props.data?.procurementAgreementPaymentTerms ===
                "Percentage" ? (
                  props.data?.procurementAgreementAmount
                ) : (
                  <NumericFormat
                    displayType="text"
                    thousandSeparator=","
                    value={props.data?.procurementAgreementAmount.toLocaleString()}
                  />
                )
              ) : (
                ""
              )}
              {props.data?.procurementAgreementPaymentTerms === "Percentage"
                ? "%"
                : ""}{" "}
            </b>
            of the purchase price upon the successful closing of the
            transaction.
          </Typography>
        </div>
      </div>

      <div className=" py-3">
        <Typography variant="h3" color="primary">
          Showing Instructions
        </Typography>
        <div className="mt-[25px]">
          <div className="flex justify-between">
            <Typography variant="body" color="secondary">
              Showing Instructions
            </Typography>
            <Typography variant="body" color="secondary">
              {props.data?.showingInstuction.length}/100
            </Typography>
          </div>
          <Textarea
            maxLength={100}
            rows={5}
            name="showingInstuction"
            value={props.data?.showingInstuction}
            onChange={(e) => props.handleInputChange(e)}
          />
        </div>
        <div className="mt-[25px]">
          <div className="flex justify-between">
            <Typography variant="body" color="secondary">
              Showing Remarks
            </Typography>
            <Typography variant="body" color="secondary">
              {props.data?.showingRemarks.length}/100
            </Typography>
          </div>
          <Textarea
            maxLength={100}
            rows={5}
            name="showingRemarks"
            value={props.data?.showingRemarks}
            onChange={(e) => props.handleInputChange(e)}
          />
        </div>
        <div className="grid grid-cols-2 gap-[15px] mt-[25px]">
          <div className="col-span-2 sm:col-span-1">
            <Typography variant="body" color="secondary">
              Access Type
            </Typography>
            <Select
              options={[
                { value: "None", label: "None" },
                { value: "Lockbox", label: "Lockbox" },
                { value: "Keypad", label: "Keypad" },
              ]}
              name="showingLockboxOrKeypad"
              value={{
                value: props.data?.showingLockboxOrKeypad,
                label: props.data?.showingLockboxOrKeypad,
              }}
              onChange={(value) =>
                props.handleSelectChange(value, "showingLockboxOrKeypad")
              }
            />
          </div>
          <div className="col-span-2 sm:col-span-1">
            <Typography variant="body" color="secondary">
              Access Code
            </Typography>
            <TextField
              name="showingAccessCode"
              value={props.data?.showingAccessCode}
              onChange={(e) => props.handleInputChange(e)}
            />
          </div>
        </div>
        {/* <div className="grid grid-cols-2 gap-[15px] mt-[25px]">
          <div className="col-span-2 sm:col-span-1">
            <Typography variant="caption" color="secondary">Occupancy Status</Typography>
            <Select
              options={occupancyStatus}
              name='showingOccupanyStatus'
              value={{ value: props.data?.showingOccupanyStatus, label: props.data?.showingOccupanyStatus }}
              onChange={(value) => props.handleSelectChange(value, 'showingOccupanyStatus')}
            />
          </div>
          <div className="col-span-2 sm:col-span-1">
            <Typography variant="caption" color="secondary">Require Agency Disclosure</Typography>
            <Select
              options={yesOrNoOptions}
              name='showingRequireAgencyDisclosure'
              value={{ value: props.data?.showingRequireAgencyDisclosure, label: props.data?.showingRequireAgencyDisclosure }}
              onChange={(value) => props.handleSelectChange(value, 'showingRequireAgencyDisclosure')}
            />
          </div>
        </div> */}
      </div>
      <div className="mt-[50px] ">
        <Typography variant="h3" color="primary">
          Showing Availability
        </Typography>
        <div className="w-1/2 mt-[25px]">
          <Typography variant="caption" color="secondary">
            Interval
          </Typography>
          <Select
            options={[
              { value: 15, label: "15 minutes" },
              { value: 30, label: "30 minutes" },
              { value: 45, label: "45 minutes" },
              { value: 60, label: "1 hour" },
              // { value: 0, label: "Custom" },
            ]}
            placeholder="Select"
            name="showingAvailabilityTimeInterval"
            value={{
              value: props.data?.showingAvailabilityTimeInterval.value ?? "",
              label:
                props.data?.showingAvailabilityTimeInterval.value +
                  " " +
                  (props.data?.showingAvailabilityTimeInterval.value === 60
                    ? "hour"
                    : "minutes") ?? "",
            }}
            onChange={(option) =>
              props.handleSelectChange(
                option,
                "showingAvailabilityTimeInterval"
              )
            }
          />
        </div>
        {props.data?.showingAvailability &&
          Object.entries(props.data.showingAvailability).map(
            ([key, day]: any, index: any) => {
              return (
                <div
                  key={key}
                  className="grid grid-cols-1 md:grid-cols-6 gap-2 md:gap-5 mt-[25px]"
                >
                  <label
                    htmlFor={`${key}_${index}`}
                    className="inline-flex items-center cursor-pointer gap-2 md:col-span-2"
                  >
                    <input
                      type="checkbox"
                      checked={day.isSelected}
                      id={`${key}_${index}`}
                      name={`${key}_${index}`}
                      className="sr-only peer"
                      onChange={(e) => props.handleSelectAvailability(e, key)}
                    />

                    <div className="relative w-9 h-5 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-4 after:w-4 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
                    <Typography
                      variant="medium-text"
                      color="primary"
                      className="capitalize"
                    >
                      {key}
                    </Typography>
                  </label>

                  <div className="flex-1 md:col-span-4 py-3">
                    {day.time &&
                      day.time.map(
                        (
                          time: MyListingShowingAvailabilityTime,
                          timeIndex: number
                        ) => {
                          return (
                            <div key={timeIndex} className="flex gap-3 mb-2">
                              <div className="flex gap-4 items-center bg-[#F1F5F9] pl-3">
                                <Typography variant="body">From</Typography>
                                <TextField
                                  type="time"
                                  id={`timeStart_${index}_${timeIndex}`}
                                  name="timeStart"
                                  inputClassName="medium-text"
                                  value={time.timeStart}
                                  textOnly
                                  onChange={(e) =>
                                    props.handleChangeAvailabilityTime(
                                      e,
                                      key,
                                      timeIndex
                                    )
                                  }
                                />
                              </div>
                              <div className="flex gap-4 items-center bg-[#F1F5F9] pl-3">
                                <Typography variant="body">To</Typography>
                                <TextField
                                  type="time"
                                  id={`timeEnd_${index}_${timeIndex}`}
                                  name="timeEnd"
                                  inputClassName="medium-text"
                                  value={time.timeEnd}
                                  textOnly
                                  onChange={(e) =>
                                    props.handleChangeAvailabilityTime(
                                      e,
                                      key,
                                      timeIndex
                                    )
                                  }
                                />
                              </div>
                              <Button
                                variant="icon"
                                className={`disabled:bg-transparent${
                                  timeIndex === 0 && ` invisible`
                                }`}
                                disabled={day.time.length === 1}
                                onClick={() =>
                                  props.onRemoveAvailability(key, timeIndex)
                                }
                              >
                                <IconWrapper
                                  name="trash-can"
                                  className="cursor-pointer"
                                  width={19}
                                  height={19}
                                />
                              </Button>
                            </div>
                          );
                        }
                      )}
                  </div>

                  {/* <div className="flex gap-3">
                    <Button
                      variant="icon"
                      onClick={() => props.onAddAvailability(key)}
                    >
                      <IconWrapper name="plus" width={21} height={21} />
                    </Button>

                    <Menu as="div" className="relative inline-block text-left">
                    <div>
                      <Menu.Button className="mt-2 px-3">
                        <MdContentCopy size={19} />
                      </Menu.Button>
                    </div>
                    <Transition
                      as={Fragment}
                      enter="transition ease-out duration-100"
                      enterFrom="transform opacity-0 scale-95"
                      enterTo="transform opacity-100 scale-100"
                      leave="transition ease-in duration-75"
                      leaveFrom="transform opacity-100 scale-100"
                      leaveTo="transform opacity-0 scale-95"
                    >
                      <Menu.Items className="absolute right-7 top-0 mt-2 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5">
                        <div className="px-0 py-0">
                          {props.data?.showingAvailability && Object.entries(props.data.showingAvailability).filter(([secondKey]: any) => secondKey !== key).map(([key, day]: any, index: any) => {

                            return (
                              <Menu.Item disabled>
                                <label key={day + index} className='pl-3 pr-8 py-2 flex flex-row gap-3 items-center cursor-pointer hover:bg-gray-300'>
                                  <input
                                    type="checkbox"
                                    className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                                    // ref={checkbox as any}
                                    // checked={checked}
                                    // onChange={toggleAll}
                                  />
                                  <Typography variant='button2' className='capitalize'>{key}</Typography>
                                </label>
                              </Menu.Item>
                            )
                          })}
                        </div>
                      </Menu.Items>
                    </Transition>
                  </Menu>
                  </div> */}
                </div>
              );
            }
          )}
      </div>
    </div>
  );
};

export default Showings;
