type Props = {
  width: number;
  height: number;
};

const IconBookmarkHeart = (props: Props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 18 24"
      fill="none"
      {...props}
    >
      <rect y="3" width="18" height="13" fill="#C84156" />
      <path
        d="M2.00803e-07 23.25C-9.50907e-05 23.3802 0.0337268 23.5083 0.0981369 23.6215C0.162547 23.7347 0.255325 23.8291 0.367338 23.8956C0.47935 23.962 0.606737 23.9982 0.736957 24.0004C0.867178 24.0027 0.995744 23.971 1.11 23.9085L9 19.6035L16.89 23.9085C17.0043 23.971 17.1328 24.0027 17.263 24.0004C17.3933 23.9982 17.5207 23.962 17.6327 23.8956C17.7447 23.8291 17.8375 23.7347 17.9019 23.6215C17.9663 23.5083 18.0001 23.3802 18 23.25V3C18 2.20435 17.6839 1.44129 17.1213 0.87868C16.5587 0.316071 15.7956 0 15 0L3 0C2.20435 0 1.44129 0.316071 0.87868 0.87868C0.316071 1.44129 2.00803e-07 2.20435 2.00803e-07 3V23.25ZM9 7.5C11 4.5 15 8 9 13.0305C3 8 7 4.5 9 7.5Z"
        fill="#8E9CB2"
      />
    </svg>
  );
};

export default IconBookmarkHeart;
