import React, { useState } from "react";

interface TooltipProps {
  text: any;
  children: React.ReactNode;
  className?: string;
}

const Tooltip: React.FC<TooltipProps> = ({ text, children, className }) => {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <div
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      className={`${className} relative bg-[#52525B]"`}
    >
      {children}
      {isHovered && (
        <div
          className="lg:block sm:hidden max-sm:hidden"
          style={{
            whiteSpace: "nowrap",
            fontFamily: "Arial",
            fontSize: "12px",
            position: "absolute",
            backgroundColor: "#52525B",
            color: "white",
            padding: "8px",
            borderRadius: "8px",
            zIndex: 999,
            top: "100%",
            left: "50%",
            transform: "translateX(-50%)",
            marginTop: "2px",
            userSelect: "none",
          }}
        >
          {text}
        </div>
      )}
    </div>
  );
};

export default Tooltip;
