type Props = {
  width: number;
  height: number;
  stroke?: string;
  className?: string;
};

const IconHouse = (props: Props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 20 21"
      fill="none"
      {...props}
    >
      <path
        d="M2.5 7.70833L10 1.875L17.5 7.70833V16.875C17.5 17.317 17.3244 17.7409 17.0118 18.0535C16.6993 18.3661 16.2754 18.5417 15.8333 18.5417H4.16667C3.72464 18.5417 3.30072 18.3661 2.98816 18.0535C2.67559 17.7409 2.5 17.317 2.5 16.875V7.70833Z"
        stroke="#6B7280"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.5 18.5404V10.207H12.5V18.5404"
        stroke="#6B7280"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default IconHouse;
