import { Fragment } from "react";
import { Popover, Transition } from "@headlessui/react";
import { AiOutlineUndo } from "react-icons/ai";
import { MdOutlineKeyboardArrowDown } from "react-icons/md";
import Typography from "../Typography";
import IconWrapper from "../IconWrapper";
import Label from "../Label";

type IProps = {
  item: any;
  editLead: Function;
  archive: Function;
  convertToClient: Function;
  iconWidth?: number;
};

export function DeleteMenu({
  editLead,
  convertToClient,
  archive,
  item,
}: IProps) {
  return (
    <Popover className="relative flex items-center">
      <Popover.Button className="group mt-1">
        <IconWrapper
          name="more-vertical"
          className="group-hover:[&>svg>path]:!stroke-[#9333EA]"
          width={20}
        />
      </Popover.Button>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Popover.Panel className="z-20 absolute right-[1rem] top-0 w-[10rem] origin-top-right rounded-lg bg-white shadow-lg">
          {({ close }) => (
            <>
              <div
                className="cursor-pointer flex hover:bg-gray-50 rounded-t-md p-3 gap-3"
                onClick={() => {
                  close();
                  editLead(item);
                }}
              >
                <IconWrapper name="edit" width={16} />
                <Typography variant="body" color="primary">
                  Edit
                </Typography>
              </div>
              {item.isArchived === 0 &&
                (item.isClient ? (
                  <div
                    className="cursor-pointer flex items-center gap-[8px] hover:bg-gray-50 px-3 rounded-b-md text-green-600 gap-3"
                    onClick={() => {
                      close();
                      convertToClient(item, false);
                    }}
                  >
                    <AiOutlineUndo className="text-16 mb-[2px]" />
                    <Typography variant="body">Revert to Lead</Typography>
                  </div>
                ) : (
                  <div
                    className="cursor-pointer flex items-center hover:bg-gray-50 p-3 gap-3"
                    onClick={() => {
                      close();
                      convertToClient(item, true);
                    }}
                  >
                    <IconWrapper name="user-check" width={16} />
                    <Typography variant="body" color="primary">
                      Convert to Client
                    </Typography>
                  </div>
                ))}
              {item.isArchived ? (
                <div
                  className="cursor-pointer flex items-center gap-2 rounded-b-md hover:bg-gray-50 p-3 text-green-600 gap-3"
                  onClick={() => {
                    close();
                    archive(item);
                  }}
                >
                  <AiOutlineUndo className="text-16" />
                  <Typography variant="body">Unarchive</Typography>
                </div>
              ) : (
                <div
                  className="cursor-pointer flex items-center hover:bg-gray-50 rounded-b-md p-3 text-rose-600 gap-3"
                  onClick={() => {
                    close();
                    archive(item);
                  }}
                >
                  <IconWrapper name="archive" width={16} />
                  <Typography variant="body">Archive</Typography>
                </div>
              )}
            </>
          )}
        </Popover.Panel>
      </Transition>
    </Popover>
  );
}

type ILikeProps = {
  item: any;
  setPriority: Function;
};

export function LikeMenu({ setPriority, item }: ILikeProps) {
  return (
    <Popover className="relative">
      <Popover.Button>
        {item.rating === "High Priority" ? (
          <Typography
            variant="table-row-content"
            className="text-center rounded-xl px-2 py-1 bg-yellow-50 text-yellow-600 whitespace-nowrap w-full"
          >
            High Priority
          </Typography>
        ) : item.rating === "In Contact" ? (
          <Typography
            variant="table-row-content"
            className="text-center rounded-xl px-2 py-1 bg-green-50 text-green-600 whitespace-nowrap w-full"
          >
            Contacted
          </Typography>
        ) : (
          <Typography
            variant="table-row-content"
            className="text-center rounded-xl px-2 py-1 bg-gray-50 text-gray-600 whitespace-nowrap w-full"
          >
            Uncontacted
          </Typography>
        )}
      </Popover.Button>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Popover.Panel className="flex flex-col z-20 absolute top-[2rem] origin-top-right rounded-b-lg bg-white shadow-lg">
          {({ close }) => (
            <>
              <div
                className="cursor-pointer hover:bg-gray-50 p-3"
                onClick={() => {
                  close();
                  setPriority(item, "Uncontacted");
                }}
              >
                <Label
                  variant="contained"
                  color="gray-light"
                  size="small"
                  title="Uncontacted"
                  className="rounded-xl py-1"
                />
              </div>
              <div
                className="cursor-pointer hover:bg-gray-50 p-3"
                onClick={() => {
                  close();
                  setPriority(item, "In Contact");
                }}
              >
                <Label
                  variant="contained"
                  color="success-light"
                  size="small"
                  title="In Contact"
                  className="rounded-xl py-1"
                />
              </div>
              <div
                className="cursor-pointer hover:bg-gray-50 p-3"
                onClick={() => {
                  close();
                  setPriority(item, "High Priority");
                }}
              >
                <Label
                  variant="contained"
                  color="warning"
                  size="small"
                  title="High Priority"
                  className="rounded-xl py-1"
                />
              </div>
            </>
          )}
        </Popover.Panel>
      </Transition>
    </Popover>
  );
}

type IStatusProps = {
  item: any;
  setStatus: Function;
};

export function StatusMenu({ setStatus, item }: IStatusProps) {
  return (
    <Popover className="relative">
      <Popover.Button className="flex justify-between items-center w-28 px-2 py-1 rounded-lg gap-1">
        <Label
          variant="contained"
          color="gray-light"
          size="small"
          className="rounded-xl"
          title={
            <div className="flex items-center justify-between w-20">
              <IconWrapper
                width={14}
                name="heart-beat"
                className={`
                ${
                  item.leadStatus === `Active`
                    ? `[&>svg>path]:!stroke-[#22C55E]`
                    : item.leadStatus === `Waiting`
                    ? `[&>svg>path]:!stroke-[#F97316]`
                    : `[&>svg>path]:!stroke-[#F43F5E]`
                }`}
              />
              <Typography variant="table-row-content">
                {item.leadStatus}
              </Typography>
              <MdOutlineKeyboardArrowDown width={20} />
            </div>
          }
        />
      </Popover.Button>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Popover.Panel className="z-20 absolute left-2 top-8 origin-top-left rounded-b-lg bg-white shadow-lg">
          {({ close }) => (
            <>
              <div
                className="cursor-pointer flex hover:bg-gray-50 rounded-t-md p-3 gap-2 items-center"
                onClick={() => {
                  close();
                  setStatus(item, "Active");
                }}
              >
                <IconWrapper
                  name="heart-beat"
                  className="[&>svg>path]:!stroke-[#22C55E]"
                  width={14}
                />
                <Typography variant="table-row-content" color="primary">
                  Active
                </Typography>
              </div>
              <div
                className="cursor-pointer flex hover:bg-gray-50 p-3 gap-2 items-center"
                onClick={() => {
                  close();
                  setStatus(item, "Waiting");
                }}
              >
                <IconWrapper
                  name="heart-beat"
                  className="[&>svg>path]:!stroke-[#F97316]"
                  width={14}
                />
                <Typography variant="table-row-content" color="primary">
                  Waiting
                </Typography>
              </div>
              <div
                className="cursor-pointer flex hover:bg-gray-50 rounded-b-md p-3 gap-2 items-center"
                onClick={() => {
                  close();
                  setStatus(item, "Lost");
                }}
              >
                <IconWrapper
                  name="heart-beat"
                  className="[&>svg>path]:!stroke-[#F43F5E]"
                  width={14}
                />
                <Typography variant="table-row-content" color="primary">
                  Lost
                </Typography>
              </div>
            </>
          )}
        </Popover.Panel>
      </Transition>
    </Popover>
  );
}

type IMyListingStatusProps = {
  datarow: any;
  item: any;
  setStatus: Function;
};

type IMyListingStatusTextProps = {
  datarow: any;
  item: any;
};

export function MyListingStatusMenu({
  datarow,
  item,
  setStatus,
}: IMyListingStatusProps) {
  return (
    <>
      <Popover as="div" className="relative">
        <Popover.Button>
          {({ open }) => (
            <>
              {/* for desktop */}
              <div
                className={`hidden md:flex items-center gap-2 px-[8px] py-[2px] rounded-[6px] ${
                  datarow[item.slug] === "Active" &&
                  new Date() < new Date(datarow.listingExpirationDate)
                    ? "bg-[#DCFCE7] !text-[#16A34A]"
                    : datarow[item.slug] === "Pending"
                    ? "bg-orange-100 text-orange-500"
                    : datarow[item.slug] === "Closed"
                    ? "bg-gray-100 shadow-sm"
                    : !datarow.isClaimed
                    ? "bg-gray-50 border border-gray-600 text-gray-600 shadow-sm"
                    : "bg-gray-50 text-[#9CA3AF] shadow-sm"
                }`}
              >
                <span className="text-[14px]">
                  {new Date() > new Date(datarow.listingExpirationDate) ? "Expired" : (datarow ? (datarow.isClaimed ? datarow[item.slug] : "Confirm Status") : "")}
                </span>
                <IconWrapper
                  name="arrow-up"
                  width={16}
                  innerStroke={`${
                    datarow[item.slug] === "Active"
                      ? "#16A34A"
                      : datarow[item.slug] === "Pending"
                      ? "#EA580C"
                      : datarow[item.slug] === "Closed"
                      ? "#4B5563"
                      : !datarow.isClaimed
                      ? "#3C3C3C"
                      : "#9CA3AF"
                  }`}
                  className={` ${
                    open ? "-rotate-180 transform" : "rotate-90 "
                  }`}
                />
              </div>
              {/* for mobile */}
              <div
                className={`flex md:hidden items-center gap-2 px-[8px] py-[2px] rounded-[6px] ${
                  datarow[item.statusSlug] === "Active"
                    ? "bg-[#DCFCE7] !text-[#16A34A]"
                    : datarow[item.statusSlug] === "Pending"
                    ? "bg-orange-100 text-orange-500"
                    : datarow[item.statusSlug] === "Closed"
                    ? "bg-white shadow-sm"
                    : "text-[#18295266] shadow-sm"
                }`}
              >
                <span className="text-[14px]">
                  {new Date() > new Date(datarow.listingExpirationDate)
                    ? "Expired"
                    : datarow
                    ? datarow[item.statusSlug]
                    : ""}
                </span>
                <IconWrapper
                  name="arrow-up"
                  width={16}
                  innerStroke={`${
                    datarow[item.statusSlug] === "Active"
                      ? "#16A34A"
                      : datarow[item.statusSlug] === "Pending"
                      ? "#EA580C"
                      : datarow[item.statusSlug] === "Closed"
                      ? "#4B5563"
                      : "#18295266"
                  }`}
                  className={` ${
                    open ? "-rotate-180 transform" : "rotate-90 "
                  }`}
                />
              </div>
            </>
          )}
        </Popover.Button>
        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Popover.Panel className="border border-gray-100 z-20 absolute left-0 md:right-0 w-fit origin-top-right divide-y divide-gray-100 rounded-md overflow-hidden bg-white shadow-lg">
            {({ close }) => (
              <>
                <div
                  className="cursor-pointer flex hover:bg-neutral-100 p-[12px]"
                  onClick={() => {
                    close();
                    setStatus(datarow, "Active");
                  }}
                >
                  <Typography
                    className={`mr-2 text-[#16A34A]`}
                    variant="table-row-content"
                  >
                    Active
                  </Typography>
                </div>
                <div
                  className="cursor-pointer flex  hover:bg-neutral-100 p-[12px]"
                  onClick={() => {
                    close();
                    setStatus(datarow, "Pending");
                  }}
                >
                  <Typography
                    className={`mr-2 text-orange-500`}
                    variant="table-row-content"
                  >
                    Pending
                  </Typography>
                </div>
                <div
                  className="cursor-pointer flex hover:bg-neutral-100 p-[12px]"
                  onClick={() => {
                    close();
                    setStatus(datarow, "Closed");
                  }}
                >
                  <Typography
                    className={`mr-2`}
                    variant="table-row-content"
                    color="primary"
                  >
                    Closed
                  </Typography>
                </div>
                <div
                  className="cursor-pointer flex hover:bg-neutral-100 p-[12px]"
                  onClick={() => {
                    close();
                    setStatus(datarow, "Withdrawn");
                  }}
                >
                  <Typography
                    className={`mr-2 text-[#18295266]`}
                    variant="table-row-content"
                  >
                    Withdrawn
                  </Typography>
                </div>
              </>
            )}
          </Popover.Panel>
        </Transition>
      </Popover>
    </>
  );
}

export function MyListingStatus({ datarow, item }: IMyListingStatusTextProps) {
  return (
    <>
      <div className="flex w-auto">
        <div
          className={`items-center gap-2 px-[8px] py-[2px] rounded-[6px] ${
            datarow[item.slug] === "Active"
              ? "bg-[#DCFCE7] !text-[#16A34A]"
              : datarow[item.slug] === "Pending"
              ? "bg-orange-100 text-orange-500"
              : datarow[item.slug] === "Closed"
              ? "bg-gray-100 shadow-sm"
              : "bg-gray-50 text-[#9CA3AF] shadow-sm"
          }`}
        >
          <span className="text-[14px]">{datarow[item.slug]}</span>
        </div>
      </div>
    </>
  );
}

export function MyListingSimpleStatus({ title }: { title: string }) {
  return (
    <>
      <div className="flex w-auto">
        <div
          className={`items-center gap-2 px-[8px] py-[2px] rounded-[6px] ${
            title === "Active"
              ? "bg-[#DCFCE7] !text-[#16A34A]"
              : title === "Pending"
              ? "bg-orange-100 text-orange-500"
              : title === "Closed"
              ? "bg-gray-100 shadow-sm"
              : "bg-gray-50 text-[#9CA3AF] shadow-sm"
          }`}
        >
          <span className="text-[14px]">{title}</span>
        </div>
      </div>
    </>
  );
}
