import defaultAvatar from "@/assets/images/default_avatar.jpg";
import VerifyImageLayer1 from "@/assets/images/verify_image1.png";
import VerifyImageLayer2 from "@/assets/images/verify_image2.png";
import { Button } from "@/components/baseComponents/Button";
import Typography from "@/components/baseComponents/Typography";
import { useEffect, useState } from "react";
import IconWrapper from "../../components/baseComponents/IconWrapper";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { getSubscriptionDetails, getSubscriptionStatus, getUser, requestSubscriptionLink, requestVerificationLink } from "../../redux/user/userSlice";

const VerifyDefault = ({ subscribe }: { subscribe: () => void }) => {
    return (
        <div className="flex flex-row w-full gap-6 rounded-lg bg-white drop-shadow-md">
            <div className="flex-1 pt-6 pb-6 pl-6 pr-6 2xl:pr-0">
                <div className="flex">
                    <IconWrapper name="verified-filled" width={36} height={36} />
                </div>
                <Typography variant="h2" color="primary">
                    Upgrade to RealtyView Verified
                </Typography>
                <Typography variant="body" color="primary" className="w-full">
                    When you upgrade to Verified for $20/Month, not only do you build trust within the RealtyView community; You also unlock many new features to help you optimize
                    your productivity.
                </Typography>
                <div className="my-6 gap-y-6 grid">
                    <div className="flex">
                        <span className="pt-1 bg-indigo-50 w-[32px] h-[32px] rounded-md">
                            <IconWrapper name="verified" className="stroke-indigo-600" />
                        </span>
                        <span className="flex-1 ml-3">
                            <Typography variant="h4" color="primary">
                                Get Verified Badge
                            </Typography>
                            <Typography variant="button2" color="secondary">
                                Enhance your credibility and visibility on the platform, gaining instant trust from followers and clients.
                            </Typography>
                        </span>
                    </div>
                    <div className="flex">
                        <span className="pt-1 bg-indigo-50 w-[32px] h-[32px] rounded-md">
                            <IconWrapper name="ads" className="stroke-indigo-600" />
                        </span>
                        <span className="flex-1 ml-3">
                            <Typography variant="h4" color="primary">
                                Ads Free Experience
                            </Typography>
                            <Typography variant="button2" color="secondary">
                                Enjoy an uninterrupted experience with zero ads - focus on what matters most without distractions.
                            </Typography>
                        </span>
                    </div>
                    <div className="flex">
                        <span className="pt-1 bg-indigo-50 w-[32px] h-[32px] rounded-md">
                            <IconWrapper name="search" className="stroke-indigo-600" />
                        </span>
                        <span className="flex-1 ml-3">
                            <Typography variant="h4" color="primary">
                                Activate Search Bar
                            </Typography>
                            <Typography variant="button2" color="secondary">
                                Quick and easy access to search functionality directly to find information.
                            </Typography>
                        </span>
                    </div>
                </div>
                <div className="my-6 border-t"></div>
                <div className="grid gap-4 grid-cols-2">
                    <div className="flex">
                        <span className="pt-1">
                            <IconWrapper name="calendar" className="stroke-indigo-600" />
                        </span>
                        <span className="flex-1 ml-3">
                            <Typography variant="h4" color="primary">
                                Calendar Sync
                            </Typography>
                            <Typography variant="button2" color="secondary">
                                Auto-sync and integrating events into your RealtyView.
                            </Typography>
                        </span>
                    </div>
                    <div className="flex">
                        <span className="pt-1">
                            <IconWrapper name="check-circle" className="stroke-indigo-600" />
                        </span>
                        <span className="flex-1 ml-3">
                            <Typography variant="h4" color="primary">
                                Task Manager
                            </Typography>
                            <Typography variant="button2" color="secondary">
                                Easily organize, access, and share files across platform.
                            </Typography>
                        </span>
                    </div>
                    <div className="flex">
                        <span className="pt-1">
                            <IconWrapper name="folder" className="stroke-indigo-600" />
                        </span>
                        <span className="flex-1 ml-3">
                            <Typography variant="h4" color="primary">
                                File Manager
                            </Typography>
                            <Typography variant="button2" color="secondary">
                                Easily organize, access, and share files across platform.
                            </Typography>
                        </span>
                    </div>
                    <div className="flex">
                        <span className="pt-1">
                            <IconWrapper name="notebook" className="stroke-indigo-600" />
                        </span>
                        <span className="flex-1 ml-3">
                            <Typography variant="h4" color="primary">
                                Notebook
                            </Typography>
                            <Typography variant="button2" color="secondary">
                                Seamless note-taking all in one place.
                            </Typography>
                        </span>
                    </div>
                </div>
                <Button className="mt-6 w-full" color="primary" onClick={subscribe}>
                    Get Verified
                </Button>
            </div>
            <div className="flex-1 pt-6 pb-6 pr-6 hidden 2xl:flex">
                <span className="flex w-full p-1 rounded-md bg-cover bg-center" style={{ backgroundImage: `url(${VerifyImageLayer2})` }}>
                    <span className="w-full bg-center bg-contain bg-no-repeat" style={{ backgroundImage: `url(${VerifyImageLayer1})` }}></span>
                </span>
            </div>
        </div>
    );
};

interface ISubscriptionDetailsProps {
    isLoaded?: boolean;
    isSubscribed?: boolean;
    isVerified?: boolean;
    name?: string;
    license?: string;
    avatar: string;
    status?: string;
    nextInvoice?: string;
    amount?: string;
    card?: string;
    history?: { date: string; timestamp: number; description: string; status: string; amount: string; link: string }[];
    verify?: () => void;
    resubscribe?: () => void;
}
const SubscriptionDetails = ({ isLoaded, isSubscribed, isVerified, verify, resubscribe, ...data }: ISubscriptionDetailsProps) => {
    const [banner, setBanner] = useState("" as "invoice" | "resubscribe");
    const [size, setSize] = useState("" as "sm" | "md" | "lg" | "xl" | "2xl");
    const [history, setHistory] = useState(data.history);
    const [sortValue, setSortValue] = useState({
        date: "desc" as "asc" | "desc",
        amount: "desc" as "asc" | "desc"
    });
    const getScreenSize = () => {
        const screenWidth = window.outerWidth;
        if (screenWidth >= 1536) return "2xl";
        else if (screenWidth >= 1280) return "xl";
        else if (screenWidth >= 1024) return "lg";
        else if (screenWidth >= 768) return "md";
        return "sm";
    };
    const toggleSort = (field: "date" | "amount") => {
        const newSortValue = { ...sortValue };
        const sort = (newSortValue[field] = newSortValue[field] !== "asc" ? "asc" : "desc");
        setSortValue(newSortValue);

        let newHistory = [...history!];

        if (field === "date") {
            newHistory.sort(({ timestamp: a }, { timestamp: b }) => (sort === "desc" ? b - a : a - b));
        } else if (field === "amount") {
            newHistory.sort(({ amount: a }, { amount: b }) => (sort === "desc" ? parseFloat(b) - parseFloat(a) : parseFloat(a) - parseFloat(b)));
        }

        setHistory(newHistory);
    };

    useEffect(() => {
        if (isLoaded) {
            setHistory(data.history?.sort(({ timestamp: a }, { timestamp: b }) => b - a));
            setBanner(data.nextInvoice && data.status !== "canceled" ? "invoice" : "resubscribe");
        }
    }, [isLoaded]);

    useEffect(() => {
        setSize(getScreenSize());
    }, [window.outerWidth]);

    return (
        <div className="flex flex-col w-full gap-6 p-6 rounded-lg bg-white drop-shadow-md">
            <Typography variant="h3" color="primary">
                Subscription Details
            </Typography>
            {isLoaded && isSubscribed && !isVerified && (
                <div className="flex flex-row p-4 gap-2 border rounded-lg bg-yellow-50 border-yellow-200">
                    <span>
                        <IconWrapper name="alert-circle" className="stroke-yellow-700" />
                    </span>
                    <span>
                        You have successfully subscribed to the Verified plan.{" "}
                        <a onClick={verify} className="underline cursor-pointer">
                            Please Verify your identity here.
                        </a>
                    </span>
                </div>
            )}
            <div className="flex flex-row border rounded-lg p-4">
                <div className="relative">
                    <img src={data.avatar ?? defaultAvatar} onError={(e) => e.currentTarget.src = defaultAvatar} className="aspect-square !w-2FO0 h-20 rounded-full border-2 border-white" />
                    {isSubscribed && isVerified && (
                        <IconWrapper
                            name="verified-filled"
                            innerStroke={isSubscribed && data.status === "active" ? "#4F46E5" : "#6B7280"}
                            className="absolute bottom-0.5 right-0.5"
                        />
                    )}
                </div>
                <div className="flex items-center ml-3">
                    <div>
                        <div className="font-semibold">{data.name}</div>
                        <Typography variant="button1" color="secondary" className="font-sm bg-gray-100 p-1 pl-2 pr-2 rounded-xl mt-[2px]">
                            {data.license}
                        </Typography>
                    </div>
                </div>
            </div>
            {/xl|lg/.test(size) && (
                <Typography variant="h4" color="primary">
                    Subscription History
                </Typography>
            )}
            {banner === "invoice" && (
                <div className="flex flex-row p-4 gap-2 border rounded-lg bg-blue-50 border-blue-200">
                    <span>
                        <IconWrapper name="credit-card" className="stroke-indigo-600" />
                    </span>
                    <span>
                        Your next subscription is ${data.amount} on {data.nextInvoice}. Automatically paid with the card ending in •••• {data.card}.
                    </span>
                </div>
            )}
            {banner === "resubscribe" && (
                <div className="flex flex-row p-4 gap-2 border rounded-lg bg-orange-50 border-orange-200">
                    <span>
                        <IconWrapper name="alert-triangle" className="stroke-orange-700" />
                    </span>
                    <span className="flex-1">
                        You cancelled {history?.[0].description} recently. Resubscribe for ${data.amount} per month to unlock all features.
                    </span>
                    <span className="flex flex-col justify-center">
                        <Button variant="text" className="text-white bg-button-primary" onClick={resubscribe}>
                            Resubscribe
                        </Button>
                    </span>
                </div>
            )}
            {/md|sm/.test(size) && (
                <Typography variant="h4" color="primary">
                    Subscription History
                </Typography>
            )}
            {/xl|lg/.test(size) ? (
                <table>
                    <thead>
                        <tr>
                            <th className="text-left font-normal border-b pt-2 pb-2 text-gray-500">
                                <span className="flex">
                                    <span>Date</span>
                                    <span onClick={() => toggleSort("date")} className={`ml-1 cursor-pointer rotate-${sortValue.date === "desc" ? "0" : "180"}`}>
                                        <IconWrapper name="arrow-down-tail" />
                                    </span>
                                </span>
                            </th>
                            <th className="text-left font-normal border-b pt-2 pb-2 text-gray-500">Description</th>
                            <th className="text-left font-normal border-b pt-2 pb-2 text-gray-500">Status</th>
                            <th className="text-left font-normal border-b pt-2 pb-2 text-gray-500">
                                <span className="flex">
                                    <span>Amount</span>
                                    <span onClick={() => toggleSort("amount")} className={`ml-1 cursor-pointer rotate-${sortValue.amount === "desc" ? "0" : "180"}`}>
                                        <IconWrapper name="arrow-down-tail" />
                                    </span>
                                </span>
                            </th>
                            <th className="text-left font-normal border-b pt-2 pb-2 text-gray-500"></th>
                        </tr>
                    </thead>
                    <tbody>
                        {history?.map((item, index) => (
                            <tr key={index}>
                                <th className="text-left border-b pt-4 pb-4">
                                    <span className="flex">
                                        <span>{item.date}</span>
                                        {item.link && (
                                            <span className="ml-2 cursor-pointer" onClick={() => window.location.replace(item.link)}>
                                                <IconWrapper name="link-external" />
                                            </span>
                                        )}
                                    </span>
                                </th>
                                <th className="text-left font-normal border-b pt-4 pb-4">{item.description}</th>
                                <th className={`text-left font-normal border-b pt-4 pb-4 ${item.status.toLowerCase() === "paid" ? "text-green-600" : "text-orange-600"}`}>
                                    {item.status}
                                </th>
                                <th className="text-left font-normal border-b pt-4 pb-4">${item.amount}</th>
                                <th className="text-right font-normal border-b pt-4 pb-4">
                                    {item.status === "Canceled" && banner === "resubscribe" && (
                                        <span className="text-indigo-600 cursor-pointer hover:underline" onClick={resubscribe}>
                                            Resubscribe
                                        </span>
                                    )}
                                </th>
                            </tr>
                        ))}
                    </tbody>
                </table>
            ) : (
                <div>
                    <div className="font-normal border-b pt-2 pb-2 text-gray-500 flex">
                        <span>Date</span>
                        <span onClick={() => toggleSort("date")} className={`ml-1 rotate-${sortValue.date === "desc" ? "0" : "180"}`}>
                            <IconWrapper name="arrow-down-tail" />
                        </span>
                    </div>
                    {history?.map((item, index) => (
                        <div key={index} className="grid grid-cols-2 grid-rows-3 border-b pt-4 pb-4 gap-4">
                            <div className="font-semibold flex">
                                <span>{item.date}</span>
                                {item.link && (
                                    <span className="ml-2 cursor-pointer" onClick={() => window.location.replace(item.link)}>
                                        <IconWrapper name="link-external" />
                                    </span>
                                )}
                            </div>
                            <div className="text-right text-indigo-600 cursor-pointer hover:underline" onClick={resubscribe}>
                                {item.status === "Canceled" && banner === "resubscribe" && "Resubscribe"}
                            </div>
                            <div>{item.description}</div>
                            <div></div>
                            <div className={/^paid/i.test(item.status) ? "text-green-600" : "text-orange-600"}>{item.status}</div>
                            <div className="text-right">${item.amount}</div>
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
};

const Verify = () => {
    const dispatch = useAppDispatch();
    const user = useAppSelector(getUser);
    const [isLoaded, setLoaded] = useState(false);
    const [status, setStatus] = useState({ isVerified: false, isSubscribed: false });
    const [details, setDetails] = useState({
        name: `${user.agent.firstName} ${user.agent.lastName}`,
        license: `${user.agent.licenseState} ${user.agent.licenseType}`,
        avatar: user.agent.avatarURL ?? defaultAvatar
    } as ISubscriptionDetailsProps);
    const getSubscriptionLink = (userID: string) => {
        dispatch(requestSubscriptionLink(userID)).then(({ payload }) => {
            const { url } = payload as { url?: string };
            if (url) window.location.replace(url);
        });
    };
    const getVerificationLink = (userID: string) => {
        dispatch(requestVerificationLink(userID)).then(({ payload }) => {
            const { url } = payload as { url?: string };
            if (url) window.location.replace(url);
        });
    };

    useEffect(() => {
        dispatch(getSubscriptionStatus(user._id)).then(({ payload }) => {
            const { isSubscribed, isVerified } = payload as typeof status;
            setStatus(() => ({ isVerified, isSubscribed }));
        });
    }, [user]);

    useEffect(() => {
        if (!isLoaded) {
            dispatch(getSubscriptionDetails(user._id)).then(({ payload }) => {
                const { message, success, ...history } = payload;
                setLoaded(true);
                setDetails((details) => ({ ...details, ...history }));
            });
        }
    }, [isLoaded]);

    return !status.isSubscribed ? (
        <VerifyDefault subscribe={() => getSubscriptionLink(user._id)} />
    ) : (
        <SubscriptionDetails
            verify={() => getVerificationLink(user._id)}
            resubscribe={() => getSubscriptionLink(user._id)}
            isLoaded={isLoaded}
            isSubscribed={status.isSubscribed}
            isVerified={status.isVerified}
            {...details}
        />
    );
};
export default Verify;
