import { Fragment, useEffect, useState } from "react";
import Typography from "@/components/baseComponents/Typography";
import TextField from "@/components/baseComponents/TextField";
import Select from "@/components/baseComponents/Select";

import { Button } from "@/components/baseComponents/Button";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "@/redux/hooks";
import { CreateUserDto, ForgotPasswordDto, LogInDto, ResetPasswordDto, SelectType } from "@/shared/interfaces/interfaces";
import { forgotPassword, getStates, resetPassword, signIn, signUp, updateStates } from "@/redux/user/userSlice";
import validation from "@/shared/services/validation";
import { notify } from "@/shared/services/notify";
import { Dialog, Transition } from "@headlessui/react";
import Logo from "@/assets/images/logo_black.svg";
import { Cookies, useCookies } from "react-cookie";
import { IoMdClose } from "react-icons/io";
import { LuEye } from "react-icons/lu";
import { LuEyeOff } from "react-icons/lu";
import { AUTH } from "@/shared/config/constants";

type IProps = {
  open: boolean;
  closeDrawer: Function;
  type: string;
  listingId?: string;
  rememberTypeState?: boolean;
};

const AuthDrawer = (props: IProps) => {
  const cookies = new Cookies();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const states = useAppSelector(getStates);

  const [firstName, setFirstName] = useState<string>("");
  const [lastName, setLastName] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [confirm, setConfirm] = useState<string>("");
  const [licenseState, setLicenseState] = useState<SelectType>({ value: "1", label: "" });
  const [licenseType, setLicenseType] = useState<SelectType>({ value: "1", label: "" });

  const [errorFirstName, setErrorFirstName] = useState<boolean>(false);
  const [errorLastName, setErrorLastName] = useState<boolean>(false);
  const [errorEmail, setErrorEmail] = useState<boolean>(false);
  const [errorConfirm, setErrorConfirm] = useState<boolean>(false);
  const [errorMessagePassword, setErrorMessagePassword] = useState<string>("");
  const [cookie, setCookie] = useCookies(["rememberMyEmailAddress", "rememberedEmailAddress"]);
  const [rememberMyEmailAddress, setRememberMyEmailAddress] = useState<boolean>(cookie.rememberMyEmailAddress || false);
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState<boolean>(false);

  const [licenseStateList, setLicenseStateList] = useState<Array<any>>([]);
  const [licenseTypeList, setLicenseTypeList] = useState<Array<any>>([]);

  const [type, setType] = useState<string>(props.type);
  const [code, setCode] = useState<string>("");

  const [isLink, setIsLink] = useState<boolean>(true);
  const [title, setTitle] = useState<string>("Sign In");
  const [description, setDescription] = useState<string>("Don’t have an account? Sign up");

  const changeEmail = (value: string) => {
    setEmail(value);
    setErrorEmail(validation.IsInvalidEmail(value));
  };
  const changeFirstName = (value: string) => {
    setFirstName(value);
    setErrorFirstName(validation.IsEmptyString(value));
  };
  const changeLastName = (value: string) => {
    setLastName(value);
    setErrorLastName(validation.IsEmptyString(value));
  };
  const changeConfirm = (value: string) => {
    setConfirm(value);
    setErrorConfirm(validation.IsDifferentString(password, value));
  };
  const changeLicenseState = (value: any) => {
    setLicenseState(value);
    let temp = states.filter((state: any) => state.name === value.value)[0];
    let tempTypes = [];
    if (temp.licenseType1) {
      tempTypes.push({
        value: temp.licenseType1,
        label: temp.licenseType1,
      });
    }
    if (temp.licenseType2) {
      tempTypes.push({
        value: temp.licenseType2,
        label: temp.licenseType2,
      });
    }
    if (temp.licenseType3) {
      tempTypes.push({
        value: temp.licenseType3,
        label: temp.licenseType3,
      });
    }
    setLicenseTypeList(tempTypes);
  };

  const changePassword = (value: string) => {
    setPassword(value);
    if (!validation.emptyPassword(value)) {
      setErrorMessagePassword("This field is required");
      return true;
    }
    if (!validation.containsNumber(value)) {
      setErrorMessagePassword("Should contain at least a number");
      return true;
    }
    if (!validation.containsUppercase(value)) {
      setErrorMessagePassword("Should contain at least a uppercase character");
      return true;
    }
    if (!validation.containsSpecial(value)) {
      setErrorMessagePassword("Should contain at least a special character");
      return true;
    }
    if (!validation.minLength(value)) {
      setErrorMessagePassword("Should be at least 8 characters");
      return true;
    }
    setErrorMessagePassword("");
    return false;
  };

  const registerUser = () => {
    const passwordHasError = changePassword(password);
    setErrorFirstName(validation.IsEmptyString(firstName));
    setErrorLastName(validation.IsEmptyString(lastName));
    setErrorEmail(validation.IsInvalidEmail(email));
    if (validation.IsDifferentString(password, confirm)) {
      setErrorConfirm(true);
      return;
    }

    if (!validation.IsEmptyString(firstName) && !validation.IsEmptyString(lastName) && !validation.IsInvalidEmail(email) && !validation.IsDifferentString(password, confirm) && !passwordHasError) {
      let data: CreateUserDto = {
        firstName: firstName,
        lastName: lastName,
        email: email.toLocaleLowerCase(),
        password: password,
        licenseState: licenseState.label,
        licenseType: licenseType.label,
        listingId: props.listingId,
      };
      dispatch(signUp(data)).then((res) => {
        try {
          if (res.payload.success) navigate("/profile", { state: { id: "My Info" } });
          notify(res.payload.success, res.payload.message);
          cookies.remove("lastViewedPublicListingId", { path: "/" });
        } catch (e) {
          notify(false, "Something went wrong.");
        }
      });
    }
  };

  useEffect(() => {
    dispatch(updateStates()).then((res) => {
      setLicenseState({
        value: res.payload.states[0].name,
        label: res.payload.states[0].name,
      });
      setLicenseType({
        value: res.payload.states[0].licenseType1,
        label: res.payload.states[0].licenseType1,
      });
      let tempTypes = [];
      if (res.payload.states[0].licenseType1) {
        tempTypes.push({
          value: res.payload.states[0].licenseType1,
          label: res.payload.states[0].licenseType1,
        });
      }
      if (res.payload.states[0].licenseType2) {
        tempTypes.push({
          value: res.payload.states[0].licenseType2,
          label: res.payload.states[0].licenseType2,
        });
      }
      if (res.payload.states[0].licenseType3) {
        tempTypes.push({
          value: res.payload.states[0].licenseType3,
          label: res.payload.states[0].licenseType3,
        });
      }
      setLicenseTypeList(tempTypes);
      let tempStates = res.payload.states
        .map((state: any) => {
          return {
            value: state.name,
            label: state.name,
          };
        })
        .sort((a: any, b: any) => {
          if (a.value < b.value) {
            return -1;
          }
          if (a.value > b.value) {
            return 1;
          }
          return 0;
        });
      setLicenseStateList(tempStates);
    });
  }, []);

  const goFogotPassword = () => {
    setType("forgot-password");
    // navigate("/auth/forgot-password");
  };

  const logIn = () => {
    if (email !== "" && password !== "") {
      let data: LogInDto = {
        email: email.toLocaleLowerCase(),
        password: password,
      };
      dispatch(signIn(data)).then((res) => {
        try {
          if (res.payload.success) {
            setCookie("rememberMyEmailAddress", rememberMyEmailAddress, { path: "/", expires: new Date(Date.now() + 24 * 60 * 60 * 1000) });

            if (rememberMyEmailAddress) {
              setCookie("rememberedEmailAddress", email, { path: "/", expires: new Date(Date.now() + 24 * 60 * 60 * 1000) });
            } else {
              setCookie("rememberedEmailAddress", "", { path: "/", expires: new Date(Date.now() + 24 * 60 * 60 * 1000) });
            }
            const userType: AUTH = res.payload.user.userType as AUTH;
            const requestURL = window.innerWidth <= 640 ? "/dashboard" : window.localStorage.getItem("requestURL");
            const urlItems = requestURL?.split("/");
            window.localStorage.removeItem("requestURL");
            if (requestURL && urlItems && urlItems.length > 1 && ((urlItems[1] === "admin" && userType === AUTH.ADMIN) || urlItems[1] !== "admin")) {
              navigate(requestURL);
            } else {
              if (userType === AUTH.ADMIN) {
                navigate("/admin");
              } else {
                navigate("/");
              }
            }
          }
          notify(res.payload.success, res.payload.message);
        } catch (e) {
          console.log(e);
          notify(false, "Something went wrong");
        }
      });
    }
  };

  const sendCode = () => {
    if (email.length === 0) {
      notify(false, "Email field is required");
    } else {
      let data: ForgotPasswordDto = {
        email: email.toLocaleLowerCase(),
      };
      dispatch(forgotPassword(data)).then((res) => {
        notify(res.payload.success, res.payload.message);
        if (res.payload.success) setType("reset-password"); //navigate('/auth/reset-password')
      });
    }
  };

  useEffect(() => {
    setPassword("");
    setErrorMessagePassword("");
    setErrorConfirm(false);
    setErrorEmail(false);
    setErrorFirstName(false);
    setErrorLastName(false);
    setShowPassword(false);
    setShowConfirmPassword(false);
    setHeaderInformation();
  }, [type]);

  const resetPasswordFunc = () => {
    changePassword(password);
    changeConfirm(confirm);
    if (!validation.IsDifferentString(password, confirm) && !errorMessagePassword) {
      let data: ResetPasswordDto = {
        code: code,
        password: password,
      };
      dispatch(resetPassword(data)).then((res) => {
        notify(res.payload.success, res.payload.message);
        if (res.payload.success) setType("signin"); //navigate('/auth/login')
      });
    }
  };

  const handleClose = () => {
    if (!props.rememberTypeState) {
      setType("signup");
    }
    props.closeDrawer();
  };

  const setHeaderInformation = () => {
    switch (type) {
      case "signup":
        setTitle("Sign Up");
        break;
      case "signin":
        setTitle("Sign In");
        break;
      case "forgot-password":
        setTitle("Forgot your password?");
        break;
      case "renew-password":
        setTitle("Reset Password");
        break;
      default:
        "Title";
    }
    if (type == "signin" || type == "signup") {
      setIsLink(true);
    } else {
      setIsLink(false);
    }
    switch (type) {
      case "signup":
        setDescription("Have an account? Sign In");
        break;
      case "signin":
        setDescription("Don’t have an account? Sign up");
        break;
      case "forgot-password":
        setDescription("Enter your email address and we will send you a link to reset your password.");
        break;
      case "renew-password":
        setDescription(" Enter your email address and we will send you a link to reset your password.");
        break;
    }
  };

  const handleLink = () => {
    if (type == "signin") {
      setType("signup");
    } else {
      setType("signin");
    }
  };
  
  return (
    <Transition.Root show={props.open} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-30 "
        onClose={() => {
          handleClose()
        }}
      >
        <div className="fixed inset-0 overflow-hidden"
          style={{ backgroundColor: 'rgba(0,0,0,0.4)' }}
        >
          <div className="absolute inset-0 overflow-hidden">
            <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full md:pl-10 pl-0 sm:pl-16">
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-500 sm:duration-300"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-500 sm:duration-300"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <Dialog.Panel className="pointer-events-auto h-screen max-sm:w-[414px] md:w-[600px] w-full overflow-y-auto hide-scrollbar ">
                  <div className="flex bg-white w-full h-screen flex-col gap-3 md:px-8 md:py-6 p-4 overflow-y-auto hide-scrollbar shadow">
                    <div className="flex w-full justify-end">
                      <div className="flex gap-3 items-start">
                        <div onClick={() => (handleClose())} className="cursor-pointer">
                          <IoMdClose style={{ height: 24, width: 24 }} className="text-primary hover:text-[#6B7280]" />
                        </div>
                      </div>
                    </div>
                    <div className="flex w-full justify-center">
                      <img src={Logo} alt="Logo" className="scale-[80%]" />
                    </div>
                    <div className="flex flex-col w-full justify-center gap-1 mt-1">
                      <div className="flex justify-center">
                        <Typography variant="h1" className="font-arial">
                          {title}
                        </Typography>
                      </div>
                      {isLink && <div className="flex justify-center cursor-pointer">
                        <Typography className="text-[#4C42D7] hover:text-[#6366F1] disabled:text-[#D1D5DB]" onClick={() => handleLink()} >
                          {description}
                        </Typography>
                      </div>}
                      {!isLink && <div className="flex justify-center text-center">
                        <Typography className="text-[#6B7280] text-[14px] max-w-[360px]">
                          {description}
                        </Typography>
                      </div>}
                    </div>


                    {type === "signup" && (
                      <div className="flex flex-col gap-3">
                        <div>
                          <div className="grid sm:grid-cols-2 grid-cols-1 gap-3 mb-[25px]">
                            <div className="col-span-1 relative">
                              <Typography variant="caption" className="text-secondary">
                                First Name
                              </Typography>
                              <TextField
                                className={`w-full ${errorFirstName ? "outline outline-1 rounded outline-[#E01010]" : ""}`}
                                value={firstName}
                                onChange={(e) => changeFirstName(e.target.value)}
                              />
                              {errorFirstName && (
                                <Typography variant="caption" className="text-[#E01010] absolute mt-[2px]">
                                  This field is required.
                                </Typography>
                              )}
                            </div>
                            <div className="col-span-1 relative">
                              <Typography variant="caption" className="text-secondary mb-[9px]">
                                Last Name
                              </Typography>
                              <TextField className={`w-full ${errorLastName ? "outline outline-1 rounded outline-[#E01010]" : ""}`} value={lastName} onChange={(e) => changeLastName(e.target.value)} />
                              {errorLastName && (
                                <Typography variant="caption" className="text-[#E01010] absolute mt-[2px]">
                                  This field is required.
                                </Typography>
                              )}
                            </div>
                          </div>
                          <div className="mb-[25px] relative">
                            <Typography variant="caption" className="text-secondary mb-[9px]">
                              Email Address
                            </Typography>
                            <TextField className={`w-full ${errorEmail ? "outline outline-1 rounded outline-[#E01010]" : ""}`} value={email} onChange={(e) => changeEmail(e.target.value)} />
                            {errorEmail && (
                              <Typography variant="caption" className="text-[#E01010] absolute mt-[2px]">
                                Invalid Email Address
                              </Typography>
                            )}
                          </div>
                          <div className="grid sm:grid-cols-2 grid-cols-1 gap-3 mb-[25px]">
                            <div className="col-span-1 relative">
                              <Typography variant="caption" className="text-secondary mb-[9px]">
                                Password
                              </Typography>
                              <div className="relative w-full">
                                <TextField
                                  className={`w-full ${errorMessagePassword !== "" ? "outline outline-1 rounded outline-[#E01010]" : ""}`}
                                  type={`${showPassword ? "text" : "password"}`}
                                  value={password}
                                  onChange={(e) => changePassword(e.target.value)}
                                />
                                <button type="button" onClick={() => setShowPassword(!showPassword)} className="absolute inset-y-0 end-0 flex items-center pe-3">
                                  {!showPassword && <LuEye />}
                                  {showPassword && <LuEyeOff />}
                                </button>
                              </div>
                              {errorMessagePassword !== "" && (
                                <Typography variant="caption" className="text-[#E01010] absolute mt-[2px]">
                                  {errorMessagePassword}
                                </Typography>
                              )}
                            </div>
                            <div className="col-span-1 relative">
                              <Typography variant="caption" className="text-secondary mb-[9px]">
                                Confirm Password
                              </Typography>
                              <TextField
                                className={`w-full ${errorConfirm ? "outline outline-1 rounded outline-[#E01010]" : ""}`}
                                type="password"
                                value={confirm}
                                onChange={(e) => changeConfirm(e.target.value)}
                              />
                              {errorConfirm && (
                                <Typography variant="caption" className="text-[#E01010] absolute mt-[2px]">
                                  Passwords must match!
                                </Typography>
                              )}
                            </div>
                          </div>
                          <div className="grid sm:grid-cols-2 grid-cols-1 gap-3">
                            <div className="col-span-1">
                              <Typography variant="caption" className="text-secondary mb-[9px]">
                                License State
                              </Typography>
                              <Select options={licenseStateList} placeholder="" value={licenseState} onChange={(value) => changeLicenseState(value)} />
                            </div>
                            <div className="col-span-1">
                              <Typography variant="caption" className="text-secondary mb-[9px]">
                                License Type
                              </Typography>
                              <Select options={licenseTypeList} placeholder="" value={licenseType} onChange={(value) => setLicenseType(value)} />
                            </div>
                          </div>
                        </div>
                        <div className="flex w-full flex-col">
                          <div className="flex w-full">
                            <Button variant="contained" onClick={() => registerUser()} className="w-full my-4">
                              Sign Up
                            </Button>
                          </div>
                          <div className="flex w-full justify-center text-center">
                            <Typography variant="medium-text" className="text-sm font-arial text-[#9CA3AF]">
                              RealtyView is forever-free. We don’t required your credit card.
                            </Typography>
                          </div>
                        </div>
                      </div>
                    )}
                    {type === "signin" && (
                      <div className="flex flex-col gap-3">
                        <div className="flex flex-col gap-4">
                          <div className="relative">
                            <div className="flex flex-col gap-2 w-full">
                              <Typography variant="caption" className="text-secondary ">
                                Email Address
                              </Typography>
                              <TextField className={`w-full ${errorEmail ? "outline outline-1 rounded outline-[#E01010]" : ""}`} value={email} onChange={(e) => changeEmail(e.target.value)} />
                              <div className="relative">
                                {errorEmail && (
                                  <Typography variant="caption" className="text-[#E01010] absolute -mt-2">
                                    Invalid Email Address
                                  </Typography>
                                )}
                              </div>
                              <div className="flex items-center">
                                <label className="flex items-center gap-2">
                                  <input
                                    type="checkbox"
                                    className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                                    checked={rememberMyEmailAddress}
                                    onChange={() => setRememberMyEmailAddress(!rememberMyEmailAddress)}
                                  />
                                  <Typography variant="caption" className="text-secondary">
                                    Remember my Email Address
                                  </Typography>
                                </label>
                              </div>
                            </div>
                          </div>
                          <div className="relative">
                            <div className="col-span-1 relative">
                              <div className="flex flex-col gap-2 w-full">
                                <Typography variant="caption" className="text-secondary">
                                  Password
                                </Typography>
                                <div className="relative w-full">
                                  <TextField
                                    className={`w-full ${errorMessagePassword !== "" ? "outline outline-1 rounded outline-[#E01010]" : ""}`}
                                    type={`${showPassword ? "text" : "password"}`}
                                    value={password}
                                    onChange={(e) => changePassword(e.target.value)}
                                  />
                                  <button type="button" onClick={() => setShowPassword(!showPassword)} className="absolute inset-y-0 end-0 flex items-center pe-3">
                                    {!showPassword && <LuEye />}
                                    {showPassword && <LuEyeOff />}
                                  </button>
                                </div>
                                <div className="relative">
                                  {errorMessagePassword !== "" && (
                                    <Typography variant="caption" className="text-[#E01010] absolute -mt-2">
                                      {errorMessagePassword}
                                    </Typography>
                                  )}
                                </div>
                                <div className="-mt-2">
                                  <Typography variant="caption" className="text-[12px] mt-3 text-[#9CA3AF] cursor-pointer hover:text-primary" onClick={() => goFogotPassword()}>
                                    Forgot your password?
                                  </Typography>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="flex w-full flex-col">
                          <div className="flex w-full">
                            <Button variant="contained" onClick={() => logIn()} className="w-full">
                              Sign in
                            </Button>
                          </div>
                        </div>
                      </div>
                    )}
                    {type === "forgot-password" && (
                      <div className="flex flex-col gap-3">
                        <div className="flex flex-col gap-4">
                          <div className="relative">
                            <Typography variant="caption" className="text-secondary mb-[9px]">
                              Email Address
                            </Typography>
                            <TextField className={`w-full ${errorEmail ? "outline outline-1 rounded outline-[#E01010]" : ""}`} value={email} onChange={(e) => changeEmail(e.target.value)} />
                            {errorEmail && (
                              <Typography variant="caption" className="text-[#E01010] absolute mt-1px]">
                                Invalid Email Address
                              </Typography>
                            )}
                          </div>
                        </div>
                        <div className="flex w-full flex-col mt-2">
                          <div className="flex w-full">
                            <Button variant="contained" onClick={() => sendCode()} className="w-full">
                              Send Code
                            </Button>
                          </div>
                        </div>
                        <div className="flex justify-center cursor-pointer">
                          <Typography className="text-[#4C42D7] hover:text-[#6366F1] disabled:text-[#D1D5DB]" onClick={() => setType("signin")}>
                            Return to Sign In
                          </Typography>
                        </div>
                      </div>
                    )}
                    {type === "reset-password" && (
                      <div className="flex flex-col gap-3">
                        <div className="flex flex-col gap-4">
                          <div className="relative">
                            <div className="flex flex-col gap-2 w-full">
                              <Typography variant="caption" className="text-secondary ">
                                Code
                              </Typography>
                              <TextField className="w-full mb-[5px]" placeholder="Enter the code" autoComplete="off" value={code} onChange={(e) => setCode(e.target.value)} />
                            </div>
                          </div>
                          <div className="relative">
                            <div className="col-span-1 relative">
                              <div className="flex flex-col gap-2 w-full">
                                <Typography variant="caption" className="text-secondary">
                                  Password
                                </Typography>
                                <div className="relative w-full">
                                  <TextField
                                    placeholder="Enter your new password"
                                    className={`w-full ${errorMessagePassword !== "" ? "outline outline-1 rounded outline-[#E01010]" : ""}`}
                                    type={`${showPassword ? "text" : "password"}`}
                                    autoComplete="new-password"
                                    value={password}
                                    onChange={(e) => changePassword(e.target.value)}
                                  />
                                  <button type="button" onClick={() => setShowPassword(!showPassword)} className="absolute inset-y-0 end-0 flex items-center pe-3">
                                    {!showPassword && <LuEye />}
                                    {showPassword && <LuEyeOff />}
                                  </button>
                                </div>
                                <div className="relative">
                                  {errorMessagePassword !== "" && (
                                    <Typography variant="caption" className="text-[#E01010] absolute -mt-2">
                                      {errorMessagePassword}
                                    </Typography>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="relative">
                            <div className="col-span-1 relative">
                              <div className="flex flex-col gap-2 w-full">
                                <Typography variant="caption" className="text-secondary">
                                  Confirm Password
                                </Typography>
                                <div className="relative w-full">
                                  <TextField
                                    className={`w-full ${errorConfirm ? "outline outline-1 rounded outline-[#E01010]" : ""}`}
                                    type={`${showConfirmPassword ? "text" : "password"}`}
                                    autoComplete="new-password"
                                    value={confirm}
                                    onChange={(e) => changeConfirm(e.target.value)}
                                  />
                                  <button type="button" onClick={() => setShowConfirmPassword(!showConfirmPassword)} className="absolute inset-y-0 end-0 flex items-center pe-3">
                                    {!showConfirmPassword && <LuEye />}
                                    {showConfirmPassword && <LuEyeOff />}
                                  </button>
                                </div>
                                <div className="relative">
                                  {errorConfirm && (
                                    <Typography variant="caption" className="text-[#E01010] absolute -mt-2">
                                      {errorMessagePassword}
                                    </Typography>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="flex w-full flex-col">
                          <div className="flex w-full">
                            <Button variant="contained" onClick={() => resetPasswordFunc()} className="w-full">
                              Reset Password
                            </Button>
                          </div>
                        </div>
                        <div className="flex justify-center cursor-pointer">
                          <Typography className="text-[#4C42D7] hover:text-[#6366F1] disabled:text-[#D1D5DB]" onClick={() => setType("signin")}>
                            Return to Sign In
                          </Typography>
                        </div>
                      </div>
                    )}
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default AuthDrawer;
