import { useEffect, useLayoutEffect, useState } from "react";
import { useOutletContext } from "react-router-dom";

interface DimensionProps {
    targetRef: any
}
const useDimension = ({targetRef }: DimensionProps) => {
    const [width, setWidth] = useState<number>(0)
    const updateDimensions = () => {
        if (targetRef.current) {
            const { width } = targetRef.current.getBoundingClientRect();
            setWidth(width);
        }
    };
    const outletData = useOutletContext() as Array<any>;
    const isOpen = outletData[1] as boolean;

    useEffect(() => {
        setTimeout(() => {
            if(targetRef){
                updateDimensions();
            }
        }, 500);
    }, [isOpen,window])

    useLayoutEffect(() => {
        updateDimensions();
        const handleResize = () => {
            updateDimensions();
        };
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize)
        }
    }, [])

    return {
        width
    }

}

export default useDimension;