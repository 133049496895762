type Props = {
  width: number;
  height: number;
  stroke?: string;
  className?: string;
};

const IconComment = (props: Props) => {
  return (
    <svg
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g id="vuesax/outline/message">
        <g id="message">
          <path
            id="Vector"
            d="M7.49866 13.6686C7.0952 13.6686 6.71513 13.464 6.44616 13.1073L5.56907 11.9378C5.55153 11.9145 5.48136 11.8852 5.45213 11.8794H5.15977C2.72147 11.8794 1.21289 11.2186 1.21289 7.9325V5.00889C1.21289 2.42441 2.57529 1.06201 5.15977 1.06201H9.83754C12.422 1.06201 13.7844 2.42441 13.7844 5.00889V7.9325C13.7844 10.517 12.422 11.8794 9.83754 11.8794H9.54518C9.49841 11.8794 9.45747 11.9028 9.42824 11.9378L8.55116 13.1073C8.28218 13.464 7.90211 13.6686 7.49866 13.6686ZM5.15977 1.9391C3.06646 1.9391 2.08997 2.91558 2.08997 5.00889V7.9325C2.08997 10.5754 2.99629 11.0023 5.15977 11.0023H5.45213C5.75034 11.0023 6.08947 11.1719 6.27074 11.4116L7.14782 12.581C7.35247 12.85 7.64484 12.85 7.84949 12.581L8.72657 11.4116C8.91953 11.1543 9.22359 11.0023 9.54518 11.0023H9.83754C11.9309 11.0023 12.9073 10.0258 12.9073 7.9325V5.00889C12.9073 2.91558 11.9309 1.9391 9.83754 1.9391H5.15977Z"
            fill="#787486"
          />
          <path
            id="Vector_2"
            d="M7.49683 7.34767C7.16939 7.34767 6.91211 7.08454 6.91211 6.76294C6.91211 6.44135 7.17523 6.17822 7.49683 6.17822C7.81843 6.17822 8.08155 6.44135 8.08155 6.76294C8.08155 7.08454 7.82428 7.34767 7.49683 7.34767Z"
            fill="#787486"
          />
          <path
            id="Vector_3"
            d="M9.83668 7.34767C9.50923 7.34767 9.25195 7.08454 9.25195 6.76294C9.25195 6.44135 9.51508 6.17822 9.83668 6.17822C10.1583 6.17822 10.4214 6.44135 10.4214 6.76294C10.4214 7.08454 10.1641 7.34767 9.83668 7.34767Z"
            fill="#787486"
          />
          <path
            id="Vector_4"
            d="M5.15699 7.34767C4.82954 7.34767 4.57227 7.08454 4.57227 6.76294C4.57227 6.44135 4.83539 6.17822 5.15699 6.17822C5.47859 6.17822 5.74171 6.44135 5.74171 6.76294C5.74171 7.08454 5.48443 7.34767 5.15699 7.34767Z"
            fill="#787486"
          />
        </g>
      </g>
    </svg>
  );
};

export default IconComment;
