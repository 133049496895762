import React, { useRef } from "react";
import { useAppDispatch, useAppSelector } from "@/redux/hooks";
import { getVendorTypes, fetchVendorTypes } from "@/redux/user/userSlice";
import Logo from "@/assets/images/logo_black.svg";
import LogoMain from "@/assets/images/logo_main.svg";
import IconWrapper from "@/components/baseComponents/IconWrapper";
import Typography from "@/components/baseComponents/Typography";
import { useNavigate } from "react-router-dom";
import Tooltip from "@/components/mainComponents/ToolTip";

interface IProps {
  open: boolean;
  changeOpen: Function;
  isLoggedIn: boolean;
  setAuthModalOpen?: Function;
  scrollbars?: any;
}

const UserSidebar = ({
  open,
  changeOpen,
  isLoggedIn = false,
  setAuthModalOpen,
  scrollbars,
}: IProps) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const vendorTypes = useAppSelector(getVendorTypes);

  const windowWidth = useRef(window.innerWidth);
  const [currentPage, setCurrentPage] = React.useState<string>(
    location.pathname.replace("/", "") || ""
  );
  const [showRelationshipsSubmenu, setShowRelationshipsSubmenu] =
    React.useState<boolean>(
      location.pathname.includes("leads") ||
      location.pathname.includes("clients") ||
      false
    );
  const [showListingSubmenu, setShowListingSubmenu] = React.useState<boolean>(
    location.pathname.includes("my-listings") ||
    location.pathname.replace("/", "").includes("listings") ||
    false
  );
  const [showDirectorySubmenu, setShowDirectorySubmenu] =
    React.useState<boolean>(false);
  const goPage = (page: string) => {
    if (scrollbars) {
      scrollbars.current?.scrollToTop();
    }
    setCurrentPage(page);
    navigate("/" + page);
    windowWidth.current < 768 && changeOpen(!open);
  };

  React.useEffect(() => {
    dispatch(fetchVendorTypes());
  }, []);

  return (
    <div
      className={`${isLoggedIn ? `` : `cursor-pointer`}`}
      onClick={() => !isLoggedIn && setAuthModalOpen && setAuthModalOpen(true)}
    >
      <div
        className={`h-screen flex flex-col pt-4 transition-all duration-600  ${open ? `block` : `hidden sm:max-md:block max-sm:block`
          } ${isLoggedIn ? `` : ` pointer-events-none`}`}
      >
        <img src={Logo} alt="Logo" className="scale-[80%]" />
        <div className="px-6 py-8">
          <div
            className={`${currentPage.includes("welcome")
                ? "text-primary bg-gray-100 rounded-md"
                : "text-secondary hover:text-primary"
              } cursor-pointer flex gap-[14px] items-center mb-[8px] px-[8px] py-[6px]`}
            onClick={() => goPage("welcome")}
          >
            <IconWrapper name="welcome" width={21} />
            <Typography variant="left-menu">Welcome</Typography>
          </div>
          <div
            className={`${currentPage.includes("dashboard") || currentPage === ""
                ? "text-primary bg-gray-100 rounded-md"
                : "text-secondary hover:text-primary"
              } cursor-pointer flex gap-[14px] items-center mb-[8px] px-[8px] py-[6px]`}
            onClick={() => goPage("dashboard")}
          >
            <IconWrapper name="dashboard" width={21} />
            <Typography variant="left-menu" className="mt-0.5">
              Dashboard
            </Typography>
          </div>
          <Tooltip text="Coming Soon">
            <div
              className={`${currentPage.includes("calendar")
                  ? "text-primary bg-gray-100 rounded-md"
                  : "text-gray-300 [&>div>svg>path]:!stroke-gray-300  hover:text-primary"
                } cursor-pointer flex gap-[14px] items-center pointer-events-none mb-[8px] px-[8px] py-[6px]`}
              onClick={() => goPage("calendar")}
            >
              <IconWrapper name="calendar" width={21} />
              <Typography variant="left-menu">Calendar</Typography>
            </div>
          </Tooltip>
          <div
            className={`${currentPage.includes("chat")
                ? "text-primary bg-gray-100 rounded-md"
                : "text-secondary hover:text-primary"
              } cursor-pointer flex gap-[14px] items-center mb-[8px] px-[8px] py-[6px]`}
            onClick={() => goPage("chat")}
          >
            <IconWrapper name="chat" width={21} />
            <Typography variant="left-menu" className="mt-0.5">
              Messages
            </Typography>
          </div>
          <div className="my-[24px]">
            <Typography
              color="secondary"
              className="!text-gray-300 text-11 uppercase select-none px-2"
            >
              Workspace
            </Typography>
            <div>
              <div
                className={`${currentPage.includes("leads") ||
                    currentPage.includes("clients")
                    ? "text-primary"
                    : "text-secondary hover:text-primary"
                  } flex justify-between cursor-pointer mb-[8px] px-[8px] py-[6px]`}
                onClick={() =>
                  setShowRelationshipsSubmenu(!showRelationshipsSubmenu)
                }
              >
                <div className="flex gap-[14px] items-center">
                  <IconWrapper name="relationships" width={21} />
                  <Typography variant="left-menu">Relationships</Typography>
                </div>
                {showRelationshipsSubmenu ? (
                  <IconWrapper name="arrow-up" />
                ) : (
                  <IconWrapper name="arrow-down" />
                )}
              </div>
              {showRelationshipsSubmenu && (
                <div className="sub-menu-area pl-[14px] ml-[24px] border-l-1 border-gray-200">
                  <div
                    className={`${currentPage.includes("leads")
                        ? "text-primary bg-gray-100 rounded-md"
                        : "text-secondary hover:text-primary"
                      } cursor-pointer mb-[8px] px-[8px] py-[6px]`}
                    onClick={() => goPage("leads")}
                  >
                    <Typography variant="left-menu">Leads</Typography>
                  </div>
                  <div
                    className={`${currentPage.includes("clients")
                        ? "text-primary bg-gray-100 rounded-md"
                        : "text-secondary hover:text-primary"
                      } cursor-pointer mb-[8px] px-[8px] py-[6px]`}
                    onClick={() => goPage("clients")}
                  >
                    <Typography variant="left-menu">Clients</Typography>
                  </div>
                </div>
              )}
              <div
                className={`${currentPage.includes("listings")
                    ? "text-primary"
                    : "text-secondary hover:text-primary"
                  } flex justify-between cursor-pointer mb-[8px] px-[8px] py-[6px]`}
                onClick={() => setShowListingSubmenu(!showListingSubmenu)}
              >
                <div className="flex gap-[14px] items-center">
                  <IconWrapper name="listings" width={21} />
                  <Typography variant="left-menu">Listings</Typography>
                </div>
                {showListingSubmenu ? (
                  <IconWrapper name="arrow-up" />
                ) : (
                  <IconWrapper name="arrow-down" />
                )}
              </div>
              {showListingSubmenu && (
                <div className="sub-menu-area pl-[14px] ml-[24px] border-l-1 border-gray-200">
                  <div
                    className={`${currentPage.includes("my-listings")
                        ? "text-primary bg-gray-100 rounded-md"
                        : "text-secondary hover:text-primary"
                      } cursor-pointer mb-[8px] px-[8px] py-[6px]`}
                    onClick={() => goPage("my-listings")}
                  >
                    <Typography variant="left-menu">My Listings</Typography>
                  </div>
                  <div
                    className={`${currentPage.replace("/", "") === "listings"
                        ? "text-primary bg-gray-100 rounded-md"
                        : "text-secondary hover:text-primary"
                      } cursor-pointer mb-[8px] px-[8px] py-[6px]`}
                    onClick={() => goPage("listings")}
                  >
                    <Typography variant="left-menu">Search Listings</Typography>
                  </div>
                </div>
              )}
              <div
                className={`${currentPage.includes("showings")
                    ? "text-primary bg-gray-100 rounded-md"
                    : "text-secondary hover:text-primary"
                  } cursor-pointer mb-[8px] px-[8px] py-[6px] flex gap-[14px] items-center`}
                onClick={() => goPage("showings")}
              >
                <IconWrapper name="showings" width={21} />
                <Typography variant="left-menu">Showings</Typography>
              </div>
              <Tooltip text="Coming Soon">
                <div
                  className={`${currentPage.includes("offers")
                    ? "text-primary bg-gray-100 rounded-md"
                    : "text-gray-300 [&>div>svg>path]:!stroke-gray-300 hover:text-primary"
                  } cursor-pointer mb-[8px] px-[8px] py-[6px] flex gap-[14px] items-center pointer-events-none`}
                  onClick={() => goPage("offers")}
                >
                  <IconWrapper name="offers" width={21} />
                  <Typography variant="left-menu">Offers</Typography>
                </div>
              </Tooltip>
              <Tooltip text="Coming Soon">
                <div
                  className={`${currentPage.includes("transactions")
                    ? "text-primary bg-gray-100 rounded-md"
                    : "text-gray-300 [&>div>svg>path]:!stroke-gray-300 hover:text-primary"
                    } cursor-pointer mb-[8px] px-[8px] py-[6px] flex gap-[14px] items-center pointer-events-none`}
                  onClick={() => goPage("transactions")}
                >
                  <IconWrapper name="transactions" width={21} />
                  <Typography variant="left-menu">Transactions</Typography>
                </div>
              </Tooltip>
            </div>
          </div>
          <div className="mt-[24px]">
            <Typography
              color="secondary"
              className="!text-gray-300 text-11 uppercase select-none px-2"
            >
              Realconnect
            </Typography>
            <div>
              <Tooltip text="Coming Soon">
                <div
                  className={`${currentPage.includes("agents/all")
                      ? "text-primary bg-gray-100 rounded-md"
                      : "text-gray-300 [&>div>svg>path]:!stroke-gray-300 hover:text-primary"
                    } cursor-pointer flex mb-[8px] px-[8px] py-[6px] gap-[14px] items-center pointer-events-none`}
                  onClick={() => goPage("agents/all")}
                >
                  <IconWrapper name="agents" width={21} />
                  <Typography variant="left-menu">Agents</Typography>
                </div>
              </Tooltip>
              <Tooltip text="Coming Soon">
                <div
                  className={`${currentPage.includes("agents/team")
                      ? "text-primary bg-gray-100 rounded-md"
                      : "text-gray-300 [&>div>svg>path]:!stroke-gray-300  hover:text-primary"
                    } cursor-pointer flex mb-[8px] px-[8px] py-[6px] gap-[14px] items-center pointer-events-none`}
                  onClick={() => goPage("agents/team")}
                >
                  <IconWrapper name="team" width={21} />
                  <Typography variant="left-menu">My Team</Typography>
                </div>
              </Tooltip>
              <Tooltip text="Coming Soon">
                <div
                  className={`${currentPage.includes("agents/company")
                      ? "text-primary bg-gray-100 rounded-md"
                      : "text-gray-300 [&>div>svg>path]:!stroke-gray-300 hover:text-primary"
                    } cursor-pointer flex mb-[8px] px-[8px] py-[6px] gap-[14px] items-center pointer-events-none`}
                  onClick={() => goPage("agents/company")}
                >
                  <IconWrapper name="brokerage" width={21} />
                  <Typography variant="left-menu">My Brokerage</Typography>
                </div>
              </Tooltip>
            </div>
            <div>
              <Tooltip text="Coming Soon">
                <div
                  className={`${currentPage.includes("vendor")
                      ? "text-primary"
                      : "text-gray-300 [&>div>div>svg>path]:!stroke-gray-300 [&>div>svg>path]:!stroke-gray-300  hover:text-primary"
                    } flex justify-between cursor-pointer mb-[8px] px-[8px] py-[6px] pointer-events-none`}
                  onClick={() => setShowDirectorySubmenu(!showDirectorySubmenu)}
                >
                  <div className="flex gap-3 items-center">
                    <IconWrapper name="folder" width={21} />
                    <Typography variant="left-menu">Directory</Typography>
                  </div>
                  {showDirectorySubmenu ? (
                    <IconWrapper name="arrow-up" />
                  ) : (
                    <IconWrapper name="arrow-down" />
                  )}
                </div>
              </Tooltip>
              <div className="sub-menu-area pl-[14px] ml-[24px] border-l-1 border-gray-200">
                {showDirectorySubmenu && (
                  <>
                    <div
                      className={`${currentPage === "vendor/All"
                          ? "text-primary bg-gray-100 rounded-md"
                          : "text-secondary hover:text-primary"
                        } cursor-pointer mb-[8px] px-[8px] py-[6px]`}
                      onClick={() => goPage("vendor/All")}
                    >
                      <Typography variant="left-menu">{"All"}</Typography>
                    </div>
                    {vendorTypes &&
                      vendorTypes.length > 0 &&
                      vendorTypes.map((type: any) => (
                        <div
                          key={type.name}
                          className={`${currentPage === "vendor/" + type.name
                              ? "text-primary bg-gray-100 rounded-md"
                              : "text-secondary hover:text-primary"
                            } cursor-pointer mb-[8px] px-[8px] py-[6px]`}
                          onClick={() => goPage("vendor/" + type.name)}
                        >
                          <Typography variant="left-menu">
                            {type.name ?? ""}
                          </Typography>
                        </div>
                      ))}
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="border-gray-200 pt-2 flex flex-1 justify-end items-end">
          <button className="p-5" onClick={() => changeOpen(!open)}>
            <IconWrapper name="panel-left-close-alt" width={20} className="sm:max-md:hidden max-sm:hidden" />
          </button>
        </div>
      </div>

      <div
        className={`py-3 transition-all duration-600 ${open ? `hidden` : `block sm:max-md:hidden max-sm:hidden`
          }`}
      >
        <div className="flex flex-col items-center space-y-5">
          <img src={LogoMain} alt="logoMain" className="w-[36px] h-[36px]" />
          <div
            className={`${currentPage === "" && "[&>div>div>svg>path]:!stroke-[#294661]"
              } p-1 rounded-lg cursor-pointer mb-4 group`}
            onClick={() => goPage("")}
          >
            <IconWrapper
              width={21}
              name="welcome"
              className="group-hover:[&>path]:!stroke-[#294661]"
            />
          </div>

          <div
            className={`${currentPage.includes("dashboard") &&
              "text-primary [&>div>div>svg>path]:!stroke-[#294661]"
              } p-1 rounded-lg cursor-pointer mb-4 group`}
            onClick={() => goPage("dashboard")}
          >
            <IconWrapper
              width={21}
              name="dashboard"
              className="group-hover:[&>path]:!stroke-[#294661]"
            />
          </div>

          <div
            className={`${currentPage.includes("dashboard") &&
              "text-primary [&>div>div>svg>path]:!stroke-[#294661]"
              } p-1 rounded-lg cursor-pointer mb-4 group`}
            onClick={() => goPage("chat")}
          >
            <IconWrapper
              width={21}
              name="chat"
              className="group-hover:[&>path]:!stroke-[#294661]"
            />
          </div>

          <div className=" border-1 border-border w-full"></div>

          <div
            className={`${currentPage.includes("leads") &&
              "text-primary [&>div>div>svg>path]:!stroke-[#294661]"
              } p-1 rounded-lg cursor-pointer mb-4 group`}
            onClick={() => goPage("leads")}
          >
            <IconWrapper
              width={21}
              name="leads"
              className="group-hover:[&>path]:!stroke-[#294661]"
            />
          </div>

          <div
            className={`${currentPage.includes("clients") &&
              "text-primary [&>div>div>svg>path]:!stroke-[#294661]"
              } p-1 rounded-lg cursor-pointer mb-4 group`}
            onClick={() => goPage("clients")}
          >
            <IconWrapper
              width={21}
              name="clients"
              className="group-hover:[&>path]:!stroke-[#294661]"
            />
          </div>

          <div
            className={`${currentPage.includes("my-listings") &&
              "text-primary [&>div>div>svg>path]:!stroke-[#294661]"
              } p-1 cursor-pointer rounded-lg mb-4 group`}
            onClick={() => goPage("my-listings")}
          >
            <IconWrapper
              width={21}
              name="listings"
              className="group-hover:[&>path]:!stroke-[#294661]"
            />
          </div>

          <div
            className={`${currentPage.includes("showings") &&
              "text-primary [&>div>div>svg>path]:!stroke-[#294661]"
              } p-1 rounded-lg cursor-pointer mb-4 group`}
            onClick={() => goPage("showings")}
          >
            <Tooltip text="Showings">
              <IconWrapper
                width={21}
                name="showings"
                className="group-hover:[&>path]:!stroke-[#294661]"
              />
            </Tooltip>
          </div>

          <div
            className={`${currentPage.includes("offers") &&
              "text-primary [&>div>div>svg>g>path]:!stroke-[#294661]"
              } p-1 rounded-lg cursor-pointer mb-4 group`}
            onClick={() => goPage("offers")}
          >
            <IconWrapper
              width={21}
              name="offers"
              className="group-hover:[&>g>path]:!stroke-[#294661]"
            />
          </div>

          <Tooltip text="Coming Soon">
            <div
              className={`${currentPage.includes("transactions") &&
                "text-primary [&>div>div>svg>path]:!stroke-[#294661]"
                } p-1 rounded-lg cursor-pointer mb-4 group pointer-events-none [&>div>svg>path]:!stroke-gray-300`}
              onClick={() => goPage("transactions")}
            >
              <IconWrapper
                width={21}
                name="transactions"
                className="group-hover:[&>path]:!stroke-[#294661]"
              />
            </div>
          </Tooltip>

          <div className=" border-1 border-border w-full"></div>

          <div
            className={`${currentPage.includes("agents/all") &&
              "text-primary [&>div>div>svg>path]:!stroke-[#294661]"
              } p-1 rounded-lg cursor-pointer mb-4 group`}
            onClick={() => goPage("agents/all")}
          >
            <Tooltip text="Agents">
              <IconWrapper
                width={21}
                name="agents"
                className="group-hover:[&>path]:!stroke-[#294661]"
              />
            </Tooltip>
          </div>

          <Tooltip text="Coming Soon">
            <div
              className={`${currentPage.includes("agents/team") &&
                "text-primary [&>div>div>svg>g>path]:!stroke-[#294661]"
                } p-1 rounded-lg cursor-pointer mb-4 group pointer-events-none [&>div>svg>g>path]:!stroke-gray-300`}
              onClick={() => goPage("agents/team")}
            >
              <IconWrapper
                width={21}
                name="team"
                className="group-hover:[&>g>path]:!stroke-[#294661]"
              />
            </div>
          </Tooltip>

          <div
            className={`${currentPage.includes("agents/company") &&
              "text-primary [&>div>div>svg>path]:!stroke-[#294661]"
              } p-1 rounded-lg cursor-pointer mb-4 group`}
            onClick={() => goPage("agents/company")}
          >
            <Tooltip text="My Brokerage">
              <IconWrapper
                width={21}
                name="brokerage"
                className="group-hover:[&>path]:!stroke-[#294661]"
              />
            </Tooltip>
          </div>

          <Tooltip text="Coming Soon">
            <div
              className={`${currentPage.includes("vendor") &&
                "text-primary [&>div>svg>path]:!stroke-[#294661]"
                } p-1 rounded-lg cursor-pointer mb-4 group pointer-events-none [&>div>svg>path]:!stroke-gray-300`}
              onClick={() => goPage("vendor/all")}
            >
              <IconWrapper
                width={21}
                name="folder"
                stroke="#A3A9BA"
                className="group-hover:[&>div>svg>path]:!stroke-[#294661]"
              />
            </div>
          </Tooltip>

          <button className="p-[12px]" onClick={() => changeOpen(!open)}>
            <IconWrapper name="panel-right-open-alt" width={20} className="sm:max-md:hidden max-sm:hidden" />
          </button>
        </div>
      </div>
    </div>
  );
};

export default UserSidebar;
