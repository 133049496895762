type PlaceholderProps = {
    value: any;
    icon?: any;
    className?: string;
}

const Placeholder = ({
    value,
    icon,
    className
}: PlaceholderProps) => {
    return (
        <div className={`flex items-start bg-gray-100 px-4 py-2.5 ${value ? `` : `h-[42px]`} ${className}`}>
            {icon && <div className="-mt-[2px] mr-2">{icon}</div>}
            <div className="line-clamp-5 body">{value}</div>
        </div>
    );
}

export default Placeholder;