import { useEffect, useRef, useState } from "react";
import TextField from "@/components/baseComponents/TextField";
import Typography from "@/components/baseComponents/Typography";
import { Button } from "@/components/baseComponents/Button";
import DefaultCompanyLogo from "@/assets/images/default_company_logo.webp";
import AddTeam from "@/assets/images/add_team.svg";
import { ICompanyTeamInfo } from "@/shared/interfaces/interfaces";
import { useAppSelector, useAppDispatch } from "@/redux/hooks";
import {
    getUser,
    updateCompanyTeamInfo,
    getCompanies,
    fetchRelevantCompanies,
    getAddresses,
    fetchAddressAutocomplete,
    getTeams,
    fetchRelevantTeams,
} from "@/redux/user/userSlice";
import { notify } from "@/shared/services/notify";
import validation from "@/shared/services/validation";
import AutoComplete from "@/components/baseComponents/AutoComplete";
import AddressAutoComplete from "@/components/baseComponents/AddressAutoComplete";
import { myBucket } from "@/shared/services/s3Bucket";

type IProps = {
    isCompanyDetailsHidden?: boolean;
    isTeamDetailsHidden?: boolean;
    onSubmitClick: boolean;
    thereIsChange: (change: boolean) => void;
    revertChanges: number;
    changeSaved: () => void;
};

const TeamInfo = (props: IProps) => {
    const dispatch = useAppDispatch();
    const user = useAppSelector(getUser);
    const isVendor = user.isVendor;
    const companies = useAppSelector(getCompanies);
    const teams = useAppSelector(getTeams);
    const addresses = useAppSelector(getAddresses);

    const company = (isVendor ? user.vendor?.company : user.agent.company) || {};
    const team = (isVendor ? user.vendor?.team : user.agent.team) || {};
    const initialInfo: ICompanyTeamInfo = {
        companyId: company?._id,
        businessName: company?.businessName ?? "",
        businessAddress1: company?.businessAddress1 ?? "",
        businessAddress2: company?.businessAddress2 ?? "",
        businessCity: company?.businessCity ?? "",
        state: company?.state ?? "",
        businessZip: company?.businessZip ?? "",
        county: company?.county ?? "",
        businessLogo: company?.businessLogo ?? "",
        isProfile: company?.isProfile ?? "",
        principalBrokerFirstName: company?.principalBrokerFirstName ?? "",
        principalBrokerLastName: company?.principalBrokerLastName ?? "",
        principalBrokerEmail: company?.principalBrokerEmail ?? "",
        principalBrokerPhone: company?.principalBrokerPhone ?? "",
        teamId: team?._id,
        teamLogo: team?.teamLogo ?? "",
        teamName: team?.teamName ?? "",
        teamLeaderFirstName: team?.teamLeaderFirstName ?? "",
        teamLeaderLastName: team?.teamLeaderLastName ?? "",
        teamLeaderBrokerEmail: team?.teamLeaderBrokerEmail ?? "",
        teamLeaderPhone: team?.teamLeaderPhone ?? "",
    };
    const [avatarURL, setAvatarURL] = useState<string>("");
    const avatarRef = useRef(null);
    const [avatarTeamURL, setAvatarTeamURL] = useState<string>("");
    const avatarTeamRef = useRef(null);
    const [values, setValues] = useState<ICompanyTeamInfo>(initialInfo);
    const [errorBrokerPhone, setErrorBrokerPhone] = useState<boolean>(false);
    const [errorLeaderPhone, setErrorLeaderPhone] = useState<boolean>(false);
    const [errorTeamLeaderBrokerEmail, setErrorTeamLeaderBrokerEmail] = useState<boolean>(false);
    const [errorPrincipalBrokerEmail, setErrorPrincipalBrokerEmail] = useState<boolean>(false);
    const [isLeader, setIsLeader] = useState<boolean>(team.teamLeaderBrokerEmail === user.email ? true : false);
    const [teamDisable, setTeamDisable] = useState<boolean>(false);
    const [companyDisable, setCompanyDisable] = useState<boolean>(false);
    //(company && company?.principalBrokerFirstName && company?.principalBrokerLastName ? true : false)
    const [submitDisable, setSubmitDisable] = useState<boolean>(false);
    const [lastSelectedCompany, setLastSelectedCompany] = useState<any>();

    useEffect(() => {
        if (props.onSubmitClick) {
            updateCompanyTeam();
            props.changeSaved();
        }
    }, [props.onSubmitClick]);

    const handleInputChange = (e: any) => {
        const { name, value } = e.target;
        let tempValue = value;
        if (name.includes("Name")) {
            tempValue = value.replace(/[^a-z]/gi, "");
        }
        setValues({
            ...values,
            [name]: tempValue,
        });
        if (name === "principalBrokerPhone" || name === "teamLeaderPhone") {
            const formattedPhoneNumber = validation.phoneNumberAutoFormat(value);
            if (name === "principalBrokerPhone" && formattedPhoneNumber.length > 0 && formattedPhoneNumber.length < 12)
                setErrorBrokerPhone(true);
            else setErrorBrokerPhone(false);
            if (name === "teamLeaderPhone" && formattedPhoneNumber.length > 0 && formattedPhoneNumber.length < 12)
                setErrorLeaderPhone(true);
            else setErrorLeaderPhone(false);
            setValues({
                ...values,
                [name]: formattedPhoneNumber,
            });
        }
        switch (name) {
            case "principalBrokerEmail":
                setErrorPrincipalBrokerEmail(validation.IsInvalidEmail(value) && value.length > 0);
                break;
            case "teamLeaderBrokerEmail":
                setErrorTeamLeaderBrokerEmail(validation.IsInvalidEmail(value) && value.length > 0);
                break;
        }
    };

    const changeFile = (files: Array<any>) => {
        setAvatarURL(URL.createObjectURL(files[0]));
        updateAvatar(files[0], true);
    };
    const changeFileTeam = (files: Array<any>) => {
        setAvatarTeamURL(URL.createObjectURL(files[0]));
        updateAvatar(files[0], false);
    };
    const updateAvatar = (file: any, isCompany: boolean) => {
        if (file === "") return;
        setSubmitDisable(true);
        const timestamp = new Date().getTime();
        const newFile = new File([file], timestamp + "_" + file.name);
        const fileParams: any = {
            ACL: "bucket-owner-full-control",
            Body: newFile,
            Bucket: import.meta.env.VITE_BUCKET_NAME,
            Key: `company-logos/${newFile.name}`,
        };

        myBucket.upload(fileParams, (err: any, data: any) => {
            if (err) {
                console.log(err);
            }
            if (data) {
                if (isCompany) {
                    setAvatarURL(data.Location);
                    setValues({
                        ...values,
                        businessLogo: data.Location,
                    });
                } else {
                    setAvatarTeamURL(data.Location);
                    setValues({
                        ...values,
                        teamLogo: data.Location,
                    });
                }

                setSubmitDisable(false);
            }
        });
    };
    const openDialog = () => {
        (avatarRef.current as any).click();
    };

    const openDialogTeam = () => {
        (avatarTeamRef.current as any).click();
    };

    const updateCompanyTeam = () => {
        if (!errorBrokerPhone && !errorLeaderPhone && !errorPrincipalBrokerEmail && !errorTeamLeaderBrokerEmail) {
            dispatch(
                updateCompanyTeamInfo({
                    email: user.email,
                    data: values,
                })
            ).then((res) => {
                if (res.payload.user?.agent?.company?.isProfile) {
                    setCompanyDisable(true);
                }
                notify(res.payload.success, res.payload.message);
            });
        }
    };

    const onChangeAutoComplete = (value: any) => {
        setCompanyDisable(false);
        setValues({
            ...values,
            businessName: value,
        });
        if (lastSelectedCompany?.businessName === value) {
            onAllChangeAutoComplete(lastSelectedCompany);
        }
        dispatch(fetchRelevantCompanies({ query: value }));
    };

    const onChangeAutoCompleteTeam = (value: any) => {
        setValues({
            ...values,
            teamName: value,
        });
        dispatch(fetchRelevantTeams({ query: value, companyId: values.companyId }));
    };

    const onChangeAddressAutoComplete = (value: any) => {
        setValues({
            ...values,
            businessAddress1: value,
        });
        dispatch(fetchAddressAutocomplete({ address: value }));
    };

    const onSelectAddressAutoComplete = (value: any) => {
        setValues({
            ...values,
            businessAddress1: value.streetLine,
            businessCity: value.city,
            state: value.state,
            businessZip: value.zipcode,
        });
    };

    const onAllChangeAutoComplete = (value: any) => {
        setLastSelectedCompany(value);
        setValues({
            ...values,
            companyId: value._id,
            businessName: value.businessName,
            businessLogo: value.businessLogo,
            businessAddress1: value.businessAddress1,
            businessAddress2: value.businessAddress2,
            businessCity: value.businessCity,
            state: value.state,
            businessZip: value.businessZip,
            county: value.county,
            principalBrokerEmail: value.principalBrokerEmail ?? "",
            principalBrokerFirstName: value.principalBrokerFirstName ?? "",
            principalBrokerLastName: value.principalBrokerLastName ?? "",
            principalBrokerPhone: value.principalBrokerPhone ?? "",
        });
        dispatch(fetchRelevantCompanies({ query: value.businessName }));
    };

    const onAllChangeAutoCompleteTeam = (value: any) => {
        if (value.isProfile === 1) {
            setTeamDisable(true);
        } else {
            setTeamDisable(false);
        }

        setValues({
            ...values,
            teamId: value._id,
            teamLogo: value.teamLogo,
            teamName: value.teamName,
            teamLeaderFirstName: value.teamLeaderFirstName ?? "",
            teamLeaderLastName: value.teamLeaderLastName ?? "",
            teamLeaderBrokerEmail: value.teamLeaderBrokerEmail ?? "",
            teamLeaderPhone: value.teamLeaderPhone ?? "",
        });
        dispatch(fetchRelevantCompanies({ query: value.businessName }));
    };

    const handleIsLeader = (isLeader: boolean) => {
        setIsLeader(isLeader);
        if (isLeader) {
            setValues({
                ...values,
                teamLeaderFirstName: isVendor ? user.vendor?.firstName : user.agent?.firstName,
                teamLeaderLastName: isVendor ? user.vendor?.lastName : user.agent?.lastName,
                teamLeaderBrokerEmail: isVendor ? user.vendor?.contactEmail : user.agent?.contactEmail,
                teamLeaderPhone: isVendor ? user.vendor?.mobileNumber : user.agent?.mobileNumber,
            });
        }
    };

    useEffect(() => {
        props.thereIsChange(JSON.stringify(values) !== JSON.stringify(initialInfo));
    }, [values])

    useEffect(() => {
        setValues(initialInfo);
        setAvatarURL("");
    }, [props.revertChanges])

    return (
        <div>
            {!props.isCompanyDetailsHidden && (
                <div className="flex w-full flex-col gap-6">
                    <div className="flex flex-col 2xl:flex-row items-start justify-between bg-white p-6 2xl:gap-20 xl:gap-8 rounded-lg">
                        <div className="flex flex-col xl:min-w-[300px] xl:pb-0 pb-8">
                            <Typography variant="h2" color="primary">
                                Company Info
                            </Typography>
                            <Typography variant="input-label" color="primary">
                                Adding your Brokerage/company is required.
                            </Typography>
                        </div>
                        <div className="w-full flex flex-col gap-4">
                            <div className="flex w-full">
                                <div className="flex w-full flex-col gap-2">
                                    <Typography variant="input-label" className="text-secondary">
                                        Company Name
                                    </Typography>
                                    <AutoComplete
                                        options={companies}
                                        leadingClassName="p-1"
                                        filterKey="businessName"
                                        placeholder="Search by typing your Brokerage’s name or continue typing to add a new company"
                                        value={values.businessName}
                                        onChange={onChangeAutoComplete}
                                        onAllChange={onAllChangeAutoComplete}
                                    />
                                </div>
                            </div>
                            <div className="flex flex-col md:flex-row gap-6">
                                <div className="flex w-full flex-col gap-2">
                                    <Typography
                                        variant="input-label"
                                        color={companyDisable ? "" : "tertiary"}
                                        className={companyDisable ? "text-border" : ""}
                                    >
                                        Address
                                    </Typography>
                                    <AddressAutoComplete
                                        options={addresses}
                                        value={values.businessAddress1}
                                        onChange={onChangeAddressAutoComplete}
                                        onAllChange={onSelectAddressAutoComplete}
                                        disabled={companyDisable}
                                    />
                                </div>
                            </div>
                            <div className="flex flex-col md:flex-row gap-6">
                                <div className="flex w-full flex-col gap-2">
                                    <Typography
                                        variant="input-label"
                                        color={companyDisable ? "" : "tertiary"}
                                        className={companyDisable ? "text-border" : ""}
                                    >
                                        Unit
                                    </Typography>
                                    <TextField
                                        className="w-full"
                                        name="businessAddress2"
                                        value={values.businessAddress2}
                                        onChange={(e) => handleInputChange(e)}
                                        disabled={companyDisable}
                                        inputClassName={`${values?.businessAddress2?.length != 0
                                                ? companyDisable
                                                    ? "bg-netural"
                                                    : "bg-[#E5E7EB]"
                                                : ""
                                            }`}
                                    />
                                </div>
                            </div>
                            <div className="flex flex-col md:flex-row gap-6">
                                <div className="flex flex-col w-full md:w-1/2 gap-2">
                                    <Typography
                                        variant="input-label"
                                        color={companyDisable ? "" : "tertiary"}
                                        className={companyDisable ? "text-border" : ""}
                                    >
                                        City
                                    </Typography>
                                    <TextField
                                        className="w-full"
                                        name="businessCity"
                                        value={values.businessCity}
                                        onChange={(e) => handleInputChange(e)}
                                        disabled={companyDisable}
                                        inputClassName={`${values?.businessCity?.length != 0 ? "bg-[#E5E7EB]" : ""}`}
                                    />
                                </div>
                                <div className="flex flex-col w-full md:w-1/2 gap-2">
                                    <Typography
                                        variant="input-label"
                                        color={companyDisable ? "" : "tertiary"}
                                        className={companyDisable ? "text-border" : ""}
                                    >
                                        State
                                    </Typography>
                                    <TextField
                                        className="w-full"
                                        name="state"
                                        value={values.state}
                                        onChange={(e) => handleInputChange(e)}
                                        disabled={companyDisable}
                                        inputClassName={`${values?.state?.length != 0 ? "bg-[#E5E7EB]" : ""}`}
                                    />
                                </div>
                            </div>
                            <div className="flex flex-col md:flex-row gap-6">
                                <div className="flex flex-col w-full md:w-1/2 gap-2">
                                    <Typography
                                        variant="input-label"
                                        color={companyDisable ? "" : "tertiary"}
                                        className={companyDisable ? "text-border" : ""}
                                    >
                                        Zip Code
                                    </Typography>
                                    <TextField
                                        className="w-full"
                                        name="businessZip"
                                        value={values.businessZip}
                                        onChange={(e) => handleInputChange(e)}
                                        inputClassName={`${values?.businessZip?.length != 0 ? "bg-[#E5E7EB]" : ""}`}
                                        disabled={companyDisable}
                                    />
                                </div>
                                <div className="flex flex-col w-full md:w-1/2 gap-2">
                                    <Typography
                                        variant="input-label"
                                        color={companyDisable ? "" : "tertiary"}
                                        className={companyDisable ? "text-border" : ""}
                                    >
                                        County
                                    </Typography>
                                    <TextField
                                        className="w-full"
                                        name="county"
                                        value={values.county}
                                        onChange={(e) => handleInputChange(e)}
                                        disabled={companyDisable}
                                        inputClassName={`${values?.county?.length != 0 ? "bg-[#E5E7EB]" : ""}`}
                                    />
                                </div>
                            </div>

                            <div className="w-full lg2:max-w-[670px] mt-[25px] lg2:mt-[0px]">
                                {/* 
              <div
                className={`grid grid-cols-2 md:gap-3 mb-[25px] ${companyDisable ? "opacity-40" : ""
                  }`}
              >
                <div className="col-span-2 md:col-span-1">
                  <Typography
                    variant="caption"
                    className="text-secondary "
                  >
                    Principal Broker Email
                  </Typography>
                  <TextField
                    className={`w-full ${errorPrincipalBrokerEmail
                      ? "outline outline-1 rounded outline-[#E01010]"
                      : ""
                      }`}
                    name="principalBrokerEmail"
                    value={values.principalBrokerEmail}
                    onChange={(e) => handleInputChange(e)}
                    disabled={companyDisable}
                  />
                  {errorPrincipalBrokerEmail && (
                    <Typography
                      variant="caption"
                      className="text-[#E01010] absolute mt-[2px]"
                    >
                      Invalid Email Address
                    </Typography>
                  )}
                </div>
                <div className="col-span-2 md:col-span-1 mt-[25px] md:mt-[0px] relative">
                  <Typography
                    variant="caption"
                    className="text-secondary "
                  >
                    Principal Broker Phone
                  </Typography>
                  <TextField
                    className={`w-full`}
                    maxLength={12}
                    name="principalBrokerPhone"
                    value={values.principalBrokerPhone}
                    onChange={(e) => handleInputChange(e)}
                    disabled={companyDisable}
                  />
                  {errorBrokerPhone && (
                    <Typography
                      variant="caption"
                      className="text-[#E01010] absolute mt-[2px]"
                    >
                      Please enter a valid phone number
                    </Typography>
                  )}
                </div>
              </div> */}
                            </div>
                        </div>
                    </div>

                    <div className="flex flex-col 2xl:flex-row items-start justify-between bg-white p-6 2xl:gap-20 xl:gap-8 rounded-lg">
                        <div className="flex flex-col xl:min-w-[300px] xl:pb-0 pb-8">
                            <Typography variant="h2" color="primary">
                                Principal Broker Info
                            </Typography>
                            <Typography variant="input-label" color="primary">
                                Principal Broker info is required.
                            </Typography>
                        </div>
                        <div className="w-full flex flex-col gap-4">
                            <div className="flex flex-col md:flex-row gap-6">
                                <div className="flex flex-col w-full md:w-1/2 gap-2">
                                    <Typography
                                        variant="input-label"
                                        color={companyDisable ? "" : "tertiary"}
                                        className={companyDisable ? "text-border" : ""}
                                    >
                                        Principal Broker First Name
                                    </Typography>
                                    <TextField
                                        className="w-full"
                                        name="principalBrokerFirstName"
                                        value={values.principalBrokerFirstName}
                                        onChange={(e) => handleInputChange(e)}
                                        disabled={companyDisable}
                                        inputClassName={`${values?.principalBrokerFirstName?.length != 0 ? "bg-[#E5E7EB]" : ""
                                            }`}
                                    />
                                </div>
                                <div className="flex flex-col w-full md:w-1/2 gap-2">
                                    <Typography
                                        variant="input-label"
                                        color={companyDisable ? "" : "tertiary"}
                                        className={companyDisable ? "text-border" : ""}
                                    >
                                        Principal Broker Last Name
                                    </Typography>
                                    <TextField
                                        className="w-full"
                                        name="principalBrokerLastName"
                                        value={values.principalBrokerLastName}
                                        onChange={(e) => handleInputChange(e)}
                                        disabled={companyDisable}
                                        inputClassName={`${values?.principalBrokerLastName?.length != 0 ? "bg-[#E5E7EB]" : ""
                                            }`}
                                    />
                                </div>
                            </div>
                            <div className="flex flex-col md:flex-row gap-6">
                                <div className="flex flex-col w-full md:w-1/2 gap-2">
                                    <Typography
                                        variant="input-label"
                                        color={companyDisable ? "" : "tertiary"}
                                        className={companyDisable ? "text-border" : ""}
                                    >
                                        Principal Broker Email
                                    </Typography>
                                    <TextField
                                        className={`w-full ${errorPrincipalBrokerEmail
                                                ? "outline outline-1 rounded outline-[#E01010]"
                                                : ""
                                            }`}
                                        name="principalBrokerEmail"
                                        value={values.principalBrokerEmail}
                                        onChange={(e) => handleInputChange(e)}
                                        disabled={companyDisable}
                                        inputClassName={`${values?.principalBrokerEmail?.length != 0 ? "bg-[#E5E7EB]" : ""
                                            }`}
                                    />
                                    <div className="relative">
                                        {errorPrincipalBrokerEmail && (
                                            <Typography variant="caption" className="text-[#E01010] absolute -mt-2">
                                                Invalid Email Address
                                            </Typography>
                                        )}
                                    </div>
                                </div>
                                <div className="flex flex-col w-full md:w-1/2 gap-2">
                                    <Typography
                                        variant="input-label"
                                        color={companyDisable ? "" : "tertiary"}
                                        className={companyDisable ? "text-border" : ""}
                                    >
                                        Principal Broker Phone
                                    </Typography>
                                    <TextField
                                        className={`w-full`}
                                        maxLength={12}
                                        name="principalBrokerPhone"
                                        value={values.principalBrokerPhone}
                                        onChange={(e) => handleInputChange(e)}
                                        disabled={companyDisable}
                                        inputClassName={`${values?.principalBrokerPhone?.length != 0 ? "bg-[#E5E7EB]" : ""
                                            }`}
                                    />
                                    <div className="relative">
                                        {errorBrokerPhone && (
                                            <Typography variant="caption" className="text-[#E01010] absolute -mt-2">
                                                Please enter a valid phone number
                                            </Typography>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="flex flex-col 2xl:flex-row items-start justify-between bg-white p-6 2xl:gap-20 xl:gap-8 rounded-lg">
                        <div className="flex flex-col xl:min-w-[300px] xl:pb-0 pb-8">
                            <Typography variant="h2" color="primary">
                                Company Logo
                            </Typography>
                        </div>
                        <div className="w-full flex flex-col gap-4">
                            <div className="flex flex-col md:flex-row gap-4 items-center">
                                <img
                                    src={
                                        avatarURL !== ""
                                            ? avatarURL
                                            : values.businessLogo
                                                ? values.businessLogo
                                                : DefaultCompanyLogo
                                    }
                                    alt="addProfile"
                                    className="cursor-pointer w-[75px] h-[75px] rounded"
                                    onClick={() => openDialog()}
                                    onError={(e) => (e.currentTarget.src = DefaultCompanyLogo)}
                                />
                                <input
                                    type="file"
                                    ref={avatarRef}
                                    hidden
                                    onChange={(e) => changeFile((e.target as any).files)}
                                    disabled={companyDisable}
                                />
                                <div
                                    className="flex cursor-pointer items-center border-1 border-[#D4D4D8] rounded-2xl text-sm h-6 px-4"
                                    onClick={() => openDialog()}
                                >
                                    Upload
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
            {!props.isCompanyDetailsHidden && !props.isTeamDetailsHidden && (
                <div className="border-b border-gray-200 w-full mt-[25px]"></div>
            )}
            {!props.isTeamDetailsHidden && (
                <>
                    <div
                        className={` lg2:flex items-start justify-between ${!props.isCompanyDetailsHidden && `mt-[50px]`
                            }`}
                    >
                        <div className={`${teamDisable ? "opacity-40" : ""}`}>
                            <Typography variant="h2" color="primary">
                                Team Details
                            </Typography>
                            <Typography variant="body" color="secondary" className="text-[11px] leading-[15px] mt-2 ">
                                If you are a member of a team, add it here.
                            </Typography>
                            <div className="flex items-center gap-5 mt-5">
                                <img
                                    src={
                                        avatarTeamURL !== ""
                                            ? avatarTeamURL
                                            : values.teamLogo
                                                ? values.teamLogo
                                                : AddTeam
                                    }
                                    alt="addProfile"
                                    className="cursor-pointer w-[75px] h-[75px] rounded"
                                    onClick={() => openDialogTeam()}
                                />
                                <input
                                    type="file"
                                    ref={avatarTeamRef}
                                    hidden
                                    onChange={(e) => changeFileTeam((e.target as any).files)}
                                    disabled={teamDisable}
                                />
                            </div>
                        </div>
                        <div className="w-full lg2:max-w-[670px] mt-[25px] lg2:mt-[0px]">
                            <div>
                                <div className="grid grid-cols-1 mb-[25px]">
                                    <div className="col-span-1">
                                        <Typography variant="input-label" className="text-secondary ">
                                            Team Name
                                        </Typography>
                                        <AutoComplete
                                            options={teams}
                                            filterKey="teamName"
                                            placeholder="Search by typing your Team's name or add a new one"
                                            value={values.teamName}
                                            onChange={onChangeAutoCompleteTeam}
                                            onAllChange={onAllChangeAutoCompleteTeam}
                                        />
                                    </div>
                                </div>
                                <div className={`mb-[25px] flex items-center gap-2 ${teamDisable ? "opacity-40" : ""}`}>
                                    <input
                                        type="checkbox"
                                        className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                                        disabled={teamDisable}
                                        checked={isLeader}
                                        onChange={(e) => handleIsLeader(e.target.checked)}
                                    />
                                    <Typography variant="body">I am the team leader.</Typography>
                                </div>
                                <div
                                    className={`grid grid-cols-2 md:gap-3 mb-[25px] ${teamDisable ? "opacity-40" : ""}`}
                                >
                                    <div className="col-span-2 md:col-span-1">
                                        <Typography variant="input-label" className="text-secondary ">
                                            Team Leader First Name
                                        </Typography>
                                        <TextField
                                            className="w-full"
                                            disabled={teamDisable}
                                            name="teamLeaderFirstName"
                                            value={values.teamLeaderFirstName}
                                            onChange={(e) => handleInputChange(e)}
                                        />
                                    </div>
                                    <div className="col-span-2 md:col-span-1 mt-[25px] md:mt-[0px]">
                                        <Typography variant="input-label" className="text-secondary ">
                                            Team Leader Last Name
                                        </Typography>
                                        <TextField
                                            className="w-full"
                                            disabled={teamDisable}
                                            name="teamLeaderLastName"
                                            value={values.teamLeaderLastName}
                                            onChange={(e) => handleInputChange(e)}
                                        />
                                    </div>
                                </div>
                                <div
                                    className={`grid grid-cols-2 md:gap-3 mb-[25px] ${teamDisable ? "opacity-40" : ""}`}
                                >
                                    <div className="col-span-2 md:col-span-1">
                                        <Typography variant="input-label" className="text-secondary ">
                                            Team Leader Broker Email
                                        </Typography>
                                        <TextField
                                            className={`w-full ${errorTeamLeaderBrokerEmail
                                                    ? "outline outline-1 rounded outline-[#E01010]"
                                                    : ""
                                                }`}
                                            disabled={teamDisable}
                                            name="teamLeaderBrokerEmail"
                                            value={values.teamLeaderBrokerEmail}
                                            onChange={(e) => handleInputChange(e)}
                                        />
                                        {errorTeamLeaderBrokerEmail && (
                                            <Typography
                                                variant="input-label"
                                                className="text-[#E01010] absolute mt-[2px]"
                                            >
                                                Invalid Email Address
                                            </Typography>
                                        )}
                                    </div>
                                    <div className="col-span-2 md:col-span-1 mt-[25px] md:mt-[0px] relative">
                                        <Typography variant="input-label" className="text-secondary ">
                                            Team Leader Phone
                                        </Typography>
                                        <TextField
                                            className={`w-full`}
                                            disabled={teamDisable}
                                            maxLength={12}
                                            name="teamLeaderPhone"
                                            value={values.teamLeaderPhone}
                                            onChange={(e) => handleInputChange(e)}
                                        />
                                        {errorLeaderPhone && (
                                            <Typography
                                                variant="input-label"
                                                className="text-[#E01010] absolute mt-[2px]"
                                            >
                                                Please enter a valid phone number
                                            </Typography>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            )}
            <div className={`flex justify-end items-center mb-12 gap-4 px-4 mt-6 xl:hidden`}>
                <Button color="inherit" className="border-1 border-[#D4D4D8]">
                    Cancel
                </Button>
                <Button color="primary" disabled={submitDisable} onClick={() => updateCompanyTeam()}>
                    Save Changes
                </Button>
            </div>
        </div>
    );
};
export default TeamInfo;
