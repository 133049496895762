import { useState, Fragment, useEffect } from 'react'
import Typography from '@/components/baseComponents/Typography'
import TextField from "@/components/baseComponents/TextField"
import Textarea from '@/components/baseComponents/Textarea'
import Checkbox from '@/components/baseComponents/Checkbox'
import { Button } from '@/components/baseComponents/Button';
import { Dialog, Transition } from '@headlessui/react'
import { CreateHomepageFeedDto, UpdateHomepageFeedDto, IHomepageFeed, GetHomepageFeedsDto } from '@/shared/interfaces/interfaces'
import { createHomepageFeed, updateHomepageFeed } from '@/redux/homepageFeed/homepageFeedSlice';
import validation from '@/shared/services/validation'
import { notify } from '@/shared/services/notify'
import XMark from '@/assets/icons/XMark.png'
import { useAppDispatch } from '@/redux/hooks'

interface IProps {
    open: boolean
    closeModal: Function
    search: GetHomepageFeedsDto
    value: IHomepageFeed
}

const CreateModal = (props: IProps) => {
    const closeModal = props.closeModal
    const dispatch = useAppDispatch()
    const defaultValue: IHomepageFeed = {
        title: '',
        url: '',
        urlLabel: '',
        content: '',
    }

    const [isNew, setIsNew] = useState<boolean>(true)
    const [values, setValues] = useState<IHomepageFeed>(defaultValue)
    const [errorTitle, setErrorTitle] = useState<boolean>(false)
    const [errorContent, setErrorContent] = useState<boolean>(false)

    const handleInputChange = (e: any) => {
        const { name, value } = e.target
        setValues({
            ...values,
            [name]: value
        })
        
        switch (name) {
            case "title":
                setErrorTitle(validation.IsEmptyString(value))
                break
            case "content":
                setErrorContent(validation.IsEmptyString(value))
                break
        }
    }

    const handleCheckboxChange = (e: any) => {
        const { name, checked } = e.target
        if (name === 'isMuted') {
            setValues({
                ...values,
                [name]: checked ? 1 : 0
            })
        } else if (name === 'status') {
            setValues({
                ...values,
                [name]: checked ? 'active' : 'inactive'
            })
        }
    }

    const submitHomepageFeed = () => {
        setErrorTitle(validation.IsEmptyString(values.title))
        setErrorContent(validation.IsEmptyString(values.content))

        if (!validation.IsEmptyString(values.title) &&
            !validation.IsEmptyString(values.content)
        ) {
            if (isNew) {
                const data: CreateHomepageFeedDto = {
                    data: values,
                    search: props.search
                }
    
                dispatch(createHomepageFeed(data)).then((res) => {
                    try {
                        notify(res.payload.success, res.payload.message)
                    } catch (e) {
                        notify(false, 'Something went wrong.')
                    }
                }).finally(() => {
                    closeModal()
                })
            } else if (values._id !== undefined && values._id !== '') {
                const data: UpdateHomepageFeedDto = {
                    data: values,
                    id: values._id,
                    search: props.search
                }
    
                dispatch(updateHomepageFeed(data)).then((res: any) => {
                    try {
                        notify(res.payload.success, res.payload.message)
                    } catch (e) {
                        notify(false, 'Something went wrong.')
                    }
                }).finally(() => {
                    closeModal()
                })
            }
        }
    }
    
    useEffect(() => {
        if (props.value) {
            setIsNew(!(props.value._id !== undefined))
            setValues(props.value)
        }
    }, [props.value])

    return (
        <Transition.Root show={props.open} as={Fragment}>
            <Dialog as="div" className="relative z-30" onClose={() => props.closeModal()}>
                <div className="fixed inset-0 flex items-center justify-center z-50">
                    <Dialog.Panel className='rounded-lg max-w-[800px] w-full bg-white'>
                        <div className='px-10 py-3'>
                            <div className='flex justify-between items-center'>
                                <Typography variant='h2' className='mt-[8px] text-[#4C42D7]'>Create Homepage Feed</Typography>
                                <img src={XMark} alt="XMark" className='cursor-pointer w-4 h-4' onClick={() => closeModal()} />
                            </div>
                            <div className='px-4 border-[1px] border-[#4C42D7] w-full'></div>
                        </div>
                        <div className='flex flex-col gap-4 px-16 py-3 h-[70vh] overflow-y-auto'>
                            <div className='relative mt-2'>
                                <Typography variant='body' className='text-secondary mb-[5px]'>Title *</Typography>
                                <TextField className={`w-full ${errorTitle ? 'outline outline-1 rounded outline-[#E01010]' : ''}`} name="title" value={values.title} onChange={(e) => handleInputChange(e)} />
                                {errorTitle && <Typography variant='caption' className='text-[#E01010] absolute mt-[2px]'>This field is required</Typography>}
                            </div>
                            <div className='relative mt-2'>
                                <Typography variant='body' className='text-secondary mb-[5px]'>Content *</Typography>
                                <Textarea className={`w-full ${errorContent ? 'outline outline-1 rounded outline-[#E01010]' : ''}`} name="content" value={values.content} onChange={(e) => handleInputChange(e)} />
                                {errorContent && <Typography variant='caption' className='text-[#E01010] absolute mt-[2px]'>This field is required</Typography>}
                            </div>
                            <div className='relative mt-2'>
                                <Typography variant='body' className='text-secondary mb-[5px]'>URL</Typography>
                                <TextField className="w-full" name="url" value={values.url} onChange={(e) => handleInputChange(e)} />
                            </div>
                            <div className='relative mt-2'>
                                <Typography variant='body' className='text-secondary mb-[5px]'>URL Label</Typography>
                                <TextField className="w-full" name="urlLabel" value={values.urlLabel} onChange={(e) => handleInputChange(e)} />
                            </div>
                            <div className='flex flex-row items-center gap-2 mt-2'>
                                <Checkbox variant='primary' inputSize='medium' name='isMuted' checked={values.isMuted === 1} onChange={(e) => handleCheckboxChange(e)}/>
                                <Typography variant='body' className='text-secondary'>Add 24-Hour Mute</Typography>
                            </div>
                            <div className='flex flex-row items-center gap-2 mt-2'>
                                <Checkbox variant='primary' inputSize='medium' name='status' checked={values.status === 'active'} onChange={(e) => handleCheckboxChange(e)}/>
                                <Typography variant='body' className='text-secondary'>Activate This Homepage Feed</Typography>
                            </div>
                            <div className="flex justify-center mt-8 mb-16">
                                <Button className="w-[200px] text-15" onClick={() => submitHomepageFeed()}>Submit</Button>
                            </div>
                        </div>
                    </Dialog.Panel>
                </div>
            </Dialog>
        </Transition.Root>
    )
}
export default CreateModal;