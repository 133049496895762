// icons
import IconAlert from "./list/IconAlert";
import IconArrowBackward from "./list/IconArrowBackward";
import IconArrowBackwardMultiple from "./list/IconArrowBackwardMultiple";
import IconArrowForward from "./list/IconArrowForward";
import IconArrowForwardMultiple from "./list/IconArrowForwardMultiple";
import IconArrowTopBottom from "./list/IconArrowTopBottom";
import IconCalendar from "./list/IconCalendar";
import IconClose from "./list/IconClose";
import IconCloseRoundFill from "./list/IconCloseRoundFill";
import IconEye from "./list/IconEye";
import IconMenu from "./list/IconMenu";
import IconMoney from "./list/IconMoney";
import IconSearch from "./list/IconSearch";
import IconUser from "./list/IconUser";
import IconHome from "./list/IconHome";
import IconLeads from "./list/IconLeads";
import IconClients from "./list/IconClients";
import IconListings from "./list/IconListings";
import IconShowings from "./list/IconShowings";
import IconOffers from "./list/IconOffers";
import IconHelp from "./list/IconHelp";
import IconNext from "./list/IconNext";
import IconTrashCan from "./list/IconTrashCan";
import IconPlus from "./list/IconPlus";
import IconTelephone from "./list/IconTelephone";
import IconEnvelope from "./list/IconEnvelope";
import IconLeaf from "./list/IconLeaf";
import IconProtect from "./list/IconProtect";
import IconWarning from "./list/IconWarning";
import IconThunder from "./list/IconThunder";
import IconMessage from "./list/IconMessage";
import IconImage from "./list/IconImage";
import IconCheckList from "./list/IconCheckList";
import IconCheckPlusCircle from "./list/IconCheckPlusCircle";
import IconDashboard from "./list/IconDashboard";
import IconRelationships from "./list/IconRelationships";
import IconTransactions from "./list/IconTransactions";
import IconRealEstateBrokers from "./list/IconRealEstateBrokers";
import IconBrokerage from "./list/IconBrokerage";
import IconTeam from "./list/IconTeam";
import IconAgents from "./list/IconAgents";
import IconTransactionNote from "./list/IconTransactionNote";
import IconFolder from "./list/IconFolder";
import IconComment from "./list/IconComment";
import IconUpload from "./list/IconUpload";
import IconUploadCloud from "./list/IconUploadCloud";
import IconBookmarkHeart from "./list/IconBookmarkHeart";
import IconBookmarkPlus from "./list/IconBookmarkPlus";
import IconBookmarkMinus from "./list/IconBookmarkMinus";
import IconMessageSend from "./list/IconMessageSend";
import IconHomeAlt from "./list/IconHomeAlt";
import IconBed from "./list/IconBed";
import IconBath from "./list/IconBath";
import IconGraph from "./list/IconGraph";
import IconBookmark from "./list/IconBookmark";
import IconHeartBeat from "./list/IconHeartBeat";
import IconSort from "./list/IconSort";
import IconFilter from "./list/IconFilter";
import IconMoreVertical from "./list/IconMoreVertical";
import IconWelcome from "./list/IconWelcome";
import IconArrowUp from "./list/IconArrowUp";
import IconArrowDown from "./list/IconArrowDown";
import IconPanelLeftClose from "./list/IconPanelLeftClose";
import IconPanelRightOpen from "./list/IconPanelRightOpen";
import IconChat from "./list/IconChat";
import IconChatBlue from "./list/IconChatBlue";
import IconPanelLeftCloseAlt from "./list/IconPanelLeftCloseAlt";
import IconPanelRightOpenAlt from "./list/IconPanelRightOpenAlt";
import IconSentCheck from "./list/IconSentCheck";
import IconReceivedCheck from "./list/IconReceivedCheck";
import IconLink from "./list/IconLink";
import IconNextSlide from "./list/IconNextSlide";
import IconPrevSlide from "./list/IconPrevSlide";
import IconDot from "./list/IconDot";
import IconMinus from "./list/IconMinus";
import IconEdit from "./list/IconEdit";
import IconArchive from "./list/IconArchive";
import IconHouse from "./list/IconHouse";
import IconHouseSpace from "./list/IconHomeSpace";
import IconLotSpace from "./list/IconLotSpace";
import IconParking from "./list/IconParking";
import IconCool from "./list/IconCool";
import IconHeating from "./list/IconHeating";
import IconGarage from "./list/IconGarage";
import IconPool from "./list/IconPool";
import IconShare from "./list/IconShare";
import IconCamera from "./list/IconCamera";
import IconCompanyTeam from "./list/IconCompanyTeam";
import IconSetting from "./list/IconSetting";
import IconCredential from "./list/IconCredential";
import IconProfileUser from "./list/IconProfileUser";
import IconDoorOpen from "./list/IconDoorOpen";
import IconSubmitOffer from "./list/IconSubmitOffer";
import IconPercentage from "./list/IconPercentage";
import IconBack from "./list/IconBack";
import IconUserCheck from "./list/IconUserCheck";
import IconPencil from "./list/IconPencil";
import IconUserRevert from "./list/IconUserRevert";
import IconIndicator from "./list/IconIndicator";
import IconArrowRight from "./list/IconArrowRight";
import IconFile from "./list/IconFile";
import IconSingleCheck from "./list/IconSingleCheck";
import IconExpand from "./list/IconExpand";
import IconSpinner from "./list/IconSpinner";
import IconVerified from "./list/IconVerified";
import IconCheckCircle from "./list/IconCheckCircle";
import IconNotebook from "./list/IconNotebook";
import IconAds from "./list/IconAds";
import IconVerifiedFilled from "./list/IconVerifiedFilled";
import IconInfo from "./list/IconInfo";
import IconBedNew from "./list/IconBedNew";
import IconHouseNew from "./list/IconHouseSpace";
import IconBathNew from "./list/IconBathNew";
import IconFacebook from "./list/IconFacebook";
import IconInstagram from "./list/IconInstagram";
import IconDownCircular from "./list/IconDownCircular";
import IconActive from "./list/IconActive";
import IconPropertyType from "./list/IconPropertyType";
import IconSqft from "./list/IconSqrt";
import IconInPerson from "./list/IconInPerson";
import IconVirtual from "./list/IconVirtual";
import IconRequestTour from "./list/IconRequestTour";
import IconDocument from "./list/IconDocument";
import IconPhoto from "./list/IconPhoto";
import IconCreditCard from "./list/IconCreditCard";
import IconFollow from "./list/IconFollow";
import IconUnfollow from "./list/IconUnfollow";
import IconAlertCircle from "./list/IconAlertCircle";
import IconAlertTriangle from "./list/IconAlertTriangle";
import IconLock from "./list/IconLock";
import IconHumberger from "./list/IconHumberger";
import IconHeart from "./list/IconHeart";
import IconLinkExternal from "./list/IconLinkExternal";
import IconArrowDownTail from "./list/IconArrowDownTail";
import Icon404 from "./list/Icon404";

// ----------------------------------------------------------------------
// ----------------------------------------------------------------------

export type IconType = keyof typeof IconList;
export const IconList = {
  "arrow-top-bottom": IconArrowTopBottom,
  "close-round-fill": IconCloseRoundFill,
  "arrow-backward": IconArrowBackward,
  "arrow-backward-multiple": IconArrowBackwardMultiple,
  "arrow-forward": IconArrowForward,
  "arrow-forward-multiple": IconArrowForwardMultiple,
  "arrow-up": IconArrowUp,
  "arrow-down": IconArrowDown,
  "arrow-right": IconArrowRight,
  "trash-can": IconTrashCan,
  "check-list": IconCheckList,
  "check-plus-circle": IconCheckPlusCircle,
  "real-estate-brokers": IconRealEstateBrokers,
  "transaction-note": IconTransactionNote,
  "upload-cloud": IconUploadCloud,
  "bookmark-heart": IconBookmarkHeart,
  "bookmark-plus": IconBookmarkPlus,
  "bookmark-minus": IconBookmarkMinus,
  "message-send": IconMessageSend,
  "home-alt": IconHomeAlt,
  "heart-beat": IconHeartBeat,
  "more-vertical": IconMoreVertical,
  "panel-left-close": IconPanelLeftClose,
  "panel-left-close-alt": IconPanelLeftCloseAlt,
  "panel-right-open": IconPanelRightOpen,
  "panel-right-open-alt": IconPanelRightOpenAlt,
  eye: IconEye,
  money: IconMoney,
  search: IconSearch,
  close: IconClose,
  calendar: IconCalendar,
  alert: IconAlert,
  menu: IconMenu,
  user: IconUser,
  home: IconHome,
  leads: IconLeads,
  clients: IconClients,
  listings: IconListings,
  showings: IconShowings,
  offers: IconOffers,
  help: IconHelp,
  next: IconNext,
  plus: IconPlus,
  telephone: IconTelephone,
  envelope: IconEnvelope,
  leaf: IconLeaf,
  protect: IconProtect,
  warning: IconWarning,
  thunder: IconThunder,
  message: IconMessage,
  image: IconImage,
  dashboard: IconDashboard,
  relationships: IconRelationships,
  transactions: IconTransactions,
  agents: IconAgents,
  team: IconTeam,
  brokerage: IconBrokerage,
  comment: IconComment,
  folder: IconFolder,
  upload: IconUpload,
  bed: IconBed,
  bath: IconBath,
  graph: IconGraph,
  bookmark: IconBookmark,
  filter: IconFilter,
  sort: IconSort,
  welcome: IconWelcome,
  chat: IconChat,
  sent: IconSentCheck,
  recieved: IconReceivedCheck,
  link: IconLink,
  "next-slider": IconNextSlide,
  "prev-slider": IconPrevSlide,
  dot: IconDot,
  minus: IconMinus,
  edit: IconEdit,
  archive: IconArchive,
  house: IconHouse,
  "house-space": IconHouseSpace,
  lot: IconLotSpace,
  parking: IconParking,
  cool: IconCool,
  heating: IconHeating,
  garage: IconGarage,
  pool: IconPool,
  share: IconShare,
  camera: IconCamera,
  setting: IconSetting,
  "profile-user": IconProfileUser,
  "company-team": IconCompanyTeam,
  credential: IconCredential,
  "door-open": IconDoorOpen,
  "submit-offer": IconSubmitOffer,
  "user-check": IconUserCheck,
  "user-revert": IconUserRevert,
  percentage: IconPercentage,
  back: IconBack,
  pencil: IconPencil,
  indicator: IconIndicator,
  file: IconFile,
  "chat-blue": IconChatBlue,
  "single-check": IconSingleCheck,
  expand: IconExpand,
  spinner: IconSpinner,
  verified: IconVerified,
  "verified-filled": IconVerifiedFilled,
  "check-circle": IconCheckCircle,
  notebook: IconNotebook,
  ads: IconAds,
  info: IconInfo,
  "external-link": IconLinkExternal,
  "bed-new": IconBedNew,
  "house-space-new": IconHouseNew,
  "bath-new": IconBathNew,
  facebook: IconFacebook,
  instagram: IconInstagram,
  "down-circular": IconDownCircular,
  active: IconActive,
  "property-type": IconPropertyType,
  sqft: IconSqft,
  "in-person": IconInPerson,
  virtual: IconVirtual,
  "request-tour": IconRequestTour,
  document: IconDocument,
  photo: IconPhoto,
  "credit-card": IconCreditCard,
  "alert-circle": IconAlertCircle,
  "alert-triangle": IconAlertTriangle,
  follow: IconFollow,
  unfollow: IconUnfollow,
  lock: IconLock,
  humberger: IconHumberger,
  heart: IconHeart,
  "link-external": IconLinkExternal,
  "arrow-down-tail": IconArrowDownTail,
  icon404: Icon404
};
