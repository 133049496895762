import DefaultListingImage from "@/assets/images/default_listing_image.webp";
import IconWrapper from "@/components/baseComponents/IconWrapper";
import Slider from "react-slick";

type IProps = {
  data: any;
  onSelect?: Function;
  isCenter?: boolean;
  centerPadding?: string;
  slidesToShow?: number;
  className?: string;
};

function SampleNextArrow(props: any) {
  const { className, onClick } = props;
  return (
    <div className={className} onClick={(event) => { event.stopPropagation(); onClick(); }}>
      <IconWrapper name="next-slider" width={32} />
    </div>
  );
}

function SamplePrevArrow(props: any) {
  const { className, onClick } = props;
  return (
    <div className={className} onClick={(event) => { event.stopPropagation(); onClick(); }}>
      <IconWrapper name="prev-slider" width={32} />
    </div>
  );
}

const PropertyCarousel = ({ data, onSelect, isCenter, centerPadding, slidesToShow, className }: IProps) => {
  const settings = {
    infinite: true,
    slidesToShow: slidesToShow ? slidesToShow : 2, 
    slidesToScroll: 1,
    speed: 500,
    afterChange: (index: number) => onSelect && onSelect(index),
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    centerMode: isCenter ? isCenter : false,
    centerPadding: centerPadding ? centerPadding : '0%',
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: slidesToShow ? slidesToShow : 2,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: slidesToShow ? slidesToShow : 2,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: slidesToShow ? slidesToShow : 1,
          slidesToScroll: 1
        }
      }
    ]
  };

  return (
    <Slider {...settings} className=" relative h-full">
      {data && data.length > 0
        ? data.length < 3
          ? [...data, Array(2)].map((propertyPhoto, index) => (
            <img
              key={index}
              src={
                propertyPhoto?.file
                  ? propertyPhoto?.isFile
                    ? URL.createObjectURL(propertyPhoto?.file)
                    : propertyPhoto?.file
                  : DefaultListingImage
              }
              onLoad={(e) => {
                if (e.currentTarget.src.includes("default_listing_image.webp")) {
                  e.currentTarget.classList.add('!object-scale-down');
                }
              }}
              onError={(e) => {
                  e.currentTarget.src = DefaultListingImage;
                  e.currentTarget.classList.add('!object-scale-down');
              }}
              className={`rounded-md w-[226px] h-[195px] object-cover object-center bg-[#f4f5f6] ${className}`}
            />
          ))
          : [...data]
            .sort((a, b) => a?.order - b?.order)
            .map((propertyPhoto, index) => (
              <img
                key={index}
                src={
                  propertyPhoto?.isFile
                    ? URL.createObjectURL(propertyPhoto?.file)
                    : propertyPhoto?.file
                }
                onError={(e) => {
                    e.currentTarget.src = DefaultListingImage;
                    e.currentTarget.classList.add('!object-scale-down');
                }}
                className={`rounded-md  w-[226px] h-[195px] object-cover object-center bg-[#f4f5f6] ${className}`}
              />
            ))
        : [...Array(3)].map((placeholder, index) => (
          <img
            key={placeholder + "_" + index}
            src={DefaultListingImage}
            className={`rounded-md  w-[226px] h-[195px] object-scale-down object-center bg-[#f4f5f6] ${className}`}
          />
        ))}
    </Slider>
  );
};

export default PropertyCarousel;
