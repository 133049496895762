import { Fragment } from "react";
import { Transition, Menu } from "@headlessui/react";
import Typography from "@/components/baseComponents/Typography";
import IconWrapper from "../IconWrapper";

type IAdditionalActionProps = {
  text: string;
  icon: React.ReactNode;
  function: Function;
  textClassName?: string;
};

type ITableActionPopoverProps = {
  data?: any;
  onEdit?: Function;
  onArchive?: Function;
  additionalActions?: Array<IAdditionalActionProps>;
};

const TableActionPopover = ({
  data,
  onEdit,
  onArchive,
  additionalActions,
}: ITableActionPopoverProps) => {
  return (
    <>
      <div className="relative text-right z-20">
        <Menu as="div" className="relative inline-block text-left">
          {({ open }) => (
            <>
              <div>
                <Menu.Button
                  className={`${open && "bg-gray-100"} p-[4px] rounded-[8px]`}
                >
                  <span
                    className={`hover:opacity-50 ${
                      open && "bg-gray-100 opacity-70"
                    }`}
                  >
                    <IconWrapper name="more-vertical" />
                  </span>
                </Menu.Button>
              </div>
              <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <Menu.Items className="absolute  right-0 top-full mt-2 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 overflow-hidden">
                  <div className="px- py-0">
                    {onEdit && (
                      <Menu.Item>
                        <div
                          className="pl-3 pr-8 py-2 flex gap-3 items-center cursor-pointer hover:bg-gray-100"
                          onClick={() => onEdit(data)}
                        >
                          <IconWrapper name="edit" width={16} />
                          <Typography variant="button2">Edit</Typography>
                        </div>
                      </Menu.Item>
                    )}
                    {onArchive && (
                      <Menu.Item>
                        <div
                          className="pl-3 pr-8 py-2 flex gap-3 items-center cursor-pointer hover:bg-gray-100"
                          onClick={() => onArchive(data)}
                        >
                          <IconWrapper name="archive" width={16} />
                          <Typography
                            variant="button2"
                            className="text-red-600"
                          >
                            Archive
                          </Typography>
                        </div>
                      </Menu.Item>
                    )}
                    {additionalActions &&
                      additionalActions.length > 0 &&
                      additionalActions.map((action, index) => {
                        return (
                          <Menu.Item>
                            <div
                              key={index}
                              className="pl-3 pr-8 py-2 flex gap-3 items-center cursor-pointer hover:bg-gray-100"
                              onClick={() => action.function(data)}
                            >
                              <Typography
                                variant="button2"
                                className={action.textClassName}
                              >
                                {action.text}
                              </Typography>{" "}
                              {action.icon}
                            </div>
                          </Menu.Item>
                        );
                      })}
                  </div>
                </Menu.Items>
              </Transition>
            </>
          )}
        </Menu>
      </div>
    </>
  );
};
export default TableActionPopover;
