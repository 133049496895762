import React from "react";
import Typography from "../Typography";
import { useOutletContext } from "react-router-dom";
import IconWrapper from "../IconWrapper";
import { Button } from "../Button";

// Define the props for the Pagination component
interface IProps {
  totalPage: number;
  currentPage: number;
  onSetPage: Function;
  loadingData: boolean;
}

const Pagination = ({
  totalPage,
  currentPage,
  onSetPage,
  loadingData,
}: IProps) => {
  const [scrollbars]: any = useOutletContext();
  const normalClass = "cursor-pointer select-none text-secondary";
  const activeClass = "cursor-pointer select-none text-primary";

  // Scroll to top when the current page changes
  React.useEffect(() => {
    scrollbars.current?.scrollToTop();
  }, [currentPage]);

  // Render the pagination component
  return (
    <div
      className={`flex max-sm:gap-[16px] gap-[24px] items-center ${
        loadingData ? "pointer-events-none" : ""
      }`}
    >
      {/* Render the backward arrow and page numbers */}
      {currentPage == 1 ? (
        ""
      ) : (
        <Button
          variant="icon"
          disabled={currentPage === 1}
          className="disabled:bg-transparent disabled:cursor-default group h-8"
          onClick={() => {
            onSetPage(currentPage > 1 ? currentPage - 1 : 1);
          }}
        >
          <IconWrapper
            name="arrow-backward"
            className="group-disabled:[&>svg>path]:!stroke-gray-400"
            width={18}
          />
        </Button>
      )}

      {/* Render the page numbers */}
      {currentPage > 1 ? (
        <Typography
          variant="body"
          className={normalClass}
          onClick={() => {
            onSetPage(1);
          }}
        >
          1
        </Typography>
      ) : (
        <></>
      )}
      {currentPage > 3 && totalPage <= 4 ? (
        <Typography
          variant="body"
          className={normalClass}
          onClick={() => {
            onSetPage(2);
          }}
        >
          2
        </Typography>
      ) : (
        <></>
      )}
      {currentPage > 3 && totalPage > 4 ? (
        <Typography variant="body" className={normalClass}>
          ...
        </Typography>
      ) : (
        <></>
      )}
      {currentPage > 2 ? (
        <Typography
          variant="body"
          className={normalClass}
          onClick={() => {
            onSetPage(currentPage - 1);
          }}
        >
          {currentPage - 1}
        </Typography>
      ) : (
        <></>
      )}
      <Typography variant="body" className={activeClass}>
        {currentPage}
      </Typography>

      {currentPage + 1 < totalPage ? (
        <Typography
          variant="body"
          className={normalClass}
          onClick={() => {
            onSetPage(currentPage + 1);
          }}
        >
          {currentPage + 1}
        </Typography>
      ) : (
        <></>
      )}
      {currentPage + 2 < totalPage && currentPage === 1 ? (
        <Typography
          variant="body"
          className={normalClass}
          onClick={() => {
            onSetPage(currentPage + 2);
          }}
        >
          {currentPage + 2}
        </Typography>
      ) : (
        <></>
      )}
      {totalPage > 3 && currentPage + 3 <= totalPage ? (
        <Typography variant="body" className={normalClass}>
          ...
        </Typography>
      ) : (
        <></>
      )}

      {totalPage > 1 && currentPage !== totalPage ? (
        <Typography
          variant="body"
          className={normalClass}
          onClick={() => {
            onSetPage(totalPage);
          }}
        >
          {totalPage}
        </Typography>
      ) : (
        <></>
      )}

      {/* Render the forward arrow */}
      <Button
        variant="icon"
        disabled={currentPage === (totalPage > 0 ? totalPage : 1)}
        className="disabled:bg-transparent disabled:cursor-default group h-8"
        onClick={() => {
          onSetPage(
            currentPage < (totalPage > 0 ? totalPage : 1)
              ? currentPage + 1
              : totalPage > 0
              ? totalPage
              : 1
          );
        }}
      >
        <IconWrapper
          name="arrow-forward"
          className="group-disabled:[&>svg>path]:!stroke-gray-400"
          width={18}
        />
      </Button>
    </div>
  );
};

export default Pagination;
