// Define the type for the component props
type Props = {
  width: number;
  height: number;
  stroke?: string;
  className?: string;
  innerstroke?: any;
};

// Define the IconAgents component
const IconReceivedCheck = (props: Props) => {
  // Return the SVG for the icon
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 16 16"
      fill="none"
      {...props}
    >
      <path
        d="M11.9997 4L4.66634 11.3333L1.33301 8"
        stroke={props.innerstroke ? props.innerstroke : "black"}
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.667 6.66602L9.66699 11.666L8.66699 10.666"
        stroke={props.innerstroke ? props.innerstroke : "black"}
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

// Export the IconAgents component
export default IconReceivedCheck;
