// Define the type for the component props
type Props = {
    width: number;
    height: number;
    stroke?: string;
    className?: string;
};

// Define the IconAgents component
const IconAgents = (props: Props) => {
    // Return the SVG for the icon
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" {...props}>
            <path d="M11 13.957H13C13.5304 13.957 14.0391 13.7463 14.4142 13.3712C14.7893 12.9962 15 12.4875 15 11.957C15 11.4266 14.7893 10.9179 14.4142 10.5428C14.0391 10.1677 13.5304 9.95703 13 9.95703H10C9.4 9.95703 8.9 10.157 8.6 10.557L3 15.957" stroke="#A3A9BA" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M7 19.9573L8.6 18.5573C8.9 18.1573 9.4 17.9573 10 17.9573H14C15.1 17.9573 16.1 17.5573 16.8 16.7573L21.4 12.3573C21.7859 11.9926 22.0111 11.4896 22.0261 10.9589C22.0411 10.4281 21.8447 9.91319 21.48 9.5273C21.1153 9.14141 20.6123 8.91619 20.0816 8.90119C19.5508 8.88619 19.0359 9.08263 18.65 9.4473L14.45 13.3473" stroke="#A3A9BA" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M2 14.957L8 20.957" stroke="#A3A9BA" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M19.1694 9.2576V5.00717L15.1072 1.85742L11.0449 5.00717V9.95679" stroke="#A3A9BA" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    );
};

// Export the IconAgents component
export default IconAgents;