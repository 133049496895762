import AddressAutoComplete from "@/components/baseComponents/AddressAutoComplete"
import TextField from "@/components/baseComponents/TextField"
import Typography from "@/components/baseComponents/Typography"

type IProps = {
    addresses: any[]
}

const LoanOfficer = ({
    addresses
}: IProps) => {
    
    return (
        <div className="mt-[50px]">
            <Typography variant="h3" color="primary">Loan Officer</Typography>
            <div className="grid grid-cols-2 gap-[15px] mt-[25px]">
                <div className="col-span-2 sm:col-span-1">
                    <Typography variant="caption" color="secondary">First Name</Typography>
                    <TextField name="loanOfficerFirstName" className={`w-full`} />
                </div>
                <div className="col-span-2 sm:col-span-1">
                    <Typography variant="caption" color="secondary">Last Name</Typography>
                    <TextField className={`w-full`} name="loanOfficerLastName" />
                </div>
            </div>
            <div className="mt-[25px]">
                <Typography variant="caption" color="secondary">Company Name</Typography>
                <TextField name="loanOfficerCompanyName" />
            </div>
            <div className="grid grid-cols-2 gap-[15px] mt-[25px]">
                <div className="col-span-2 sm:col-span-1 relative">
                    <Typography variant="caption" color="secondary">Phone Number</Typography>
                    <TextField className={`w-full`} maxLength={12} name="loanOfficerPhoneNumber" />
                </div>
                <div className="col-span-2 sm:col-span-1 relative">
                    <Typography variant="caption" color="secondary">Email Address</Typography>
                    <TextField className={`w-full`} name="loanOfficerEmailAddress" />
                </div>
            </div>
            <div className="mt-[25px]">
                <Typography variant="caption" color="secondary">Address</Typography>
                <AddressAutoComplete options={addresses} placeholder="Enter Other Agents's Address" value={""} onChange={(value) => console.log(value)} onAllChange={(value) => console.log(value)} />
            </div>
        </div>
    )
}

export default LoanOfficer