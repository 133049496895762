import LandingHero from "@/components/mainComponents/Landing/LandingHero";
import Landingheader from "./header";
import LandingFooter from "./Footer";

interface IProps {
  Component: any;
  isHome?: boolean;
}

const LandingLayout = ({ Component, isHome }: IProps) => {
  return (
    <div className="flex w-full flex-col bg-white">
      <Landingheader />
      {isHome && <LandingHero />}
      <div>
        <Component />
      </div>
      <LandingFooter />
    </div>
  );
};

export default LandingLayout;
