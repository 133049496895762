// Define the type for the component props
type Props = {
  width: number;
  height: number;
  stroke?: string;
  className?: string;
};

// Define the IconTeam component
const IconTeam = (props: Props) => {
  // Return the SVG for the icon
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      fill="none"
      {...props}
    >
      <path
        d="M18 21C18 18.8783 17.1571 16.8434 15.6569 15.3431C14.1566 13.8429 12.1217 13 10 13C7.87827 13 5.84344 13.8429 4.34315 15.3431C2.84285 16.8434 2 18.8783 2 21"
        stroke="#A3A9BA"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10 13C12.7614 13 15 10.7614 15 8C15 5.23858 12.7614 3 10 3C7.23858 3 5 5.23858 5 8C5 10.7614 7.23858 13 10 13Z"
        stroke="#A3A9BA"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21.9998 19.9992C21.9998 16.6292 19.9998 13.4992 17.9998 11.9992C18.6572 11.506 19.1829 10.8583 19.5304 10.1135C19.8778 9.36867 20.0363 8.54969 19.9918 7.72902C19.9473 6.90835 19.7012 6.1113 19.2753 5.40842C18.8493 4.70553 18.2567 4.11848 17.5498 3.69922"
        stroke="#A3A9BA"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

// Export the IconTeam component
export default IconTeam;
