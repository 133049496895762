import { useState, useEffect, useRef } from "react";
import {
  CreateMessageDto,
  GetMessagesDto,
  GetParticipantsDto,
  IMessageDto,
  IParticipantDto,
  UpdateReadStatus,
} from "@/shared/interfaces/interfaces";
import { useAppSelector, useAppDispatch } from "@/redux/hooks";
import { getUser } from "@/redux/user/userSlice";
import {
  createMessage,
  fetchMessages,
  fetchParticipants,
  getMessages,
  getParticipants,
  updateReadStatus,
  getParticipantId,
} from "@/redux/chat/chatSlice";
import ChatSidebar from "./components/ChatSidebar";
import ChatMessageArea from "./components/ChatMessageArea";
import ChatInputBox from "./components/ChatInputBox";
import { notify } from "@/shared/services/notify";
import { getListingById } from "@/redux/myListings/myListingSlice";
import useDimension from "@/shared/hooks/useDimension";
import Skeleton from "react-loading-skeleton";

const Chat = () => {
  const dispatch = useAppDispatch();
  const user = useAppSelector(getUser);
  const participantList = useAppSelector(getParticipants);
  const messages = useAppSelector(getMessages);
  const curParticipantId = useAppSelector(getParticipantId);

  const initialParticipant: IParticipantDto = {
    _id: "",
    name: "",
    isOnline: false,
  };

  const [selectedParticipant, setSelectedParticipant] =
    useState<IParticipantDto>(initialParticipant);
  const [participants, setParticipants] = useState<Array<IParticipantDto>>([]);
  const [viewMessages, setViewMessages] = useState<IMessageDto[]>([]);
  const [listing, setListing] = useState<any>();
  const targetRef = useRef<HTMLDivElement>(null);
  const { width } = useDimension({ targetRef });
  const [content, setContent] = useState<string>("");
  const [isTyping, setIsTyping] = useState(false);
  const [isSelected, setIsSelected] = useState(false);
  const [loading, setLoading] = useState(false);

  const addNewParticipant = (id: string) => {
    const messageData: IMessageDto = {
      sender: user?._id,
      receiver: id,
      content: "",
    };

    const createData: CreateMessageDto = {
      userId: user?._id,
      data: messageData,
    };

    dispatch(createMessage(createData));
  };

  const sendChatMessage = (content: string) => {
    if (curParticipantId && curParticipantId !== "") {
      const messageData: IMessageDto = {
        sender: user?._id,
        receiver: curParticipantId,
        content: content,
      };

      const createData: CreateMessageDto = {
        userId: user?._id,
        data: messageData,
      };

      dispatch(createMessage(createData));
    }
  };

  const exParticipants = (participantList: Array<any>) => {
    if (participantList && participantList.length > 0) {
      const participants: IParticipantDto[] = participantList.map(
        (participant: any) => {
          return {
            _id: participant?._id,
            name:
              (participant?.agent?.firstName ?? "") +
              (participant?.agent?.lastName
                ? " " + participant?.agent?.lastName
                : ""),
            firstName: participant?.agent?.firstName ?? "",
            avatarURL: participant?.agent?.avatarURL ?? "",
            unreadCount: participant?.unreadCount ?? 0,
            latestMessage: participant?.latestMessage?.content ?? "",
            latestTime: participant?.latestMessage?.createdAt ?? "",
            phoneNumber: participant?.agent?.mobileNumber ?? "",
            email: participant?.agent?.contactEmail ?? "",
            licenseType: participant?.agent?.licenseType ?? "",
            isRead: participant?.latestMessage?.isRead ?? "",
            isOnline: participant?.isOnline,
            companyName: participant?.agent?.company?.businessName ?? "",
            companyLogo: participant?.agent?.company?.businessLogo ?? "",
          };
        }
      );

      setParticipants(participants);
    }
  };

  const handleFocusMainArea = () => {
    if (messages.length > 0) {
      const unreadMessages = messages.filter(
        (message) => message.sender === curParticipantId && !message.isRead
      );

      if (unreadMessages.length > 0) {
        const ids: string[] = unreadMessages
          .map((message) => message?._id)
          .filter((id) => id !== undefined)
          .map((id) => id as string);

        var data: UpdateReadStatus = {
          ids: ids,
          userId: user?._id,
        };
        dispatch(updateReadStatus(data));
      }
    }
  };

  // get current listing, not well implemented but just to get some data i used a single bookmarked
  // Listing to represent some ui, this need to be changed to use the actual listing id thats coming in, if there is any

  const getListing = () => {
    dispatch(getListingById({ id: user.bookmarkedListings[0] }))
      .then((res) => {
        if (res.payload.success) {
          const data = res.payload.data;
          setListing(data[0]);
        } else {
          notify(false, "Something went wrong.");
        }
      })
      .catch(() => {
        notify(false, "Something went wrong.");
      });
  };

  useEffect(() => {
    exParticipants(participantList);
    getListing();
  }, [participantList]);

  useEffect(() => {
    if (user && user?._id && user?._id !== "" && curParticipantId !== "") {
      const data: GetMessagesDto = {
        userId: user?._id,
        participantId: curParticipantId,
      };
      // dispatch(setMessages([]));
      dispatch(fetchMessages(data));
    }
  }, [selectedParticipant, user]);

  useEffect(() => {
    if (
      curParticipantId !== null &&
      curParticipantId !== "" &&
      participants &&
      participants.length > 0
    ) {
      const curParticipant = participants.filter(
        (p) => p?._id === curParticipantId
      )[0];
      if (curParticipant && curParticipant?._id && curParticipant?._id !== "") {
        setSelectedParticipant(curParticipant);
      } else {
        addNewParticipant(curParticipantId);
      }
    }
  }, [curParticipantId, participants]);

  useEffect(() => {
    if (participants && participants.length > 0) {
      participants.map((participant) => {
        if (participant?._id === curParticipantId) {
          setSelectedParticipant(participant);
        }
      });
    }
  }, [participants]);

  useEffect(() => {
    if (user && user?._id && user?._id !== "") {
      setLoading(true);
      const data: GetParticipantsDto = {
        userId: user?._id,
      };
      dispatch(fetchParticipants(data)).then(_ => {
        setLoading(false);
      });
    }
  }, [user]);

  useEffect(() => {
    console.log(participants);
  }, []);

  const [allUnread, setAllUnread] = useState(0);
  // get all unread messages number
  const getAllUnread = () => {
    let totalUnread = 0;
    participants.forEach((participant) => {
      const isSender = user?._id === participant?._id;
      const participantUnreadCountExist = participant.unreadCount ?? 0;
      // set to 0 to see something
      if (!isSender) {
        if (participantUnreadCountExist >= 1) {
          totalUnread++; // Increment total count if unread is 1 or more
        }
      }
    });
    setAllUnread(totalUnread);
  };

  useEffect(() => {
    if (messages.length > 0) {
      const viewMessages = messages.filter((msg: IMessageDto) => {
        return (
          (msg.sender === user?._id && msg.receiver === curParticipantId) ||
          (msg.receiver === user?._id && msg.sender === curParticipantId)
        );
      });

      setViewMessages(viewMessages);
    }
    getAllUnread();
  }, [messages, user, curParticipantId]);

  useEffect(() => {
    setIsSelected(selectedParticipant?._id === curParticipantId);
  }, [selectedParticipant]);

  const [openMobileChatDrawer, setOpenMobileChatDrawer] = useState(false);
  const triggerChatDrawer = () => {
    setOpenMobileChatDrawer(true);
  };
  const closeChatDrawer = () => {
    setOpenMobileChatDrawer(false);
  };

  return (
    <div className="px-8 pt-10 pb-[25vh] relative" ref={targetRef}>
      <div className="h-[calc(100vh-110px)] max-sm:!h-[calc(100vh-180px)] border border-gray-100 flex items-start justify-center  rounded-[12px] shadow-sm overflow-hidden relative">
        {/* chat sidebar for desktop */}
        <div
          className={`w-full h-full hidden lg:flex border-r border-border ${
            width > 930 ? "lg:max-w-[320px] lg2:max-w-[400px]" : ""
          }`}
        >
          <ChatSidebar
            isTyping={isTyping}
            allUnread={allUnread}
            participantList={participants}
            loading={loading}
            openMobileChatDrawer={() => {
              if (width < 930) {
                triggerChatDrawer();
              }
            }}
          />
        </div>

        {/* chat sidebar for mobile*/}
        <div
          className={`w-full lg:max-w-[320px] lg2:max-w-[400px] h-full flex lg:hidden border-r border-border `}
        >
          <ChatSidebar
            isTyping={isTyping}
            openMobileChatDrawer={() => triggerChatDrawer()}
            allUnread={allUnread}
            participantList={participants}
          />
        </div>

        <div
          className={` ${
            width < 930 ? "hidden" : "hidden lg:block w-full h-full"
          }`}
        >
          <div
            className="relative flex flex-col h-full"
            tabIndex={1}
            onFocus={() => handleFocusMainArea()}
          >
            <ChatMessageArea
              messages={viewMessages}
              participant={selectedParticipant}
              chatListing={listing}
              loading={loading}
            />
            <div className="flex w-full sticky bottom-0 rounded-br-lg bg-white p-4">
              {loading ? (
                <div className="flex rounded-8 bg-[#F1F5F9] w-full items-center gap-2 px-4 py-3">
                  <Skeleton className="w-full h-5" />
                </div>
              ) : (
                <ChatInputBox
                  content={{ content: content, set: setContent }}
                  participants={participants}
                  isSelected={isSelected}
                  setIsTyping={setIsTyping}
                  sendChatMessage={sendChatMessage}
                />
              )}
            </div>
          </div>
        </div>

        <div
          className={` h-full absolute inset-0 flex transition-all duration-300 ease-out z-10 
          ${width > 930 ? "lg:!hidden" : ""}
          ${openMobileChatDrawer ? "translate-x-0" : "translate-x-full"} `}
        >
          <div
            className=" flex flex-col w-full h-full"
            tabIndex={1}
            onFocus={() => handleFocusMainArea()}
          >
            <ChatMessageArea
              messages={viewMessages}
              participant={selectedParticipant}
              chatListing={listing}
              closeDrawer={() => closeChatDrawer()}
            />
            <div className="flex w-full sticky bottom-0 rounded-br-lg bg-white p-4">
              <ChatInputBox
                content={{ content: content, set: setContent }}
                setIsTyping={setIsTyping}
                sendChatMessage={sendChatMessage}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Chat;
