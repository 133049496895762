import { Fragment } from 'react'
import Typography from '@/components/baseComponents/Typography'
import { Dialog, Transition } from '@headlessui/react'
import { IAdvertisement } from '@/shared/interfaces/interfaces'
import defaultBackground from '@/assets/images/event_background.png'
import XMark from '@/assets/icons/XMark.png'

interface IProps {
    open: boolean
    closeModal: Function
    value: IAdvertisement
}

const PreviewModal = (props: IProps) => {
    const closeModal = props.closeModal
    const handleNavigate = (url: string) => {
        window.open(url, "_blank")
    };

    return (
        <Transition.Root show={props.open} as={Fragment}>
            <Dialog as="div" className="relative z-30" onClose={() => props.closeModal()}>
                <div className="fixed inset-0 flex items-center justify-center z-50">
                    <Dialog.Panel className='rounded-lg max-w-[800px] w-full bg-white'>
                        <div className='px-10 py-3'>
                            <div className='flex justify-between items-center'>
                                <Typography variant='h2' className='mt-[8px] text-[#4C42D7]'>Preview</Typography>
                                <img src={XMark} alt="XMark" className='cursor-pointer w-4 h-4' onClick={() => closeModal()} />
                            </div>
                            <div className='px-4 border-[1px] border-[#4C42D7] w-full'></div>
                        </div>
                        <div className='flex items-center justify-center w-full h-[70vh]'>
                            <div className="border-b-1 grid grid-cols-3 p-[16px] w-1/3 gap-[14px] cursor-pointer" onClick={() => handleNavigate(props.value.adLinkURL)}>
                                <img
                                    className="rounded-lg object-cover object-center w-20 h-20"
                                    src={props.value?.adImageURL && props.value.adImageURL !== '' ? props.value.adImageURL : defaultBackground} alt={props.value.adTitle}
                                />
                                <div className="col-span-2 flex flex-col gap-[0px]">
                                    <Typography variant="h4">{props.value.adTitle}</Typography>
                                    <Typography variant="caption">{props.value.adContent}</Typography>
                                </div>
                            </div>
                        </div>
                    </Dialog.Panel>
                </div>
            </Dialog>
        </Transition.Root>
    )
}
export default PreviewModal;