import { useEffect } from 'react'
import { PDFDocument } from 'pdf-lib'
import { NumericFormat } from 'react-number-format';
import { IOffer, IMyListing, IDocument, IDocumentObject } from '@/shared/interfaces/interfaces'
import { FormatFileSize, formatSlashDate } from '@/shared/config/constants'
import { useAppSelector } from '@/redux/hooks';
import { getUser } from '@/redux/user/userSlice'
import Typography from "@/components/baseComponents/Typography"
import Button from "@/components/baseComponents/Button/Button"
import PropertyCarousel from '@/components/mainComponents/PropertyCarousel';
import CompanyAgentInfo from '@/components/CompanyAgentInfo'

type IProps = {
  selectedlisting?: IMyListing
  setOpenDocModal: Function
  sendOffer: Function
  values: IOffer
  mainDoc: IDocument
  additionalDocs: Array<IDocumentObject>
  setPreviewDoc: Function
}

const Review = (props: IProps) => {
  const user = useAppSelector(getUser)
  const buildOfferDocument = async () => {
    const { mainDoc, additionalDocs, setPreviewDoc } = props

    let pdfDoc: any
    // fill main document
    if (mainDoc !== undefined && mainDoc !== null && mainDoc.file !== '') {
      let matchSetting = {}
      try{
        matchSetting = JSON.parse(mainDoc.matchJson)
      } catch (e) {
        matchSetting = {}
      }
      const formUrl = mainDoc.file ?? ''
      const formPdfBytes = await fetch(formUrl).then((res) =>
        res.arrayBuffer(),
      );
      pdfDoc = await PDFDocument.load(formPdfBytes)
  
      const form = pdfDoc?.getForm()
      const fields = form.getFields()
      fields.forEach((field: any) => {
        const name = field.getName()
        var setting = Object.entries(matchSetting).filter(([_, value]) => {
          return value === name
        })
        if (setting && setting.length > 0) {
          var key = setting[0][0]
          var formValues: any = props.values
          field.setText(formValues[key] ?? '')
        }
      })
      form.flatten()
    }

    // merge additional documents
    if (additionalDocs && additionalDocs.length) {
      for (const doc of additionalDocs) {
        if (doc.file !== undefined && doc.file !== null && doc.file !== '') {
          let existingPdf: PDFDocument
          if (doc.isFile) {
            const pdfBytes = new Uint8Array(await (doc.file as File).arrayBuffer())
            existingPdf = await PDFDocument.load(pdfBytes)
          } else {
            const existingPdfBytes = await fetch(doc.file as string).then(res => res.arrayBuffer())
            existingPdf = await PDFDocument.load(existingPdfBytes)
          }
          var form = existingPdf.getForm()
          if (form) {
            form.flatten()
          }
  
          const copiedPages = await pdfDoc.copyPages(existingPdf, existingPdf.getPageIndices())
          copiedPages.forEach((page: any) => {
            pdfDoc.addPage(page)
          });
        }
      }
    }

    if (pdfDoc !== undefined) {
      const mergedPdfBytes = await pdfDoc.save()
      const blob = new Blob([mergedPdfBytes], { type: 'application/pdf' });
      const url = URL.createObjectURL(blob);
      setPreviewDoc(url)
    }
  }

  useEffect(() => {
    buildOfferDocument()
  }, [])

  return (
    <div>
      {props.selectedlisting && (
        <>
          <PropertyCarousel data={props.selectedlisting?.propertyPhotos} />
          <Typography variant="h3" color="primary" className="mt-[25px]">
            Address
          </Typography>
          <div className="flex w-full mt-[25px]">
            <div className="w-1/3 sm:w-full">
              <Typography
                variant="caption"
                color="secondary"

              >
                Address 1
              </Typography>
              <div>
                {props.selectedlisting?.listingAddress && props.selectedlisting?.listingAddress.city && props.selectedlisting?.listingAddress.state && props.selectedlisting?.listingAddress.zipcode ? (
                  <>
                    <div>
                      <Typography variant="body" color="primary" className="line-clamp-2">
                        {props.selectedlisting?.listingAddress?.streetLine ? props.selectedlisting?.listingAddress?.streetLine + " " : ""}
                        {props.selectedlisting?.listingAddress?.secondary ? props.selectedlisting?.listingAddress?.secondary + " " : ""}
                      </Typography>
                    </div>
                    <div>
                      <Typography variant="body" color="primary" className="line-clamp-1">
                        {props.selectedlisting?.listingAddress?.city + ", " + props.selectedlisting?.listingAddress?.state + " " + props.selectedlisting?.listingAddress?.zipcode}
                      </Typography>
                    </div>
                  </>
                ) : "-"}
              </div>
            </div>
            {/* <div className='w-1/3 sm:w-full'>
                    <Typography variant='caption' color='secondary' className='py-2'>Address 2</Typography>
                    <Typography variant='body' color='primary' className='pb-2'>{props.selectedlisting.address2}</Typography>
                </div>
                <div className='w-1/3 sm:w-full'>
                    <Typography variant='caption' color='secondary' className='py-2'>City</Typography>
                    <Typography variant='body' color='primary' className='pb-2'>{props.selectedlisting.city}</Typography>
                </div> */}
          </div>
          <div className="flex w-full mt-[25px] mb-[50px]">
            <div className="w-1/3 sm:w-full">
              <Typography
                variant="caption"
                color="secondary"

              >
                Neighborhood
              </Typography>
              <Typography variant="body" color="primary">
                {props.selectedlisting.listingNeigborhood || "-"}
              </Typography>
            </div>
            <div className="w-1/3 sm:w-full">
              <Typography
                variant="caption"
                color="secondary"

              >
                Unit
              </Typography>
              <Typography variant="body" color="primary">
                {props.selectedlisting.listingUnit || "-"}
              </Typography>
            </div>
            {/* <div className='w-1/3 sm:w-full'>
                    <Typography variant='caption' color='secondary' className='py-2'>Zip Code</Typography>
                    <Typography variant='body' color='primary' className='pb-2'>{props.selectedlisting.zip_code}</Typography>
                </div> */}
          </div>
        </>
      )}

      <Typography variant="h3" color="primary">
        Primary Buyer Details
      </Typography>
      <div className="flex w-full mt-[25px]">
        <div className="w-1/3 sm:w-full">
          <Typography variant="caption" color="secondary">
            First Name
          </Typography>
          <Typography variant="body" color="primary">
            {props.values.primaryBuyerFirstName || "-"}
          </Typography>
        </div>
        <div className="w-1/3 sm:w-full">
          <Typography variant="caption" color="secondary">
            Last Name
          </Typography>
          <Typography variant="body" color="primary">
            {props.values.primaryBuyerLastName || "-"}
          </Typography>
        </div>
        <div className="w-1/3 sm:w-full">
          <Typography variant="caption" color="secondary">
            Company Name
          </Typography>
          <Typography variant="body" color="primary">
            {props.values.primaryBuyerCompanyName || "-"}
          </Typography>
        </div>
      </div>
      <div className="flex w-full mt-[25px]">
        <div className="w-1/3 sm:w-full">
          <Typography variant="caption" color="secondary">
            Phone Number
          </Typography>
          <Typography variant="body" color="primary">
            {props.values.primaryBuyerPhoneNumber || "-"}
          </Typography>
        </div>
        <div className="w-1/3 sm:w-full">
          <Typography variant="caption" color="secondary">
            Email Address
          </Typography>
          <Typography variant="body" color="primary">
            {props.values.primaryBuyerEmailAddress || "-"}
          </Typography>
        </div>
        <div className="w-1/3 sm:w-full">
          <Typography variant="caption" color="secondary">
            Current Address
          </Typography>
          <div>
            {props.values?.primaryBuyerCurrentAddress && props.values?.primaryBuyerCurrentAddress.city && props.values?.primaryBuyerCurrentAddress.state && props.values?.primaryBuyerCurrentAddress.zipcode ? (
              <>
                <div>
                  <Typography variant="body" color="primary" className="line-clamp-2">
                    {props.values?.primaryBuyerCurrentAddress?.streetLine ? props.values?.primaryBuyerCurrentAddress?.streetLine + " " : ""}
                    {props.values?.primaryBuyerCurrentAddress?.secondary ? props.values?.primaryBuyerCurrentAddress?.secondary + " " : ""}
                  </Typography>
                </div>
                <div>
                  <Typography variant="body" color="primary" className="line-clamp-1">
                    {props.values?.primaryBuyerCurrentAddress?.city + ", " + props.values?.primaryBuyerCurrentAddress?.state + " " + props.values?.primaryBuyerCurrentAddress?.zipcode}
                  </Typography>
                </div>
              </>
            ) : "-"}
          </div>
        </div>
      </div>
      <div className="flex w-full mt-[25px] mb-[50px]">
        <div className="w-1/3 sm:w-full">
          <Typography variant="caption" color="secondary">
            Mailing Address
          </Typography>
          <div>
            {props.values?.primaryBuyerMailingAddress && props.values?.primaryBuyerMailingAddress.city && props.values?.primaryBuyerMailingAddress.state && props.values?.primaryBuyerMailingAddress.zipcode ? (
              <>
                <div>
                  <Typography variant="body" color="primary" className="line-clamp-2">
                    {props.values?.primaryBuyerMailingAddress?.streetLine ? props.values?.primaryBuyerMailingAddress?.streetLine + " " : ""}
                    {props.values?.primaryBuyerMailingAddress?.secondary ? props.values?.primaryBuyerMailingAddress?.secondary + " " : ""}
                  </Typography>
                </div>
                <div>
                  <Typography variant="body" color="primary" className="line-clamp-1">
                    {props.values?.primaryBuyerMailingAddress?.city + ", " + props.values?.primaryBuyerMailingAddress?.state + " " + props.values?.primaryBuyerMailingAddress?.zipcode}
                  </Typography>
                </div>
              </>
            ) : "-"}
          </div>
        </div>
      </div>

      <Typography variant="h3" color="primary">
        Secondary Buyer Details
      </Typography>
      <div className="flex w-full mt-[25px]">
        <div className="w-1/3 sm:w-full">
          <Typography variant="caption" color="secondary">
            First Name
          </Typography>
          <Typography variant="body" color="primary">
            {props.values.secondaryBuyerFirstName || "-"}
          </Typography>
        </div>
        <div className="w-1/3 sm:w-full">
          <Typography variant="caption" color="secondary">
            Last Name
          </Typography>
          <Typography variant="body" color="primary">
            {props.values.secondaryBuyerLastName || "-"}
          </Typography>
        </div>
        <div className="w-1/3 sm:w-full">
          <Typography variant="caption" color="secondary">
            Phone Number
          </Typography>
          <Typography variant="body" color="primary">
            {props.values.secondaryBuyerPhoneNumber || "-"}
          </Typography>
        </div>
      </div>
      <div className="flex w-full mt-[25px] mb-[50px]">
        <div className="w-1/3 sm:w-full">
          <Typography variant="caption" color="secondary">
            Email Address
          </Typography>
          <Typography variant="body" color="primary">
            {props.values.secondaryBuyerEmailAddress || "-"}
          </Typography>
        </div>
      </div>

      <Typography variant="h3" color="primary">
        Terms and Conditions
      </Typography>
      <div className="flex w-full mt-[25px]">
        <div className="w-1/3 sm:w-full">
          <Typography variant="caption" color="secondary">
            Offer Amount
          </Typography>
          <Typography variant="body" color="primary">
            <NumericFormat
              displayType="text"
              thousandSeparator=","
              prefix="$"
              value={props.values?.offerAmount || 0}
            />
          </Typography>
        </div>
        <div className="w-1/3 sm:w-full">
          <Typography variant="caption" color="secondary">
            Earnest Money Deposit
          </Typography>
          <Typography variant="body" color="primary">
            <NumericFormat
              displayType="text"
              thousandSeparator=","
              prefix="$"
              value={props.values?.earnestMoneyDeposit || 0}
            />
          </Typography>
        </div>
        <div className="w-1/3 sm:w-full">
          <Typography variant="caption" color="secondary">
            Downpayment Inc. EMD
          </Typography>
          <Typography variant="body" color="primary">
            <NumericFormat
              displayType="text"
              thousandSeparator=","
              prefix="$"
              value={props.values?.downPayment || 0}
            />
          </Typography>
        </div>
      </div>
      <div className="flex w-full mt-[25px]">
        <div className="w-1/3 sm:w-full">
          <Typography variant="caption" color="secondary">
            Subject to Mortgage
          </Typography>
          <Typography variant="body" color="primary">
            <NumericFormat
              displayType="text"
              thousandSeparator=","
              prefix="$"
              value={props.values?.subjectToMortgage || 0}
            />
          </Typography>
        </div>
        <div className="w-1/3 sm:w-full">
          <Typography variant="caption" color="secondary">
            Seller Concession
          </Typography>
          <Typography variant="body" color="primary">
            <NumericFormat
              displayType="text"
              thousandSeparator=","
              prefix="$"
              value={props.values?.sellerConcession || 0}
            />
          </Typography>
        </div>
        <div className="w-1/3 sm:w-full">
          <Typography variant="caption" color="secondary">
            Cash on Closing
          </Typography>
          <Typography variant="body" color="primary">
            <NumericFormat
              displayType="text"
              thousandSeparator=","
              prefix="$"
              value={props.values?.cashOnClosing || 0}
            />
          </Typography>
        </div>
      </div>
      <div className="flex w-full mt-[25px]">
        <div className="w-1/3 sm:w-full">
          <Typography variant="caption" color="secondary">
            Proposed Closing Location
          </Typography>
          <Typography variant="body" color="primary">
            {props.values.proposedClosingLocation || "-"}
          </Typography>
        </div>
        <div className="w-1/3 sm:w-full">
          <Typography variant="caption" color="secondary">
            Proposed Closing Date
          </Typography>
          <Typography variant="body" color="primary">
            {formatSlashDate(props.values.proposedClosingDate) || "-"}
          </Typography>
        </div>
        <div className="w-1/3 sm:w-full">
          <Typography variant="caption" color="secondary">
            Personal Property Inclusions
          </Typography>
          <Typography variant="body" color="primary">
            {props.values.personalPropertyInclusions || "-"}
          </Typography>
        </div>
      </div>
      <div className="flex w-full mt-[25px]">
        <div className="w-1/3 sm:w-full">
          <Typography variant="caption" color="secondary">
            Personal Property Exclusions
          </Typography>
          <Typography variant="body" color="primary">
            {props.values.personalPropertyExclusions || "-"}
          </Typography>
        </div>
      </div>
      <div className="flex w-full mt-[25px] mb-[50px]">
        <div className="w-full">
          <Typography variant="caption" color="secondary">
            Note
          </Typography>
          <Typography variant="body" color="primary">
            {props.values.noteToListingAgent || "-"}
          </Typography>
        </div>
      </div>

      <Typography variant="h3" color="primary">
        Mortgage
      </Typography>
      <div className="flex w-full mt-[25px]">
        <div className="w-1/3 sm:w-full">
          <Typography variant="caption" color="secondary">
            Mortgage Held By
          </Typography>
          <Typography variant="body" color="primary">
            {props.values?.mortgageHeldBy || "-"}
          </Typography>
        </div>
        <div className="w-1/3 sm:w-full">
          <Typography variant="caption" color="secondary">
            Mortgage Type
          </Typography>
          <Typography variant="body" color="primary">
            {props.values?.mortgageType || "-"}
          </Typography>
        </div>
      </div>
      <div className="flex w-full mt-[25px]">
        <div className="w-1/3 sm:w-full">
          <Typography variant="caption" color="secondary">
            Mortgage Period
          </Typography>
          <Typography variant="body" color="primary">
            {props.values?.mortgagePeriod || 0} years
          </Typography>
        </div>
        <div className="w-1/3 sm:w-full">
          <Typography variant="caption" color="secondary">
            Interest Rate
          </Typography>
          <Typography variant="body" color="primary">
            {props.values?.interestRate || "-"}
          </Typography>
        </div>
      </div>
      <div className="flex w-full mt-[25px]">
        <div className="w-1/3 sm:w-full">
          <Typography variant="caption" color="secondary">
            Monthly Payment
          </Typography>
          <Typography variant="body" color="primary">
            <NumericFormat
              displayType="text"
              thousandSeparator=","
              prefix="$"
              value={props.values?.monthlyPayment || 0}
            />
          </Typography>
        </div>
        <div className="w-1/3 sm:w-full">
          <Typography variant="caption" color="secondary">
            Proposed Closing Date
          </Typography>
          <Typography variant="body" color="primary">
            {formatSlashDate(props.values?.mortgageProposedClosingDate) || "-"}
          </Typography>
        </div>
      </div>
      <div className="flex w-full mt-[25px] mb-[50px]">
        <div className="w-1/3 sm:w-full">
          <Typography variant="caption" color="secondary">
            Mortgage Due (days from formal contract)
          </Typography>
          <Typography variant="body" color="primary">
            {props.values?.mortgageDays || 0} days
          </Typography>
        </div>
        <div className="w-1/3 sm:w-full">
          <Typography variant="caption" color="secondary">
            Payment Due Date
          </Typography>
          <Typography variant="body" color="primary">
            {formatSlashDate(props.values?.paymentDate) || "-"}
          </Typography>
        </div>
      </div>

      <Typography variant="h3" color="primary">
        Loan Officer
      </Typography>
      <div className="flex w-full mt-[25px]">
        <div className="w-1/3 sm:w-full">
          <Typography variant="caption" color="secondary">
            First Name
          </Typography>
          <Typography variant="body" color="primary">
            {props.values.loanFirstName || "-"}
          </Typography>
        </div>
        <div className="w-1/3 sm:w-full">
          <Typography variant="caption" color="secondary">
            Last Name
          </Typography>
          <Typography variant="body" color="primary">
            {props.values.loanLastName || "-"}
          </Typography>
        </div>
        <div className="w-1/3 sm:w-full">
          <Typography variant="caption" color="secondary">
            Phone Number
          </Typography>
          <Typography variant="body" color="primary">
            {props.values.loanPhoneNumber || "-"}
          </Typography>
        </div>
      </div>
      <div className="flex w-full mt-[25px] mb-[50px]">
        <div className="w-1/3 sm:w-full">
          <Typography variant="caption" color="secondary">
            Email
          </Typography>
          <Typography variant="body" color="primary">
            {props.values.loanEmailAddress || "-"}
          </Typography>
        </div>
        <div className="w-1/3 sm:w-full">
          <Typography variant="caption" color="secondary">
            Company
          </Typography>
          <Typography variant="body" color="primary">
            {props.values.loanCompany || "-"}
          </Typography>
        </div>
        <div className="w-1/3 sm:w-full">
          <Typography variant="caption" color="secondary">
            Address
          </Typography>
          <div>
            {props.values?.loanCompanyAddress && props.values?.loanCompanyAddress.city && props.values?.loanCompanyAddress.state && props.values?.loanCompanyAddress.zipcode ? (
              <>
                <div>
                  <Typography variant="body" color="primary" className="line-clamp-2">
                    {props.values?.loanCompanyAddress?.streetLine ? props.values?.loanCompanyAddress?.streetLine + " " : ""}
                    {props.values?.loanCompanyAddress?.secondary ? props.values?.loanCompanyAddress?.secondary + " " : ""}
                  </Typography>
                </div>
                <div>
                  <Typography variant="body" color="primary" className="line-clamp-1">
                    {props.values?.loanCompanyAddress?.city + ", " + props.values?.loanCompanyAddress?.state + " " + props.values?.loanCompanyAddress?.zipcode}
                  </Typography>
                </div>
              </>
            ) : "-"}
          </div>
        </div>
      </div>

      <Typography variant="h3" color="primary">
        Buyer’s Attorney
      </Typography>
      <div className="flex w-full mt-[25px]">
        <div className="w-1/3 sm:w-full">
          <Typography variant="caption" color="secondary">
            First Name
          </Typography>
          <Typography variant="body" color="primary">
            {props.values.buyeraAttorneyFirstName || "-"}
          </Typography>
        </div>
        <div className="w-1/3 sm:w-full">
          <Typography variant="caption" color="secondary">
            Last Name
          </Typography>
          <Typography variant="body" color="primary">
            {props.values.buyerAttorneyLastName || "-"}
          </Typography>
        </div>
        <div className="w-1/3 sm:w-full">
          <Typography variant="caption" color="secondary">
            Phone Number
          </Typography>
          <Typography variant="body" color="primary">
            {props.values.buyerAttorneyPhoneNumber || "-"}
          </Typography>
        </div>
      </div>
      <div className="flex w-full mt-[25px] mb-[50px]">
        <div className="w-1/3 sm:w-full">
          <Typography variant="caption" color="secondary">
            Email
          </Typography>
          <Typography variant="body" color="primary">
            {props.values.buyerAttorneyEmailAddress || "-"}
          </Typography>
        </div>
        <div className="w-1/3 sm:w-full">
          <Typography variant="caption" color="secondary">
            Company
          </Typography>
          <Typography variant="body" color="primary">
            {props.values.buyerAttorneyCompany || "-"}
          </Typography>
        </div>
        <div className="w-1/3 sm:w-full">
          <Typography variant="caption" color="secondary">
            Address
          </Typography>
          <div>
            {props.values?.buyerAttorneyAddress && props.values?.buyerAttorneyAddress.city && props.values?.buyerAttorneyAddress.state && props.values?.buyerAttorneyAddress.zipcode ? (
              <>
                <div>
                  <Typography variant="body" color="primary" className="line-clamp-2">
                    {props.values?.buyerAttorneyAddress?.streetLine ? props.values?.buyerAttorneyAddress?.streetLine + " " : ""}
                    {props.values?.buyerAttorneyAddress?.secondary ? props.values?.buyerAttorneyAddress?.secondary + " " : ""}
                  </Typography>
                </div>
                <div>
                  <Typography variant="body" color="primary" className="line-clamp-1">
                    {props.values?.buyerAttorneyAddress?.city + ", " + props.values?.buyerAttorneyAddress?.state + " " + props.values?.buyerAttorneyAddress?.zipcode}
                  </Typography>
                </div>
              </>
            ) : "-"}
          </div>
        </div>
      </div>

      <Typography variant="h3" color="primary">
        Seller’s Attorney
      </Typography>
      <div className="flex w-full mt-[25px]">
        <div className="w-1/3 sm:w-full">
          <Typography variant="caption" color="secondary">
            First Name
          </Typography>
          <Typography variant="body" color="primary">
            {props.values.sellerAttorneyFirstName || "-"}
          </Typography>
        </div>
        <div className="w-1/3 sm:w-full">
          <Typography variant="caption" color="secondary">
            Last Name
          </Typography>
          <Typography variant="body" color="primary">
            {props.values.sellerAttorneyLastName || "-"}
          </Typography>
        </div>
        <div className="w-1/3 sm:w-full">
          <Typography variant="caption" color="secondary">
            Phone Number
          </Typography>
          <Typography variant="body" color="primary">
            {props.values.sellerAttorneyPhoneNumber || "-"}
          </Typography>
        </div>
      </div>
      <div className="flex w-full mt-[25px] mb-[50px]">
        <div className="w-1/3 sm:w-full">
          <Typography variant="caption" color="secondary">
            Email
          </Typography>
          <Typography variant="body" color="primary">
            {props.values.sellerAttorneyEmailAddress || "-"}
          </Typography>
        </div>
        <div className="w-1/3 sm:w-full">
          <Typography variant="caption" color="secondary">
            Company
          </Typography>
          <Typography variant="body" color="primary">
            {props.values.sellerAttorneyCompany || "-"}
          </Typography>
        </div>
        <div className="w-1/3 sm:w-full">
          <Typography variant="caption" color="secondary">
            Address
          </Typography>
          <div>
            {props.values?.sellerAttorneyAddress && props.values?.sellerAttorneyAddress.city && props.values?.sellerAttorneyAddress.state && props.values?.sellerAttorneyAddress.zipcode ? (
              <>
                <div>
                  <Typography variant="body" color="primary" className="line-clamp-2">
                    {props.values?.sellerAttorneyAddress?.streetLine ? props.values?.sellerAttorneyAddress?.streetLine + " " : ""}
                    {props.values?.sellerAttorneyAddress?.secondary ? props.values?.sellerAttorneyAddress?.secondary + " " : ""}
                  </Typography>
                </div>
                <div>
                  <Typography variant="body" color="primary" className="line-clamp-1">
                    {props.values?.sellerAttorneyAddress?.city + ", " + props.values?.sellerAttorneyAddress?.state + " " + props.values?.sellerAttorneyAddress?.zipcode}
                  </Typography>
                </div>
              </>
            ) : "-"}
          </div>
        </div>
      </div>

      <Typography variant="h3" color="primary">
        Documents
      </Typography>
      {props.mainDoc && props.mainDoc.name && props.mainDoc.file && (
        <div className="flex justify-between">
          <div className="w-1/3 mr-2">
            <Typography variant="caption" className="text-[13px] truncate">
              {'main document'}
            </Typography>
          </div>
          <div className="w-1/3 mr-2">
            <Typography
              variant="body"
              className="break-all text-[13px] text-[#4C42D7] truncate"
            >
              {props.mainDoc.name || '-'}
            </Typography>
          </div>
          <div className="w-1/3">
            <Typography variant="body" className="text-[13px]">
              {'-'}
            </Typography>
          </div>
        </div>        
      )}
      {props.additionalDocs && props.additionalDocs.length > 0 && props.additionalDocs.map((document: any, index: number) => {
        return (
          <div key={index}>
          {document.file && (
            <div className="flex justify-between" key={index}>
              <div className="w-1/3 mr-2">
                <Typography variant="body" className="text-[13px] truncate">
                  {document.doc_type || '-'}
                </Typography>
              </div>
              <div className="w-1/3 mr-2">
                <Typography
                  variant="body"
                  className="break-all text-[13px] text-[#4C42D7] truncate"
                >
                  {document.rename || '-'}
                </Typography>
              </div>
              <div className="w-1/3">
                <Typography variant="body" className="text-[13px]">
                  {document.isFile ? FormatFileSize(document.file.size) : '' + '|' + document.uploadAt && document.uploadAt !== null ? document.uploadAt : ''}
                </Typography>
              </div>
            </div>
          )}
          </div>
        );
      })}
      {(props.additionalDocs === undefined || props.additionalDocs.length === 0) && (
        <Typography variant="body" color="primary">
          {"-"}
        </Typography>
      )}

      <div className="mt-[25px]">
        <div className="flex items-center">
          <input
            type="checkbox"
            className="h-6 w-6 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
            value={"send_flag"}
          // checked={companyOnlyFlag===true}
          // onChange={(e) => {
          //     setCompanyOnlyFlag(e.target.checked);
          //     if (e.target.checked){
          //         setTeamOnlyFlag(false);
          //     }
          // }}
          />
          <Typography className="ml-3" variant="body" color="secondary">
            Send to client for signature
          </Typography>
        </div>
        <div className="w-full my-[15px]">
          <Typography variant="body" color="secondary">
            If checked, the generated offer document will be sent to the Buyer
            Client for review and signatures before being submitted to the
            Listing Agent. Signed offers are of higher quality and have a
            greater chance of being selected.
          </Typography>
        </div>
      </div>
      <div className="flex gap-[15px] mt-[10px] mb-[30px]">
        <Button
          variant="outlined"
          onClick={() => props.setOpenDocModal(true)}
        >
          <Typography variant="button1">View Offer Document</Typography>
        </Button>
        <Button
          variant="contained"
          onClick={() => props.sendOffer()}
        >
          <Typography variant="button1">Send Offer</Typography>
        </Button>
      </div>
      <div className='my-16'>
        <CompanyAgentInfo agent={user?.agent} />
      </div>
    </div>
  );
}

export default Review