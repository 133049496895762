import { Fragment, useEffect, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import Typography from "@/components/baseComponents/Typography";
import { Button } from "@/components/baseComponents/Button";
import Label from "@/components/baseComponents/Label";
import IconWrapper from "@/components/baseComponents/IconWrapper";
import DefaultAvatar from "@/assets/images/default_avatar.jpg";
import { format } from "date-fns";
import Scrollbars from "react-custom-scrollbars";
import Placeholder from "@/components/baseComponents/Placeholder";
import { NumericFormat } from "react-number-format";
import { Link } from "react-router-dom";
import Tooltip from "@/components/mainComponents/ToolTip";
import { AiOutlineUndo } from "react-icons/ai";

type IProps = {
  open: boolean;
  changeState: Function;
  data: any;
  archive: Function;
  editLead: Function;
  convertToClient: Function;
};

const View = ({
  open,
  changeState,
  data,
  archive,
  editLead,
  convertToClient,
}: IProps) => {

  let tabs = [
    { name: "All" },
    { name: "Buyer Info" },
    { name: "Buyer Preferences" },
    { name: "Financing" },
    { name: "Notes" },
  ];

  if (data?.leadType === "Seller") {
    tabs = [
      { name: "All" },
      { name: "Seller Info" },
      { name: "Property Description" },
      { name: "Notes" },
    ];
  }

  const [currentTab, setCurrentTab] = useState<string>("All");

  useEffect(() => {
    if (open) {
      setCurrentTab("All");
    }
  }, [open]);

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 z-30"
        onClose={() => {}}
      >
        {/* The backdrop, rendered as a fixed sibling to the panel container */}
        <div className="fixed inset-0 bg-black/30" aria-hidden="true" />

        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden" onClick={() => changeState(false)}>
          <div className="pointer-events-none fixed inset-y-0 right-0 flex md:pl-10">
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-500 sm:duration-300"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-500 sm:duration-300"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <Dialog.Panel className="pointer-events-auto w-screen md:max-w-[600px] sm:max-w-full bg-white shadow-xl py-6 max-sm:pb-24">
                  <div className="flex items-center justify-between md:px-9 sm:px-5 max-sm:px-5">
                    <div className="flex items-center gap-[10px] justify-center">
                      <Typography variant="h1" color="primary" className="line-clamp-1">{data?.firstName + " " + data?.lastName}</Typography>
                      <Label variant="contained" color="info" size="small" className="rounded-lg" title={data?.leadType} />
                    </div>
                    <div className="flex gap-3 items-center">
                      <Tooltip text="Edit">
                        <Button variant="icon" className="!p-1" onClick={() => editLead(data)}>
                          <IconWrapper name="pencil" width={20} />
                        </Button>
                      </Tooltip>
                      {data?.isArchived === 0 && (
                        <Tooltip text="Revert to Lead">
                          <Button variant="icon" className="!p-1" onClick={() => convertToClient(data, data?.isClient ? false : true)}>
                            <AiOutlineUndo className="text-20" />
                          </Button>
                        </Tooltip>
                      )}
                      <Tooltip text="Archive">
                        <Button variant="icon" className="!p-1" onClick={() => archive(data)}>
                          <IconWrapper name="archive" width={20} className={data?.isArchived === 0 ? `` : `[&>svg>path]:!stroke-[#6DA172]`} />
                        </Button>
                      </Tooltip>
                      <Button variant="icon" className="group !p-1" onClick={() => changeState(false)}>
                        <IconWrapper name="close" className="cursor-pointer group-hover:[&>svg>path]:!stroke-[#9333EA]" />
                      </Button>
                    </div>
                  </div>
                  <Scrollbars autoHide>
                    <div className="md:px-9 sm:px-4 max-sm:px-4 pb-6">
                      <div className="flex justify-center w-full mt-5">
                        <nav className="flex justify-center my-3 w-full border-b-1" aria-label="Tabs">
                          <Scrollbars autoHide autoHeight={true} renderThumbHorizontal={() => { return <div></div> }}>
                            <div className="flex justify-between w-full py-3">
                              {tabs.map((tab) => (
                                <div
                                  key={tab.name}
                                  className={`${tab.name === currentTab
                                    ? "text-gray-800 bg-gray-100"
                                    : "text-gray-400"}
                                      whitespace-nowrap cursor-pointer rounded-lg px-4 py-1.5 !text-9
                                  `}
                                  aria-current={tab.name === currentTab ? "page" : undefined}
                                  onClick={() => setCurrentTab(tab.name)}
                                >
                                  <Typography variant="body">{tab.name}</Typography>
                                </div>
                              ))}
                            </div>
                          </Scrollbars>
                        </nav>
                      </div>
                      {(currentTab === "All" || currentTab === "Buyer Info" || currentTab === "Seller Info") && (
                        <div className="flex flex-row justify-between my-5">
                          <div className="flex max-sm:flex-col gap-[16px]">
                            <img src={DefaultAvatar} alt="addProfile" className="h-[80px] w-[80px] flex-none rounded-full bg-gray-50" />
                            <div className="flex flex-col">
                              {data?.firstName && (
                                <Typography variant="h3">
                                  {data?.firstName + " " + data?.lastName}
                                </Typography>
                              )}
                              {data?.phoneNumber && (
                                <Typography
                                  variant="body"
                                  className="text-gray-600"
                                >
                                  {data?.phoneNumber}
                                </Typography>
                              )}
                              {data?.email && (
                                <Typography
                                  variant="body"
                                  className="text-gray-600"
                                >
                                  {data?.email}
                                </Typography>
                              )}
                              <div className="flex gap-[8px] mt-2">
                                <Label
                                  size="small"
                                  variant="contained"
                                  className="rounded-xl"
                                  color={
                                    data?.leadStatus === `Active`
                                      ? `success-light`
                                      : data?.leadStatus === `Waiting`
                                      ? `warning`
                                      : `danger-light`
                                  }
                                  title={
                                    <div className="flex items-center gap-[4px]">
                                      <IconWrapper
                                        width={14}
                                        name="heart-beat"
                                        className={`whitespace-nowrap 
                                            ${
                                              data?.leadStatus === `Active`
                                                ? `[&>svg>path]:!stroke-[#22C55E]`
                                                : data?.leadStatus === `Waiting`
                                                ? `[&>svg>path]:!stroke-[#F97316]`
                                                : `[&>svg>path]:!stroke-[#E11D48]`
                                            }`}
                                      />
                                      <Typography
                                        variant="body"
                                        color={
                                          data?.leadStatus === `Active`
                                            ? `success`
                                            : data?.leadStatus === `Waiting`
                                            ? `warning`
                                            : `danger`
                                        }
                                      >
                                        {data?.leadStatus}
                                      </Typography>
                                    </div>
                                  }
                                />
                                <Label
                                  size="small"
                                  variant="contained"
                                  className="rounded-xl whitespace-nowrap"
                                  color={
                                    data?.rating === "In Contact"
                                      ? `success-light`
                                      : data?.rating === "High Priority"
                                      ? "warning"
                                      : ""
                                  }
                                  title={
                                    <Typography variant="body">
                                      {data?.rating === "In Contact"
                                        ? "Contacted"
                                        : data?.rating ?? "Uncontacted"}
                                    </Typography>
                                  }
                                />
                              </div>
                            </div>
                          </div>
                          <div className="flex gap-3">
                            <Link to={"tel:" + data?.phoneNumber}>
                              <Button variant="icon" className="bg-gray-100 !rounded-full md:!w-[60px] md:!h-[60px] max-sm:!w-[50px] max-sm:!h-[50px] !p-0">
                                <IconWrapper name="telephone" />
                              </Button>
                            </Link>
                            <Link to={"mailto:" + data?.email}>
                              <Button variant="icon" className="bg-gray-100 !rounded-full md:!w-[60px] md:!h-[60px] max-sm:!w-[50px] max-sm:!h-[50px] !p-0">
                                <IconWrapper name="envelope" />
                              </Button>
                            </Link>
                          </div>
                        </div>
                      )}
                      {(currentTab === "All" || currentTab === "Notes") && (
                        <div className="mb-5 py-3">
                          <Typography
                            variant="h4"
                            color="primary"
                            className="py-3"
                          >
                            Notes
                          </Typography>
                          <div className="py-3">
                            <Typography
                              variant="body"
                              color="tertiary"
                              className="mb-2"
                            >
                              About the Client
                            </Typography>
                            <Typography variant="body" color="primary" className="text-justify line-clamp-3">
                              {data?.about}
                            </Typography>
                          </div>
                          <div className="flex flex-col bg-gray-100 rounded-lg py-2">
                            <div className="flex justify-between border-b-1 mx-3 py-2.5">
                              <Typography variant="body" color="secondary">
                                Target Sales
                              </Typography>
                              <div className="flex flex-row gap-1">
                                <Typography variant="body" color="primary">
                                  {data?.startOfTarget
                                    ? format(
                                        new Date(data?.startOfTarget),
                                        "MM/dd/yyyy"
                                      )
                                    : "00/00/0000"}
                                </Typography>
                                <IconWrapper
                                  name="minus"
                                  width={14}
                                  height={11}
                                />
                                <Typography variant="body" color="primary">
                                  {data?.endOfTarget
                                    ? format(
                                        new Date(data?.endOfTarget),
                                        "MM/dd/yyyy"
                                      )
                                    : "00/00/0000"}
                                </Typography>
                              </div>
                            </div>
                            <div className="flex justify-between border-b-1 mx-3 py-2.5">
                              <Typography variant="body" color="secondary">
                                Last Contact Date
                              </Typography>
                              <Typography variant="body" color="primary">
                                {data?.lastContact
                                  ? format(
                                      new Date(data?.lastContact),
                                      "MM/dd/yyyy"
                                    )
                                  : "00/00/0000"}
                              </Typography>
                            </div>
                            <div className="flex justify-between border-b-1 mx-3 py-2.5">
                              <Typography variant="body" color="secondary">
                                Next Contact Date
                              </Typography>
                              <Typography variant="body" color="primary">
                                {data?.nextContact
                                  ? format(
                                      new Date(data?.nextContact),
                                      "MM/dd/yyyy"
                                    )
                                  : "00/00/0000"}
                              </Typography>
                            </div>
                            <div className="flex justify-between mx-3 py-2.5">
                              <Typography variant="body" color="secondary">
                                Date Added
                              </Typography>
                              <Typography variant="body" color="primary">
                                {data?.dateAdded
                                  ? format(
                                      new Date(data?.dateAdded),
                                      "MM/dd/yyyy"
                                    )
                                  : "00/00/0000"}
                              </Typography>
                            </div>
                          </div>
                        </div>
                      )}
                      {data?.leadType === "Seller" &&
                        (currentTab === "All" ||
                          currentTab === "Property Description") && (
                          <>
                            <div className="mb-5 py-3">
                              <Typography
                                variant="h4"
                                color="primary"
                                className="py-3"
                              >
                                Property Description
                              </Typography>
                              <div className="py-3">
                                <Typography
                                  variant="body"
                                  color="tertiary"
                                  className="mb-2"
                                >
                                  Property Description
                                </Typography>
                                <Placeholder
                                  value={data?.sellerPropertyDescription}
                                />
                              </div>
                              <div className="flex gap-2 py-3">
                                <div className="w-full">
                                  <Typography
                                    variant="body"
                                    color="tertiary"
                                    className="mb-2"
                                  >
                                    Potential Listing Price
                                  </Typography>
                                  <Placeholder
                                    icon={
                                      <IconWrapper name="money" width={16} />
                                    }
                                    value={
                                      <NumericFormat
                                        displayType="text"
                                        decimalSeparator="."
                                        thousandSeparator=","
                                        value={data?.sellerListPrice}
                                      />
                                    }
                                  />
                                </div>
                                <div className="w-full">
                                  <Typography
                                    variant="body"
                                    color="tertiary"
                                    className="mb-2"
                                  >
                                    Occupancy Status
                                  </Typography>
                                  <Placeholder
                                    value={data?.sellerOccupancyStatus}
                                  />
                                </div>
                              </div>
                              <div className="py-3">
                                <Typography
                                  variant="body"
                                  color="tertiary"
                                  className="mb-2"
                                >
                                  Keywords
                                </Typography>
                                <div className="flex flex-wrap gap-1">
                                  {data?.sellerKeywordsMulti?.map(
                                    (keyword: string, index: number) => (
                                      <Label
                                        key={index}
                                        size="small"
                                        variant="outline"
                                        color="info"
                                        title={keyword}
                                      />
                                    )
                                  )}
                                </div>
                              </div>
                              <div className="flex gap-2 py-3">
                                <div className="w-3/4">
                                  <Typography
                                    variant="body"
                                    color="tertiary"
                                    className="mb-2"
                                  >
                                    Address
                                  </Typography>
                                  <Placeholder
                                    value={
                                      data?.sellerPropertyAddress?.fullAddress
                                    }
                                  />
                                </div>
                                <div className="w-1/4">
                                  <Typography
                                    variant="body"
                                    color="tertiary"
                                    className="mb-2"
                                  >
                                    Unit
                                  </Typography>
                                  <Placeholder value={data?.sellerUnit} />
                                </div>
                              </div>
                              <div className="py-3">
                                <Typography
                                  variant="body"
                                  color="tertiary"
                                  className="mb-2"
                                >
                                  School Districts
                                </Typography>
                                <Placeholder
                                  value={
                                    data?.sellerSchoolDistrictsMulti?.length >
                                      0 && (
                                      <div className="flex flex-wrap gap-1">
                                        {data?.sellerSchoolDistrictsMulti?.map(
                                          (district: string, index: number) => (
                                            <Label
                                              key={index}
                                              size="small"
                                              variant="outline"
                                              color="white"
                                              title={district}
                                              className="whitespace-nowrap rounded-xl"
                                            />
                                          )
                                        )}
                                      </div>
                                    )
                                  }
                                />
                              </div>
                              <div className="flex gap-2 py-3">
                                <div className="w-full">
                                  <Typography
                                    variant="body"
                                    color="tertiary"
                                    className="mb-2"
                                  >
                                    Property Type
                                  </Typography>
                                  <Placeholder
                                    icon={
                                      <IconWrapper name="home-alt" width={16} />
                                    }
                                    value={data?.sellerPropertyType}
                                  />
                                </div>
                                <div className="w-full">
                                  <Typography
                                    variant="body"
                                    color="tertiary"
                                    className="mb-2"
                                  >
                                    Property Sub-Type
                                  </Typography>
                                  <Placeholder
                                    value={data?.sellerPropertySubType}
                                  />
                                </div>
                              </div>
                              <div className="flex gap-2 py-3">
                                <div className="w-full">
                                  <Typography
                                    variant="body"
                                    color="tertiary"
                                    className="mb-2"
                                  >
                                    Home SqFt
                                  </Typography>
                                  <Placeholder
                                    icon={
                                      <IconWrapper name="graph" width={16} />
                                    }
                                    value={data?.sellerHomeSqFt}
                                  />
                                </div>
                                <div className="w-full">
                                  <Typography
                                    variant="body"
                                    color="tertiary"
                                    className="mb-2"
                                  >
                                    Lot SqFt
                                  </Typography>
                                  <Placeholder value={data?.sellerLotSqFt} />
                                </div>
                              </div>
                            </div>
                            <div className="mb-5 py-3">
                              <Typography
                                variant="h4"
                                color="primary"
                                className="py-3"
                              >
                                Property Features
                              </Typography>
                              <div className="flex gap-2 py-3">
                                <div className="w-full">
                                  <Typography
                                    variant="body"
                                    color="tertiary"
                                    className="mb-2"
                                  >
                                    Bedrooms
                                  </Typography>
                                  <Placeholder
                                    icon={<IconWrapper name="bed" width={16} />}
                                    value={data?.sellerBedrooms}
                                  />
                                </div>
                                <div className="w-full">
                                  <Typography
                                    variant="body"
                                    color="tertiary"
                                    className="mb-2"
                                  >
                                    Bathrooms
                                  </Typography>
                                  <Placeholder
                                    icon={
                                      <IconWrapper name="bath" width={16} />
                                    }
                                    value={data?.sellerBathrooms}
                                  />
                                </div>
                              </div>
                              <div className="flex gap-2 py-3">
                                <div className="w-full">
                                  <Typography
                                    variant="body"
                                    color="tertiary"
                                    className="mb-2"
                                  >
                                    Parking
                                  </Typography>
                                  <Placeholder
                                    icon={
                                      <IconWrapper name="parking" width={16} />
                                    }
                                    value={data?.sellerParking}
                                  />
                                </div>
                                <div className="w-full">
                                  <Typography
                                    variant="body"
                                    color="tertiary"
                                    className="mb-2"
                                  >
                                    Garage
                                  </Typography>
                                  <Placeholder
                                    icon={
                                      <IconWrapper name="garage" width={16} />
                                    }
                                    value={data?.sellerGarage}
                                  />
                                </div>
                              </div>
                              <div className="flex gap-2 py-3">
                                <div className="flex flex-col gap-2 w-full">
                                  <Typography variant="body" color="tertiary">
                                    Heating
                                  </Typography>
                                  <Placeholder
                                    icon={
                                      <IconWrapper name="heating" width={16} />
                                    }
                                    value={data?.sellerHeating}
                                  />
                                  <div className="flex flex-wrap gap-1">
                                    {data?.sellerHeatingTypeMulti?.map(
                                      (value: string, index: number) => (
                                        <Label
                                          key={index}
                                          size="small"
                                          variant="outline"
                                          color="gray"
                                          title={value}
                                          className="whitespace-nowrap rounded-xl"
                                        />
                                      )
                                    )}
                                  </div>
                                </div>
                                <div className="flex flex-col gap-2 w-full">
                                  <Typography variant="body" color="tertiary">
                                    Cooling
                                  </Typography>
                                  <Placeholder
                                    icon={
                                      <IconWrapper name="cool" width={16} />
                                    }
                                    value={data?.sellerCooling}
                                  />
                                  <div className="flex flex-wrap gap-1">
                                    {data?.sellerCoolingTypeMulti?.map(
                                      (value: string, index: number) => (
                                        <Label
                                          key={index}
                                          size="small"
                                          variant="outline"
                                          color="gray"
                                          title={value}
                                          className="whitespace-nowrap rounded-xl"
                                        />
                                      )
                                    )}
                                  </div>
                                </div>
                              </div>
                              <div className="flex gap-2 py-3">
                                <div className="w-full">
                                  <Typography
                                    variant="body"
                                    color="tertiary"
                                    className="mb-2"
                                  >
                                    Pool
                                  </Typography>
                                  <Placeholder
                                    icon={
                                      <IconWrapper name="pool" width={16} />
                                    }
                                    value={data?.sellerPool}
                                  />
                                </div>
                                <div className="w-full">
                                  <Typography
                                    variant="body"
                                    color="tertiary"
                                    className="mb-2"
                                  >
                                    Is Pre-Foreclosure?
                                  </Typography>
                                  <Placeholder
                                    value={data?.sellerIsPreForeclosure}
                                  />
                                </div>
                              </div>
                              <div className="flex gap-2 py-3">
                                <div className="w-full">
                                  <Typography
                                    variant="body"
                                    color="tertiary"
                                    className="mb-2"
                                  >
                                    Is Attached?
                                  </Typography>
                                  <Placeholder value={data?.sellerIsAttached} />
                                </div>
                                <div className="w-full">
                                  <Typography
                                    variant="body"
                                    color="tertiary"
                                    className="mb-2"
                                  >
                                    New Construction
                                  </Typography>
                                  <Placeholder
                                    value={data?.sellerNewConstruction}
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="mb-5 py-3">
                              <Typography
                                variant="h4"
                                color="primary"
                                className="py-3"
                              >
                                Community & Neighborhood
                              </Typography>
                              <div className="py-3">
                                <Typography
                                  variant="body"
                                  color="tertiary"
                                  className="mb-2"
                                >
                                  Views
                                </Typography>
                                <div className="flex flex-wrap gap-1">
                                  {data?.sellerViewsMulti?.map(
                                    (view: string, index: number) => (
                                      <Label
                                        key={index}
                                        size="small"
                                        variant="outline"
                                        color="info"
                                        title={view}
                                      />
                                    )
                                  )}
                                </div>
                              </div>
                              <div className="py-3">
                                <Typography
                                  variant="body"
                                  color="tertiary"
                                  className="mb-2"
                                >
                                  Amenities
                                </Typography>
                                <div className="flex flex-wrap gap-1">
                                  {data?.sellerAmenitiesMulti?.map(
                                    (amenity: string, index: number) => (
                                      <Label
                                        key={index}
                                        size="small"
                                        variant="outline"
                                        color="info"
                                        title={amenity}
                                      />
                                    )
                                  )}
                                </div>
                              </div>
                            </div>
                            <div className="mb-5 py-3">
                              <Typography
                                variant="h4"
                                color="primary"
                                className="py-3"
                              >
                                Other Details
                              </Typography>
                              <div className="flex gap-2 py-3">
                                <div className="w-full">
                                  <Typography
                                    variant="body"
                                    color="tertiary"
                                    className="mb-2"
                                  >
                                    HOA Dues (Annual)
                                  </Typography>
                                  <Placeholder
                                    icon={
                                      <IconWrapper name="money" width={16} />
                                    }
                                    value={
                                      <NumericFormat
                                        displayType="text"
                                        decimalSeparator="."
                                        thousandSeparator=","
                                        value={data?.sellerAnnualHOADues}
                                      />
                                    }
                                  />
                                </div>
                                <div className="w-full">
                                  <Typography
                                    variant="body"
                                    color="tertiary"
                                    className="mb-2"
                                  >
                                    Taxes (Annual)
                                  </Typography>
                                  <Placeholder
                                    icon={
                                      <IconWrapper name="money" width={16} />
                                    }
                                    value={
                                      <NumericFormat
                                        displayType="text"
                                        decimalSeparator="."
                                        thousandSeparator=","
                                        value={data?.sellerAnnualTaxes}
                                      />
                                    }
                                  />
                                </div>
                              </div>
                              <div className="flex gap-2 py-3">
                                <div className="w-full">
                                  <Typography
                                    variant="body"
                                    color="tertiary"
                                    className="mb-2"
                                  >
                                    Other Expenses (Annual)
                                  </Typography>
                                  <Placeholder
                                    icon={
                                      <IconWrapper name="money" width={16} />
                                    }
                                    value={
                                      <NumericFormat
                                        displayType="text"
                                        decimalSeparator="."
                                        thousandSeparator=","
                                        value={data?.sellerAnnualOtherExpenses}
                                      />
                                    }
                                  />
                                </div>
                                <div className="w-full">
                                  <Typography
                                    variant="body"
                                    color="tertiary"
                                    className="mb-2"
                                  >
                                    Village Taxes (Annual)
                                  </Typography>
                                  <Placeholder
                                    icon={
                                      <IconWrapper name="money" width={16} />
                                    }
                                    value={
                                      <NumericFormat
                                        displayType="text"
                                        decimalSeparator="."
                                        thousandSeparator=","
                                        value={data?.sellerVillageAnnualTaxes}
                                      />
                                    }
                                  />
                                </div>
                              </div>
                            </div>
                          </>
                        )}
                      {data?.leadType === "Buyer" &&
                        (currentTab === "All" ||
                          currentTab === "Buyer Preferences") && (
                          <>
                            <div className="mb-5 py-3">
                              <Typography
                                variant="h4"
                                color="primary"
                                className="py-3"
                              >
                                Buyer Preference
                              </Typography>

                              <div className="py-3">
                                <Typography
                                  variant="body"
                                  color="tertiary"
                                  className="mb-2"
                                >
                                  Locations
                                </Typography>
                                <Placeholder
                                  value={
                                    data?.buyerLocationsMulti?.length > 0 && (
                                      <div className="flex flex-wrap gap-1">
                                        {data?.buyerLocationsMulti?.map(
                                          (location: string, index: number) => (
                                            <Label
                                              key={index}
                                              size="small"
                                              variant="outline"
                                              color="white"
                                              title={location}
                                              className="whitespace-nowrap rounded-xl"
                                            />
                                          )
                                        )}
                                      </div>
                                    )
                                  }
                                />
                              </div>
                              <div className="py-3">
                                <Typography
                                  variant="body"
                                  color="tertiary"
                                  className="mb-2"
                                >
                                  School Districts
                                </Typography>
                                <Placeholder
                                  value={
                                    data?.buyerSchoolDistrictsMulti?.length >
                                      0 && (
                                      <div className="flex flex-wrap gap-1">
                                        {data?.buyerSchoolDistrictsMulti?.map(
                                          (district: string, index: number) => (
                                            <Label
                                              key={index}
                                              size="small"
                                              variant="outline"
                                              color="white"
                                              title={district}
                                              className="whitespace-nowrap rounded-xl"
                                            />
                                          )
                                        )}
                                      </div>
                                    )
                                  }
                                />
                              </div>
                              <div className="flex gap-2 py-3">
                                <div className="w-full">
                                  <Typography
                                    variant="body"
                                    color="tertiary"
                                    className="mb-2"
                                  >
                                    Property Type
                                  </Typography>
                                  <Placeholder
                                    icon={
                                      <IconWrapper name="home-alt" width={16} />
                                    }
                                    value={data?.buyerPropertyType}
                                  />
                                </div>
                              </div>
                              <div className="flex gap-2 py-3">
                                <div className="w-full">
                                  <Typography
                                    variant="body"
                                    color="tertiary"
                                    className="mb-2"
                                  >
                                    Home SqFt
                                  </Typography>
                                  <Placeholder
                                    icon={
                                      <IconWrapper name="graph" width={16} />
                                    }
                                    value={data?.buyerMinimumHomeSqFt}
                                  />
                                </div>
                                <div className="w-full">
                                  <Typography
                                    variant="body"
                                    color="tertiary"
                                    className="mb-2"
                                  >
                                    Lot SqFt
                                  </Typography>
                                  <Placeholder
                                    value={data?.buyerMinimumLotSqFt}
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="mb-5 py-3">
                              <Typography
                                variant="h4"
                                color="primary"
                                className="py-3"
                              >
                                Property Features
                              </Typography>
                              <div className="flex gap-2 py-3">
                                <div className="w-full">
                                  <Typography
                                    variant="body"
                                    color="tertiary"
                                    className="mb-2"
                                  >
                                    Bedrooms
                                  </Typography>
                                  <Placeholder
                                    icon={<IconWrapper name="bed" width={16} />}
                                    value={data?.buyerMinimumBedrooms}
                                  />
                                </div>
                                <div className="w-full">
                                  <Typography
                                    variant="body"
                                    color="tertiary"
                                    className="mb-2"
                                  >
                                    Bathrooms
                                  </Typography>
                                  <Placeholder
                                    icon={
                                      <IconWrapper name="bath" width={16} />
                                    }
                                    value={data?.buyerMinimumBathrooms}
                                  />
                                </div>
                              </div>
                              <div className="flex gap-2 py-3">
                                <div className="w-full">
                                  <Typography
                                    variant="body"
                                    color="tertiary"
                                    className="mb-2"
                                  >
                                    Parking
                                  </Typography>
                                  <Placeholder
                                    icon={
                                      <IconWrapper name="parking" width={16} />
                                    }
                                    value={data?.buyerParking}
                                  />
                                </div>
                                <div className="w-full">
                                  <Typography
                                    variant="body"
                                    color="tertiary"
                                    className="mb-2"
                                  >
                                    Garage
                                  </Typography>
                                  <Placeholder
                                    icon={
                                      <IconWrapper name="garage" width={16} />
                                    }
                                    value={data?.buyerGarage}
                                  />
                                </div>
                              </div>
                              <div className="flex gap-2 py-3">
                                <div className="flex flex-col gap-2 w-full">
                                  <Typography variant="body" color="tertiary">
                                    Heating
                                  </Typography>
                                  <Placeholder
                                    icon={
                                      <IconWrapper name="heating" width={16} />
                                    }
                                    value={data?.buyerHeating}
                                  />
                                  <div className="flex flex-wrap gap-1">
                                    {data?.buyerHeatingTypeMulti?.map(
                                      (value: string, index: number) => (
                                        <Label
                                          key={index}
                                          size="small"
                                          variant="outline"
                                          color="gray"
                                          title={value}
                                          className="whitespace-nowrap rounded-xl"
                                        />
                                      )
                                    )}
                                  </div>
                                </div>
                                <div className="flex flex-col gap-2 w-full">
                                  <Typography variant="body" color="tertiary">
                                    Cooling
                                  </Typography>
                                  <Placeholder
                                    icon={
                                      <IconWrapper name="cool" width={16} />
                                    }
                                    value={data?.buyerCooling}
                                  />
                                  <div className="flex flex-wrap gap-1">
                                    {data?.buyerCoolingTypeMulti?.map(
                                      (value: string, index: number) => (
                                        <Label
                                          key={index}
                                          size="small"
                                          variant="outline"
                                          color="gray"
                                          title={value}
                                          className="whitespace-nowrap rounded-xl"
                                        />
                                      )
                                    )}
                                  </div>
                                </div>
                              </div>
                              <div className="flex gap-2 py-3">
                                <div className="w-full">
                                  <Typography
                                    variant="body"
                                    color="tertiary"
                                    className="mb-2"
                                  >
                                    Pool
                                  </Typography>
                                  <Placeholder
                                    icon={
                                      <IconWrapper name="pool" width={16} />
                                    }
                                    value={data?.buyerPool}
                                  />
                                </div>
                                <div className="w-full">
                                  <Typography
                                    variant="body"
                                    color="tertiary"
                                    className="mb-2"
                                  >
                                    Views
                                  </Typography>
                                  <div className="flex flex-wrap gap-1 py-2">
                                    {data?.buyerViewsMulti?.map(
                                      (view: string, index: number) => (
                                        <Label
                                          key={index}
                                          size="small"
                                          variant="outline"
                                          color="info"
                                          title={view}
                                        />
                                      )
                                    )}
                                  </div>
                                </div>
                              </div>
                              <div className="flex gap-2 py-3">
                                <div className="w-full">
                                  <Typography
                                    variant="body"
                                    color="tertiary"
                                    className="mb-2"
                                  >
                                    Attached Ok?
                                  </Typography>
                                  <Placeholder value={data?.buyerIsAttached} />
                                </div>
                                <div className="w-full">
                                  <Typography
                                    variant="body"
                                    color="tertiary"
                                    className="mb-2"
                                  >
                                    New Construction
                                  </Typography>
                                  <Placeholder
                                    value={data?.buyerNewConstruction}
                                  />
                                </div>
                              </div>
                              <div className="py-3">
                                <Typography
                                  variant="body"
                                  color="tertiary"
                                  className="mb-2"
                                >
                                  Keywords
                                </Typography>
                                <div className="flex flex-wrap gap-1">
                                  {data?.buyerKeywordsMulti?.map(
                                    (keyword: string, index: number) => (
                                      <Label
                                        key={index}
                                        size="small"
                                        variant="outline"
                                        color="info"
                                        title={keyword}
                                      />
                                    )
                                  )}
                                </div>
                              </div>
                              <div className="py-3">
                                <Typography
                                  variant="body"
                                  color="tertiary"
                                  className="mb-2"
                                >
                                  Amenities
                                </Typography>
                                <div className="flex flex-wrap gap-1">
                                  {data?.buyerAmenitiesMulti?.map(
                                    (amenity: string, index: number) => (
                                      <Label
                                        key={index}
                                        size="small"
                                        variant="outline"
                                        color="info"
                                        title={amenity}
                                      />
                                    )
                                  )}
                                </div>
                              </div>
                            </div>
                          </>
                        )}
                      {data?.leadType === "Buyer" &&
                        (currentTab === "All" ||
                          currentTab === "Financing") && (
                          <div className="mb-5 py-3">
                            <Typography
                              variant="h4"
                              color="primary"
                              className="py-3"
                            >
                              Financing
                            </Typography>
                            <Typography
                              variant="h4"
                              color="primary"
                              className="py-3"
                            >
                              Pre-Approval
                            </Typography>
                            <div className="flex gap-2 py-3">
                              <div className="w-full">
                                <Typography
                                  variant="body"
                                  color="tertiary"
                                  className="mb-2"
                                >
                                  Is pre-approved?
                                </Typography>
                                <Placeholder value={data?.buyerIsPreApproved} />
                              </div>
                              <div className="w-full">
                                <Typography
                                  variant="body"
                                  color="tertiary"
                                  className="mb-2"
                                >
                                  Maximum Purchase Price
                                </Typography>
                                <Placeholder
                                  icon={<IconWrapper name="money" width={16} />}
                                  value={
                                    <NumericFormat
                                      displayType="text"
                                      decimalSeparator="."
                                      thousandSeparator=","
                                      value={data?.buyerMaximumPurchasPrice}
                                    />
                                  }
                                />
                              </div>
                            </div>
                            <div className="flex gap-2 py-3">
                              <div className="w-full">
                                <Typography
                                  variant="body"
                                  color="tertiary"
                                  className="mb-2"
                                >
                                  Loan Officer First Name
                                </Typography>
                                <Placeholder
                                  value={data?.buyerLoanOfficerFirstName}
                                />
                              </div>
                              <div className="w-full">
                                <Typography
                                  variant="body"
                                  color="tertiary"
                                  className="mb-2"
                                >
                                  Loan Officer First Name
                                </Typography>
                                <Placeholder
                                  value={data?.buyerLoanOfficerLastName}
                                />
                              </div>
                            </div>
                            <div className="flex gap-2 py-3">
                              <div className="w-full">
                                <Typography
                                  variant="body"
                                  color="tertiary"
                                  className="mb-2"
                                >
                                  Loan Officer Phone
                                </Typography>
                                <Placeholder
                                  value={data?.buyerLoanOfficerPhone}
                                />
                              </div>
                              <div className="w-full">
                                <Typography
                                  variant="body"
                                  color="tertiary"
                                  className="mb-2"
                                >
                                  Loan Officer Email
                                </Typography>
                                <Placeholder
                                  value={data?.buyerLoanOfficerEmail}
                                />
                              </div>
                            </div>
                            <div className="flex gap-2 py-3">
                              <div className="w-full">
                                <Typography
                                  variant="body"
                                  color="tertiary"
                                  className="mb-2"
                                >
                                  Lender Company
                                </Typography>
                                <Placeholder value={data?.buyerLenderCompany} />
                              </div>
                            </div>
                            <div className="flex gap-2 py-3">
                              <div className="w-full">
                                <Typography
                                  variant="body"
                                  color="tertiary"
                                  className="mb-2"
                                >
                                  Primary Borrower
                                </Typography>
                                <Placeholder
                                  value={data?.buyerPrimaryBorrower}
                                />
                              </div>
                              <div className="w-full">
                                <Typography
                                  variant="body"
                                  color="tertiary"
                                  className="mb-2"
                                >
                                  Secondary Borrower
                                </Typography>
                                <Placeholder
                                  value={data?.buyerSecondaryBorrower}
                                />
                              </div>
                            </div>
                            <div className="flex gap-2 py-3">
                              <div className="w-full">
                                <Typography
                                  variant="body"
                                  color="tertiary"
                                  className="mb-2"
                                >
                                  Loan Type
                                </Typography>
                                <Placeholder value={data?.buyerLoanType} />
                              </div>
                              <div className="w-full">
                                <Typography
                                  variant="body"
                                  color="tertiary"
                                  className="mb-2"
                                >
                                  Purchase Price
                                </Typography>
                                <Placeholder
                                  icon={<IconWrapper name="money" width={16} />}
                                  value={
                                    <NumericFormat
                                      displayType="text"
                                      decimalSeparator="."
                                      thousandSeparator=","
                                      value={data?.buyerPurchasPrice}
                                    />
                                  }
                                />
                              </div>
                            </div>
                            <div className="flex gap-2 py-3">
                              <div className="w-full">
                                <Typography
                                  variant="body"
                                  color="tertiary"
                                  className="mb-2"
                                >
                                  Seller's Consession
                                </Typography>
                                <Placeholder
                                  icon={<IconWrapper name="money" width={16} />}
                                  value={
                                    <NumericFormat
                                      displayType="text"
                                      decimalSeparator="."
                                      thousandSeparator=","
                                      value={data?.buyerSellerConsession}
                                    />
                                  }
                                />
                              </div>
                              <div className="w-full">
                                <Typography
                                  variant="body"
                                  color="tertiary"
                                  className="mb-2"
                                >
                                  Down Payment Amount
                                </Typography>
                                <Placeholder
                                  icon={<IconWrapper name="money" width={16} />}
                                  value={
                                    <NumericFormat
                                      displayType="text"
                                      decimalSeparator="."
                                      thousandSeparator=","
                                      value={data?.buyerDownPaymentAmount}
                                    />
                                  }
                                />
                              </div>
                            </div>
                            <div className="flex gap-2 py-3">
                              <div className="w-full">
                                <Typography
                                  variant="body"
                                  color="tertiary"
                                  className="mb-2"
                                >
                                  Base Loan Amount
                                </Typography>
                                <Placeholder
                                  icon={<IconWrapper name="money" width={16} />}
                                  value={
                                    <NumericFormat
                                      displayType="text"
                                      decimalSeparator="."
                                      thousandSeparator=","
                                      value={data?.buyerBaseLoanAmount}
                                    />
                                  }
                                />
                              </div>
                              <div className="w-full">
                                <Typography
                                  variant="body"
                                  color="tertiary"
                                  className="mb-2"
                                >
                                  Loan to Value
                                </Typography>
                                <Placeholder
                                  icon={
                                    <IconWrapper name="percentage" width={16} />
                                  }
                                  value={
                                    <NumericFormat
                                      displayType="text"
                                      decimalSeparator="."
                                      thousandSeparator=","
                                      value={data?.buyerLoanToValue}
                                    />
                                  }
                                />
                              </div>
                            </div>
                            <div className="flex gap-2 py-3">
                              <div className="w-full">
                                <Typography
                                  variant="body"
                                  color="tertiary"
                                  className="mb-2"
                                >
                                  Taxes (Annual)
                                </Typography>
                                <Placeholder
                                  icon={<IconWrapper name="money" width={16} />}
                                  value={
                                    <NumericFormat
                                      displayType="text"
                                      decimalSeparator="."
                                      thousandSeparator=","
                                      value={data?.buyerAnnualTaxes}
                                    />
                                  }
                                />
                              </div>
                              <div className="w-full">
                                <Typography
                                  variant="body"
                                  color="tertiary"
                                  className="mb-2"
                                >
                                  Insurance (Annual)
                                </Typography>
                                <Placeholder
                                  icon={<IconWrapper name="money" width={16} />}
                                  value={
                                    <NumericFormat
                                      displayType="text"
                                      decimalSeparator="."
                                      thousandSeparator=","
                                      value={data?.buyerAnnualInsurance}
                                    />
                                  }
                                />
                              </div>
                            </div>
                            <div className="flex gap-2 py-3">
                              <div className="w-full">
                                <Typography
                                  variant="body"
                                  color="tertiary"
                                  className="mb-2"
                                >
                                  HOA Dues (Annual)
                                </Typography>
                                <Placeholder
                                  icon={<IconWrapper name="money" width={16} />}
                                  value={
                                    <NumericFormat
                                      displayType="text"
                                      decimalSeparator="."
                                      thousandSeparator=","
                                      value={data?.buyerAnnualHOADues}
                                    />
                                  }
                                />
                              </div>
                              <div className="w-full">
                                <Typography
                                  variant="body"
                                  color="tertiary"
                                  className="mb-2"
                                >
                                  Other Expenses (Annual)
                                </Typography>
                                <Placeholder
                                  icon={<IconWrapper name="money" width={16} />}
                                  value={
                                    <NumericFormat
                                      displayType="text"
                                      decimalSeparator="."
                                      thousandSeparator=","
                                      value={data?.buyerAnnualOtherExpenses}
                                    />
                                  }
                                />
                              </div>
                            </div>
                          </div>
                        )}
                    </div>
                  </Scrollbars>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default View;
