type Props = {
  width: number;
  height: number;
  stroke?: string;
  className?: string;
};

const IconLotSpace = (props: Props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 20 21"
      fill="none"
      {...props}
    >
      <path
        d="M10 6.875L15 4.375L10 1.875V10.2083"
        stroke="#6B7280"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.66668 10.1992L2.08335 12.8159C1.9551 12.8886 1.84842 12.994 1.77421 13.1213C1.69999 13.2487 1.66089 13.3935 1.66089 13.5409C1.66089 13.6883 1.69999 13.8331 1.77421 13.9604C1.84842 14.0878 1.9551 14.1932 2.08335 14.2659L9.16668 18.3159C9.42004 18.4622 9.70745 18.5392 10 18.5392C10.2926 18.5392 10.58 18.4622 10.8333 18.3159L17.9167 14.2659C18.0449 14.1932 18.1516 14.0878 18.2258 13.9604C18.3 13.8331 18.3391 13.6883 18.3391 13.5409C18.3391 13.3935 18.3 13.2487 18.2258 13.1213C18.1516 12.994 18.0449 12.8886 17.9167 12.8159L13.3333 10.2076"
        stroke="#6B7280"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.40845 10.9141L14.5918 16.1641"
        stroke="#6B7280"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.5917 10.9141L5.41675 16.1641"
        stroke="#6B7280"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default IconLotSpace;
