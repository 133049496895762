// Define the type for the component props
type Props = {
  width: number;
  height: number;
  stroke?: string;
  className?: string;
  innerfill?: string;
};

// Define the IconAgents component
const IconDot = (props: Props) => {
  // Return the SVG for the icon
  return (
    <svg viewBox="0 0 8 8" fill="currentColor" {...props}>
      <path
        d="M0 4C0 1.79086 1.79086 0 4 0C6.20914 0 8 1.79086 8 4C8 6.20914 6.20914 8 4 8C1.79086 8 0 6.20914 0 4Z"
        fill={props.innerfill ? props.innerfill : "#3C3C3C"}
      />
    </svg>
  );
};

// Export the IconDot component
export default IconDot;
