// Import necessary modules and components
import { Fragment } from 'react';
import Typography from '@/components/baseComponents/Typography';
import { Dialog, Transition } from '@headlessui/react';
import { IHomepageFeed } from '@/shared/interfaces/interfaces';
import XMark from '@/assets/icons/XMark.png';
import { Button } from '@/components/baseComponents/Button';
import IconWrapper from '@/components/baseComponents/IconWrapper';

// Define the props interface
interface IProps {
    open: boolean;
    closeModal: Function;
    value: IHomepageFeed;
}

// Define the PreviewModal component
const PreviewModal = (props: IProps) => {
    const closeModal = props.closeModal;

    // Render the component
    return (
        <Transition.Root show={props.open} as={Fragment}>
            <Dialog as="div" className="relative z-30" onClose={() => props.closeModal()}>
                <div className="fixed inset-0 flex items-center justify-center z-50">
                    <Dialog.Panel className='rounded-lg max-w-[60vw] w-full bg-white'>
                        <div className='px-10 py-3'>
                            <div className='flex justify-between items-center'>
                                <Typography variant='h2' className='mt-[8px] text-[#4C42D7]'>Preview</Typography>
                                <img src={XMark} alt="XMark" className='cursor-pointer w-4 h-4' onClick={() => closeModal()} />
                            </div>
                            <div className='px-4 border-[1px] border-[#4C42D7] w-full'></div>
                        </div>
                        <div className='flex items-center justify-center px-16 py-3 w-full h-[70vh] overflow-y-auto'>
                            <div className="relative flex flex-col w-full h-fit p-[16px] bg-purple-300 bg-opacity-20 bg-shadow-sm rounded-lg mb-5">
                                <div className="flex justify-between items-center mb-[14px]">
                                    <Typography variant='h3' color='primary'>{props.value.title ?? ''}</Typography>
                                    {props.value?.isMuted === 1 && (
                                        <Button variant="icon">
                                            <IconWrapper name="close" />
                                        </Button>
                                    )}
                                </div>
                                <Typography variant='body' color='primary'>{props.value.content ?? ''}</Typography>
                            </div>
                        </div>
                    </Dialog.Panel>
                </div>
            </Dialog>
        </Transition.Root>
    );
}

// Export the PreviewModal component
export default PreviewModal;