type Props = {
  width: number;
  height: number;
  stroke?: string;
  className?: string;
};

const IconGarage = (props: Props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      viewBox="0 0 20 21"
      fill="none"
    >
      <path
        d="M18.3334 7.16646V16.8748C18.3334 17.3168 18.1578 17.7407 17.8453 18.0533C17.5327 18.3659 17.1088 18.5415 16.6667 18.5415H3.33341C2.89139 18.5415 2.46746 18.3659 2.1549 18.0533C1.84234 17.7407 1.66675 17.3168 1.66675 16.8748V7.16646C1.66809 6.83408 1.76878 6.5097 1.95588 6.23498C2.14299 5.96027 2.40796 5.74777 2.71675 5.62479L9.38341 2.95812C9.7794 2.80042 10.2208 2.80042 10.6167 2.95812L17.2834 5.62479C17.5922 5.74777 17.8572 5.96027 18.0443 6.23498C18.2314 6.5097 18.3321 6.83408 18.3334 7.16646Z"
        stroke="#6B7280"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5 15.207H15"
        stroke="#6B7280"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5 11.875H15"
        stroke="#6B7280"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15 8.53906H5V18.5391H15V8.53906Z"
        stroke="#6B7280"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default IconGarage;
