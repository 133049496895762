import AddCompany from '@/assets/images/add_company.svg'
import AddProfile from '@/assets/images/add_profile.svg'
import IconWrapper from "@/components/baseComponents/IconWrapper"
import Typography from "@/components/baseComponents/Typography"

type IProps = {
    agent?: any
}

const CompanyAgentInfo = (props: IProps) => {
  const { agent } = props
  return (
    <>
      <div>
        <div className="flex justify-between gap-10">
          <div className="grid grid-cols-3 gap-3">
            <div className="col-span-1">
              <img
                className="w-full h-20 aspect-square rounded-md"
                src={agent?.company?.businessLogo || AddCompany}
              />
            </div>
              <div className="col-span-2 flex flex-col">
                <Typography variant="h4" color="primary" className="truncate">{agent?.company?.businessName || "Company"}</Typography>
                <div className="flex items-center gap-2">
                  <IconWrapper name="telephone" width={13} />
                  <Typography variant="medium-text" color="secondary" className="flex items-center gap-2">{agent?.company?.principalBrokerPhone || "None"}</Typography>
                </div>
                <div className="flex items-center gap-2">
                  <IconWrapper name="envelope" width={13} />
                  <Typography variant="medium-text" color="secondary" className="flex items-center gap-2">{agent?.company?.principalBrokerEmail || "None"}</Typography>
                </div>
              </div>
          </div>
          <div className="grid grid-cols-3 gap-3">
            <div className="col-span-1">
              <img className="w-full h-20 aspect-square rounded-md" src={agent?.avatarURL || AddProfile} />
            </div>
            <div className="col-span-2 flex flex-col">
              <Typography variant="h4" color="primary">{(agent?.firstName || "Agent") + " " + (agent?.lastName || "")}</Typography>
              <div className="flex items-center gap-2">
                <IconWrapper name="telephone" width={13} />
                <Typography variant="medium-text" color="secondary" className="flex items-center gap-2">{agent?.mobileNumber || "None"}</Typography>
              </div>
              <div className="flex items-center gap-2">
                <IconWrapper name="envelope" width={13} />
                <Typography variant="medium-text" color="secondary" className="flex items-center gap-2">{agent?.contactEmail || "None"}</Typography>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default CompanyAgentInfo