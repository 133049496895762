type Props = {
  width: number;
  height: number;
  stroke?: string;
  className?: string;
};

const IconRealEstateBrokers = (props: Props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 16 16"
      fill="none"
      {...props}
    >
      <path
        d="M14.2857 2.85718H1.71428C1.41118 2.85718 1.12049 2.97759 0.906161 3.19191C0.691834 3.40624 0.571426 3.69693 0.571426 4.00003V12C0.571426 12.3031 0.691834 12.5938 0.906161 12.8082C1.12049 13.0225 1.41118 13.1429 1.71428 13.1429H14.2857C14.5888 13.1429 14.8795 13.0225 15.0938 12.8082C15.3082 12.5938 15.4286 12.3031 15.4286 12V4.00003C15.4286 3.69693 15.3082 3.40624 15.0938 3.19191C14.8795 2.97759 14.5888 2.85718 14.2857 2.85718Z"
        stroke="#7D8E9F"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.71429 6.28564H12.5714M9.71429 9.71422H12.5714M5.14286 9.71422C5.36798 9.71422 5.5909 9.66987 5.79889 9.58372C6.00687 9.49757 6.19586 9.3713 6.35504 9.21211C6.51423 9.05293 6.6405 8.86395 6.72665 8.65596C6.8128 8.44797 6.85715 8.22505 6.85715 7.99993C6.85715 7.77481 6.8128 7.55189 6.72665 7.3439C6.6405 7.13591 6.51423 6.94693 6.35504 6.78775C6.19586 6.62856 6.00687 6.50229 5.79889 6.41614C5.5909 6.32999 5.36798 6.28564 5.14286 6.28564C4.6882 6.28564 4.25217 6.46626 3.93068 6.78775C3.60919 7.10924 3.42857 7.54527 3.42857 7.99993C3.42857 8.45459 3.60919 8.89062 3.93068 9.21211C4.25217 9.5336 4.6882 9.71422 5.14286 9.71422Z"
        stroke="#7D8E9F"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default IconRealEstateBrokers;
