import { IAddress } from "@/shared/interfaces/interfaces";
import { Combobox } from "@headlessui/react"
import { useState } from "react";
import TextField from "../TextField";

type AddressUnitAutoCompleteProps = {
    value?: string;
    options: Array<IAddress>;
    onChange: React.Dispatch<string>;
}

const AddressUnitAutoComplete = ({
    value,
    options,
    onChange
}: AddressUnitAutoCompleteProps) => {
    const [query, setQuery] = useState<string>("");
    const filteredOptions =
        query === ''
            ? options
            : options?.filter((option: IAddress) => {
                return option?.secondary?.toLowerCase().includes(query.toLowerCase())
            });
    
    return (
        <Combobox value={value} onChange={onChange} nullable>
            <Combobox.Input as={TextField} onChange={(event) => setQuery(event.target.value)} />
            <Combobox.Options className="absolute z-10 bg-white shadow-xl overflow-y-auto transition-opacity rounded-b-md w-[170px] max-h-60">
                {filteredOptions?.map((option: IAddress, index: number) => (
                    <Combobox.Option
                        key={`${option.secondary?.split(" ").join("__")}__${index}`}
                        value={option.secondary}
                        className="body text-primary px-4 py-2 cursor-pointer select-none hover:bg-neutral-100"
                    >
                        {option.secondary}
                    </Combobox.Option>
                ))}
            </Combobox.Options>
        </Combobox>
    )
}

export default AddressUnitAutoComplete