type Props = {
    width: number;
    height: number;
    stroke?: string;
    className?: string;
};

const IconArrowDownTail = (props: Props) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 17 16" fill="none" {...props}>
            <path
                d="M8.4987 3.33398V12.6673M8.4987 12.6673L13.1654 8.00065M8.4987 12.6673L3.83203 8.00065"
                stroke={props.stroke ?? "#6B7280"}
                strokeWidth="1.2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};

export default IconArrowDownTail;
