import { Dispatch, forwardRef, SetStateAction, useEffect, useImperativeHandle, useState } from 'react'
import { useLocation } from 'react-router-dom'
import AdvancedTable from '@/components/baseComponents/AdvancedTable'
import Typography from '@/components/baseComponents/Typography'
import { Button } from '@/components/baseComponents/Button'
import DefaultListingImage from "@/assets/images/default_listing_image.webp";
import View from '../myListings/View';
import TableEmptyState from '@/components/baseComponents/TableEmptyState';
import { NumericFormat } from 'react-number-format';
import { useAppDispatch, useAppSelector } from '@/redux/hooks'
import { fetchAddressAutocomplete, getAddresses, getCities } from '@/redux/user/userSlice'
import { BookmarkMyListingDto, GetMyListingsDto, ListingAdvancedSearchDto } from '@/shared/interfaces/interfaces'
import { IconType } from '@/components/baseComponents/IconWrapper/Icons'
import { bookmarkMyListings, getListingById, getListingsByAdvancedSearch } from '@/redux/myListings/myListingSlice'
import { notify } from '@/shared/services/notify'
import IconWrapper from '@/components/baseComponents/IconWrapper'
import { IoMdClose } from 'react-icons/io'
import AdvancedAddressSearch from '@/components/baseComponents/AdvancedAddressSearch'
import { MdOutlineSearch } from 'react-icons/md'
import Select from '@/components/baseComponents/Select'
import { getTime } from 'date-fns'
import { useDebounce } from 'use-debounce'

const propertyType = [
    { value: "Single Family Residence", label: "Single Family Residence" },
    { value: "Multi Family Residence", label: "Multi Family Residence" },
    { value: "Apartment", label: "Apartment" },
    { value: "Townhouse", label: "Townhouse" },
    { value: "Land", label: "Land" },
    { value: "Mobile", label: "Mobile" },
    { value: "Commercial", label: "Commercial" },
    { value: "Other", label: "Other" },
];

const TableFields = [
    {
        name: "",
        type: "checkbox",
        slug: "_id",
        class_name: "text-left !p-[8px]",
    },
    {
        name: "Address",
        type: "address_image",
        slug: "imageSrc",
        imageClass: "w-[4rem]",
        class_name: "text-left pl-[16px] min-w-[300px] !py-[20px]",
    },
    {
        name: "Status",
        type: "status_show",
        slug: "status",
        class_name: "text-left !p-[8px]  min-w-[100px] ",
    },
    {
        name: "Price",
        type: "text",
        slug: "listingListPriceHtml",
        class_name: "text-left !p-[8px]",
    },
    {
        name: "Bedrooms",
        type: "text",
        slug: "propertyBedrooms",
        class_name: "text-left !p-[8px] xl:min-w-[100px] ",
    },
    {
        name: "Bathrooms",
        type: "text",
        slug: "propertyBathrooms",
        class_name: "text-left !p-[8px] xl:min-w-[100px] ",
    },
    {
        name: "Property Type",
        type: "text",
        slug: "propertyType",
        class_name: "text-left !py-[8px] w",
    },
];

const SubTypeList = [
    {
        parent: "Single Family Residence",
        contents: [
            { value: "Cabin", label: "Cabin" },
            { value: "Manufactured Home", label: "Manufactured Home" },
            { value: "Manufactured On Land", label: "Manufactured On Land" },
            { value: "Mobile Home", label: "Mobile Home" },
            { value: "Own Your Own", label: "Own Your Own" },
            { value: "Single Family Residence", label: "Single Family Residence" },
            { value: "Mixed Use", label: "Mixed Use" },
        ],
    },
    {
        parent: "Multi Family Residence",
        contents: [
            { value: "Duplex", label: "Duplex" },
            { value: "Manufactured Home", label: "Manufactured Home" },
            { value: "Manufactured On Land", label: "Manufactured On Land" },
            { value: "Mobile Home", label: "Mobile Home" },
            { value: "Own Your Own", label: "Own Your Own" },
            { value: "Quadruplex", label: "Quadruplex" },
            { value: "Triplex", label: "Triplex" },
        ],
    },
    {
        parent: "Apartment",
        contents: [
            { value: "Apartment", label: "Apartment" },
            { value: "Condominium", label: "Condominium" },
            { value: "Own Your Own", label: "Own Your Own" },
            { value: "Stock Cooperative", label: "Stock Cooperative" },
        ],
    },
    {
        parent: "Townhouse",
        contents: [
            { value: "Own Your Own", label: "Own Your Own" },
            { value: "Townhouse", label: "Townhouse" },
        ],
    },
    {
        parent: "Land",
        contents: [
            { value: "Farm (PropertySubType)", label: "Farm (PropertySubType)" },
            { value: "Own Your Own", label: "Own Your Own" },
            { value: "Ranch", label: "Ranch" },
            { value: "Unimproved Land", label: "Unimproved Land" },
        ],
    },
    {
        parent: "Mobile",
        contents: [
            { value: "Mobile Home", label: "Mobile Home" },
            { value: "Own Your Own", label: "Own Your Own" },
        ],
    },
    {
        parent: "Commercial",
        contents: [
            { value: "Own Your Own", label: "Own Your Own" },
            { value: "Stock Cooperative", label: "Stock Cooperative" },
            {
                value: "Business (PropertySubType)",
                label: "Business (PropertySubType)",
            },
            { value: "Hotel-Motel", label: "Hotel-Motel" },
            {
                value: "Industrial (PropertySubType)",
                label: "Industrial (PropertySubType)",
            },
            { value: "Mixed Use", label: "Mixed Use" },
            { value: "Multi Family", label: "Multi Family" },
            {
                value: "Office (PropertySubType)",
                label: "Office (PropertySubType)",
            },
            {
                value: "Retail (PropertySubType)",
                label: "Retail (PropertySubType)",
            },
            {
                value: "Warehouse (PropertySubType)",
                label: "Warehouse (PropertySubType)",
            },
        ],
    },
    {
        parent: "Other",
        contents: [
            { value: "Boat Slip", label: "Boat Slip" },
            { value: "Cabin", label: "Cabin" },
            { value: "Deeded Parking", label: "Deeded Parking" },
            { value: "Farm (PropertySubType)", label: "Farm (PropertySubType)" },
            { value: "Manufactured Home", label: "Manufactured Home" },
            { value: "Manufactured On Land", label: "Manufactured On Land" },
            { value: "Own Your Own", label: "Own Your Own" },
            { value: "Ranch", label: "Ranch" },
            { value: "Timeshare", label: "Timeshare" },
            {
                value: "Agriculture (PropertySubType)",
                label: "Agriculture (PropertySubType)",
            },
            {
                value: "Business (PropertySubType)",
                label: "Business (PropertySubType)",
            },
        ],
    },
];

interface FilterItem {
    label: string;
    value: string | number | boolean;
    title?: string;
    icon?: IconType;
}
export interface ExpansiveRef {
    handleSearch: () => void;
    handleClearQueries: () => void;
}

interface Props {
    isSearched: boolean;
    setIsSearched: Dispatch<SetStateAction<boolean>>;
    value: ListingAdvancedSearchDto;
    setValue: Dispatch<SetStateAction<ListingAdvancedSearchDto>>;
    initialQuery: ListingAdvancedSearchDto;
    activeOnly: boolean;
    setOpenConfirm: Dispatch<SetStateAction<boolean>>;
    setOpen: Dispatch<SetStateAction<boolean>>;
    setOpenView: Dispatch<SetStateAction<boolean>>;
    openView: boolean;
    open: boolean;
    recordsPerPage: number;
    setRecordsPerPage: Dispatch<SetStateAction<number>>;
    currentPage: number;
    setCurrentPage: Dispatch<SetStateAction<number>>;
    width: number;
    user: any
}

const Expansive = forwardRef<ExpansiveRef, Props>((props, ref) => {
    const {
        isSearched,
        setIsSearched,
        value,
        setValue,
        initialQuery,
        activeOnly,
        setOpen,
        open,
        openView,
        setOpenView,
        recordsPerPage,
        setRecordsPerPage,
        currentPage,
        setCurrentPage,
        width,
        user
    } = props;

    const location = useLocation();
    const urlState = location.state;
    const dispatch = useAppDispatch()
    const addresses = useAppSelector(getAddresses);
    const cities = useAppSelector(getCities);
    const [noResultFlag, setNoResultFlag] = useState<boolean>(false);
    const [propertySubType, setPropertySubType] = useState<Array<any>>([]);
    const [viewData, setViewdata] = useState<any>(null);
    const [filteredData, setFilteredData] = useState<Array<any>>([]);
    const [totalCount, setTotalCount] = useState<number>(0);
    const [showResetButton, setShowResetButton] = useState<boolean>(false);
    const [selectedPropertyType, setSelectedPropertyType] = useState(null);
    const [selectedPropertySubType, setSelectedPropertySubType] = useState(null);
    const [selectedRows, setSelectedRows] = useState<Array<any>>([]);
    const [filterList, setFilterList] = useState<FilterItem[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [addressSearchTerm, setAddressSearchTerm] = useState<string>("");
    const [debouncedAddressSearchTerm] = useDebounce(addressSearchTerm, 1000);

    const detectChange = () => {
        const str1 = JSON.stringify(initialQuery);
        const str2 = JSON.stringify(value);
        if (str1 === str2) {
            setShowResetButton(false);
            return true;
        }
        setShowResetButton(true);
        return false;
    };

    useImperativeHandle(ref, () => ({
        handleSearch,
        handleClearQueries,
    }));

    useEffect(() => {
        setIsSearched(false);
    }, [location.key, //cities
    ]);

    useEffect(() => {
        if (urlState?.id && urlState.id !== "") {
            dispatch(getListingById({ id: urlState.id }))
                .then((res) => {
                    if (res.payload.success) {
                        const data = res.payload.data;
                        var listing;
                        if (Array.isArray(data)) {
                            listing = data[0];
                        } else {
                            listing = data;
                        }

                        if (listing && listing._id && listing._id !== "") {
                            onSetPage(1);
                            setIsSearched(true);
                            setViewdata(listing);
                            setOpenView(true);
                        }
                    } else {
                        notify(false, "Something went wrong.");
                    }
                })
                .catch(() => {
                    notify(false, "Something went wrong.");
                });
        }
    }, [urlState]);

    useEffect(() => {
        detectChange();
    }, [value]);


    const makeTableData = (data: Array<object>) => {
        const res: Array<object> = [];
        data.map((item: any) => {
            const new_item = JSON.parse(JSON.stringify(item));

            // temporary, to be phased out since we broke down the address by parts 12-20-2023
            if (new_item?.listingAddress.city) {
                new_item.address = (
                    <div>
                        {new_item?.isPublic === 1 && (
                            <div className="truncate">
                                {new_item?.listingAddress?.streetLine +
                                    " " +
                                    (new_item?.listingUnit || new_item?.listingAddress?.secondary)}
                            </div>
                        )}
                        <div className={`truncate ${new_item?.isPublic === 1 ? `text-tertiary` : `text-primary`}`}>
                            {new_item?.listingAddress?.city +
                                ", " +
                                new_item?.listingAddress?.state +
                                " " +
                                new_item?.listingAddress?.zipcode}
                        </div>
                    </div>
                );
            } else {
                new_item.address = (
                    <div className="!line-clamp-2 !whitespace-normal">
                        Address Kept Private
                    </div>
                );
            }

            new_item.imageSrc =
                (new_item.propertyPhotos && new_item.propertyPhotos[0]?.file) ||
                DefaultListingImage;
            new_item.listingListPriceHtml = (
                <NumericFormat
                    displayType="text"
                    thousandSeparator=","
                    prefix="$"
                    value={item.listingListPrice}
                />
            );
            res.push(new_item);
        });
        return res;
    };

    const searchData = (currentPage: number) => {
        setNoResultFlag(false);
        setRecordsPerPage(25);

        value.currentPage = currentPage;
        value.status = activeOnly ? "Active" : "";
        if (value.listingListPriceMax) {
            value.listingListPriceMax = value.listingListPriceMax.substring(1, value.listingListPriceMax.length)
        }
        if (value.listingListPriceMin) {
            value.listingListPriceMin = value.listingListPriceMin.substring(1, value.listingListPriceMin.length)
        }
        dispatch(getListingsByAdvancedSearch(value)).then((res) => {
            try {
                setTotalCount(res.payload.totalCount);
                if (res.payload.totalCount === 0) {
                    setIsSearched(false);
                    setNoResultFlag(true);
                } else {
                    setIsSearched(true);
                    const table_data = makeTableData(
                        res.payload.listingsForAdvancedSearch
                    );
                    setFilteredData(table_data);
                }
            } catch (e) { }
        });
    };

    const changePropertyType = (value: any) => {
        handleSelectChange(value, "propertyType");
        SubTypeList.map((item) => {
            if (item.parent === value.value) {
                setPropertySubType(item.contents);
            }
        });
    };

    const handleInputChange = (item: any, key: string) => {
        setValue({
            ...value,
            [key]: item.target.value,
        });
    };

    const handleSelectChange = (item: any, key: string) => {
        setValue({
            ...value,
            [key]: item.value,
        });
    };

    const onClickRow = (value: any) => {
        setOpenView(true);
        setViewdata(value);
    };

    const onSetPage = (page: number) => {
        setCurrentPage(page);
        searchData(page);
    };

    const onSelectRow = (value: any) => {
        setSelectedRows(value);
    };

    const onBookmarkSelectedListings = () => {
        if (selectedRows.length > 0) {
            let ids = selectedRows.map((listing: any) => {
                return listing._id;
            });
            const data: BookmarkMyListingDto = {
                ids: ids,
                bookmark: true,
                userId: user._id,
                search: {
                    userId: user._id,
                    keyword: "",
                    sortField: "",
                    sortType: "Descending",
                    recordsPerPage: 25,
                    currentPage: 1,
                    status: "Bookmarked",
                },
            };
            dispatch(bookmarkMyListings(data)).then((res) => {
                try {
                    notify(res.payload.success, res.payload.message);
                } catch (e) {
                    notify(false, "Something went wrong.");
                }
            });
        }
    };

    const getNameFromVariable = (name: string): string => {
        let result = name.replace(/([A-Z])/g, " $1");
        result = result.charAt(0).toUpperCase() + result.slice(1);
        return result;
    };

    useEffect(() => {
        if (isSearched) {
            onSetPage(1);
        }
    }, [activeOnly, isSearched]);

    const handleFilterClose = (label: string) => {
        let newFilterList = filterList.filter((ele) => ele.label != label);
        setFilterList(newFilterList);
        let initValue = initialQuery[label as keyof ListingAdvancedSearchDto];
        setValue({
            ...value,
            [label as keyof ListingAdvancedSearchDto]: initValue,
        });
        searchData(1);
    };

    // useLayoutEffect(() => {
    //     const updateDimensions = () => {
    //         if (targetRef.current) {
    //             const { width } = targetRef.current.getBoundingClientRect();
    //             setWidth(width);
    //         }
    //     };
    //     updateDimensions();
    //     const handleResize = () => {
    //         updateDimensions();
    //     };
    //     window.addEventListener("resize", handleResize);
    //     return () => {
    //         window.removeEventListener("resize", handleResize);
    //     };
    // }, []);

    const handleSearch = () => {
        if (open) {
            setOpen(false);
        }
        getFilterList();
        searchData(1);
    }

    const handleClearQueries = () => {
        setValue({ ...initialQuery });
        setSelectedPropertyType(null);
        setSelectedPropertySubType(null);
    };

    const getFilterList = () => {
        let list: FilterItem[] = [];
        let keys = Object.keys(value);
        for (let key of keys) {
            if (
                key != "additionalAddresses" &&
                key != "listingListPriceMin" &&
                key != "listingListPriceMax"
            ) {
                if (
                    value[key as keyof ListingAdvancedSearchDto] !==
                    initialQuery[key as keyof ListingAdvancedSearchDto]
                ) {
                    let title = getNameFromVariable(key);
                    let icon: IconType = "bed";
                    if (key === "propertyBedrooms") {
                        icon = "bed";
                    }
                    if (key === "propertyBathrooms") {
                        icon = "bath";
                    }
                    if (
                        initialQuery[key as keyof ListingAdvancedSearchDto] != undefined
                    ) {
                        list.push({
                            value: value[key as keyof ListingAdvancedSearchDto] as
                                | string
                                | boolean
                                | number,
                            title,
                            icon: icon,
                            label: key,
                        });
                    }
                }
            }
        }
        setFilterList(list);
    };

    useEffect(() => {
        if (debouncedAddressSearchTerm) {
            setLoading(true);
            setValue({
                ...value,
                address: debouncedAddressSearchTerm,
            });
            dispatch(fetchAddressAutocomplete({ address: debouncedAddressSearchTerm })).then(() => {
                setLoading(false);
            });
        }
    }, [debouncedAddressSearchTerm])

    return (
        <div className="flex flex-col w-full">
            {isSearched ? (
                <div className="flex flex-col w-full mt-3">
                    <div className="flex flex-col w-full p-1 gap-2 py-3">
                        <div className="flex w-full justify-between items-center gap-2">
                            <Typography className="text-[18px] font-bold">
                                Search Results
                            </Typography>
                            <div className="flex gap-3">
                                {selectedRows.length > 0 && (
                                    <Button
                                        variant="icon"
                                        className="h-6"
                                        onClick={() => onBookmarkSelectedListings()}
                                    >
                                        <IconWrapper name="bookmark-plus" width={14} />
                                    </Button>
                                )}
                                <Button
                                    variant="text"
                                    className="flex items-center border border-[#E5E7EB] border-1 gap-2 text-[14px] bg-[#F3F4F6] hover:bg-[#F9FAFB]"
                                    onClick={() => setIsSearched(false)}
                                >
                                    <IconWrapper width={16} height={16} name="search" />
                                    <Typography className="text-primary text-[14px] hidden md:inline">
                                        Search
                                    </Typography>
                                </Button>
                            </div>
                        </div>
                        <div className="flex flex-wrap gap-2">
                            {filterList.map((ele: FilterItem, index: number) => (
                                <div
                                    key={index}
                                    className="flex gap-1 items-center rounded-full py-1 px-2 border border-1 border-[#D1D5DB] bg-white"
                                >
                                    <div className="flex gap-0.5">
                                        {(ele.label == "propertyBedrooms" ||
                                            ele.label == "propertyBathrooms") &&
                                            ele.icon && (
                                                <IconWrapper width={16} height={16} name={ele.icon} />
                                            )}
                                        {(ele.label == "companyOnly" ||
                                            ele.label == "teamOnly") && (
                                                <Typography className="text-[14px]">
                                                    {ele.title}
                                                </Typography>
                                            )}
                                        {ele.label != "companyOnly" && ele.label != "teamOnly" && (
                                            <Typography className="text-[14px]">
                                                {ele.value}
                                            </Typography>
                                        )}
                                    </div>
                                    <div
                                        className="flex"
                                        onClick={() => {
                                            handleFilterClose(ele.label);
                                        }}
                                    >
                                        <IoMdClose
                                            className={`h-4 w-4 text-[#9CA3AF] hover:text-[#DC2626] `}
                                        />
                                        {/* <IconWrapper iconClassName="text-[#DC2626]" width={16} height={16} name="close" className="cursor-pointer text-red" /> */}
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className="mt-8">
                        <View
                            open={openView}
                            changeState={setOpenView}
                            data={viewData}
                            search={
                                {
                                    userId: user._id,
                                    keyword: "",
                                    sortField: "",
                                    sortType: "",
                                    recordsPerPage: 25,
                                    currentPage: 1,
                                    status: "",
                                } as GetMyListingsDto
                            }
                        />
                        <div className="flex w-full">
                            <AdvancedTable
                                minCellWidth={100}
                                class_name="custom-table table items-center"
                                data={filteredData}
                                fields={TableFields}
                                onClickRow={onClickRow}
                                totalPage={Math.ceil(totalCount / recordsPerPage)}
                                totalCount={totalCount}
                                currentPage={currentPage}
                                recordsPerpage={recordsPerPage}
                                onSetPage={onSetPage}
                                onSelectRow={onSelectRow}
                                isDefaultMobile={false}
                            />
                        </div>
                    </div>
                </div>
            ) : (
                <div className="mt-8">
                    <div className="w-full flex flex-col bg-white flex justify-center rounded-md">
                        <div className="flex bg-white flex-col w-full p-6">
                            <div
                                className={`flex  gap-8 ${width > 600 ? "flex-col md:flex-row w-full" : "flex-col"
                                    }`}
                            >
                                <div className={`flex flex-col gap-4 ${width < 600 ? 'w-full' : 'md:w-1/2 '}`}>
                                    <div className="flex flex-col w-full py-3 gap-2">
                                        <Typography variant="caption" className="text-secondary">
                                            Search Location
                                        </Typography>
                                        <div className="flex relative w-full ">
                                            <AdvancedAddressSearch
                                                cities={cities}
                                                value={value.address}
                                                addresses={addresses}
                                                loading={loading}
                                                placeholder="Enter an Address, City, State or Zip Code"
                                                className={`!border-0 !border-b-2 w-full `}
                                                inputClassName={`!border-0 pl-8 ${value.address ? "bg-[#E5E7EB]" : "bg-[#F1F5F9]"}`}
                                                onChange={(value) => setAddressSearchTerm(value)}
                                                onAllChange={(option) => {
                                                    setValue((prev: ListingAdvancedSearchDto) => {
                                                        return {
                                                            ...prev,
                                                            address: option,
                                                        };
                                                    });
                                                }}
                                            />
                                            <MdOutlineSearch
                                                className={`absolute top-[14px] left-[10px] text-[#8E9CB2]`}
                                            />
                                        </div>
                                    </div>
                                    <div className="flex  flex-col sm:flex-row gap-6">
                                        <div className="flex flex-col w-full gap-2">
                                            <Typography variant="caption" className="text-secondary">
                                                Property Type
                                            </Typography>
                                            <div className="border-b-2 w-full">
                                                <Select
                                                    className={`!border-0 !border-transparent  ${value.propertyType
                                                        ? "!bg-[#E5E7EB]"
                                                        : "!bg-[#F1F5F9]"
                                                        }`}
                                                    placeholder="Select"
                                                    options={propertyType}
                                                    name={`propertyType-${getTime(new Date())}`}
                                                    value={selectedPropertyType}
                                                    onChange={(value) => {
                                                        changePropertyType(value);
                                                        setSelectedPropertyType(value);
                                                    }}
                                                />
                                                {/* onChange={(value) => { changePropertyType(value); }} */}
                                            </div>
                                        </div>
                                        <div className="flex flex-col w-full gap-2">
                                            <Typography variant="caption" className="text-secondary">
                                                Property Sub-Type
                                            </Typography>
                                            <div className="border-b-2 w-full">
                                                <Select
                                                    disabled={value?.propertyType ? false : true}
                                                    className={`!border-0 !border-transparent   ${value.propertySubType
                                                        ? "!bg-[#E5E7EB]"
                                                        : "!bg-[#F1F5F9]"
                                                        }`}
                                                    placeholder="Select"
                                                    options={propertySubType}
                                                    name={`propertySubType-${getTime(new Date())}`}
                                                    value={selectedPropertySubType}
                                                    onChange={(value) => {
                                                        setSelectedPropertySubType(value);
                                                        handleSelectChange(value, "propertySubType");
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className={`flex flex-col gap-4 ${width < 600 ? 'w-full' : 'md:w-1/2 '}`}>
                                    <div className="flex flex-col sm:flex-row w-full gap-6">
                                        <div className="flex flex-col w-full py-3 gap-2">
                                            <Typography variant="caption" className="text-secondary">
                                                Min. List Price
                                            </Typography>
                                            <NumericFormat
                                                autoComplete="off"
                                                thousandSeparator=","
                                                prefix="$ "
                                                name={`listingListPriceMin-${getTime(new Date())}`}
                                                placeholder="$ 0"
                                                value={value.listingListPriceMin}
                                                onChange={(e) =>
                                                    handleInputChange(e, "listingListPriceMin")
                                                }
                                                className={`medium-text rounded-5 h-10 text-primary p-4 outline-none w-full placeholder:medium-text border-transparent focus:border-transparent ${value.listingListPriceMin
                                                    ? "bg-[#E5E7EB]"
                                                    : "bg-[#F1F5F9]"
                                                    }`}
                                            />
                                        </div>
                                        <div className="flex flex-col w-full py-3 gap-2">
                                            <Typography variant="caption" className="text-secondary">
                                                Max. List Price
                                            </Typography>
                                            <NumericFormat
                                                autoComplete="off"
                                                thousandSeparator=","
                                                prefix="$ "
                                                name={`listingListPriceMax-${getTime(new Date())}`}
                                                placeholder="$ 0"
                                                value={value.listingListPriceMax}
                                                onChange={(e) =>
                                                    handleInputChange(e, "listingListPriceMax")
                                                }
                                                className={`medium-text rounded-5 h-10 text-primary p-4 outline-none w-full placeholder:medium-text border-transparent focus:border-transparent  ${value.listingListPriceMax
                                                    ? "bg-[#E5E7EB]"
                                                    : "bg-[#F1F5F9]"
                                                    }`}
                                            />
                                        </div>
                                    </div>
                                    <div className="flex flex-col sm:flex-row w-full gap-6">
                                        <div className="flex flex-col w-full py-3 gap-2">
                                            <Typography variant="caption" className="text-secondary">
                                                Min. Bedrooms
                                            </Typography>
                                            <NumericFormat
                                                autoComplete="off"
                                                thousandSeparator=","
                                                name={`propertyBedrooms-${getTime(new Date())}`}
                                                placeholder="1"
                                                value={value.propertyBedrooms}
                                                onChange={(e) =>
                                                    handleInputChange(e, "propertyBedrooms")
                                                }
                                                className={`medium-text rounded-5 h-10 text-primary p-4 outline-none w-full placeholder:medium-text border-transparent focus:border-transparent ${value.propertyBedrooms
                                                    ? "bg-[#E5E7EB]"
                                                    : "bg-[#F1F5F9]"
                                                    }`}
                                            />
                                        </div>
                                        <div className="flex flex-col w-full py-3 gap-2 medium-text">
                                            <Typography variant="caption" className="text-secondary">
                                                Min. Bathrooms
                                            </Typography>
                                            <NumericFormat
                                                autoComplete="off"
                                                thousandSeparator=","
                                                name={`propertyBathrooms-${getTime(new Date())}`}
                                                placeholder="1"
                                                value={value.propertyBathrooms}
                                                onChange={(e) =>
                                                    handleInputChange(e, "propertyBathrooms")
                                                }
                                                className={`medium-text rounded-5 h-10 text-primary p-4 outline-none w-full placeholder:medium-text border-transparent focus:border-transparent ${value.propertyBathrooms
                                                    ? "bg-[#E5E7EB]"
                                                    : "bg-[#F1F5F9]"
                                                    }`}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className={`"flex  w-full mt-8 justify-between ${width < 600 ? 'flex-col' : 'flex-col md:flex-row'}`}>
                                <div className="flex md:gap-8 sm:gap-6 gap-2 w-full justify-start">
                                    {/* <div className="flex">
                                        <label className="inline-flex items-center mb-5 cursor-pointer">
                                            <input
                                                type="checkbox"
                                                className="sr-only peer"
                                                value={"company"}
                                                checked={value.companyOnly === true}
                                                onChange={(e) => {
                                                    setValue({
                                                        ...value,
                                                        companyOnly: e.target.checked,
                                                        teamOnly: e.target.checked ? false : value.teamOnly,
                                                    });
                                                }}
                                            />
                                            <div className="relative w-9 h-5 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-4 after:w-4 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
                                            <Typography className="text-[14px] pl-2">
                                                Company only
                                            </Typography>
                                        </label>
                                    </div> */}
                                    {/* Temporarily Commented Out - Do not remove! */}
                                    {/* <div className="flex">
                                        <label className="inline-flex items-center mb-5 cursor-pointer">
                                            <input
                                                type="checkbox"
                                                value={"team"}
                                                className="sr-only peer"
                                                checked={value.teamOnly === true}
                                                onChange={(e) => {
                                                    setValue({
                                                        ...value,
                                                        teamOnly: e.target.checked,
                                                        companyOnly: e.target.checked
                                                            ? false
                                                            : value.companyOnly,
                                                    });
                                                }}
                                            />
                                            <div className="relative w-9 h-5 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-4 after:w-4 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
                                            <Typography className="text-[14px] pl-2">
                                                Team Only
                                            </Typography>
                                        </label>
                                    </div> */}
                                </div>
                                <div className={`flex gap-4 w-full justify-end mt-8 ${width > 600 ? 'md:mt-0' : ''}`}>
                                    {showResetButton && (
                                        <Button
                                            color="default"
                                            onClick={() => handleClearQueries()}
                                        >
                                            <Typography variant="button1">
                                                Reset
                                            </Typography>
                                        </Button>
                                    )}
                                    <Button
                                        onClick={() => {
                                            handleSearch()
                                        }}
                                    >
                                        <Typography variant="button1">
                                            Search
                                        </Typography>
                                    </Button>
                                </div>
                            </div>
                            {noResultFlag && (
                                <TableEmptyState
                                    heading="No Results Found"
                                    content="Try broader search criteria for results."
                                />
                            )}
                        </div>
                    </div>
                </div>
            )}
        </div>
    )
})
export default Expansive