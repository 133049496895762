type Props = {
    width: number;
    height: number;
    stroke?: string;
    className?: string;
};

const IconAlertCircle = (props: Props) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25 24" fill="none" {...props}>
            <path
                d="M12.5 8V12M12.5 16H12.51M22.5 12C22.5 17.5228 18.0228 22 12.5 22C6.97715 22 2.5 17.5228 2.5 12C2.5 6.47715 6.97715 2 12.5 2C18.0228 2 22.5 6.47715 22.5 12Z"
                stroke={props.stroke ?? "#A16207"}
                strokeWidth="1.75"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};

export default IconAlertCircle;
