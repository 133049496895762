import { Fragment } from 'react'
import Typography from '@/components/baseComponents/Typography'
import { Dialog, Transition } from '@headlessui/react'
import { IPost } from '@/shared/interfaces/interfaces'
import IconWrapper from '@/components/baseComponents/IconWrapper'
import { Button } from '@/components/baseComponents/Button'

interface IProps {
    open: boolean;
    closeModal: Function;
    value: IPost;
}

const PostModal = (props: IProps) => {
    const closeModal = props.closeModal

    return (
        <Transition.Root show={props.open} as={Fragment}>
            <Dialog as="div" className="relative z-30" onClose={() => props.closeModal()}>
                <div className="fixed inset-0 flex items-center justify-center z-50">
                    <Dialog.Panel className="rounded-lg max-w-[800px] w-full bg-white">
                        <div className="px-10 py-3">
                            <div className="flex justify-between items-center">
                                <Typography variant="h2" color="indigo" className="mt-2">{props.value?.postTitle}</Typography>
                                <Button variant="icon" className="group" onClick={() => closeModal()}>
                                    <IconWrapper name="close" className="group-hover:[&>svg>path]:!stroke-indigo-600"/>
                                </Button>
                            </div>
                            <div className="px-4 border-1 border-indigo-600 w-full mt-2"></div>
                        </div>
                        <div className="flex flex-col gap-4 px-16 py-3 h-[70vh] overflow-y-auto">
                            <Typography variant="body" color="primary">{props.value?.postContent}</Typography>
                        </div>
                    </Dialog.Panel>
                </div>
            </Dialog>
        </Transition.Root>
    )
}
export default PostModal;