// Import necessary modules and components
import { useAppDispatch, useAppSelector } from '@/redux/hooks'
import { getWelcome, getWelcomeCards } from '@/redux/welcome/welcomeSlice';
import { IWelcome } from '@/shared/interfaces/interfaces';
import { notify } from '@/shared/services/notify';
import { useEffect } from 'react';

// Define the DashboardSimple component
const DashboardSimple = () => {
    const dispatch = useAppDispatch();

    // Use the Redux hook to get the welcome cards
    const welcomeCards = useAppSelector(getWelcome);

    // Fetch data on component mount
    useEffect(() => {
        dispatch(getWelcomeCards()).then((res) => {
            if (!res.payload.success) {
                notify(false, "Something went wrong.");
            }
        }).catch(() => {
            notify(false, "Something went wrong.");
        });
    }, []);

    // Render the component
    return (
        <>
            {welcomeCards && welcomeCards.map((card: IWelcome, index: number) => (
                <div 
                    key={index} 
                    className={`bg-white rounded-lg shadow-sm lg:xl:px-12 lg:xl:py-16 md:px-6 md:py-8 sm:px-6 sm:py-8 max-sm:px-6 max-sm:py-8 ${card.media ? `flex gap-8 justify-end` : ``} lg:col-span-${card.gridSpan} sm:col-span-3 max-sm:col-span-3 ${card.gridSpan !== 3 ? `flex-col-reverse` : `max-lg:flex-col-reverse`}`}
                >
                    <div className='ql-snow w-full'>
                        <div className='ql-editor !p-0' dangerouslySetInnerHTML={{ __html: card.content }} />
                    </div>
                    <div className="flex items-center w-full">
                        {card.media && (
                            card.media.includes("mp4") ? (
                                <video
                                    className="rounded-lg"
                                    onClick={(event) => { event.currentTarget.paused ? event.currentTarget.play() : event.currentTarget.pause(); event.preventDefault(); }}
                                >
                                    <source src={card.media} type="video/mp4" />
                                    Your browser does not support the video tag.
                                </video>
                            ) : (
                                <img className="rounded-lg" src={card.media} />
                            )
                        )}
                    </div>
                </div>
            ))}
        </>
    )
}

// Export the DashboardSimple component
export default DashboardSimple;