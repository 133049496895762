import { ActionMeta } from "react-select"
import { useAppDispatch } from "@/redux/hooks"
import { fetchAddressAutocomplete } from "@/redux/user/userSlice"
import Select from "@/components/baseComponents/Select"
import { useEffect, useState } from "react";

type Props = {
  filterKeys?: Array<string>;
  options?: Array<{ value: any; label: string }>;
  className?: string;
  value?: any;
  isMulti?: boolean;
  placeholder?: string;
  isSearchable?: boolean;
  name?: string;
  onChange?: (newValue: any, actionMeta: ActionMeta<any>) => void;
  onInputChange?: (inputValue: string) => void;
};

const AddressMultiAutoComplete = ({
  className,
  value,
  // filterKeys = ["city"],
  isMulti,
  placeholder = "",
  onChange,
}: Props) => {
  const dispatch = useAppDispatch()
  const [addressOptions, setAddressOptions] = useState<any[]>([]);

  const onChangeAreaInput = (value: string) => {
    if (value !== "") {
      dispatch(fetchAddressAutocomplete({ address: value })).then((res) => {
        setAddressOptions(res.payload.addresses);
      })
    }
  }

  useEffect(() => {
    dispatch(fetchAddressAutocomplete({ address: "a" })).then((res) => {
      setAddressOptions(res.payload.addresses);
    })
  }, [])

  return (
    <Select
      options={addressOptions.map((area: any) => {
        var areaString = (area.city ?? "") + ", " + (area.state ?? "") + (area.zipcode ? " " + area.zipcode : "")
        return { value: areaString, label: areaString }
      })}
      isMulti={isMulti}
      value={value}
      placeholder={placeholder}
      className={className}
      onInputChange={onChangeAreaInput}
      onChange={onChange}
    />
  );
};

export default AddressMultiAutoComplete;
