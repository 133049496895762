import React, { useEffect } from "react";
import TextField from "@/components/baseComponents/TextField";
import Typography from "@/components/baseComponents/Typography";
import { Button } from "@/components/baseComponents/Button";
import { useAppDispatch, useAppSelector } from "@/redux/hooks";
import { getUser, replaceEmail, replacePassword } from "@/redux/user/userSlice";
import validation from "@/shared/services/validation";
import {
  ChangeEmailDto,
  ChangePasswordDto,
} from "@/shared/interfaces/interfaces";
import { notify } from "@/shared/services/notify";

const Credentials = () => {
  const dispatch = useAppDispatch();
  const user = useAppSelector(getUser);
  const [email, setEmail] = React.useState<string>(user.email);
  const [errorEmail, setErrorEmail] = React.useState<boolean>(false);
  const [currentPassword, setCurrentPassword] = React.useState<string>("");
  const [password, setPassword] = React.useState<string>("");
  const [confirm, setConfirm] = React.useState<string>("");
  const [errorConfirm, setErrorConfirm] = React.useState<boolean>(false);
  const [errorCurrentPassword, setErrorCurrentPassword] =
    React.useState<boolean>(false);
  const [errorMessagePassword, setErrorMessagePassword] =
    React.useState<string>("");

  const [unSavedEmailChange, setUnSavedEmailChange] =
    React.useState<boolean>(false);
  const [unSavedPasswordChange, setUnSavedPasswordChange] =
    React.useState<boolean>(false);

  const changeEmail = (value: string) => {
    setUnSavedEmailChange(true);
    setEmail(value);
    setErrorEmail(validation.IsInvalidEmail(value));
  };
  const updateEmail = () => {
    if (!errorEmail) {
      setUnSavedEmailChange(false);
      let data: ChangeEmailDto = {
        email: user.email,
        updatedEmail: email,
      };
      dispatch(replaceEmail(data)).then((res) => {
        notify(res.payload.success, res.payload.message);
      });
    }
  };
  const changeCurrentPassword = (value: string) => {
    setCurrentPassword(value);
    setErrorCurrentPassword(validation.IsEmptyString(value));
  };
  const changePassword = (value: string) => {
    setPassword(value);
    if (!validation.emptyPassword(value)) {
      setErrorMessagePassword("This field is required");
      return;
    }
    if (!validation.containsNumber(value)) {
      setErrorMessagePassword("Should contain at least a number");
      return;
    }
    if (!validation.containsUppercase(value)) {
      setErrorMessagePassword("Should contain at least a uppercase character");
      return;
    }
    if (!validation.containsSpecial(value)) {
      setErrorMessagePassword("Should contain at least a special character");
      return;
    }
    if (!validation.minLength(value)) {
      setErrorMessagePassword("Should be at least 8 characters");
      return;
    }
    setErrorMessagePassword("");
  };
  const changeConfirm = (value: string) => {
    setUnSavedPasswordChange(true);
    setConfirm(value);
    setErrorConfirm(validation.IsDifferentString(password, value));
  };
  const updatePassword = () => {
    changeCurrentPassword(currentPassword);
    changePassword(password);
    changeConfirm(confirm);
    if (!errorCurrentPassword && !errorConfirm && errorMessagePassword === "") {
      setUnSavedPasswordChange(false);
      let data: ChangePasswordDto = {
        email: email,
        password: currentPassword,
        updatedPassword: password,
      };
      dispatch(replacePassword(data)).then((res) => {
        try {
          notify(res.payload.success, res.payload.message);
          if (res.payload.success) {
            setCurrentPassword("");
            setPassword("");
            setConfirm("");
            setErrorCurrentPassword(false);
            setErrorConfirm(false);
            setErrorMessagePassword("");
          }
        } catch (e) {
          notify(false, "Something went wrong.");
        }
      });
    }
  };

  const showSubmitButton = (show: boolean) => {
    if (
      show &&
      !errorCurrentPassword &&
      !errorConfirm &&
      errorMessagePassword === ""
    ) {
      return true;
    }
    return false;
  };

  // temporary fix on build errors, should be removed
  useEffect(() => {
    if (unSavedPasswordChange === true) {
      showSubmitButton(false);
      updatePassword();
    }
  }, []);

  return (
    <div className="flex flex-col gap-6">
      <div className="flex flex-col 2xl:flex-row items-start justify-between bg-white p-6 2xl:gap-20 xl:gap-8 rounded-lg">
        <div className='flex flex-col xl:min-w-[300px] xl:pb-0 pb-8'>
          <Typography variant="h2" color="primary">Update Log in Email</Typography>
          <Typography variant="input-label" color="primary">
            Email address to log in your account.
          </Typography>
        </div>
        <div className="w-full flex flex-col gap-4">
          <div className="flex w-full">
            <div className="flex w-full flex-col gap-2">
              <Typography variant='input-label' className='text-secondary'>Email</Typography>
              <TextField inputClassName={`${email.length != 0 ? 'bg-[#E5E7EB]' : ''}`} className={`w-full ${(errorEmail) ? 'outline outline-1 rounded outline-[#E01010]' : ''}`} placeholder='' value={email} onChange={(e) => changeEmail(e.target.value)} />
              {(errorEmail) && <Typography variant='caption' className='text-[#E01010] absolute mt-[68px]'>Invalid Email Address</Typography>}
              <div className="flex justify-end w-full">
                <Button className={`text-[#A5B4FC] ${unSavedEmailChange && (!errorEmail) ? 'bg-button-primary' : 'bg-indigo-400'}`} onClick={() => {
                  if (unSavedEmailChange && (!errorEmail)) {
                    updateEmail()
                  }
                }
                }>Update</Button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-col 2xl:flex-row items-start justify-between bg-white p-6 2xl:gap-20 xl:gap-8 rounded-lg">
        <div className='flex flex-col xl:min-w-[300px] xl:pb-0 pb-8'>
          <Typography variant="h2" color="primary">Update Password</Typography>
          <Typography variant="input-label" color="primary">
            Set a password to log in your account.
          </Typography>
        </div>
        <div className="w-full flex flex-col gap-4">
          <div className="flex w-full">
            <div className="flex w-full flex-col gap-2">
              <Typography variant='input-label' className='text-secondary'>Current Password</Typography>
              <TextField type="password" className={`w-full ${errorCurrentPassword ? 'outline outline-1 rounded outline-[#E01010]' : ''}`} placeholder='' value={currentPassword} onChange={(e) => { changeCurrentPassword(e.target.value); setUnSavedPasswordChange(true) }} />
              {errorCurrentPassword && <Typography variant='caption' className='text-[#E01010] absolute mt-[68px]'>This field is required.</Typography>}
            </div>
          </div>
          <div className="flex w-full">
            <div className="flex w-full flex-col gap-2">
              <Typography variant='input-label' className='text-secondary'>New Password</Typography>
              <TextField type="password" className={`w-full ${errorMessagePassword !== '' ? 'outline outline-1 rounded outline-[#E01010]' : ''}`} placeholder='' value={password} onChange={(e) => { changePassword(e.target.value); setUnSavedPasswordChange(true) }} />
              {errorMessagePassword !== '' && <Typography variant='caption' className='text-[#E01010] absolute mt-[68px]'>{errorMessagePassword}</Typography>}
            </div>
          </div>
          <div className="flex w-full">
            <div className="flex w-full flex-col gap-2">
              <Typography variant='input-label' className='text-secondary'>Confirm Password</Typography>
              <TextField type="password" className='w-full' placeholder='' value={confirm} onChange={(e) => { setUnSavedPasswordChange(true); changeConfirm(e.target.value) }} />
              {errorConfirm && <Typography variant='caption' className='text-[#E01010] absolute mt-[68px]'>Passwords must match!</Typography>}
            </div>
          </div>
          <div className="flex w-full">
            <div className="flex w-full flex-col gap-2">
              <div className="flex justify-end w-full items-center">
                {/* <Typography variant='input-label' className='text-secondary'>Forgot Password?</Typography> */}
                <Button className={`text-[#A5B4FC] ${showSubmitButton(unSavedPasswordChange) ? 'bg-button-primary' : 'bg-indigo-400'}`} onClick={() => {
                  updatePassword()
                }}>Update</Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Credentials;
