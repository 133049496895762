type Props = {
    width: number;
    height: number;
    stroke?: string;
    className?: string;
};

const IconLinkExternal = (props: Props) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 15 14" fill="none" {...props}>
            <path
                d="M12.75 5.25001L12.75 1.75001M12.75 1.75001H9.24999M12.75 1.75001L7.5 7M6.33333 1.75H5.05C4.06991 1.75 3.57986 1.75 3.20552 1.94074C2.87623 2.10852 2.60852 2.37623 2.44074 2.70552C2.25 3.07986 2.25 3.56991 2.25 4.55V9.45C2.25 10.4301 2.25 10.9201 2.44074 11.2945C2.60852 11.6238 2.87623 11.8915 3.20552 12.0593C3.57986 12.25 4.06991 12.25 5.05 12.25H9.95C10.9301 12.25 11.4201 12.25 11.7945 12.0593C12.1238 11.8915 12.3915 11.6238 12.5593 11.2945C12.75 10.9201 12.75 10.4301 12.75 9.45V8.16667"
                stroke={props.stroke ?? "#6B7280"}
                strokeWidth="1.2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};

export default IconLinkExternal;
