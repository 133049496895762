// Define the type for the component props
type Props = {
  width: number;
  height: number;
  stroke?: string;
  className?: string;
};

// Define the IconChat component
const IconChat = (props: Props) => {
  // Return the SVG for the icon
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 25 24"
      fill="none"
      {...props}
    >
      <path
        d="M14.04 9C14.04 9.53043 13.8293 10.0391 13.4543 10.4142C13.0792 10.7893 12.5705 11 12.04 11H6.04004L2.04004 15V4C2.04004 2.9 2.94004 2 4.04004 2H12.04C12.5705 2 13.0792 2.21071 13.4543 2.58579C13.8293 2.96086 14.04 3.46957 14.04 4V9Z"
        stroke="#969696"
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.04 9H20.04C20.5705 9 21.0792 9.21071 21.4543 9.58579C21.8293 9.96086 22.04 10.4696 22.04 11V22L18.04 18H12.04C11.5096 18 11.0009 17.7893 10.6258 17.4142C10.2508 17.0391 10.04 16.5304 10.04 16V15"
        stroke="#969696"
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

// Export the IconChat component
export default IconChat;
