import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import type { RootState } from '../store'
import axios from '@/shared/services/axios'
import { IMyInfo, GetAgentsDto } from '@/shared/interfaces/interfaces'

interface AgentState {
  agents: Array<IMyInfo>
}

const initialState: AgentState = {
  agents: [],
};

export const agentSlice = createSlice({
  name: 'agents',
  initialState,
  reducers: {
    setTest: (state, action: PayloadAction<any>) => {
      state.agents = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAgentsByUserId.fulfilled, (state, action) => {
      if (action.payload.success) {
        state.agents = action.payload.agents;
      }
    });
  },
});

export const agentsAction = agentSlice.actions;

export const getAgents = (state: RootState) => state.agent.agents;

export default agentSlice.reducer;

export const getAgentsByUserId = createAsyncThunk(
  'agent/get',
  async (data: GetAgentsDto) => {
    const response = await axios.post('agent/get', data);
    return response.data;
  }
);

export const follow = createAsyncThunk(
  'agent/follow',
  async (payload: { follower: string, following: string }) => {
    const response = await axios.post('agent/follow', payload);
    return response.data;
  }
)

export const unfollow = createAsyncThunk(
  'agent/unfollow',
  async (payload: { follower: string, following: string }) => {
    const response = await axios.post('agent/unfollow', payload);
    return response.data;
  }
)