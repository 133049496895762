// Import necessary modules
import { Outlet } from "react-router-dom";
import Scrollbars from "react-custom-scrollbars";
import Header from "./bars/Header";
import { useState } from "react";
import Sidebar from "./bars/Sidebar";
import { getUser } from "@/redux/user/userSlice";
import { useAppSelector } from "@/redux/hooks";
import { IntercomProvider } from "react-use-intercom";

// Define the PublicListingLayout component
const PublicListingLayout = () => {

    // Define state and ref
    const user = useAppSelector(getUser);
    const [open, setOpen] = useState<boolean>(true);
    const [isAuthModalOpenFromSidebar, setAuthModalOpenFromSidebar] = useState<boolean>(false);

    // Render the component
    return (
        <IntercomProvider
            autoBoot
            appId={import.meta.env.VITE_INTERCOM_APP_ID}
        >
            <Scrollbars autoHide className="min-h-screen">
                <div className="flex h-full relative">
                    <div
                        className={`bg-[#00000040] h-screen w-full top-0 left-0 z-10 md:hidden ${open
                            ? `md:!hidden sm:!hidden max-sm:!hidden`
                            : `md:!fixed sm:!fixed max-sm:!fixed`
                            }`}
                        onClick={() => setOpen(!open)}
                    ></div>
                    <Sidebar
                        scrollbars={scrollbars}
                        open={open}
                        changeOpen={setOpen}
                        isLoggedIn={user ? true : false}
                        setAuthModalOpen={setAuthModalOpenFromSidebar}
                    />
                    <div
                        className={`w-full relative ml-auto max-md:w-screen  transition-all duration-600 min-h-full ${open
                            ? `lg:w-[75vw] lg2:w-[80vw] md:w-[70vw] sm:w-full `
                            : `lg:w-[95vw] lg2:w-[95vw] md:w-[92vw] sm:w-full `
                            } `}
                    >
                        <Header open={open} changeOpen={setOpen} scrollbars={scrollbars} />
                        <div className="pt-[3.75rem] w-full h-full relative">
                            <Outlet context={[isAuthModalOpenFromSidebar, setAuthModalOpenFromSidebar]} />
                        </div>
                    </div>
                </div>
            </Scrollbars>
        </IntercomProvider>
    )
}

// Export the PublicListingLayout component
export default PublicListingLayout;