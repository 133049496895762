type Props = {
  width: number;
  height: number;
  stroke?: string;
  className?: string;
};

const IconDownCircular = (props: Props) => {
  return (
    <svg
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle cx="10" cy="10" r="10" fill="#F0EFFB" />
      <g clip-path="url(#clip0_10694_140676)">
        <path
          d="M6.5 8.25L10 11.75L13.5 8.25"
          stroke="#7065F0"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_10694_140676">
          <rect
            width="12"
            height="12"
            fill="white"
            transform="translate(4 4)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default IconDownCircular;
