// Define the type for the component props
type Props = {
    width: number;
    height: number;
    stroke?: string;
    className?: string;
};

// Define the IconDoorOpen component
const IconDoorOpen = (props: Props) => {
    // Return the SVG for the icon
    return (
        <svg viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <g id="door-open24">
                <path id="Vector" d="M9.16675 2.66406H11.1667C11.5204 2.66406 11.8595 2.80454 12.1096 3.05459C12.3596 3.30464 12.5001 3.64377 12.5001 3.9974V13.3307" stroke="white" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
                <path id="Vector_2" d="M1.83325 13.3359H3.83325" stroke="white" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
                <path id="Vector_3" d="M9.16675 13.3359H15.1667" stroke="white" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
                <path id="Vector_4" d="M7.16675 8V8.006" stroke="white" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
                <path id="Vector_5" d="M9.16659 3.04407V13.8154C9.16656 13.9167 9.14346 14.0166 9.09905 14.1076C9.05464 14.1986 8.99008 14.2783 8.91027 14.3406C8.83046 14.403 8.7375 14.4463 8.63844 14.4673C8.53939 14.4884 8.43684 14.4866 8.33859 14.4621L3.83325 13.3361V3.71074C3.8333 3.41341 3.93272 3.12463 4.11572 2.89029C4.29872 2.65595 4.5548 2.4895 4.84325 2.4174L7.50992 1.75074C7.70642 1.70162 7.91153 1.69791 8.10967 1.73991C8.30782 1.7819 8.49379 1.86848 8.65348 1.99308C8.81317 2.11768 8.94237 2.27702 9.03127 2.45901C9.12018 2.641 9.16645 2.84152 9.16659 3.04407Z" stroke="white" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
            </g>
        </svg>
    );
};

// Export the IconDoorOpen component
export default IconDoorOpen;
