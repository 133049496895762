import React, { Fragment, useEffect } from "react";
import { Dialog, Transition } from "@headlessui/react";
import IconWrapper from "@/components/baseComponents/IconWrapper";
import Typography from "@/components/baseComponents/Typography";
import { Button } from "@/components/baseComponents/Button";
import { CreateShowingDto, IMyListing } from "@/shared/interfaces/interfaces";
import { format } from "date-fns";
import CalendarWeeklyView from "@/components/mainComponents/CalendarWeeklyView";
import TimeSelector from "@/components/mainComponents/TimeSelector";
import { useAppSelector } from "@/redux/hooks";
import { getUser } from "@/redux/user/userSlice";
import validation from "@/shared/services/validation";
import TextField from "@/components/baseComponents/TextField";
import AddProfile from '@/assets/images/add_profile.svg'
import AddCompany from '@/assets/images/add_company.svg'
import { truncateText } from "@/shared/config/constants";

type IProps = {
    open: boolean;
    listing: IMyListing;
    closeDrawer: Function;
    date: { date: Date; setDate: React.Dispatch<React.SetStateAction<Date>> };
};


export const ShowingDrawer = (props: IProps) => {
    // const [isLoading, setIsLoading] = React.useState(false);
    const user = useAppSelector(getUser);
    const [open, setOpen] = React.useState<boolean>(props.open)

    const [successNotification, setSuccessNotification] = React.useState<boolean>(false)

    const [availabilityTime, setAvailabilityTime] = React.useState<any>([]);
    const [selectedTime, setSelectedTime] = React.useState("");
    const [errorClientFirstName, setErrorClientFirstName] =
        React.useState<boolean>(false);
    const [errorClientLastName, setErrorClientLastName] =
        React.useState<boolean>(false);
    const [errorClientEmailAddress, setErrorClientEmailAddress] =
        React.useState<boolean>(false);
    const [errorClientPhoneNumber, setErrorClientPhoneNumber] =
        React.useState<boolean>(false);
    const [values, setValues] = React.useState<CreateShowingDto>({
        // temporary to fix build error
        email: user?.email,
        data: {
            _id: "",
            client: "",
            clientFirstName: "",
            clientLastName: "",
            clientCompanyName: "",
            clientPhoneNumber: "",
            clientEmailAddress: "",
            isClient: false,
            status: "",
            listing: props.listing?._id as string,
            dateTime: new Date(),
        },
        userId: user?._id,
    });
    const [addNew, setAddNew] = React.useState<boolean>(true)


    useEffect(() => {
        setOpen(props.open)
    }, [props.open, open])

    const onChangeDate = (date: any) => {
        props.date.setDate(date);
    };

    const handleInputChange = (e: any) => {
        const { name, value } = e.target;
        let tempValue = value;
        const formattedPhoneNumber = validation.phoneNumberAutoFormat(value);

        setValues({
            ...values,
            data: {
                ...values.data,
                [name]: tempValue,
            },
        });

        if (
            name === "clientPhoneNumber" &&
            formattedPhoneNumber.length > 0 &&
            formattedPhoneNumber.length < 12
        )
            setErrorClientPhoneNumber(true);
        else setErrorClientPhoneNumber(false);

        switch (name) {
            case "clientFirstName":
                setErrorClientFirstName(validation.IsEmptyString(value));
                break;
            case "clientLastName":
                setErrorClientLastName(validation.IsEmptyString(value));
                break;
            case "clientEmailAddress":
                setErrorClientEmailAddress(
                    validation.IsInvalidEmail(value) && value.length > 0
                );
                break;
        }
    };

    const isFormValid = () => {
        setErrorClientFirstName(validation.IsEmptyString(values.data.clientFirstName));
        setErrorClientLastName(validation.IsEmptyString(values.data.clientFirstName));
        const value = values.data.clientEmailAddress;
        setErrorClientEmailAddress(
            validation.IsInvalidEmail(value) && value.length > 0
        );
        const formattedPhoneNumber = validation.phoneNumberAutoFormat(values.data.clientPhoneNumber);
        if (
            formattedPhoneNumber.length > 0 &&
            formattedPhoneNumber.length < 12
        )
            setErrorClientPhoneNumber(true);
        else setErrorClientPhoneNumber(false);

        return !errorClientFirstName && !errorClientLastName;
    }

    const handleRequestShowing = () => {
        if (isFormValid()) {
            setSuccessNotification(true)
            setAddNew(false)
        }
    }

    return (
        <Transition.Root show={props.open} as={Fragment}>
            <Dialog
                as="div"
                className="relative z-30 "
                onClose={() => {
                    props.closeDrawer();
                }
                }
            >
                <div className="fixed inset-0 overflow-hidden"
                    style={{ backgroundColor: 'rgba(0,0,0,0.2)' }}
                >
                    <div className="absolute inset-0 overflow-hidden">
                        <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full md:pl-10 pl-0 sm:pl-16">
                            <Transition.Child
                                as={Fragment}
                                enter="transform transition ease-in-out duration-500 sm:duration-300"
                                enterFrom="translate-x-full"
                                enterTo="translate-x-0"
                                leave="transform transition ease-in-out duration-500 sm:duration-300"
                                leaveFrom="translate-x-0"
                                leaveTo="translate-x-full"
                            >
                                <Dialog.Panel className="pointer-events-auto h-screen w-full max-sm:w-[414px] md:w-[600px] overflow-y-auto hide-scrollbar ">
                                    <div className="flex bg-white w-full h-screen flex-col gap-3 lg:px-8 lg:py-6 px-4 py-4 overflow-y-auto hide-scrollbar shadow">
                                        <div className="flex w-full justify-between">
                                            <div className="flex flex-col">
                                                <Typography
                                                    className="whitespace-normal"
                                                    variant="h1"
                                                    color="primary"
                                                >
                                                    {props.listing?.listingAddress.streetLine}
                                                </Typography>
                                                <Typography variant="body" color="primary">
                                                    {props.listing?.listingAddress.city},{" "}
                                                    {props.listing?.listingAddress.state}{" "}
                                                    {props.listing?.listingAddress.zipcode}
                                                </Typography>
                                            </div>
                                            <div className="flex gap-3 items-start">
                                                <div onClick={() => (props.closeDrawer())}>
                                                    <IconWrapper className='w-6 h-6' name="close" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="flex w-full">
                                            {/* <Button variant="text" className='bg-[#DCFCE7] rounded-[6px] px-2 py-1 text-[#16A34A]'>Active</Button> */}
                                        </div>
                                        <div className="flex flex-col gap-4">
                                            <Typography
                                                variant="body"
                                                color="primary"
                                                className="w-full whitespace-nowrap pr-5 pt-2 font-bold"
                                            >
                                                {format(
                                                    props.date.date,
                                                    "EEEE, MMMM d, yyyy"
                                                )}{" "}
                                                at: {format(new Date(), "p")}
                                            </Typography>
                                            <div className="border shadow rounded-lg p-2 py-4 md:p-3">
                                                <CalendarWeeklyView
                                                    onSelectDate={onChangeDate}
                                                />
                                            </div>
                                        </div>
                                        <div>
                                            <TimeSelector
                                                data={props.listing}
                                                date={props.date.date}
                                                availability={availabilityTime}
                                                setAvalablity={setAvailabilityTime}
                                                onSelectTime={setSelectedTime}
                                            />
                                        </div>
                                        {
                                            addNew && (
                                                <div className="flex flex-col w-full">
                                                    <div className='flex flex-col md:flex-row gap-6 mt-5 py-2'>
                                                        <div className='flex flex-col w-full md:w-1/2 gap-2'>
                                                            <Typography variant='caption' className='text-secondary'>First Name *</Typography>
                                                            <TextField value={values.data.clientFirstName} className={`w-full ${errorClientFirstName ? 'outline outline-1 rounded outline-[#E01010]' : ''}`} name="clientFirstName" onChange={(e) => handleInputChange(e)} />
                                                            <div className="relative">
                                                                {errorClientFirstName && <Typography variant='caption' className='text-[#E01010] absolute -mt-[9px]'>This field is required</Typography>}
                                                            </div>
                                                        </div>
                                                        <div className='flex flex-col w-full md:w-1/2 gap-2'>
                                                            <Typography variant='caption' className='text-secondary'>Last Name *</Typography>
                                                            <TextField value={values.data.clientLastName} inputClassName={`${values.data.clientLastName.length != 0 ? 'bg-[#E5E7EB]' : ''}`} className={`w-full ${errorClientLastName ? 'outline outline-1 rounded outline-[#E01010]' : ''}`} name="clientLastName" onChange={(e) => handleInputChange(e)} />
                                                            <div className="relative">
                                                                {errorClientLastName && <Typography variant='caption' className='text-[#E01010] absolute -mt-[9px]'>This field is required</Typography>}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='flex flex-col md:flex-row gap-6 py-2'>
                                                        <div className='flex flex-col w-full md:w-1/2 gap-2'>
                                                            <Typography variant='caption' className='text-secondary'>Phone Number</Typography>
                                                            <TextField value={values.data.clientPhoneNumber} inputClassName={`${values.data.clientPhoneNumber.length != 0 ? 'bg-[#E5E7EB]' : ''}`} className={`w-full ${errorClientPhoneNumber ? 'outline outline-1 rounded outline-[#E01010]' : ''}`} name="clientPhoneNumber" onChange={(e) => handleInputChange(e)} />
                                                            <div className="relative">
                                                                {errorClientPhoneNumber && <Typography variant='caption' className='text-[#E01010] absolute -mt-[9px]'> Please enter a valid phone number</Typography>}
                                                            </div>
                                                        </div>
                                                        <div className='flex flex-col w-full md:w-1/2 gap-2'>
                                                            <Typography variant='caption' className='text-secondary'>Email Address</Typography>
                                                            <TextField value={values.data.clientEmailAddress} inputClassName={`${values.data.clientEmailAddress.length != 0 ? 'bg-[#E5E7EB]' : ''}`} className={`w-full ${errorClientEmailAddress ? 'outline outline-1 rounded outline-[#E01010]' : ''}`} name="clientEmailAddress" onChange={(e) => handleInputChange(e)} />
                                                            <div className="relative">
                                                                {errorClientEmailAddress && <Typography variant='caption' className='text-[#E01010] absolute -mt-[9px]'>Invalid Email Address</Typography>}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='flex flex-col md:flex-row gap-6'>
                                                        <div className='flex flex-col w-full gap-2'>
                                                            <Typography variant='caption' className='text-secondary'>Company Name</Typography>
                                                            <TextField value={values.data.clientCompanyName} inputClassName={`${values.data.clientEmailAddress.length != 0 ? 'bg-[#E5E7EB]' : ''}`} className={`w-full`} name="clientCompanyName" onChange={(e) => handleInputChange(e)} />
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        }
                                        {/* showing detail one */}
                                        <div className="!mt-[48px] p-3 bg-gray-50 space-y-3 rounded-[5px]">
                                            <div className="flex items-center lg:gap-12 gap-0">
                                                <Typography
                                                    variant="body"
                                                    color="secondary"
                                                    className="min-w-[145px]"
                                                >
                                                    Showing Date
                                                </Typography>
                                                <Typography
                                                    variant="body"
                                                    color="primary"
                                                    className="text-center whitespace-nowrap font-bold"
                                                >
                                                    {format(props.date.date, "EEEE, MMMM d, yyyy")}
                                                </Typography>
                                            </div>
                                            <div className="flex items-center lg:gap-12 gap-0">
                                                <Typography
                                                    variant="body"
                                                    color="secondary"
                                                    className="min-w-[145px]"
                                                >
                                                    Showing Time
                                                </Typography>
                                                {selectedTime ? (
                                                    <span className=" text-indigo-500 text-[14px]">
                                                        {selectedTime}
                                                    </span>
                                                ) : (
                                                    <span className=" text-indigo-500 text-[14px]">
                                                        Select Time
                                                    </span>
                                                )}
                                            </div>
                                            <div className="flex items-start lg:gap-12 gap-0">
                                                <Typography
                                                    variant="body"
                                                    color="secondary"
                                                    className="min-w-[145px]"
                                                >
                                                    Client
                                                </Typography>
                                                {
                                                    addNew && (
                                                        <div>
                                                            <Typography variant="body" onClick={() => { }} className="cursor-pointer text-[14px] text-gray-500">
                                                                Add New or Search Existing Client
                                                            </Typography>
                                                        </div>
                                                    )
                                                }
                                                {
                                                    !addNew && (
                                                        <div className="bg-white border border-[#E5E7EB] p-2 rounded-8 flex w-full justify-between items-start">
                                                            <div>
                                                                <div className="flex items-center gap-1">
                                                                    <Typography
                                                                        variant="body"
                                                                        color="primary"
                                                                        className="!font-bold"
                                                                    >
                                                                        {values.data.clientFirstName +
                                                                            " " +
                                                                            values.data.clientLastName}
                                                                    </Typography>
                                                                </div>
                                                                <Typography variant="body" color="primary">
                                                                    {values.data.clientPhoneNumber}
                                                                </Typography>
                                                                <Typography variant="body" color="primary">
                                                                    {values.data.clientEmailAddress}
                                                                </Typography>
                                                            </div>
                                                        </div>)
                                                }

                                            </div>
                                        </div>
                                        <div className=" text-justify">
                                            <div className="py-3">
                                                <Typography variant="h4" color="primary">
                                                    Showings Details
                                                </Typography>
                                            </div>
                                            <div className="p-3 max-sm:!pt-[10px] bg-gray-50 space-y-[20px] rounded-lg">
                                                <div className="flex flex-col space-y-[20px]">
                                                    <div>
                                                        <Typography
                                                            variant="caption"
                                                            color="secondary"
                                                        >
                                                            Showing Instructions
                                                        </Typography>
                                                        <Typography variant="body" color="primary">
                                                            {props.listing?.showingInstuction || "None"}
                                                        </Typography>
                                                    </div>
                                                    <div className="flex flex-col">
                                                        <Typography
                                                            variant="caption"
                                                            color="secondary"
                                                        >
                                                            Showing Remarks
                                                        </Typography>
                                                        <Typography variant="body" color="primary">
                                                            {props.listing?.showingRemarks || "None"}
                                                        </Typography>
                                                    </div>
                                                </div>

                                                <div className="grid grid-cols-2 gap-5">
                                                    <div className="flex flex-col gap-1 py-[10px] ">
                                                        <Typography
                                                            variant="caption"
                                                            color="secondary"
                                                        >
                                                            Lockbox or Keypad
                                                        </Typography>
                                                        <Typography variant="body" color="primary">
                                                            {props.listing?.showingLockboxOrKeypad ||
                                                                "None"}
                                                        </Typography>
                                                    </div>
                                                    <div className="flex flex-col gap-1 py-[10px] ">
                                                        <Typography
                                                            variant="caption"
                                                            color="secondary"
                                                        >
                                                            Access Code
                                                        </Typography>
                                                        <Typography variant="body" color="primary">
                                                            {props.listing?.showingAccessCode || "None"}
                                                        </Typography>
                                                    </div>
                                                    <div className="flex flex-col gap-1 py-[10px] ">
                                                        <Typography
                                                            variant="caption"
                                                            color="secondary"
                                                        >
                                                            Occupancy Status
                                                        </Typography>
                                                        <Typography variant="body" color="primary">
                                                            {props.listing?.listingOccupancyStatus ||
                                                                "None"}
                                                        </Typography>
                                                    </div>
                                                    <div className="flex flex-col gap-1 py-[10px] ">
                                                        <Typography
                                                            variant="caption"
                                                            color="secondary"
                                                        >
                                                            Require Agency Disclosure
                                                        </Typography>
                                                        <Typography variant="body" color="primary">
                                                            {props.listing
                                                                ?.showingRequireAgencyDisclosure ||
                                                                "None"}
                                                        </Typography>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="flex w-full pb-3">
                                            <Button variant="contained" onClick={() => handleRequestShowing()} className="w-full">Request Showing</Button>
                                        </div>
                                        {
                                            successNotification && (
                                                <div className="flex w-full bg-[#F0FDF4] py-2 px-3 rounded-lg">
                                                    <div className="flex flex-col">
                                                        <div className=" text-[14px]">
                                                            You’re request to show
                                                            <span className="font-bold"> 901 Stillwater Dr Miami Beach, FL 33141 </span> at <span className="font-bold"> 12:30 PM </span> on <span className="font-bold"> Friday February 23rd </span> has been received by <span className="font-bold">Philip Lilavois.</span>
                                                        </div>
                                                        <div className="flex mt-4">
                                                            You will be notified via email and your RealtyView dashboard when a response is entered. Please allow some time for a response but always follow up if necessary.
                                                        </div>
                                                    </div>
                                                    <div className="flex gap-2 items-start p-2" >
                                                        <div onClick={() => (setSuccessNotification(!successNotification))}>
                                                            <IconWrapper className='w-6 h-6 cursor-pointer' name="close" />
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        }
                                        <div className="flex w-full py-5">
                                            <div className="flex w-full h-0.5 bg-gray-100">

                                            </div>
                                        </div>
                                        <div className="flex flex-col w-full py-5 gap-4">
                                            <div className="flex sm:flex-row flex-col w-full justify-between px-3 py-4 gap-2 bg-[#F0F9FF] rounded-[5px] border border-1 border-[#E5E7EB]">
                                                <div className="flex w-full gap-2 items-center">
                                                    <div className="flex">
                                                        <img
                                                            className="w-9 h-9 object-cover rounded aspect-square"
                                                            src={props.listing?.owner?.agent?.company?.businessLogo || AddCompany}
                                                        />
                                                    </div>
                                                    <div className="flex flex-col">
                                                        <Typography variant="h4" color="primary" className="truncate">{truncateText(props.listing?.owner?.agent?.company?.businessName || "Company")}</Typography>
                                                        <Typography variant="medium-text" color="secondary" className="flex items-center gap-2">{props.listing?.owner?.agent?.company?.businessAddress1 || "Address"} </Typography>
                                                    </div>
                                                </div>
                                                <div className="flex flex-col w-full">
                                                    <div className="flex items-center gap-2">
                                                        <IconWrapper name="telephone" width={13} />
                                                        <Typography variant="medium-text" color="secondary" className="flex items-center gap-2">{props.listing?.owner?.agent?.company?.principalBrokerPhone || "None"} </Typography>
                                                    </div>
                                                    <div className="flex items-center gap-2">
                                                        <IconWrapper name="envelope" width={13} />
                                                        <Typography variant="medium-text" color="secondary" className="flex items-center gap-2 truncate ">{props.listing?.owner?.agent?.contactEmail || "None"}</Typography>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="flex sm:flex-row flex-col w-full justify-between px-3 py-4 gap-2 bg-[#EEF2FF] rounded-[5px] border border-1 border-[#E5E7EB]">
                                                <div className="flex w-full gap-2 items-center">
                                                    <div className="flex">
                                                        <img
                                                            className="w-9 h-9 object-cover rounded aspect-square"
                                                            src={props.listing?.owner?.agent?.avatarURL || AddProfile}
                                                        />
                                                    </div>
                                                    <div className="flex flex-col">
                                                        <Typography variant="h4" color="primary" className='truncate'>{truncateText((props.listing?.owner?.agent?.firstName || "Agent") + " " + (props.listing?.owner?.agent?.lastName || ""), 30)}</Typography>
                                                        <Typography variant="medium-text" color="secondary" className="flex items-center gap-2">{props.listing?.owner?.agent?.licenseType || "Address"} </Typography>
                                                    </div>
                                                </div>
                                                <div className="flex flex-col w-full">
                                                    <div className="flex items-center gap-2">
                                                        <IconWrapper name="telephone" width={13} />
                                                        <Typography variant="medium-text" color="secondary" className="flex items-center gap-2 truncate">{props.listing?.owner?.agent?.mobileNumber || "None"}</Typography>
                                                    </div>
                                                    <div className="flex items-center gap-2">
                                                        <IconWrapper name="envelope" width={13} />
                                                        <Typography variant="medium-text" color="secondary" className="flex items-center gap-2 truncate ">{props.listing?.owner?.agent?.contactEmail || "None"}</Typography>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {/* <div className="flex w-full pb-3">
                                            <Button variant="contained" onClick={() => setSuccessNotification(true)} className="w-full">Request Showing</Button>
                                        </div> */}
                                        {/* <div className="flex w-full justify-center text-center">
                                            <Typography variant="medium-text" className="text-sm font-arial text-[#9CA3AF]">Source's Property ID: A11470191 ©2023 Miami Association of REALTORS®. All rights reserved.</Typography>
                                        </div> */}
                                    </div>
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    )
}