// React imports
import React, { useEffect, useState } from "react";

// Third-party library imports
import { format } from "date-fns";
import { NumericFormat } from "react-number-format";

// Redux-related imports
import { useAppDispatch, useAppSelector } from "@/redux/hooks";
import { getUser } from "@/redux/user/userSlice";
import { getListingById } from "@/redux/myListings/myListingSlice";
import {
  createMessage,
  fetchMessages,
  getMessages,
  updateReadStatus,
} from "@/redux/chat/chatSlice";

// Interfaces
import {
  CreateMessageDto,
  GetMessagesDto,
  IMessageDto,
  IMyListing,
  IParticipantDto,
  UpdateReadStatus,
} from "@/shared/interfaces/interfaces";

// Services
import { formatAddress } from "@/shared/services/utils";

// Base components
import Typography from "@/components/baseComponents/Typography";
import { Button } from "@/components/baseComponents/Button";
import TextField from "@/components/baseComponents/TextField";
import IconWrapper from "@/components/baseComponents/IconWrapper";
import ScheduleDrawer from "@/components/baseComponents/ScheduleDrawer";
import { MyListingSimpleStatus } from "@/components/baseComponents/Menu";
import Label from "@/components/baseComponents/Label";

// Main components
import PropertyCarousel from "@/components/mainComponents/PropertyCarousel";
import Tooltip from "@/components/mainComponents/ToolTip";

// Other components
import SubmitOffer from "@/pages/offer/SubmitOffer";
import ListingDrawerFooter from "./ListingDrawerFooter";
import ChatDrawer from "@/components/layouts/bars/Components/ChatDrawer";

type IProps = {
  // Data
  data?: IMyListing;

  // Flags
  isPublished: boolean;

  // Functions
  onSetCurrentStep?: Function;

  // Miscellaneous
  scrollbars?: any;

  // State setters
  showShedule?: React.Dispatch<React.SetStateAction<boolean>>;
  closeAll?: React.Dispatch<React.SetStateAction<boolean>>;
};

const Preview = (props: IProps) => {
  // Redux hooks
  const dispatch = useAppDispatch();
  const user = useAppSelector(getUser);
  const [listings, setListings] = useState<IMyListing[]>([]);
  const listing: any = listings ? listings[0] : {};
  const messages = useAppSelector(getMessages);

  // Props
  const owner = (props.data as any)?.owner;
  const isSelf = owner ? owner._id === user._id : false;

  // State for UI controls
  const [openSubmitOfferDrawer, setOpenSubmitOfferDrawer] =
    useState<boolean>(false);
  const [isPublicListingUrlOpen, setIsPublicListingUrlOpen] =
    useState<boolean>(true);
  const [isPublicListingUrlCopied, setIsPublicListinngUrlCopied] =
    useState<boolean>(false);
  const [openShecdule, setOpenShecdule] = useState<boolean>(false);
  const [showMore, setShowMore] = useState(false);

  // State for form data
  const [date, setDate] = useState<Date>(new Date());
  const [slidesToShow, setSlidesToShow] = useState<number>(1.5);
  const [openProfileChat, setOpenProfileChat] = useState<boolean>(false);
  const [chatParticipant, setChatParticipant] = useState<IParticipantDto>(
    {} as IParticipantDto
  );
  const [viewMessages, setViewMessages] = useState<IMessageDto[]>([]);
  const [content, setContent] = useState<string>("");

  // Constants
  const baseUrl = window.location.protocol + "//" + window.location.host;
  const publicListingUrl = `${baseUrl ?? "https://www.realtyview.app"
    }/listings/${props.data?._id}`;

  const onCopyPublicListingUrlToClipboard = () => {
    navigator.clipboard.writeText(publicListingUrl);
    setIsPublicListinngUrlCopied(true);
  };

  useEffect(() => {
    dispatch(getListingById({ id: props.data?._id ?? "" })).then((data) => {
      setListings(data.payload.data);
    });
  }, [dispatch]);

  const openScheduler = () => {
    setOpenShecdule(true);
  };

  // Function to set class name based on the status
  const getStatusColor = (status: string | undefined) => {
    return status === "Active"
      ? "bg-[#DCFCE7] !text-[#16A34A]"
      : status === "Pending"
        ? "bg-orange-100 text-orange-500"
        : status === "Closed"
          ? "bg-gray-100 shadow-sm"
          : "bg-gray-50 text-[#9CA3AF] shadow-sm";
  };

  useEffect(() => {
    if (props.data?.propertyPhotos && props.data?.propertyPhotos.length == 1) {
      setSlidesToShow(1);
    }
  }, []);

  const fetchRecentMessages = () => {
    if (user && user?._id && user?._id !== "" && listing?.owner?._id !== "") {
      const data: GetMessagesDto = {
        userId: user?._id,
        participantId: listing?.owner?._id,
      };
      dispatch(fetchMessages(data));
    }
  };

  const handleFocusMainArea = () => {
    if (messages.length > 0) {
      const unreadMessages = messages.filter(
        (message) =>
          message.sender === listing?.owner?.agent?._id && !message.isRead
      );

      if (unreadMessages.length > 0) {
        const ids: string[] = unreadMessages
          .map((message) => message?._id)
          .filter((id) => id !== undefined)
          .map((id) => id as string);

        var data: UpdateReadStatus = {
          ids: ids,
          userId: user?._id,
        };
        dispatch(updateReadStatus(data));
      }
    }
  };

  const sendChatMessage = (content: string) => {
    if (listing?.owner?._id && listing?.owner?._id !== "") {
      const messageData: IMessageDto = {
        sender: user?._id,
        receiver: listing?.owner?._id,
        content: content,
      };

      const createData: CreateMessageDto = {
        userId: user?._id,
        data: messageData,
      };

      dispatch(createMessage(createData)).then(() => {
        fetchRecentMessages();
      });
    }
  };

  useEffect(() => {
    setChatParticipant({
      _id: listing?.owner?._id,
      name:
        listing?.owner?.agent?.firstName +
        " " +
        listing?.owner?.agent?.lastName,
      firstName: listing?.owner?.agent?.firstName ?? "",
      avatarURL: listing?.owner?.agent?.avatarURL ?? "",
      unreadCount: listing?.owner?.unreadCount ?? 0,
      latestMessage: listing?.owner?.latestMessage?.content ?? "",
      latestTime: listing?.owner?.latestMessage?.createdAt ?? "",
      phoneNumber: listing?.owner?.agent?.mobileNumber ?? "",
      email: listing?.owner?.agent?.contactEmail ?? "",
      licenseType: listing?.owner?.agent?.licenseType ?? "",
      isRead: listing?.owner?.latestMessage?.isRead ?? "",
      companyName: listing?.owner?.agent?.company?.businessName ?? "",
      companyLogo: listing?.owner?.agent?.company?.businessLogo ?? "",
      isOnline: listing?.owner?.isOnline,
    });

    fetchRecentMessages();
  }, [listing]);

  useEffect(() => {
    if (messages.length > 0) {
      const viewMessages = messages.filter((message: IMessageDto) => {
        return (
          (message.sender === user?._id &&
            message.receiver === listing?.owner?._id) ||
          (message.receiver === user?._id &&
            message.sender === listing?.owner?._id)
        );
      });

      setViewMessages(viewMessages);
    }
  }, [messages]);

  return (
    <>
      {props.isPublished && (
        <>
          <ScheduleDrawer
            data={props.data}
            open={openShecdule}
            closeAll={props.closeAll}
            changeState={setOpenShecdule}
            date={{ date, setDate }}
            footer={
              <div className="py-5">
                <ListingDrawerFooter
                  data={props.data?._id ? owner?.agent : user.agent}
                  isSelf={isSelf}
                  ownerId={owner?._id}
                />
              </div>
            }
          />
        </>
      )}

      {user && (
        <>
          <ChatDrawer
            listing={props.data}
            content={content}
            open={openProfileChat}
            selectedParticipant={chatParticipant}
            viewMessages={viewMessages}
            setContent={setContent}
            setOpen={setOpenProfileChat}
            handleFocusMainArea={handleFocusMainArea}
            sendChatMessage={sendChatMessage}
          />
        </>
      )}

      <div className="space-y-[20px]">
        {/* Offer Drawer */}
        <SubmitOffer
          open={openSubmitOfferDrawer}
          changeState={setOpenSubmitOfferDrawer}
          search_list_flag={false}
          data={{ listing: props.data }}
        />
        <div className="flex md:hidden w-full justify-between">
          <MyListingSimpleStatus
            title={props.data?.status ? props.data?.status : ""}
          />
          <div
            className="flex items-center flex-wrap gap-1 ml-auto cursor-pointer"
            onClick={() => setIsPublicListingUrlOpen(!isPublicListingUrlOpen)}
          >
            <IconWrapper name="share" width={16} />
            <Typography
              variant="body"
              color="indigo"
              className="whitespace-nowrap"
            >
              Share
            </Typography>
          </div>
        </div>
        {isPublicListingUrlOpen && (
          <div className="mt-16 md:hidden">
            <div className="flex flex-col gap-3">
              <div className="relative flex flex-col justify-center gap-1">
                <Typography variant="caption" color="secondary">
                  {user._id !== props.data?.owner?._id
                    ? `This is the public listing link for ${formatAddress(
                      props.data?.listingAddress
                    )}`
                    : `Copy and paste this link into your MLS showing instructions and share with your colleagues.`}
                </Typography>
                <div className="flex">
                  <TextField
                    readOnly
                    name="publicListingLink"
                    value={`${publicListingUrl.substring(
                      0,
                      publicListingUrl.length - 4
                    )}...`}
                  />
                  <Button
                    variant="text"
                    onClick={() => onCopyPublicListingUrlToClipboard()}
                  >
                    <Typography variant="body" color="primary">
                      Copy
                    </Typography>
                  </Button>
                </div>
              </div>
              {isPublicListingUrlCopied && (
                <Typography variant="caption" color="secondary">
                  Your showing link has been copied. Share to your MLS listing,
                  social media and your network.
                </Typography>
              )}
            </div>
          </div>
        )}
        <div>
          <PropertyCarousel
            slidesToShow={slidesToShow}
            isCenter={true}
            data={props.data?.propertyPhotos}
          />
        </div>
        {props.isPublished && (
          <div className="flex flex-wrap gap-[16px] mt-[25px] hidden md:block md:flex">
            <div className="flex gap-2 items-center">
              <Button
                color="primary"
                onClick={() => openScheduler()}
                className="max-sm:w-full "
              >
                <Typography variant="button1">
                  Schedule a Showing
                </Typography>
              </Button>
              <Tooltip text="Coming Soon">
                <Button
                  disabled={true}
                  onClick={() => setOpenSubmitOfferDrawer(true)}
                >
                  <Typography variant="button1" className="whitespace-nowrap">
                    Submit an Offer
                  </Typography>
                </Button>
              </Tooltip>
            </div>
            <div
              className="flex items-center flex-wrap gap-1 ml-auto cursor-pointer"
              onClick={() => setIsPublicListingUrlOpen(!isPublicListingUrlOpen)}
            >
              <IconWrapper name="share" width={16} />
              <Typography
                variant="body"
                color="indigo"
                className="whitespace-nowrap max-sm:hidden"
              >
                Share
              </Typography>
            </div>
          </div>
        )}
        {isPublicListingUrlOpen && (
          <div className="mt-16 md:block hidden">
            <div className="flex flex-col gap-3">
              <div className="relative flex flex-col justify-center gap-1">
                <Typography variant="caption" color="secondary">
                  {user._id !== props.data?.owner?._id
                    ? `This is the public listing link for ${formatAddress(
                      props.data?.listingAddress
                    )}`
                    : `Copy and paste this link into your MLS showing instructions and share with your colleagues.`}
                </Typography>
                <div className="flex">
                  <TextField
                    readOnly
                    name="publicListingLink"
                    value={`${publicListingUrl.substring(
                      0,
                      publicListingUrl.length - 4
                    )}...`}
                  />
                  <Button
                    variant="text"
                    onClick={() => onCopyPublicListingUrlToClipboard()}
                  >
                    <Typography variant="body" color="primary">
                      Copy
                    </Typography>
                  </Button>
                </div>
              </div>
              {isPublicListingUrlCopied && (
                <Typography variant="caption" color="secondary">
                  Your showing link has been copied. Share to your MLS listing,
                  social media and your network.
                </Typography>
              )}
            </div>
          </div>
        )}
        <div className="flex flex-col justify-center gap-5">
          <Button
            variant="text"
            className="md:hidden disabled:bg-[#6366F1] h-10 disabled:opacity-80 bg-button-primary hover:bg-[#6366F1] border-[#6366F1] hover:border-[#818CF8] text-white"
            onClick={() => openScheduler()}
          >
            <div className="flex items-center w-full justify-center gap-3">
              {/* <IconWrapper name="door-open" /> */}
              <Typography
                variant="button1"
                className="flex justify-center whitespace-nowrap"
              >
                Schedule a Showing
              </Typography>
            </div>
          </Button>
          <Button
            variant="text"
            className="md:hidden h-10 disabled:opacity-80 whitespace-nowrap justify-start border-[#22C55E] hover:bg-button-success-hover text-white hover:text-white border-[1px]"
            onClick={() => {
              // user ? setOpenSubmitDrawer(true) : onOpenAuthModal('Submit an Offer')
            }}
            disabled={true}
          >
            <Tooltip className="relative w-full" text="Coming Soon">
              <div className="flex items-center w-full justify-center gap-3">
                {/* <IconWrapper name="submit-offer" /> */}
                <Typography
                  variant="button1"
                  className="flex justify-center whitespace-nowrap"
                >
                  Submit an Offer
                </Typography>
              </div>
            </Tooltip>
          </Button>
          <Button
            variant="text"
            className="bg-[#EEF2FF] border-[#C7D2FE] hover:bg-[#E0E7FF] hover:border-[#4C42D7] disabled:border-[#D1D5DB] disabled:opacity-80 flex"
            onClick={() => {
              if (user) {
                setOpenProfileChat(true);
              }
            }}
          >
            <div className="flex items-center justify-center w-full gap-3">
              <IconWrapper name="chat-blue" />
              <Typography
                variant="button1"
                className="flex justify-center whitespace-nowrap text-[#4C42D7]"
                onClick={() => {
                  if (user) {
                    setOpenProfileChat(true);
                  }
                }}
              >
                Message{" "}
                {(props.data?.owner?.agent?.firstName || "Agent") +
                  " " +
                  (props.data?.owner?.agent?.lastName || "")}
              </Typography>
            </div>
          </Button>
        </div>
        <div className=" space-y-[20px] w-full">
          {/* listing details */}
          <div className=" border rounded-[8px] divide-y">
            <div className=" p-3">
              <Typography variant="h3">Listing Details</Typography>
            </div>
            <div className=" p-3 grid grid-cols-3 gap-5 w-full">
              <div>
                <Typography variant="caption" color="secondary">
                  List Price
                </Typography>
                <Typography variant="body" color="primary">
                  <NumericFormat
                    displayType="text"
                    thousandSeparator=","
                    prefix="$"
                    value={props.data?.listingListPrice}
                  />
                </Typography>
              </div>
              <div>
                <Typography variant="caption" color="secondary">
                  Annual Taxes
                </Typography>
                <Typography variant="body" color="primary">
                  <NumericFormat
                    displayType="text"
                    thousandSeparator=","
                    prefix="$"
                    value={props.data?.listingAnnualTaxes}
                  />
                </Typography>
              </div>
              <div>
                <Typography variant="caption" color="secondary">
                  HOA Expenses
                </Typography>
                <Typography variant="body" color="primary">
                  <NumericFormat
                    displayType="text"
                    thousandSeparator=","
                    prefix="$"
                    value={props.data?.listingHoaExpenses}
                  />
                </Typography>
              </div>
              <div>
                <Typography variant="caption" color="secondary">
                  Other Monthly Expenses
                </Typography>
                <Typography variant="body" color="primary">
                  <NumericFormat
                    displayType="text"
                    thousandSeparator=","
                    prefix="$"
                    value={props.data?.listingOtherMonthlyExpenses}
                  />
                </Typography>
              </div>
              <div>
                <Typography variant="caption" color="secondary">
                  Occupancy Status
                </Typography>
                <Typography variant="body" color="primary">
                  {props.data?.listingOccupancyStatus || "None"}
                </Typography>
              </div>
              <div>
                <Typography variant="caption" color="secondary">
                  Has Lis Pendens
                </Typography>
                <Typography variant="body" color="primary">
                  {props.data?.listingLisPendens || "No"}
                </Typography>
              </div>
              <div>
                <Typography variant="caption" color="secondary">
                  Listing Type
                </Typography>
                <Typography variant="body" color="primary">
                  {props.data?.listingType || "None"}
                </Typography>
              </div>
              <div>
                <Typography variant="caption" color="secondary">
                  Sale Type
                </Typography>
                <Typography variant="body" color="primary">
                  {props.data?.listingSaleType || "None"}
                </Typography>
              </div>
              <div>
                <Typography variant="caption" color="secondary">
                  MLS Number
                </Typography>
                <Typography variant="body" color="primary">
                  {props.data?.listingMlsNumber || "None"}
                </Typography>
              </div>

              <div>
                <Typography variant="caption" color="secondary">
                  Date Listed
                </Typography>
                <Typography variant="body" color="primary">
                  {props.data?.listingDateListed
                    ? format(
                      new Date(props.data?.listingDateListed),
                      "MMMM dd, yyyy"
                    )
                    : "None"}
                </Typography>
              </div>
              <div>
                <Typography
                  variant="caption"
                  color="secondary"
                  className="whitespace-nowrap"
                >
                  Listing Expiration Date
                </Typography>
                <Typography variant="body" color="primary">
                  {props.data?.listingExpirationDate
                    ? format(
                      new Date(props.data?.listingExpirationDate),
                      "MMMM dd, yyyy"
                    )
                    : "None"}
                </Typography>
              </div>
              <div className="flex flex-col">
                <Typography
                  variant="caption"
                  color="secondary"
                  className="whitespace-nowrap"
                >
                  Listing Status
                </Typography>
                <span
                  className={`w-fit px-3 py-0.5 rounded-lg text-14 font-normal ${getStatusColor(
                    props.data?.status
                  )}`}
                >
                  {props.data?.status}
                </span>
              </div>
            </div>
          </div>
          {/* Address details */}
          <div className=" border rounded-[8px] divide-y">
            <div className=" p-3">
              <Typography variant="h3" color="primary">
                Address
              </Typography>
            </div>
            <div className="p-3 grid grid-cols-3 gap-5">
              <div className="flex-1 flex flex-col gap-1">
                <Typography variant="caption" color="secondary">
                  Address 1
                </Typography>
                {props.data?.isPublic ? (
                  <Typography variant="body" color="primary">
                    {props.data?.listingAddress?.streetLine || "None"}
                  </Typography>
                ) : (
                  ""
                )}
              </div>
              <div className="flex-1 flex flex-col gap-1">
                <Typography variant="caption" color="secondary">
                  Address 2 / Unit
                </Typography>
                {props.data?.isPublic ? (
                  <Typography variant="body" color="primary">
                    {props.data?.listingUnit ||
                      props.data?.listingAddress?.secondary ||
                      "None"}
                  </Typography>
                ) : (
                  ""
                )}
              </div>
              <div className="flex-1 flex flex-col gap-1">
                <Typography variant="caption" color="secondary">
                  City
                </Typography>
                <Typography variant="body" color="primary">
                  {props.data?.listingAddress?.city || "None"}
                </Typography>
              </div>

              <div className="flex-1 flex flex-col gap-1">
                <Typography variant="caption" color="secondary">
                  Neighborhood
                </Typography>
                <Typography variant="body" color="primary">
                  {props.data?.listingNeigborhood || "None"}
                </Typography>
              </div>
              <div className="flex-1 flex flex-col gap-1">
                <Typography variant="caption" color="secondary">
                  State
                </Typography>
                <Typography variant="body" color="primary">
                  {props.data?.listingAddress?.state || "None"}
                </Typography>
              </div>
              <div className="flex-1 flex flex-col gap-1">
                <Typography variant="caption" color="secondary">
                  Zip Code
                </Typography>
                <Typography variant="body" color="primary">
                  {props.data?.listingAddress?.zipcode || "None"}
                </Typography>
              </div>
            </div>
          </div>
          {/* Property details */}
          <div className=" border rounded-[8px] divide-y">
            <div className=" p-3">
              <Typography variant="h3" color="primary">
                Details
              </Typography>
            </div>
            <div className=" p-3 grid grid-cols-2 gap-5 py-[18px]">
              <div className="flex-1 flex gap-1 items-center">
                <div className="p-2 bg-gray-100 rounded-full w-fit aspect-square">
                  <IconWrapper name="house" />
                </div>
                <div className="flex flex-col items-start">
                  <Typography variant="caption" color="secondary">
                    Property Type
                  </Typography>
                  <Typography variant="body" color="primary">
                    {props.data?.propertyType || "None"}
                  </Typography>
                </div>
              </div>
              <div className="flex-1 flex items-center gap-1">
                <div className="p-2 bg-gray-100 rounded-full w-fit aspect-square">
                  <IconWrapper name="house" />
                </div>
                <div className="flex flex-col items-start">
                  <Typography variant="caption" color="secondary">
                    Property Sub-Type
                  </Typography>
                  <Typography variant="body" color="primary">
                    {props.data?.propertySubType || "None"}
                  </Typography>
                </div>
              </div>

              <div className="flex-1 flex items-center gap-1">
                <div className="p-2 bg-gray-100 rounded-full w-fit aspect-square">
                  <IconWrapper name="bed" />
                </div>
                <div className="flex flex-col items-start">
                  <Typography variant="caption" color="secondary">
                    Bedrooms
                  </Typography>
                  <Typography variant="body" color="primary">
                    {props.data?.propertyBedrooms || "None"}
                  </Typography>
                </div>
              </div>

              <div className="flex-1 flex items-center gap-1">
                <div className="p-2 bg-gray-100 rounded-full w-fit aspect-square">
                  <IconWrapper name="bath" />
                </div>
                <div className="flex flex-col items-start">
                  <Typography variant="caption" color="secondary">
                    Bathrooms
                  </Typography>
                  <Typography variant="body" color="primary">
                    {props.data?.propertyBathrooms || "None"}
                  </Typography>
                </div>
              </div>

              <div className="flex-1 flex items-center gap-1">
                <div className="p-2 bg-gray-100 rounded-full w-fit aspect-square">
                  <IconWrapper name="house-space" />
                </div>
                <div className="flex flex-col items-start">
                  <Typography variant="caption" color="secondary">
                    Home SqFt
                  </Typography>
                  <Typography variant="body" color="primary">
                    {props.data?.propertyHomeSqft || "-"}
                  </Typography>
                </div>
              </div>

              <div className="flex-1 flex items-center gap-1">
                <div className="p-2 bg-gray-100 rounded-full w-fit aspect-square">
                  <IconWrapper name="lot" />
                </div>
                <div className="flex flex-col">
                  <Typography variant="caption" color="secondary">
                    Lot SqFt
                  </Typography>
                  <Typography variant="body" color="primary">
                    {props.data?.propertyLotSqft || "-"}
                  </Typography>
                </div>
              </div>

              <div className="flex-1 flex items-center gap-1">
                <div className="p-2 bg-gray-100 rounded-full w-fit aspect-square">
                  <IconWrapper name="parking" />
                </div>
                <div className="flex flex-col ">
                  <Typography variant="caption" color="secondary">
                    Parking
                  </Typography>
                  <Typography variant="body" color="primary">
                    {props.data?.propertyParking}
                  </Typography>
                </div>
              </div>

              <div className="flex-1 flex items-center gap-1">
                <div className="p-2 bg-gray-100 rounded-full w-fit aspect-square">
                  <IconWrapper name="cool" />
                </div>
                <div className="flex flex-col ">
                  <Typography variant="caption" color="secondary">
                    Cooling
                  </Typography>
                  <Typography variant="body" color="primary">
                    {props.data?.propertyCooling}
                  </Typography>
                </div>
              </div>

              <div className="flex-1 flex items-center gap-1">
                {" "}
                <div className="p-2 bg-gray-100 rounded-full w-fit aspect-square">
                  <IconWrapper name="heating" />
                </div>
                <div className="flex flex-col ">
                  <Typography variant="caption" color="secondary">
                    Heating
                  </Typography>
                  <Typography variant="body" color="primary">
                    {props.data?.propertyHeating}
                  </Typography>
                </div>
              </div>

              <div className="flex-1 flex items-center gap-1">
                {" "}
                <div className="p-2 bg-gray-100 rounded-full w-fit aspect-square">
                  <IconWrapper name="garage" />
                </div>
                <div className="flex flex-col ">
                  <Typography variant="caption" color="secondary">
                    Garage
                  </Typography>
                  <Typography variant="body" color="primary">
                    {props.data?.propertyGarage}
                  </Typography>
                </div>
              </div>

              <div className="flex-1 flex items-center gap-1">
                {" "}
                <div className="p-2 bg-gray-100 rounded-full w-fit aspect-square">
                  <IconWrapper name="pool" />
                </div>
                <div className="flex flex-col ">
                  <Typography variant="caption" color="secondary">
                    Pool
                  </Typography>
                  <Typography variant="body" color="primary">
                    {props.data?.propertyPool}
                  </Typography>
                </div>
              </div>
            </div>
            <div className="flex flex-col p-[12px] gap-5">
              <div className="flex justify-between">
                <div className="flex flex-col">
                  <Typography variant="caption" color="secondary">
                    Units
                  </Typography>

                  <Typography variant="body" color="primary">
                    {props.data?.propertyNumberOfUnits || "None"}
                  </Typography>
                </div>
                <div className="flex flex-col ">
                  <Typography variant="caption" color="secondary">
                    Stories
                  </Typography>

                  <Typography variant="body" color="primary">
                    {props.data?.propertyStories || "None"}
                  </Typography>
                </div>
                <div className="flex flex-col ">
                  <Typography variant="caption" color="secondary">
                    Is Attached
                  </Typography>
                  <Typography variant="body" color="primary">
                    {props.data?.propertyAttached}
                  </Typography>
                </div>
                <div className="flex flex-col ">
                  <Typography variant="caption" color="secondary">
                    Is New Construction
                  </Typography>
                  <Typography variant="body" color="primary">
                    {props.data?.propertyNewConstruction}
                  </Typography>
                </div>
              </div>
              <div className="flex ">
                <div className="flex-1 flex flex-col gap-1">
                  <Typography variant="caption" color="secondary">
                    Views
                  </Typography>
                  <div className="flex flex-wrap items-center gap-1">
                    {props.data?.propertyViews !== undefined &&
                      props.data?.propertyViews.length > 0
                      ? props.data?.propertyViews.map(
                        (view, viewIndex) =>
                          view && (
                            <Label
                              key={viewIndex}
                              variant="outline"
                              color="info"
                              size="small"
                              title={view}
                            />
                          )
                      )
                      : "-"}
                  </div>
                </div>
                <div className="flex flex-1 flex-col gap-1 md:w-1/3 sm:w-full">
                  <Typography variant="caption" color="secondary">
                    Amenities
                  </Typography>
                  <div className="flex items-center gap-2">
                    <Typography variant="body" color="primary">
                      {props.data?.propertyAmenities !== undefined &&
                        props.data?.propertyAmenities.length > 0
                        ? props.data?.propertyAmenities.join(", ")
                        : "-"}
                    </Typography>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="mt-[50px] divide-y border text-justify rounded-[8px]">
            <div className="p-3">
              <Typography variant="h3" color="primary">
                Description
              </Typography>
            </div>
            <div className="p-3 space-y-[1rem]">
              <Typography
                variant="body"
                color="primary"
                className={showMore ? "line-clamp-3" : ""}
              >
                {props.data?.propertyDescription || "None"}
              </Typography>
              <span
                className="cursor-pointer text-[#4C42D7] text-[14px]"
                onClick={() => setShowMore(!showMore)}
              >
                Show {showMore ? "more" : "less"}
              </span>
              <div>
                <Typography variant="body" color="primary">
                  Keywords
                </Typography>
                <div className="flex flex-wrap items-center gap-1 w-full">
                  {props.data?.propertyKeywords !== undefined &&
                    props.data?.propertyKeywords.length > 0
                    ? props.data?.propertyKeywords?.map(
                      (keyword, keywordIndex) =>
                        keyword && (
                          <Label
                            key={keywordIndex}
                            variant="outline"
                            color="info"
                            size="small"
                            title={keyword}
                          />
                        )
                    )
                    : "-"}
                </div>
              </div>
            </div>
          </div>

          <div className="mt-[50px]">
            <div className="py-3">
              <Typography variant="h3" color="primary">
                Showing Details
              </Typography>
            </div>
            <div className="bg-gray-50 rounded-md">
              <div className="px-[10px] py-3">
                <div className="flex flex-col gap-1">
                  <Typography variant="caption" color="secondary">
                    Showing Instructions
                  </Typography>
                  <Typography variant="body" color="primary">
                    {props.data?.showingInstuction || "-"}
                  </Typography>
                </div>
                <div className="flex flex-col gap-1">
                  <Typography variant="caption" color="secondary">
                    Showing Remarks
                  </Typography>
                  <Typography variant="body" color="primary">
                    {props.data?.showingRemarks || "-"}
                  </Typography>
                </div>
              </div>
              <div className="flex px-[10px] py-3 gap-3">
                <div className="flex-1 flex flex-col gap-1">
                  <Typography variant="caption" color="secondary">
                    Lockbox or Keypad
                  </Typography>
                  <Typography variant="body" color="primary">
                    {props.data?.showingLockboxOrKeypad || "-"}
                  </Typography>
                </div>
                <div className="flex-1 flex flex-col gap-1">
                  <Typography variant="caption" color="secondary">
                    Access Code
                  </Typography>
                  <Typography variant="body" color="primary">
                    {props.data?.showingAccessCode || "-"}
                  </Typography>
                </div>
              </div>
              <div className="flex px-[10px] py-3 gap-3">
                <div className="flex-1 flex flex-col gap-1">
                  <Typography variant="caption" color="secondary">
                    Occupancy Status
                  </Typography>
                  <Typography variant="body" color="primary">
                    {props.data?.showingOccupanyStatus || "-"}
                  </Typography>
                </div>
                <div className="flex-1 flex flex-col gap-1">
                  <Typography variant="caption" color="secondary">
                    Require Agency Disclosure
                  </Typography>
                  <Typography variant="body" color="primary">
                    {props.data?.showingRequireAgencyDisclosure || "-"}
                  </Typography>
                </div>
              </div>
            </div>
          </div>

          {props.data?.owner?.agent?.company?.businessName && props.data?.procurementAgreementPaymentTerms && props.data?.procurementAgreementAmount && (
            <div className="mt-[50px]">
            <div className="py-3">
              <Typography variant="h3" color="primary">
                Procurement Agreement
              </Typography>
            </div>
            <div className="bg-gray-50 rounded-md">
              <div className="px-[10px] py-3">
                <Typography variant="body" color="secondary">
                  For the successful procurement of a Buyer for my listing,
                  <b> {props.data?.owner?.agent?.company?.businessName || "My company"}</b> is willing to
                  pay <b>{user?.agent?.company?.businessName || "your company"} {((props.data?.procurementAgreementPaymentTerms === "Fixed Amount" ? "$" : ""))}
                    {(props.data?.procurementAgreementAmount && props.data?.procurementAgreementAmount > 0
                      ? (props.data?.procurementAgreementPaymentTerms === "Percentage"
                        ? props.data?.procurementAgreementAmount
                        : <NumericFormat displayType="text" thousandSeparator="," value={props.data?.procurementAgreementAmount.toLocaleString()} />)
                      : "")}
                    {(props.data?.procurementAgreementPaymentTerms === "Percentage" ? "%" : "")} </b>
                  of the purchase price upon the successful closing of the transaction.
                </Typography>
              </div>
            </div>
          </div>
          )}

          <div className="flex min-w-full">
            {props.isPublished && (
              <div className=" w-full">
                {/* Drawer footer */}
                <div className="py-5">
                  <ListingDrawerFooter
                    data={props.data?._id ? owner?.agent : user.agent}
                    isSelf={isSelf}
                    ownerId={owner?._id}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Preview;
