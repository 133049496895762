type Props = {
  width: number;
  height: number;
  stroke?: string;
  className?: string;
};

const IconVirtual = (props: Props) => {
  return (
    <svg
      viewBox="0 0 21 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M7.99935 18.3346H12.9993C17.166 18.3346 18.8327 16.668 18.8327 12.5013V7.5013C18.8327 3.33464 17.166 1.66797 12.9993 1.66797H7.99935C3.83268 1.66797 2.16602 3.33464 2.16602 7.5013V12.5013C2.16602 16.668 3.83268 18.3346 7.99935 18.3346Z"
        stroke="#7065F0"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.08398 9.99828V8.76495C8.08398 7.17328 9.20898 6.53162 10.584 7.32328L11.6507 7.93995L12.7173 8.55662C14.0923 9.34828 14.0923 10.6483 12.7173 11.44L11.6507 12.0566L10.584 12.6733C9.20898 13.465 8.08398 12.815 8.08398 11.2316V9.99828Z"
        stroke="#7065F0"
        strokeWidth="2"
        stroke-miterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default IconVirtual;
