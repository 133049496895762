import { createSlice, createAsyncThunk, createAction } from '@reduxjs/toolkit'
import type { RootState } from '../store'
import axios from '@/shared/services/axios'
import { mergeMessages, updateMessages } from '@/shared/services/utils';
import { IParticipantDto, IMessageDto, CreateMessageDto, UpdateMessageDto, UpdateReadStatus, GetMessagesDto, GetParticipantsDto, DeleteMessagesDto, MessageItemDto, WhoIsTypingDto } from '@/shared/interfaces/interfaces'

interface ChatState {
  participants: Array<IParticipantDto>,
  messages: Array<IMessageDto>,
  messagePool: Array<MessageItemDto>,
  participantId: string,
}

const initialState: ChatState = {
  participants: [],
  messages: [],
  messagePool: [],
  participantId: ''
};

export const chatSlice = createSlice({
  name: 'chat',
  initialState,
  reducers: {
    reset: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(createMessage.fulfilled, (state, action) => {
      if(action.payload.success){
        state.participants = action.payload.participants;
      }
    });
    builder.addCase(updateMessage.fulfilled, (state, action) => {
      if(action.payload.success){
        state.participants = action.payload.participants;
      }
    });
    builder.addCase(updateReadStatus.fulfilled, (state, action) => {
      if(action.payload.success){
        state.participants = action.payload.participants;
        state.messages = updateMessages(state.messages, action.payload.messages);
      }
    });
    builder.addCase(fetchMessages.fulfilled, (state, action) => {
      if(action.payload.success){
        state.messages = mergeMessages(state.messages, action.payload.messages);
      }
    });
    builder.addCase(fetchParticipants.fulfilled, (state, action) => {
      if(action.payload.success){
        state.participants = action.payload.participants;
      }
    });
    builder.addCase(setParticipantId, (state, action) => {
      if(action.payload){
        state.participantId = action.payload;
      }
    });
    builder.addCase(setParticipants, (state, action) => {
      if(action.payload){
        state.participants = action.payload;
      }
    });
    builder.addCase(setMessages, (state, action) => {
      if(action.payload){
        state.messages = action.payload;
      }
    });
  },
});

export const chatAction = chatSlice.actions;

// Other code such as selectors can use the imported `RootState` type
export const getParticipants = (state: RootState) => 
  state.chat.participants;

export const getMessages = (state: RootState) => 
  state.chat.messages;

export const getParticipantId = (state: RootState) => 
  state.chat.participantId;

export default chatSlice.reducer;

export const createMessage = createAsyncThunk(
  'chat/create',
  async (data: CreateMessageDto) => {
    const response = await axios.post('chat/create', data);
    return response.data;
  }
);

export const updateMessage = createAsyncThunk(
  'chat/update',
  async (data: UpdateMessageDto) => {
    const response = await axios.put('chat/update', data);
    return response.data;
  }
);

export const updateReadStatus = createAsyncThunk(
  'chat/update-read',
  async (data: UpdateReadStatus) => {
    const response = await axios.put('chat/update-read', data);
    return response.data;
  }
);

export const fetchMessages = createAsyncThunk(
  'chat/get',
  async (data: GetMessagesDto) => {
    const response = await axios.post('chat/get', data);
    return response.data;
  }
);

export const fetchParticipants = createAsyncThunk(
  'chat/get-participant',
  async (data: GetParticipantsDto) => {
    const response = await axios.post('chat/get-participant', data);
    return response.data;
  }
);

export const deleteMessages = createAsyncThunk(
  'chat/delete',
  async (data: DeleteMessagesDto) => {
    const response = await axios.post('chat/delete', data);
    return response.data;
  }
);

export const whoIsTyping = createAsyncThunk(
  'chat/who-is-typing',
  async (data: WhoIsTypingDto) => {
    const response = await axios.post('chat/who-is-typing', data);
    return response.data;
  }
);

export const setParticipantId = createAction(
  'set/participant',
  (participantId: string) => {
    return {
      payload: participantId
    };
  }
);

export const setParticipants = createAction(
  'set/participants',
  (participants: Array<IParticipantDto>) => {
    return {
      payload: participants
    };
  }
);

export const setMessages = createAction(
  'set/messages',
  (messages: Array<IMessageDto>) => {
    return {
      payload: messages
    };
  }
);