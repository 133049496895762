import DefaultCompanyLogo from "@/assets/images/default_company_logo.webp";
import IconWrapper from "@/components/baseComponents/IconWrapper";
import Typography from "@/components/baseComponents/Typography";
import AgentDrawer from "@/pages/agent/ViewDrawer";
import { useState } from "react";
import DefaultProfilePicture from "@/assets/images/default_profile_picture.webp";

type IProps = {
  data?: any;
  isSelf?: boolean;
  ownerId?: string;
};

const ListingDrawerFooter = (props: IProps) => {

  const [openAgentDrawer, setOpenAgentDrawer] = useState<boolean>(false);

  return (
    <>
      {props.data && (<AgentDrawer open={openAgentDrawer} changeViewDrawer={setOpenAgentDrawer} data={props.data} />)}

      <div>
        <div className="flex flex-col justify-between gap-[14px] pb-10">
          {props.data?.company && props.data?.company?.businessName && (
            <div className="flex items-center justify-between gap-[14px] w-full  rounded-[5px] px-[12px] py-[16px] bg-[#F0F9FF]">
              <div className="flex md:items-center items-start  gap-[8px]">
                <img
                  className="w-[36px] aspect-square rounded-md object-cover object-top"
                  src={props.data?.company?.businessLogo || DefaultCompanyLogo}
                  onError={(e) => e.currentTarget.src = DefaultCompanyLogo}
                />
                <div className="">
                  <Typography
                    variant="h4"
                    color="primary"
                    className="truncate text-[18px]  max-w-[200px]"
                  >
                    {props.data?.company?.businessName || "Company"}
                  </Typography>
                  <Typography
                    variant="body"
                    color="secondary"
                    className="truncate  hidden md:block"
                  >
                    {props.data?.company?.businessCity || "Company"},{" "}
                    {props.data?.company?.businessState || "-"}
                  </Typography>
                  <div className="flex md:hidden flex-col -space-y-1">
                    <div className="flex items-center gap-2">
                      <IconWrapper name="telephone" width={13} />
                      <Typography
                        variant="medium-text"
                        color="secondary"
                        className="flex items-center gap-2"
                      >
                        {props.data?.company?.principalBrokerPhone || "None"}
                      </Typography>
                    </div>
                    <div className="flex items-center gap-2 ">
                      <IconWrapper name="envelope" width={13} />
                      <Typography
                        variant="medium-text"
                        color="secondary"
                        className="flex items-center gap-2"
                      >
                        {props.data?.company?.principalBrokerEmail || "None"}
                      </Typography>
                    </div>
                  </div>
                </div>
              </div>
              <div className="hidden md:flex flex-col min-w-[200px] ">
                <div className="flex items-center gap-2">
                  <IconWrapper name="telephone" width={13} />
                  <Typography
                    variant="medium-text"
                    color="secondary"
                    className="flex items-center gap-2"
                  >
                    {props.data?.company?.principalBrokerPhone || "None"}
                  </Typography>
                </div>
                <div className="flex items-center gap-2">
                  <IconWrapper name="envelope" width={13} />
                  <Typography
                    variant="medium-text"
                    color="secondary"
                    className="flex items-center gap-2"
                  >
                    {props.data?.company?.principalBrokerEmail || "None"}
                  </Typography>
                </div>
              </div>
            </div>
          )}

          <div className="flex items-center justify-between gap-[14px] w-full rounded-[5px] px-[12px] py-[16px] bg-[#EEF2FF] cursor-pointer" onClick={() => setOpenAgentDrawer(true)}>
            <div className="flex items-start md:items-center gap-[8px]">
              <img
                className="w-[36px] aspect-square rounded-full object-cover object-top"
                src={props.data?.avatarURL || DefaultProfilePicture}
                onError={(e) => e.currentTarget.src = DefaultProfilePicture}
              />
              <div className="">
                <Typography variant="h4" color="primary">
                  {(props.data?.firstName || "Agent") +
                    " " +
                    (props.data?.lastName || "")}
                </Typography>
                <Typography
                  variant="body"
                  color="secondary"
                  className="truncate  max-sm:hidden"
                >
                  Agent
                </Typography>
                <div className=" md:hidden flex flex-col -space-y-1">
                  <div className="flex items-center gap-2">
                    <IconWrapper name="telephone" width={13} />
                    <Typography
                      variant="medium-text"
                      color="secondary"
                      className="flex items-center gap-2"
                    >
                      {props.data?.mobileNumber || "None"}
                    </Typography>
                  </div>
                  <div className="flex items-center gap-2">
                    <IconWrapper name="envelope" width={13} />
                    <Typography
                      variant="medium-text"
                      color="secondary"
                      className="flex items-center gap-2"
                    >
                      {props.data?.contactEmail || "None"}
                    </Typography>
                  </div>
                </div>
              </div>
            </div>
            <div className="hidden md:flex flex-col min-w-[200px]">
              <div className="flex items-center gap-2">
                <IconWrapper name="telephone" width={13} />
                <Typography
                  variant="medium-text"
                  color="secondary"
                  className="flex items-center gap-2"
                >
                  {props.data?.mobileNumber || "None"}
                </Typography>
              </div>
              <div className="flex items-center gap-2">
                <IconWrapper name="envelope" width={13} />
                <Typography
                  variant="medium-text"
                  color="secondary"
                  className="flex items-center gap-2"
                >
                  {props.data?.contactEmail || "None"}
                </Typography>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <div className="pt-10 max-sm:pb-24">
        <Typography
          variant="medium-text"
          color="primary"
          className="text-center opacity-50"
        >
          Source's Property ID: A11470191 ©2023 Miami Association of REALTORS®.
          All rights reserved.
        </Typography>
      </div> */}
    </>
  );
};

export default ListingDrawerFooter;
