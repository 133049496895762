import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import type { RootState } from "../store";
import axios from "@/shared/services/axios";
import { IAddress, IAddressSearch } from "@/shared/interfaces/interfaces";

interface AddressState {
  zipcodes: Array<any>;
  units: Array<IAddress>;
}

const initialState: AddressState = {
  zipcodes: [],
  units: []
};

export const addressSlice = createSlice({
  name: "address",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(zipcodeLookup.fulfilled, (state, action) => {
      if (action.payload.success) {
        state.zipcodes = action.payload.data;
      }
    });
    builder.addCase(getAddressUnits.fulfilled, (state, action) => {
      if (action.payload.success) {
        state.units = action.payload.data;
      }
    });
  },
});

export const addressActions = addressSlice.actions;

export const getZipcodes = (state: RootState) => state.address.zipcodes;
export const getUnits = (state: RootState) => state.address.units;

export default addressSlice.reducer;

export const zipcodeLookup = createAsyncThunk(
  "address/zipcode/lookup",
  async (searchTerm: string) => {
    const response = await axios.get(
      "address/zipcode/lookup?searchTerm=" + searchTerm
    );
    return response.data;
  }
);

export const getAddressUnits = createAsyncThunk(
  "address/units",
  async ({ searchTerm, selected }: IAddressSearch) => {
    const response = await axios.get(
      `address/units?searchTerm=${searchTerm}&selected=${selected}`
    );
    return response.data;
  }
);