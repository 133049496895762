const PropertyDetailSkeleton = () => {
  return (
    <>
      <div className="flex w-full bg-white">
        <div className="max-w-screen-xl mx-auto w-full px-6">
          <div className="flex flex-col w-full mt-10 pb-10">
            <div className="flex items-center gap-2 mb-4 cursor-pointer">
              <div className="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-48 mb-4"></div>
            </div>
            <div className="flex gap-4 flex-col mb-10">
              <div className="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-20 mb-4"></div>

              <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-700 w-48 mb-4"></div>
            </div>
            <div className="flex w-full lg:hidden gap-4 mb-6">
              <div className="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-20 mb-4"></div>
              <div className="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-20 mb-4"></div>
            </div>
            <div className="flex lg:flex-row flex-col w-full lg:gap-6 gap-2">
              <div className="flex flex-col justify-end lg:w-2/3 w-full">
                <div
                  className={`flex w-full lg:h-[467px] h-[270px] rounded-xl`}
                ></div>
                <div className="relative">
                  <div className="absolute lg:right-6 lg:bottom-6 left-6 lg:left-auto bottom-6">
                    <div className="flex gap-1">
                      <div className="w-4 h-1 bg-white rounded-full"></div>
                      <div className="w-4 h-1 bg-white rounded-full"></div>
                      <div className="w-4 h-1 bg-white rounded-full"></div>
                      <div className="w-4 h-1 bg-white rounded-full"></div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex lg:flex-col flex-row lg:justify-end lg:w-1/3 w-full">
                <div className="flex w-full mb-6 lg:h-[222px] h-[108px] bg-[#C4C4C4] rounded-xl "></div>
                <div className="flex w-full lg:h-[222px] lg:ml-0 ml-2 h-[108px] bg-[#C4C4C4] rounded-xl "></div>
                <div className="relative">
                  <div className="absolute lg:right-6 lg:bottom-6 right-1 bottom-[160px] min-w-[200px]">
                    <div className="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-48 mb-4"></div>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex lg:flex-row flex-col w-full gap-6 lg:mt-8 mt-6">
              <div className="flex flex-col lg:w-2/3 w-full gap-8">
                <div className="flex lg:flex-row gap-y-4 flex-wrap justify-between w-full py-6 px-6  rounded-xl border-[1px] border-gray-300">
                  <div className="flex flex-col gap-4">
                    <div className="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-20 mb-4"></div>
                    <div className="flex gap-2">
                      <div className="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-20 mb-4"></div>
                    </div>
                  </div>
                  <div className="flex flex-col gap-4">
                    <div className="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-20 mb-4"></div>
                    <div className="flex gap-2">
                      <div className="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-20 mb-4"></div>
                    </div>
                  </div>
                  <div className="flex flex-col gap-4">
                    <div className="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-20 mb-4"></div>
                    <div className="flex gap-2">
                      <div className="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-20 mb-4"></div>
                    </div>
                  </div>
                  <div className="flex flex-col gap-4">
                    <div className="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-20 mb-4"></div>
                    <div className="flex gap-2">
                      <div className="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-20 mb-4"></div>
                    </div>
                  </div>

                  <div className="flex flex-col gap-4">
                    <div className="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-20 mb-4"></div>
                    <div className="flex gap-2">
                      <div className="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-20 mb-4"></div>
                    </div>
                  </div>
                </div>

                <div className="flex flex-col w-full gap-6 mt-6">
                  <div className="flex flex-col gap-4">
                    <div className="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-20 mb-4"></div>
                    <div className="flex gap-2">
                      <div className="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-20 mb-4"></div>
                    </div>
                  </div>
                </div>
                <div className="flex flex-col w-full gap-6 mt-6">
                  <div className="flex flex-col gap-4">
                    <div className="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-20 mb-4"></div>
                    <div className="flex gap-2">
                      <div className="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-20 mb-4"></div>
                    </div>
                  </div>
                </div>
                <div className="flex w-full h-0.5 bg-[#F0EFFB] mt-[22px] my-6"></div>
              </div>
              <div className="flex flex-col lg:w-1/3 w-full gap-6">
                <div className="flex flex-col justify-around w-full py-6 px-6  rounded-xl border-[1px] border-gray-300 gap-6">
                  <div className="flex w-full gap-2 justify-start">
                    <div className="flex w-20 h-20 bg-cover bg-center rounded-full bg-gray-400"></div>

                    <div className="flex flex-col justify-center">
                      <div className="flex flex-col gap-4">
                        <div className="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-20 mb-4"></div>
                        <div className="flex gap-2">
                          <div className="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-40 mb-4"></div>
                        </div>
                      </div>
                      <div className="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-40 mb-4"></div>
                    </div>
                  </div>
                  <div className="flex flex-col gap-2">
                    <div className="flex gap-2 items-center">
                      <div className="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-40 mb-4"></div>
                    </div>
                    <div className="flex gap-2 items-center">
                      <div className="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-40 mb-4"></div>
                    </div>
                  </div>
                  <div className="flex p-3 bg-[#F2F2F2]">
                    <div className="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-40 mb-4"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PropertyDetailSkeleton;
