import Typography from "@/components/baseComponents/Typography"
import { formatSlashDate } from "@/shared/config/constants"
import { NumericFormat } from "react-number-format"

type IProps = {
  data?: any
}

const Terms = (props: IProps) => {

  return (
    <div>
      <Typography variant="h3" color="primary">
        Terms and Conditions
      </Typography>
      <div className="flex w-full mt-[25px]">
        <div className="w-1/3 sm:w-full">
          <Typography variant="caption" color="secondary">
            Offer Amount
          </Typography>
          <Typography variant="body" color="primary">
            <NumericFormat
              displayType="text"
              thousandSeparator=","
              prefix="$"
              value={props.data?.offerAmount || 0}
            />
          </Typography>
        </div>
        <div className="w-1/3 sm:w-full">
          <Typography variant="caption" color="secondary">
            Earnest Money Deposit
          </Typography>
          <Typography variant="body" color="primary">
            <NumericFormat
              displayType="text"
              thousandSeparator=","
              prefix="$"
              value={props.data?.earnestMoneyDeposit || 0}
            />
          </Typography>
        </div>
        <div className="w-1/3 sm:w-full">
          <Typography variant="caption" color="secondary">
            Downpayment Inc. EMD
          </Typography>
          <Typography variant="body" color="primary">
            <NumericFormat
              displayType="text"
              thousandSeparator=","
              prefix="$"
              value={props.data?.downPayment || 0}
            />
          </Typography>
        </div>
      </div>
      <div className="flex w-full mt-[25px]">
        <div className="w-1/3 sm:w-full">
          <Typography variant="caption" color="secondary">
            Subject to Mortgage
          </Typography>
          <Typography variant="body" color="primary">
            <NumericFormat
              displayType="text"
              thousandSeparator=","
              prefix="$"
              value={props.data?.subjectToMortgage || 0}
            />
          </Typography>
        </div>
        <div className="w-1/3 sm:w-full">
          <Typography variant="caption" color="secondary">
            Seller Concession
          </Typography>
          <Typography variant="body" color="primary">
            <NumericFormat
              displayType="text"
              thousandSeparator=","
              prefix="$"
              value={props.data?.sellerConcession || 0}
            />
          </Typography>
        </div>
        <div className="w-1/3 sm:w-full">
          <Typography variant="caption" color="secondary">
            Cash on Closing
          </Typography>
          <Typography variant="body" color="primary">
            <NumericFormat
              displayType="text"
              thousandSeparator=","
              prefix="$"
              value={props.data?.cashOnClosing || 0}
            />
          </Typography>
        </div>
      </div>
      <div className="flex w-full mt-[25px]">
        <div className="w-1/3 sm:w-full">
          <Typography variant="caption" color="secondary">
            Proposed Closing Location
          </Typography>
          <Typography variant="body" color="primary">
            {props.data?.proposedClosingLocation || "-"}
          </Typography>
        </div>
        <div className="w-1/3 sm:w-full">
          <Typography variant="caption" color="secondary">
            Proposed Closing Date
          </Typography>
          <Typography variant="body" color="primary">
            {formatSlashDate(props.data?.proposedClosingDate) || "-"}
          </Typography>
        </div>
        <div className="w-1/3 sm:w-full">
          <Typography variant="caption" color="secondary">
            Personal Property Inclusions
          </Typography>
          <Typography variant="body" color="primary">
            {props.data?.personalPropertyInclusions || "-"}
          </Typography>
        </div>
      </div>
      <div className="flex w-full mt-[25px]">
        <div className="w-1/3 sm:w-full">
          <Typography variant="caption" color="secondary">
            Personal Property Exclusions
          </Typography>
          <Typography variant="body" color="primary">
            {props.data?.personalPropertyExclusions || "-"}
          </Typography>
        </div>
      </div>
      <div className="flex w-full mt-[25px] mb-[50px]">
        <div className="w-full">
          <Typography variant="caption" color="secondary">
            Note
          </Typography>
          <Typography variant="body" color="primary">
            {props.data?.noteToListingAgent || "-"}
          </Typography>
        </div>
      </div>

      <div className="mb-[50px]">
        <Typography variant="h3" color="primary">
          Listing Agent
        </Typography>
        <div className="flex w-full mt-[25px]">
          <div className="w-1/3 sm:w-full">
            <Typography variant="caption" color="secondary">
              First Name
            </Typography>
            <Typography variant="body" color="primary">
              {props.data?.listing?.owner?.agent?.firstName || "-"}
            </Typography>
          </div>
          <div className="w-1/3 sm:w-full">
            <Typography variant="caption" color="secondary">
              Last Name
            </Typography>
            <Typography variant="body" color="primary">
              {props.data?.listing?.owner?.agent?.lastName || "-"}
            </Typography>
          </div>
          <div className="w-1/3 sm:w-full">
            <Typography variant="caption" color="secondary">
              Phone Number
            </Typography>
            <Typography variant="body" color="primary">
              {props.data?.listing?.owner?.agent?.mobileNumber || "-"}
            </Typography>
          </div>
        </div>
        <div className="flex w-full mt-[25px] mb-[50px]">
          <div className="w-1/3 sm:w-full">
            <Typography variant="caption" color="secondary">
              Email
            </Typography>
            <Typography variant="body" color="primary">
              {props.data?.listing?.owner?.agent?.contactEmail || "-"}
            </Typography>
          </div>
          <div className="w-1/3 sm:w-full">
            <Typography variant="caption" color="secondary">
              Company
            </Typography>
            <Typography variant="body" color="primary">
              {props.data?.listing?.owner?.agent?.company?.businessName || "-"}
            </Typography>
          </div>
          <div className="w-1/3 sm:w-full">
            <Typography variant="caption" color="secondary">
              Address
            </Typography>
            <div>
              {props.data?.listing?.owner?.agent?.company?.businessCity && props.data?.listing?.owner?.agent?.company?.state && props.data?.listing?.owner?.agent?.company?.businessZip ? (
                <>
                  <div>
                    <Typography variant="body" color="primary" className="line-clamp-2">
                      {props.data?.listing?.owner?.agent?.company?.businessAddress1 ? props.data?.listing?.owner?.agent?.company?.businessAddress1 + " " : ""}
                      {props.data?.listing?.owner?.agent?.company?.businessAddress2}
                    </Typography>
                  </div>
                  <div>
                    <Typography variant="body" color="primary" className="line-clamp-1">
                      {props.data?.listing?.owner?.agent?.company?.businessCity + ", " + props.data?.listing?.owner?.agent?.company?.state + " " + props.data?.listing?.owner?.agent?.company?.businessZip}
                    </Typography>
                  </div>
                </>
              ) : "-"}
            </div>
          </div>
        </div>
      </div>
      <div>
        <Typography variant="h3" color="primary">
          Buyer's Agent
        </Typography>
        <div className="flex w-full mt-[25px]">
          <div className="w-1/3 sm:w-full">
            <Typography variant="caption" color="secondary">
              First Name
            </Typography>
            <Typography variant="body" color="primary">
              {props.data?.owner?.agent?.firstName || "-"}
            </Typography>
          </div>
          <div className="w-1/3 sm:w-full">
            <Typography variant="caption" color="secondary">
              Last Name
            </Typography>
            <Typography variant="body" color="primary">
              {props.data?.owner?.agent?.lastName || "-"}
            </Typography>
          </div>
          <div className="w-1/3 sm:w-full">
            <Typography variant="caption" color="secondary">
              Phone Number
            </Typography>
            <Typography variant="body" color="primary">
              {props.data?.owner?.agent?.mobileNumber || "-"}
            </Typography>
          </div>
        </div>
        <div className="flex w-full mt-[25px] mb-[50px]">
          <div className="w-1/3 sm:w-full">
            <Typography variant="caption" color="secondary">
              Email
            </Typography>
            <Typography variant="body" color="primary" className="break-all">
              {props.data?.owner?.agent?.contactEmail || "-"}
            </Typography>
          </div>
          <div className="w-1/3 sm:w-full">
            <Typography variant="caption" color="secondary">
              Company
            </Typography>
            <Typography variant="body" color="primary">
              {props.data?.owner?.agent?.company?.businessName || "-"}
            </Typography>
          </div>
          <div className="w-1/3 sm:w-full">
            <Typography variant="caption" color="secondary">
              Address
            </Typography>
            <div>
              {props.data?.owner?.agent?.company?.businessCity && props.data?.owner?.agent?.company?.state && props.data?.owner?.agent?.company?.businessZip ? (
                <>
                  <div>
                    <Typography variant="body" color="primary" className="line-clamp-2">
                      {props.data?.owner?.agent?.company?.businessAddress1 ? props.data?.owner?.agent?.company?.businessAddress1 + " " : ""}
                      {props.data?.owner?.agent?.company?.businessAddress2}
                    </Typography>
                  </div>
                  <div>
                    <Typography variant="body" color="primary" className="line-clamp-1">
                      {props.data?.owner?.agent?.company?.businessCity + ", " + props.data?.owner?.agent?.company?.state + " " + props.data?.owner?.agent?.company?.businessZip}
                    </Typography>
                  </div>
                </>
              ) : "-"}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Terms