type Props = {
  width: number;
  height: number;
  stroke?: string;
  className?: string;
};

const IconEdit = (props: Props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 16 16"
      fill="none"
      {...props}
    >
      <g clipPath="url(#clip0_4092_20347)">
        <path
          d="M11.3333 2.00108C11.5031 1.80043 11.7131 1.63711 11.95 1.52153C12.1869 1.40595 12.4454 1.34063 12.7091 1.32975C12.9728 1.31887 13.2359 1.36266 13.4816 1.45833C13.7273 1.554 13.9502 1.69946 14.1362 1.88542C14.3221 2.07139 14.4671 2.29378 14.5617 2.53844C14.6563 2.78309 14.6985 3.04463 14.6857 3.30639C14.6729 3.56816 14.6053 3.8244 14.4872 4.05879C14.3692 4.29317 14.2032 4.50056 14 4.66774L4.99998 13.6677L1.33331 14.6677L2.33331 11.0011L11.3333 2.00108Z"
          stroke="#1F2937"
          strokeWidth="1.2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M10 3.33203L12.6667 5.9987"
          stroke="#1F2937"
          strokeWidth="1.2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_4092_20347">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default IconEdit;
