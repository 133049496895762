import { IMyListing } from "@/shared/interfaces/interfaces";
import DefaultAvatar from "@/assets/images/default_profile_picture.webp";
import { Button } from "@/components/baseComponents/Button";
import IconWrapper from "@/components/baseComponents/IconWrapper";
import Typography from "@/components/baseComponents/Typography";
import { useState } from "react";
import Textarea from "@/components/baseComponents/Textarea";

interface Props {
  listing?: IMyListing;
}

const ListingProfileCard = ({ listing }: Props) => {
  const [agentMessage, setAgentMessage] = useState<string>(
    `${listing?.owner.agent.firstName}, I’d like to learn more about your real estate services.`
  );
  return (
    <div className="flex flex-col justify-around w-full py-6 px-6  rounded-xl shadow gap-6">
      <div className="flex w-full gap-2 justify-start">
        <div className="flex w-20 h-20 bg-cover bg-center rounded-full">
          <img
            className="rounded-xl w-full h-full bg-[#C4C4C4] object-cover"
            src={
              listing?.owner.agent.avatarURL &&
              listing?.owner.agent.avatarURL.length > 0
                ? listing?.owner.agent.avatarURL.length
                : DefaultAvatar
            }
            alt=""
          />
        </div>

        <div className="flex flex-col justify-center">
          <Typography
            color="black"
            className="text-[24px] font-bold  font-jakarta"
          >
            {listing?.owner.agent.firstName} {listing?.owner.agent.lastName}
          </Typography>
          <Typography className="whitespace-nowrap w-48 overflow-ellipsis overflow-hidden text-[20px] text-[#878787] font-medium  font-jakarta">
            {listing?.owner.company?.businessName}
          </Typography>
        </div>
      </div>
      <div className="flex flex-col gap-2">
        <div className="flex gap-2 items-center">
          <IconWrapper width={20} name="telephone" />
          <Typography className="whitespace-nowrap text-[16px] font-medium text-[#878787] mt-1 font-jakarta">
            {listing?.owner.agent.mobileNumber}
          </Typography>
        </div>
        <div className="flex gap-2 items-center">
          <IconWrapper width={20} name="message" />
          <Typography className="whitespace-nowrap text-[16px] font-medium text-[#878787] mt-1 font-jakarta">
            {listing?.owner.agent.contactEmail}
          </Typography>
        </div>
      </div>
      <div className="flex">
        <Textarea
          value={agentMessage}
          name="description"
          textareaClassName="!bg-[#F2F2F2] p-3"
          onChange={(e) => setAgentMessage(e.target.value)}
          placeholder=""
        ></Textarea>
      </div>
      <div className="flex md:flex-row flex-col gap-2 w-full">
        <Button
          className={`py-3 !h-full text-white w-full bg-[#7065F0] font-jakarta font-semibold text-[16px]  justify-center items-center`}
          variant="text"
          onClick={() => {}}
        >
          Send a Message
        </Button>
        <Button
          color="inherit"
          className="w-full border-1 !h-full border-[#7065F0] text-[#7065F0] py-3 justify-center items-center font-jakarta font-semibold text-[16px]"
          onClick={() => {}}
        >
          Connect with Agent
        </Button>
      </div>
    </div>
  );
};

export default ListingProfileCard;
