type Props = {
    width: number;
    height: number;
    stroke?: string;
    className?: string;
};

const IconVerified = (props: Props) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 21 20" fill="none" {...props}>
            <path
                d="M8.0013 10.0013L9.66797 11.668L13.418 7.91797M15.419 4.16672C15.5906 4.58175 15.9199 4.91163 16.3347 5.08386L17.789 5.68628C18.204 5.8582 18.5338 6.18796 18.7057 6.60302C18.8776 7.01807 18.8776 7.48442 18.7057 7.89948L18.1037 9.3528C17.9317 9.76804 17.9315 10.2349 18.1043 10.6499L18.7052 12.1028C18.7904 12.3084 18.8343 12.5287 18.8343 12.7513C18.8344 12.9738 18.7906 13.1942 18.7054 13.3998C18.6203 13.6054 18.4954 13.7922 18.338 13.9495C18.1807 14.1068 17.9938 14.2316 17.7882 14.3167L16.3349 14.9187C15.9199 15.0903 15.59 15.4197 15.4178 15.8344L14.8154 17.2888C14.6435 17.7039 14.3138 18.0336 13.8987 18.2056C13.4837 18.3775 13.0174 18.3775 12.6023 18.2056L11.149 17.6036C10.734 17.4321 10.2678 17.4324 9.85303 17.6045L8.39871 18.2061C7.9839 18.3776 7.51798 18.3775 7.10328 18.2057C6.68857 18.0339 6.35901 17.7045 6.18698 17.2899L5.5844 15.8351C5.4128 15.4201 5.08342 15.0902 4.66867 14.918L3.21436 14.3156C2.7995 14.1437 2.46985 13.8142 2.29787 13.3994C2.12589 12.9845 2.12566 12.5184 2.29722 12.1034L2.89919 10.6501C3.07068 10.235 3.07033 9.76885 2.89822 9.35404L2.29711 7.89862C2.21189 7.69305 2.168 7.4727 2.16797 7.25016C2.16793 7.02762 2.21175 6.80726 2.2969 6.60166C2.38206 6.39606 2.5069 6.20926 2.66428 6.05193C2.82165 5.8946 3.00849 5.76982 3.21411 5.68472L4.66738 5.08273C5.08203 4.91128 5.41171 4.5823 5.58406 4.16802L6.18645 2.71364C6.35837 2.29859 6.68812 1.96883 7.10315 1.79691C7.51819 1.62499 7.98453 1.62499 8.39957 1.79691L9.85284 2.3989C10.2679 2.5704 10.7341 2.57005 11.1488 2.39792L12.6038 1.79784C13.0188 1.62602 13.485 1.62605 13.9 1.79794C14.3149 1.96983 14.6446 2.29949 14.8166 2.71444L15.4191 4.16924L15.419 4.16672Z"
                stroke={props.stroke}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};

export default IconVerified;