type Props = {
  width: number;
  height: number;
  stroke?: string;
  className?: string;
};

const IconMoreVertical = (props: Props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 16 16"
      fill="none"
      {...props}
    >
      <path
        d="M8.00004 8.66683C8.36823 8.66683 8.66671 8.36835 8.66671 8.00016C8.66671 7.63197 8.36823 7.3335 8.00004 7.3335C7.63185 7.3335 7.33337 7.63197 7.33337 8.00016C7.33337 8.36835 7.63185 8.66683 8.00004 8.66683Z"
        fill="#1F2937"
        stroke="#1F2937"
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.00004 3.99984C8.36823 3.99984 8.66671 3.70136 8.66671 3.33317C8.66671 2.96498 8.36823 2.6665 8.00004 2.6665C7.63185 2.6665 7.33337 2.96498 7.33337 3.33317C7.33337 3.70136 7.63185 3.99984 8.00004 3.99984Z"
        fill="#1F2937"
        stroke="#1F2937"
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.00004 13.3333C8.36823 13.3333 8.66671 13.0349 8.66671 12.6667C8.66671 12.2985 8.36823 12 8.00004 12C7.63185 12 7.33337 12.2985 7.33337 12.6667C7.33337 13.0349 7.63185 13.3333 8.00004 13.3333Z"
        fill="#1F2937"
        stroke="#1F2937"
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default IconMoreVertical;
