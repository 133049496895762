import React, { useEffect } from "react";
import Typography from "@/components/baseComponents/Typography";
import { Button } from "@/components/baseComponents/Button";
import { format } from "date-fns";
import Textarea from "@/components/baseComponents/Textarea";
import { notify } from "@/shared/services/notify";
import CalendarWeeklyView from "@/components/mainComponents/CalendarWeeklyView";
import TimeSelector from "@/components/mainComponents/TimeSelector";

type IProps = {
  data: any;
  closeDrawer: Function;
  reschedule: Function;
  updateStatus: Function;
};

const ShowingDetailsSection = (props: IProps) => {
  // const user = useAppSelector(getUser);
  const [currentAction, setCurrentAction] = React.useState<string>(props.data.status?.props?.children ?? '');
  const [availabilityTime, setAvailabilityTime] = React.useState<any>([]);

  // const [selectedTimeIndex, setSelectedTimeIndex] = React.useState<number>(0);

  const [selectedTime, setSelectedTime] = React.useState<any>();

  const [date, setDate] = React.useState<Date>(new Date());

  const [remarks, setRemarks] = React.useState<string>("");
  // const [newSchedule, setNewSchedule] = React.useState<Date>(new Date());

  const handleConfirm = () => {
    props.updateStatus("Confirmed");
    props.closeDrawer();
  };

  const onChangeDate = (date: any) => {
    setDate(date);
  };

  const handleDeny = () => {
    props.updateStatus("Denied", remarks);
    props.closeDrawer();
  };

  const onRescheduleShowing = () => {
    if (selectedTime) {
      const newSchedule = (new Date(`${date.toDateString()} ${selectedTime}`));
      props.reschedule(newSchedule, remarks);
      props.closeDrawer();
    } else {
      notify(false, "Please select a time");
    }
  };

  useEffect(() => {
    if (availabilityTime.length === 0) {
      setSelectedTime("");
    }
  }, [availabilityTime]);

  useEffect(()=>{
    if(currentAction){
      if(currentAction=="Denied"){
        setCurrentAction('deny')
      }
    }
  },[currentAction])

  return (
    <div className="">
      <>
        <div
          className={`bg-gray-50 border rounded-[12px] flex flex-col cursor-pointer items-start justify-between rounded-t-[12px] overflow-hidden 
                "border-b border-border"
              `}
        >
          <div className="bg-white flex gap-2 items-center border-b w-full p-[12px]">
            <Typography variant="h3" color="primary" className="">
              Showing Details
            </Typography>
          </div>

          <div className={"w-full bg-white pt-2"}>
            <div className="px-[12px]  grid grid-cols-3 gap-3">
              <div className="flex flex-col gap-1">
                <Typography variant="caption" color="secondary">
                  Day
                </Typography>
                <Typography variant="body" color="primary" className="truncate">
                  {(props.data &&
                    format(new Date(props.data?.dateTime), "iiii")) ||
                    "None"}
                </Typography>
              </div>
              <div className="flex flex-col gap-1">
                <Typography variant="caption" color="secondary">
                  Date
                </Typography>
                <Typography variant="body" color="primary" className="truncate">
                  {(props.data &&
                    format(new Date(props.data?.dateTime), "MM/dd/yyyy")) ||
                    "None"}
                </Typography>
              </div>
              <div className="flex flex-col gap-1">
                <Typography variant="caption" color="secondary">
                  Time
                </Typography>
                <Typography variant="body" color="primary" className="truncate">
                  {(props.data &&
                    format(new Date(props.data?.dateTime), "p")) ||
                    "None"}
                </Typography>
              </div>
            </div>

            {props.data?.status.props.children !== "Denied" && (
              <div className="px-[12px] pb-[12px] border-b border-border">
                <div className="flex items-center gap-1 pt-4 ">
                  <div className="flex items-center gap-1 max-sm:flex-col max-sm:items-center w-full">
                    {props.data?.status.props.children == "Pending" && (
                      <Button
                        color="success"
                        onClick={handleConfirm}
                        className="max-sm:w-full"
                      >
                        <Typography variant="button1">Confirm Showing</Typography>
                      </Button>
                    )}
                    <Button
                      variant="contained"
                      color="primary"
                      className="max-sm:w-full"
                      onClick={() => {
                        setCurrentAction("reschedule");
                      }}
                    >
                      <Typography variant="button1">
                        Reschedule Showing
                      </Typography>
                    </Button>
                    {
                      currentAction !== "deny" && (
                        <Button
                          color="danger-nobg"
                          className="max-sm:w-full"
                          onClick={() => {
                            setCurrentAction("deny");
                          }}
                        >
                          <Typography variant="button1">Cancel Showing</Typography>
                        </Button>
                      )
                    }
                  </div>
                </div>
              </div>
            )}

            <div className="!p-0 bg-gray-50 h-full">
              {currentAction === "reschedule" && (
                <div className=" bg-[#FAFAFA] p-2 md:p-3">
                  <div className="py-3">
                    <Typography
                      variant="body"
                      color="primary"
                      className="!font-bold"
                    >
                      Reschedule Showing
                    </Typography>
                  </div>
                  <div className="bg-white border-gray-200 border-1 flex flex-col gap-[15px] px-2 md:px-3 rounded-xl">
                    <div className="flex flex-col">
                      <div className="flex flex-wrap justify-between items-center border-b border-border py-3 my-2">
                        <Typography variant="body" color="secondary">
                          Schedule Date
                        </Typography>
                        <Typography
                          variant="body"
                          color="primary"
                          className="text-center whitespace-nowrap pr-5 pt-2 font-bold"
                        >
                          {format(date, "EEEE, MMMM d, yyyy")}{" "}
                          {selectedTime && ("at: " + selectedTime)}
                        </Typography>
                      </div>
                      <div className="border shadow rounded-lg p-2 py-4 md:p-3">
                        <CalendarWeeklyView onSelectDate={onChangeDate} />
                      </div>
                    </div>
                    <div>
                      {/* <Typography
                      variant="h3"
                      color="primary"
                      className="text-center whitespace-nowrap mb-4 pr-5 pt-2"
                    >
                      {formatWeekDate(date)}
                    </Typography> */}

                      <TimeSelector
                        data={props.data?.listing}
                        date={date}
                        availability={availabilityTime}
                        setAvalablity={setAvailabilityTime}
                        onSelectTime={setSelectedTime}
                      />
                    </div>
                    {/* Reason for reschedule */}
                    <div className="space-y-2 py-3">
                      <div className="flex gap-3 justify-between">
                        <Typography
                          variant="body"
                          color="primary"
                          className="flex gap-5 items-center"
                        >
                          Reason for Rescheduling
                        </Typography>
                        <Typography
                          variant="body"
                          color="primary"
                          className="flex  items-center"
                        >
                          0/100
                        </Typography>
                      </div>
                      <Textarea
                        name="showingRemarks"
                        className="rounded-[8px]"
                        rows={5}
                        value={remarks}
                        onChange={(e) => setRemarks(e.target.value)}
                      />
                      <div className="flex justify-end mt-[25px]">
                        <Button color="primary">
                          <Typography
                            variant="button1"
                            onClick={onRescheduleShowing}
                          >
                            Request Reschedule
                          </Typography>
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              )}

              {/* Cancellation display */}
              {(props.data?.status.props.children !== "Denied" && currentAction === "deny") && (
                <div className=" my-[4px] md:my-[25px] px-[4px] md:px-[12px]">
                  <div className="py-[12px]">
                    <Typography variant="h4" color="primary">
                      Cancellation
                    </Typography>
                  </div>
                  <div className="border rounded-[12px] p-[12px] bg-white">
                    <div className="flex items-center justify-between flex-wrap border-b pb-[12px]">
                      <Typography variant="body" color="secondary">
                        Current showing request
                      </Typography>
                      <Typography>
                        {props.data &&
                          format(
                            new Date(props.data?.dateTime),
                            "iiii MM/dd/yy"
                          )}{" "}
                        at{" "}
                        {props.data &&
                          format(new Date(props.data?.dateTime), "p")}
                        .
                      </Typography>
                    </div>
                    <div className="flex gap-3 justify-between pt-[12px]">
                      {/* <IconWrapper name="check-plus-circle" /> */}
                      <Typography
                        variant="body"
                        color="primary"
                        className="flex gap-5 items-center"
                      >
                        Reason for Cancellation
                      </Typography>
                      <Typography
                        variant="body"
                        color="primary"
                        className="flex gap-5 items-center"
                      >
                        0/100
                      </Typography>
                    </div>
                    <div className="space-y-[12px] pt-[12px]">
                      <Textarea
                        name="showingRemarks"
                        rows={5}
                        value={remarks}
                        onChange={(e) => setRemarks(e.target.value)}
                      />
                      <div className="flex justify-end mt-[25px]">
                        <Button color="danger" variant="contained">
                          <Typography variant="button1" onClick={handleDeny}>
                            Confirm Cancellation
                          </Typography>
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </>
    </div>
  );
};

export default ShowingDetailsSection;
