import { IMyListing } from "@/shared/interfaces/interfaces";
import DefaultListing from "@/assets/images/default_listing_image.webp";

interface Props {
  listing?: IMyListing;
  index: number;
  className?: string;
}
const ListingImage = ({ listing, index, className }: Props) => {
  return (
    <div className={className}>
      <img
        className="rounded-xl w-full h-full bg-[#C4C4C4] object-cover"
        src={
          listing?.propertyPhotos && listing?.propertyPhotos.length > index
            ? listing?.propertyPhotos[index].file
            : DefaultListing
        }
        alt=""
      />
    </div>
  );
};

export default ListingImage;
