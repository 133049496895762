type Props = {
    width: number;
    height: number;
    stroke?: string;
    className?: string;
};

const IconSearch = (props: Props) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" fill="none" {...props}>
            <path
                d="M7.33333 12.6667C10.2789 12.6667 12.6667 10.2789 12.6667 7.33333C12.6667 4.38781 10.2789 2 7.33333 2C4.38781 2 2 4.38781 2 7.33333C2 10.2789 4.38781 12.6667 7.33333 12.6667Z"
                stroke={props.className?.includes("stroke") ? undefined : props.stroke ?? "#3C3C3C"}
                strokeWidth="1.2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M14 13.9995L11.1333 11.1328"
                stroke={props.className?.includes("stroke") ? undefined : props.stroke ?? "#3C3C3C"}
                strokeWidth="1.2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};

export default IconSearch;
