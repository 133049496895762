import { useEffect, useRef, useState } from "react";
import TextField from "@/components/baseComponents/TextField";
import Typography from "@/components/baseComponents/Typography";
import { DatePicker } from "@/components/baseComponents/DatePickers";
import Select from "@/components/baseComponents/Select";
import { Button } from "@/components/baseComponents/Button";
import { IMyInfo, UpdateMyInfoDto } from "@/shared/interfaces/interfaces";
import {
  getUser,
  getStates,
  updateMyInfo,
  fetchStateAssociation,
  updateStates,
  fetchLocalAssociation,
  fetchMLSAssociation,
} from "@/redux/user/userSlice";
import { useAppDispatch, useAppSelector } from "@/redux/hooks";
import validation from "@/shared/services/validation";
import { notify } from "@/shared/services/notify";
import { myBucket } from "@/shared/services/s3Bucket";
import { AiOutlineInfoCircle } from "react-icons/ai";
import Textarea from "@/components/baseComponents/Textarea";
import defaultCoverPhoto from "@/assets/images/default_cover_image.jpg";
import defaultAvatar from "@/assets/images/default_avatar.jpg";
import IconWrapper from "@/components/baseComponents/IconWrapper";
import ImageCropModal from "@/components/mainComponents/ImageCrop";
import DefaultProfilePicture from "@/assets/images/default_profile_picture.webp";
import { zipcodeLookup } from "@/redux/address/addressSlice";
import Label from "@/components/baseComponents/Label";

interface IProps {
  onSubmitClick: boolean;
  thereIsChange: (change: boolean) => void;
  revertChanges: number;
  changeSaved: () => void;
  width: number;
}

const MyInfo = ({
  onSubmitClick,
  thereIsChange,
  revertChanges,
  changeSaved,
  width,
}: IProps) => {

  const baseURL = `${window.location.origin.toString()}/`;
  const dispatch = useAppDispatch();
  const user = useAppSelector(getUser);
  const states = useAppSelector(getStates);
  const avatarRef = useRef<HTMLInputElement>(null);
  const coverPhotoRef = useRef(null);

  const prefixOptions = [
    { value: "Mr", label: "Mr" },
    { value: "Mrs", label: "Mrs" },
    { value: "Ms", label: "Ms" },
    { value: "Dr", label: "Dr" },
    { value: "Prof", label: "Prof" },
  ];

  const initialMyInfo: IMyInfo = {

    // General Details
    memberID: user.agent?.memberID ?? "",
    prefix: user.agent?.prefix ?? "",
    firstName: user.agent?.firstName ?? "",
    middleName: user.agent?.middleName ?? "",
    lastName: user.agent?.lastName ?? "",
    mobileNumber: user.agent?.mobileNumber ?? "",
    officeNumber: user.agent?.officeNumber ?? "",
    contactEmail: user.agent?.contactEmail ?? "",
    avatarURL: user.agent?.avatarURL ?? "",
    coverPhotoURL: user.agent?.coverPhotoURL ?? "",
    description: user.agent?.description ?? "",
    profileURL: user.agent?.profileURL ?? baseURL,
    assignedURL: baseURL + user.agent?.firstName.toLowerCase() + "-" + user.agent?.memberID,

    // License Details
    licenseNumber: user.agent?.licenseNumber ?? "",
    licenseState: user.agent?.licenseState ?? "",
    licenseType: user.agent?.licenseType ?? "",
    licenseDate: user.agent?.licenseDate
      ? new Date(user.agent?.licenseDate)
      : undefined,
    licenseExpiration: user.agent?.licenseExpiration
      ? new Date(user.agent?.licenseExpiration)
      : undefined,

    //Associations
    localAssociations: user.agent?.localAssociations ?? "",
    stateAssociations: user.agent?.stateAssociations ?? "",
    mlsAssociations: user.agent?.mlsAssociations ?? "",

    // Serviced Areas
    serviceAreas: user.agent?.serviceAreas ?? [],

    // Social Profile
    instagram: user.agent?.instagram ?? "",
    facebook: user.agent?.facebook ?? "",
    tiktok: user.agent?.tiktok ?? "",
    linkedin: user.agent?.linkedin ?? "",
    youtube: user.agent?.youtube ?? "",

    // Professional Profiles
    zillow: user.agent?.zillow ?? "",
    homes: user.agent?.homes ?? "",
    realtor: user.agent?.realtor ?? "",
    ratemyagent: user.agent?.ratemyagent ?? "",
  };
  
  const [values, setValues] = useState<IMyInfo>(initialMyInfo);
  const [errorFirstName, setErrorFirstName] = useState<boolean>(false);
  const [errorLastName, setErrorLastName] = useState<boolean>(false);
  const [errorEmail, setErrorEmail] = useState<boolean>(false);
  const [errorMobileNumber, setErrorMobileNumber] = useState<boolean>(false);
  const [errorOfficeNumber, setErrorOfficeNumber] = useState<boolean>(false);

  const [prefixState, setPrefixState] = useState({ value: "", label: "" });
  const [licenseState, setLicenseState] = useState({ value: "", label: "", lookup: "" });
  const [licenseType, setLicenseType] = useState({ value: "", label: "" });

  const [coverPhotoURL, setCoverPhotoURL] = useState<string>("");
  const [avatarURL, setAvatarURL] = useState<string>("");

  const [stateAssociations, setStateAssociations] = useState<Array<any>>([]);
  const [localAssociations, setLocalAssociations] = useState<Array<any>>([]);
  const [mlsAssociations, setMLSAssociations] = useState<Array<any>>([]);
  const [servicedAreaOptions, setServicedAreaOptions] = useState<Array<any>>([]);

  const [licenseStateList, setLicenseStateList] = useState<Array<any>>([]);
  const [licenseTypeList, setLicenseTypeList] = useState<Array<any>>([]);
  const [licenseLookup, setLicenseLookup] = useState<string>("");
  const [submitDisable, setSubmitDisable] = useState<boolean>(false);

  const [imageCropModalOpen, setImageCropModalOpen] = useState<boolean>(false);
  const [imageForCrop, setImageForCrop] = useState<any>();
  const [imageName, setImageName] = useState<string>(new Date().getTime().toString());
  const [profileURLCustomValue, setProfileURLCustomValue] = useState<string>("");

  const handleInputChange = (e: any) => {
    const { name, value } = e.target;
    let tempValue = value;
    if (name.includes("Name")) {
      tempValue = value.replace(/[^a-z]/gi, "");
    }
    setValues({
      ...values,
      [name]: tempValue,
    });
    if (name === "mobileNumber" || name === "officeNumber") {
      const formattedPhoneNumber = validation.phoneNumberAutoFormat(value);
      if (
        name === "mobileNumber" &&
        formattedPhoneNumber.length > 0 &&
        formattedPhoneNumber.length < 12
      )
        setErrorMobileNumber(true);
      else setErrorMobileNumber(false);
      if (
        name === "officeNumber" &&
        formattedPhoneNumber.length > 0 &&
        formattedPhoneNumber.length < 12
      )
        setErrorOfficeNumber(true);
      else setErrorOfficeNumber(false);
      setValues({
        ...values,
        [name]: formattedPhoneNumber,
      });
    }
    if (name === "profileURL") {
      const splitValues = tempValue.split(baseURL);
      if (splitValues.length > 1) {
        setProfileURLCustomValue(splitValues[1]);
        setValues({
          ...values,
          profileURL: tempValue
        })
      } else {
        setValues({
          ...values,
          profileURL: baseURL + profileURLCustomValue
        })
      }
    }
    switch (name) {
      case "firstName":
        setErrorFirstName(validation.IsEmptyString(value));
        break;
      case "lastName":
        setErrorLastName(validation.IsEmptyString(value));
        break;
      case "contactEmail":
        setErrorEmail(validation.IsInvalidEmail(value));
        break;
    }
  };

  const handleSelectChange = (
    value: { value: string; label: string; lookup?: string },
    name: string
  ) => {
    setValues({
      ...values,
      [name]: value.value,
    });
    if (name === "licenseState") {
      setLicenseLookup(value.lookup ?? "");
    }
  };

  const generateLicenseTypeList = (value: any) => {
    let temp = (states as any).filter(
      (state: any) => state.name === value[0].value
    );

    if (temp.length > 0) {
      temp = temp[0];
    }
    let tempTypes = [];
    if (temp?.licenseType1) {
      tempTypes.push({
        value: temp.licenseType1,
        label: temp.licenseType1,
      });
    }
    if (temp?.licenseType2) {
      tempTypes.push({
        value: temp.licenseType2,
        label: temp.licenseType2,
      });
    }
    if (temp?.licenseType3) {
      tempTypes.push({
        value: temp.licenseType3,
        label: temp.licenseType3,
      });
    }
    if (temp?.licenseLookup) {
      setLicenseLookup(temp.licenseLookup);
    }
    setLicenseTypeList(tempTypes);
  };

  const handleMultiSelectChange = (value: any[], name: string) => {
    let tempValues: any[] = [];

    if (value?.length > 0) {
      value.forEach((ele: any) => {
        tempValues.push(ele.value);
      });
    }

    setValues({
      ...values,
      [name]: tempValues,
    });
  };

  const changeFile = (files: Array<any>) => {
    if (files.length > 0) {
      setImageForCrop(URL.createObjectURL(files[0]));
      setImageCropModalOpen(true);
      setImageName(files[0].name);
      (avatarRef.current as any).value = "";
    }
  };

  const handleCompletedCrop = (completedCrop: any) => {
    setAvatarURL(URL.createObjectURL(completedCrop));
    updateAvatar(completedCrop);
  };

  const changeCoverPhotoFile = (files: Array<any>) => {
    setCoverPhotoURL(URL.createObjectURL(files[0]));
    updateCoverPhoto(files[0]);
  };

  const openDialog = () => {
    (avatarRef.current as any).click();
  };

  const openCoverPhotoDialog = () => {
    (coverPhotoRef.current as any).click();
  };

  const updateCoverPhoto = (file: any) => {
    if (file === "") return;
    setSubmitDisable(true);
    const timestamp = new Date().getTime();
    const newFile = new File([file], timestamp + "_" + imageName);
    const fileParams: any = {
      ACL: "bucket-owner-full-control",
      Body: newFile,
      Bucket: import.meta.env.VITE_BUCKET_NAME,
      Key: "cover-photos/" + newFile.name,
    };

    myBucket.upload(fileParams, (err: any, data: any) => {
      if (err) {
        console.log(err);
      }
      if (data) {
        setCoverPhotoURL(data.Location);
        setValues({
          ...values,
          coverPhotoURL: data.Location,
        });
        setSubmitDisable(false);
      }
    });
  };

  const updateAvatar = (file: any) => {
    if (file === "") return;
    setSubmitDisable(true);
    const timestamp = new Date().getTime();
    const newFile = new File([file], timestamp + "_" + imageName);
    const fileParams: any = {
      ACL: "bucket-owner-full-control",
      Body: newFile,
      Bucket: import.meta.env.VITE_BUCKET_NAME,
      Key: "profile-photos/" + newFile.name,
    };

    myBucket.upload(fileParams, (err: any, data: any) => {
      if (err) {
        console.log(err);
      }
      if (data) {
        setAvatarURL(data.Location);
        setValues({
          ...values,
          avatarURL: data.Location,
        });
        setSubmitDisable(false);
      }
    });
  };

  const changeDate = (value: any, name: string) => {
    setValues({
      ...values,
      [name]: value,
    });
  };

  useEffect(() => {
    if (values) {
      if (licenseType.value === "" && values.licenseState.length > 0) {
        let value: any = [{ value: values.licenseState }];
        generateLicenseTypeList(value);
      }
      setLicenseState({
        value: values.licenseState,
        label: values.licenseState,
        lookup: "",
      });
      setLicenseType({ value: values.licenseType, label: values.licenseType });
      setPrefixState({ value: values.prefix, label: values.prefix });
    }
  }, [values]);

  const updateMyInfoFunc = () => {
    setErrorFirstName(validation.IsEmptyString(values.firstName));
    setErrorLastName(validation.IsEmptyString(values.lastName));
    setErrorEmail(validation.IsInvalidEmail(values.contactEmail));

    if (
      !validation.IsEmptyString(values.firstName) &&
      !validation.IsEmptyString(values.lastName) &&
      !validation.IsInvalidEmail(values.contactEmail) &&
      !errorMobileNumber &&
      !errorOfficeNumber
    ) {
      let data: UpdateMyInfoDto = {
        email: user.email,
        data: values,
      };
      dispatch(updateMyInfo(data)).then((res) => {
        try {
          notify(res.payload.success, res.payload.message);
        } catch (e) {
          notify(false, "Something went wrong.");
        }
      });
    }
  };

  const onCancelChanges = () => {
    console.log(initialMyInfo);
    setSubmitDisable(true);
  }

  const openLookup = () => {
    if (licenseLookup) {
      window.open(licenseLookup, "_blank");
    }
  };

  const onSearchServicedArea = (searchTerm: string) => {
    if (searchTerm !== "") {
      dispatch(zipcodeLookup(searchTerm)).then((_result: any) => {
        const options: any = [];
        const result = _result.payload.data;
        if (result && result.length > 0) {
          result.map((zipcode: any) => {
            options.push({
              label: zipcode.fullCityStateZipcode,
              value: zipcode.fullCityStateZipcode,
            });
          });

          setServicedAreaOptions(options);
        }
      });
    }
  };

  useEffect(() => {
    dispatch(updateStates()).then((res: any) => {
      let tempLicenseState = res.payload.states.filter(
        (state: any) => state.name === values.licenseState
      )[0];
      if (tempLicenseState?.licenseLookup) {
        setLicenseLookup(tempLicenseState.licenseLookup);
      }
      let tempStates = (res.payload.states as any).map((state: any) => {
        return {
          value: state.name,
          label: state.name,
          lookup: state.licenseLookup,
        };
      });
      setLicenseStateList(tempStates);
    });
    dispatch(fetchStateAssociation()).then((res: any) => {
      let tempStateAssociations = [];
      for (let i = 0; i < res.payload.stateAssociations?.length; i++) {
        tempStateAssociations.push({
          value: res.payload.stateAssociations[i].name,
          label: res.payload.stateAssociations[i].name,
        });
      }
      setStateAssociations(tempStateAssociations);
    });
    dispatch(fetchMLSAssociation()).then((res: any) => {
      let tempMLSAssociations = [];
      for (let i = 0; i < res.payload.mlsAffiliations?.length; i++) {
        tempMLSAssociations.push({
          value: res.payload.mlsAffiliations[i].name,
          label: res.payload.mlsAffiliations[i].name,
        });
      }
      setMLSAssociations(tempMLSAssociations);
    });
    dispatch(fetchLocalAssociation()).then((res: any) => {
      let tempLocalAssociations = [];
      for (let i = 0; i < res.payload.localAssociations?.length; i++) {
        tempLocalAssociations.push({
          value: res.payload.localAssociations[i].name,
          label: res.payload.localAssociations[i].name,
        });
      }
      setLocalAssociations(tempLocalAssociations);
    });
  }, []);

  useEffect(() => {
    if (onSubmitClick) {
      updateMyInfoFunc();
      changeSaved();
    }
  }, [onSubmitClick]);

  useEffect(() => {
    thereIsChange(JSON.stringify(values) !== JSON.stringify(initialMyInfo));
  }, [values])

  useEffect(() => {
    setValues(initialMyInfo);
  }, [revertChanges])

  return (
    <div className="flex flex-col gap-6">
      <div className="flex flex-col w-full bg-white rounded-xl">
        <div
          className="relative bg-cover bg-center bg-image w-full h-[11.625rem] rounded-t-xl"
          style={{
            backgroundImage:
              "url('" +
              (coverPhotoURL !== ""
                ? coverPhotoURL
                : values.coverPhotoURL !== undefined &&
                  values.coverPhotoURL !== ""
                  ? values.coverPhotoURL
                  : defaultCoverPhoto) +
              "')",
          }}
        >
          <div className="absolute bottom-2.5 right-3.5 flex flex-row">
            <input
              type="file"
              ref={coverPhotoRef}
              hidden
              onChange={(e) => changeCoverPhotoFile((e.target as any).files)}
            />
            <Button
              className="flex gap-1 text-15 mt-5 bg-white hover:bg-border"
              variant="text"
              onClick={openCoverPhotoDialog}
            >
              <IconWrapper name="camera" />
              <Typography color="primary">Cover</Typography>
            </Button>
          </div>
        </div>
        <div className="personal-info mx-4">
          <div className="flex mb-6 w-full">
            <div className="flex w-full items-end gap-2 -mt-8 z-5">
              {imageCropModalOpen && (
                <ImageCropModal
                  open={imageCropModalOpen}
                  imgSrc={imageForCrop}
                  handleCompletedCrop={handleCompletedCrop}
                  handleCloseModal={() => setImageCropModalOpen(false)}
                />
              )}
              <div className="relative !w-24 !h-24 cursor-pointer">
                <img
                  src={avatarURL !== "" ? avatarURL : (values.avatarURL ? values.avatarURL : defaultAvatar)}
                  className="aspect-square rounded-full border-2 border-white"
                  onClick={() => openDialog()}
                  onError={(e) => (e.currentTarget.src = DefaultProfilePicture)}
                />
                <Button variant="contained" className="absolute bottom-2 right-0 !rounded-full border-2 border-white !p-1 !h-6" onClick={() => openDialog()}>
                  <IconWrapper name="camera" className="[&>g>path]:!stroke-white" width={16} />
                </Button>
              </div>
              <input
                type="file"
                ref={avatarRef}
                hidden
                onChange={(e) => changeFile((e.target as any).files)}
                onInput={(e) => changeFile((e.target as any).files)}
              />
              <div className="flex flex-col w-full gap-0.5">
                <Typography
                  className="text-[18px] font-bold leading-6"
                  color="primary"
                >
                  {values.firstName + " " + values.lastName}
                </Typography>
                <div className="flex">
                  <Label
                    size="small"
                    variant="contained"
                    color="gray-light"
                    className="rounded-lg"
                    title={
                      <Typography>
                        {values.licenseState.length > 0 ? values.licenseState : ""}{" "}
                        {values.licenseType}
                      </Typography>
                    }
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <div className={`flex flex-col  items-start justify-between bg-white p-6 2xl:gap-20 xl:gap-8 rounded-lg ${width > 1100 ? "xl:flex-row" : ""}`}>
        <div className="flex flex-col xl:min-w-[300px] xl:pb-0 pb-8">
          <Typography variant="h2" color="primary">
            Edit your custom URL
          </Typography>
          <Typography variant="input-label" color="primary">
            Personalize the URL for your profile.
          </Typography>
        </div>
        <div className="flex flex-col gap-y-4">
          <div className="w-full flex flex-col gap-3">
            <div className="flex flex-col w-full gap-2">
              <Typography variant="input-label" className="text-secondary">
                Profile URL
              </Typography>
              <TextField
                name="profileURL"
                className="w-full"
                value={values.profileURL}
                onChange={(event) => handleInputChange(event)}
              />
            </div>
            <Typography
              variant="body"
              color="tertiary"
              className="flex items-start gap-2"
            >
              <IconWrapper name="info" width={16} />
              Your custom URL must contain 3-100 letters or number. Please do not use spaces, symbol, or special characters.
            </Typography>
          </div>
          <div className="w-full flex flex-col gap-4 py-3">
            <div className="flex flex-col w-full gap-2">
              <Typography variant="input-label" className="text-secondary">
                Assigned URL (cannot be changed)
              </Typography>
              <TextField
                disabled
                name="assignedURL"
                className="text-tertiary"
                value={values.assignedURL}
                endAdornment={<IconWrapper name="lock" />}
              />
            </div>
          </div>
        </div>
      </div> */}

      <div className={`flex flex-col  items-start justify-between bg-white p-6 2xl:gap-20 xl:gap-8 rounded-lg ${width > 1100 ? "xl:flex-row" : ""}`}>
        <div className="flex flex-col xl:min-w-[300px] xl:pb-0 pb-8">
          <Typography variant="h2" color="primary">
            Personal Info
          </Typography>
          <Typography variant="input-label" color="primary">
            This will be displayed on public profile.
          </Typography>
        </div>
        <div className="w-full flex flex-col gap-4">
          <div className="flex flex-col md:flex-row gap-6">
            <div className="flex flex-col w-full md:w-1/2 gap-2">
              <Typography variant="input-label" className="text-secondary">
                Prefix
              </Typography>
              <Select
                options={prefixOptions}
                name="prefix"
                isMulti={false}
                value={prefixState}
                onChange={(value) => {
                  setPrefixState(value);
                  handleSelectChange(value, "prefix");
                }}
              />
            </div>
            <div className="flex flex-col w-full md:w-1/2 gap-2">
              <Typography variant="input-label" className="text-secondary">
                First Name *
              </Typography>
              <TextField
                inputClassName={`${values.firstName?.length != 0 ? "bg-[#E5E7EB]" : ""
                  }`}
                className={`w-full ${errorFirstName
                  ? "outline outline-1 rounded outline-[#E01010]"
                  : ""
                  }`}
                name="firstName"
                value={values.firstName}
                onChange={(e) => handleInputChange(e)}
              />
              <div className="relative">
                {errorFirstName && (
                  <Typography
                    variant="caption"
                    className="text-[#E01010] absolute -mt-2"
                  >
                    This field is required
                  </Typography>
                )}
              </div>
            </div>
          </div>
          <div className="flex flex-col md:flex-row gap-6">
            <div className="flex flex-col w-full md:w-1/2 gap-2">
              <Typography variant="input-label" className="text-secondary">
                Middle Name
              </Typography>
              <TextField
                inputClassName={`${values.middleName?.length != 0 ? "bg-[#E5E7EB]" : ""
                  }`}
                className="w-full"
                name="middleName"
                value={values.middleName}
                onChange={(e) => handleInputChange(e)}
              />
            </div>
            <div className="flex flex-col w-full md:w-1/2 gap-2">
              <Typography variant="input-label" className="text-secondary">
                Last Name *
              </Typography>
              <TextField
                inputClassName={`${values.lastName?.length != 0 ? "bg-[#E5E7EB]" : ""
                  }`}
                className={`w-full ${errorLastName
                  ? "outline outline-1 rounded outline-[#E01010]"
                  : ""
                  }`}
                name="lastName"
                value={values.lastName}
                onChange={(e) => handleInputChange(e)}
              />
              <div className="relative">
                {errorLastName && (
                  <Typography
                    variant="caption"
                    className="text-[#E01010] absolute -mt-2"
                  >
                    This field is required
                  </Typography>
                )}
              </div>
            </div>
          </div>
          <div className="flex w-full">
            <div className="flex w-full flex-col gap-2">
              <Typography variant="input-label" className="text-secondary">
                Tell your colleagues about who you are.
              </Typography>
              <Textarea
                value={values.description}
                name="description"
                onChange={(e) => handleInputChange(e)}
                placeholder=""
              ></Textarea>
            </div>
          </div>
        </div>
      </div>
      {/* <div className="border-b border-gray-200 w-full mt-[25px] bg-white"></div> */}
      <div
        className={`flex flex-col items-start justify-between bg-white p-6 2xl:gap-20 xl:gap-8 rounded-lg ${width > 1100 ? "xl:flex-row" : ""
          }`}
      >
        <div className="flex flex-col xl:min-w-[300px] xl:pb-0 pb-8">
          <Typography variant="h2" color="primary">
            Contact Info
          </Typography>
          <Typography variant="input-label" color="primary">
            This will be displayed on public profile.
          </Typography>
        </div>
        <div className="w-full flex flex-col gap-4">
          <div className="flex flex-col md:flex-row gap-6">
            <div className="flex flex-col w-full md:w-1/2 gap-2">
              <Typography variant="input-label" className="text-secondary">
                Mobile Number
              </Typography>
              <TextField
                inputClassName={`${values.mobileNumber?.length != 0 ? "bg-[#E5E7EB]" : ""
                  }`}
                className={`w-full`}
                name="mobileNumber"
                value={values.mobileNumber}
                maxLength={12}
                onChange={(e) => handleInputChange(e)}
              />
              <div className="relative">
                {errorMobileNumber && (
                  <Typography
                    variant="caption"
                    className="text-[#E01010] absolute -mt-2"
                  >
                    Please enter a valid phone number
                  </Typography>
                )}
              </div>
            </div>
            <div className="flex flex-col w-full md:w-1/2 gap-2">
              <Typography variant="input-label" className="text-secondary">
                Office Number
              </Typography>
              <TextField
                inputClassName={`${values.officeNumber?.length != 0 ? "bg-[#E5E7EB]" : ""
                  }`}
                className={`w-full`}
                name="officeNumber"
                value={values.officeNumber}
                maxLength={12}
                onChange={(e) => handleInputChange(e)}
              />
              <div className="relative">
                {errorOfficeNumber && (
                  <Typography
                    variant="caption"
                    className="text-[#E01010] absolute -mt-2"
                  >
                    Please enter a valid phone number
                  </Typography>
                )}
              </div>
            </div>
          </div>
          <div className="flex flex-col md:flex-row gap-6">
            <div className="flex w-full flex-col gap-2">
              <Typography variant="input-label" className="text-secondary">
                Contact Email *
              </Typography>
              <TextField
                inputClassName={`${values.contactEmail?.length != 0 ? "bg-[#E5E7EB]" : ""
                  }`}
                className={`w-full ${errorEmail
                  ? "outline outline-1 rounded outline-[#E01010]"
                  : ""
                  }`}
                name="contactEmail"
                value={values.contactEmail}
                onChange={(e) => handleInputChange(e)}
              />
              <div className="relative">
                {errorEmail && (
                  <Typography
                    variant="caption"
                    className="text-[#E01010] absolute -mt-2"
                  >
                    Invalid Email Address
                  </Typography>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className={`flex flex-col items-start justify-between bg-white p-6 2xl:gap-20 xl:gap-8 rounded-lg ${width > 1100 ? "xl:flex-row" : ""
          }`}
      >
        <div className="flex flex-col xl:min-w-[300px] xl:pb-0 pb-8">
          <Typography variant="h2" color="primary">
            License Details
          </Typography>
          <Typography variant="input-label" color="primary">
            Your license details are required.
          </Typography>
        </div>
        <div className="w-full flex flex-col gap-4">
          <div className="flex flex-col md:flex-row gap-6">
            <div className="flex flex-col w-full md:w-1/2 gap-2">
              <Typography variant="input-label" className="text-secondary">
                License State
              </Typography>
              <Select
                options={licenseStateList}
                name="licenseState"
                value={licenseState}
                onChange={(value) => {
                  setLicenseState(value);
                  handleSelectChange(value, "licenseState");
                }}
              />
            </div>
            <div className="flex flex-col w-full md:w-1/2 gap-2">
              <Typography variant="input-label" className="text-secondary">
                License Type
              </Typography>
              <Select
                options={licenseTypeList}
                name="licenseTypeList"
                isMulti={false}
                value={licenseType}
                onChange={(value) => {
                  setLicenseType(value);
                  handleSelectChange(value, "licenseType");
                }}
              />
            </div>
          </div>
          <div className="flex w-full">
            <div className="flex w-full flex-col gap-2">
              <div className="flex w-full justify-between">
                <Typography variant="input-label" className="">
                  License Number
                </Typography>
                <Typography
                  variant="caption"
                  className={`flex items-center ${values.licenseState.length > 0
                    ? `cursor-pointer text-indigo-600 `
                    : `pointer-events-none text-indigo-400`
                    }`}
                  onClick={() => openLookup()}
                >
                  <AiOutlineInfoCircle
                    color="#4F46E5"
                    className={`h-4 w-4 mr-1 ${values.licenseState.length > 0
                      ? `!fill-indigo-600`
                      : `!fill-indigo-400`
                      }`}
                    aria-hidden="true"
                  />
                  {values.licenseState} State License Lookup
                </Typography>
              </div>
              <TextField
                inputClassName={`${values.licenseNumber?.length != 0 ? "bg-[#E5E7EB]" : ""
                  }`}
                className={`w-full`}
                name="licenseNumber"
                value={values.licenseNumber}
                onChange={(e) => handleInputChange(e)}
              />
            </div>
          </div>
          <div className="flex flex-col md:flex-row gap-6">
            <div className="flex flex-col w-full md:w-1/2 gap-2">
              <Typography variant="input-label" className="text-secondary">
                License Date
              </Typography>
              <DatePicker
                leadingClassName={"right-0 top-1 left-1"}
                innerClassName={`pl-10 ${values.licenseDate ? "bg-[#E5E7EB]" : "bg-[#F1F5F9]"
                  }`}
                name="licenseDate"
                value={values.licenseDate}
                onChange={(value) => changeDate(value, "licenseDate")}
              />
            </div>
            <div className="flex flex-col w-full md:w-1/2 gap-2">
              <Typography variant="input-label" className="text-secondary">
                License Expiration
              </Typography>
              <DatePicker
                leadingClassName={"right-0 top-1 left-1"}
                innerClassName={`pl-10 ${values.licenseExpiration ? "bg-[#E5E7EB]" : "bg-[#F1F5F9]"
                  }`}
                name="licenseExpiration"
                value={values.licenseExpiration}
                onChange={(value) => changeDate(value, "licenseExpiration")}
              />
            </div>
          </div>
        </div>
      </div>
      <div
        className={`flex flex-col items-start justify-between bg-white p-6 2xl:gap-20 xl:gap-8 rounded-lg ${width > 1100 ? "xl:flex-row" : ""
          }`}
      >
        <div className="flex flex-col xl:min-w-[300px] xl:pb-0 pb-8">
          <Typography variant="h2" color="primary">
            Associations
          </Typography>
          <Typography variant="input-label" color="primary">
            Select all associations you are affiliated with.
          </Typography>
        </div>
        <div className="w-full flex flex-col gap-4">
          <div className="flex w-full">
            <div className="flex flex-col w-full gap-2">
              <Typography variant="input-label" className="text-secondary">
                Local Associations
              </Typography>
              <Select
                options={localAssociations}
                name="localAssociations"
                isMulti={true}
                value={
                  values?.localAssociations
                    ? values.localAssociations.map((ele) => {
                      return {
                        value: ele,
                        label: ele,
                      };
                    })
                    : ""
                }
                onChange={(value) =>
                  handleMultiSelectChange(value, "localAssociations")
                }
              />
            </div>
          </div>
          <div className="flex w-full">
            <div className="flex flex-col w-full gap-2">
              <Typography variant="input-label" className="text-secondary">
                State Associations
              </Typography>
              <Select
                options={stateAssociations}
                name="stateAssociations"
                isMulti={true}
                value={
                  values?.stateAssociations
                    ? values.stateAssociations.map((ele) => {
                      return {
                        value: ele,
                        label: ele,
                      };
                    })
                    : ""
                }
                onChange={(value) =>
                  handleMultiSelectChange(value, "stateAssociations")
                }
              />
            </div>
          </div>
          <div className="flex w-full">
            <div className="flex flex-col w-full gap-2">
              <Typography variant="input-label" className="text-secondary">
                MLS Associations
              </Typography>
              <Select
                options={mlsAssociations}
                name="mlsAssociations"
                isMulti={true}
                value={
                  values?.mlsAssociations
                    ? values.mlsAssociations.map((ele) => {
                      return {
                        value: ele,
                        label: ele,
                      };
                    })
                    : ""
                }
                onChange={(value) =>
                  handleMultiSelectChange(value, "mlsAssociations")
                }
              />
            </div>
          </div>
        </div>
      </div>
      <div
        className={`flex flex-col items-start justify-between bg-white p-6 2xl:gap-20 xl:gap-8 rounded-lg ${width > 1100 ? "xl:flex-row" : ""
          }`}
      >
        <div className="flex flex-col xl:min-w-[300px] xl:pb-0 pb-8">
          <Typography variant="h2" color="primary">
            Serviced Areas
          </Typography>
          <Typography variant="input-label" color="primary">
            Select up to 10 of the most areas you focus on the most.
          </Typography>
        </div>
        <div className="w-full flex flex-col gap-4">
          <div className="flex w-full">
            <div className="flex flex-col w-full gap-2">
              <Typography variant="input-label" className="text-secondary">
                Area
              </Typography>
              <Select
                options={servicedAreaOptions}
                isMulti={true}
                onInputChange={onSearchServicedArea}
                onChange={(value) =>
                  handleMultiSelectChange(value, "serviceAreas")
                }
                filterOption={() => true}
                value={
                  values?.serviceAreas
                    ? values.serviceAreas.map((serviceArea) => {
                      return {
                        value: serviceArea,
                        label: serviceArea,
                      };
                    })
                    : ""
                }
              />
            </div>
          </div>
        </div>
      </div>
      <div
        className={`flex flex-col items-start justify-between bg-white p-6 2xl:gap-20 xl:gap-8 rounded-lg ${width > 1100 ? "xl:flex-row" : ""
          }`}
      >
        <div className="flex flex-col xl:min-w-[300px] xl:pb-0 pb-8">
          <Typography variant="h2" color="primary">
            Social Profiles
          </Typography>
          <Typography variant="input-label" color="primary">
            Description
          </Typography>
        </div>
        <div className="w-full flex flex-col gap-4">
          <div className="flex flex-col md:flex-row gap-6">
            <div className="flex flex-col w-full md:w-1/2 gap-2">
              <Typography variant="input-label" className="text-secondary">
                Instagram
              </Typography>
              <TextField
                inputClassName={`${values.instagram?.length != 0 ? "bg-[#E5E7EB]" : ""
                  }`}
                placeholder="https://www.instagram.com/"
                name="instagram"
                value={values.instagram}
                onChange={(e) => handleInputChange(e)}
              />
            </div>
            <div className="flex flex-col w-full md:w-1/2 gap-2">
              <Typography variant="input-label" className="text-secondary">
                Facebook
              </Typography>
              <TextField
                inputClassName={`${values.facebook?.length != 0 ? "bg-[#E5E7EB]" : ""
                  }`}
                placeholder="https://www.facebook.com/"
                name="facebook"
                value={values.facebook}
                onChange={(e) => handleInputChange(e)}
              />
            </div>
          </div>

          <div className="flex flex-col md:flex-row gap-6">
            <div className="flex flex-col w-full md:w-1/2 gap-2">
              <Typography variant="input-label" className="text-secondary">
                TikTok
              </Typography>
              <TextField
                inputClassName={`${values.tiktok?.length != 0 ? "bg-[#E5E7EB]" : ""
                  }`}
                placeholder="https://www.tiktok.com/@"
                name="tiktok"
                value={values.tiktok}
                onChange={(e) => handleInputChange(e)}
              />
            </div>
            <div className="flex flex-col w-full md:w-1/2 gap-2">
              <Typography variant="input-label" className="text-secondary">
                LinkedIn
              </Typography>
              <TextField
                inputClassName={`${values.linkedin?.length != 0 ? "bg-[#E5E7EB]" : ""
                  }`}
                placeholder="https://www.linkedin.com/in"
                name="linkedin"
                value={values.linkedin}
                onChange={(e) => handleInputChange(e)}
              />
            </div>
          </div>

          <div className="flex flex-col md:flex-row gap-6">
            <div className="flex flex-col w-full md:w-1/2 gap-2">
              <Typography variant="input-label" className="text-secondary">
                YouTube
              </Typography>
              <TextField
                inputClassName={`${values.youtube?.length != 0 ? "bg-[#E5E7EB]" : ""
                  }`}
                placeholder="https://www.youtube.com/@"
                name="youtube"
                value={values.youtube}
                onChange={(e) => handleInputChange(e)}
              />
            </div>
          </div>
        </div>
      </div>
      <div
        className={`flex flex-col items-start justify-between bg-white p-6 2xl:gap-20 xl:gap-8 rounded-lg ${width > 1100 ? "xl:flex-row" : ""
          }`}
      >
        <div className="flex flex-col xl:min-w-[300px] xl:pb-0 pb-8">
          <Typography variant="h2" color="primary">
            Professional Profiles
          </Typography>
          <Typography variant="input-label" color="primary">
            Description
          </Typography>
        </div>
        <div className="w-full flex flex-col gap-4">
          <div className="flex flex-col md:flex-row gap-6">
            <div className="flex flex-col w-full md:w-1/2 gap-2">
              <Typography variant="input-label" className="text-secondary">
                Zillow
              </Typography>
              <TextField
                inputClassName={`${values.zillow?.length != 0 ? "bg-[#E5E7EB]" : ""
                  }`}
                placeholder="https://www.zillow.com/profile/"
                name="zillow"
                value={values.zillow}
                onChange={(e) => handleInputChange(e)}
              />
            </div>
            <div className="flex flex-col w-full md:w-1/2 gap-2">
              <Typography variant="input-label" className="text-secondary">
                Homes.com
              </Typography>
              <TextField
                inputClassName={`${values.homes?.length != 0 ? "bg-[#E5E7EB]" : ""
                  }`}
                placeholder="https://www.homes.com/real-estate-agents/"
                name="homes"
                value={values.homes}
                onChange={(e) => handleInputChange(e)}
              />
            </div>
          </div>
          <div className="flex flex-col md:flex-row gap-6">
            <div className="flex flex-col w-full md:w-1/2 gap-2">
              <Typography variant="input-label" className="text-secondary">
                Realtor.com
              </Typography>
              <TextField
                inputClassName={`${values.realtor?.length != 0 ? "bg-[#E5E7EB]" : ""
                  }`}
                placeholder="https://www.realtor.com/realestateagents/"
                name="realtor"
                value={values.realtor}
                onChange={(e) => handleInputChange(e)}
              />
            </div>
            <div className="flex flex-col w-full md:w-1/2 gap-2">
              <Typography variant="input-label" className="text-secondary">
                RateMyAgent
              </Typography>
              <TextField
                inputClassName={`${values.realtor?.length != 0 ? "bg-[#E5E7EB]" : ""
                  }`}
                placeholder="https://www.ratemyagent.com/"
                name="ratemyagent"
                value={values.ratemyagent}
                onChange={(e) => handleInputChange(e)}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="flex justify-end items-center mb-12 gap-4 px-4 xl:hidden">
        <Button color="inherit" className="border-1 border-[#D4D4D8]" onClick={() => onCancelChanges()}>
          Cancel
        </Button>
        <Button
          color="primary"
          disabled={submitDisable}
          onClick={() => updateMyInfoFunc()}
        >
          Save Changes
        </Button>
      </div>
    </div>
  );
};

export default MyInfo;
