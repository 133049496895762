import ReactSelect, { ActionMeta } from "react-select";
import classNames from "classnames";
import Icon from "../IconWrapper";
import { MdOutlineKeyboardArrowDown } from "react-icons/md";
import { removeFirstDuplicateClassNames } from "@/shared/config/constants";
import Typography from "../Typography";
// ----------------------------------------------------------------------
type Props = {
  className?: string;
  options: Array<{ value: any; label: string }>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  value?: any;
  isMulti?: boolean;
  placeholder?: string;
  isSearchable?: boolean;
  name?: string;
  disabled?: boolean;
  isValid?: boolean;
  labelClassName?: string;
  valueClassName?: string;
  iconClassName?: string;
  notFoundText?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onChange?: (newValue: any, actionMeta: ActionMeta<any>) => void;
  onInputChange?: (inputValue: string) => void;
  filterOption?: (options: any) => boolean;
};

const Select = ({
  className,
  options,
  value,
  isMulti,
  isSearchable,
  placeholder = "",
  name,
  disabled,
  isValid = true,
  onChange,
  onInputChange,
  filterOption,
  labelClassName,
  valueClassName,
  iconClassName,
  notFoundText="No records found",
  ...other
}: Props) => {
  const existingClass = "!min-h-[42px] rounded-sm border-1 focus:!ring-2 !ring-[#4C42D7]/25 " + (isValid ? "!border-transparent" : "!border-rose-600");
  const modifiedClass = removeFirstDuplicateClassNames(existingClass + (className ? ` ${className}` : ""))

  const modifiedValueClass = removeFirstDuplicateClassNames("!px-4 !font-arial " + valueClassName)
  return (
    <ReactSelect
      options={options}
      value={value}
      onChange={onChange}
      onInputChange={onInputChange}
      filterOption={filterOption}
      isMulti={isMulti}
      isSearchable={isSearchable}
      isDisabled={disabled}
      placeholder={placeholder}
      name={name}
      menuPlacement="auto"
      classNames={{
        control: () =>
          classNames(
            modifiedClass,
            {
              ['!bg-[#F1F5F9]']: (value?.value === undefined || value?.length === 0) || value?.label === "",
              ['!bg-[#EAEEF2]']: value?.length > 0 || value?.label,
            }
          ),
        singleValue: () => "!body !text-primary !font-arial " + labelClassName,
        valueContainer: () => modifiedValueClass,
        placeholder: () => "!body !text-secondary !font-arial",
        option: (state) =>
          classNames(
            "!border-border !border-opacity-50 !border-b !font-arial !text-13 !leading-18 !font-medium !text-primary !px-4 !cursor-pointer",
            {
              ["!bg-neutral-100"]: state.isFocused,
              ["!bg-[#EAEEF2]"]: state.isSelected,
            }
          ),
        menu: () => "!border-none !outline-none !py-1 !shadow-md !mt-1",
        indicatorSeparator: () => "hidden",
        indicatorsContainer: () => "pr-2",
        multiValue: () => "!bg-white !rounded-3 !m-0 !mr-1",
        multiValueLabel: () => "!body !text-primary",
        multiValueRemove: () => "hover:!bg-red-100 hover:!text-red-500",
        input: () => "!body",
      }}
      components={{
        DropdownIndicator: () =>
          isSearchable ? (
            <Icon className={iconClassName} name="arrow-top-bottom" />
          ) : (
            <MdOutlineKeyboardArrowDown className={iconClassName} />
          ),
        NoOptionsMessage: () => (
          <Typography variant="body" color="secondary" className="px-4 text-center">
            <strong>{notFoundText}</strong>
            {/* <strong>No address found</strong> <br /> Try adding a specific city or state on your input. */}
          </Typography>
        )
      }}
      {...other}
    />
  );
};

export default Select;
