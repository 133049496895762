import { Button } from "@/components/baseComponents/Button";
import IconWrapper from "@/components/baseComponents/IconWrapper";
import Typography from "@/components/baseComponents/Typography";
import { Dialog, Transition } from "@headlessui/react";
import { Fragment, useState } from "react";
import Cropper, { Point } from "react-easy-crop";
import getCroppedImg from "@/shared/services/cropImage";

type ImageCropModalProps = {
    open: boolean;
    imgSrc: any;
    handleCompletedCrop: Function;
    handleCloseModal: Function;
}

const ImageCropModal = ({
    open,
    imgSrc,
    handleCompletedCrop,
    handleCloseModal
}: ImageCropModalProps) => {

    const [crop, setCrop] = useState<Point>({ x: 0, y: 0 });
    const [zoom, setZoom] = useState<number>(1);
    const [croppedAreaPixels, setCroppedAreaPixels] = useState<any>(null);

    const onCropComplete = (_croppedArea: any, croppedAreaPixels: any) => {
        setCroppedAreaPixels(croppedAreaPixels)
    }

    const showCroppedImage = async () => {
        try {
            const croppedImage = await getCroppedImg(
                imgSrc,
                croppedAreaPixels
            )
            handleCompletedCrop(croppedImage);
            handleCloseModal();
        } catch (e) {
            console.error(e)
        }
    }
    return (
        <Transition.Root show={open} as={Fragment}>
            <Dialog as="div" className="relative z-30" onClose={() => handleCloseModal()}>

                {/* The backdrop, rendered as a fixed sibling to the panel container */}
                <div className="fixed inset-0 bg-black/30" aria-hidden="true" />

                <div className="fixed inset-0 flex items-center justify-center z-50">
                    <Dialog.Panel className="mx-10 rounded-lg max-w-[600px] w-full bg-white max-sm:mx-4">
                        <div className="px-10 py-3 max-sm:px-4">
                            <div className="flex justify-between items-center">
                                <Typography variant="h2" color="primary" className="mt-2">Crop Your Photo</Typography>
                                <Button variant="icon" className="group" onClick={() => handleCloseModal()}>
                                    <IconWrapper name="close" className="group-hover:[&>svg>path]:!stroke-indigo-600" />
                                </Button>
                            </div>
                            <div className="px-4 border-1 border-gray-600 w-full mt-2"></div>
                        </div>
                        {imgSrc !== undefined && (
                            <div className="flex flex-col gap-4 py-3 mx-10 max-sm:mx-4 lg:h-[600px] md:sm:h-[500px] sm:h-[500px] max-sm:h-[500px]">
                                <div className="relative h-full">
                                    <Cropper
                                        image={imgSrc}
                                        crop={crop}
                                        zoom={zoom}
                                        aspect={4 / 4}
                                        cropShape="round"
                                        onCropChange={setCrop}
                                        onCropComplete={onCropComplete}
                                        onZoomChange={setZoom}
                                        classes={{
                                            cropAreaClassName: "shadow-[0_0_0_9999em]"
                                        }}
                                    />
                                </div>
                                <input 
                                    value={zoom}
                                    type="range" 
                                    className="w-full h-2 bg-gray-200 rounded-lg appearance-none cursor-pointer dark:bg-gray-700" 
                                    onChange={(e) => setZoom(parseInt(e.currentTarget.value))}
                                    min={1}
                                    max={10}
                                />

                                <div className="flex gap-2 self-end py-2">
                                    <Button variant="contained" color="default" onClick={() => handleCloseModal()}>
                                        <Typography variant="button1" color="primary">Cancel</Typography>
                                    </Button>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        className="w-28"
                                        onClick={() => showCroppedImage()}
                                    >
                                        <Typography variant="button1" color="white">Done</Typography>
                                    </Button>
                                </div>
                            </div>
                        )}
                    </Dialog.Panel>
                </div>
            </Dialog>
        </Transition.Root>
    );
}

export default ImageCropModal;