type Props = {
  width: number;
  height: number;
  stroke?: string;
  className?: string;
};

const IconSqft = (props: Props) => {
  return (
    <svg
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g opacity="0.5" clip-path="url(#clip0_10694_140939)">
        <path
          d="M10.5974 18.6509L4.14718 12.2007C3.41709 11.4706 3.41709 10.1263 4.14718 9.39622L10.5974 2.946C11.3275 2.21592 12.6717 2.21592 13.4018 2.946L19.852 9.39622C20.5821 10.1263 20.5821 11.4706 19.852 12.2007L13.4018 18.6509C12.6717 19.381 11.3275 19.381 10.5974 18.6509V18.6509Z"
          stroke="#000929"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M2.40039 15.8047L7.63685 21.0411"
          stroke="#000929"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M16.3633 21.0411L21.5997 15.8047"
          stroke="#000929"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_10694_140939">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default IconSqft;
