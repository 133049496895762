
const ListingCardSkeleton = () => {

  return (
    <div className={`flex w-full animate-pulse`}>
      <div className="flex flex-col w-full bg-white rounded-lg">
        <div className="flex xl:min-w-[343px] w-full min-w-[300px] rounded-tr-lg rounded-tl-lg min-h-[215px] bg-[#C4C4C4]"></div>
        <div className={`flex flex-col py-8 px-6`}>
          <div className="flex flex-col justify-between w-full h-[204px]">
            <div className="flex flex-col w-full">
              <div className="text-[#7065F0] font-jakarta text-[24px]">
                <span className="font-extrabold">
                <div className="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-20 mb-4"></div>

                </span>
              </div>
              <div className="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-48 mb-4"></div>
              <div className="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-48 mb-4"></div>
            </div>
            <div className="flex flex-col w-full">
              <div className="flex w-full h-0.5 bg-[#F0EFFB] mt-[22px] mb-4"></div>
              <div className="flex w-full gap-4">
                <div className="flex gap-2 text-3.5 font-jakarta font-medium text-[#6C727F]">
                  <div className="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-10 mb-4"></div>
                </div>
                <div className="flex gap-2 text-3.5 font-jakarta font-medium text-[#6C727F]">
                  <div className="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-10 mb-4"></div>
                </div>
                <div className="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-10 mb-4"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ListingCardSkeleton;
