import { useLocation } from "react-router-dom";
import UserSidebar from "./UserSidebar";
import AdminSidebar from "./AdminSidebar";
import Scrollbars from "react-custom-scrollbars";

interface IProps {
  open: boolean;
  changeOpen: Function;
  isLoggedIn: boolean;
  setAuthModalOpen?: Function;
  scrollbars?: any;
}
const Sidebar = ({
  open,
  changeOpen,
  isLoggedIn = false,
  setAuthModalOpen,
  scrollbars,
}: IProps) => {
  const location = useLocation();
  const isAdminPage =
    location?.pathname?.slice(0, 1) === "/"
      ? location.pathname.split("/")[1] === "admin"
      : location.pathname.split("/")[0] === "admin";

  return (
    <div className="relative">
      <div
        className={`border-r-1 border-[#E6E6E6] h-screen fixed top-0 bg-white transition-all duration-300 z-20 ${
          open
            ? `max-sm:w-0 sm:w-0 md:w-[30vw] lg:w-[25vw] xl:w-[20vw] `
            : `max-sm:w-[60vw] sm:w-[60vw] md:w-[8vw] lg:w-[5vw] xl:w-[5vw]`
        }`}
      >
        <Scrollbars hideTracksWhenNotNeeded autoHide className="min-h-screen">
          {isAdminPage && <AdminSidebar open={open} changeOpen={changeOpen} />}
          {!isAdminPage && (
            <UserSidebar
              scrollbars={scrollbars}
              isLoggedIn={isLoggedIn}
              setAuthModalOpen={setAuthModalOpen}
              open={open}
              changeOpen={changeOpen}
            />
          )}
        </Scrollbars>
      </div>
    </div>
  );
};

export default Sidebar;
