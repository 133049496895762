import Typography from "@/components/baseComponents/Typography"

type IProps = {
    data?:any
}

const Buyers = (props: IProps) => {

    return (
      <div>
        <Typography variant="h3" color="primary">
          Primary Buyer Details
        </Typography>
        <div className="flex w-full mt-[25px]">
          <div className="w-1/3 sm:w-full">
            <Typography variant="caption" color="secondary">
              First Name
            </Typography>
            <Typography variant="body" color="primary">
              {props.data?.client?.firstName || "-"}
            </Typography>
          </div>
          <div className="w-1/3 sm:w-full">
            <Typography variant="caption" color="secondary">
              Last Name
            </Typography>
            <Typography variant="body" color="primary">
              {props.data?.client?.lastName || "-"}
            </Typography>
          </div>
          <div className="w-1/3 sm:w-full">
            <Typography variant="caption" color="secondary">
              Company Name
            </Typography>
            <Typography variant="body" color="primary">
              {props.data?.client?.companyName || "-"}
            </Typography>
          </div>
        </div>
        <div className="flex w-full mt-[25px]">
          <div className="w-1/3 sm:w-full">
            <Typography variant="caption" color="secondary">
              Phone Number
            </Typography>
            <Typography variant="body" color="primary">
              {props.data?.client?.phoneNumber || "-"}
            </Typography>
          </div>
          <div className="w-1/3 sm:w-full">
            <Typography variant="caption" color="secondary">
              Email Address
            </Typography>
            <Typography variant="body" color="primary">
              {props.data?.client?.email || "-"}
            </Typography>
          </div>
          <div className="w-1/3 sm:w-full">
            <Typography variant="caption" color="secondary">
              Current Address
            </Typography>
            <div>
              {props.data?.address && props.data?.address.city && props.data?.address.state && props.data?.address.zipcode ? (
                <>
                  <div>
                    <Typography variant="body" color="primary" className="line-clamp-2">
                      {props.data?.address?.streetLine ? props.data?.address?.streetLine + " " : ""}
                      {props.data?.address?.secondary ? props.data?.address?.secondary + " " : ""}
                    </Typography>
                  </div>
                  <div>
                    <Typography variant="body" color="primary" className="line-clamp-1">
                      {props.data?.address?.city + ", " + props.data?.address?.state + " " + props.data?.address?.zipcode}
                    </Typography>
                  </div>
                </>
              ) : "-"}
            </div>
          </div>
        </div>
        <div className="flex w-full mt-[25px] mb-[50px]">
          <div className="w-1/3 sm:w-full">
            <Typography variant="caption" color="secondary">
              Mailing Address
            </Typography>
            <div>
              {props.data?.emailAddress && props.data?.emailAddress.city && props.data?.emailAddress.state && props.data?.emailAddress.zipcode ? (
                <>
                  <div>
                    <Typography variant="body" color="primary" className="line-clamp-2">
                      {props.data?.emailAddress?.streetLine ? props.data?.emailAddress?.streetLine + " " : ""}
                      {props.data?.emailAddress?.secondary ? props.data?.emailAddress?.secondary + " " : ""}
                    </Typography>
                  </div>
                  <div>
                    <Typography variant="body" color="primary" className="line-clamp-1">
                      {props.data?.emailAddress?.city + ", " + props.data?.emailAddress?.state + " " + props.data?.emailAddress?.zipcode}
                    </Typography>
                  </div>
                </>
              ) : "-"}
            </div>
          </div>
        </div>

        <Typography variant="h3" color="primary">
          Secondary Buyer Details
        </Typography>
        <div className="flex w-full mt-[25px]">
          <div className="w-1/3 sm:w-full">
            <Typography variant="caption" color="secondary">
              First Name
            </Typography>
            <Typography variant="body" color="primary">
              {props.data?.client?.secondaryFirstName || "-"}
            </Typography>
          </div>
          <div className="w-1/3 sm:w-full">
            <Typography variant="caption" color="secondary">
              Last Name
            </Typography>
            <Typography variant="body" color="primary">
              {props.data?.client?.secondaryLastName || "-"}
            </Typography>
          </div>
          <div className="w-1/3 sm:w-full">
            <Typography variant="caption" color="secondary">
              Phone Number
            </Typography>
            <Typography variant="body" color="primary">
              {props.data?.client?.secondaryPhoneNumber || "-"}
            </Typography>
          </div>
        </div>
        <div className="flex w-full mt-[25px] mb-[50px]">
          <div className="w-1/3 sm:w-full">
            <Typography variant="caption" color="secondary">
              Email Address
            </Typography>
            <Typography variant="body" color="primary">
              {props.data?.client?.secondaryEmailAddress || "-"}
            </Typography>
          </div>
        </div>
      </div>
    );
}

export default Buyers