import Typography from "@/components/baseComponents/Typography"
import PropertyCarousel from '@/components/mainComponents/PropertyCarousel';

type IProps = {
  data?: any
}

const Listing = (props: IProps) => {

  return (
    <div className="mb-[50px]">
      <div className="mb-[25px]">
        <PropertyCarousel data={props.data?.listing?.propertyPhotos} />
      </div>
      <Typography
        variant="h3"
        color="primary"
        className="mb-5"
      >
        Address
      </Typography>
      <div className="grid grid-cols-3 gap-16">
        <div className="col-span-1">
          <div className="flex flex-col gap-5">
            <div className="flex flex-col gap-1">
              <Typography variant="caption" color="secondary">
                Address 1
              </Typography>
              <Typography variant="body" color="primary">
                {props.data?.listing?.listingAddress1 ||
                  "None"}
              </Typography>
            </div>
            <div className="flex flex-col gap-1">
              <Typography variant="caption" color="secondary">
                Neighborhood
              </Typography>
              <Typography variant="body" color="primary">
                {props.data?.listing?.listingNeigborhood ||
                  "None"}
              </Typography>
            </div>
          </div>
        </div>
        <div className="col-span-1">
          <div className="flex flex-col gap-5">
            <div className="flex flex-col gap-1">
              <Typography variant="caption" color="secondary">
                Address 2 / Unit
              </Typography>
              <Typography variant="body" color="primary">
                {props.data?.listing?.listingAddress2 ||
                  "None"}
              </Typography>
            </div>
            <div className="flex flex-col gap-1">
              <Typography variant="caption" color="secondary">
                State
              </Typography>
              <Typography variant="body" color="primary">
                {props.data?.listing?.listingState ||
                  "None"}
              </Typography>
            </div>
          </div>
        </div>
        <div className="col-span-1">
          <div className="flex flex-col gap-5">
            <div className="flex flex-col gap-1">
              <Typography variant="caption" color="secondary">
                City
              </Typography>
              <Typography variant="body" color="primary">
                {props.data?.listing?.listingCity ||
                  "None"}
              </Typography>
            </div>
            <div className="flex flex-col gap-1">
              <Typography variant="caption" color="secondary">
                Zip Code
              </Typography>
              <Typography variant="body" color="primary">
                {props.data?.listing?.listingZipcode ||
                  "None"}
              </Typography>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Listing