import classNames from "classnames";
import DefaultProfilePicture from "@/assets/images/default_profile_picture.webp";
import IconWrapper from "@/components/baseComponents/IconWrapper";

// ----------------------------------------------------------------------
type Props = {
  className?: string;
  src?: string;
};

const Avatar = ({ className, src }: Props) => {
  if (src) {
    return (
      <img
        src={src}
        alt="User Avatar"
        className={classNames(
          "md:w-10 sm:w-[36px]  max-sm:w-[36px] rounded-full",
          className
        )}
        onError={(e) => e.currentTarget.src = DefaultProfilePicture}
      />
    );
  }

  return <IconWrapper name="user" width={40} height={40} />;
};

export default Avatar;
