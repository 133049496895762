import { useState } from "react";
import IconWrapper from "@/components/baseComponents/IconWrapper";
import Textarea from "@/components/baseComponents/Textarea";
import { IParticipantDto } from "@/shared/interfaces/interfaces";

type IProps = {
  content: {
    content?: string;
    set?: React.Dispatch<React.SetStateAction<string>>;
  };
  participants?: IParticipantDto[];
  sendChatMessage: Function;
  isSelected?: boolean;
  setIsTyping?: React.Dispatch<React.SetStateAction<boolean>>;
};

const ChatInputBox = (props: IProps) => {
  const [rows, setRows] = useState(1);
  const { content, sendChatMessage } = props;

  const handleClickSendBtn = () => {
    if (content.content && content.content !== "") {
      content.set && content.set("");
      setRows(1);
      sendChatMessage(content.content);
    }
  };

  const handleKeyDown = (event: any) => {
    if (event.key === "Enter") {
      event.preventDefault();
      handleClickSendBtn();
    }
  };

  const handleChange = (event: any) => {
    content.set && content.set(event.target.value);

    // is typing validation check not working

    // to detect typing event
    // if (event.target.value.split("").length > 0) {
    //   setIsTyping(true);
    // }

    const textareaLineHeight = 24; // Adjust this value based on your textarea's line height
    const previousRows = event.target.rows;
    event.target.rows = 1; // Reset the number of rows to 1 to calculate the new height
    const currentRows = Math.min(
      Math.ceil((event.target.scrollHeight - 16) / textareaLineHeight),
      3
    );
    if (currentRows === previousRows) {
      event.target.rows = currentRows;
    }
    setRows(currentRows);
  };

  return (
    <div className="flex rounded-8 bg-[#F1F5F9] w-full items-center gap-2 px-4 py-3">
      <Textarea
        className="flex"
        textareaClassName="p-0 text-[14px]"
        rows={rows}
        value={content.content}
        onChange={handleChange}
        onKeyDown={handleKeyDown}
        placeholder="Write a message"
        style={{ resize: "none" }}
      />
      <div className="flex gap-3 items-center">
        <div
        // onClick={() => handleClickSendBtn()}
        >
          <IconWrapper
            width={20}
            height={20}
            name="file"
            className="cursor-pointer"
          />
        </div>
        <div onClick={() => handleClickSendBtn()}>
          <IconWrapper
            width={20}
            height={20}
            name="message-send"
            className="cursor-pointer"
          />
        </div>
      </div>
    </div>
  );
};

export default ChatInputBox;
