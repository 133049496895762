import { useNavigate } from "react-router-dom";
import { Button } from "../../../components/baseComponents/Button";
import IconWrapper from "../../../components/baseComponents/IconWrapper";
import Typography from "../../../components/baseComponents/Typography";
import format from "date-fns/format";

export type Showing<T = Date> = {
  _id: string;
  dateTime: T;
  fullName: string;
  fullAddress: string;
};

const Showing = (showing: Showing) => {
  return (
    <div className="flex flex-row gap-x-2">
      <div className="bg-indigo-50 px-2 py-4 text-center rounded-[4px]">
        <div className="font-semibold">{format(showing.dateTime, "dd")}</div>
        <div>{format(showing.dateTime, "MMM")}</div>
      </div>
      <div className="flex-1 flex flex-col overflow-hidden">
        <div className="flex-1 font-semibold">{showing.fullName}</div>
        <div className="flex-1">{format(showing.dateTime, "hh:mm aa")}</div>
        <div className="bg-gray-100 w-full rounded-full px-3 pt-1 pb-0.5 text-ellipsis overflow-hidden whitespace-nowrap">{showing.fullAddress}</div>
      </div>
    </div>
  );
};

const UpcomingShowings = ({ showings }: { showings: Showing[] }) => {
  const navigate = useNavigate();
  const color = {
    smoke150: "#C1C9D5",
    smoke50: "#EFF1F5"
  } as const;

  return (
    <div className="flex flex-col bg-white p-3 rounded-lg gap-4 shadow-sm">
      <Typography variant="h4" className="mb-1">
        Upcoming Showings
      </Typography>
      {showings.length ? (
        <div>
          <div className="flex flex-col gap-4">
            {showings.map((item) => (
              <Showing key={item._id} {...item} />
            ))}
          </div>
          <div className="mt-4 text-button-primary cursor-pointer" onClick={() => navigate("/showings")}>
            View All
          </div>
        </div>
      ) : (
        <div className="flex flex-col gap-3">
          <div className={`inline-block p-4 self-center rounded-full mt-3 border-[${color.smoke50}] bg-gradient-to-b from-[#ECEFF3] via-[#ECEFF319] to-[#ECEFF300]`}>
            <div className={`p-4 bg-white border border-[${color.smoke50}] rounded-full shadow-[0_1px_2px_0_rgba(16,24,40,0.04)]`}>
              <IconWrapper name="calendar" stroke={color.smoke150} />
            </div>
          </div>
          <div className="text-[#A6B0C0] font-semibold text-center">No upcoming showings scheduled</div>
          <Button className="bg-white border-1 border-gray-300 self-center w-[232px] mb-6" color="inherit" onClick={() => navigate("/listings")}>
            Search Listings
          </Button>
        </div>
      )}
    </div>
  );
};

export default UpcomingShowings;
