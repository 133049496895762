type Props = {
  width: number;
  height: number;
  stroke?: string;
  className?: string;
};

const IconTransactionNote = (props: Props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 17 17"
      fill="none"
      {...props}
    >
      <rect
        x="0.632812"
        y="0.314941"
        width="15.7875"
        height="15.7875"
        rx="2.63125"
        fill="#468189"
      />
      <path
        d="M7.2116 3.47266V5.57766C7.2116 5.71723 7.15616 5.85108 7.05747 5.94977C6.95877 6.04846 6.82492 6.10391 6.68535 6.10391H4.58035M11.9479 3.99891V12.4189C11.9523 12.5537 11.9031 12.6848 11.811 12.7834C11.719 12.8821 11.5917 12.9402 11.4569 12.9452H5.07134C4.93654 12.9402 4.80918 12.8821 4.71716 12.7834C4.62514 12.6848 4.57594 12.5537 4.58035 12.4189V6.01339C4.58041 5.73427 4.69134 5.46661 4.88873 5.26927L6.37697 3.78104C6.5743 3.58364 6.84197 3.47272 7.12109 3.47266H11.4569C11.5917 3.4776 11.719 3.53575 11.811 3.63438C11.9031 3.73301 11.9523 3.86408 11.9479 3.99891Z"
        stroke="white"
        strokeWidth="1.0525"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default IconTransactionNote;
