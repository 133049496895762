// Import necessary modules and components
import { useLocation } from "react-router-dom";
import DashboardSimple from "./DashboardSimple";
import Events from "./Events";

// Define the Dashboard component
const Dashboard = () => {
  // Use the useLocation hook to get the current location
  const location = useLocation();
  const curPage = location.pathname.split("/")[1];

  // Render the component
  return (
    <div className="pb-[25vh]">
      {curPage === "dashboard" || curPage === "" ? (
        <Events />
      ) : (
        <DashboardSimple />
      )}
    </div>
  );
};

// Export the Dashboard component
export default Dashboard;
