import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import type { RootState } from '../store'
import axios from '@/shared/services/axios'
import { IVendor, CreateVendorDto, UpdateVendorDto, DeleteVendorsDto, GetVendorsDto, SearchVendorsDto } from '@/shared/interfaces/interfaces'

interface VendorState {
  vendors: Array<IVendor>
}

const initialState: VendorState = {
  vendors: [],
};

export const vendorSlice = createSlice({
  name: 'vendors',
  initialState,
  reducers: {
    setTest: (state, action: PayloadAction<any>) => {
      state.vendors = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(createNewVendor.fulfilled, (state, action) => {
      if(action.payload.success){
        state.vendors = action.payload.vendors;
      }
    });
    builder.addCase(getVendorsByUserId.fulfilled, (state, action) => {
      if(action.payload.success){
        state.vendors = action.payload.vendors;
      }
    });
    builder.addCase(updateVendor.fulfilled, (state, action) => {
      if(action.payload.success){
        state.vendors = action.payload.vendors;
      }
    });
    builder.addCase(deleteVendors.fulfilled, (state, action) => {
      if(action.payload.success){
        state.vendors = action.payload.vendors;
      }
    });
  },
});

export const vendorsAction = vendorSlice.actions;

// Other code such as selectors can use the imported `RootState` type
export const getVendors = (state: RootState) => 
  state.vendor.vendors;

export default vendorSlice.reducer;

export const createNewVendor = createAsyncThunk(
  'vendor/create',
  async (data: CreateVendorDto) => {
    const response = await axios.post('vendor/create', data);
    return response.data;
  }
);

export const updateVendor = createAsyncThunk(
  'vendor/update',
  async (data: UpdateVendorDto) => {
    const response = await axios.put('vendor/update', data);
    return response.data;
  }
);

export const getVendorsByUserId = createAsyncThunk(
  'vendor/get',
  async (data: GetVendorsDto) => {
    const response = await axios.post('vendor/get', data);
    return response.data;
  }
);

export const advancedSearchVendors = createAsyncThunk(
  'vendor/search',
  async (data: SearchVendorsDto) => {
    const response = await axios.post('vendor/search', data);
    return response.data;
  }
);

export const deleteVendors = createAsyncThunk(
  'vendor/delete',
  async (data: DeleteVendorsDto) => {
    const response = await axios.post('vendor/delete', data);
    return response.data;
  }
);
