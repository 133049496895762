import { Fragment } from "react";
import { useNavigate } from "react-router-dom";
import Scrollbars from "react-custom-scrollbars";
import { getUser } from "@/redux/user/userSlice";
import { useAppSelector } from "@/redux/hooks";
import {
  updateViewedStatus,
  deleteNotification,
} from "@/shared/services/notificationService";
import Typography from "@/components/baseComponents/Typography";
import { Dialog, Transition } from "@headlessui/react";
import XMark from "@/assets/icons/XMark.png";

interface IProps {
  open: boolean;
  notifications: Array<any>;
  closeModal: Function;
}

const NotificationDrawer = (props: IProps) => {
  const user = useAppSelector(getUser);
  const navigate = useNavigate();

  const handleClickNotification = async (notification: any) => {
    if (notification._id && !notification.viewed) {
      await updateViewedStatus(notification._id, true);
    }

    switch (notification.objectType) {
      case "showing":
        navigate("/showings", { state: { id: notification.objectId } });
        break;
      case "listing":
        navigate("/listings", { state: { id: notification.objectId } });
        break;
      case "offer":
        break;
    }

    props.closeModal && props.closeModal();
  };

  const handleRemoveNotification = async (
    event: React.MouseEvent<HTMLImageElement, MouseEvent>,
    notificationId: string
  ) => {
    event.stopPropagation();
    if (notificationId) {
      await deleteNotification(notificationId, user._id ?? "");
    }
  };

  return (
    <Transition.Root show={props.open} as={Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 z-30"
        onClose={() => props.closeModal()}
      >
        <div className="fixed inset-0" />
        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-500 sm:duration-300"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-500 sm:duration-300"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <Dialog.Panel className="pointer-events-auto w-screen max-w-[600px]">
                  <div className="flex h-full flex-col overflow-hidden bg-white pt-6 shadow-xl max-sm:pb-14">
                    <div className="px-8 pb-2">
                      <div className="flex items-start justify-between">
                        <div className="flex h-3 items-center">
                          <Typography variant="h2" className="mt-[8px]">
                            Notifications
                          </Typography>
                        </div>
                        <div className="flex h-3 items-center cursor-pointer">
                          <img
                            src={XMark}
                            alt="XMark"
                            onClick={() => props.closeModal()}
                            width={14}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="border-b mt-5"></div>
                    <Scrollbars autoHide>
                      <div className="px-4 sm:px-[2.5rem] py-[50px]">
                        {props.notifications &&
                          props.notifications.length > 0 &&
                          props.notifications.map(
                            (notification: any, index: number) => (
                              <div
                                className="p-4 cursor-pointer hover:bg-gray-200 rounded-md"
                                key={index}
                                onClick={() =>
                                  handleClickNotification(notification)
                                }
                              >
                                <div className="flex justify-between mb-[10px]">
                                  <div className="flex items-center gap-4">
                                    <Typography variant="h3">
                                      {notification.title ?? ""}
                                    </Typography>
                                    {!notification.viewed && (
                                      <Typography
                                        variant="caption"
                                        className="w-10 rounded-md text-white text-center bg-red-500"
                                      >
                                        {"New"}
                                      </Typography>
                                    )}
                                  </div>
                                  <div className="flex gap-4">
                                    <Typography
                                      variant="caption"
                                      className="pr-0"
                                    >
                                      {new Date(
                                        notification.createdAt
                                      ).toLocaleDateString() +
                                        " " +
                                        new Date(
                                          notification.createdAt
                                        ).toLocaleTimeString([], {
                                          hour: "numeric",
                                          minute: "2-digit",
                                        })}
                                    </Typography>
                                    <div className="flex h-3 items-center cursor-pointer">
                                      <img
                                        src={XMark}
                                        alt="XMark"
                                        onClick={(event) =>
                                          handleRemoveNotification(
                                            event,
                                            notification._id
                                          )
                                        }
                                        width={12}
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div>
                                  <Typography variant="caption">
                                    {notification.description ?? ""}
                                  </Typography>
                                </div>
                              </div>
                            )
                          )}
                      </div>
                    </Scrollbars>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};
export default NotificationDrawer;
