type Props = {
    width: number;
    height: number;
    stroke?: string;
    className?: string;
};

const IconCreditCard = (props: Props) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25 24" fill="none" {...props}>
            <path
                d="M22.5 10H2.5M11.5 14H6.5M2.5 8.2L2.5 15.8C2.5 16.9201 2.5 17.4802 2.71799 17.908C2.90973 18.2843 3.21569 18.5903 3.59202 18.782C4.01984 19 4.57989 19 5.7 19L19.3 19C20.4201 19 20.9802 19 21.408 18.782C21.7843 18.5903 22.0903 18.2843 22.282 17.908C22.5 17.4802 22.5 16.9201 22.5 15.8V8.2C22.5 7.0799 22.5 6.51984 22.282 6.09202C22.0903 5.7157 21.7843 5.40974 21.408 5.21799C20.9802 5 20.4201 5 19.3 5L5.7 5C4.5799 5 4.01984 5 3.59202 5.21799C3.2157 5.40973 2.90973 5.71569 2.71799 6.09202C2.5 6.51984 2.5 7.07989 2.5 8.2Z"
                stroke={props.stroke ?? "#4338CA"}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};

export default IconCreditCard;
