type Props = {
  width: number;
  height: number;
  stroke?: string;
  className?: string;
  innerstroke?: string;
};

const IconArchive = (props: Props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 16 16"
      fill="none"
      {...props}
    >
      <path
        d="M14 2H1.99998C1.63179 2 1.33331 2.29848 1.33331 2.66667V4.66667C1.33331 5.03486 1.63179 5.33333 1.99998 5.33333H14C14.3682 5.33333 14.6666 5.03486 14.6666 4.66667V2.66667C14.6666 2.29848 14.3682 2 14 2Z"
        stroke={props.innerstroke ? props.innerstroke : "#DC2626"}
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2.66669 5.33203V12.6654C2.66669 13.019 2.80716 13.3581 3.05721 13.6082C3.30726 13.8582 3.6464 13.9987 4.00002 13.9987H12C12.3536 13.9987 12.6928 13.8582 12.9428 13.6082C13.1929 13.3581 13.3334 13.019 13.3334 12.6654V5.33203"
        stroke={props.innerstroke ? props.innerstroke : "#DC2626"}
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.66669 8H9.33335"
        stroke={props.innerstroke ? props.innerstroke : "#DC2626"}
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default IconArchive;
