type Props = {
  width: number;
  height: number;
};

const IconBookmarkMinus = (props: Props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 18 24"
      fill="none"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.00803e-07 23.25V3C2.00803e-07 2.20435 0.316071 1.44129 0.87868 0.87868C1.44129 0.316071 2.20435 0 3 0L15 0C15.7956 0 16.5587 0.316071 17.1213 0.87868C17.6839 1.44129 18 2.20435 18 3V23.25C18.0001 23.3802 17.9663 23.5083 17.9019 23.6215C17.8375 23.7347 17.7447 23.8291 17.6327 23.8956C17.5207 23.962 17.3933 23.9982 17.263 24.0004C17.1328 24.0027 17.0043 23.971 16.89 23.9085L9 19.6035L1.11 23.9085C0.995744 23.971 0.867178 24.0027 0.736957 24.0004C0.606737 23.9982 0.47935 23.962 0.367338 23.8956C0.255325 23.8291 0.162547 23.7347 0.0981369 23.6215C0.0337268 23.5083 -9.50907e-05 23.3802 2.00803e-07 23.25ZM6 9C5.80109 9 5.61032 9.07902 5.46967 9.21967C5.32902 9.36032 5.25 9.55109 5.25 9.75C5.25 9.94891 5.32902 10.1397 5.46967 10.2803C5.61032 10.421 5.80109 10.5 6 10.5H12C12.1989 10.5 12.3897 10.421 12.5303 10.2803C12.671 10.1397 12.75 9.94891 12.75 9.75C12.75 9.55109 12.671 9.36032 12.5303 9.21967C12.3897 9.07902 12.1989 9 12 9H6Z"
        fill="#8E9CB2"
      />
    </svg>
  );
};

export default IconBookmarkMinus;
