import React, { useEffect, useLayoutEffect, useRef } from 'react'
import { Link, useOutletContext, useParams } from 'react-router-dom'
import { Button } from '@/components/baseComponents/Button'
import Typography from '@/components/baseComponents/Typography'
import IconWrapper from "@/components/baseComponents/IconWrapper"
import PropertyCarousel from "@/components/mainComponents/PropertyCarousel"
import DefaultCompanyLogo from '@/assets/images/default_company_logo.webp'
import DefaultProfilePicture from '@/assets/images/default_profile_picture.webp'
import { useAppSelector, useAppDispatch } from '@/redux/hooks';
import { getListingById } from '@/redux/myListings/myListingSlice';
import 'react-calendar/dist/Calendar.css';
import Tooltip from '@/components/mainComponents/ToolTip';
import { IoKeyOutline } from "react-icons/io5";
import { IconType } from '@/components/baseComponents/IconWrapper/Icons';
import { ShowingDrawer } from './ScheduleDrawer'
import AuthDrawer from './AuthDrawer'
import { getUser } from '@/redux/user/userSlice'
import { Cookies } from 'react-cookie'
import { GetByIdDto, IMyListing } from '@/shared/interfaces/interfaces';
import { truncateText } from '@/shared/config/constants';
import ChatViewDrawer from '@/components/layouts/bars/Components/ChatViewDrawer'
import ChatProfileDrawer from './ChatProfileDrawer'

interface PropertyItem {
    icon: IconType;
    title: string;
    value: number | string | boolean;
}

interface PropertyItem {
    icon: IconType;
    title: string;
    value: number | string | boolean;
}

const PublicListing = () => {
    const { id } = useParams()
    const cookies = new Cookies();
    const params = useParams();
    const dispatch = useAppDispatch()
    const user = useAppSelector(getUser);
    const [listings, setListings] = React.useState<IMyListing[]>([])
    const listing: any = listings ? listings[0] : {}
    const targetRef = useRef<HTMLDivElement>(null);
    const [date, setDate] = React.useState<Date>(new Date());
    const [showScheduleListing, setShowScheduleListing] = React.useState<boolean>(false)
    const [selectedImgIndex, setSelectedImgIndex] = React.useState<number>(0);
    const [openAuthDrawer, setOpenAuthDrawer] = React.useState<boolean>(false)
    const [listingId, setListingId] = React.useState<string | undefined>("" || params.id)
    const [isMobile, setIsMobile] = React.useState<boolean>(false)
    const [listingDate, setListingDate] = React.useState<Date>()
    // const [dayName, setDayName] = React.useState<string>()
    const [width, setWidth] = React.useState(0)
    const [openChat, setOpenChat] = React.useState<boolean>(false)
    const [openProfileChat, setOpenProfileChat] = React.useState<boolean>(false)
    const [isAuthModalOpenFromSidebar, setAuthModalOpenFromSidebar]: any = useOutletContext();

    const handleProfileClose = () => {
        setOpenProfileChat(false);
        setOpenChat(true)
    }

    useEffect(() => {
        dispatch(getListingById({ id: id ?? "" })).then((data) => {
            setListings(data.payload.data)
        })
    }, [dispatch]);

    useEffect(() => {
        const data: GetByIdDto = {
            id: id ?? '',
            status: 'Active', // get only active listing info
        }

        dispatch(getListingById(data))
    }, [])

    const propertyDesignItems: PropertyItem[] = [
        {
            title: 'Bedrooms',
            icon: 'bed',
            value: listing?.propertyBedrooms ?? 'None'
        },
        {
            title: 'Bathrooms',
            icon: 'bath',
            value: listing?.propertyBathrooms ?? 'None'
        }, {
            title: 'Home SqFt',
            icon: 'house-space',
            value: listing?.propertyHomeSqft ?? 'None'
        },
        {
            title: 'Lot SqFt',
            icon: 'lot',
            value: listing?.propertyLotSqft ?? 'None'
        },
        {
            title: 'Parking',
            icon: 'parking',
            value: listing?.propertyParking ?? 'No'
        },
        {
            title: 'Garage',
            icon: 'garage',
            value: listing?.propertyGarage ?? 'No'
        },
        {
            title: 'Heating',
            icon: 'heating',
            value: listing?.propertyHeating ?? 'No'
        },
        {
            title: 'Cooling',
            icon: 'cool',
            value: listing?.propertyCooling ?? 'No'
        },
        {
            title: 'Pool',
            icon: 'pool',
            value: listing?.propertyPool ?? 'No'
        }
    ]

    const propertyDesignItems2 = [
        {
            title: 'Units',
            value: listing?.propertyNumberOfUnits ?? 0
        },
        {
            title: 'Stories',
            value: listing?.propertyStories ?? 0
        },
        {
            title: 'Is Attached',
            value: listing?.propertyAttached ?? "No"
        },
        {
            title: 'Is New Construction',
            value: listing?.propertyAttached ?? "No"
        }
    ]

    const updateDimensions = () => {
        if (targetRef.current) {
            const { width } = targetRef.current.getBoundingClientRect();
            setWidth(width);
            if (width < 640) {
                setIsMobile(true)
            } else {
                setIsMobile(false)
            }
        }
    };

    useLayoutEffect(() => {
        updateDimensions();
        const handleResize = () => {
            updateDimensions();
        };
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize)
        }
    }, [])

    useEffect(() => {
        if (listingId && listingId !== "") {
            cookies.set("lastViewedPublicListingId", listingId, { path: "/", expires: new Date(Date.now() + 24 * 60 * 60 * 1000) })
        }
    }, [])

    useEffect(() => {
        if (listing) {
            getDay();
        }
    }, [listing])

    useEffect(() => {
        if (listingDate) {
            // setDateName(listingDate.toDateString(), 'en-US');
        }
    }, [listingDate])

    const getDay = () => {
        const date = listing?.listingDateListed;
        if (date) {
            setListingDate(new Date(date))
        }

    }

    // function setDateName(dateStr: string, locale: string) {
    //     var date = new Date(dateStr);
    //     setDayName(date.toLocaleDateString(locale, { weekday: 'long' }));
    // }

    // const getHour = () => {
    //     if (listingDate) {
    //         let hour = listingDate.getHours();

    //         if (hour > 12) {
    //             return hour - 12 + ":" + listingDate?.getMinutes() + " PM";
    //         }
    //         return hour + ":" + listingDate?.getMinutes() + "AM"
    //     }
    //     return ""
    // }
console.log(listing);
    useEffect(() => {
        setOpenAuthDrawer(isAuthModalOpenFromSidebar)
    }, [isAuthModalOpenFromSidebar]);

    useEffect(() => {
        if (!openAuthDrawer) {
            setAuthModalOpenFromSidebar(false);
        }
    }, [openAuthDrawer])

    return (
        <div ref={targetRef}>
            {openChat && user && (
                <div className="!bg-[#00000075] h-screen w-full fixed top-0 left-0 z-20"></div>
            )}
            <ShowingDrawer
                closeDrawer={() => setShowScheduleListing(!showScheduleListing)}
                listing={listing}
                open={showScheduleListing}
                date={{ date, setDate }}
            />
            <AuthDrawer
                open={openAuthDrawer}
                closeDrawer={() => setOpenAuthDrawer(!openAuthDrawer)}
                listingId={listingId}
                type={"signup"}
            />
            {user &&
                <>
                    <ChatViewDrawer open={openChat} changeState={setOpenChat} />
                    <ChatProfileDrawer
                        open={openProfileChat}
                        openDrawer={setOpenProfileChat}
                        listing={listing}
                        curParticipantId={listing?.owner?.agent?._id}
                        handleProfileClose={handleProfileClose}
                    />
                </>
            }
            <div className="container max-w-screen-xl mx-auto ">
                <div className="p-10 p-5 lg:pb-24 md:pb-60 sm:pb-60 max-sm:pb-60">
                    <div className={`flex gap-4 sm:my-0 my-2 justify-between ${width > 900 ? 'flex-row' : 'flex-col'}`}>
                        <div className="flex flex-col">
                            <Typography className='text-[28px] font-bold color-primary font-arial leading-[48px]'>{listing?.listingAddress.streetLine + " " + (listing?.listingUnit || listing?.listingAddress.secondary)}</Typography>
                            <Typography className='text-[#6B7280] text-[16px]' >{listing?.listingAddress.city}, {listing?.listingAddress.state} {listing?.listingAddress.zipcode}</Typography>
                        </div>
                        <div className="flex gap-3 ">
                            <div className="bg-[#0F766E] px-1 justify-start flex-col md:flex-row md:justify-between md:w-full md:h-10 h-full w-full sm:justify-start sm:items-start flex gap-1.5 py-1 rounded-lg items-start md:items-center text-white">
                                <div className="flex gap-2 ml-1 items-start md:items-center flex-col md:flex-row">
                                    <IoKeyOutline color="white" />
                                    <Typography className='text-sm' color='white'>RealtyView is intended for Licensed Real Estate Professionals</Typography>
                                </div>
                                <Link to={'https://realtyview.com/'} target='_blank'>
                                    <div color="inherit" className='flex text-[14px] items-center border-1 bg-white text-primary h-full rounded-lg px-2.5 py-1'>Learn More</div>
                                </Link>
                            </div>
                        </div>
                    </div>
                    <div className='flex pt-10 pb-10'>
                        <div className='xl:w-2/3 h-full w-full'>
                            <div className='hidden'>{selectedImgIndex}</div>
                            <PropertyCarousel isCenter={true} className='h-[387px]' centerPadding={isMobile ? '0%' : '10%'} slidesToShow={1} data={listing?.propertyPhotos} onSelect={setSelectedImgIndex} />
                            <div className="flex flex-col w-full py-8 gap-6 xl:hidden">
                                <div className="flex w-full flex-col p-6 gap-6 bg-white shadow rounded-xl w-full bg-[#F0F9FF] rounded-[5px] border border-1 border-[#E5E7EB]">
                                    {/* WITH BUG - TOBE CHECKED */}
                                    {/* <Typography color="primary" className="text-base">
                                        The next available showing time is&nbsp;
                                        <span className="font-bold">{dayName}, {Months[date?.getMonth()]} {date?.getDay()}th</span>&nbsp;at&nbsp;
                                        <span className="font-bold">{getHour()}.</span>&nbsp;
                                        This listings’ status is&nbsp;
                                        <span className="font-bold">{listing?.status}.</span>
                                    </Typography> */}
                                    <div className="flex flex-col md:flex-row gap-4">
                                        <Button variant="text" className='md:w-full disabled:bg-[#6366F1] disabled:opacity-80 bg-button-primary hover:bg-[#6366F1] border-[#6366F1] hover:border-[#818CF8] text-white' onClick={() => user ? setShowScheduleListing(true) : setOpenAuthDrawer(true)}>
                                            <div className="flex items-center w-full justify-center gap-3">
                                                <IconWrapper name="door-open" />
                                                <Typography variant="button1" className="flex md:w-full w-[170px] justify-start">Schedule a Showing</Typography>
                                            </div>
                                        </Button>
                                        <Button variant="text" className="md:w-full disabled:opacity-80 justify-start border-[#22C55E] hover:bg-button-success-hover text-white hover:text-white border-[1px]"
                                            onClick={() => {
                                                // user ? setOpenSubmitDrawer(true) : onOpenAuthModal('Submit an Offer')
                                            }}
                                            disabled={true}>
                                            <Tooltip className='relative w-full' text="Coming Soon">
                                                <div className="flex items-center w-full justify-center gap-3">
                                                    <IconWrapper name="submit-offer" />
                                                    <Typography variant="button1" className="md:w-full w-[170px] flex justify-start">Submit an Offer</Typography>
                                                </div>
                                            </Tooltip>
                                        </Button>
                                        <Button variant='text' className='md:w-full bg-[#EEF2FF] border-[#C7D2FE] hover:bg-[#E0E7FF] hover:border-[#4C42D7] disabled:border-[#D1D5DB] disabled:opacity-80 flex'>
                                            <div className="flex items-center justify-center w-full gap-3">
                                                <IconWrapper name="chat-blue" />
                                                <Typography variant="button1" className="md:w-full w-[180px] max-w-[200px] text-[#4C42D7]" onClick={() => { setListingId(listing?.id); if (!user) { setOpenAuthDrawer(true) } if (user) { setOpenProfileChat(true) } }}>
                                                    Message {truncateText((listing?.owner?.agent?.firstName || "Agent") + " " + (listing?.owner?.agent?.lastName || ""), 30)}</Typography>
                                            </div>
                                        </Button>
                                    </div>
                                </div>
                                <div className="flex sm:flex-row flex-col w-full justify-between px-3 py-4 gap-2 bg-[#F0F9FF] rounded-[5px] border border-1 border-[#E5E7EB]">
                                    <div className="flex w-full gap-2 items-center">
                                        <div className="flex">
                                            <img
                                                className="w-9 h-9 object-cover rounded aspect-square"
                                                src={listing?.owner?.agent?.company?.businessLogo || DefaultCompanyLogo}
                                                onError={(e) => e.currentTarget.src = DefaultCompanyLogo}
                                            />
                                        </div>
                                        <div className="flex flex-col">
                                            <Typography variant="h4" color="primary" className="text-[14px] line-clamp-1 max-w-[400px]">{listing?.owner?.agent?.company?.businessName || "Company"}</Typography>
                                            <Typography variant="medium-text" color="secondary" className="flex items-center gap-2">{listing?.owner?.agent?.company?.businessAddress1 || "Address"} </Typography>
                                        </div>
                                    </div>
                                    <div className="flex flex-col ">
                                        <div className="flex items-center gap-2">
                                            <IconWrapper name="telephone" width={13} />
                                            <Typography variant="medium-text" color="secondary" className="flex items-center gap-2">{listing?.owner?.agent?.company?.principalBrokerPhone || "None"} </Typography>
                                        </div>
                                        <div className="flex items-center gap-2">
                                            <IconWrapper name="envelope" width={13} />
                                            <Typography variant="medium-text" color="secondary" className="flex items-center gap-2 truncate ">{listing?.owner?.agent?.contactEmail || "None"}</Typography>
                                        </div>
                                    </div>
                                </div>
                                <div className="flex sm:flex-row flex-col w-full justify-between px-3 py-4 gap-2 bg-[#EEF2FF] rounded-[5px] border border-1 border-[#E5E7EB]">
                                    <div className="flex w-full  gap-2 items-center">
                                        <div className="flex">
                                            <img
                                                className="w-9 h-9 object-cover rounded aspect-square"
                                                src={listing?.owner?.agent?.avatarURL || DefaultProfilePicture}
                                                onError={(e) => e.currentTarget.src = DefaultProfilePicture}
                                            />
                                        </div>
                                        <div className="flex flex-col">
                                            <Typography variant="h4" color="primary" className="text-[14px] line-clamp-1 max-w-[400px]">{(listing?.owner?.agent?.firstName || "Agent") + " " + (listing?.owner?.agent?.lastName || "")}</Typography>
                                            <Typography variant="medium-text" color="secondary" className="flex items-center gap-2">{listing?.owner?.agent?.licenseType || "Address"} </Typography>
                                        </div>
                                    </div>
                                    <div className="flex flex-col">
                                        <div className="flex items-center gap-2">
                                            <IconWrapper name="telephone" width={13} />
                                            <Typography variant="medium-text" color="secondary" className="flex items-center gap-2 truncate">{listing?.owner?.agent?.mobileNumber || "None"}</Typography>
                                        </div>
                                        <div className="flex items-center gap-2">
                                            <IconWrapper name="envelope" width={13} />
                                            <Typography variant="medium-text" color="secondary" className="flex items-center gap-2 truncate ">{listing?.owner?.agent?.contactEmail || "None"}</Typography>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="flex flex-col w-full mt-8 p-3 rounded-xl bg-white">
                                <div className='flex flex-col w-full gap-[18px] py-3'>
                                    <Typography variant='h3' color='primary'>Address</Typography>
                                    <div className='grid grid-cols-2 md:grid-cols-4 w-full gap-4 px-3'>
                                        {listing?.listingAddress.streetLine && (
                                            <div className='mr-4'>
                                                <Typography variant='caption' color='secondary'>Address</Typography>
                                                <Typography variant='body' color='primary'>{listing?.listingAddress.streetLine + " " + (listing?.listingUnit || listing?.listingAddress.secondary)}</Typography>
                                            </div>
                                        )}
                                        {listing?.listingAddress.city && (
                                            <div className='mr-4'>
                                                <Typography variant='caption' color='secondary'>City</Typography>
                                                <Typography variant='body' color='primary'>{listing?.listingAddress.city}</Typography>
                                            </div>
                                        )}
                                        {listing?.listingNeigborhood && listing?.listingNeigborhood !== '' && (
                                            <div className='mr-4'>
                                                <Typography variant='caption' color='secondary'>Neighborhood</Typography>
                                                <Typography variant='body' color='primary'>{listing?.listingNeigborhood}</Typography>
                                            </div>
                                        )}
                                        {listing?.listingAddress.state && (
                                            <div className='mr-4'>
                                                <Typography variant='caption' color='secondary'>State</Typography>
                                                <Typography variant='body' color='primary'>{listing?.listingAddress.state}</Typography>
                                            </div>
                                        )}
                                        {listing?.listingAddress.zipcode && (
                                            <div className='mr-4'>
                                                <Typography variant='caption' color='secondary'>Zip Code</Typography>
                                                <Typography variant='body' color='primary'>{listing?.listingAddress.zipcode}</Typography>
                                            </div>
                                        )}
                                    </div>
                                </div>
                                <div className="flex h-0.5 w-full bg-gray-listing?.propertyBathrooms ?? 'None' my-2"></div>
                                <div className="flex flex-col py-3 w-full">
                                    <Typography variant='h3' color='primary'>Property Details</Typography>
                                    <div className="flex w-full py-2">
                                        <div className="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1  gap-4 w-full">
                                            <div className="flex items-center py-2.5 px-3 gap-2">
                                                <div className="flex p-2 rounded-full bg-[#F3F4F6]">
                                                    <IconWrapper className='w-5 h-5' name="home" />
                                                </div>
                                                <div className="flex flex-col">
                                                    <Typography className='medium-text' color='secondary'>Property Type</Typography>
                                                    <Typography className='text-sm' color='primary'>{listing?.propertyType ?? 'None'}</Typography>
                                                </div>
                                            </div>
                                            <div className="flex items-center py-2.5 px-3 gap-2">
                                                <div className="flex p-2 rounded-full bg-[#F3F4F6]">
                                                    <IconWrapper className='w-5 h-5' name="home" />
                                                </div>
                                                <div className="flex flex-col">
                                                    <Typography className='medium-text' color='secondary'>Property Sub-Type</Typography>
                                                    <Typography className='text-sm' color='primary'>Single Family Residence</Typography>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="flex w-full">
                                        <div className="grid lg:grid-cols-3 grid-cols-2 gap-4 w-full">
                                            {
                                                propertyDesignItems.map((property: PropertyItem, i: number) =>
                                                    <div className="flex items-center py-2.5 px-3 gap-2" key={i}>
                                                        <div className="flex p-2 rounded-full bg-[#F3F4F6]">
                                                            <IconWrapper className='w-5 h-5' name={property.icon} />
                                                        </div>
                                                        <div className="flex flex-col">
                                                            <Typography className='medium-text' color='secondary'>{property.title}</Typography>
                                                            <Typography className='text-sm' color='primary'>{property.value}</Typography>
                                                        </div>
                                                    </div>
                                                )
                                            }
                                        </div>
                                    </div>
                                </div>
                                <div className="flex h-0.5 w-full bg-gray-200 my-2"></div>
                                <div className="flex w-full">
                                    <div className="grid grid-cols-3 gap-4 w-full">
                                        {
                                            propertyDesignItems2.map((property, i: number) =>
                                                <div className="flex items-center py-2.5 px-3" key={i}>
                                                    <div className="flex flex-col">
                                                        <Typography className='medium-text' color='secondary'>{property.title}</Typography>
                                                        <Typography className='text-sm' color='primary'>{property.value}</Typography>
                                                    </div>
                                                </div>
                                            )
                                        }
                                        <div className="flex items-center py-2.5 px-3">
                                            <div className="flex flex-col gap-1">
                                                <Typography className='medium-text' color='secondary'>Views</Typography>
                                                {
                                                    listing?.propertyViews.length > 0 && listing?.propertyViews.map((ele: string, i: number) =>
                                                        <div className="border-1 py-1 px-2 border-[#6AABFB] rounded-2xl" key={i}>

                                                            <div>
                                                                <Typography className="text-sm text-[#2563EB]">{ele}</Typography>
                                                            </div>
                                                        </div>
                                                    )
                                                }
                                                {
                                                    listing?.propertyViews.length == 0 && ('-')
                                                }

                                            </div>
                                        </div>
                                        <div className="flex items-center py-2.5 px-3">
                                            <div className="flex flex-col gap-1">
                                                <Typography className='medium-text' color='secondary'>Amenities</Typography>
                                                {
                                                    listing?.propertyAmenities.length > 0 && listing?.propertyAmenities.map((ele: string, i: number) =>
                                                        <div className="border-1 py-1 px-2 border-[#6AABFB] rounded-2xl" key={i}>

                                                            <div>
                                                                <Typography className="text-sm text-[#2563EB]">{ele}</Typography>
                                                            </div>
                                                        </div>
                                                    )
                                                }
                                                {
                                                    listing?.propertyAmenities.length == 0 && ('-')
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="flex h-0.5 w-full bg-gray-200 my-2"></div>
                                <div className="flex flex-col py-3 w-full">
                                    <Typography variant='h3' color='primary'>Description</Typography>
                                    <div className="flex w-full py-2">
                                        <Typography className='text-sm text-black'>
                                            {listing?.propertyDescription ?? '-'}
                                        </Typography>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="xl:w-1/3 xl:block hidden w-full sticky tap-0">
                            <div className='flex flex-col gap-10 w-full md:mt-0 mt-6 pl-6 sticky top-[60px]'>
                                <div className="flex flex-col p-8 bg-white shadow rounded-xl gap-6 w-full bg-[#F0F9FF] rounded-[5px] border border-1 border-[#E5E7EB]">
                                    {/* WITH BUG - TO BE CHECKED */}
                                    {/* <Typography color="primary" className="text-base">
                                        The next available showing time is&nbsp;
                                        <span className="font-bold">{dayName}, {Months[listingDate?.getMonth() ?? 0]} {listingDate?.getDay()}th</span>&nbsp;at&nbsp;
                                        <span className="font-bold">{getHour()}.</span>&nbsp;
                                        This listings’ status is&nbsp;
                                        <span className="font-bold">{listing?.status}.</span>
                                    </Typography> */}
                                    <div className="flex flex-col justify-center gap-5">
                                        <Button variant="text" className='disabled:bg-[#6366F1] disabled:opacity-80 bg-button-primary hover:bg-[#6366F1] border-[#6366F1] hover:border-[#818CF8] text-white' onClick={() => user ? setShowScheduleListing(true) : setOpenAuthDrawer(true)}>
                                            <div className="flex items-center w-full justify-center gap-3">
                                                <IconWrapper name="door-open" />
                                                <Typography variant="button1" className="flex w-[170px] justify-start whitespace-nowrap">Schedule a Showing</Typography>
                                            </div>
                                        </Button>
                                        <Button variant="text" className=" disabled:opacity-80 whitespace-nowrap justify-start border-[#22C55E] hover:bg-button-success-hover text-white hover:text-white border-[1px]"
                                            onClick={() => {
                                                // user ? setOpenSubmitDrawer(true) : onOpenAuthModal('Submit an Offer')
                                            }}
                                            disabled={true}>
                                            <Tooltip className='relative w-full' text="Coming Soon">
                                                <div className="flex items-center w-full justify-center gap-3">
                                                    <IconWrapper name="submit-offer" />
                                                    <Typography variant="button1" className="w-[170px] flex justify-start whitespace-nowrap">Submit an Offer</Typography>
                                                </div>
                                            </Tooltip>
                                        </Button>
                                        <Button variant='text' className='bg-[#EEF2FF] border-[#C7D2FE] hover:bg-[#E0E7FF] hover:border-[#4C42D7] disabled:border-[#D1D5DB] disabled:opacity-80 flex'>
                                            <div className="flex items-center justify-center w-full gap-3">
                                                <IconWrapper name="chat-blue" />
                                                <Typography variant="button1" className="w-[170px] max-w-[200px] flex justify-start whitespace-nowrap text-[#4C42D7]" onClick={() => { setListingId(listing?.id); if (!user) { setOpenAuthDrawer(true) } if (user) { setOpenProfileChat(true) } }}>
                                                    Message {truncateText((listing?.owner?.agent?.firstName || "Agent") + " " + (listing?.owner?.agent?.lastName || ""), 30)}</Typography>
                                            </div>
                                        </Button>
                                    </div>
                                </div>
                                {listing?.owner?.agent?.company && listing?.owner?.agent?.company.businessName && (
                                    <div className="flex w-full flex-col px-3 py-4 gap-2 bg-[#F0F9FF] rounded-[5px] border border-1 border-[#E5E7EB]">
                                        <div className="flex w-full gap-2 items-center">
                                            <div className="flex">
                                                <img
                                                    className="w-9 h-9 object-cover rounded aspect-square"
                                                    src={listing?.owner?.agent?.company?.businessLogo || DefaultCompanyLogo}
                                                    onError={(e) => e.currentTarget.src = DefaultCompanyLogo}
                                                />
                                            </div>
                                            <div className="flex flex-col">
                                                <div className="flex w-full">
                                                    <Typography variant="h4" color="primary" className="text-[14px] line-clamp-1  max-w-[240px]">{listing?.owner?.agent?.company?.businessName + " " || "Company"}</Typography>
                                                </div>
                                                <Typography variant="medium-text" color="secondary" className="flex items-center gap-2">{listing?.owner?.agent?.company?.businessAddress1 || "Address"} </Typography>
                                            </div>
                                        </div>
                                        <div className="flex flex-col w-full">
                                            <div className="flex items-center gap-2">
                                                <IconWrapper name="telephone" width={13} />
                                                <Typography variant="medium-text" color="secondary" className="flex items-center gap-2">{listing?.owner?.agent?.company?.principalBrokerPhone || "None"} </Typography>
                                            </div>
                                            <div className="flex items-center gap-2">
                                                <IconWrapper name="envelope" width={13} />
                                                <Typography variant="medium-text" color="secondary" className="flex items-center gap-2 truncate ">{listing?.owner?.agent?.contactEmail || "None"}</Typography>
                                            </div>
                                        </div>
                                    </div>
                                )}
                                <div className="flex w-full flex-col px-3 py-4 gap-2 bg-[#EEF2FF] rounded-[5px] border border-1 border-[#E5E7EB]">
                                    <div className="flex w-full gap-2 items-center">
                                        <div className="flex">
                                            <img
                                                className="w-9 h-9 object-cover rounded aspect-square"
                                                src={listing?.owner?.agent?.avatarURL || DefaultProfilePicture}
                                                onError={(e) => e.currentTarget.src = DefaultProfilePicture}
                                            />
                                        </div>
                                        <div className="flex flex-col">
                                            <Typography variant="h4" color="primary" className="text-[14px] line-clamp-1 max-w-[240px]">{listing?.owner?.agent?.firstName || "Agent"} {listing?.owner?.agent?.lastName || ""}</Typography>
                                            <Typography variant="medium-text" color="secondary" className="flex items-center gap-2">{listing?.owner?.agent?.licenseType || "Address"} </Typography>
                                        </div>
                                    </div>
                                    <div className="flex flex-col w-full">
                                        <div className="flex items-center gap-2">
                                            <IconWrapper name="telephone" width={13} />
                                            <Typography variant="medium-text" color="secondary" className="flex items-center gap-2 truncate">{listing?.owner?.agent?.mobileNumber || "None"}</Typography>
                                        </div>
                                        <div className="flex items-center gap-2">
                                            <IconWrapper name="envelope" width={13} />
                                            <Typography variant="medium-text" color="secondary" className="flex items-center gap-2 truncate ">{listing?.owner?.agent?.contactEmail || "None"}</Typography>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}

export default PublicListing