import classNames from "classnames";
import { ReactNode } from "react";

// ----------------------------------------------------------------------
type Props = {
  className?: string;
  variant?: "contained" | "outline";
  color?: "success" | "success-light" | "warning" | "danger" | "danger-light" | "info" | "white" | "gray" | "gray-light" | "";
  size?: "small" | "medium";
  title?: ReactNode;
};

const Label = ({
  className,
  variant = "contained",
  color = "success",
  size = "medium",
  title,
}: Props) => {
  return (
    <span
      className={classNames("flex items-center", className, {
        ["bg-gray-300 text-primary"]: variant === "contained" && color === "gray",
        ["bg-gray-200 text-primary"]: variant === "contained" && color === "gray-light",
        ["bg-gray-100 border-1 border-gray-300 text-primary"]: variant === "outline" && color === "gray",
        ["bg-gray-100"]: variant === "contained" && color === "",
        ["bg-white border-1 border-gray-300 text-primary"]: variant === "outline" && color === "white",
        ["bg-success"]: variant === "contained" && color === "success",
        ["bg-success-light text-success-dark"]: variant === "contained" && color === "success-light",
        ["bg-warning"]: variant === "contained" && color === "warning",
        ["bg-error"]: variant === "contained" && color === "danger",
        ["bg-error-light"]: variant === "contained" && color === "danger-light",
        ["bg-info"]: variant === "contained" && color === "info",
        ["border-1 border-[#6AABFB] rounded-xl py-1 px-2"]: variant === "outline" && color === "info",
        ["text-success-dark"]: color === "success",
        ["text-warning-dark"]: color === "warning",
        ["text-error-dark"]: color === "danger",
        ["text-info-dark"]: color === "info",
        ["px-3 py-0.5 text-12 leading-16"]: size === "small",
        ["px-4 py-2 font-medium text-20 leading-30 rounded-8"]: size === "medium",
      })}
    >
      {title}
    </span>
  );
};

export default Label;
