type Props = {
  width: number;
  height: number;
  stroke?: string;
  className?: string
};

const IconOffers = (props: Props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" {...props}>
      <path d="M4 22H18C18.5304 22 19.0391 21.7893 19.4142 21.4142C19.7893 21.0391 20 20.5304 20 20V7L15 2H6C5.46957 2 4.96086 2.21071 4.58579 2.58579C4.21071 2.96086 4 3.46957 4 4V8" stroke="#A3A9BA" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M14 2V6C14 6.53043 14.2107 7.03914 14.5858 7.41421C14.9609 7.78929 15.4696 8 16 8H20" stroke="#A3A9BA" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M2 15H12" stroke="#A3A9BA" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M9 18L12 15L9 12" stroke="#A3A9BA" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};

export default IconOffers;
