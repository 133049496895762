// Define the type for the component props
type Props = {
    width: number;
    height: number;
    stroke?: string;
    className?: string;
};

// Define the IconProfileUser component
const IconProfileUser = (props: Props) => {
    // Return the SVG for the icon
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 17 16" fill="none" {...props}>
            <path d="M8.49935 8.66667C10.3403 8.66667 11.8327 7.17428 11.8327 5.33333C11.8327 3.49238 10.3403 2 8.49935 2C6.6584 2 5.16602 3.49238 5.16602 5.33333C5.16602 7.17428 6.6584 8.66667 8.49935 8.66667Z" stroke="#1F2937" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M13.8327 14.0013C13.8327 12.5868 13.2708 11.2303 12.2706 10.2301C11.2704 9.22987 9.91384 8.66797 8.49935 8.66797C7.08486 8.66797 5.72831 9.22987 4.72811 10.2301C3.72792 11.2303 3.16602 12.5868 3.16602 14.0013" stroke="#1F2937" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
        </svg>

    );
};

// Export the IconProfileUser component
export default IconProfileUser;