import { useState } from "react";
import Typography from "@/components/baseComponents/Typography";
import Select from "@/components/baseComponents/Select";
import Textarea from "@/components/baseComponents/Textarea";
import AddressAutoComplete from "@/components/baseComponents/AddressAutoComplete";
import DatePicker from "@/components/baseComponents/DatePickers/DatePicker";
import { NumericFormat } from "react-number-format";
import {
  fetchAmenities,
  fetchKeywords,
  fetchSchoolDistricts,
  getAmenities,
  getKeywords,
} from "@/redux/lead/leadSlice";
import { useAppDispatch, useAppSelector } from "@/redux/hooks";
import { IAddress, IAddressSearch, IMyListing } from "@/shared/interfaces/interfaces";
import TextField from "@/components/baseComponents/TextField";
import { formatAddress } from "@/shared/services/utils";
import IconWrapper from "@/components/baseComponents/IconWrapper";
import Tooltip from "@/components/mainComponents/ToolTip";
import AddressUnitAutoComplete from "@/components/baseComponents/AddressUnitAutoComplete";
import { getAddressUnits } from "@/redux/address/addressSlice";

type IProps = {
  data?: IMyListing;
  addresses: Array<any>;
  errorListingAddress: boolean;
  errorListingDateListed: boolean;
  errorListingExpirationDate: boolean;
  errorListingType: boolean;
  errorListingSaleType: boolean;
  errorListingListPrice: boolean;
  errorPropertyBedrooms: boolean;
  errorPropertyBathrooms: boolean;
  errorPropertyType: boolean;
  // errorPropertySubType: boolean
  errorPropertyStories: boolean;
  errorPropertyNumberOfUnits: boolean;
  handleInputChange: Function;
  handleSelectChange: Function;
  handleDateChange: Function;
  onChangeAddressAutoComplete: Function;
  onSelectAddressAutoComplete: Function;
  handleChangePrivateListing: Function;
};
const ListingDetails = (props: IProps) => {

  const dispatch = useAppDispatch();

  const keywords = useAppSelector(getKeywords);
  const amenities = useAppSelector(getAmenities);

  const [propertySubType, setPropertySubType] = useState<Array<any>>([]);
  const [searchKeyword, setSearchKeyword] = useState<string>("");
  const [searchAmenities, setSearchAmenities] = useState<string>("");
  const [resetPropertySubType, setResetPropertySubType] = useState<boolean>(false);
  const [units, setUnits] = useState<IAddress[]>([]);

  const occupancyStatus = [
    { value: "Owner Occupied", label: "Owner Occupied" },
    { value: "Renter Occupied", label: "Renter Occupied" },
    { value: "Squatters", label: "Squatters" },
    { value: "Vacant", label: "Vacant" },
  ];

  const propertyType = [
    { value: "Single Family Residence", label: "Single Family Residence" },
    { value: "Multi Family Residence", label: "Multi Family Residence" },
    { value: "Apartment", label: "Apartment" },
    { value: "Townhouse", label: "Townhouse" },
    { value: "Land", label: "Land" },
    { value: "Mobile", label: "Mobile" },
    { value: "Commercial", label: "Commercial" },
    { value: "Other", label: "Other" },
  ];

  const SubTypeList = [
    {
      parent: "Single Family Residence",
      contents: [
        { value: "Cabin", label: "Cabin" },
        { value: "Manufactured Home", label: "Manufactured Home" },
        { value: "Manufactured On Land", label: "Manufactured On Land" },
        { value: "Mobile Home", label: "Mobile Home" },
        { value: "Own Your Own", label: "Own Your Own" },
        { value: "Single Family Residence", label: "Single Family Residence" },
        { value: "Mixed Use", label: "Mixed Use" },
      ],
    },
    {
      parent: "Multi Family Residence",
      contents: [
        { value: "Duplex", label: "Duplex" },
        { value: "Manufactured Home", label: "Manufactured Home" },
        { value: "Manufactured On Land", label: "Manufactured On Land" },
        { value: "Mobile Home", label: "Mobile Home" },
        { value: "Own Your Own", label: "Own Your Own" },
        { value: "Quadruplex", label: "Quadruplex" },
        { value: "Triplex", label: "Triplex" },
      ],
    },
    {
      parent: "Apartment",
      contents: [
        { value: "Apartment", label: "Apartment" },
        { value: "Condominium", label: "Condominium" },
        { value: "Own Your Own", label: "Own Your Own" },
        { value: "Stock Cooperative", label: "Stock Cooperative" },
      ],
    },
    {
      parent: "Townhouse",
      contents: [
        { value: "Own Your Own", label: "Own Your Own" },
        { value: "Townhouse", label: "Townhouse" },
      ],
    },
    {
      parent: "Land",
      contents: [
        { value: "Farm (PropertySubType)", label: "Farm (PropertySubType)" },
        { value: "Own Your Own", label: "Own Your Own" },
        { value: "Ranch", label: "Ranch" },
        { value: "Unimproved Land", label: "Unimproved Land" },
      ],
    },
    {
      parent: "Mobile",
      contents: [
        { value: "Mobile Home", label: "Mobile Home" },
        { value: "Own Your Own", label: "Own Your Own" },
      ],
    },
    {
      parent: "Commercial",
      contents: [
        { value: "Own Your Own", label: "Own Your Own" },
        { value: "Stock Cooperative", label: "Stock Cooperative" },
        {
          value: "Business (PropertySubType)",
          label: "Business (PropertySubType)",
        },
        { value: "Hotel-Motel", label: "Hotel-Motel" },
        {
          value: "Industrial (PropertySubType)",
          label: "Industrial (PropertySubType)",
        },
        { value: "Mixed Use", label: "Mixed Use" },
        { value: "Multi Family", label: "Multi Family" },
        {
          value: "Office (PropertySubType)",
          label: "Office (PropertySubType)",
        },
        {
          value: "Retail (PropertySubType)",
          label: "Retail (PropertySubType)",
        },
        {
          value: "Warehouse (PropertySubType)",
          label: "Warehouse (PropertySubType)",
        },
      ],
    },
    {
      parent: "Other",
      contents: [
        { value: "Boat Slip", label: "Boat Slip" },
        { value: "Cabin", label: "Cabin" },
        { value: "Deeded Parking", label: "Deeded Parking" },
        { value: "Farm (PropertySubType)", label: "Farm (PropertySubType)" },
        { value: "Manufactured Home", label: "Manufactured Home" },
        { value: "Manufactured On Land", label: "Manufactured On Land" },
        { value: "Own Your Own", label: "Own Your Own" },
        { value: "Ranch", label: "Ranch" },
        { value: "Timeshare", label: "Timeshare" },
        {
          value: "Agriculture (PropertySubType)",
          label: "Agriculture (PropertySubType)",
        },
        {
          value: "Business (PropertySubType)",
          label: "Business (PropertySubType)",
        },
      ],
    },
  ];

  const changePropertyType = (value: any) => {
    props.handleSelectChange(value, "propertyType");
    SubTypeList.map((item) => {
      if (item.parent === value.value) {
        setPropertySubType(item.contents);
      }
    });
    setResetPropertySubType(true);

    if (value.value === "Single Family Residence") {
      setResetPropertySubType(false);
      props.handleSelectChange({ value: "Single Family Residence", label: "Single Family Residence" }, "propertySubType");
    } else {
      props.handleSelectChange({}, "propertySubType");
    }
  };

  const onInputChangeAutoComplete = (value: any, name: string) => {
    if (name === "propertyKeywords") {
      setSearchKeyword(value);
      dispatch(fetchKeywords({ query: value }));
    } else if (name === "propertyAmenities") {
      setSearchAmenities(value);
      dispatch(fetchAmenities({ query: value }));
    } else {
      dispatch(fetchSchoolDistricts({ query: value }));
    }
  };

  const handleUnits = (selected: IAddress) => {
    if (selected.entries && selected.entries > 0) {
      const payload: IAddressSearch = { 
        searchTerm: props.data?.listingAddress as any, 
        selected: `${selected.streetLine} ${selected.secondary} (${selected.entries}) ${selected.city} ${selected.state} ${selected.zipcode}`
      };
      dispatch(getAddressUnits(payload)).then(response => {
        setUnits(response.payload.data);
      })
    } else {
      setUnits([]);
    }
  }

  return (
    <div className="my-[30px] px-4 md:px-8 space-y-10">
      <div className="space-y-6">
        <div>
          <Typography variant="h3" color="primary">
            Listing Details
          </Typography>
          <Typography variant="body" color="secondary">
            Description optional
          </Typography>
        </div>

        {/* listing type and sale type */}
        <div className="grid grid-cols-2 gap-[15px] mt-[25px]">
          <div className="col-span-2 sm:col-span-1 relative">
            <Typography variant="body" color="secondary">
              Listing Type <span className="text-red-600">*</span>
            </Typography>
            <Select
              isValid={!props.errorListingType}
              options={[
                { value: "Exclusive Agency", label: "Exclusive Agency" },
                {
                  value: "Exclusive Right To Lease",
                  label: "Exclusive Right To Lease",
                },
                {
                  value: "Exclusive Right To Sell",
                  label: "Exclusive Right To Sell",
                },
                {
                  value: "Exclusive Right With Exception",
                  label: "Exclusive Right With Exception",
                },
                {
                  value: "Net (Listing Agreement)",
                  label: "Net (Listing Agreement)",
                },
                { value: "Open", label: "Open" },
                { value: "Probate", label: "Probate" },
              ]}
              name="listingType"
              value={{
                value: props.data?.listingType,
                label: props.data?.listingType,
              }}
              onChange={(value) =>
                props.handleSelectChange(value, "listingType")
              }
            />
            {props.errorListingType && (
              <Typography
                variant="caption"
                className="text-[#E01010] absolute mt-[2px]"
              >
                This field is required
              </Typography>
            )}
          </div>
          <div className="col-span-2 sm:col-span-1">
            <Typography variant="body" color="secondary">
              Sale Type <span className="text-red-600">*</span>
            </Typography>
            <Select
              isValid={!props.errorListingSaleType}
              options={[
                { value: "Auction", label: "Auction" },
                {
                  value: "Bankruptcy Property",
                  label: "Bankruptcy Property",
                },
                { value: "HUD Owned", label: "HUD Owned" },
                { value: "In Foreclosure", label: "In Foreclosure" },
                { value: "Notice Of Default", label: "Notice Of Default" },
                { value: "Probate Listing", label: "Probate Listing" },
                { value: "Real Estate Owned", label: "Real Estate Owned" },
                { value: "Short Sale", label: "Short Sale" },
                { value: "Standard", label: "Standard" },
                {
                  value: "Third Party Approval",
                  label: "Third Party Approval",
                },
              ]}
              name="listingSaleType"
              value={{
                value: props.data?.listingSaleType,
                label: props.data?.listingSaleType,
              }}
              onChange={(value) =>
                props.handleSelectChange(value, "listingSaleType")
              }
            />
            {props.errorListingSaleType && (
              <Typography
                variant="body"
                className="text-[#E01010] absolute mt-[2px]"
              >
                This field is required
              </Typography>
            )}
          </div>
        </div>

        {/* Address and Unit */}
        <div className="grid grid-cols-3 gap-[15px] mt-[25px]">
          <div className="col-span-3">
            <div className="flex">
              <label className="flex items-center gap-2 cursor-pointer">
                <input
                  value={0}
                  name="isPublic"
                  type="checkbox"
                  className="sr-only peer"
                  checked={props.data?.isPublic === 0}
                  onChange={(e) => props.handleChangePrivateListing(e.currentTarget.checked ? 0 : 1)}
                />
                <div className="relative w-9 h-5 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-4 after:w-4 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
                <Typography variant="button1" className="flex items-center gap-2">
                  Private Listing
                  <Tooltip
                    text={
                      <div className="text-center">
                        <div>Will hide address details and only</div>
                        <div>show city, state, and zip code</div>
                        <div>after published</div>
                      </div>
                    }
                  >
                    <IconWrapper name="info" width={18} />
                  </Tooltip>
                </Typography>
              </label>
            </div>
          </div>
          <div className="col-span-2 sm:col-span-2">
            <Typography variant="body" color="secondary">
              Address <span className="text-red-600">*</span>
            </Typography>
            <AddressAutoComplete
              options={props.addresses}
              placeholder="Enter Property Address"
              value={formatAddress(props.data?.listingAddress)}
              inputClassName={`${props.errorListingAddress && `!border-[#E01010]`}`}
              onChange={(e) => props.onChangeAddressAutoComplete(e, "listingAddress")}
              onAllChange={(e) => {
                props.onSelectAddressAutoComplete(e, "listingAddress");
                handleUnits(e);
              }}
            />
            {props.errorListingAddress && (
              <Typography variant="caption" className="text-[#E01010] absolute">
                Please enter a valid address
              </Typography>
            )}
          </div>
          <div className="col-span-1 sm:col-span-1">
            <Typography variant="body" color="secondary">
              Unit
            </Typography>
            <AddressUnitAutoComplete 
              options={units} 
              value={props.data?.listingUnit}
              onChange={(value) => props.handleSelectChange(value, "listingUnit")} 
            />
          </div>
        </div>

        {/* neiborhood and MLS Number */}
        <div className="grid grid-cols-2 gap-[15px] mt-[25px]">
          <div className="col-span-2 sm:col-span-1">
            <Typography variant="body" color="secondary">
              Neighborhood
            </Typography>
            <TextField
              name="listingNeigborhood"
              value={props.data?.listingNeigborhood}
              onChange={(e) => props.handleInputChange(e)}
            />
          </div>
          <div className="col-span-2 sm:col-span-1">
            <Typography variant="body" color="secondary">
              MLS Number
            </Typography>
            <TextField
              name="listingMlsNumber"
              value={props.data?.listingMlsNumber || ""}
              onChange={(e) => props.handleInputChange(e)}
            />
          </div>
        </div>

        {/* Date listed and expiring date */}
        <div className="grid grid-cols-2 gap-[15px] mt-[25px]">
          <div className="col-span-2 sm:col-span-1 relative">
            <Typography variant="caption" color="secondary">
              Date Listed <span className="text-red-600">*</span>
            </Typography>
            <DatePicker
              className={`w-full relative ${props.errorListingDateListed
                ? "outline outline-1 rounded outline-[#E01010]"
                : ""
                }`}
              name="listingDateListed"
              value={
                props.data?.listingDateListed !== undefined
                  ? new Date(props.data?.listingDateListed)
                  : null
              }
              onChange={(value) =>
                props.handleDateChange(value, "listingDateListed")
              }
            />
            {props.errorListingDateListed && (
              <Typography
                variant="caption"
                className="text-[#E01010] absolute mt-[42px] left-0"
              >
                This field is required
              </Typography>
            )}
          </div>
          <div className="col-span-2 sm:col-span-1 relative">
            <Typography variant="caption" color="secondary">
              Listing Expiration Date <span className="text-red-600">*</span>
            </Typography>
            <DatePicker
              className={`${props.errorListingExpirationDate
                ? "outline outline-1 rounded outline-[#E01010]"
                : ""
                }`}
              name="listingExpirationDate"
              value={
                props.data?.listingExpirationDate !== undefined
                  ? new Date(props.data?.listingExpirationDate)
                  : null
              }
              onChange={(value) =>
                props.handleDateChange(value, "listingExpirationDate")
              }
            />
            {props.errorListingExpirationDate && (
              <Typography
                variant="caption"
                className="text-[#E01010] absolute mt-[42px] left-0"
              >
                This field is required
              </Typography>
            )}
          </div>
        </div>

        {/* list Price and Annual Tax */}
        <div className="grid grid-cols-2 gap-[15px] mt-[25px]">
          <div className="col-span-2 sm:col-span-1 relative">
            <Typography variant="body" color="secondary">
              List Price <span className="text-red-600">*</span>
            </Typography>
            <NumericFormat
              autoComplete="off"
              allowLeadingZeros={false}
              thousandSeparator=","
              placeholder="$"
              name="listingListPrice"
              value={props.data?.listingListPrice || ""}
              onChange={(e) => props.handleInputChange(e, "number")}
              className={`body bg-netural rounded-5 h-10 text-primary p-4 w-full placeholder:caption border-transparent focus:border-netural-dark ${props.errorListingListPrice
                ? "border-1 rounded !border-[#E01010]"
                : ""
                }`}
            />
            {props.errorListingListPrice && (
              <Typography
                variant="caption"
                className="text-[#E01010] absolute mt-[42px] left-0"
              >
                This field is required
              </Typography>
            )}
          </div>
          <div className="col-span-2 sm:col-span-1">
            <Typography variant="body" color="secondary">
              Annual Taxes
            </Typography>
            <NumericFormat
              autoComplete="off"
              allowLeadingZeros={false}
              thousandSeparator=","
              placeholder="$"
              name="listingAnnualTaxes"
              value={props.data?.listingAnnualTaxes || ""}
              onChange={(e) => props.handleInputChange(e, "number")}
              className="body bg-netural rounded-5 h-10 text-primary p-4 outline-none w-full placeholder:caption  border-1 border-transparent focus:border-netural-dark"
            />
          </div>
        </div>

        {/* Annual HOA Expenses and Monthly Expensies */}
        <div className="grid grid-cols-2 gap-[15px] mt-[25px]">
          <div className="col-span-2 sm:col-span-1">
            <Typography variant="body" color="secondary">
              Annual HOA Expenses
            </Typography>
            <NumericFormat
              autoComplete="off"
              allowLeadingZeros={false}
              thousandSeparator=","
              placeholder="$"
              name="listingHoaExpenses"
              value={props.data?.listingHoaExpenses || ""}
              onChange={(e) => props.handleInputChange(e, "number")}
              className="body bg-netural rounded-5 h-10 text-primary p-4 outline-none w-full placeholder:caption  border-1 border-transparent focus:border-netural-dark"
            />
          </div>
          <div className="col-span-2 sm:col-span-1">
            <Typography variant="body" color="secondary">
              Other Monthly Expenses
            </Typography>
            <NumericFormat
              autoComplete="off"
              allowLeadingZeros={false}
              thousandSeparator=","
              placeholder="$"
              name="listingOtherMonthlyExpenses"
              value={props.data?.listingOtherMonthlyExpenses || ""}
              onChange={(e) => props.handleInputChange(e, "number")}
              className="body bg-netural rounded-5 h-10 text-primary p-4 outline-none w-full placeholder:caption  border-1 border-transparent focus:border-netural-dark"
            />
          </div>
        </div>

        {/* Occupancy Status and Lis Pendens */}
        <div className="grid grid-cols-2 gap-[15px] mt-[25px]">
          <div className="col-span-2 sm:col-span-1">
            <Typography variant="body" color="secondary">
              Occupancy Status
            </Typography>
            <Select
              options={occupancyStatus}
              name="listingOccupancyStatus"
              value={{
                value: props.data?.listingOccupancyStatus,
                label: props.data?.listingOccupancyStatus,
              }}
              onChange={(value) =>
                props.handleSelectChange(value, "listingOccupancyStatus")
              }
            />
          </div>
          <div className="col-span-2 sm:col-span-1">
            <Typography variant="body" color="secondary">
              Lis Pendens
            </Typography>
            <Select
              options={[
                { value: "Yes", label: "Yes" },
                { value: "No", label: "No" },
                { value: "Unknown", label: "Unknown" },
              ]}
              name="listingLisPendens"
              value={{
                value: props.data?.listingLisPendens,
                label: props.data?.listingLisPendens,
              }}
              onChange={(value) =>
                props.handleSelectChange(value, "listingLisPendens")
              }
            />
          </div>
        </div>
      </div>

      <div className=" ">
        <Typography variant="h3" color="primary">
          Property Details
        </Typography>
        <Typography variant="body" color="secondary">
          Description optional
        </Typography>
        <div className="grid grid-cols-2 gap-[15px] mt-[25px]">
          <div className="col-span-2 sm:col-span-1 relative">
            <Typography variant="body" color="secondary">
              Property Type <span className="text-red-600">*</span>
            </Typography>
            <Select
              isValid={!props.errorPropertyType}
              options={propertyType}
              name="propertyType"
              value={{
                value: props.data?.propertyType,
                label: props.data?.propertyType,
              }}
              onChange={(value) => changePropertyType(value)}
            />
            {props.errorPropertyType && (
              <Typography variant="caption" className="text-[#E01010] absolute">
                Please enter a valid property type
              </Typography>
            )}
          </div>
          <div className="col-span-2 sm:col-span-1 relative">
            <Typography variant="body" color="secondary">
              Property Sub-Type
            </Typography>
            <Select
              disabled={props.data?.propertyType ? false : true}
              options={propertySubType}
              name="propertySubType"
              value={{
                value: resetPropertySubType ? "" : props.data?.propertySubType,
                label: resetPropertySubType ? "" : props.data?.propertySubType,
              }}
              onChange={(value) => {
                props.handleSelectChange(value, "propertySubType");
                setResetPropertySubType(false);
              }}
            />
          </div>
        </div>
        <div className="grid grid-cols-2 gap-[15px] mt-[25px]">
          <div className="col-span-2 sm:col-span-1 relative">
            <Typography variant="body" color="secondary">
              Unit Count <span className="text-red-600">*</span>
            </Typography>
            <NumericFormat
              autoComplete="off"
              thousandSeparator=","
              name="propertyNumberOfUnits"
              value={props.data?.propertyNumberOfUnits}
              onChange={(e) => props.handleInputChange(e, "number")}
              className={`${props.errorPropertyNumberOfUnits ? `!border-[#E01010] ` : ``
                }body bg-netural rounded-5 h-10 p-4 w-full border-transparent focus:border-transparent`}
            />
            {props.errorPropertyNumberOfUnits && (
              <Typography
                variant="caption"
                className="text-[#E01010] absolute mt-[42px] left-0"
              >
                Please enter a valid number of units
              </Typography>
            )}
          </div>
          <div className="col-span-2 sm:col-span-1 relative">
            <Typography variant="body" color="secondary">
              Stories <span className="text-red-600">*</span>
            </Typography>
            <NumericFormat
              autoComplete="off"
              thousandSeparator=","
              name="propertyStories"
              value={props.data?.propertyStories}
              onChange={(e) => props.handleInputChange(e, "number")}
              className={`${props.errorPropertyStories ? `!border-[#E01010] ` : ``
                }body bg-netural rounded-5 h-10 p-4 w-full border-transparent focus:border-transparent`}
            />
            {props.errorPropertyStories && (
              <Typography
                variant="caption"
                className="text-[#E01010] absolute mt-[42px] left-0"
              >
                Please enter a valid number of stories
              </Typography>
            )}
          </div>
        </div>
        <div className="grid grid-cols-2 gap-[15px] mt-[25px]">
          <div className="col-span-2 sm:col-span-1">
            <Typography variant="body" color="secondary">
              Home SqFt
            </Typography>
            <NumericFormat
              autoComplete="off"
              thousandSeparator=","
              name="propertyHomeSqft"
              value={props.data?.propertyHomeSqft}
              onChange={(e) => props.handleInputChange(e, "number")}
              className="body bg-netural rounded-5 h-10 text-primary p-4 outline-none w-full placeholder:caption  border-1 border-transparent focus:border-netural-dark"
            />
          </div>
          <div className="col-span-2 sm:col-span-1">
            <Typography variant="body" color="secondary">
              Lot SqFt
            </Typography>
            <NumericFormat
              autoComplete="off"
              thousandSeparator=","
              name="propertyLotSqft"
              value={props.data?.propertyLotSqft}
              onChange={(e) => props.handleInputChange(e, "number")}
              className="body bg-netural rounded-5 h-10 text-primary p-4 outline-none w-full placeholder:caption  border-1 border-transparent focus:border-netural-dark"
            />
          </div>
        </div>
      </div>

      <div>
        <Typography variant="h3" color="primary">
          Property Features
        </Typography>

        <div className="grid grid-cols-2 gap-[15px] mt-[25px]">
          <div className="col-span-2 sm:col-span-1 relative">
            <Typography variant="body" color="secondary">
              Bedrooms <span className="text-red-600">*</span>
            </Typography>
            <NumericFormat
              autoComplete="off"
              thousandSeparator=","
              name="propertyBedrooms"
              value={props.data?.propertyBedrooms}
              onChange={(e) => props.handleInputChange(e, "number")}
              className={`${props.errorPropertyBedrooms ? `!border-[#E01010] ` : ``
                }body bg-netural rounded-5 h-10 p-4 w-full border-transparent focus:border-transparent`}
            />
            {props.errorPropertyBedrooms && (
              <Typography
                variant="caption"
                className="text-[#E01010] absolute mt-[42px] left-0"
              >
                Please enter a valid number of bedrooms
              </Typography>
            )}
          </div>
          <div className="col-span-2 sm:col-span-1 relative">
            <Typography variant="body" color="secondary">
              Bathrooms <span className="text-red-600">*</span>
            </Typography>
            <NumericFormat
              autoComplete="off"
              thousandSeparator=","
              name="propertyBathrooms"
              value={props.data?.propertyBathrooms}
              onChange={(e) => props.handleInputChange(e, "number")}
              className={`${props.errorPropertyBathrooms ? `!border-[#E01010] ` : ``
                }body bg-netural rounded-5 h-10 p-4 w-full border-transparent focus:border-transparent`}
            />
            {props.errorPropertyBathrooms && (
              <Typography
                variant="caption"
                className="text-[#E01010] absolute mt-[42px] left-0"
              >
                Please enter a valid number of bathrooms
              </Typography>
            )}
          </div>
        </div>
        <div className="grid grid-cols-2 gap-[15px] mt-[25px]">
          {/* Parking */}
          <div className="py-3 space-y-2">
            <Typography variant="body" color="secondary">
              Parking
            </Typography>
            <div className="flex gap-8">
              <div className="flex items-center">
                <input
                  id="parkingYes"
                  type="radio"
                  checked={props.data?.propertyParking === "Yes"}
                  className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                  onChange={() =>
                    props.handleSelectChange(
                      { value: "Yes" },
                      "propertyParking"
                    )
                  }
                />
                <label htmlFor={`parkingYes`} className="ml-1 block">
                  <Typography variant="page-menu">Yes</Typography>
                </label>
              </div>
              <div className="flex items-center">
                <input
                  id="parkingNo"
                  type="radio"
                  checked={props.data?.propertyParking === "No"}
                  className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                  onChange={() =>
                    props.handleSelectChange({ value: "No" }, "propertyParking")
                  }
                />
                <label htmlFor={`parkingNo`} className="ml-1 block">
                  <Typography variant="page-menu">No</Typography>
                </label>
              </div>
            </div>
          </div>
          {/* garage */}
          <div className="py-3 space-y-2">
            <Typography variant="body" color="secondary">
              Garage
            </Typography>
            <div className="flex gap-8">
              <div className="flex items-center">
                <input
                  id="garageYes"
                  type="radio"
                  checked={props.data?.propertyGarage === "Yes"}
                  className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                  onChange={() =>
                    props.handleSelectChange({ value: "Yes" }, "propertyGarage")
                  }
                />
                <label htmlFor={`garageYes`} className="ml-1 block">
                  <Typography variant="page-menu">Yes</Typography>
                </label>
              </div>
              <div className="flex items-center">
                <input
                  id="garageNo"
                  type="radio"
                  checked={props.data?.propertyGarage === "No"}
                  className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                  onChange={() =>
                    props.handleSelectChange({ value: "No" }, "propertyGarage")
                  }
                />
                <label htmlFor={`garageNo`} className="ml-1 block">
                  <Typography variant="page-menu">No</Typography>
                </label>
              </div>
            </div>
          </div>
          {/* cooling */}
          <div className="py-3 space-y-2">
            <Typography variant="body" color="secondary">
              Cooling
            </Typography>
            <div className="flex gap-8">
              <div className="flex items-center">
                <input
                  id="coolingYes"
                  type="radio"
                  checked={props.data?.propertyCooling === "Yes"}
                  className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                  onChange={() =>
                    props.handleSelectChange(
                      { value: "Yes" },
                      "propertyCooling"
                    )
                  }
                />
                <label htmlFor={`coolingYes`} className="ml-1 block">
                  <Typography variant="page-menu">Yes</Typography>
                </label>
              </div>
              <div className="flex items-center">
                <input
                  id="coolingNo"
                  type="radio"
                  checked={props.data?.propertyCooling === "No"}
                  className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                  onChange={() =>
                    props.handleSelectChange({ value: "No" }, "propertyCooling")
                  }
                />
                <label htmlFor={`coolingNo`} className="ml-1 block">
                  <Typography variant="page-menu">No</Typography>
                </label>
              </div>
            </div>
          </div>
          {/* Heating */}
          <div className="py-3 space-y-2">
            <Typography variant="body" color="secondary">
              Heating
            </Typography>
            <div className="flex gap-8">
              <div className="flex items-center">
                <input
                  id="heatingYes"
                  type="radio"
                  checked={props.data?.propertyHeating === "Yes"}
                  className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                  onChange={() =>
                    props.handleSelectChange(
                      { value: "Yes" },
                      "propertyHeating"
                    )
                  }
                />
                <label htmlFor={`heatingYes`} className="ml-1 block">
                  <Typography variant="page-menu">Yes</Typography>
                </label>
              </div>
              <div className="flex items-center">
                <input
                  id="heatingNo"
                  type="radio"
                  checked={props.data?.propertyHeating === "No"}
                  className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                  onChange={() =>
                    props.handleSelectChange({ value: "No" }, "propertyHeating")
                  }
                />
                <label htmlFor={`heatingNo`} className="ml-1 block">
                  <Typography variant="page-menu">No</Typography>
                </label>
              </div>
            </div>
          </div>
          {/* Pool */}
          <div className="py-3 space-y-2">
            <Typography variant="body" color="secondary">
              Pool
            </Typography>
            <div className="flex gap-8">
              <div className="flex items-center">
                <input
                  id="poolYes"
                  type="radio"
                  checked={props.data?.propertyPool === "Yes"}
                  className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                  onChange={() =>
                    props.handleSelectChange({ value: "Yes" }, "propertyPool")
                  }
                />
                <label htmlFor={`poolYes`} className="ml-1 block">
                  <Typography variant="page-menu">Yes</Typography>
                </label>
              </div>
              <div className="flex items-center">
                <input
                  id="poolNo"
                  type="radio"
                  checked={props.data?.propertyPool === "No"}
                  className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                  onChange={() =>
                    props.handleSelectChange({ value: "No" }, "propertyPool")
                  }
                />
                <label htmlFor={`poolNo`} className="ml-1 block">
                  <Typography variant="page-menu">No</Typography>
                </label>
              </div>
            </div>
          </div>
          {/* Attached */}
          <div className="py-3 space-y-2">
            <Typography variant="body" color="secondary">
              Is Attached? <span className="text-red-600">*</span>
            </Typography>
            <div className="flex gap-8">
              <div className="flex items-center">
                <input
                  id="attachedYes"
                  type="radio"
                  checked={props.data?.propertyAttached === "Yes"}
                  className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                  onChange={() =>
                    props.handleSelectChange(
                      { value: "Yes" },
                      "propertyAttached"
                    )
                  }
                />
                <label htmlFor={`attachedYes`} className="ml-1 block">
                  <Typography variant="page-menu">Yes</Typography>
                </label>
              </div>
              <div className="flex items-center">
                <input
                  id="attachedNo"
                  type="radio"
                  checked={props.data?.propertyAttached === "No"}
                  className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                  onChange={() =>
                    props.handleSelectChange(
                      { value: "No" },
                      "propertyAttached"
                    )
                  }
                />
                <label htmlFor={`attachedNo`} className="ml-1 block">
                  <Typography variant="page-menu">No</Typography>
                </label>
              </div>
            </div>
          </div>
          {/* New Construction */}
          <div className="py-3 space-y-2">
            <Typography variant="body" color="secondary">
              New Construction <span className="text-red-600">*</span>
            </Typography>
            <div className="flex gap-8">
              <div className="flex items-center">
                <input
                  id="newConstructionYes"
                  type="radio"
                  checked={props.data?.propertyNewConstruction === "Yes"}
                  className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                  onChange={() =>
                    props.handleSelectChange(
                      { value: "Yes" },
                      "propertyNewConstruction"
                    )
                  }
                />
                <label htmlFor={`newConstructionYes`} className="ml-1 block">
                  <Typography variant="page-menu">Yes</Typography>
                </label>
              </div>
              <div className="flex items-center">
                <input
                  id="newConstructionNo"
                  type="radio"
                  checked={props.data?.propertyNewConstruction === "No"}
                  className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                  onChange={() =>
                    props.handleSelectChange(
                      { value: "No" },
                      "propertyNewConstruction"
                    )
                  }
                />
                <label htmlFor={`newConstructionNo`} className="ml-1 block">
                  <Typography variant="page-menu">No</Typography>
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div>
        <Typography variant="h3" color="primary">
          Community & Neighborhood
        </Typography>
        <div className="w-full mt-[25px]">
          <Typography variant="body" color="secondary">
            Views
          </Typography>
          <Select
            isMulti={true}
            options={[
              { value: "Bay", label: "Bay" },
              { value: "Beach", label: "Beach" },
              { value: "Bridge(s)", label: "Bridge(s)" },
              { value: "Canal", label: "Canal" },
              { value: "Canyon", label: "Canyon" },
              { value: "City", label: "City" },
              { value: "City Lights", label: "City Lights" },
              { value: "Creek/Stream", label: "Creek/Stream" },
              { value: "Desert", label: "Desert" },
              { value: "Downtown", label: "Downtown" },
              { value: "Forest", label: "Forest" },
              { value: "Garden", label: "Garden" },
              { value: "Golf Course", label: "Golf Course" },
              { value: "Hills", label: "Hills" },
              { value: "Lake", label: "Lake" },
              { value: "Marina", label: "Marina" },
              { value: "Meadow", label: "Meadow" },
              { value: "Mountain(s)", label: "Mountain(s)" },
              { value: "Neighborhood", label: "Neighborhood" },
              { value: "None", label: "None" },
              { value: "Ocean", label: "Ocean" },
              { value: "Orchard", label: "Orchard" },
              { value: "Other", label: "Other" },
              { value: "Panoramic", label: "Panoramic" },
              { value: "Park/Greenbelt", label: "Park/Greenbelt" },
              { value: "Pasture", label: "Pasture" },
              { value: "Pond", label: "Pond" },
              { value: "Pool", label: "Pool" },
              { value: "Ridge", label: "Ridge" },
              { value: "River", label: "River" },
              { value: "Rural", label: "Rural" },
              { value: "See Remarks", label: "See Remarks" },
              { value: "Skyline", label: "Skyline" },
              { value: "Territorial", label: "Territorial" },
              { value: "Trees/Woods", label: "Trees/Woods" },
              { value: "Valley", label: "Valley" },
              { value: "Vineyard", label: "Vineyard" },
              { value: "Water", label: "Water" },
            ]}
            name="propertyViews"
            value={props.data?.propertyViews.map((item: any) => {
              return {
                value: item,
                label: item,
              };
            })}
            onChange={(value) =>
              props.handleSelectChange(value, "propertyViews", true)
            }
          />
        </div>
        <div className="mt-[25px]">
          <Typography variant="body" color="secondary">
            Amenities
          </Typography>
          <Select
            options={[
              { value: searchAmenities, label: searchAmenities },
              ...(amenities
                ? amenities.map((item: any) => {
                  return { value: item.name, label: item.name };
                })
                : []),
            ]}
            name="propertyAmenities"
            isMulti={true}
            value={props.data?.propertyAmenities.map((item: any) => {
              return {
                value: item,
                label: item,
              };
            })}
            onChange={(value) =>
              props.handleSelectChange(value, "propertyAmenities", true)
            }
            onInputChange={(value) =>
              onInputChangeAutoComplete(value, "propertyAmenities")
            }
          />
        </div>
        <div className="mt-[25px]">
          <Typography variant="body" color="secondary">
            Keywords
          </Typography>
          <Select
            options={[
              { value: searchKeyword, label: searchKeyword },
              ...(keywords
                ? keywords.map((item: any) => {
                  return { value: item.name, label: item.name };
                })
                : []),
            ]}
            name="propertyKeywords"
            isMulti={true}
            value={props.data?.propertyKeywords.map((item: any) => {
              return {
                value: item,
                label: item,
              };
            })}
            onChange={(value) =>
              props.handleSelectChange(value, "propertyKeywords", true)
            }
            onInputChange={(value) =>
              onInputChangeAutoComplete(value, "propertyKeywords")
            }
          />
        </div>
        <div className="mt-[25px]">
          <Typography variant="body" color="secondary">
            Property Description
          </Typography>
          <Textarea
            placeholder="Describe the Property"
            name="propertyDescription"
            rows={5}
            value={props.data?.propertyDescription}
            onChange={(e) => props.handleInputChange(e)}
          />
        </div>
      </div>
    </div>
  );
};

export default ListingDetails;
