import React, { useEffect } from "react";
import Typography from "../../baseComponents/Typography";
import { addMinutes, format } from "date-fns";

// type AvProps = {
//   availability: any;
//   setAvalablity: React.Dispatch<any>;
// };

type IProps = {
  data: any;
  date: Date;
  availability: any;
  setAvalablity: Function;
  onSelectTime: React.Dispatch<any>;
};

const TimeSelector = ({ data, availability, setAvalablity, onSelectTime, date }: IProps) => {
  // state for time picker
  const [selectedMorningIndex, setSelectedMorningIndex] =
    React.useState<number>(-1);
  const [selectedAfternoonIndex, setSelectedAfternoonIndex] =
    React.useState<number>(-1);
  const [selectedEveningIndex, setSelectedEveningIndex] =
    React.useState<number>(-1);
  const [hasAvailableShowingTime, setHasAvailableShowingTime] =
    React.useState<boolean>(false);

  const [isMorning, setIsMorning] = React.useState<any>([]);
  const [isAfternoon, setIsAfternoon] = React.useState<any>([]);
  const [isEvening, setIsEvening] = React.useState<any>([]);

  const selectTime = (index: number, period: string) => {
    // Determine which period the selected time belongs to
    switch (period) {
      case "Morning":
        isMorning[index] && onSelectTime(isMorning[index]);
        setSelectedMorningIndex(index); // Update the selected index for morning period

        // reset slections
        setSelectedEveningIndex(-1);
        setSelectedAfternoonIndex(-1);
        break;
      case "Afternoon":
        isAfternoon[index] && onSelectTime(isAfternoon[index]);
        setSelectedAfternoonIndex(index); // Update the selected index for afternoon period

        // reset slections
        setSelectedMorningIndex(-1);
        setSelectedEveningIndex(-1);
        break;
      case "Evening":
        isEvening[index] && onSelectTime(isEvening[index]);
        setSelectedEveningIndex(index); // Update the selected index for evening period

        // reset slections
        setSelectedAfternoonIndex(-1);
        setSelectedMorningIndex(-1);
        break;
    }
  };

  React.useEffect(() => {
    if (data?.showingAvailability) {
      const availabilityTimeInterval = data?.showingAvailabilityTimeInterval.value;
      const availabilityToday = (data?.showingAvailability as any)[
        format(date, "EEEE").toLowerCase()
      ];
      if (availabilityToday?.isSelected) {
        setHasAvailableShowingTime(true);

        let tempAvailabilityTime: any = [];
        let morningTimeSlots: string[] = [];
        let afternoonTimeSlots: string[] = [];
        let eveningTimeSlots: string[] = [];


        availabilityToday?.time.map((item: any) => {
          const tempDate = format(date, "yyyy-MM-dd");
          const timeStart = `${tempDate}T${item.timeStart}`;
          const timeEnd = `${tempDate}T${item.timeEnd}`;
          
          let start = new Date(timeStart);
          let end = new Date(timeEnd);

          while (start <= end) {
            if (start >= new Date()) {
              tempAvailabilityTime.push(format(start, "p"));
            }

            start = addMinutes(
              start,
              availabilityTimeInterval &&
                availabilityTimeInterval > 0
                ? availabilityTimeInterval
                : 30
            )
          }
        });

        tempAvailabilityTime.forEach((timeSlot: string) => {
          // Determine if it is morning, afternoon, or evening
          const [hour, timeSlug] = timeSlot.split(" ");
          const time = parseInt(hour);

          // Determine if it is morning, afternoon, or evening
          if ((time >= 6 && time < 12) && timeSlug === "PM") {
            eveningTimeSlots.push(timeSlot);
          } else if ((time <= 5 || time === 12) && timeSlug === "PM") {
            afternoonTimeSlots.push(timeSlot);
          } else if (timeSlug === "AM") {
            morningTimeSlots.push(timeSlot);
          }
        });

        setAvalablity(tempAvailabilityTime);
        setIsMorning(morningTimeSlots);
        setIsAfternoon(afternoonTimeSlots);
        setIsEvening(eveningTimeSlots);
      } else {
        setHasAvailableShowingTime(false);
        setAvalablity([]);
      }
    }
  }, [date]);

  useEffect(() => {
    if (isMorning.length > 0) {
      selectTime(0, "Morning");
    } else if (isAfternoon.length > 0) {
      selectTime(0, "Afternoon");
    } else {
      selectTime(0, "Evening");
    }
  }, [availability])

  return (
    <div className="h-full overflow-hidden w-full">
      {hasAvailableShowingTime && availability.length > 0 ? (
        <>
          <div className="flex flex-col gap-[16px]">
            <div className={`morning`}>
              {isMorning && (
                <>
                  {isMorning[0] && (
                    <div className="py-[8px]">
                      <Typography variant="body">Morning</Typography>
                    </div>
                  )}
                  <div className="flex flex-wrap gap-y-[8px] gap-x-[12px]">
                    {isMorning.map((item: any, index: number) => {
                      return (
                        <span
                          key={"morning" + index}
                          onClick={() => selectTime(index, "Morning")}
                          className={`cursor-pointer shadow-sm text-primary max-w-fit px-[8px] py-[4px] rounded-[8px] flex items-center hover:border-gray-400 border-[1px] ${index === selectedMorningIndex &&
                            `bg-indigo-600 border-indigo-500 text-white `
                            }`}
                        >
                          <Typography variant="medium-text">{item}</Typography>
                        </span>
                      );
                    })}
                  </div>
                </>
              )}
            </div>

            <div className={`afternoon`}>
              {isAfternoon && (
                <>
                  {isAfternoon[0] && (
                    <div className="py-[8px]">
                      <Typography variant="body">Afternoon</Typography>
                    </div>
                  )}
                  <div className="flex flex-wrap gap-y-[8px] gap-x-[12px]">
                    {isAfternoon.map((item: any, index: number) => {
                      return (
                        <span
                          key={"afternoon" + index}
                          onClick={() => selectTime(index, "Afternoon")}
                          className={`cursor-pointer shadow-sm text-primary max-w-fit px-[8px] py-[4px] rounded-[8px] flex items-center hover:border-gray-400 border-[1px] ${index === selectedAfternoonIndex &&
                            `bg-indigo-600 border-indigo-500 border-[1px] text-white `
                            }`}
                        >
                          <Typography variant="medium-text">{item}</Typography>
                        </span>
                      );
                    })}
                  </div>
                </>
              )}
            </div>

            <div className={`evening `}>
              {isEvening && (
                <>
                  {isEvening[0] && (
                    <div className="py-[8px]">
                      <Typography variant="body">Evening</Typography>
                    </div>
                  )}
                  <div className="flex flex-wrap gap-y-[8px] gap-x-[12px]">
                    {isEvening.map((item: any, index: number) => {
                      return (
                        <span
                          key={"evening" + index}
                          onClick={() => selectTime(index, "Evening")}
                          className={`cursor-pointer shadow-sm text-primary max-w-fit px-[8px] py-[4px] rounded-[8px] flex items-center hover:border-gray-400v border-[1px] ${index === selectedEveningIndex &&
                            `bg-indigo-600 border-indigo-500 text-white `
                            }`}
                        >
                          <Typography variant="medium-text">{item}</Typography>
                        </span>
                      );
                    })}
                  </div>
                </>
              )}
            </div>
          </div>
        </>
      ) : (
        <div className="flex items-center justify-center w-full h-full pt-5">
          <Typography variant="caption" color="secondary">
            No available time
          </Typography>
        </div>
      )}
    </div>
  );
};

export default TimeSelector;
