// Define the type for the component props
type Props = {
  width: number;
  height: number;
  stroke?: string;
  className?: string;
};

// Define the IconAgents component
const IconPrevSlide = (props: Props) => {
  // Return the SVG for the icon
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 32 33"
      fill="none"
      {...props}
    >
      <path
        d="M20 24.6035L12 16.6035L20 8.60352"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

// Export the IconAgents component
export default IconPrevSlide;
