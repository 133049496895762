import Typography from "@/components/baseComponents/Typography";


interface Props {
  title: string;
  details: string[];
}
const FooterTextCard = ({ title, details }: Props) => {
  return (
    <div className="flex flex-col lg:max-w-[200px] w-full">
      <div className="flex flex-col w-full gap-4">
        <Typography
          variant="h1"
          color="black"
          className="text-[16px] font-bold font-jakarta uppercase"
        >
          {title}
        </Typography>
        <div className="flex flex-col">
          {details.map((ele: string, index) => (
            <Typography
              key={index}
              variant="input-label"
              className="text-[16px] text-[#000929] opacity-70 font-medium font-jakarta py-1"
            >
              {ele}
            </Typography>
          ))}
        </div>
      </div>
    </div>
  );
};

export default FooterTextCard;
