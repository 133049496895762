// Define the type for the component props
type Props = {
    width: number;
    height: number;
    stroke?: string;
    className?: string;
};

// Define the IconFollow component
const IconFollow = (props: Props) => {
    // Return the SVG for the icon
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12 12" fill="none" {...props}>
            <g clipPath="url(#clip0_11200_101115)">
                <path d="M1 10.5C0.999958 9.73014 1.22207 8.97665 1.63967 8.32992C2.05728 7.68319 2.65264 7.17071 3.3543 6.85398C4.05596 6.53725 4.83412 6.42973 5.59538 6.54432C6.35664 6.6589 7.06867 6.99073 7.646 7.49998" stroke="#3B82F6" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M5 6.5C6.38071 6.5 7.5 5.38071 7.5 4C7.5 2.61929 6.38071 1.5 5 1.5C3.61929 1.5 2.5 2.61929 2.5 4C2.5 5.38071 3.61929 6.5 5 6.5Z" stroke="#3B82F6" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M9.5 8V11" stroke="#3B82F6" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M11 9.5H8" stroke="#3B82F6" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
            </g>
            <defs>
                <clipPath id="clip0_11200_101115">
                    <rect width="12" height="12" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
};

// Export the IconFollow component
export default IconFollow;
