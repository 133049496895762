import { Fragment } from 'react';
import { MdMoreVert } from 'react-icons/md'
import { IDocument, IDocumentObject } from '@/shared/interfaces/interfaces'
import Typography from "@/components/baseComponents/Typography"
import TextField from '@/components/baseComponents/TextField'
import Button from "@/components/baseComponents/Button/Button"
import Select from "@/components/baseComponents/Select"
import { Transition, Popover } from '@headlessui/react';
import XMark from '@/assets/icons/XMark.png'
import { formatSlashDate, formatTime } from "@/shared/config/constants";

type IProps = {
  docOptions: any[]
  mainDoc: IDocument;
  handleSelectDocument: Function;
  changeRenameFile: Function;
  changeDocType: Function;
  handleFileChange: Function;
  deleteDocLine: Function;
  addDocsLine: Function;
  errorMainDocument: boolean;
  additionalDocs: Array<IDocumentObject>;
  docTypeOptions: Array<any>;
};

type IPropsActionDelete = {
  index: number;
  deleteDocLine: Function;
}
const ActionDelete = (props: IPropsActionDelete) => {
  const { index, deleteDocLine }  = props
  return (
    <div className="text-right flex justify-end items-center">
      <Popover as="div" className="relative">
        <Popover.Button className="flex items-center justify-center disabled:text-gray-400">
          <MdMoreVert />
        </Popover.Button>
        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Popover.Panel className="z-20 absolute right-[1.3rem] -top-5 w-[6rem] origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
            <Typography variant='body' className='text-center cursor-pointer' onClick={() => deleteDocLine(index)}>Delete</Typography>
          </Popover.Panel>
        </Transition>
      </Popover>
    </div>
  )
}

const Docs = (props: IProps) => {
  return (
    <div>
      <div className="grid grid-cols-3 gap-2">
        <div className="col-span-2">
          <Typography variant="h3" color="primary">
            Offer Documents *
          </Typography>
          <Typography variant="caption" color="secondary">
            Select offer document to be generated
          </Typography>
          <Select
            className='w-full mt-8'
            options={props.docOptions}
            value={props.docOptions.filter(item => item.value === props.mainDoc?._id).length > 0 ? props.docOptions.filter(item => item.value === props.mainDoc?._id)[0] : {label: '', value: ''}}
            name='document'
            onChange={(e) => props.handleSelectDocument(e)}
          />
          {props.errorMainDocument && <Typography variant='caption' className='text-[#E01010] absolute mt-[2px]'>Please select an offer document</Typography>}
        </div>
        <div className="col-span-1">
          {props.mainDoc && props.mainDoc?.file && (  
            <iframe
              title="PDF Preview"
              src={props.mainDoc?.file + '#toolbar=0&navpanes=0'}
              width="100%"
              height="300px"
              className="mb-8"
            />
          )}
        </div>
      </div>
      <div className='w-full mt-12'>
        <Typography variant="h3" color="primary">
          Additional Documents
        </Typography>
        {props.additionalDocs?.length > 0 &&
          props.additionalDocs.map((documentItem: any, index: any) => {
            return (
              <div className="mt-6 flex" key={index}>
                <div className="w-1/4 mr-2">
                  <Typography variant="caption" color="primary">
                    Document Type
                  </Typography>
                  <Select
                    className="bg-white"
                    options={props.docTypeOptions}
                    name="document_type_1"
                    value={{
                      value: documentItem.docType,
                      label: documentItem.docType,
                    }}
                    onChange={(value) => props.changeDocType(value, index)}
                  />
                </div>
                <div className="w-1/4 mr-2">
                  <Typography variant="caption" color="primary">
                    Rename File
                  </Typography>
                  <TextField
                    placeholder=""
                    value={documentItem.rename}
                    onChange={(e) => props.changeRenameFile(e, index)}
                    className={`w-full`}
                  />
                </div>
                <div className="w-1/2">
                  <Typography variant="caption" color="primary">
                    Document
                  </Typography>
                  {documentItem.file && documentItem.isFile ? (
                    <div>
                      <div className="w-full flex justify-between">
                        <Typography
                          variant="body"
                          className="text-[13px] text-[#4C42D7] w-[95%]"
                        >
                          {documentItem.file.name}
                        </Typography>
                        <div className="text-right flex justify-end items-center">
                          <ActionDelete index={index} deleteDocLine={props.deleteDocLine} />
                        </div>
                      </div>
                      <div className="w-full flex">
                        <Typography
                          variant="body"
                          color="secondary"
                          className="text-[13px]"
                        >
                          Uploaded on{" "}
                          {formatSlashDate(documentItem.uploadAt) +
                            " " +
                            formatTime(documentItem.uploadAt)}
                        </Typography>
                      </div>
                    </div>
                  ) : documentItem.file && !documentItem.isFile ? (
                    <div>
                      <div className="w-full flex justify-between">
                        <Typography
                          variant="body"
                          className="text-[13px] text-[#4C42D7] w-[95%] truncate "
                        >
                          {documentItem.file}
                        </Typography>
                        <ActionDelete index={index} deleteDocLine={props.deleteDocLine} />
                      </div>
                      <div className="w-full flex">
                        <Typography
                          variant="body"
                          color="secondary"
                          className="text-[13px]"
                        >
                          Uploaded on{" "}
                          {formatSlashDate(documentItem.uploadAt) +
                            " " +
                            formatTime(documentItem.uploadAt)}
                        </Typography>
                      </div>
                    </div>
                  ) : (
                    <div className="flex">
                      <input
                        type="text"
                        readOnly
                        placeholder="Browse..."
                        className="h-[38px] w-[85%] border-gray-300 border-r-0"
                      ></input>
                      <label
                        htmlFor={"file_input_" + index.toString()}
                        className="bg-gray-100 h-[39px] px-4 pt-2"
                      >
                        <Typography variant="button2">Browse</Typography>
                      </label>
                      <input
                        id={"file_input_" + +index.toString()}
                        type="file"
                        className="hidden"
                        accept=".pdf"
                        onChange={(e) => props.handleFileChange(e, index)}
                      /> 
                      <img
                        src={XMark}
                        alt="XMark"
                        className="cursor-pointer h-[15px] w-[15px] mt-[15px] ml-[10px]"
                        onClick={() => props.deleteDocLine(index)}
                      />
                    </div>
                  )}
                </div>
              </div>
            );
          })}
        <div className="mt-6">
          <Button
            variant="outlined"
            size="small"
            className="py-1 h-[30px] border-[#F0F4FA]"
            onClick={() => props.addDocsLine()}
          >
            <Typography variant="button2" color="secondary">
              + Add Document
            </Typography>
          </Button>
        </div>
      </div>
    </div>
  );
};

export default Docs