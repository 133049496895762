import { useLayoutEffect, useRef, useState } from "react";
import Typography from "../Typography";
import Pagination from "@/components/baseComponents/Pagination";

// import Note from '@/assets/icons/note.png'
import { DeleteMenu, LikeMenu, StatusMenu } from "../Menu";
type IProps = {
  data: Array<any>;
  onClickRow: Function;
  editLead: Function;
  changeSelectedPeople: Function;
  totalPage: number;
  currentPage: number;
  recordsPerpage: number;
  onSetPage: Function;
  setStatus: Function;
  convertToClient: Function;
  archive: Function;
  setRating: Function;
  totalCount: number;
};

const Table = ({
  data,
  onClickRow,
  editLead,
  changeSelectedPeople,
  totalPage,
  currentPage,
  recordsPerpage,
  onSetPage,
  setRating,
  setStatus,
  archive,
  convertToClient,
  totalCount,
}: IProps) => {
  const type = location.pathname.replace("/", "").replace("s", "").replace("/", "");
  const checkbox = useRef();
  const [checked, setChecked] = useState(false);
  const [indeterminate, setIndeterminate] = useState(false);
  const [selectedPeople, setSelectedPeople] = useState<Array<any>>([]);

  useLayoutEffect(() => {
    const isIndeterminate =
      selectedPeople.length > 0 && selectedPeople.length < data.length;
    setChecked(
      selectedPeople.length !== 0 &&
        data.length !== 0 &&
        selectedPeople.length === data.length
    );
    setIndeterminate(isIndeterminate);
    (checkbox.current as any).indeterminate = isIndeterminate;
  }, [selectedPeople]);

  const toggleAll = () => {
    setIndeterminate(false);
    setChecked(!checked && !indeterminate);
    setSelectedPeople(checked || indeterminate ? [] : data);
    changeSelectedPeople(checked || indeterminate ? [] : data);
  };

  const checkedPerson = (e: any, person: any) => {
    const temp = e.target.checked
      ? [...selectedPeople, person]
      : selectedPeople.filter((p) => p !== person);

    setSelectedPeople(temp);
    changeSelectedPeople(temp);
  };

  return (
    <div className="flex flex-col justify-between min-h-[calc(100vh-230px)] bg-white rounded-lg shadow-md pb-0">
      <div className="flow-root">
        <div className="min-w-full">
          <table className="min-w-full divide-y divide-gray-300">
            <thead className="xl:table-header-group sm:max-xl:hidden max-sm:hidden">
              <tr className="border-b">
                <th className="relative text-left px-[16px] py-[20px]">
                  <input
                    type="checkbox"
                    className="h-4 w-4 rounded-sm border-gray-200 z-10"
                    ref={checkbox as any}
                    checked={checked}
                    onChange={toggleAll}
                  />
                </th>
                <th className="text-left px-[16px] py-[20px] !border-0">
                  <Typography variant="table-headers" className="capitalize">{type}</Typography>
                </th>
                <th className="text-left px-[16px] py-[20px]">
                  <Typography variant="table-headers">Email</Typography>
                </th>
                <th className="text-left px-[16px] py-[20px]">
                  <Typography variant="table-headers">Phone</Typography>
                </th>
                <th className="text-left px-[16px] py-[20px]">
                  <Typography variant="table-headers" className="capitalize">{type} Type</Typography>
                </th>
                <th className="text-left px-[16px] py-[20px]">
                  <Typography variant="table-headers">Status</Typography>
                </th>
              </tr>
            </thead>
            <tbody className="!border-0">
              {data.map((person) => (
                <tr
                  key={person._id}
                  className={`cursor-pointer border-b-1 ${
                    selectedPeople.includes(person)
                      ? `bg-gray-100`
                      : `hover:bg-gray-50`
                  }`}
                >
                  <td className="relative px-[16px] py-[20px]">
                    {selectedPeople.includes(person) && (
                      <div className="absolute inset-y-0 left-0 w-0.5 bg-indigo-600" />
                    )}
                    <input
                      type="checkbox"
                      className="h-4 w-4 rounded-sm border-gray-200"
                      value={person._id}
                      checked={selectedPeople.includes(person)}
                      onChange={(e) => checkedPerson(e, person)}
                    />
                  </td>
                  <td
                    className="px-[16px] py-[20px]"
                    onClick={() => onClickRow(person)}
                  >
                    <Typography variant="table-row-content" color="primary">
                      {person.firstName + " " + person.lastName}
                    </Typography>
                    <Typography
                      variant="table-row-content"
                      color="secondary"
                      className="hidden sm:max-xl:block max-sm:block"
                    >
                      {person.email}
                    </Typography>
                    <Typography
                      variant="table-row-content"
                      color="secondary"
                      className="hidden sm:max-xl:block max-sm:block"
                    >
                      {person.phoneNumber}
                    </Typography>
                  </td>
                  <td
                    className="px-[16px] py-[20px] xl:table-cell sm:max-xl:hidden max-sm:hidden"
                    onClick={() => onClickRow(person)}
                  >
                    <Typography variant="table-row-content" color="primary">
                      {person.email}
                    </Typography>
                  </td>
                  <td
                    className="px-[16px] py-[20px] xl:table-cell sm:max-xl:hidden max-sm:hidden"
                    onClick={() => onClickRow(person)}
                  >
                    <Typography variant="table-row-content" color="primary">
                      {person.phoneNumber}
                    </Typography>
                  </td>
                  <td
                    className="px-[16px] py-[20px] max-sm:hidden"
                    onClick={() => onClickRow(person)}
                  >
                    <Typography
                      variant="table-row-content"
                      className={`text-center rounded-lg p-1 w-16 ${
                        person.leadType === `Seller`
                          ? `bg-blue-50 text-blue-600`
                          : `bg-purple-50 text-purple-600`
                      }`}
                    >
                      {person.leadType}
                    </Typography>
                  </td>
                  <td className="px-1 py-[20px] lg:table-cell md:hidden sm:hidden max-sm:hidden">
                    <StatusMenu item={person} setStatus={setStatus} />
                  </td>
                  <td className="px-[16px] py-[20px] lg:table-cell md:hidden sm:hidden max-sm:hidden">
                    <LikeMenu item={person} setPriority={setRating} />
                  </td>
                  <td className="px-[16px] py-[20px] flex justify-center">
                    <DeleteMenu
                      item={person}
                      editLead={editLead}
                      archive={archive}
                      convertToClient={convertToClient}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      <nav className="flex items-center lg:justify-between sm:max-lg:justify-center max-sm:justify-center w-full px-[16px] py-[20px]">
        <Typography
          variant="body"
          color="secondary"
          className="lg:block sm:hidden max-sm:hidden"
        >
          Showing {totalCount > 0 ? (currentPage - 1) * recordsPerpage + 1 : 0}{" "}
          to{" "}
          {totalCount >= currentPage * recordsPerpage
            ? currentPage * recordsPerpage
            : totalCount}{" "}
          of {totalCount} results
        </Typography>
        <Pagination
          totalPage={totalPage}
          currentPage={currentPage}
          onSetPage={onSetPage}
          loadingData={false}
        />
      </nav>
    </div>
  );
};

export default Table;
