import Typography from "@/components/baseComponents/Typography";
import { Button } from "@/components/baseComponents/Button";
import { useNavigate } from "react-router-dom";
import ListingCard from "@/components/mainComponents/Landing/ListingCard";
import { useAppDispatch } from "@/redux/hooks";
import { useEffect, useState } from "react";
import { getMyListingsByUserId } from "@/redux/myListings/myListingSlice";
import { GetMyListingsDto, IMyListing } from "@/shared/interfaces/interfaces";
import { notify } from "@/shared/services/notify";
import ListingCardSkeleton from "../../components/mainComponents/Landing/ListingCardSkeleton";
import ListingDrawer from "./ListingDrawer";

const LandingPage = () => {
  const navigate = useNavigate();

  const dispatch = useAppDispatch();
  const [isLoading, setIsLoading] = useState(true);

  const [listings, setListings] = useState<IMyListing[]>([]);

  const [openListingDrawer,setOpenListingDrawer] = useState<boolean>(false);
  const [selectedListing, setSelectedListing] = useState<IMyListing | undefined>(undefined);
  const [selectedIndex, setSelectedIndex] = useState<number>(1);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = () => {
    const data: GetMyListingsDto = {
      userId: "",
      keyword: "",
      sortType: "Descending",
      sortField: "Latest",
      recordsPerPage: 20,
      currentPage: 1,
      status: "",
    };

    dispatch(getMyListingsByUserId(data))
      .then((res: any) => {
        try {
          const newListings = res.payload.listings.slice(0, 6); 
          setListings([...listings, ...newListings]);

        } catch (e) {
          notify(false, "Something went wrong.");
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  };
  const listingSelected = (listing?:IMyListing) =>{
    setSelectedListing(listing);
    setOpenListingDrawer(true);
  }

  return (
    <>
     {openListingDrawer && (
        <div className="!bg-[#00000075] h-screen w-full fixed top-0 left-0 z-20"></div>
     )}
     <ListingDrawer
        open={openListingDrawer}
        closeDrawer={() => setOpenListingDrawer(!openListingDrawer)}
        listing={selectedListing}
        onNext={() => {
          if (listings.length - 1 == selectedIndex) {
            setSelectedIndex(0);
          } else {
            setSelectedIndex(selectedIndex + 1);
          }
          setSelectedListing(listings[selectedIndex]);
        }}
        onPrev={() => {
          if (selectedIndex == 0) {
            setSelectedIndex(listings.length - 1);
          } else {
            setSelectedIndex(selectedIndex - 1);
          }
          setSelectedListing(listings[selectedIndex]);
        }}
      />
      <div className="flex w-full bg-custom-gradient">
        <div className="max-w-screen-xl mx-auto w-full">
          <div className="flex flex-col w-full lg:pt-20 pt-12">
            <div className="hidden lg:flex w-full justify-between items-center">
              <div className="flex flex-col w-full gap-4">
                <Typography
                  variant="h1"
                  color="black"
                  className="whitespace-nowrap text-[32px] font-bold font-jakarta"
                >
                  For Sale Near You
                </Typography>
                <Typography
                  variant="input-label"
                  className="whitespace-nowrap text-[16px] text-gray-400 font-medium font-jakarta"
                >
                  Based on your location settings.
                </Typography>
              </div>
              <Button
                className={`py-3 !h-full text-white w-[300px] bg-[#7065F0] font-jakarta font-bold text-[16px]`}
                variant="text"
                onClick={() => {
                  navigate("property?loc=New York, USA");
                }}
              >
                Browse more properties
              </Button>
            </div>
            <div className="flex flex-col lg:hidden gap-4 px-6">
              <Typography
                variant="input-label"
                className=" text-[40px] font-bold font-jakarta text-center"
              >
                Based on your location
              </Typography>
              <div className="flex justify-center w-full">
                <div className="flex w-full md:w-2/3 justify-center">
                  <Typography
                    variant="input-label"
                    className=" text-[20px] font-medium font-jakarta text-center"
                  >
                    Some of our picked properties near you location.
                  </Typography>
                </div>
              </div>
            </div>
            {isLoading && (
              <>
                <div className="md:hidden flex w-full px-10 pt-12 relative overflow-x-auto scrollbar-hide">
                  <div className="flex gap-4 md:pl-10 px-4 py-2">
                  {Array.from({ length: 6 }).map((_, index) => (
                    <ListingCardSkeleton key={index} />
                  ))}{" "}
                  </div>
                </div>
                <div className="hidden md:flex w-full my-12 px-6">
                  <div className="grid lg:grid-cols-3 grid-cols-2 w-full gap-8">
                  {Array.from({ length: 6 }).map((_, index) => (
                    <ListingCardSkeleton key={index} />
                  ))}{" "}
                  </div>
                </div>
              </>
            )}

            
            {!isLoading && (
              <>
                <div className="md:hidden flex w-full px-10 pt-12 relative overflow-x-auto scrollbar-hide">
                  <div className="flex gap-4 md:pl-10 px-4 py-2">
                    {listings.map((listing, index) => (
                      <ListingCard listing={listing} key={index} isSelected={(listing)=>listingSelected(listing)}/>
                    ))}
                  </div>
                </div>
                <div className="lg:hidden flex py-12 w-full justify-center">
                  <Button
                    className={`py-4 !h-full text-white w-[300px] bg-[#7065F0] font-jakarta font-bold text-[16px]`}
                    variant="text"
                    onClick={() => {
                      navigate("property");
                    }}
                  >
                    Browse more properties
                  </Button>
                </div>
                <div className="hidden md:flex w-full my-12 px-6">
                  <div className="grid lg:grid-cols-3 grid-cols-2 w-full gap-8">
                    {listings.map((listing, index) => (
                      <ListingCard listing={listing} key={index} isSelected={(listing)=>listingSelected(listing)}/>
                    ))}
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default LandingPage;
