import ChatDrawer from "@/components/layouts/bars/Components/ChatDrawer";
import { createMessage, getMessages, updateReadStatus } from "@/redux/chat/chatSlice";
import { useAppDispatch, useAppSelector } from "@/redux/hooks";
import { getUser } from "@/redux/user/userSlice";
import { CreateMessageDto, IMessageDto, IParticipantDto, UpdateReadStatus } from "@/shared/interfaces/interfaces";
import { Dialog, Transition } from "@headlessui/react";
import { Fragment, useEffect, useState } from 'react';
interface IProps {
    open: boolean;
    openDrawer: React.Dispatch<React.SetStateAction<boolean>>;
    listing: any;
    curParticipantId: string | undefined;
    handleProfileClose?: React.Dispatch<React.SetStateAction<boolean>>
}
const ChatProfileDrawer = ({ ...props }: IProps) => {
    const dispatch = useAppDispatch();
    const user = useAppSelector(getUser);
    const messages = useAppSelector(getMessages);

    const [selectedParticipant, setSelectedParticipant] =
        useState<IParticipantDto>({ _id: '', name: '', isOnline: false });

    const [viewMessages, setViewMessages] = useState<IMessageDto[]>([]);
    const [content, setContent] = useState<string>("");

    useEffect(() => {
        if (props.listing) {
            setSelectedParticipant({
                _id: props.listing?.owner?.agent?._id,
                name: props.listing?.owner?.agent?.firstName ?? '' + " " + props.listing?.owner?.agent?.lastName ?? '',
                avatarURL: props.listing?.owner?.agent?.avatarURL ?? '',
                isOnline: props.listing?.owner?.isOnline,
            });
        }
    }, [props.listing])

    const handleFocusMainArea = () => {
        if (messages.length > 0) {
            const unreadMessages = messages.filter(
                (message) => message.sender === props.curParticipantId && !message.isRead
            );

            if (unreadMessages.length > 0) {
                const ids: string[] = unreadMessages
                    .map((message) => message?._id)
                    .filter((id) => id !== undefined)
                    .map((id) => id as string);

                var data: UpdateReadStatus = {
                    ids: ids,
                    userId: user?._id,
                };
                dispatch(updateReadStatus(data));
            }
        }
    };
    useEffect(() => {
        if (messages.length > 0) {
            const viewMessages = messages.filter((msg: IMessageDto) => {
                return (
                    (msg.sender === user?._id && msg.receiver === props.curParticipantId) ||
                    (msg.receiver === user?._id && msg.sender === props.curParticipantId)
                );
            });
            setViewMessages(viewMessages);
        }
    }, [messages, user, props.curParticipantId]);

    const sendChatMessage = (content: string) => {
        if (props.curParticipantId && props.curParticipantId !== "") {
            const messageData: IMessageDto = {
                sender: user?._id,
                receiver: props.curParticipantId,
                content: content,
            };

            const createData: CreateMessageDto = {
                userId: user?._id,
                data: messageData,
            };

            dispatch(createMessage(createData));
        }
    };


    return (
        <Transition.Root show={props.open} as={Fragment}>
            <Dialog
                as="div"
                className="relative z-30"
                onClose={() => {}}
            >
                <div className="fixed inset-0 overflow-hidden"
                    style={{ backgroundColor: 'rgba(0,0,0,0.2)' }}
                >
                    <div className="absolute inset-0 overflow-hidden">
                        <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full md:pl-10 pl-0 sm:pl-16">
                            <Transition.Child
                                as={Fragment}
                                enter="transform transition ease-in-out duration-500 sm:duration-300"
                                enterFrom="translate-x-full"
                                enterTo="translate-x-0"
                                leave="transform transition ease-in-out duration-500 sm:duration-300"
                                leaveFrom="translate-x-0"
                                leaveTo="translate-x-full"
                            >
                                <Dialog.Panel className="pointer-events-auto h-screen w-full min-w-[414px] sm:w-[414px] md:w-[600px] overflow-y-auto hide-scrollbar ">
                                    <div className="flex bg-white w-full h-screen flex-col gap-3  overflow-y-auto hide-scrollbar">
                                        <ChatDrawer
                                            open={props.open}
                                            setOpen={props.openDrawer}
                                            handleFocusMainArea={() => handleFocusMainArea()}
                                            viewMessages={viewMessages}
                                            selectedParticipant={selectedParticipant}
                                            listing={props.listing}
                                            content={content}
                                            setContent={setContent}
                                            sendChatMessage={sendChatMessage}
                                            isProfileChat={true}
                                            handleProfileClose={props.handleProfileClose}
                                        />
                                    </div>
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    )
}


export default ChatProfileDrawer;
