import { useEffect, useState } from "react";
import Typography from "@/components/baseComponents/Typography";
import Select from "@/components/baseComponents/Select";
import { DatePicker } from "@/components/baseComponents/DatePickers";
import { ListingAdvancedSearchDto } from '@/shared/interfaces/interfaces';

const listingTypeOptions = [
  { 
    value: "Exclusive Agency",
    label: "Exclusive Agency" 
  },
  {
    value: "Exclusive Right To Lease",
    label: "Exclusive Right To Lease",
  },
  {
    value: "Exclusive Right To Sell",
    label: "Exclusive Right To Sell",
  },
  {
    value: "Exclusive Right With Exception",
    label: "Exclusive Right With Exception",
  },
  {
    value: "Net (Listing Agreement)",
    label: "Net (Listing Agreement)",
  },
  { value: "Open", label: "Open" },
  { value: "Probate", label: "Probate" },
];

const listingSaleTypeOptions = [
  { value: "Auction", label: "Auction" },
  { value: "Bankruptcy Property", label: "Bankruptcy Property", },
  { value: "HUD Owned", label: "HUD Owned" },
  { value: "In Foreclosure", label: "In Foreclosure" },
  { value: "Notice Of Default", label: "Notice Of Default" },
  { value: "Probate Listing", label: "Probate Listing" },
  { value: "Real Estate Owned", label: "Real Estate Owned" },
  { value: "Short Sale", label: "Short Sale" },
  { value: "Standard", label: "Standard" },
  { value: "Third Party Approval", label: "Third Party Approval", },
];

type IProps = {
  value?: ListingAdvancedSearchDto;
  setValue?: Function;
  handleInputChange?: Function;
  handleSelectChange?: Function;
}

const ListingDetails = (props: IProps) => {
  const [index, setIndex] = useState(0);
  
  const handleSelectChange = (value: any, name: string, isMulti?: boolean) => {
    props.handleSelectChange && props.handleSelectChange(value, name, isMulti);
  }

  const handleInputChange = (value: any, name: string) => {
    props.handleInputChange && props.handleInputChange(value, name);
  }

  useEffect(() => {
    setIndex(prev => prev + 1);
  }, [props.value])

  return (
    <div>
      <Typography variant="h3" color="primary">Listing Details</Typography>
      <div className="grid grid-cols-1 sm:grid-cols-2 gap-3 mt-[15px]">
        <div className="col-span-2 md:col-span-1">
          <Typography variant="caption" color="secondary">Date Listed</Typography>
          <DatePicker 
            key={index}
            name='listingDateListed'
            value={
              props.value?.listingDateListed !== undefined
                ? new Date(props.value?.listingDateListed)
                : null
            }
            leadingClassName={'right-0 top-1 left-1'} 
            innerClassName={`pl-10 ${
              props.value?.listingDateListed ? "bg-[#E5E7EB]" : "bg-[#F1F5F9]"
            }`} 
            onChange={(value) =>
              handleInputChange(value, "listingDateListed")
            }
          />
        </div>
        <div className="col-span-2 md:col-span-1">
          <Typography variant="caption" color="secondary">Listing Expiration Date</Typography>
          <DatePicker
            key={index}
            name='listingExpirationDate'            
            leadingClassName={'right-0 top-1 left-1'} 
            innerClassName={`pl-10 ${
              props.value?.listingExpirationDate ? "bg-[#E5E7EB]" : "bg-[#F1F5F9]"
            }`} 
            value={
              props.value?.listingExpirationDate !== undefined
                ? new Date(props.value?.listingExpirationDate)
                : null
            }
            onChange={(value) =>
              handleInputChange(value, "listingExpirationDate")
            }
          />
        </div>
      </div>
      <div className="grid grid-cols-1 sm:grid-cols-2 gap-3 mt-[15px]">
        <div className="col-span-2 md:col-span-1">
          <Typography variant="caption" color="secondary">Listing Type</Typography>
          <Select
            options={listingTypeOptions}
            name="listingType"
            value={{
              value: props.value?.listingType,
              label: props.value?.listingType,
            }}
            onChange={(value) =>
              handleSelectChange(value, "listingType")
            }
          />
        </div>
        <div className="col-span-2 md:col-span-1">
          <Typography variant="caption" color="secondary">Sale Type</Typography>
          <Select
            options={listingSaleTypeOptions}
            name="listingSaleType"
            value={{
              value: props.value?.listingSaleType,
              label: props.value?.listingSaleType,
            }}
            onChange={(value) =>
              handleSelectChange(value, "listingSaleType")
            }
          />
        </div>
      </div>
    </div>
  )
}

export default ListingDetails;