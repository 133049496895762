// Define the type for the component props
type Props = {
  width: number;
  height: number;
  stroke?: string;
  className?: string;
  innerstroke?: any;
};

// Define the IconArrowUp component
const IconArrowUp = (props: Props) => {
  // Return the SVG for the icon
  return (
    <svg
      viewBox="0 0 17 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M12.5 10.2031L8.5 6.20312L4.5 10.2031"
        stroke={props.innerstroke ? props.innerstroke : "#1F2937"}
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

// Export the IconArrowUp component
export default IconArrowUp;
