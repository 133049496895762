type Props = {
    width: number;
    height: number;
    stroke?: string;
    className?: string;
};

const IconNotebook = (props: Props) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 17 16" fill="none" {...props}>
            <path
                d="M6.5 1.33203V14.6654M3.83333 3.9987H3.16667C2.79848 3.9987 2.5 4.29717 2.5 4.66536C2.5 5.03355 2.79848 5.33203 3.16667 5.33203H3.83333M3.83333 10.6654H3.16667C2.79848 10.6654 2.5 10.9638 2.5 11.332C2.5 11.7002 2.79848 11.9987 3.16667 11.9987H3.83333M5.96667 14.6654H11.7C12.4467 14.6654 12.8201 14.6654 13.1053 14.52C13.3562 14.3922 13.5602 14.1882 13.688 13.9374C13.8333 13.6521 13.8333 13.2788 13.8333 12.532V3.46536C13.8333 2.71863 13.8333 2.34526 13.688 2.06004C13.5602 1.80916 13.3562 1.60519 13.1053 1.47736C12.8201 1.33203 12.4467 1.33203 11.7 1.33203H5.96667C5.21993 1.33203 4.84656 1.33203 4.56135 1.47736C4.31046 1.60519 4.10649 1.80916 3.97866 2.06004C3.83333 2.34526 3.83333 2.71863 3.83333 3.46536V12.532C3.83333 13.2788 3.83333 13.6521 3.97866 13.9374C4.10649 14.1882 4.31046 14.3922 4.56135 14.52C4.84656 14.6654 5.21993 14.6654 5.96667 14.6654Z"
                stroke={props.stroke ?? "#4F46E5"}
                strokeWidth="1.2"
                strokeLinecap="round"
            />
        </svg>
    );
};

export default IconNotebook;