type Props = {
  width: number;
  height: number;
  stroke?: string;
  className?: string;
  innerstroke?: string;
};

const IconPhoto = (props: Props) => {
  return (
    <svg
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M6.00065 14.6654H10.0007C13.334 14.6654 14.6673 13.332 14.6673 9.9987V5.9987C14.6673 2.66536 13.334 1.33203 10.0007 1.33203H6.00065C2.66732 1.33203 1.33398 2.66536 1.33398 5.9987V9.9987C1.33398 13.332 2.66732 14.6654 6.00065 14.6654Z"
        stroke="#7065F0"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.99935 6.66667C6.73573 6.66667 7.33268 6.06971 7.33268 5.33333C7.33268 4.59695 6.73573 4 5.99935 4C5.26297 4 4.66602 4.59695 4.66602 5.33333C4.66602 6.06971 5.26297 6.66667 5.99935 6.66667Z"
        stroke="#7065F0"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1.7793 12.635L5.06596 10.4283C5.59263 10.075 6.35263 10.115 6.82596 10.5217L7.04596 10.715C7.56596 11.1617 8.40596 11.1617 8.92596 10.715L11.6993 8.335C12.2193 7.88833 13.0593 7.88833 13.5793 8.335L14.666 9.26833"
        stroke="#7065F0"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default IconPhoto;
