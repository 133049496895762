import React from 'react'
import { Button } from '@/components/baseComponents/Button'
import AdvancedTable from '@/components/baseComponents/AdvancedTable'
import Typography from '@/components/baseComponents/Typography'
import { useAppSelector, useAppDispatch } from '@/redux/hooks'
import { getUser, updateMyInfo } from '@/redux/user/userSlice'
import { bookmarkMyListings, getMyBookmarkedListingsByUserId, getMyListings } from '@/redux/myListings/myListingSlice'
import { BookmarkMyListingDto, GetMyListingsDto, UpdateMyInfoDto } from '@/shared/interfaces/interfaces'
import { notify } from '@/shared/services/notify'
import { XMarkIcon } from '@heroicons/react/24/outline'
import { NumericFormat } from 'react-number-format'
import AdvancedSearch from './AdvancedSearch'
import View from '../myListings/View'
import Filter from '@/components/mainComponents/Filter'
import SortAdvanced from '@/components/mainComponents/SortAdvanced'
import MyListingsDrawer from '../myListings/Drawer'
import DefaultListingImage from "@/assets/images/default_listing_image.webp"
import IconWrapper from '@/components/baseComponents/IconWrapper'

const TableFields = [
  {
    name: "",
    type: "checkbox",
    slug: "_id",
    class_name: "text-left !p-[8px]",
  },
  {
    name: "",
    type: "image",
    slug: "imageSrc",
    imageClass: "max-w-[4rem]",
    class_name: "text-left !py-[8px]",
  },
  {
    name: "Address",
    type: "text",
    slug: "address",
    class_name: "text-left !p-[8px]",
  },
  {
    name: "Status",
    type: "status_show",
    slug: "status",
    class_name: "text-left !p-[8px]",
  },
  {
    name: "Price",
    type: "text",
    slug: "listingListPriceHtml",
    class_name: "text-left !p-[8px]",
  },
  {
    name: "Bedrooms",
    type: "text",
    slug: "propertyBedrooms",
    class_name: "text-left !p-[8px]",
  },
  {
    name: "Bathrooms",
    type: "text",
    slug: "propertyBathrooms",
    class_name: "text-left !p-[8px]",
  },
  {
    name: "Property Type",
    type: "text",
    slug: "propertyType",
    class_name: "text-left !py-[8px]",
  },
];

const SortFieldOptions = [
  { value: 'address', label: 'Address' },
  { value: 'property_type', label: 'Property Type' },
  { value: 'bedrooms', label: 'Bedrooms' },
  { value: 'bathrooms', label: 'Bathrooms' },
];

const Saved = () => {
  const dispatch = useAppDispatch();
  const listings = useAppSelector(getMyListings);
  const user = useAppSelector(getUser);

  const [currentTab, setCurrentTab] = React.useState<string>("Active");
  const [keyword, setKeyword] = React.useState<string>("");
  const [sortType, setSortType] = React.useState<string>("Descending");
  const [sortField, setSortField] = React.useState<string>("");

  const [filteredListings, setFilteredListings] = React.useState<Array<any>>([]);
  const [totalCount, setTotalCount] = React.useState<number>(0);
  const [currentPage, setCurrentPage] = React.useState<number>(1);
  const [recordsPerPage, setRecordsPerPage] = React.useState<number>(25);

  const [open, setOpen] = React.useState<boolean>(false);
  const [openConfirm, setOpenConfirm] = React.useState<boolean>(false);
  const [openView, setOpenView] = React.useState<boolean>(false);
  const [openMyListingsDrawer, setOpenMyListingsDrawer] = React.useState<boolean>(false);

  const [viewData, setViewdata] = React.useState<any>(null);
  const [selectedRows, setSelectedRows] = React.useState<Array<any>>([]);

  const changeKeyword = (keyword: string) => setKeyword(keyword);
  const changeSortType = (value: string) => setSortType(value);
  const changeSortField = (option: any) => setSortField(option.label);
  const onSetPage = (page: number) => setCurrentPage(page);
  const onSelectRow = (value: any) => setSelectedRows(value);
  const onClickRow = (value: any) => {
    setOpenView(true);
    setViewdata(value);
  };


  const filterListings = () => {
    const data: GetMyListingsDto = {
      userId: user._id,
      keyword: keyword,
      sortType: sortType,
      sortField: sortField,
      recordsPerPage: recordsPerPage,
      currentPage: currentPage,
      status: "Bookmarked",
    };

    dispatch(getMyBookmarkedListingsByUserId(data)).then((res: any) => {
      try {
        setTotalCount(res.payload.totalCount);
      } catch (e) {
        notify(false, "Something went wrong.");
      }
    });
  };

  const makeTableData = (data: Array<object>) => {
    return data?.map((item: any) => {
      const tempListing = JSON.parse(JSON.stringify(item));

      if (item?.listingAddress) {
        tempListing.address = (
          <div>
            {item?.isPublic === 1 && (
              <div className="truncate">
                {item?.listingAddress?.streetLine +
                  " " +
                  (item?.listingUnit || item?.listingAddress?.secondary)}
              </div>
            )}
            <div className={`truncate ${item.isPublic === 1 ? `text-tertiary` : `text-primary`}`}>
              {item?.listingAddress?.city +
                ", " +
                item?.listingAddress?.state +
                " " +
                item?.listingAddress?.zipcode}
            </div>
          </div>
        );
      } else {
        tempListing.address = (
          <div className={`!line-clamp-2 !whitespace-normal ${item.viewed ? `` : `font-semibold`}`}>
            Address Kept Private
          </div>
        );
      }

      tempListing.imageSrc = (tempListing.propertyPhotos && tempListing.propertyPhotos[0]?.file) || DefaultListingImage;
      tempListing.listingListPriceHtml = <NumericFormat displayType="text" thousandSeparator="," prefix="$" value={item.listingListPrice} />

      return tempListing;
    });
  };

  const onUnbookmarkSelectedListings = () => {
    if (selectedRows.length > 0) {
      let ids = selectedRows.map((listing: any) => {
        return listing._id;
      });
      const data: BookmarkMyListingDto = {
        ids: ids,
        bookmark: false,
        userId: user._id,
        search: {
          userId: user._id,
          keyword: "",
          sortField: "",
          sortType: "",
          recordsPerPage: 25,
          currentPage: 1,
          status: "Bookmarked"
        }
      };
      dispatch(bookmarkMyListings(data)).then((res) => {
        try {
          setTotalCount(res.payload.totalCount);
          notify(res.payload.success, res.payload.message);
          filterListings();
          let data: UpdateMyInfoDto = {
            email: user.email,
            data: user.agent
          }
          dispatch(updateMyInfo(data));
        } catch (e) {
          notify(false, "Something went wrong.");
        }
      });
    }
  }

  const ConfirmationModal = ({ openConfirm, setOpenConfirm }: any) => (
    openConfirm && (
      <div
        className="bg-[#00000040] w-full h-screen flex items-center justify-center fixed top-0 left-0"
        onClick={() => setOpenConfirm(false)}
      >
        <div className="rounded-lg max-w-[435px] w-full bg-white p-3">
          <div>
            <div className="flex justify-end w-full text-secondary hover:text-primary cursor-pointer">
              <XMarkIcon
                className="h-6 w-6"
                aria-hidden="true"
                onClick={() => setOpenConfirm(false)}
              />
            </div>
            <div className="w-full flex justify-center mt-[10px]">
              <Typography variant="button2">
                Are you sure you want to archive this listing?
              </Typography>
            </div>
            <div className="flex justify-center mt-[20px] mb-[10px]">
              <div className="bg-[#C18193] hover:bg-[#B17183] mr-4 w-[146px] h-[40px] rounded flex items-center justify-center cursor-pointer">
                <Typography variant="button2" className="text-[#B32F43]">
                  Archive Listing
                </Typography>
              </div>
              <div className="bg-[#B5E2C4] hover:bg-[#B17183] w-[146px] h-[40px] rounded flex items-center justify-center cursor-pointer">
                <Typography variant="button2" className="text-[#6DA172]">
                  Keep Listing
                </Typography>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  );

  React.useEffect(() => {
    setCurrentTab("Active");
    setRecordsPerPage(25);
    filterListings();
  }, [currentTab, currentPage, openView]);

  React.useEffect(() => {
    if (listings) {
      const tableData = makeTableData(listings);
      setFilteredListings(tableData);
    }
  }, [listings]);

  const handleSearch = () => { }
  const handleClearQueries = () => { }

  return (
    <div>
      {(open || openView) && <div className="!bg-[#00000075] h-screen w-full fixed top-0 left-0 z-20"></div>}
      <AdvancedSearch
        open={open}
        openDrawer={setOpen}
        handleSearch={handleSearch}
        handleClearQueries={handleClearQueries}
      />
      <ConfirmationModal openConfirm={openConfirm} setOpenConfirm={setOpenConfirm} />
      <div className="flex flex-col gap-2">
        <nav className="flex justify-end items-center" aria-label="Tabs">
          {/* <Tabs tabs={tabs} navigate={navigate} /> */}
          <div className="flex items-center gap-5">
            {selectedRows.length > 0 && (
              <Button variant="icon" className="h-6" onClick={() => onUnbookmarkSelectedListings()}>
                <IconWrapper name="bookmark-minus" width={14} />
              </Button>
            )}
            <Filter
              changeKeyword={changeKeyword}
              keyword={keyword}
              filterLeads={filterListings}
            />
            <SortAdvanced
              sortFieldOptions={SortFieldOptions}
              sortType={sortType}
              sortField={sortField}
              changeSortField={changeSortField}
              changeSortType={changeSortType}
              filterData={filterListings}
            />
          </div>
        </nav>
      </div>
      <div className="mt-8">
        <View
          open={openView}
          changeState={setOpenView}
          data={viewData}
          setTotalCount={setTotalCount}
          search={
            {
              userId: user._id,
              keyword: keyword,
              sortType: sortType,
              sortField: sortField,
              recordsPerPage: recordsPerPage,
              currentPage: currentPage,
              status: "Bookmarked",
            } as GetMyListingsDto
          }
        />
        <AdvancedTable
          minCellWidth={100}
          class_name="custom-table table"
          data={filteredListings}
          fields={TableFields}
          onClickRow={onClickRow}
          onSelectRow={onSelectRow}
          totalPage={Math.ceil(totalCount / recordsPerPage)}
          totalCount={totalCount}
          currentPage={currentPage}
          recordsPerpage={recordsPerPage}
          onSetPage={onSetPage}
          emptyStateProps={{
            // attachment: <TeamInfo isTeamDetailsHidden={true} />,
            // attachmentCondition: user.agent.companyName?.businessName !== "",
            heading: "Be the first to Add a Listing",
            content:
              "After you add your listings, you’ll unlock ultimate listing management and productivity.",
            buttonText: "Add a Listing",
            onButtonClick: () => setOpenMyListingsDrawer(true),
            drawer: (
              <MyListingsDrawer
                open={openMyListingsDrawer}
                closeDrawer={() => setOpenMyListingsDrawer(false)}
                changeState={setOpenMyListingsDrawer}
                setOpenConfirm={setOpenConfirm}
                currentTab={currentTab}
              />
            ),
          }}
        />
      </div>
    </div>
  );
}

export default Saved