// Define the type for the component props
type Props = {
    width: number;
    height: number;
    stroke?: string;
    className?: string;
};

// Define the IconPanelLeftCloseAlt component
const IconPanelLeftCloseAlt = (props: Props) => {
    // Return the SVG for the icon
    return (
        <svg viewBox="0 0 14 10" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path d="M1 1H6" stroke="#878A99" strokeWidth="1.5" strokeLinecap="round" />
            <path d="M1 5H6" stroke="#878A99" strokeWidth="1.5" strokeLinecap="round" />
            <path d="M1 9H6" stroke="#878A99" strokeWidth="1.5" strokeLinecap="round" />
            <path d="M13.7891 7.85821L10.7227 5.00057L13.7891 2.14292C13.8559 2.081 13.909 2.00733 13.9452 1.92615C13.9814 1.84498 14 1.75792 14 1.66998C14 1.58205 13.9814 1.49498 13.9452 1.41381C13.909 1.33263 13.8559 1.25896 13.7891 1.19704C13.7228 1.1346 13.6439 1.08505 13.557 1.05123C13.4701 1.01741 13.3769 1 13.2828 1C13.1886 1 13.0954 1.01741 13.0085 1.05123C12.9216 1.08505 12.8427 1.1346 12.7765 1.19704L9.21094 4.52763C9.1441 4.58955 9.09105 4.66322 9.05484 4.7444C9.01864 4.82557 9 4.91263 9 5.00057C9 5.0885 9.01864 5.17557 9.05484 5.25674C9.09105 5.33791 9.1441 5.41159 9.21094 5.47351L12.7765 8.8041C12.8429 8.86621 12.9219 8.91547 13.0088 8.94909C13.0956 8.9827 13.1887 9 13.2828 9C13.4727 9 13.6548 8.92953 13.7891 8.8041C13.9233 8.67867 13.9988 8.50855 13.9988 8.33116C13.9988 8.15377 13.9233 7.98365 13.7891 7.85821Z" fill="#888A96" />
        </svg>
    );
};

// Export the IconPanelLeftCloseAlt component
export default IconPanelLeftCloseAlt;