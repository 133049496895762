import { Button } from "@/components/baseComponents/Button"
import IconWrapper from "@/components/baseComponents/IconWrapper"
import Typography from "@/components/baseComponents/Typography"

const NotFound = () => {
    return (
        <div className="w-screen h-screen flex justify-center items-center bg-gray-100">
            <div className="flex flex-col gap-3 justify-center items-center bg-white shadow w-[761px] p-16 rounded-xl">
                <div className="rounded-full p-4 flex-none bg-gradient-to-b from-gray-200 border-t">
                    <div className="p-4 bg-white rounded-full border shadow">
                        <IconWrapper name="icon404" />
                    </div>
                </div>
                <Typography variant="h2" color="primary">Page not found</Typography>
                <Typography variant="body" color="secondary">We’re sorry, we can’t find the page you’re looking for.</Typography>
                <Button variant="text" className="group" onClick={() => location.href = "/dashboard"}>
                    <Typography variant="button1" color="indigo" className="group-hover:underline">
                        Home
                    </Typography>
                </Button>
            </div>
        </div>
    )
}

export default NotFound