type Props = {
    width: number;
    height: number;
    stroke?: string;
    className?: string;
};

const IconInfo = (props: Props) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" fill="none" {...props}>
            <g clipPath="url(#clip0_10417_396881)">
                <path d="M8.00004 10.6654V7.9987M8.00004 5.33203H8.00671M14.6667 7.9987C14.6667 11.6806 11.6819 14.6654 8.00004 14.6654C4.31814 14.6654 1.33337 11.6806 1.33337 7.9987C1.33337 4.3168 4.31814 1.33203 8.00004 1.33203C11.6819 1.33203 14.6667 4.3168 14.6667 7.9987Z" stroke="#3C3C3C" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
            </g>
            <defs>
                <clipPath id="clip0_10417_396881">
                    <rect width="16" height="16" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
};

export default IconInfo;
