type Props = {
  width: number;
  height: number;
  stroke?: string;
  className?: string;
};

const IconPool = (props: Props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      viewBox="0 0 20 21"
      fill="none"
    >
      <path
        d="M1.66675 5.20833C2.16675 5.625 2.66675 6.04167 3.75008 6.04167C5.83341 6.04167 5.83341 4.375 7.91675 4.375C10.0834 4.375 9.91675 6.04167 12.0834 6.04167C14.1667 6.04167 14.1667 4.375 16.2501 4.375C17.3334 4.375 17.8334 4.79167 18.3334 5.20833"
        stroke="#6B7280"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1.66675 10.2083C2.16675 10.625 2.66675 11.0417 3.75008 11.0417C5.83341 11.0417 5.83341 9.375 7.91675 9.375C10.0834 9.375 9.91675 11.0417 12.0834 11.0417C14.1667 11.0417 14.1667 9.375 16.2501 9.375C17.3334 9.375 17.8334 9.79167 18.3334 10.2083"
        stroke="#6B7280"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1.66675 15.2083C2.16675 15.625 2.66675 16.0417 3.75008 16.0417C5.83341 16.0417 5.83341 14.375 7.91675 14.375C10.0834 14.375 9.91675 16.0417 12.0834 16.0417C14.1667 16.0417 14.1667 14.375 16.2501 14.375C17.3334 14.375 17.8334 14.7917 18.3334 15.2083"
        stroke="#6B7280"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default IconPool;
