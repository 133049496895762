// Import necessary libraries, types and interfaces
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import type { RootState } from '../store';
import axios from '@/shared/services/axios';
import { IWelcome, CreateWelcomeDto, UpdateWelcomeDto, DeleteWelcomesDto } from '@/shared/interfaces/interfaces';

// Define the state structure for 'welcome'
interface WelcomeState {
  welcomeCards: IWelcome[];
}

// Define the initial state
const initialState: WelcomeState = {
  welcomeCards: [],
};

// Define the 'welcome' slice of the state
export const welcomeSlice = createSlice({
  name: 'welcome',
  initialState,
  reducers: {
    reset: (state) => { state.welcomeCards = [] },
  },
  extraReducers: (builder) => {
    // Handle fulfilled getWelcomeCards action
    builder.addCase(getWelcomeCards.fulfilled, (state, action) => {
      if(action.payload.success){
        state.welcomeCards = action.payload.welcome;
      }
    });
  },
});

// Export the actions generated from the slice
export const welcomeAction = welcomeSlice.actions;

// Define a selector to get 'welcome' from the state
export const getWelcome = (state: RootState) => state.welcome.welcomeCards;

// Export the reducer
export default welcomeSlice.reducer;

// Define async thunk for creating a welcome
export const createWelcome = createAsyncThunk(
  'welcome/create',
  async (data: CreateWelcomeDto) => {
    const response = await axios.post('welcome/create', data);
    return response.data;
  }
);

// Define async thunk for updating a welcome
export const updateWelcome = createAsyncThunk(
  'welcome/update',
  async (data: UpdateWelcomeDto) => {
    const response = await axios.put('welcome/update', data);
    return response.data;
  }
);

// Define async thunk for getting a welcome from the database
export const getWelcomeCards = createAsyncThunk(
  'welcome/get-cards',
  async () => {
    const response = await axios.post('welcome/get-cards');
    return response.data;
  }
);

// Define async thunk for deleting welcomes
export const deleteWelcomes = createAsyncThunk(
  'welcome/delete',
  async (data: DeleteWelcomesDto) => {
    const response = await axios.post('welcome/delete', data);
    return response.data;
  }
);