type Props = {
    width: number;
    height: number;
    stroke?: string;
    className?: string;
};

const IconPercentage = (props: Props) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="none" {...props}>
            <g opacity="0.6">
                <path d="M15.8332 4.16699L4.1665 15.8337" stroke="#8E9CB2" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M5.41683 7.49967C6.56742 7.49967 7.50016 6.56693 7.50016 5.41634C7.50016 4.26575 6.56742 3.33301 5.41683 3.33301C4.26624 3.33301 3.3335 4.26575 3.3335 5.41634C3.3335 6.56693 4.26624 7.49967 5.41683 7.49967Z" stroke="#8E9CB2" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M14.5833 16.6667C15.7339 16.6667 16.6667 15.7339 16.6667 14.5833C16.6667 13.4327 15.7339 12.5 14.5833 12.5C13.4327 12.5 12.5 13.4327 12.5 14.5833C12.5 15.7339 13.4327 16.6667 14.5833 16.6667Z" stroke="#8E9CB2" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            </g>
        </svg>
    );
};

export default IconPercentage;
