import Select from "@/components/baseComponents/Select"
import Typography from "@/components/baseComponents/Typography"
import { GetMyListingsDto } from "@/shared/interfaces/interfaces";
import { fetchAllClientsByUserId } from "@/redux/lead/leadSlice"
import { notify } from "@/shared/services/notify"
import { useEffect, useState } from "react"
import { getMyListingsByClientId } from "@/redux/myListings/myListingSlice";
import { useAppDispatch, useAppSelector } from "@/redux/hooks";
import { fetchAddressAutocomplete, getAddresses, getUser } from "@/redux/user/userSlice";
import AddressAutoComplete from "@/components/baseComponents/AddressAutoComplete";

const Client = () => {

    const dispatch = useAppDispatch()
    const user = useAppSelector(getUser)
    const addresses = useAppSelector(getAddresses);

    const [clientOptions, setClientOptions] = useState<any>([])
    const [listingAddress, setListingAddress] = useState<string>()

    const onSearchClient = (value: any) => {
        setTimeout(() => {
            dispatch(fetchAllClientsByUserId({ query: value, type: "", userId: user._id, relationship: "" })).then((res: any) => {
                try {
                    const clients = res.payload.clients
                    setClientOptions([
                        ...clients.map((item: any) => { return { value: item, label: item.firstName + " " + item.lastName } })
                    ])
                } catch (e) {
                    notify(false, "Something went wrong.");
                }
            })
        }, 1000);
    }

    const onSelectClient = (client: any) => {
        const data: GetMyListingsDto = {
            userId: client.value._id,
            keyword: "",
            sortType: "",
            sortField: "",
            recordsPerPage: 1,
            currentPage: 1,
            status: "Active",
        }

        dispatch(getMyListingsByClientId(data)).then((res: any) => {
            try {
                if (res.payload.listings && res.payload.listings.length > 0) {
                    if (res.payload.listings[0]) {
                        const firstListing = res.payload.listings[0]
                        
                        if (client.value.leadType === "Seller") {
                            setListingAddress(firstListing.listingAddress)
                        } else {
                            setListingAddress("")
                        }
                    }
                }
            } catch (e) {
                notify(false, "Something went wrong.");
            }
        })
    }
    
    const onSearchListingAddress = (value: string) => {
        if (value) {
            dispatch(fetchAddressAutocomplete({ address: value }));
        }
    };

    const onSelectListingAddress = (address: any) => {
        if (address) {
            let fullAddress = address.streetLine + ", " + address.city + ", " + address.state

            if (address.zipcode) {
                fullAddress += " " + address.zipcode
            }

            if (address.secondary) {
                fullAddress += " " + address.secondary
            }

            setListingAddress(fullAddress)
        }
    }

    useEffect(() => {
        if (true) {
            dispatch(fetchAllClientsByUserId({ query: "", type: "", userId: user._id, relationship: "" })).then((res: any) => {
                try {
                    const clients = res.payload.clients
                    setClientOptions([
                        ...clients.map((item: any) => { return { value: item, label: item.firstName + " " + item.lastName } })
                    ])
                } catch (e) {
                    notify(false, "Something went wrong.");
                }
            })
        }
    }, [])

    return (
        <div>
            <div className="mb-[25px]">
                <Typography variant="caption" color="secondary">Client</Typography>
                <Select
                    name="client"
                    placeholder="Find a client"
                    options={clientOptions}
                    onChange={(value) => onSelectClient(value)}
                    onInputChange={(value) => onSearchClient(value)}
                />
            </div>
            <div>
                <Typography variant="caption" color="secondary">
                    Address
                </Typography>
                <AddressAutoComplete
                    options={addresses}
                    value={listingAddress}
                    placeholder="Enter Property Address"
                    onChange={(value) => onSearchListingAddress(value)}
                    onAllChange={(value) => onSelectListingAddress(value)}
                />
            </div>
        </div>
    )
}

export default Client