import { SetStateAction, useEffect, useState } from "react";
import { Button } from "@/components/baseComponents/Button";
import Container from "@/components/baseComponents/Container";
import Icon from "@/components/baseComponents/IconWrapper";
import AccountPopover from "@/components/mainComponents/AccountPopover";
import LogoMain from "@/assets/images/logo_main.svg";
import React from "react";
import NotificationDrawer from "./Components/NotificationDrawer";
import { getUser } from "@/redux/user/userSlice";
import { useAppSelector, useAppDispatch } from "@/redux/hooks";
import Typography from "@/components/baseComponents/Typography";
// import { useNavigate } from "react-router-dom";
import ReconnectingWebSocket from "reconnecting-websocket";
import {
  getParticipants,
  setMessages,
  // setParticipants,
} from "@/redux/chat/chatSlice";
import IconWrapper from "@/components/baseComponents/IconWrapper";
import { IParticipantDto } from "@/shared/interfaces/interfaces";
import ChatViewDrawer from "./Components/ChatViewDrawer";
import Tooltip from "@/components/mainComponents/ToolTip";
import AuthDrawer from "@/pages/public_listing/AuthDrawer";
import { useDebounce } from "use-debounce";
import { search } from "@/redux/search/searchSlice";

interface IProps {
  open: boolean;
  changeOpen: Function;
  scrollbars?: any;
  disableSearch?: boolean;
  setDisabledSearch?: React.Dispatch<React.SetStateAction<boolean>>;
}

interface ISearchData {
  leads: SetStateAction<never[]>;
  clients: SetStateAction<never[]>;
  listings: SetStateAction<never[]>;
  showings: SetStateAction<never[]>;
}

const Header = (props: IProps) => {
  // const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const user = useAppSelector(getUser);
  const participants = useAppSelector(getParticipants);
  const [openSignUpDrawer, setOpenSignUpDrawer] = React.useState<boolean>(false);
  const [openSigninDrawer, setOpenSigninDrawer] = React.useState<boolean>(false);
  const [unviewedNotificationCount, setUnviewedNotificationCount] = React.useState<number>(0);
  const [notifications, setNotifications] = React.useState<Array<any>>([]);
  const [openNotificationModal, setOpenNotificaitonModal] = React.useState<boolean>(false);
  const [unreadMessageCount, setUnreadMessageCount] = React.useState<number>(0);
  const [myWindow, setMyWindow] = useState<any>(window as any);

  // const [lastScrollTop, setLastScrollTop] = React.useState<number>(0);
  // const [isScrollingDown, setIsScrollingDown] = React.useState<boolean>(false);

  const [openChat, setOpenChat] = useState(false);
  const [triggerSearch, setTriggerSearch] = useState(false);

  // const goPage = (page: string) => {
  //   navigate("/" + page);
  // };
  const changeSideBar = (value: boolean) => {
    props.changeOpen && props.changeOpen(value);
  };

  const updateUnreadCount = (participants: IParticipantDto[]) => {
    var unreadCount = 0;
    participants?.map((participant: IParticipantDto) => {
      unreadCount += participant?.unreadCount ?? 0;
    });
    setUnreadMessageCount(unreadCount);
  };

  useEffect(() => {
    if (user) {
      // Establish a WebSocket connection
      const apiUrl = import.meta.env.VITE_API_URL || "http://localhost:4000/api";
      const socketUrl = apiUrl
        .replace(/^http(?=:\/\/)/, "ws")
        .replace(/^https(?=:\/\/)/, "wss")
        .replace(/\/$/, "");
      const socket = new ReconnectingWebSocket(`${socketUrl}/notification?userId=${user._id}`);
      // Listen for events from the server
      socket.onopen = () => {
        console.log("WebSocket connection established");
      };

      socket.onmessage = (event) => {
        if (event.isTrusted) {
          const data = JSON.parse(event.data);
          if (data.notifications) {
            setUnviewedNotificationCount(data.count ?? 0);
            setNotifications(data.notifications);
          } else if (data.type === "chat" && data.participants) {
            // updateUnreadCount(data.participants);
            // dispatch(setParticipants(data.participants));
          } else if (data.type === "chat" && data.messages) {
            dispatch(setMessages(data.messages));
          }
        }
      };

      socket.onclose = () => {
        console.log("WebSocket connection closed");
      };

      // Clean up the WebSocket connection when the component unmounts
      return () => {
        socket.close();
      };
    }

    setTriggerSearch(false);
  }, [user]);

  const openReportDrawer = () => {
    if (myWindow.isUserHelpReady === undefined || myWindow.isUserHelpReady === false) {
      setMyWindow(window as any);
    }

    if (myWindow.isUserHelpReady === true) {
      myWindow.UserHelpSetName(user?.agent?.firstName + " " + user?.agent?.lastName);
      myWindow.UserHelpSetEmail(user?.agent?.contactEmail);
    }

    myWindow.openUserHelpButton();
  };

  useEffect(() => {
    updateUnreadCount(participants);
  }, [participants]);

  // functionality for search
  const [inputValue, setInputValue] = useState("");
  const [debouncedSearchTerm] = useDebounce(inputValue, 1000);
  const [searchIsFocused, setSearchIsFocused] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleInputChange = (event: any) => {
    const value = event.target.value;
    setInputValue(value);
    setSearchIsFocused(value !== "");
    props.setDisabledSearch && props.setDisabledSearch(false);
  };

  const handleClearInput = () => {
    setInputValue("");
    setSearchIsFocused(false);
  };

  const handleAuthDrawer = (type: string) => {
    console.log("type  ", type);
    if (type == "signup") {
      console.log("sigup ", type);
      setOpenSignUpDrawer(true);
    } else {
      console.log("sigin ", type);
      setOpenSigninDrawer(true);
    }
  };

  const [searchData, setSearchData] = useState<ISearchData>({
    leads: [],
    clients: [],
    listings: [],
    showings: [],
  });
  // dispatch search results
  useEffect(() => {
    if (debouncedSearchTerm && debouncedSearchTerm !== "") {
      setIsLoading(true);
      dispatch(
        search({
          searchTerm: debouncedSearchTerm,
          user: user._id,
        })
      ).then((response) => {
        setSearchData(response.payload.results);
        setIsLoading(false);
      });
    }
  }, [debouncedSearchTerm]);

  const [leads, setLeads] = useState([]);
  const [clients, setClients] = useState([]);
  const [listings, setListings] = useState([]);
  const [showings, setShowings] = useState([]);

  const splitTextBySearchTerm = (text: string, searchTerm: string): (string | JSX.Element)[] => {
    if (!searchTerm) return [text];
    const regex = new RegExp(`(${searchTerm})`, "gi");
    return text.split(regex).map((part, index) => (regex.test(part) ? <b key={index}>{part}</b> : part));
  };
  useEffect(() => {
    console.log(searchData);
    if (searchData) {
      setLeads(searchData.leads);
      setClients(searchData.clients);
      setListings(searchData.listings);
      setShowings(searchData.showings);
    } else {
      return;
    }
  }, [searchData]);

  const navTabs = ["All", "Messages", "Relationships", "Listings", "Showings", "Offers", "Transactions"];

  const [selectedTab, setSelectedTab] = useState(0);

  // function to edit status border color and text color
  const getShowingStatusColor = (status: string | undefined) => {
    return status == "Pending" ? "border-pending text-pending" : status === "Denied" ? "border-denied text-denied" : status === "Confirmed" ? "border-confirmed text-confirmed" : "";
  };

  return (
    <>
      {openNotificationModal && <div className="!bg-[#00000075] h-screen w-full fixed top-0 left-0 z-20"></div>}
      {openChat && <div className="!bg-[#00000075] h-screen w-full fixed top-0 left-0 z-20"></div>}
      {searchIsFocused && !props.disableSearch && <div onClick={handleClearInput} className="!bg-[#00000075] h-screen w-full fixed top-0 left-0 z-10"></div>}
      {openSignUpDrawer && (
        <AuthDrawer
          open={openSignUpDrawer}
          closeDrawer={() => {
            setOpenSignUpDrawer(!openSignUpDrawer);
          }}
          type={"signup"}
        />
      )}
      {openSigninDrawer && (
        <AuthDrawer
          open={openSigninDrawer}
          closeDrawer={() => {
            setOpenSigninDrawer(!openSigninDrawer);
          }}
          type={"signin"}
        />
      )}
      <header
        className={`fixed top-0 bg-white flex flex-col border-b-1 border-b-divider w-full z-10 h-[60px] ${
          props.open ? `lg:w-[75vw] lg2:w-[80vw] md:w-[70vw] sm:w-full ` : `lg:w-[95vw] lg2:w-[95vw] md:w-[92vw] sm:w-full `
        }`}
      >
        <Container className={`flex items-center border-b-1 py-2 h-full`}>
          <div className="flex gap-3 items-center max-sm:gap-[1px]">
            <img src={LogoMain} alt="logoMain" className="w-[36px] h-[36px] block md:hidden" />
            <Button variant="icon" color="inherit" onClick={() => changeSideBar(!props.open)}>
              <IconWrapper name="menu" className="md:hidden" />
              {props.open ? (
                <IconWrapper name="panel-left-close-alt" width={20} className="sm:max-md:hidden max-sm:hidden" />
              ) : (
                <IconWrapper name="panel-right-open-alt" width={20} className="sm:max-md:hidden max-sm:hidden" />
              )}
            </Button>

            {/* COMMENTED OUT SEARCH BAR FEATURE TEMPORARILY FOR PRODUCTION DEPLOYMENT */}
            {/* search bar tablet trigger */}
            {/* <div
              className={`${triggerSearch && "bg-gray-100"} p-2 rounded-lg cursor-pointer block max-sm:hidden lg:hidden`}
              onClick={() => {
                setTriggerSearch(!triggerSearch);
              }}
            >
              <IconWrapper name="search" width={16} className="pointer-events-none" />
            </div> */}

            {/* search bar Desktop */}
            {/* <div className="max-lg:hidden relative p-2 py-[8px] flex gap-[6px] bg-[#F1F5F9] w-full min-w-[425px] rounded-[3px] ">
              <div className="flex justify-between items-center w-full ">
                <IconWrapper name="search" width={16} className="pointer-events-none" />
                {searchIsFocused && inputValue !== "" && (
                  <div onClick={handleClearInput} className=" flex items-center gap-[6px] cursor-pointer z-10">
                    <span className="text-secondary text-[14px]">Clear</span>
                    <IconWrapper name="close" width={16} />
                  </div>
                )}
              </div> */}

              {/* to be replace with textfield */}
              {/* <input
                placeholder="Search"
                className=" placeholder:text-[14px] text-[14px]  bg-transparent absolute pl-8 inset-0 flex items-center bg-[#F1F5F9] rounded-sm p-3 outline-none w-full h-full placeholder:caption border-1 border-transparent focus:border-[#4C42D7] focus:!ring-2 !ring-[#4C42D7]/25"
                value={inputValue}
                onChange={handleInputChange}
              />
            </div> */}
          </div>
          <div className="flex-1" />

          {user ? (
            <div className="flex items-center gap-3 sm:gap-[2px] max-sm:gap-[1px]">
              {/* search bar tablet trigger */}
              {/* <div
                className={`${triggerSearch && "bg-gray-100"} p-2 rounded-lg cursor-pointer max-sm:block hidden`}
                onClick={() => {
                  setTriggerSearch(!triggerSearch);
                }}
              >
                <IconWrapper name="search" className="pointer-events-none" />
              </div> */}
              <div className="flex items-center gap-[14px] sm:gap-[2px] max-sm:gap-[1px]">
                <Tooltip text="Leave Feedback">
                  <Button variant="icon" onClick={() => openReportDrawer()}>
                    <IconWrapper name="warning" />
                  </Button>
                </Tooltip>
                <Tooltip text="Messages">
                  <Button variant="icon" color="inherit" className="relative !pl-3" onClick={() => setOpenChat(true)}>
                    {unreadMessageCount > 0 && (
                      <div className="absolute bg-[#C84156] text-[white] w-fit min-w-[14px] h-[14px] px-0.5 text-[7px] leading-[15px] text-center rounded-[15px] right-[5px] top-[6px]">
                        {unreadMessageCount}
                      </div>
                    )}
                    <IconWrapper name="chat" />
                  </Button>
                </Tooltip>
                <Tooltip text="Notifications">
                  <Button variant="icon" color="inherit" className="relative !px-3" onClick={() => setOpenNotificaitonModal(true)}>
                    {unviewedNotificationCount > 0 && (
                      <div className="absolute bg-[#C84156] text-[white] w-fit min-w-[14px] h-[14px] px-0.5 text-[7px] leading-[15px] text-center rounded-[15px] right-[5px] top-[6px]">
                        {unviewedNotificationCount}
                      </div>
                    )}
                    <Icon name="alert" width={20} className="cursor-pointer" />
                  </Button>
                </Tooltip>
              </div>
              <div className="h-10 w-[1px] bg-gray-200"></div>
              <AccountPopover />
            </div>
          ) : (
            <div className="flex flex-row gap-3 py-1.5">
              <Button
                variant="text"
                className="px-4 py-2 border-[#D1D5DB] hover:border-[#3C3C3C] border-1"
                onClick={() => handleAuthDrawer("signin")}
                // onClick={() => goPage("auth/login")}
              >
                <Typography className="whitespace-nowrap text-[14px]">Log In</Typography>
              </Button>
              <Button
                variant="contained"
                color="primary"
                className="w-28"
                onClick={() => handleAuthDrawer("signup")}
                // onClick={() => goPage("auth/register")}
              >
                <Typography variant="button2" className="whitespace-nowrap">
                  Sign Up
                </Typography>
              </Button>
            </div>
          )}
        </Container>
        {triggerSearch && (
          <div className="px-3 py-2 shadow-md lg:hidden">
            <div className="relative p-2 py-[13px] flex gap-[6px] bg-[#F1F5F9] w-full rounded-sm">
              <div className="flex justify-between items-center w-full ">
                <IconWrapper name="search" width={16} className="pointer-events-none" />
                {searchIsFocused && inputValue !== "" && (
                  <div onClick={handleClearInput} className=" flex items-center gap-[6px] cursor-pointer z-10">
                    <span className="text-secondary">Clear</span>
                    <IconWrapper name="close" />
                  </div>
                )}
              </div>

              <input
                placeholder="Search"
                className=" placeholder:text-[14px]  bg-transparent absolute pl-10 inset-0 flex items-center bg-[#F1F5F9] rounded-sm  p-3 
                outline-none w-full h-full placeholder:caption  
                border-1 border-transparent focus:border-[#4C42D7] focus:!ring-2 !ring-[#4C42D7]/25"
                value={inputValue}
                onChange={handleInputChange}
              />
            </div>
          </div>
        )}
        {/* plan to put the results in a component */}
        {searchIsFocused && !props.disableSearch && inputValue !== "" && (
          <div className="absolute top-[60px] inset-x-0 w-[947px] max-w-full m-2  bg-white rounded-lg shadow-md z-50">
            <div className="flex flex-col">
              <div className=" flex justify-between w-full px-4  p-2 pt-[27px]">
                {navTabs.map((tab, tabIndex) => (
                  <Typography
                    key={tabIndex}
                    className={` w-full text-center px-[11px] pb-[5px] text-[14px] cursor-pointer border-b-1 ${selectedTab === tabIndex ? "border-blue-500 border-b-2" : " border-[#9CA3AF]"}`}
                    onClick={() => setSelectedTab(tabIndex)}
                    color="primary"
                  >
                    {tab}
                  </Typography>
                ))}
              </div>
              <div>
                <div className="overflow-y-scroll h-[400px]">
                  {!isLoading ? (
                    <>
                      {selectedTab === 0 ? (
                        <>
                          <div className="leads">
                            {leads.length > 0 && (
                              <div className="flex flex-col cursor-pointer">
                                <Typography className=" uppercase text-[12px] pt-4 px-3" color="secondary">
                                  Leads
                                </Typography>
                                {leads.map((lead: any, index) => (
                                  <div key={index} className="flex p-3 gap-[6px] hover:bg-secondary hover:bg-opacity-5">
                                    <div className="flex flex-col">
                                      <Typography color="primary" className="font-normal">
                                        {splitTextBySearchTerm(lead?.firstName + " " + lead?.lastName || "", debouncedSearchTerm)}
                                      </Typography>
                                      <div>
                                        {lead.phoneNumber && (
                                          <div className="flex items-center gap-1">
                                            <IconWrapper name="telephone" width={16} />
                                            <Typography color="secondary" className="leading-[16px]">
                                              {splitTextBySearchTerm(lead?.phoneNumber || "", debouncedSearchTerm)}
                                            </Typography>
                                          </div>
                                        )}
                                        {lead.email && (
                                          <div className="flex items-center gap-1">
                                            <IconWrapper name="envelope" width={16} />
                                            <Typography color="secondary" className="leading-[16px]">
                                              {splitTextBySearchTerm(lead?.email || "", debouncedSearchTerm)}
                                            </Typography>
                                          </div>
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                ))}
                              </div>
                            )}
                          </div>

                          <div className="clients">
                            {clients.length > 0 && (
                              <div className="flex flex-col cursor-pointer">
                                <Typography className=" uppercase text-[12px] pt-4 px-3 " color="secondary">
                                  Clients
                                </Typography>
                                {clients.map((client: any, index) => (
                                  <div key={index} className="flex p-3 gap-[6px] hover:bg-secondary hover:bg-opacity-5">
                                    <div className="flex flex-col">
                                      <Typography color="primary" className="font-normal">
                                        {splitTextBySearchTerm(client?.firstName + " " + client?.lastName || "", debouncedSearchTerm)}
                                      </Typography>
                                      <div>
                                        {client.phoneNumber && (
                                          <div className="flex items-center gap-1">
                                            <IconWrapper name="telephone" width={16} />
                                            <Typography color="secondary" className="leading-[16px]">
                                              {splitTextBySearchTerm(client?.phoneNumber || "", debouncedSearchTerm)}
                                            </Typography>
                                          </div>
                                        )}
                                        {client.email && (
                                          <div className="flex items-center gap-1">
                                            <IconWrapper name="envelope" width={16} />
                                            <Typography color="secondary" className="leading-[16px]">
                                              {splitTextBySearchTerm(client?.email || "", debouncedSearchTerm)}
                                            </Typography>
                                          </div>
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                ))}
                              </div>
                            )}
                          </div>

                          <div className="listings">
                            {listings.length > 0 && (
                              <div className="flex flex-col cursor-pointer">
                                <Typography className=" uppercase text-[12px] px-3 pt-4" color="secondary">
                                  Listings
                                </Typography>
                                {listings.map((listing: any, index) => (
                                  <div key={index} className="flex p-3 gap-3 hover:bg-secondary hover:bg-opacity-5">
                                    <div className="w-[36px] h-[36px] rounded-md overflow-hidden leading-[16px]">
                                      <img className="w-full h-full object-cover" src={listing?.propertyPhotos[0]?.file && listing?.propertyPhotos[0]?.file} alt="" />
                                    </div>
                                    <div className="flex flex-col">
                                      <Typography color="primary" className="font-normal">
                                        {splitTextBySearchTerm(listing?.listingAddress?.fullAddress || "", debouncedSearchTerm)}
                                      </Typography>
                                      <div>
                                        <Typography color="secondary" className="leading-[16px]">
                                          {listing?.listingAddress.city + " ," + listing?.listingAddress.state + " ," + listing?.listingAddress.zipcode}
                                        </Typography>
                                      </div>
                                    </div>
                                  </div>
                                ))}
                              </div>
                            )}
                          </div>
                          <div className="showings">
                            {showings.length > 0 && (
                              <div className="flex flex-col cursor-pointer">
                                <Typography className=" uppercase text-[12px] px-3 pt-4" color="secondary">
                                  showings
                                </Typography>
                                {showings.map((showing: any, index) => (
                                  <div key={index} className="flex p-3 items-center justify-between hover:bg-secondary hover:bg-opacity-5">
                                    <div className="flex items-center gap-3">
                                      <div className="w-[36px] h-[36px] rounded-md overflow-hidden leading-[16px]">
                                        <img className="w-full h-full object-cover" src={showing?.listing?.propertyPhotos[0]?.file && showing?.listing?.propertyPhotos[0]?.file} alt="" />
                                      </div>
                                      <div className="flex flex-col">
                                        <Typography color="primary" className="font-normal">
                                          {splitTextBySearchTerm(showing?.listing?.listingAddress?.fullAddress || "", debouncedSearchTerm)}
                                        </Typography>
                                        <div>
                                          <Typography color="secondary" className="leading-[16px]">
                                            {showing?.listing?.listingAddress.city + ", " + showing?.listing?.listingAddress.state + ", " + showing?.listing?.listingAddress.zipcode}
                                          </Typography>
                                        </div>
                                      </div>
                                    </div>
                                    <div>
                                      <span className={` ${getShowingStatusColor(showing.status)} border px-[8px] rounded-full py-[4px] text-[12px] leading-[16px]`}>{showing.status}</span>
                                    </div>
                                  </div>
                                ))}
                              </div>
                            )}
                          </div>
                        </>
                      ) : (
                        <div className="grid place-items-center min-h-full w-full">No Results</div>
                      )}
                      {/* {clients.length < 1 && leads.length < 1 && listings.length < 1 && showings.length < 1 && <div className="grid place-items-center h-full w-full">No Results</div>} */}
                    </>
                  ) : (
                    // loading svg
                    <div className="grid place-items-center h-full">
                      <div className="w-[30px] aspect-square">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 200 200">
                          <radialGradient id="a12" cx=".66" fx=".66" cy=".3125" fy=".3125" gradientTransform="scale(1.5)">
                            <stop offset="0" stop-color="#000FFF"></stop>
                            <stop offset=".3" stop-color="#000FFF" stop-opacity=".9"></stop>
                            <stop offset=".6" stop-color="#000FFF" stop-opacity=".6"></stop>
                            <stop offset=".8" stop-color="#000FFF" stop-opacity=".3"></stop>
                            <stop offset="1" stop-color="#000FFF" stop-opacity="0"></stop>
                          </radialGradient>
                          <circle
                            transform-origin="center"
                            fill="none"
                            stroke="url(#a12)"
                            stroke-width="17"
                            stroke-linecap="round"
                            stroke-dasharray="200 1000"
                            stroke-dashoffset="0"
                            cx="100"
                            cy="100"
                            r="70"
                          >
                            <animateTransform
                              type="rotate"
                              attributeName="transform"
                              calcMode="spline"
                              dur="2"
                              values="360;0"
                              keyTimes="0;1"
                              keySplines="0 0 1 1"
                              repeatCount="indefinite"
                            ></animateTransform>
                          </circle>
                          <circle transform-origin="center" fill="none" opacity=".2" stroke="#000FFF" stroke-width="17" stroke-linecap="round" cx="100" cy="100" r="70"></circle>
                        </svg>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        )}
      </header>

      {user && (
        <>
          <NotificationDrawer open={openNotificationModal} notifications={notifications} closeModal={() => setOpenNotificaitonModal(false)} />
          <ChatViewDrawer open={openChat} changeState={setOpenChat} />
        </>
      )}
    </>
  );
};

export default Header;
