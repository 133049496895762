// React library for building user interfaces
import { useEffect, useState } from "react";

// Importing logo from assets
import Logo from "@/assets/images/logo_black.svg";

// Base components for Typography, TextField, Button, and Select
import Typography from "@/components/baseComponents/Typography";
import TextField from "@/components/baseComponents/TextField";
import { Button } from "@/components/baseComponents/Button";
import Select from "@/components/baseComponents/Select";

// Hook from react-router-dom to navigate between routes
import { useNavigate } from "react-router-dom";

// Interfaces for CreateUserDto and SelectType
import { CreateUserDto, SelectType } from "@/shared/interfaces/interfaces";

// Redux actions from userSlice
import { signUp, updateStates } from "@/redux/user/userSlice";

// Redux hooks for dispatching actions and selecting state
import { useAppDispatch } from "@/redux/hooks";

// Service for showing notifications
import { notify } from "@/shared/services/notify";

// Service for validation
import validation from "@/shared/services/validation";

// Library for handling cookies in React
import { Cookies } from "react-cookie";
import IconWrapper from "@/components/baseComponents/IconWrapper";

const Register = () => {
  // Initialize cookies
  const cookies = new Cookies();

  // Hook for navigation
  const navigate = useNavigate();

  // Hook for dispatching actions
  const dispatch = useAppDispatch();

  // Hook for selecting states from the Redux store
  // const states = useAppSelector(getStates);

  // State and setters for form fields
  const [firstName, setFirstName] = useState<string>("");
  const [lastName, setLastName] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [confirm, setConfirm] = useState<string>("");
  const [licenseState, setLicenseState] = useState<SelectType>({ value: "1", label: "" });
  const [licenseType, setLicenseType] = useState<SelectType>({ value: "1", label: "" });

  // State and setters for form validation errors
  const [errorFirstName, setErrorFirstName] = useState<boolean>(false);
  const [errorLastName, setErrorLastName] = useState<boolean>(false);
  const [errorEmail, setErrorEmail] = useState<boolean>(false);
  const [errorConfirm, setErrorConfirm] = useState<boolean>(false);
  const [errorMessagePassword, setErrorMessagePassword] = useState<string>("");

  // State and setters for license state and type lists
  const [licenseStateList, setLicenseStateList] = useState<Array<any>>([]);
  const [licenseTypeList, setLicenseTypeList] = useState<Array<any>>([]);

  // State and setters for show password and show confirm password
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState<boolean>(false);

  const goLogin = () => {
    navigate("/auth/login");
  };
  
  const changeEmail = (value: string) => {
    setEmail(value);
    setErrorEmail(validation.IsInvalidEmail(value));
  };

  const changeFirstName = (value: string) => {
    setFirstName(value);
    setErrorFirstName(validation.IsEmptyString(value));
  };

  const changeLastName = (value: string) => {
    setLastName(value);
    setErrorLastName(validation.IsEmptyString(value));
  };

  const changePassword = (value: string) => {
    setPassword(value);
    if (!validation.emptyPassword(value)) {
      setErrorMessagePassword("This field is required");
      return;
    }
    if (!validation.containsNumber(value)) {
      setErrorMessagePassword("Should contain at least a number");
      return;
    }
    if (!validation.containsUppercase(value)) {
      setErrorMessagePassword("Should contain at least a uppercase character");
      return;
    }
    if (!validation.containsSpecial(value)) {
      setErrorMessagePassword("Should contain at least a special character");
      return;
    }
    if (!validation.minLength(value)) {
      setErrorMessagePassword("Should be at least 8 characters");
      return;
    }
    setErrorMessagePassword("");
  };

  const registerUser = () => {
    changeFirstName(firstName);
    changeLastName(lastName);
    changeEmail(email);
    changePassword(password);
    if (validation.IsDifferentString(password, confirm)) {
      setErrorConfirm(true);
      return;
    }
    if (
      !errorFirstName &&
      !errorLastName &&
      !errorEmail &&
      !errorConfirm &&
      errorMessagePassword === ""
    ) {
      let data: CreateUserDto = {
        firstName: firstName,
        lastName: lastName,
        email: email.toLocaleLowerCase(),
        password: password,
        licenseState: licenseState.label,
        licenseType: licenseType.label,
        listingId: cookies.get("lastViewedPublicListingId"),
      };
      dispatch(signUp(data)).then((res) => {
        try {
          if (res.payload.success)
            navigate("/profile", { state: { id: "My Info" } });
          notify(res.payload.success, res.payload.message);
          cookies.remove("lastViewedPublicListingId", { path: "/" });
        } catch (e) {
          notify(false, "Something went wrong.");
        }
      });
    }
  };

  useEffect(() => {
    if (password !== "" && confirm !== "") setErrorConfirm(validation.IsDifferentString(password, confirm));
  }, [password, confirm])

  useEffect(() => {
    dispatch(updateStates()).then((res) => {
      setLicenseState({
        value: res.payload.states[0].name,
        label: res.payload.states[0].name,
      });
      setLicenseType({
        value: res.payload.states[0].licenseType1,
        label: res.payload.states[0].licenseType1,
      });
      let tempTypes = [];
      if (res.payload.states[0].licenseType1) {
        tempTypes.push({
          value: res.payload.states[0].licenseType1,
          label: res.payload.states[0].licenseType1,
        });
      }
      if (res.payload.states[0].licenseType2) {
        tempTypes.push({
          value: res.payload.states[0].licenseType2,
          label: res.payload.states[0].licenseType2,
        });
      }
      if (res.payload.states[0].licenseType3) {
        tempTypes.push({
          value: res.payload.states[0].licenseType3,
          label: res.payload.states[0].licenseType3,
        });
      }
      setLicenseTypeList(tempTypes);
      let tempStates = res.payload.states
        .map((state: any) => {
          return {
            value: state.name,
            label: state.name,
          };
        })
        .sort((a: any, b: any) => {
          if (a.value < b.value) {
            return -1;
          }
          if (a.value > b.value) {
            return 1;
          }
          return 0;
        });
      setLicenseStateList(tempStates);
    });
  }, []);

  return (
    <div className="flex items-start py-20 px-4 sm:px-6 w-full bg-white min-h-screen select-none">
      <div className="w-full flex flex-col gap-4">
        <div className=" self-center">
          <img
            src={Logo}
            alt="logo"
            className="  max-w-[197px] sm:max-w-[263px] flex"
          />
        </div>
        <div className="flex justify-center">
          <div className=" pt-5 sm:max-w-[600px] w-full space-y-2">
            <div className=" space-y-2">
              <Typography variant="h1" className="text-center leading-[141%]">
                Sign up
              </Typography>
              <Typography
                variant="body"
                className="text-button-primary hover:text-button-primary-hover cursor-pointer mt-[10px] text-center mb-[20px]"
                onClick={() => goLogin()}
              >
                Have an account? Sign In.
              </Typography>
            </div>

            <div className="grid sm:grid-cols-2 sm:gap-3">
              <div className="col-span-1 relative py-3 space-y-2">
                <Typography
                  variant="caption"
                  className="text-secondary mb-[9px]"
                >
                  First Name
                </Typography>
                <TextField
                  className={`w-full ${
                    errorFirstName
                      ? "outline outline-1 rounded outline-[#E01010]"
                      : ""
                  }`}
                  value={firstName}
                  onChange={(e) => changeFirstName(e.target.value)}
                />
                {errorFirstName && (
                  <Typography
                    variant="caption"
                    className="text-[#E01010] absolute mt-[2px]"
                  >
                    This field is required.
                  </Typography>
                )}
              </div>

              <div className="col-span-1 relative py-3 space-y-2">
                <Typography
                  variant="caption"
                  className="text-secondary mb-[9px]"
                >
                  Last Name
                </Typography>
                <TextField
                  className={`w-full ${
                    errorLastName
                      ? "outline outline-1 rounded outline-[#E01010]"
                      : ""
                  }`}
                  value={lastName}
                  onChange={(e) => changeLastName(e.target.value)}
                />
                {errorLastName && (
                  <Typography
                    variant="caption"
                    className="text-[#E01010] absolute mt-[2px]"
                  >
                    This field is required.
                  </Typography>
                )}
              </div>
            </div>

            <div className="relative py-3 space-y-2">
              <Typography variant="caption" className="text-secondary mb-[9px]">
                Email Address
              </Typography>
              <TextField
                className={`w-full ${
                  errorEmail
                    ? "outline outline-1 rounded outline-[#E01010]"
                    : ""
                }`}
                value={email}
                onChange={(e) => changeEmail(e.target.value)}
              />
              {errorEmail && (
                <Typography
                  variant="caption"
                  className="text-[#E01010] absolute mt-[2px]"
                >
                  Invalid Email Address
                </Typography>
              )}
            </div>

            <div className="grid sm:grid-cols-2 sm:gap-3">
              <div className="col-span-1 space-y-2 relative  py-3">
                <Typography
                  variant="caption"
                  className="text-secondary mb-[9px]"
                >
                  Password
                </Typography>
                <TextField
                  className={`w-full ${
                    errorMessagePassword !== ""
                      ? "outline outline-1 rounded outline-[#E01010]"
                      : ""
                  }`}
                  type={showPassword ? "text" : "password"}
                  value={password}
                  onChange={(e) => changePassword(e.target.value)}
                  endAdornment={
                    <div className="cursor-pointer" onClick={() => setShowPassword(!showPassword)} tabIndex={-1}>
                      <IconWrapper name="eye" className={showPassword ? `[&>svg>path]:!stroke-indigo-600` : ``} width={20} />
                    </div>
                  }
                />
                {errorMessagePassword !== "" && (
                  <Typography
                    variant="caption"
                    className="text-[#E01010] absolute mt-[2px]"
                  >
                    {errorMessagePassword}
                  </Typography>
                )}
              </div>

              <div className="col-span-1 space-y-2 relative py-3">
                <Typography
                  variant="caption"
                  className="text-secondary mb-[9px]"
                >
                  Confirm Password
                </Typography>
                <TextField
                  className={`w-full ${
                    errorConfirm
                      ? "outline outline-1 rounded outline-[#E01010]"
                      : ""
                  }`}
                  value={confirm}
                  type={showConfirmPassword ? "text" : "password"}
                  onChange={(e) => setConfirm(e.target.value)}
                  endAdornment={
                    <div className="cursor-pointer" onClick={() => setShowConfirmPassword(!showConfirmPassword)} tabIndex={-1}>
                      <IconWrapper name="eye" className={showConfirmPassword ? `[&>svg>path]:!stroke-indigo-600` : ``} width={20} />
                    </div>
                  }
                />
                {errorConfirm && (
                  <Typography
                    variant="caption"
                    className="text-[#E01010] absolute mt-[2px]"
                  >
                    Passwords must match!
                  </Typography>
                )}
              </div>
            </div>

            <div className="grid sm:grid-cols-2 sm:gap-3">
              <div className="col-span-1 space-y-2 py-3">
                <Typography
                  variant="caption"
                  className="text-secondary mb-[9px]"
                >
                  License State
                </Typography>
                <Select
                  options={licenseStateList}
                  placeholder="Select"
                  value={licenseState}
                  onChange={(value) => setLicenseState(value)}
                />
              </div>
              <div className="col-span-1 space-y-2 py-3">
                <Typography
                  variant="caption"
                  className="text-secondary mb-[9px]"
                >
                  License Type
                </Typography>
                <Select
                  options={licenseTypeList}
                  placeholder=""
                  value={licenseType}
                  onChange={(value) => setLicenseType(value)}
                />
              </div>
            </div>

            {/* <div className="mb-[25px] relative py-3">
              <Typography variant="caption" className="text-secondary mb-[9px]">
                How did you hear about us?
              </Typography>
              <Select options={socialOptions} placeholder="Selecet" />
            </div> */}

            <Button
              className="w-full text-15 my-3"
              onClick={() => registerUser()}
            >
              Sign Up
            </Button>

            <Typography
              variant="body"
              className=" text-secondary text-center pt-2"
            >
              RealtyView is forever-free. We don’t required your credit card.
            </Typography>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Register;
