type Props = {
  width: number;
  height: number;
};

const IconAlert = (props: Props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 19 21" fill="none" {...props}>
      <path d="M3.806 9.24423C4.16135 13.1255 2.50527 15.5157 1.48614 16.5944C1.25315 16.8416 1.18945 17.202 1.32355 17.5138C1.45848 17.8264 1.76523 18.0275 2.10466 18.0275H6.86003C7.2003 19.4137 8.43985 20.4253 9.88054 20.4253C9.904 20.4253 9.92747 20.4253 9.95178 20.4253C11.3405 20.3935 12.5365 19.3911 12.8885 18.0283H17.9405C18.2808 18.0283 18.5875 17.8264 18.7216 17.5146C18.8557 17.2028 18.792 16.8416 18.5591 16.5952C17.5399 15.5157 15.8838 13.1263 16.2392 9.24507C16.3867 7.63508 15.7933 5.9614 14.6108 4.65396C13.8196 3.77899 12.8499 3.14539 11.8157 2.8026V1.92008C11.8157 0.861566 10.9541 0 9.89562 0C8.83711 0 7.97554 0.861566 7.97554 1.92008V2.89228C7.03687 3.25015 6.15938 3.85023 5.43358 4.65396C4.25103 5.9614 3.65765 7.63508 3.80516 9.24507L3.806 9.24423ZM9.92831 19.3651C9.05082 19.3869 8.27642 18.8304 7.96967 18.0283H11.7738C11.4629 18.7935 10.7346 19.3467 9.92831 19.3651ZM9.03657 1.91925C9.03657 1.44488 9.4221 1.05936 9.89646 1.05936C10.3708 1.05936 10.7564 1.44488 10.7564 1.91925V2.55704C10.5401 2.52854 10.3231 2.51262 10.106 2.51094H9.93585C9.63498 2.51346 9.33494 2.54279 9.03657 2.59559V1.92008V1.91925ZM6.22056 5.36383C7.2246 4.25335 8.61752 3.58287 9.94088 3.5703H10.101C11.4293 3.58287 12.8214 4.25251 13.8255 5.36383C14.8094 6.45252 15.3047 7.8312 15.1849 9.14701C14.8262 13.0634 16.3373 15.6289 17.472 16.9673H2.57399C3.70878 15.6289 5.22071 13.0634 4.86116 9.14701C4.74048 7.8312 5.23579 6.45168 6.21972 5.36383H6.22056Z" fill="#969696" />
      <path d="M1.55117 9.61811C1.57296 9.61811 1.59475 9.61643 1.61654 9.61392C1.90737 9.57872 2.11354 9.31388 2.07834 9.02306C1.58889 5.027 4.48787 2.69541 4.61694 2.594C4.84742 2.41381 4.88932 2.08108 4.70913 1.85061C4.52894 1.61929 4.19621 1.57823 3.9649 1.75758C3.82158 1.86905 0.459969 4.54174 1.02485 9.15212C1.05753 9.42115 1.28633 9.61811 1.55033 9.61811H1.55117Z" fill="#969696" />
      <path d="M15.3049 2.59406C15.429 2.69128 18.3347 5.02287 17.8444 9.02312C17.8083 9.31394 18.0153 9.57794 18.3062 9.61398C18.328 9.61649 18.3498 9.61817 18.3715 9.61817C18.6355 9.61817 18.8643 9.42122 18.897 9.15219C19.4619 4.5418 16.1011 1.86911 15.957 1.75764C15.7265 1.57829 15.3946 1.61935 15.2144 1.84983C15.0342 2.08031 15.0753 2.41303 15.3049 2.59323V2.59406Z" fill="#969696" />
    </svg>
  );
};

export default IconAlert;
