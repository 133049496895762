// Import necessary modules and components
// React imports
import { useEffect, useState } from 'react';

// Quill imports
import ReactQuill, { Quill } from 'react-quill';
import { ImageActions } from '@xeger/quill-image-actions';
import { ImageFormats } from '@xeger/quill-image-formats';
import 'react-quill/dist/quill.snow.css';

// Redux imports
import { useAppSelector, useAppDispatch } from '@/redux/hooks';
import { getWelcome, getWelcomeCards, updateWelcome } from '@/redux/welcome/welcomeSlice';

// Component imports
import { Button } from '@/components/baseComponents/Button';
import Typography from '@/components/baseComponents/Typography';

// Interface imports
import { IWelcome } from '@/shared/interfaces/interfaces';

// Service imports
import { notify } from '@/shared/services/notify';
import IconWrapper from '@/components/baseComponents/IconWrapper';
import { XMarkIcon } from '@heroicons/react/24/outline';
import { fileUpload } from '@/shared/services/utils';

// Register Quill modules
Quill.register('modules/imageActions', ImageActions);
Quill.register('modules/imageFormats', ImageFormats);

// Define Quill modules and formats
const modules = {
  imageActions: {},
  imageFormats: {},
  toolbar: [
    [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
    [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
    ['bold', 'italic', 'underline', 'strike', 'blockquote'],
    [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
    [{ 'font': [] }],
    [{ align: [] }, { align: 'center' }, { align: 'right' }, { align: 'justify' }],
    [{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'indent': '-1' }, { 'indent': '+1' }],
    ['link', 'image', 'video'],
    ['clean']
  ],
};

const formats = [
  'size', 'header',
  'bold', 'italic', 'underline', 'strike', 'blockquote',
  'color', 'background', 'font',
  'align', 'float',
  'list', 'bullet', 'indent',
  'link', 'image', 'video',
  'calltoaction',
  'width', 'height'
];

// Define the Welcome component
const Welcome = () => {
  const dispatch = useAppDispatch();
  const welcomeCards = useAppSelector(getWelcome);
  const [cardForEdit, setCardForEdit] = useState<IWelcome>();

  // Define helper functions
  const fetchData = () => {
    dispatch(getWelcomeCards()).then((res) => {
      if (!res.payload.success) {
        notify(false, "Something went wrong.");
      }
    }).catch(() => {
      notify(false, "Something went wrong.");
    });
  };

  const onEdit = (card?: IWelcome) => {
    setCardForEdit(card);
  };

  const updateCard = async () => {
    if (cardForEdit) {

      let mediaURL = cardForEdit.media;
      if (cardForEdit.media && typeof cardForEdit.media === "object") {
        mediaURL = await fileUpload(cardForEdit.media, "welcome-page-assets", false, true) ?? "";
      }

      dispatch(updateWelcome({ id: cardForEdit._id, data: { content: cardForEdit.content, media: mediaURL } })).then((res) => {
        if (!res.payload.success) {
          notify(false, "Something went wrong.");
        } else {
          fetchData();
          notify(true, "Welcome content updated successfully.");
        }
      }).catch(() => {
        notify(false, "Something went wrong.");
      });

      onEdit();
    }
  };

  const handleEditorChange = (content: string, _delta: any, _source: any, _editor: any) => {
    // console.log(editor.getText(content).split("\n").filter((text: string) => text != "").join(" "))
    setCardForEdit((prev) => {
      return {
        ...prev,
        content
      }
    });
  };

  const handleOndragOver = (event: any) => {
    event.preventDefault();
  }

  const handleMediaChange = (event: any, isDropped: boolean = false) => {
    let files: any[] = [];

    if (isDropped) {
      event.preventDefault();
      files = Array.from(event.dataTransfer.files);
    } else {
      files = Array.from(event.target.files);
    }
    console.log(files[0]);
    setCardForEdit((prev: any) => {
      return {
        ...prev,
        media: files[0]
      }
    });
  }

  // Fetch data on component mount
  useEffect(() => { fetchData(); }, []);

  // Render the component
  return (
    <div className="grid grid-cols-3 gap-x-8 gap-y-12 px-8 py-12">
      {welcomeCards && welcomeCards.map((card: IWelcome, index: number) => (
        <div
          key={index}
          className={`bg-white rounded-lg shadow-sm lg:xl:px-12 lg:xl:py-16 md:px-6 md:py-8 sm:px-6 sm:py-8 max-sm:px-6 max-sm:py-8 lg:col-span-${card.gridSpan} sm:col-span-3 max-sm:col-span-3 ${card.gridSpan !== 3 ? `flex-col-reverse` : ``}`}
        >
          <div className="flex justify-end mb-[14px] px-3">
            {card._id === cardForEdit?._id ? (
              <div className="flex gap-[14px]">
                <Button variant="contained" size="small" color="secondary" onClick={() => onEdit()}>
                  <Typography variant="button1">Cancel</Typography>
                </Button>
                <Button variant="contained" size="small" color="primary" onClick={() => updateCard()}>
                  <Typography variant="button1">Save</Typography>
                </Button>
              </div>
            ) : (
              <Button variant="contained" size="small" color="primary" onClick={() => onEdit(card)}>
                <Typography variant="button1">Edit</Typography>
              </Button>
            )}
          </div>

          {card._id === cardForEdit?._id && (
            <div className={`flex gap-3 ql-snow !border-0 ${card.gridSpan !== 3 ? `flex-col-reverse` : ``}`}>
              <ReactQuill
                theme="snow"
                className={`h-full px-3 ${card.gridSpan === 3 ? `!max-w-[49%]` : `!max-w-full`}`}
                modules={modules}
                formats={formats}
                value={cardForEdit?.content}
                onChange={handleEditorChange}
              />
              <div className="w-full px-3">
                {cardForEdit?.media ? (
                  <div className="relative h-full">
                    <button
                      className="absolute top-2 right-2 bg-white hover:bg-white rounded-full p-2 z-10"
                      onClick={() => setCardForEdit((prev: any) => { return { ...prev, media: null } })}
                    >
                      <XMarkIcon className="h-4 w-4 text-gray-500" aria-hidden="true" />
                    </button>
                    {(typeof cardForEdit.media === "string" && cardForEdit.media.includes("mp4")) || cardForEdit?.media?.type?.includes("video") ? (
                      <video
                        autoPlay={true}
                        loop={true}
                        muted={true}
                        className="rounded-lg"
                        onClick={(event) => { event.currentTarget.paused ? event.currentTarget.play() : event.currentTarget.pause(); event.preventDefault(); }}
                      >
                        <source src={typeof cardForEdit.media.name === "string" ? URL.createObjectURL(cardForEdit.media) : cardForEdit.media} type="video/mp4" />
                        Your browser does not support the video tag.
                      </video>
                    ) : (
                      <img className="w-full rounded-lg" src={typeof cardForEdit.media.name === "string" ? URL.createObjectURL(cardForEdit.media) : cardForEdit.media} />
                    )}
                  </div>
                ) : (
                  <label
                    htmlFor="propertyPhotos"
                    className={`flex flex-col justify-center items-center w-full bg-[#F1F5F9] rounded-lg cursor-pointer h-full ${card.gridSpan !== 3 ? `py-10` : ``}`}
                    onDragOver={(event) => handleOndragOver(event)} onDrop={(event) => handleMediaChange(event, true)}
                  >
                    <Typography variant="h4" color="black" className="text-center">Media</Typography>
                    <Typography variant="body" color="secondary" className="text-center w-[250px] mt-3">Click here to upload image or video</Typography>
                    <div className="flex items-center gap-2 bg-white hover:bg-white mt-6 px-5 py-2 rounded-md">
                      <IconWrapper name="image" width={14} height={14} />
                      <Typography variant="body" color="primary" className="whitespace-nowrap">Upload</Typography>
                    </div>
                    <input type="file" accept="image/*,video/mp4,video/x-m4v,video/*" id="propertyPhotos" name="propertyPhotos" multiple hidden onChange={(event) => handleMediaChange(event)} />
                  </label>
                )}
              </div>
            </div>
          )}

          {card._id !== cardForEdit?._id && (
            <div className={`ql-snow px-3 flex gap-5 ${card.gridSpan !== 3 ? `flex-col-reverse` : `max-lg:flex-col-reverse`}`}>
              <div
                className="ql-editor w-full !p-0"
                dangerouslySetInnerHTML={{ __html: card.content }}
              />
              <div className="flex items-center w-full">
                {card.media && (
                  card.media.includes("mp4") ? (
                    <video
                      autoPlay={true}
                      loop={true}
                      muted={true}
                      className="rounded-lg"
                      onClick={(event) => { event.currentTarget.paused ? event.currentTarget.play() : event.currentTarget.pause(); event.preventDefault(); }}
                    >
                      <source src={card.media} type="video/mp4" />
                      Your browser does not support the video tag.
                    </video>
                  ) : (
                    <img className="rounded-lg" src={card.media}
                    />
                  )
                )}
              </div>
            </div>
          )}
        </div>
      ))}
    </div>
  );
}

// Export the Welcome component
export default Welcome;