type Props = {
    width: number;
    height: number;
    stroke?: string;
    className?: string;
};

const IconAds = (props: Props) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 21 20" fill="none" {...props}>
            <path
                d="M10.1667 13.168C10.0791 13.4853 9.92994 13.7823 9.72756 14.042C9.52525 14.3017 9.27375 14.519 8.98744 14.6815C8.70113 14.844 8.38563 14.9486 8.05888 14.9892C7.73219 15.0297 7.40069 15.0055 7.08331 14.918C6.766 14.8304 6.46894 14.6812 6.20925 14.4789C5.94956 14.2765 5.73225 14.025 5.56975 13.7387C5.40725 13.4524 5.30275 13.1369 5.26219 12.8102C5.22162 12.4835 5.24581 12.152 5.33331 11.8347M3 8.33466L18 4.16797V14.168L3 10.8347V8.33466Z"
                stroke={props.stroke ?? "#4F46E5"}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};

export default IconAds;
