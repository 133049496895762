type Props = {
    width: number;
    height: number;
    stroke?: string;
    className?: string;
};

const IconIndicator = (props: Props) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" fill="none" {...props}>
            <path d="M4 8C4 5.79086 5.79086 4 8 4C10.2091 4 12 5.79086 12 8C12 10.2091 10.2091 12 8 12C5.79086 12 4 10.2091 4 8Z" fill="#4F46E5" />
        </svg>
    );
};

export default IconIndicator;
