// Define the type for the component props
type Props = {
    width: number;
    height: number;
    stroke?: string;
    className?: string;
};

// Define the IconChatBlue component
const IconChatBlue = (props: Props) => {
    // Return the SVG for the icon
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 25 24"
            fill="none"
            {...props}
        >

            <g id="messages-square_24">
                <path id="Vector" d="M14.5 9C14.5 9.53043 14.2893 10.0391 13.9142 10.4142C13.5391 10.7893 13.0304 11 12.5 11H6.5L2.5 15V4C2.5 2.9 3.4 2 4.5 2H12.5C13.0304 2 13.5391 2.21071 13.9142 2.58579C14.2893 2.96086 14.5 3.46957 14.5 4V9Z" stroke="#4C42D7" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
                <path id="Vector_2" d="M18.5 9H20.5C21.0304 9 21.5391 9.21071 21.9142 9.58579C22.2893 9.96086 22.5 10.4696 22.5 11V22L18.5 18H12.5C11.9696 18 11.4609 17.7893 11.0858 17.4142C10.7107 17.0391 10.5 16.5304 10.5 16V15" stroke="#4C42D7" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
            </g>
        </svg>
    );
};

// Export the IconChatBlue component
export default IconChatBlue;
