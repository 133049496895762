import { toast } from 'react-toastify';

const isMobileDevice = () => window.innerWidth <= 768;

export const notify = (state: boolean, value: string) => {
    const position = isMobileDevice() ? "top-center" : "bottom-left";

    if (state) {
        toast.success(value, {
            position: position
        });
    } else {
        toast.error(value, {
            position: position
        });
    }
}
