import classNames from "classnames";
// types
import { IconList, IconType } from "./Icons";

// ----------------------------------------------------------------------
type Props = {
  name: IconType;
  className?: string;
  width?: number;
  height?: number;
  stroke?: string;
  innerStroke?: string;
  iconClassName?: string;
};

const IconWrapper = ({
  name,
  className,
  width = 24,
  height = 24,
  stroke,
  innerStroke,
  iconClassName,
}: Props) => {
  const IconTag = IconList[name];

  return (
    <span className={classNames("flex items-center justify-center", className)}>
      <IconTag
        width={width}
        height={height}
        stroke={stroke}
        innerstroke={innerStroke}
        className={className + (iconClassName ? " " + iconClassName : "")}
      />
    </span>
  );
};

export default IconWrapper;
