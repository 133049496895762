// React imports
import { useEffect, useState } from "react";
import { useCookies } from "react-cookie";

// Redux-related imports
import { useAppDispatch, useAppSelector } from "@/redux/hooks";
import {
  bookmarkMyListings,
} from "@/redux/myListings/myListingSlice";
import { getAdvertisementsFromDB } from "@/redux/advertisement/advertisementSlice";
import { getHomepageFeedsFromDB } from "@/redux/homepageFeed/homepageFeedSlice";
import { getPostsFromDB } from "@/redux/post/postSlice";
import { getUser, updateMyInfo } from "@/redux/user/userSlice";
import { getDashboardPreferencesState, getEvents } from "@/redux/dashboard/dashboardSlice";

// Interface imports
import {
  GetAdvertisementsDto,
  GetHomepageFeedsDto,
  GetPostsDto,
  IPost,
  BookmarkMyListingDto,
  UpdateMyInfoDto,
} from "@/shared/interfaces/interfaces";
import { IDashboardPreferences } from "@/shared/interfaces/dashboardPreferences/IDashboardPreferences";

// Component imports
import { Button } from "@/components/baseComponents/Button";
import Typography from "@/components/baseComponents/Typography";
import IconWrapper from "@/components/baseComponents/IconWrapper";
import PostModal from "@/pages/dashboard/PostModal";
import PropertyCarousel from "@/components/mainComponents/PropertyCarousel";
import AgentDrawer from "@/pages/agent/ViewDrawer";
import ListingDrawer from "@/pages/myListings/View";
import Label from "@/components/baseComponents/Label";
import DashboardPreferences from "@/components/mainComponents/DashboardPreferences";
import UpcomingShowings, { Showing } from "./components/UpcomingShowings";

// Third-party library imports
import { differenceInDays, differenceInHours, differenceInMinutes, differenceInMonths, differenceInWeeks } from "date-fns";
import Scrollbars from "react-custom-scrollbars";
import StickyBox from "react-sticky-box";
import { useOutletContext } from "react-router-dom";
import Skeleton from "react-loading-skeleton";

// Asset imports
import AddProfile from "@/assets/images/add_profile.svg";
import DefaultProfilePicture from "@/assets/images/default_profile_picture.webp";
import DefaultAdsImage from "@/assets/images/default_ads_image.webp";

// Utility and service imports
import { notify } from "@/shared/services/notify";
import { importanceLevels } from "@/shared/config/constants";
import { getGreeting } from "@/shared/services/utils";

// CSS imports
import "react-loading-skeleton/dist/skeleton.css";
import { getPendingShowing } from "@/redux/showing/showingSlice";
import { unwrapResult } from "@reduxjs/toolkit";

const DashboardSimple = () => {
  // Redux hooks
  const dispatch = useAppDispatch();
  const user = useAppSelector(getUser);
  const greeting = getGreeting(user);

  // Cookie state
  const [cookies, setCookie] = useCookies(["muteAD", "muteFeed"]);

  // Post state
  const defaultPost: IPost = {
    postTitle: "",
    postContent: ""
  };
  const [selectedPost, setSelectedPost] = useState<IPost>(defaultPost);
  const [openPostModal, setOpenPostModal] = useState<boolean>(false);

  // Listing state
  const [listings, setListings] = useState<Array<any>>([]);
  const [openListingDrawer, setOpenListingDrawer] = useState<boolean>(false);
  const [listingBeingViewed, setListingBeingViewed] = useState<any>();

  // Advertisement state
  const [advertisements, setAdvertisements] = useState<any[]>([]);
  const [showADPanel, setShowADPanel] = useState<boolean>(true);

  // Showing state
  const [showings, setShowings] = useState<Showing[]>([]);

  // Homepage feed state
  const [homepageFeed, setHomepageFeed] = useState<any>(null);
  const [showFeedPanel, setShowFeedPanel] = useState<boolean>(true);

  // Post state
  const [posts, setPosts] = useState<Array<any>>([]);

  // Agent state
  const [openAgentDrawer, setOpenAgentDrawer] = useState<boolean>(false);
  const [agentBeingViewed, setAgentBeingViewed] = useState<any>(user.agent);

  // Loading and pagination state
  const [isLoading, setIsLoading] = useState(false);
  const [fetchNumber, setFetchNumber] = useState<number>(1);
  const [limit, setLimit] = useState<number>(25);
  const [hasMoreData, setHasMoreData] = useState<boolean>(true);

  // Dashboard Preferences state
  const dashboardPreferencesInitialState: IDashboardPreferences = {
    locations: [],
    relationships: [],
    isAllLeads: false,
    isAllClients: false,
    user: user._id,
    fetch: 1,
    limit: 25
  };
  const dashboardPreferencesState = useAppSelector(getDashboardPreferencesState);
  const [dashboardPreferences, setDashboardPreferences] = useState<IDashboardPreferences>(dashboardPreferencesState?.user ? dashboardPreferencesState : dashboardPreferencesInitialState);

  // Scrollbar context
  const [scrollbars]: any = useOutletContext();

  const onCloseFeedPanel = () => {
    setCookie("muteFeed", homepageFeed._id, {
      path: "/",
      expires: new Date(Date.now() + 24 * 60 * 60 * 1000)
    });
    setShowFeedPanel(false);
  };

  const onClickPost = (value: any) => {
    setSelectedPost(value);
    setOpenPostModal(true);
  };

  const handleNavigate = (url: string) => {
    window.open(url, "_blank");
  };

  const fetchAds = () => {
    const data: GetAdvertisementsDto = {
      userId: "",
      keyword: "",
      sortType: "Descending",
      sortField: "",
      recordsPerPage: 10,
      currentPage: 1,
      status: "active"
    };

    dispatch(getAdvertisementsFromDB(data)).then((res: any) => {
      try {
        if (res.payload?.advertisements?.length > 0) {
          setAdvertisements(res.payload.advertisements);
        }
      } catch (e) {
        notify(false, "Something went wrong.");
      }
    });
  };

  const fetchFeeds = () => {
    const data: GetHomepageFeedsDto = {
      userId: "",
      keyword: "",
      sortType: "Descending",
      sortField: "",
      recordsPerPage: 10,
      currentPage: 1,
      status: "active"
    };

    dispatch(getHomepageFeedsFromDB(data)).then((res: any) => {
      try {
        if (res.payload?.homepageFeeds?.length > 0) {
          setHomepageFeed(res.payload.homepageFeeds[0]);
        }
      } catch (e) {
        notify(false, "Something went wrong.");
      }
    });
  };

  const fetchPosts = () => {
    const data: GetPostsDto = {
      userId: "",
      keyword: "",
      sortType: "Descending",
      sortField: "",
      recordsPerPage: 10,
      currentPage: 1
    };

    dispatch(getPostsFromDB(data)).then((res: any) => {
      try {
        if (res.payload?.posts?.length > 0) {
          setPosts(res.payload.posts);
        }
      } catch (e) {
        notify(false, "Something went wrong.");
      }
    });
  };

  /**
   * Using `unwrapResult` to infer type
   */
  const fetchShowings = () => {
    dispatch(getPendingShowing(user._id)).then(unwrapResult).then(result => {
      const showings = result.map(item => ({ ...item, dateTime: new Date(item.dateTime) }));
      setShowings(showings);
    });
  };

  const getTimePassed = (updatedAt: any) => {
    const dateTimeNow = new Date();
    const parsedCreatedAt = new Date(updatedAt);
    const timePassedInDays = differenceInDays(dateTimeNow, parsedCreatedAt);
    let timePassed = "Just now";

    if (timePassedInDays === 0) {
      const timePassedInMinutes = differenceInMinutes(dateTimeNow, parsedCreatedAt);

      if (timePassedInMinutes >= 60) {
        const timePassedInHours = differenceInHours(dateTimeNow, parsedCreatedAt);
        timePassed = `${timePassedInHours} hour${timePassedInHours > 1 ? `s` : ``} ago`;
      } else {
        if (timePassedInMinutes === 0) {
          timePassed = "Just now";
        } else {
          timePassed = `${timePassedInMinutes} minute${timePassedInMinutes > 1 ? `s` : ``} ago`;
        }
      }
    } else if (timePassedInDays === 1) {
      timePassed = "Yesterday";
    } else if (timePassedInDays >= 7) {
      const timePassedInWeeks = differenceInWeeks(dateTimeNow, parsedCreatedAt);

      if (timePassedInWeeks >= 5) {
        const timePassedInMonths = differenceInMonths(dateTimeNow, parsedCreatedAt);
        timePassed = `${timePassedInMonths} month${timePassedInMonths > 1 ? `s` : ``} ago`;
      } else {
        timePassed = `${timePassedInWeeks} week${timePassedInWeeks > 1 ? `s` : ``} ago`;
      }
    } else {
      timePassed = `${timePassedInDays} day${timePassedInDays > 1 ? `s` : ``} ago`;
    }

    return timePassed;
  };

  const onOpenAgentDrawer = (agent: any, listing: any) => {
    setAgentBeingViewed(agent);
    setListingBeingViewed(listing);
    setOpenAgentDrawer(true);
  };

  const onOpenListingDrawer = (listing: any) => {
    setListingBeingViewed(listing);
    setOpenListingDrawer(true);
  };

  const onBookmark = (id: any, isBookmarked: boolean) => {
    const data: BookmarkMyListingDto = {
      ids: [id],
      bookmark: isBookmarked,
      userId: user._id,
      search: {
        userId: user._id,
        keyword: "",
        sortType: "Descending",
        sortField: "Latest",
        recordsPerPage: 1,
        currentPage: 1,
        status: ""
      }
    };
    dispatch(bookmarkMyListings(data)).then((res) => {
      try {
        notify(res.payload.success, res.payload.message);
        let data: UpdateMyInfoDto = {
          email: user.email,
          data: user.agent
        };
        dispatch(updateMyInfo(data));
      } catch (e) {
        notify(false, "Something went wrong.");
      }
    });
  };

  const applyDashboardPreferences = (dashboardPreferences: IDashboardPreferences, isFromButtonClick: boolean = false) => {
    setIsLoading(true);
    dispatch(getEvents({
      ...dashboardPreferences,
      fetch: fetchNumber,
      limit,
      user: user._id
    })).then(result => {
      if (isFromButtonClick) {
        setListings(result.payload.data?.listings);
        setFetchNumber(1);
      } else {
        setListings([...listings, ...result.payload.data?.listings]);
        setHasMoreData(result.payload.data?.listings.length > 0);
      }
    }).finally(() => setIsLoading(false));
  }

  const resetDashboardPreferences = () => {
    setDashboardPreferences(dashboardPreferencesInitialState);
    dispatch(getEvents(dashboardPreferencesInitialState)).then(result => {
      setListings(result.payload.data?.listings);
    })
  }

  useEffect(() => {
    fetchAds();
    fetchFeeds();
    fetchPosts();
    fetchShowings();
    setLimit(25);
  }, []);

  useEffect(() => {
    if (!openAgentDrawer) applyDashboardPreferences({ ...dashboardPreferences, user: user._id }, false);
  }, [openAgentDrawer]);

  useEffect(() => {
    applyDashboardPreferences({ ...dashboardPreferences, user: user._id }, false);
  }, [fetchNumber]);

  useEffect(() => {
    scrollbars.current.props = {
      ...scrollbars.current.props,
      onScroll: () => {
        const scrollHeight = scrollbars.current.getScrollHeight();
        const scrollTop = scrollbars.current.getScrollTop();
        if (!isLoading && scrollHeight - 1000 <= scrollTop) {
          setFetchNumber((prev) => {
            return prev + 1;
          });
          setIsLoading(true);
        }
      }
    };
  }, [isLoading]);

  useEffect(() => {
    if (cookies.muteAD && advertisements[0] && cookies.muteAD === advertisements[0]._id) {
      setShowADPanel(false);
    }

    if (cookies.muteFeed && homepageFeed && cookies.muteFeed === homepageFeed._id) {
      setShowFeedPanel(false);
    }
  }, [cookies, advertisements, homepageFeed]);

  return (
    <div className="flex flex-col xl:p-10 lg:p-8 md:p-6 sm:p-4 max-sm:p-4">
      {(openPostModal || openAgentDrawer || openListingDrawer) && <div className="bg-[#00000040] w-full h-screen flex items-center justify-center fixed top-0 left-0 z-30"></div>}

      <AgentDrawer open={openAgentDrawer} changeViewDrawer={setOpenAgentDrawer} data={agentBeingViewed} listingData={listingBeingViewed} />
      <ListingDrawer open={openListingDrawer} changeState={setOpenListingDrawer} data={listingBeingViewed} />
      <div className="flex items-center justify-between">
        <div>
          <Typography variant="h2" color="black" className="whitespace-nowrap">
            {greeting}
          </Typography>
          <Typography variant="body" color="secondary">
            Welcome to your RealtyView!
          </Typography>
        </div>
        <div className="lg:block md:sm:hidden sm:hidden max-sm:hidden">
          <DashboardPreferences
            dashboardPreferencesInitialState={dashboardPreferencesInitialState}
            dashboardPreferences={dashboardPreferences}
            setDashboardPreferences={setDashboardPreferences}
            applyDashboardPreferences={applyDashboardPreferences}
            resetDashboardPreferences={resetDashboardPreferences}
          />
        </div>
      </div>
      <div className="grid grid-cols-3 gap-[15px] md:my-8 sm:my-4 max-sm:my-4 ">
        <div className="lg:col-span-2 max-sm:col-span-3 sm:max-lg:col-span-3 flex flex-col">
          {showFeedPanel && homepageFeed !== null && homepageFeed !== undefined && (
            <div className="relative flex flex-col w-full h-fit p-[16px] bg-purple-300 bg-opacity-20 bg-shadow-sm rounded-lg mb-5 gap-y-[14px]">
              <div className="flex justify-between items-center">
                <Typography variant="h3" color="primary">
                  {homepageFeed.title ?? ""}
                </Typography>
                {homepageFeed?.isMuted === 1 && (
                  <Button variant="icon" onClick={() => onCloseFeedPanel()}>
                    <IconWrapper name="close" />
                  </Button>
                )}
              </div>
              <Typography variant="body" color="primary">
                {homepageFeed.content ?? ""}
              </Typography>
              <Typography variant="body" color="indigo" className="underline">
                <a target="_blank" href={homepageFeed.url ?? "#"}>
                  {homepageFeed.urlLabel}
                </a>
              </Typography>
            </div>
          )}

          <div className="lg:hidden md:sm:block sm:block max-sm:block text-right mb-5">
            <DashboardPreferences
              dashboardPreferencesInitialState={dashboardPreferencesInitialState}
              dashboardPreferences={dashboardPreferences}
              setDashboardPreferences={setDashboardPreferences}
              applyDashboardPreferences={applyDashboardPreferences}
              resetDashboardPreferences={resetDashboardPreferences}
            />
          </div>

          {listings?.map((listing, index) => {
            const isBookmarked = user.bookmarkedListings?.includes(listing.id) || false;
            if (listing?.owner && listing?.owner?.agent) {
              return (
                <div key={index}>
                  <div className="flex flex-col bg-white p-[16px] rounded-xl shadow-sm mb-[25px] gap-y-4">
                    <div className="flex flex-row items-center gap-2 border-b-1 pb-[15px]">
                      <img
                        className="aspect-square w-5 rounded-full cursor-pointer"
                        src={listing.owner?.agent?.avatarURL !== "" && listing.owner?.agent?.avatarURL !== undefined ? listing.owner.agent?.avatarURL : AddProfile}
                        onError={(e) => (e.currentTarget.src = DefaultProfilePicture)}
                        onClick={() => onOpenAgentDrawer(listing.owner.agent, listing)}
                      />
                      <div className="flex items-center gap-1">
                        <Typography variant="body" color="primary" className="cursor-pointer whitespace-nowrap" onClick={() => onOpenAgentDrawer(listing.owner.agent, listing)}>
                          {listing.owner?.agent?.firstName + " " + listing.owner?.agent?.lastName}
                        </Typography>
                        <Typography variant="body" color="secondary" className="max-sm:hidden">
                          listed
                        </Typography>
                        <Typography variant="body" color="primary" className="cursor-pointer line-clamp-1 max-sm:hidden" onClick={() => onOpenListingDrawer(listing)}>
                          {listing.isPublic
                            ? `${listing.listingAddress.streetLine} ${listing.listingUnit || listing.listingAddress.secondary} ${listing.listingAddress.city}, 
                                ${listing.listingAddress.state} ${listing.listingAddress.zipcode}`
                            : `${listing.listingAddress.city}, ${listing.listingAddress.state} ${listing.listingAddress.zipcode}` || "a property"}
                        </Typography>
                      </div>
                    </div>
                    <div className="flex flex-col gap-y-4 cursor-pointer" onClick={() => onOpenListingDrawer(listing)}>
                      <div className="flex justify-between md:items-center px-1">
                        <Typography variant="body" color="primary" className="font-semibold cursor-pointer">
                          {listing.isPublic
                            ? `${listing.listingAddress.streetLine} ${listing.listingUnit || listing.listingAddress.secondary} ${listing.listingAddress.city}, 
                              ${listing.listingAddress.state} ${listing.listingAddress.zipcode}`
                            : `${listing.listingAddress.city}, ${listing.listingAddress.state} ${listing.listingAddress.zipcode}` || "Address Kept Private"}
                        </Typography>
                        <Button variant="icon" className="group h-5 active:bg-gray-100 rounded-lg" onClick={(event) => { event.stopPropagation(); onBookmark(listing.id, !isBookmarked); }}>
                          <IconWrapper
                            name="bookmark"
                            className={
                              isBookmarked
                                ? `[&>svg>path]:!stroke-[#4F46E5] [&>svg>path]:!fill-[#4F46E5] group-hover:[&>svg>path]:!fill-[#6366F1] group-hover:[&>svg>path]:!stroke-[#6366F1]`
                                : `group-hover:[&>svg>path]:!stroke-[#6366F1] group-active:[&>svg>path]:!stroke-[#4F46E5]`
                            }
                          />
                        </Button>
                      </div>
                      <div className="flex flex-wrap gap-2 px-1">
                        {listing.propertyType && (
                          <div className="flex gap-1 items-center bg-gray-100 px-2 py-0 rounded-full">
                            <IconWrapper name="home-alt" width={15} />
                            <Typography variant="medium-text" color="primary" className="truncate">
                              {listing.propertyType}
                            </Typography>
                          </div>
                        )}
                        {listing.propertyBedrooms && (
                          <div className="flex gap-1 items-center bg-gray-100 px-2 py-0 rounded-full">
                            <IconWrapper name="bed" width={15} />
                            <Typography variant="medium-text" color="primary" className="truncate">
                              {listing.propertyBedrooms.toLocaleString() + " Bedroom" + (listing.propertyBedrooms > 1 ? "s" : "")}
                            </Typography>
                          </div>
                        )}
                        {listing.propertyBathrooms && (
                          <div className="flex gap-1 items-center bg-gray-100 px-2 py-0 rounded-full">
                            <IconWrapper name="bath" width={15} />
                            <Typography variant="medium-text" color="primary" className="truncate">
                              {listing.propertyBathrooms.toLocaleString() + " Bathroom" + (listing.propertyBathrooms > 1 ? "s" : "")}
                            </Typography>
                          </div>
                        )}
                        {listing.propertyLotSqft && (
                          <div className="flex gap-1 items-center bg-gray-100 px-2 py-0 rounded-full">
                            <IconWrapper name="graph" width={15} />
                            <Typography variant="medium-text" color="primary" className="truncate">
                              {parseInt(listing.propertyLotSqft).toLocaleString() + " SqFt"}
                            </Typography>
                          </div>
                        )}
                      </div>
                      <div>
                        <PropertyCarousel data={listing.propertyPhotos} />
                      </div>
                      <div className="flex justify-between gap-y-[10px] px-1">
                        <div className="flex gap-2 items-center">
                          {listing.isArchived === 1 ? (
                            <Button className="bg-red-100 hover:bg-red-100 !cursor-default h-6 !px-2">
                              <Typography variant="medium-text" className="text-[#B32F43]">
                                Archived
                              </Typography>
                            </Button>
                          ) : (
                            <Label
                              variant="contained"
                              color={
                                listing.status === "Active"
                                  ? "success"
                                  : listing.status === "Pending"
                                    ? "warning"
                                    : listing.status === "Closed" || listing.status === "Withdrawn"
                                      ? "gray"
                                      : ""
                              }
                              size="small"
                              className="rounded-md"
                              title={listing.status}
                            />
                          )}
                          <Typography variant="caption" color="secondary" className="capitalize justify-self-end whitespace-nowrap">
                            {getTimePassed(listing.updatedAt)}
                          </Typography>
                        </div>
                        <Typography variant="body" className="font-semibold">
                          $ {listing.listingListPrice.toLocaleString()}
                        </Typography>
                      </div>
                    </div>
                  </div>
                  {(index + 1) % 2 === 0 && showADPanel && advertisements[0] !== null && advertisements[0] !== undefined && (
                    <div className="bg-white shadow-sm rounded-lg flex gap-[14px] p-[16px] mb-[25px] cursor-pointer" onClick={() => handleNavigate(advertisements[0].adLinkURL)}>
                      <img
                        className="rounded-lg object-cover object-center w-20 h-20"
                        src={advertisements[0]?.adImageURL && advertisements[0].adImageURL !== "" ? advertisements[0].adImageURL : DefaultAdsImage}
                        alt={advertisements[0].adTitle}
                        onError={(e) => (e.currentTarget.src = DefaultAdsImage)}
                      />
                      <div className="flex flex-col gap-[8px]">
                        <Typography variant="h4">{advertisements[0].adTitle}</Typography>
                        <Typography variant="caption">{advertisements[0].adContent}</Typography>
                      </div>
                    </div>
                  )}
                </div>
              );
            }
          })}
          {hasMoreData && isLoading && (
            <>
              <div className="flex flex-col gap-3 p-5">
                <div className="flex flex-row gap-3">
                  <Skeleton circle className="w-16 h-16" />
                  <div className="flex flex-col gap-1 w-full justify-center">
                    <Skeleton className="w-full h-5" />
                    <Skeleton className="w-[200px] h-5" />
                  </div>
                </div>
                <div className="flex flex-row gap-3">
                  <Skeleton className="w-[50px] h-5" />
                  <Skeleton className="w-[50px] h-5" />
                  <Skeleton className="w-[50px] h-5" />
                  <Skeleton className="w-[50px] h-5" />
                </div>
                <div className="grid grid-cols-3 gap-3">
                  <Skeleton className="h-24" />
                  <Skeleton className="h-24" />
                  <Skeleton className="h-24" />
                </div>
              </div>
              <div className="flex flex-col gap-3 p-5">
                <div className="flex flex-row gap-3">
                  <Skeleton circle className="w-16 h-16" />
                  <div className="flex flex-col gap-1 w-full justify-center">
                    <Skeleton className="w-full h-5" />
                    <Skeleton className="w-[200px] h-5" />
                  </div>
                </div>
                <div className="flex flex-row gap-3">
                  <Skeleton className="w-[50px] h-5" />
                  <Skeleton className="w-[50px] h-5" />
                  <Skeleton className="w-[50px] h-5" />
                  <Skeleton className="w-[50px] h-5" />
                </div>
                <div className="grid grid-cols-3 gap-3">
                  <Skeleton className="h-24" />
                  <Skeleton className="h-24" />
                  <Skeleton className="h-24" />
                </div>
              </div>
            </>
          )}
        </div>
        <div className="flex flex-col lg:block sm:hidden max-sm:hidden">
          <StickyBox offsetTop={70} className="h-[590px] flex flex-col">
            <Scrollbars autoHide>
              <div className="flex flex-col gap-4">
                {showADPanel && advertisements[0] !== null && advertisements[0] !== undefined && (
                  <div className="border-b-1 grid grid-cols-3 p-[16px] cursor-pointer" onClick={() => handleNavigate(advertisements[0].adLinkURL)}>
                    <img
                      className="rounded-lg object-cover object-center w-20 h-20"
                      src={advertisements[0]?.adImageURL && advertisements[0].adImageURL !== "" ? advertisements[0].adImageURL : DefaultAdsImage}
                      alt={advertisements[0].adTitle}
                      onError={(e) => (e.currentTarget.src = DefaultAdsImage)}
                    />
                    <div className="col-span-2 flex flex-col gap-[0px]">
                      <Typography variant="h4">{advertisements[0].adTitle}</Typography>
                      <Typography variant="caption">{advertisements[0].adContent}</Typography>
                    </div>
                  </div>
                )}
                <div className="flex flex-col bg-white py-8 px-8 rounded-lg gap-4">
                  <Typography variant="h4">Latest Updates</Typography>
                  <div className="flex flex-col gap-2">
                    {posts?.map((post, index) => (
                      <div key={index} className="flex flex-row gap-2 items-center h-[30px] cursor-pointer" onClick={() => onClickPost(post)}>
                        <div className="flex items-center justify-center">
                          <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 10 10" fill="none">
                            <circle cx="5" cy="5" r="5" fill={importanceLevels[post.postImportanceLevel].color} />
                          </svg>
                        </div>
                        <Typography variant="body" className="hover:font-semibold">
                          {post.postTitle}
                        </Typography>
                      </div>
                    ))}
                  </div>
                </div>
                <UpcomingShowings showings={showings} />
              </div>
            </Scrollbars>
          </StickyBox>
        </div>
      </div>
      <PostModal open={openPostModal} value={selectedPost} closeModal={() => setOpenPostModal(false)} />
    </div>
  );
};

export default DashboardSimple;
