type Props = {
  width: number;
  height: number;
  stroke?: string;
  className?: string;
};

const IconClose = (props: Props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" {...props}>
      <path d="M18 6L6 18" stroke="#3C3C3C" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M6 6L18 18L6 6Z" fill="#3C3C3C" />
      <path d="M6 6L18 18" stroke="#3C3C3C" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};

export default IconClose;
