import { Fragment, useState } from "react";
import classNames from "classnames";

// @headlessui
import { Combobox, Transition } from "@headlessui/react";

// icon
import Icon from "../IconWrapper";

// components
import TextField from "../TextField";
import Typography from "../Typography";
import { Button } from "../Button";

// utils
import IconWrapper from "../IconWrapper";

type Props = {
  options: Array<any>;
  filterKey: string;
  value: any;
  placeholder?: string;
  className?: string;
  onChange: (value: any) => void;
  onAllChange: (value: any) => void;
  onClear?: VoidFunction;
  leadingClassName?: string;
};

const AutoComplete = ({
  options = [],
  filterKey,
  value,
  placeholder,
  className,
  onChange,
  onAllChange,
  onClear,
  leadingClassName,
}: Props) => {
  const [query, setQuery] = useState("");

  const changeInput = (value: any) => {
    setQuery(value);
    onChange(value);
  };

  const changeValue = (value: any) => {
    setQuery(value);
    onAllChange(value);
  };

  return (
    <div className={classNames(className, "relative")}>
      <Combobox value={value}>
        {({ open }) => (
          <div className="relative">
            <Combobox.Input
              className={`bg-[#F1F5F9]`}
              as={TextField}
              startAdornment={
                <IconWrapper
                  className={leadingClassName}
                  name="search"
                />
              }
              inputClassName="aria-expanded:rounded-b-none"
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              displayValue={value}
              onChange={(event) => changeInput(event.target.value)}
              placeholder={placeholder}
              autoComplete="on"
              {...(open
                ? {
                  endAdornment: (
                    <Button variant="icon" color="inherit" onClick={onClear}>
                      <Icon name="close" />
                    </Button>
                  ),
                }
                : null)}
            />

            <Transition
              as={Fragment}
              leave="transition-all ease-in duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              afterLeave={() => setQuery("")}
            >
              <Combobox.Options className="absolute z-10 w-full py-2 overflow-auto transition-opacity bg-white max-h-60 rounded-b-5 border-1 border-netural-dark border-t-netural">
                {options.length === 0 && query !== "" ? (
                  <Typography variant="body" className="px-4">
                    {filterKey === "businessName" &&
                      "Your Brokerage was not found. Continue typing to add your company."}
                    {filterKey === "teamName" &&
                      "Your Team was not found. Continue typing to add your team."}
                  </Typography>
                ) : (
                  options
                    .filter((option: any) => option[filterKey] !== "")
                    .map((option) => (
                      <Combobox.Option
                        key={option._id}
                        className={({ active }) =>
                          classNames(
                            "relative flex cursor-default select-none py-2 px-4 pr-4 hover:bg-neutral-100 items-center",
                            {
                              ["bg-netural"]: active,
                            }
                          )
                        }
                        value={option[filterKey]}
                        onClick={() => changeValue(option)}
                      >
                        <Typography
                          variant="body"
                          className="whitespace-pre"
                          color="secondary"
                        >
                          {option[filterKey]}
                        </Typography>
                        <Typography
                          variant="caption"
                          color="secondary"
                          className="ml-3 whitespace-nowrap"
                        >
                          {filterKey === "businessName" &&
                            (option["businessAddress1"] ? option["businessAddress1"] + " " : "") +
                            (option["businessAddress2"] ? option["businessAddress2"] + " " : "") +
                            option["businessCity"] +
                            "," +
                            " " +
                            option["businessState"] +
                            " " +
                            option["businessZip"]}
                          {filterKey === "teamName" &&
                            option["teamLeaderFirstName"] +
                            " " +
                            option["teamLeaderLastName"]}
                        </Typography>
                      </Combobox.Option>
                    ))
                )}
              </Combobox.Options>
            </Transition>
          </div>
        )}
      </Combobox>
    </div>
  );
};

export default AutoComplete;
