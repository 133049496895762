// Define the type for the component props
type Props = {
  width: number;
  height: number;
  stroke?: string;
  className?: string;
  innerstroke?: string;
};

// Define the IconArrowRight component
const IconArrowRight = (props: Props) => {
  // Return the SVG for the icon
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 16 16"
      fill="none"
      {...props}
    >
      <path
        d="M3.33325 8H12.6666"
        stroke={props.innerstroke ? props.innerstroke : "white"}
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8 3.33301L12.6667 7.99967L8 12.6663"
        stroke={props.innerstroke ? props.innerstroke : "white"}
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

// Export the IconArrowRight component
export default IconArrowRight;
