type Props = {
  width: number;
  height: number;
  stroke?: string;
  className?: string;
};

const IconRequestTour = (props: Props) => {
  return (
    <svg
      viewBox="0 0 21 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clip-path="url(#clip0_10993_14723)">
        <path
          d="M3.83398 6.66536V4.9987C3.83398 4.55667 4.00958 4.13275 4.32214 3.82019C4.6347 3.50763 5.05862 3.33203 5.50065 3.33203H7.16732"
          stroke="white"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M3.83398 13.332V14.9987C3.83398 15.4407 4.00958 15.8646 4.32214 16.1772C4.6347 16.4898 5.05862 16.6654 5.50065 16.6654H7.16732"
          stroke="white"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M13.834 3.33203H15.5007C15.9427 3.33203 16.3666 3.50763 16.6792 3.82019C16.9917 4.13275 17.1673 4.55667 17.1673 4.9987V6.66536"
          stroke="white"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M13.834 16.6654H15.5007C15.9427 16.6654 16.3666 16.4898 16.6792 16.1772C16.9917 15.8646 17.1673 15.4407 17.1673 14.9987V13.332"
          stroke="white"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M10.5 9.16797V9.1763"
          stroke="white"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M10.4993 15.0005L7.58268 10.8338C7.30191 10.3263 7.15839 9.75437 7.16633 9.17446C7.17426 8.59454 7.33336 8.02673 7.62792 7.52713C7.92247 7.02753 8.34228 6.61342 8.84586 6.32573C9.34945 6.03804 9.91938 5.88672 10.4993 5.88672C11.0793 5.88672 11.6493 6.03804 12.1528 6.32573C12.6564 6.61342 13.0762 7.02753 13.3708 7.52713C13.6653 8.02673 13.8244 8.59454 13.8324 9.17446C13.8403 9.75437 13.6968 10.3263 13.416 10.8338L10.4993 15.0005Z"
          stroke="white"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      
    </svg>
  );
};

export default IconRequestTour;
