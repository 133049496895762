import { SyntheticEvent, useState } from 'react';
import ReactDatePicker, {
  ReactDatePickerCustomHeaderProps,
} from 'react-datepicker';
import { format } from 'date-fns';
import classNames from 'classnames';
// components
import { Button } from '../Button';
import Icon from '../IconWrapper';
import { PatternFormat } from 'react-number-format';
import { format as formatDate } from 'date-fns';
import { removeFirstDuplicateClassNames } from '../../../shared/config/constants';

// ----------------------------------------------------------------------
type Props = {
  className?: string;
  value?: Date | null;
  placeholder?: string;
  format?: string;
  minDate?: Date;
  name?: string;
  innerClassName?: string;
  //date icon class name
  leadingClassName?: string;
  onChange?: (
    date: Date | null,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    event: SyntheticEvent<any, Event> | undefined
  ) => void;
};

const DatePicker = ({
  className,
  value,
  placeholder,
  format = 'MM/dd/yyyy',
  minDate,
  name,
  onChange,
  innerClassName,
  leadingClassName
}: Props) => {
  const [inValue, setInValue] = useState<Date | null>(null);
  const existingLeadingClass = "absolute top-[20%] left-1";
  const modifiedLeadingClass =
    leadingClassName ?
      removeFirstDuplicateClassNames(existingLeadingClass + ' ' + leadingClassName)
      : existingLeadingClass;
  const existingInnerClass = "body rounded-md h-[42px] p-4 pl-10 outline-none w-full border-transparent bg-netural focus:border-transparent "
  const modifiedInnerClass = innerClassName ?
    removeFirstDuplicateClassNames(existingInnerClass + ' ' + innerClassName)
    : existingInnerClass;
  return (
    <>
      <ReactDatePicker
        wrapperClassName={classNames('w-full', className)}
        minDate={minDate}
        selected={value && value !== undefined ? value : inValue}
        onChange={onChange ? onChange : (date) => setInValue(date)}
        customInput={
          <div className="relative">
            <Icon name="calendar" className={modifiedLeadingClass} width={20} />
            <PatternFormat
              value={value && formatDate(new Date(value), "MM/dd/yyyy")}
              format="##/##/####" mask="-" placeholder="MM/DD/YYYY"
              className={`${modifiedInnerClass}`}
            />
          </div>
        }
        showPopperArrow={false}
        autoComplete="off"
        todayButton="Today"
        renderCustomHeader={CustomDatePickerHeader}
        formatWeekDay={(day) => day.substring(0, 3)}
        placeholderText={placeholder ?? format.toLowerCase()}
        dateFormat={format}
        name={name ?? 'datapicker'}
        preventOpenOnFocus
      ></ReactDatePicker>
    </>
  );
};

const CustomDatePickerHeader = ({
  date,
  decreaseMonth,
  decreaseYear,
  increaseMonth,
  increaseYear,
}: ReactDatePickerCustomHeaderProps) => {
  return (
    <div className="flex items-center gap-1 pb-2">
      <Button variant="icon" color="inherit" className="w-6 h-6" onClick={decreaseYear}>
        <Icon name="arrow-backward-multiple" />
      </Button>
      <Button variant="icon" color="inherit" className="w-6 h-6" onClick={decreaseMonth}>
        <Icon name="arrow-backward" />
      </Button>

      <div className="flex-1" />

      <div className="current-date-label">{format(date, 'MMMM, yyyy')}</div>

      <div className="flex-1" />

      <Button variant="icon" color="inherit" className="w-6 h-6" onClick={increaseMonth}>
        <Icon name="arrow-forward" />
      </Button>
      <Button variant="icon" color="inherit" className="w-6 h-6" onClick={increaseYear}>
        <Icon name="arrow-forward-multiple" />
      </Button>
    </div>
  );
};

export default DatePicker;
