// React hooks
import React, { useEffect, useRef, useState } from "react";

// Custom scrollbar component
import Scrollbars from "react-custom-scrollbars";

// Interfaces
import {
  GetMessagesDto,
  IMessageDto,
  IMyListing,
  IParticipantDto,
} from "@/shared/interfaces/interfaces";

// Redux hooks and actions
import { useAppSelector, useAppDispatch } from "@/redux/hooks";
import { getUser } from "@/redux/user/userSlice";
import { fetchMessages, getParticipantId } from "@/redux/chat/chatSlice";

// Base components and utilities
import Typography from "@/components/baseComponents/Typography";
import IconWrapper from "@/components/baseComponents/IconWrapper";
import { Button } from "@/components/baseComponents/Button";
import ScheduleDrawer from "@/components/baseComponents/ScheduleDrawer";

// Utils
import { getFormattedDateString } from "../utils";
import { format, parse } from "date-fns";

// Assets
import DefaultProfilePicture from "@/assets/images/default_profile_picture.webp";
import DefaultCompanyLogo from "@/assets/images/default_company_logo.webp";

// Other components
import ListingDrawerFooter from "@/pages/myListings/common/ListingDrawerFooter";

// React Router
import { Link } from "react-router-dom";

// React Skeleton
import Skeleton from "react-loading-skeleton";

// Type for the main component props
type IProps = {
  // Array of message objects
  messages: Array<IMessageDto>;

  // Participant object
  participant: IParticipantDto;

  // Optional listing related to the chat
  chatListing?: IMyListing;

  // Optional function to close the drawer
  closeDrawer?: () => void;

  // Optional boolean to check if it's for header
  isForHeader?: boolean;

  // Optional function to go back
  goBack?: () => void;

  // Optional loading state
  loading?: boolean;
};

// Type for message item properties
type IMessageItemProps = {
  // Single message object
  message: IMessageDto;

  // Participant object
  participant: IParticipantDto;
};

const MessageItem = (props: IMessageItemProps) => {
  // Destructure props
  const { message, participant } = props;

  // Destructure message object
  const { sender, content, createdAt } = message;

  // Destructure participant object
  const { avatarURL, name } = participant;

  // Get the current user from the Redux store
  const user = useAppSelector(getUser);

  // Check if the current user is the sender of the message
  const isSelf = user._id === sender;

  // Format the creation date of the message
  const createdAtStr = getFormattedDateString(createdAt);

  // Parse the formatted date string
  const parsedDate = parse(createdAtStr, "MMM d, hh:mm a", new Date());

  // useEffect(() => {
  //   console.log([parsedDate, props.message]);
  // }, []);

  return (
    <div className="flex flex-col gap-6">
      {!isSelf ? (
        <div className={"flex w-full items-start gap-1 "}>
          <img
            onError={(e) => (e.currentTarget.src = DefaultProfilePicture)}
            src={
              avatarURL && avatarURL !== "" ? avatarURL : DefaultProfilePicture
            }
            className="w-6 h-6 rounded-full"
            alt="avatar"
          />
          <div className="w-fit">
            <div className="flex gap-1">
              <Typography
                variant="caption"
                color="primary"
                className="truncate font-semibold"
              >
                {name}
              </Typography>

              <Typography variant="caption" className=" text-secondary">
                {!isNaN(parsedDate.getTime())
                  ? format(parsedDate, "hh:mm a")
                  : ""}
              </Typography>
            </div>
            <div className="max-w-[600px] w-fit ">
              <Typography
                variant="body"
                color="primary"
                className="w-auto break-words bg-gray-100 p-4 rounded-[20px] rounded-tl-none"
              >
                {content}
              </Typography>
            </div>
          </div>
        </div>
      ) : (
        <div className="flex justify-end w-full">
          <div className="max-w-[600px] w-fit">
            <Typography
              variant="body"
              color="primary"
              className="w-auto break-words bg-indigo-100 p-4 rounded-[20px] rounded-br-none"
            >
              {content}
            </Typography>
            <div className="flex gap-1 justify-end items-center">
              {/* to be change with actual isRead logic */}
              {message.isRead ? (
                <IconWrapper name="recieved" width={16} innerStroke="#4C42D7" />
              ) : (
                <IconWrapper
                  name="single-check"
                  width={16}
                  innerStroke="#4C42D7"
                />
              )}

              <Typography variant="caption" className=" text-secondary">
                {!isNaN(parsedDate.getTime())
                  ? format(parsedDate, "hh:mm a")
                  : ""}
              </Typography>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

const ChatMessageArea = (props: IProps) => {
  // State for schedule visibility
  const [openSchedule, setOpenSchedule] = useState<boolean>(false);

  // State for chat listing existence
  const [hasChatListing] = useState(false);

  // State for form data
  const [date, setDate] = useState<Date>(new Date());

  // Destructure props
  const { messages, participant } = props;

  // Redux dispatch
  const dispatch = useAppDispatch();

  // Get the current user from the Redux store
  const user = useAppSelector(getUser);

  // Destructure participant object
  const { avatarURL, name } = participant;

  // Get the participant ID from the Redux store
  const participantId = useAppSelector(getParticipantId);

  // Sort messages by creation date
  messages?.sort(
    (a, b) =>
      new Date(b.createdAt ?? "").getTime() -
      new Date(a.createdAt ?? "").getTime()
  );

  // Reverse the order of messages
  const reversedMessage = messages ? [...messages].reverse() : [];

  // Reference for the scrollbar
  const scrollRef = useRef<Scrollbars>(null);

  // State for the current number of messages
  const [currentNumberOfMessages, setCurrentNumberOfMessages] =
    useState<number>(reversedMessage?.length);

  // Get the owner from the chat listing props
  const owner = (props.chatListing as any)?.owner;

  // Check if the current user is the owner
  const isSelf = owner ? owner._id === user._id : false;

  // Function to handle scroll event
  const handleScroll = (e: any) => {
    const { scrollTop } = e.target;
    if (scrollTop === 0) {
      if (reversedMessage?.length > 0) {
        const data: GetMessagesDto = {
          userId: user._id,
          participantId: participantId ?? "",
          beforeAt: reversedMessage[0].createdAt,
        };

        // Dispatch fetch messages action
        dispatch(fetchMessages(data));
      }
    }
  };

  // Function to scroll to the bottom of the chat
  const scrollToBottom = () => {
    setTimeout(() => {
      if (scrollRef?.current) {
        scrollRef.current.scrollTop(9999);
        setCurrentNumberOfMessages(messages?.length);
      }
    }, 1000);
  };

  // Effect to scroll to the bottom when the number of messages changes
  useEffect(() => {
    if (currentNumberOfMessages !== messages?.length) {
      scrollToBottom();
    }
  }, [reversedMessage]);

  // Effect to scroll to the bottom on component mount
  useEffect(() => {
    scrollToBottom();
  }, []);

  // Function to open the scheduler
  const openScheduler = () => {
    setOpenSchedule(true);
  };

  // Function to get the status color based on the status
  const getStatusColor = (status: string | undefined) => {
    return status === "Active"
      ? "bg-[#DCFCE7] !text-[#16A34A]"
      : status === "Pending"
        ? "bg-orange-100 text-orange-500"
        : status === "Closed"
          ? "bg-gray-100 shadow-sm"
          : "bg-gray-50 text-[#9CA3AF] shadow-sm";
  };

  // const dateSent = parse(createdAtStr, "MMM d, hh:mm a", new Date());

  const getFormattedDateString = (dateString: any) => {
    // Assume this function converts a date string to a proper format if needed
    return new Date(dateString);
  };

  const isSameDay = (date1: any, date2: any) => {
    return (
      date1.getFullYear() === date2.getFullYear() &&
      date1.getMonth() === date2.getMonth() &&
      date1.getDate() === date2.getDate()
    );
  };

  const getDateLabel = (date: any) => {
    const today = new Date();
    today.setHours(0, 0, 0, 0); // Normalize today's date

    if (isSameDay(date, today)) {
      return "Today";
    }

    const weekday = date.toLocaleDateString("en-US", { weekday: "long" });

    // Return date in the format day/month/year
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-indexed
    const year = date.getFullYear();
    return `${weekday} ${day}/${month}/${year}`;
  };

  return (
    <>
      {openSchedule && (
        <div className="!bg-[#00000040] h-screen w-full fixed top-0 left-0 z-40"></div>
      )}
      <ScheduleDrawer
        data={props.chatListing}
        open={openSchedule}
        changeState={setOpenSchedule}
        date={{ date, setDate }}
        footer={
          <div className="py-5">
            <ListingDrawerFooter
              data={props.chatListing?._id ? owner?.agent : user.agent}
              isSelf={isSelf}
              ownerId={owner?._id}
            />
          </div>
        }
      />

      <div className="lg:flex-1 h-full bg-[#FCFCFD]">
        {/* Message Area header */}
        <div className="sticky top-0 w-full border-b bg-white border-border flex justify-between items-center">
          {props.loading ? (
            <>
              <div className="flex flex-row gap-3 px-3 py-2">
                <Skeleton circle className="w-10 h-10" />
                <div className="flex flex-col gap-1 w-full justify-center">
                  <Skeleton className="w-[250px] h-4" />
                  <Skeleton className="w-[100px] h-4" />
                </div>
              </div>
              <div className="flex flex-row gap-3 px-3 py-2">
                <Skeleton circle className="w-10 h-10" />
                <Skeleton circle className="w-10 h-10" />
              </div>
            </>
          ) : (
            <>
              <div className="flex items-center gap-[10px] p-4 ">
                <div className="flex items-center gap-1 max-sm:mr-4">
                  {props.closeDrawer && (
                    <button
                      onClick={() => {
                        if (props.closeDrawer) {
                          props.closeDrawer();
                        }
                      }}
                    >
                      <IconWrapper name="arrow-backward" />
                    </button>
                  )}
                  {props.goBack && (
                    <button
                      onClick={() => {
                        if (props.goBack) {
                          props.goBack();
                        }
                      }}
                    >
                      <IconWrapper name="arrow-backward" />
                    </button>
                  )}
                  <img
                    onError={(e) => (e.currentTarget.src = DefaultProfilePicture)}
                    src={
                      avatarURL && avatarURL !== ""
                        ? avatarURL
                        : DefaultProfilePicture
                    }
                    className="w-9 aspect-square rounded-full"
                    alt="avatar"
                  />
                </div>
                <div className="flex flex-col pl-[10px]">
                  <Typography
                    variant="body"
                    color="primary"
                    className="font-bold line-clamp-1"
                  >
                    {name}
                  </Typography>
                  <div className="flex gap-1">
                    {/* needs to be changed to use actual data */}
                    <Typography
                      variant="body"
                      color="primary"
                      className="whitespace-nowrap"
                    >
                      {props.participant.licenseType}{" "}
                      {participant.companyName && "at"}
                    </Typography>
                    <div className=" flex items-center gap-1">
                      {props.participant.companyLogo && (
                        <img
                          src={props.participant.companyLogo}
                          onError={(e) =>
                            (e.currentTarget.src = DefaultCompanyLogo)
                          }
                          className="w-[16px] rounded-[4px] aspect-square"
                          alt="company-logo"
                        />
                      )}
                      <Typography
                        variant="body"
                        color="primary"
                        className="line-clamp-1  max-w-[148px]"
                      >
                        {props.participant.companyName}
                      </Typography>
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex items-center gap-2">
                <Link to={`tel:${participant.phoneNumber}`}>
                  <div className=" bg-gray-100 h-fit p-2.5 rounded-full">
                    <IconWrapper name="telephone" width={16} height={16} />
                  </div>
                </Link>
                <Link to={`mailto:${participant.email}`}>
                  <div className=" bg-gray-100 h-fit p-2.5 rounded-full">
                    <IconWrapper name="envelope" width={16} height={16} />
                  </div>
                </Link>
                <div className="cursor-pointer h-fit p-2.5 rounded-full">
                  <IconWrapper name="more-vertical" width={16} height={16} />
                </div>
              </div>
            </>
          )}
        </div>

        {/* Chat listing */}
        {hasChatListing && (
          <div className=" flex items-center bg-white justify-between max-lg:flex-col max-lg:items-start  flex-wrap gap-3 p-4 max-md:mx-2 md:p-5 shadow-sm m-4 rounded-8">
            <div className="flex gap-2">
              <img
                src={
                  props.chatListing?.propertyPhotos &&
                  props.chatListing?.propertyPhotos[0]?.file
                }
                alt=""
                className="w-[64px] rounded-8 aspect-square"
              />
              <div className="flex items-start gap-1">
                <div>
                  <Typography variant="h3" color="primary">
                    {props.chatListing?.listingAddress1}
                  </Typography>
                  <Typography color="primary">
                    {props.chatListing?.listingCity +
                      ", " +
                      props.chatListing?.listingState +
                      " " +
                      props.chatListing?.listingZipcode}
                  </Typography>
                </div>
                <Typography
                  variant="body"
                  className={`px-2 rounded-md ${getStatusColor(
                    props.chatListing?.status
                  )}`}
                >
                  {props.chatListing?.status}
                </Typography>
              </div>
            </div>
            <div className="max-lg:w-full">
              <Button
                size="small"
                color="primary"
                onClick={() => openScheduler()}
                className="max-lg:w-full"
              >
                <Typography variant="button2">Schedule a Showing</Typography>
              </Button>
            </div>
          </div>
        )}

        <Scrollbars
          ref={scrollRef}
          className="!h-5/6"
          onScroll={handleScroll}
          autoHide
        >
          <div className="flex flex-col gap-2 p-4">
            {participant._id !== "" &&
              reversedMessage &&
              reversedMessage.length > 0 &&
              reversedMessage.map((message, index) => {
                const messageDate = getFormattedDateString(message.createdAt);

                let showDateLabel = true;
                if (index > 0) {
                  const previousMessageDate = getFormattedDateString(
                    reversedMessage[index - 1].createdAt
                  );
                  if (isSameDay(messageDate, previousMessageDate)) {
                    showDateLabel = false;
                  }
                }

                return (
                  props.loading ? (
                    <Skeleton className="w-[130px] h-10" />
                  ) : (
                    <React.Fragment key={index}>
                      {showDateLabel && (
                        <div className="flex justify-center">
                          <Typography className="p-2 py-1 bg-gray-100 w-fit rounded-md text-secondary text-[14px]">
                            {getDateLabel(messageDate)}
                          </Typography>
                        </div>
                      )}
                      {message.content !== "" && (
                        <MessageItem
                          message={message}
                          participant={participant}
                        />
                      )}
                    </React.Fragment>
                  )
                );
              })}
          </div>
        </Scrollbars>
      </div>
    </>
  );
};

export default ChatMessageArea;
