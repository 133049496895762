import React, { useLayoutEffect, useRef, useEffect, useState } from "react";
import { useAppSelector } from "@/redux/hooks";
import MyAgentInfo from "./MyAgentInfo";
import MyVendorInfo from "./MyVendorInfo";
import TeamInfo from "./TeamInfo";
import Credentials from "./Credentials";
import Settings from "./Setting";
import Verify from "./Verify";
import Typography from "@/components/baseComponents/Typography";
import { useLocation, useNavigate, useOutletContext } from "react-router-dom";
import { getUser } from "@/redux/user/userSlice";
import { Button } from "@/components/baseComponents/Button";
// import UserIcon from "@/assets/images/user.svg";
import { MdOutlineKeyboardArrowDown } from "react-icons/md";
import IconWrapper from "@/components/baseComponents/IconWrapper";
import { IconType } from "@/components/baseComponents/IconWrapper/Icons";
import { getUnixTime } from "date-fns";

interface ITab {
  name: string;
  value: string;
  description: string;
  icon: IconType;
  isTopExitButtonExist: boolean;
  title: string;
  // minWidth:number
}
let tabs: ITab[] = [
  {
    name: "My Info",
    value: "info",
    icon: "profile-user",
    title: "Profile",
    description: "Update your profile and professional details.",
    isTopExitButtonExist: true
  },
  {
    name: "Company/Team Info",
    value: "company",
    icon: "company-team",
    title: "Company",
    description: "Add the company or brokerage that you are associated with.",
    isTopExitButtonExist: true
  },
  {
    name: "Credentials",
    value: "credentials",
    icon: "credential",
    title: "Credentials",
    description:
      "Manage the email and password to access RealtyView and receive notifications.",
    isTopExitButtonExist: false
  },
  {
    name: "Get Verified",
    value: "verify",
    icon: "verified",
    title: "Get Verified",
    description: "Upgrade and unlock many new features.",
    isTopExitButtonExist: false
  },
  {
    name: "Settings",
    value: "settings",
    icon: "setting",
    title: "Settings",
    description:
      "Manage your times preferences.",
    isTopExitButtonExist: true
  },
];

function classNames(...classes: any) {
  return classes.filter(Boolean).join(" ");
}
const Profile = () => {
  const user = useAppSelector(getUser);
  const navigate = useNavigate();
  const location = useLocation();
  const [currentTab, setCurrentTab] = React.useState<string>("My Info");
  const [title, setTitle] = React.useState<string>("Profile");
  const [description, setDescription] = React.useState<string>(
    "Update your profile and professional details."
  );
  const [topButton, setTopButton] = React.useState<boolean>(true);
  const [showMoreTabs, setShowMoreTabs] = React.useState<boolean>(false);
  const [dimensions, setDimensions] = React.useState({ width: 0, height: 0 });
  const targetRef = useRef<HTMLDivElement>(null);

  const [unSavedChange, setUnSavedChange] = React.useState<boolean>(false);

  //submit click states for the components
  const [settingSubmit, setSettingSubmit] = React.useState<boolean>(false);
  const [teamInfoSubmit, setTeamInfoSubmit] = React.useState<boolean>(false);
  const [profileInfoSubmit, setProfileInfoSubmit] = React.useState<boolean>(false);
  const [revertChanges, setRevertChanges] = useState<number>(0);
  const outletData = useOutletContext() as Array<any>;
  const isOpen = outletData[1] as boolean;

  React.useEffect(() => {
    const currentTab = tabs.find(tab => tab.value === location.pathname.split("/")[2]);
    changeTab(currentTab ?? tabs[0]);
  }, [location.pathname])

  const changeTab = (tab: any) => {
    setCurrentTab(tab.name);
    setTitle(tab.title);
    setDescription(tab.description);
    setTopButton(tab.isTopExitButtonExist);
    navigate("/profile/" + tab.value);
  };

  const updateDimensions = () => {
    if (targetRef.current) {
      const { width, height } = targetRef.current.getBoundingClientRect();
      setDimensions({ width, height });
    }
  };

  useEffect(() => {
    setTimeout(() => {
      updateDimensions();
    }, 500);
  }, [isOpen]);

  useLayoutEffect(() => {
    updateDimensions();
    const handleResize = () => {
      updateDimensions();
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleChanges = (change: boolean) => {
    setUnSavedChange(change);
  };

  const handleSubmitClick = () => {
    switch (currentTab) {
      case "My Info":
        setProfileInfoSubmit(true);
        break;
      case "Company/Team Info":
        setTeamInfoSubmit(true);
        break;
      case "Settings":
        setSettingSubmit(true);
        break;
    }
  };

  const handleSubmitChanges = () => {
    setSettingSubmit(false);
    setUnSavedChange(false);
    setTeamInfoSubmit(false);
    setProfileInfoSubmit(false)
  }

  return (
    <div className="max-w-screen-xl mx-auto pb-[25vh]" ref={targetRef}>
      <div className="md:p-10 p-5">
        <div className="flex xl:pb-10 pb-4 sm:my-0 my-2 justify-between w-full xl:hidden">
          <div className="flex flex-col">
            <Typography variant="h1" color="primary">{title}</Typography>
            <Typography className="text-sm" color="primary">{description}</Typography>
          </div>
          {
            topButton && (
              <div className="flex gap-3 py-2 hidden md:block md:flex">
                <Button color="inherit" className="border-1 border-[#D4D4D8]" onClick={() => setRevertChanges(getUnixTime(new Date()))}>Cancel</Button>
                <Button color="primary" onClick={() => { if (unSavedChange) { handleSubmitClick() } }}>Save Changes</Button>
              </div>
            )
          }
        </div>
        <div className="flex flex-col xl:flex-row xl:gap-10">
          <div className="flex xl:flex-col xl:py-4 py-6 xl:px-3 lg:pr-3 lg:pl-0 px-0 xl:max-w-[250px] xl:min-w-[250px] gap-y-4">
            <div className="flex xl:flex-col sticky top-[50px] gap-4">
              <div className="flex flex-col hidden xl:block xl:flex gap-1 py-2">
                <Typography variant="h1" color="primary" className="font-arial">{title}</Typography>
                <Typography className="text-sm font-arial" color="primary" >{description}</Typography>
              </div>
              {
                tabs.map((tab, index: number) =>
                  <div
                    key={index}
                    className={classNames(
                      `flex cursor-pointer p-3 gap-3 rounded-xl hover:bg-gray-100 items-center
                                            ${tab.name === currentTab ? "bg-[#E5E7EB]" : ""}
                                            ${(index > 1 && (dimensions.width < 665)) ? "hidden" : ""}
                                            ${((dimensions.width > 665)) ? " md:block md:flex" : ""}
                                            ${(index == 1 && dimensions.width < 437) ? "hidden" : ""}
                                            `
                    )}
                    onClick={() => { changeTab(tab); setShowMoreTabs(false); }}
                  >
                    <IconWrapper name={tab.icon} stroke="#1F2937" />
                    <Typography className="text-sm" color="primary">{tab.name}</Typography>
                  </div>
                )
              }
              {
                topButton && (
                  <div className="flex flex-col w-full gap-4 pb-1 hidden xl:inline hidden">
                    <Button color="inherit" className="w-full border-1 border-[#D4D4D8] mb-4" onClick={() => setRevertChanges(getUnixTime(new Date()))}>Cancel</Button>
                    <Button
                      className={`w-full mb-4  text-white ${unSavedChange ? "bg-button-primary" : "bg-indigo-400"}`}
                      variant="text"
                      onClick={() => {
                        if (unSavedChange) { handleSubmitClick() }
                      }}
                    >
                      Save Changes
                    </Button>
                    <div className="flex w-full justify-center mb-2">
                      {
                        unSavedChange && (
                          <Typography className="text-sm flex justify-center w-full text-center" color="primary">You have unsaved changes ✨</Typography>
                        )
                      }
                    </div>
                  </div>
                )
              }
            </div>
            <div className={`relative flex-shrink-0 ml-auto ${(dimensions.width < 665) ? "" : "hidden"}`}>
              <Button color="inherit" className="flex items-center"
                onClick={() => setShowMoreTabs(!showMoreTabs)}
              >
                More
                <MdOutlineKeyboardArrowDown className="text-primary text-18 ml-5 sm:ml-1 max-sm:ml-1" />
              </Button>
              <div className={` z-10 absolute right-0 mt-2 py-2 w-48 rounded-xl mt-1 bg-white  ${!showMoreTabs ? "hidden" : ""}`}>
                {
                  tabs.map((tab, index: number) =>
                    <div
                      key={index}
                      className={`flex cursor-pointer p-3 gap-3 rounded-xl
                                                ${tab.name === currentTab ? "bg-[#E5E7EB]" : ""}
                                                ${index == 0 ? "hidden" : ""}
                                                ${(index == 1 && dimensions.width > 437) ? "hidden" : ""}
                                                `}
                      onClick={() => { changeTab(tab); setShowMoreTabs(false); }}
                    >
                      <IconWrapper name={`${tab.icon}`} />
                      <Typography className="text-sm" color="primary">{tab.name}</Typography>
                    </div>
                  )
                }
              </div>
            </div>
          </div>
          <div className="flex flex-col w-full">
            {currentTab === "My Info" && (user.isVendor ? <MyVendorInfo /> :
              <MyAgentInfo
                changeSaved={handleSubmitChanges}
                thereIsChange={handleChanges}
                revertChanges={revertChanges}
                onSubmitClick={profileInfoSubmit}
                width={dimensions.width}
              />
            )}
            {currentTab === "Company/Team Info" &&
              <TeamInfo
                changeSaved={handleSubmitChanges}
                thereIsChange={handleChanges}
                revertChanges={revertChanges}
                onSubmitClick={teamInfoSubmit}
                isTeamDetailsHidden={true}
              />
            }
            {currentTab === "Credentials" && <Credentials />}
            {currentTab === "Settings" &&
              <Settings
                changeSaved={handleSubmitChanges}
                thereIsChange={handleChanges}
                revertChanges={revertChanges}
                onSubmitClick={settingSubmit}
              />
            }
            {currentTab === "Get Verified" && <Verify />}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Profile;
