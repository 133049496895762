type Props = {
  width: number;
  height: number;
  stroke?: string;
  className?: string;
};

const IconMessageSend = (props: Props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 20 20"
      fill="none"
      {...props}
    >
      <path
        d="M2.5 2.5L5 10L2.5 17.5L18.3333 10L2.5 2.5Z"
        stroke="#A855F7"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5 10H18.3333"
        stroke="#A855F7"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default IconMessageSend;
