import { myBucket } from "@/shared/services/s3Bucket";
import { IAddress, IMessageDto } from "../interfaces/interfaces";

/**
 * Returns 3 string array items by search result, 2nd item is for quried data
 * @param text
 * @param query
 * @returns `[string, string, string]`
 */
export const splitTextByQuery = (
  text: string,
  query: string
): [string, string, string] => {
  const start_index = text.toLowerCase().search(query.toLowerCase());
  const end_index = start_index + query.length;

  if (start_index < 0) {
    return [text, "", ""];
  }

  return [
    text.substring(0, start_index),
    text.substring(start_index, end_index),
    text.substring(end_index),
  ];
};

export const fileUpload = async (
  file: any,
  directoryName: string,
  isPdfPreview?: boolean,
  isBlob?: boolean
) => {
  if (file === "") return;
  const timestamp = new Date().getTime();
  let newFile: any = null;
  if (isBlob) {
    newFile = file;
  } else {
    newFile = new File([file], timestamp + "_" + file.name);
  }
  const fileParams: any = {
    ACL: "bucket-owner-full-control",
    Body: newFile,
    Bucket: import.meta.env.VITE_BUCKET_NAME,
    Key: directoryName + "/" + newFile.name
  };


  if (isPdfPreview) {
    fileParams.ContentType = "application/pdf";
    fileParams.Metadata = {
      "Content-Disposition": "inline",
    };
  }

  const data = await myBucket.upload(fileParams).promise();
  return data.Location;
};

export const formatAddress = (
  address: IAddress | undefined,
  isFull: boolean = true
) => {
  let result = "";
  if (!address) {
    return result;
  }
  if (isFull) {
    if (address.streetLine) {
      result += address.streetLine + " ";
    }
    if (address.secondary) {
      result += address.secondary + " ";
    }
  }
  if (address.city) {
    result += address.city + ", ";
  }
  if (address.state) {
    result += address.state + " ";
  }
  if (address.zipcode) {
    result += address.zipcode;
  }
  return result;
};

export const getGreeting = (user: any) => {
  const isVendor = user.isVendor;
  const now = new Date();
  const hours = now.getHours();
  const name =
    ", " +
    (isVendor
      ? (user.vendor?.prefix ? user.vendor?.prefix + ". " : "") +
      user.vendor?.firstName
      : (user.agent?.prefix ? user.agent?.prefix + ". " : "") +
      user.agent?.firstName);
  let greeting = "";
  if (hours >= 5 && hours < 12) {
    greeting = "Good Morning" + name + " 🌞";
  } else if (hours >= 12 && hours < 17) {
    greeting = "Good Afternoon" + name + " ☀️";
  } else {
    greeting = "Good Evening" + name + " 🌙";
  }

  return greeting;
};

export const mergeMessages = (
  originList: Array<IMessageDto>,
  addedList: Array<IMessageDto>
) => {
  const mergedList = [...originList, ...addedList];
  const uniqueList = mergedList.filter(
    (item, index, self) => index === self.findIndex((p) => p._id === item._id)
  );

  return uniqueList;
};

export const updateMessages = (
  originList: Array<IMessageDto>,
  updatedList: Array<IMessageDto>
) => {
  if (originList.length > 0 && updatedList.length > 0) {
    const result = originList.map((oItem) => {
      let item = oItem;
      updatedList.map((uItem) => {
        if (oItem._id === uItem._id) {
          item = {
            ...uItem,
          };
        }
      });
      return item;
    });
    return result;
  }
  return [];
};