import Typography from "../Typography"

type SwitchProps = {
    value: string | number | boolean | any;
    checked: boolean;
    label: string;
    className?: string;
    onChange: (event: any) => void;
}

const Switch = ({ value, checked, label, className, onChange }: SwitchProps) => {

    let _className = "flex items-center gap-2 cursor-pointer";
    if (className) _className = `${_className} ${className}`;

    return (
        <label className={_className}>
            <input
                value={value}
                name="isPublic"
                type="checkbox"
                className="sr-only peer"
                checked={checked}
                onChange={onChange}
            />
            <div className="relative w-9 h-5 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-4 after:w-4 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
            <Typography variant="button1" color="primary" className="flex items-center gap-2">
                {label}
            </Typography>
        </label>
    )
}

export default Switch; 