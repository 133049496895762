import Typography from "@/components/baseComponents/Typography";
import { Button } from "@/components/baseComponents/Button";
import IconWrapper from "@/components/baseComponents/IconWrapper";
import { useState } from "react";

interface Props {
  bedrooms: number,
  bathrooms: number,
  type: string,
  price: number,
  onSearch: Function,
}
const PropertyFilter = ({
  bedrooms,
  bathrooms,
  type,
  price,
  onSearch,
}: Props) => {
  const [searchValue, setSearchValue] = useState<string>("");
  return (
    <>
      <div className="lg:flex hidden my-6 w-full py-6 px-8 bg-white items-center justify-around">
        <div className="flex flex-col">
          <Typography
            variant="h1"
            color="black"
            className="whitespace-nowrap text-[16px] font-medium font-jakarta opacity-50"
          >
            location
          </Typography>
          <input
            type="search"
            name="search"
            id=""
            className={`w-full py-3 px-4 placeholder-black !bg-white border-none focus:border-none rounded-lg  text-[18px] font-bold font-jakarta`}
            placeholder={"Search a location"}
            value={searchValue}
            onChange={(e) => setSearchValue(e.target.value)}
          />
        </div>
        <div className="flex h-11 w-[1px] bg-[#E0DEF7]"></div>
        <div className="flex flex-col gap-1">
          <Typography
            variant="h1"
            color="black"
            className="whitespace-nowrap text-[16px] font-medium font-jakarta opacity-50"
          >
            Price up to
          </Typography>
          <div className="flex gap-10">
            <Typography
              variant="h1"
              color="black"
              className="whitespace-nowrap text-[18px] font-bold font-jakarta text-[#000929]"
            >
              ${price?.toLocaleString()}
            </Typography>
            <IconWrapper name="down-circular" width={24} height={24} />
          </div>
        </div>
        <div className="flex h-11 w-[1px] bg-[#E0DEF7]"></div>
        <div className="flex flex-col gap-1">
          <Typography
            variant="h1"
            color="black"
            className="whitespace-nowrap text-[16px] font-medium font-jakarta opacity-50 md:block hidden"
          >
            Beds
          </Typography>
          <div className="flex gap-6">
            <Typography
              variant="h1"
              color="black"
              className="whitespace-nowrap text-[18px] font-bold font-jakarta text-[#000929]"
            >
              {bedrooms}
            </Typography>
            <IconWrapper name="down-circular" width={24} height={24} />
          </div>
        </div>
        <div className="flex h-11 w-[1px] bg-[#E0DEF7]"></div>
        <div className="flex flex-col gap-1">
          <Typography
            variant="h1"
            color="black"
            className="whitespace-nowrap text-[16px] font-medium font-jakarta opacity-50 md:inline hidden"
          >
            Baths
          </Typography>
          <div className="flex gap-6">
            <Typography
              variant="h1"
              color="black"
              className="whitespace-nowrap text-[18px] font-bold font-jakarta text-[#000929]"
            >
              {bathrooms}
            </Typography>
            <IconWrapper name="down-circular" width={24} height={24} />
          </div>
        </div>
        <div className="flex h-11 w-[1px] bg-[#E0DEF7]"></div>
        <div className="flex flex-col gap-1">
          <Typography
            variant="h1"
            color="black"
            className="whitespace-nowrap text-[16px] font-medium font-jakarta opacity-50"
          >
            Property Type
          </Typography>
          <div className="flex gap-10">
            <Typography
              variant="h1"
              color="black"
              className="whitespace-nowrap text-[18px] font-bold font-jakarta text-[#000929]"
            >
              {type}
            </Typography>
            <IconWrapper name="down-circular" width={24} height={24} />
          </div>
        </div>
        <div className="flex h-11 w-[1px] bg-[#E0DEF7]"></div>
        <Button
          className={`py-4 px-8  text-white  bg-[#7065F0] font-jakarta font-bold text-[16px]`}
          variant="text"
          onClick={() => onSearch()}
        >
          Search
        </Button>
      </div>
    </>
  );
};

export default PropertyFilter;
