// Define the type for the component props
type Props = {
    width: number;
    height: number;
    stroke?: string;
    className?: string;
};

// Define the IconPanelRightOpenAlt component
const IconPanelRightOpenAlt = (props: Props) => {
    // Return the SVG for the icon
    return (
        <svg viewBox="0 0 14 10" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path d="M13 9H8" stroke="#878A99" strokeWidth="1.5" strokeLinecap="round" />
            <path d="M13 5L8 5" stroke="#878A99" strokeWidth="1.5" strokeLinecap="round" />
            <path d="M13 1L8 1" stroke="#878A99" strokeWidth="1.5" strokeLinecap="round" />
            <path d="M0.210935 2.14179L3.27728 4.99943L0.210935 7.85708C0.144096 7.919 0.0910463 7.99267 0.0548429 8.07385C0.0186396 8.15502 0 8.24208 0 8.33002C0 8.41795 0.0186396 8.50502 0.0548429 8.58619C0.0910463 8.66737 0.144096 8.74104 0.210935 8.80296C0.277227 8.8654 0.356097 8.91495 0.442996 8.94877C0.529894 8.98259 0.623101 9 0.717239 9C0.811377 9 0.904584 8.98259 0.991482 8.94877C1.07838 8.91495 1.15725 8.8654 1.22354 8.80296L4.78906 5.47237C4.8559 5.41045 4.90895 5.33678 4.94516 5.2556C4.98136 5.17443 5 5.08737 5 4.99943C5 4.9115 4.98136 4.82443 4.94516 4.74326C4.90895 4.66209 4.8559 4.58841 4.78906 4.52649L1.22354 1.1959C1.15705 1.13379 1.07812 1.08453 0.99125 1.05091C0.904378 1.0173 0.811268 1 0.717239 1C0.527338 1 0.345215 1.07047 0.210935 1.1959C0.0766544 1.32133 0.00121689 1.49145 0.00121689 1.66884C0.00121689 1.84623 0.0766544 2.01635 0.210935 2.14179Z" fill="#888A96" />
        </svg>

    );
};

// Export the IconPanelRightOpenAlt component
export default IconPanelRightOpenAlt;