// React and third-party libraries
import React, { Fragment } from "react";
import { toast } from "react-toastify";
import { Dialog, Menu, Transition } from "@headlessui/react";
import { AiOutlineUndo } from "react-icons/ai";
import { MdOutlineKeyboardArrowDown } from "react-icons/md";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { useLocation } from "react-router-dom";

// Redux related imports
import { useAppSelector, useAppDispatch } from "@/redux/hooks";
import { getUser } from "@/redux/user/userSlice";
import {
  getMyListings,
  getMyListingsByUserId,
  deleteMyListings,
  setListingStatus,
  favoriteMyListing,
  updateMyListing,
  unarchiveMyListings,
} from "@/redux/myListings/myListingSlice";

// Services
import { notify } from "@/shared/services/notify";

// Interfaces
import {
  DeleteMyListingsDto,
  FavoriteMyListingsDto,
  GetMyListingsDto,
  IMyListing,
  SetListingStatusDto,
  UpdateMyListingDto,
} from "@/shared/interfaces/interfaces";

// Components
import Typography from "@/components/baseComponents/Typography";
import { Button } from "@/components/baseComponents/Button";
import IconWrapper from "@/components/baseComponents/IconWrapper";
import AdvancedTable from "@/components/baseComponents/AdvancedTable";
import Filter from "@/components/mainComponents/Filter";
import Sort from "@/components/mainComponents/Sort";
import Drawer from "./Drawer";
import View from "./View";

// Assets
import DefaultListingImage from "@/assets/images/default_listing_image.webp";
import Logo from "@/assets/images/logo_main.svg";

// Tabs configuration
let tabs = [
  { name: "All" },
  { name: "Active" },
  { name: "Pending" },
  { name: "Closed" },
  { name: "Withdrawn" },
  { name: "Draft" },
  { name: "Archived" },
];

// Table fields configuration
const TableFields = [
  {
    name: "",
    type: "address_image",
    slug: "photo",
    imageClass: "w-[4rem]",
    class_name: "text-left pl-[16px] min-w-[300px] !py-[20px]",
  },
  {
    name: "Status",
    type: "status_menu",
    slug: "status",
    class_name: "px-2 px-[16px] text-left !py-[20px] min-w-[100px] pl-[40px]",
  },
  {
    name: "Client",
    type: "text",
    slug: "client_name",
    class_name: "px-2 px-[16px] text-left !py-[20px] min-w-[100px] pl-[40px]",
  },
  {
    name: "Showings",
    type: "showing",
    slug: "showings",
    class_name: "px-2 px-[16px] text-left !py-[20px] min-w-[100px] pl-[40px]",
  },
  { name: "", type: "action", slug: "action", class_name: "" },
];

// Mobile table fields configuration
const MobileTableFields = [
  {
    name: "",
    type: "listing_image",
    slug: {
      imageSlug: "photo",
      statusSlug: "status",
      clientSlug: "client_name",
      showingSlug: "showings",
      addressSlug: "address",
    },
    imageClass: "w-[4rem]",
    class_name: "text-left pl-[16px] !py-[20px] min-w-[300px]",
  },
];

// Table sort options
const TableSortOptions = [
  { value: "Address", label: "Address" },
  { value: "Status", label: "Status" },
  { value: "Client", label: "Client" },
];

// Next task options
const NextTaskOptions = ["Marketing", "Task 1", "Task 2", "Task 3"];

const MyListings = () => {

  const location = useLocation();
  const locationState = location.state;
  const dispatch = useAppDispatch();
  const myListings = useAppSelector(getMyListings);
  const user = useAppSelector(getUser);
  const [currentTab, setCurrentTab] = React.useState<string>("All");
  const [open, setOpen] = React.useState<boolean>(false);
  const [openView, setOpenView] = React.useState<boolean>(false);

  const [openConfirm, setOpenConfirm] = React.useState<{
    action?: string;
    state: boolean;
  }>({ action: "archive", state: false });
  const [viewData, setViewdata] = React.useState<any>(null);
  const [filteredMyListings, setFilteredMyListings] = React.useState<Array<any>>([]);
  const [editData, setEditData] = React.useState<any>(null);
  const [selectedPeople, setSelectedPeople] = React.useState<Array<any>>([]);
  const [keyword, setKeyword] = React.useState<string>("");
  const [sortType, setSortType] = React.useState<string>("Descending");
  const [sortField, setSortField] = React.useState<string>("Address");
  const [totalCount, setTotalCount] = React.useState<number>(0);
  const [currentPage, setCurrentPage] = React.useState<number>(1);
  const [recordsPerPage, setRecordsPerPage] = React.useState<number>(25);
  const [selectedListing, setSelectedListing] = React.useState<any>({});
  const [saveAsDraft, setSaveAsDraft] = React.useState<boolean>(false);
  const [welcomeMessageOpen, setWelcomeMessageOpen] = React.useState<boolean>(false);

  const onSetPage = (value: number) => {
    setCurrentPage(value);
  };

  const openDrawer = () => {
    let tempEditData: any = {};
    setEditData(tempEditData);
    setOpen(true);
  };

  const closeDrawer = () => {
    let tempEditData: any = {};
    setEditData(tempEditData);
    setOpen(false);
  };

  const onClickRow = (value: any) => {
    setOpenView(true);
    setViewdata(value);
  };

  const editMyListing = (value: any) => {
    setEditData(value);
    setOpen(true);
  };

  const handleSaveAsDraft = () => {
    setSaveAsDraft(true);
    setOpenConfirm({ state: false });
  };

  const onSetMyListingAsFavorite = (id: any, isFavorite: number) => {
    const data: FavoriteMyListingsDto = {
      id: id,
      isFavorite: isFavorite,
      userId: user._id,
      search: {
        userId: user._id,
        keyword: keyword,
        sortType: sortType,
        sortField: sortField,
        recordsPerPage: recordsPerPage,
        currentPage: currentPage,
        status: currentTab,
      },
    };

    dispatch(favoriteMyListing(data)).then((res) => {
      try {
        setViewdata(
          res.payload.listings.find((listing: any) => listing._id === id)
        );
        setTotalCount(res.payload.totalCount);
      } catch (e) {
        notify(
          false,
          "Something went wrong while setting your listing as favorite. Please contact your support."
        );
      }
    });
  };

  const deleteConfirm = () => {
    let archivingToast = toast.loading("Archiving your listing");

    let ids: Array<string> = [];
    if (selectedPeople.length === 0 && selectedListing) {
      ids = [selectedListing._id];
    } else {
      ids = selectedPeople.map((lead: any) => {
        return lead._id;
      });
    }

    const data: DeleteMyListingsDto = {
      ids: ids,
      userId: user._id,
      search: {
        userId: user._id,
        keyword: keyword,
        sortType: sortType,
        sortField: sortField,
        recordsPerPage: recordsPerPage,
        currentPage: currentPage,
        status: currentTab,
      },
    };

    dispatch(deleteMyListings(data)).then((res) => {
      try {
        setTotalCount(res.payload.totalCount);
        toast.update(archivingToast, {
          render: res.payload.message,
          type: "success",
          isLoading: false,
          autoClose: 3000,
        });
      } catch (e) {
        toast.update(archivingToast, {
          render:
            "Something went wrong while archiving your listing. Please try again.",
          type: "error",
          isLoading: false,
          autoClose: 3000,
        });
      }
    });
  };

  const unarchiveConfirm = () => {
    let archivingToast = toast.loading("Unarchiving your listing...");

    let ids: Array<string> = [];
    if (selectedPeople.length === 0 && selectedListing) {
      ids = [selectedListing._id];
    } else {
      ids = selectedPeople.map((lead: any) => {
        return lead._id;
      });
    }

    const data: DeleteMyListingsDto = {
      ids: ids,
      userId: user._id,
      search: {
        userId: user._id,
        keyword: keyword,
        sortType: sortType,
        sortField: sortField,
        recordsPerPage: recordsPerPage,
        currentPage: currentPage,
        status: currentTab,
      },
    };

    dispatch(unarchiveMyListings(data)).then((res) => {
      try {
        setTotalCount(res.payload.totalCount);
        toast.update(archivingToast, {
          render: res.payload.message,
          type: "success",
          isLoading: false,
          autoClose: 3000,
        });
      } catch (e) {
        toast.update(archivingToast, {
          render:
            "Something went wrong while unarchiving your listing. Please try again.",
          type: "error",
          isLoading: false,
          autoClose: 3000,
        });
      }
    });
  };

  const changeKeyword = (keyword: string) => {
    setKeyword(keyword);
  };

  const changeSortType = (value: string) => {
    setSortType(value);
  };

  const changeSortField = (option: any) => {
    setSortField(option.label);
  };

  const filterListings = () => {
    const data: GetMyListingsDto = {
      userId: user._id,
      keyword: keyword,
      sortType: sortType,
      sortField: sortField,
      recordsPerPage: recordsPerPage,
      currentPage: currentPage,
      status: currentTab,
    };

    dispatch(getMyListingsByUserId(data)).then((res: any) => {
      try {
        setTotalCount(res.payload.totalCount);
      } catch (e) {
        notify(false, "Something went wrong.");
      }
    });
  };

  const archive = async (item: any) => {
    setSelectedListing(item);
    setOpenConfirm({ action: "archive", state: true });
    setOpenView(false);
  };

  const unarchive = async (item: any) => {
    setSelectedListing(item);
    setOpenConfirm({ action: "unarchive", state: true });
    setOpenView(false);
  };

  const onUpdateNextTask = (listing: IMyListing, newNextTask: string) => {
    let loadingToast = toast.loading("Updating next task.");
    let tempListing = { ...listing };
    tempListing.nextTask = newNextTask;

    let updateData: UpdateMyListingDto = {
      data: tempListing,
      listingId: listing._id || "",
      userId: user._id,
      search: {
        userId: user._id,
        keyword: keyword || "",
        sortType: sortType || "",
        sortField: sortField || "",
        recordsPerPage: recordsPerPage || 25,
        currentPage: currentPage || 1,
        status: currentTab,
      },
    };
    dispatch(updateMyListing(updateData)).then((res) => {
      try {
        setTotalCount(res.payload.totalCount);
        toast.update(loadingToast, {
          render: res.payload.message,
          type: "success",
          progress: 0,
          isLoading: false,
          autoClose: 3000,
        });
      } catch (e) {
        toast.update(loadingToast, {
          render: "There was an error updating your listing. Please try again.",
          type: "error",
          isLoading: false,
          autoClose: 3000,
        });
      }
    });
  };

  const renderNextTaskMenu = (listing: IMyListing, currentNextTask: string) => {
    return (
      <div className="relative">
        <Menu as="div">
          <div>
            <Menu.Button className="flex justify-between items-center w-24">
              <Typography variant="table-row-content" color="primary">
                {currentNextTask}
              </Typography>
              <MdOutlineKeyboardArrowDown />
            </Menu.Button>
          </div>
          <Transition
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Menu.Items className="z-20 absolute right-[115px] -top-10 w-[6rem] origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg">
              {NextTaskOptions.map((item: string) => (
                <Menu.Item key={item}>
                  <div
                    onClick={() => onUpdateNextTask(listing, item)}
                    className={`pl-3 pr-8 py-1 flex gap-3 items-center hover:bg-gray-100 first:rounded-t-md last:rounded-b-md cursor-pointer${
                      currentNextTask === item && ` bg-gray-100`
                    }`}
                  >
                    <Typography
                      variant="table-row-content"
                      color="primary"
                      className="whitespace-nowrap"
                    >
                      {item}
                    </Typography>
                  </div>
                </Menu.Item>
              ))}
            </Menu.Items>
          </Transition>
        </Menu>
      </div>
    );
  };

  const makeTableData = (myListings: Array<object>) => {
    let res: Array<object> = [];
    if (myListings && myListings.length > 0) {
      myListings.forEach((myListing: any) => {
        const updatedMyListing = {
          ...myListing,
          photo:
            (myListing.propertyPhotos && myListing.propertyPhotos[0]?.file) ||
            DefaultListingImage,
          client_name: myListing.client && (
            <div className=" flex flex-col">
              <span className="text-primary">
                {myListing.client?.firstName + " " + myListing.client?.lastName}
              </span>
              <span className="text-gray-500">
                {myListing.client?.leadType || ""}
              </span>
            </div>
          ),
          showings: (
            <div>
              <Typography variant="table-row-content" color="primary">
                # {myListing?.showings} Showings
              </Typography>
            </div>
          ),
          nextTask: renderNextTaskMenu(myListing, myListing.nextTask),
          action: [
            {
              name: "Edit",
              color: "black",
              icon: <IconWrapper name="edit" width={16} />,
            },
            ,
          ],
        };

        if (myListing)
          if (myListing?.listingAddress.city) {
            // temporary, to be phased out since we broke down the address by parts 12-20-2023
            updatedMyListing.address = (
              <div>
                {myListing.isPublic === 1 && (
                  <Typography>
                    {myListing?.listingAddress?.streetLine +
                      " " +
                      (myListing?.listingUnit || myListing?.listingAddress?.secondary)}
                  </Typography>
                )}
                <Typography
                  className={
                    myListing.isPublic === 1 ? `text-gray-400` : `text-primary`
                  }
                >
                  {myListing?.listingAddress?.city +
                    ", " +
                    myListing?.listingAddress?.state +
                    " " +
                    myListing?.listingAddress?.zipcode}
                </Typography>
              </div>
            );
          } else {
            updatedMyListing.address = (
              <div className="!line-clamp-2 !whitespace-normal">
                Address Kept Private
              </div>
            );
          }

        if (myListing.isArchived) {
          updatedMyListing.action.push({
            name: "Unarchive",
            color: "#6DA172",
            icon: <AiOutlineUndo className="text-14 text-[#6DA172] mt-[3px]" />,
          });
        } else {
          updatedMyListing.action.push({
            name: "Archive",
            color: "#DC2626",
            icon: <IconWrapper name="archive" width={16} />,
          });
        }

        res.push(updatedMyListing);
      });
    }
    return res;
  };

  const setStatus = (item: any, status: string) => {
    const data: SetListingStatusDto = {
      listingId: item._id,
      listingStatus: status,
      search: {
        userId: user._id,
        keyword: keyword,
        sortType: sortType,
        sortField: sortField,
        recordsPerPage: recordsPerPage,
        currentPage: currentPage,
        status: currentTab,
      },
    };
    dispatch(setListingStatus(data)).then((res: any) => {
      try {
        setTotalCount(res.payload.totalCount);
        notify(res.payload.success, res.payload.message);
      } catch (e) {
        notify(false, "Something went wrong.");
      }
    });
  };

  React.useEffect(() => {
    setRecordsPerPage(25);

    const data: GetMyListingsDto = {
      userId: user._id,
      keyword: keyword,
      sortType: sortType,
      sortField: sortField,
      recordsPerPage: recordsPerPage,
      currentPage: currentPage,
      status: currentTab,
    };

    dispatch(getMyListingsByUserId(data)).then((res: any) => {
      try {
        setTotalCount(res.payload.totalCount);
      } catch (e) {
        notify(false, "Something went wrong.");
      }
    });
  }, [currentTab, currentPage]);

  React.useEffect(() => {
    const tableData = makeTableData(myListings);
    setFilteredMyListings(tableData);
    setSelectedPeople([]);

    if (locationState && locationState.id) {
      setEditData(myListings.find(listing => listing._id === locationState.id));
      setOpen(true)
    }

    if (locationState && locationState.fromInvitation) {
      setWelcomeMessageOpen(true);
    }
  }, [myListings, locationState]);

  return (
    <div>
      {(open || openView) && (
        <div className="!bg-[#00000040] h-screen w-full fixed top-0 left-0 z-20"></div>
      )}

      <Drawer
        open={open}
        closeDrawer={closeDrawer}
        changeState={setOpen}
        setOpenConfirm={setOpenConfirm}
        data={editData}
        keyword={keyword}
        sortType={sortType}
        sortField={sortField}
        currentPage={currentPage}
        recordsPerPage={recordsPerPage}
        setTotalCount={setTotalCount}
        setCurrentPage={setCurrentPage}
        currentTab={currentTab}
        saveAsDraft={saveAsDraft}
        setSaveAsDraft={setSaveAsDraft}
      />

      <View
        open={openView}
        changeState={setOpenView}
        data={viewData}
        setAsFavorite={onSetMyListingAsFavorite}
        onEdit={editMyListing}
        onArchive={archive}
        search={
          {
            userId: user._id,
            keyword: keyword,
            sortType: sortType,
            sortField: sortField,
            recordsPerPage: recordsPerPage,
            currentPage: currentPage,
            status: currentTab,
          } as GetMyListingsDto
        }
      />

      <Dialog
        open={openConfirm.state}
        onClose={() => setOpenConfirm({ state: false })}
        className="fixed inset-0 z-50"
      >
        <div className="bg-[#00000040] fixed inset-0 flex w-screen items-center justify-center ">
          <Dialog.Panel className="w-full max-w-[350px] md:max-w-sm rounded-8 bg-white shadow-xl ">
            <div className="rounded-lg w-full bg-white p-4 md:px-6 py-4 gap-4 flex flex-col">
              <div className="flex justify-between w-full">
                <div className="w-full flex flex-col justify-start gap-2">
                  <Typography variant="h3">Discard Listing?</Typography>
                  <Typography variant="body">
                    Are you sure you want to {openConfirm.action}{" "}
                    {selectedPeople.length > 1
                      ? "these listings"
                      : "this listing"}
                    ?
                  </Typography>
                </div>

                <div className="flex w-fit text-secondary hover:text-primary cursor-pointer">
                  <XMarkIcon
                    className="h-6 w-6"
                    aria-hidden="true"
                    onClick={() => setOpenConfirm({ state: false })}
                  />
                </div>
              </div>

              <div className="flex  justify-between items-center ">
                <div
                  className={`${
                    openConfirm.action === "unarchive"
                      ? "bg-[#B5E2C4] text-[#6DA172]"
                      : "text-red-600"
                  }  py-1 rounded-[8px] flex items-center justify-center cursor-pointer`}
                  onClick={() => {
                    if (openConfirm.action === "archive") {
                      deleteConfirm();
                    } else if (openConfirm.action === "unarchive") {
                      unarchiveConfirm();
                    } else {
                      setOpen(false);
                    }

                    setOpenConfirm({ state: false });
                  }}
                >
                  <span className="capitalize body button2">
                    {openConfirm.action} Listing
                  </span>
                </div>

                <div className="flex items-center gap-2">
                  <div
                    className={`${
                      openConfirm.action === "unarchive"
                        ? "border-1 border-[#B5E2C4]"
                        : ""
                    }  px-4 py-1 rounded-8 flex items-center justify-center cursor-pointer border border-border`}
                    onClick={() => setOpenConfirm({ state: false })}
                  >
                    <span className="text-primary body button2">
                      {openConfirm.action === "unarchive"
                        ? "Keep Archived"
                        : "Continue"}
                    </span>
                  </div>

                  {openConfirm.action === "discard" && (
                    <div
                      className="border bg-indigo-600 px-4 py-1 rounded-8 flex items-center justify-center cursor-pointer"
                      onClick={() => handleSaveAsDraft()}
                    >
                      <span className="text-white button2">Save as Draft</span>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </Dialog.Panel>
        </div>
      </Dialog>

      <div className="px-6 pt-10 pb-[30vh]">
        <div className="flex flex-col gap-6">
          <div className="flex justify-between items-center">
            <div className="flex items-center md:gap-3">
              <Typography
                variant="h2"
                color="primary"
                className="whitespace-nowrap"
              >
                My Listings
              </Typography>
              <Menu>
                <div className="relative w-full">
                  <Menu.Button className="relative w-fit flex gap-1 cursor-default rounded-full text-left">
                    <div className="cursor-pointer text-[#9CA3AF] flex items-center px-[8px] py-[4px] gap-[4px] w-fit">
                      <Typography
                        variant="body"
                        className="text-[#6B7280] leading-[100%] w-max"
                      >
                        {currentTab === "All" ? "All Status" : currentTab}
                      </Typography>
                      <IconWrapper
                        name="arrow-up"
                        width={16}
                        innerStroke="#6B7280"
                        className=" rotate-90"
                      />
                    </div>
                  </Menu.Button>

                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <Menu.Items className="z-10 absolute top- origin-top-right left-0  min-h-60 w-fit overflow-hidden min-w-[160px] bg-white rounded-8 text-base shadow-lg">
                      {tabs.map((tab: any) => (
                        <Menu.Item as={Fragment} key={tab.name}>
                          {({ active }) => (
                            <div
                              key={tab.name}
                              className="flex flex-col items-start "
                            >
                              <button
                                className={` p-3 w-full text-start text-14 text-primary ${
                                  active ? "bg-[#F3F4F6]" : "bg-white"
                                }`}
                                onClick={() => {
                                  setCurrentTab(tab.name);
                                }}
                              >
                                {tab.name}
                              </button>
                            </div>
                          )}
                        </Menu.Item>
                      ))}
                    </Menu.Items>
                  </Transition>
                </div>
              </Menu>
            </div>

            <div className="w-fit flex md:gap-3">
              <div className="relative flex items-center md:gap-3 gap-1 max-sm:flex-wrap">
                <div className="flex items-center gap-2 md:gap-5">
                  <Filter
                    changeKeyword={changeKeyword}
                    keyword={keyword}
                    filterLeads={filterListings}
                  />
                  <Sort
                    sortType={sortType}
                    sortField={sortField}
                    changeSortField={changeSortField}
                    changeSortType={changeSortType}
                    filterLeads={filterListings}
                    options={TableSortOptions}
                  />
                </div>
              </div>
              <div className="hidden md:block">
                <Button onClick={() => openDrawer()}>
                  <Typography variant="button1" className="whitespace-nowrap">
                    Add Listings
                  </Typography>
                </Button>
              </div>
            </div>
          </div>
          <div className="block md:hidden">
            <Button onClick={() => openDrawer()} className="w-full">
              <Typography variant="button1" className="whitespace-nowrap">
                Add Listings
              </Typography>
            </Button>
          </div>
        </div>

        <div className="max-sm:pt-3 pt-8">
          {welcomeMessageOpen && (
            <div className="shadow relative flex gap-4 items-start w-full h-fit p-[16px] bg-black bg-opacity-50 bg-shadow-sm rounded-lg mb-5">
              <img src={Logo} className="w-[4rem] mt-1" />
              <div className="flex flex-col w-full">
                <div className="flex justify-between items-center">
                  <Typography variant="h3" color="white">
                    Welcome to RealtyView!
                  </Typography>
                  <Button variant="icon" onClick={() => setWelcomeMessageOpen(false)}>
                    <IconWrapper name="close" className="[&>svg>path]:!stroke-white" />
                  </Button>
                </div>
                <Typography variant="body" color="white">
                  Confirm the status of your listing to generate your showing and offer links
                </Typography>

              </div>
            </div>
          )}

          <AdvancedTable
            class_name="custom-table table items-center"
            data={filteredMyListings}
            fields={TableFields}
            mobileFields={MobileTableFields}
            userID={user._id}
            onClickRow={onClickRow}
            setStatus={setStatus}
            totalPage={Math.ceil(totalCount / recordsPerPage)}
            totalCount={totalCount}
            currentPage={currentPage}
            recordsPerpage={recordsPerPage}
            onSetPage={onSetPage}
            editRow={editMyListing}
            deleteRow={archive}
            unarchiveRow={unarchive}
            emptyStateProps={{
              heading: "Let's add your listings",
              content:
                "After you add your listings, you’ll unlock ultimate listing management and productivity.",
              buttonText: "Add a Listing",
              onButtonClick: () => openDrawer(),
            }}
            minCellWidth={100}
          />
        </div>
      </div>
    </div>
  );
};

export default MyListings;
