// Define the type for the component props
type Props = {
    width: number;
    height: number;
    stroke?: string;
    className?: string;
};

// Define the IconCamera component
const IconCamera = (props: Props) => {
    // Return the SVG for the icon
    return (
        <svg viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <g id="camera_16">
                <path id="Vector" d="M10.1667 2.66797H6.83337L5.16671 4.66797H3.16671C2.81309 4.66797 2.47395 4.80844 2.2239 5.05849C1.97385 5.30854 1.83337 5.64768 1.83337 6.0013V12.0013C1.83337 12.3549 1.97385 12.6941 2.2239 12.9441C2.47395 13.1942 2.81309 13.3346 3.16671 13.3346H13.8334C14.187 13.3346 14.5261 13.1942 14.7762 12.9441C15.0262 12.6941 15.1667 12.3549 15.1667 12.0013V6.0013C15.1667 5.64768 15.0262 5.30854 14.7762 5.05849C14.5261 4.80844 14.187 4.66797 13.8334 4.66797H11.8334L10.1667 2.66797Z" stroke="#1F2937" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
                <path id="Vector_2" d="M8.5 10.668C9.60457 10.668 10.5 9.77254 10.5 8.66797C10.5 7.5634 9.60457 6.66797 8.5 6.66797C7.39543 6.66797 6.5 7.5634 6.5 8.66797C6.5 9.77254 7.39543 10.668 8.5 10.668Z" stroke="#1F2937" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
            </g>
        </svg>
    );
};

// Export the IconCamera component
export default IconCamera;