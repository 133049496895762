import LandingPopular from "@/assets/images/landing_popular.png";
import IconWrapper from "@/components/baseComponents/IconWrapper";
import { IMyListing } from "@/shared/interfaces/interfaces";
import LandingPropertyCarousel from "./LandingPropertyCarousel";

interface Props {
  isPopular?: boolean;
  price?: string;
  priceExtra?: string;
  address?: string;
  addressDetail?: string;
  likeButton?: boolean;
  listing?: IMyListing;
  isSelected: (listing?: IMyListing) => void;
}

const ListingCard = ({
  isPopular = false,
  listing,
  priceExtra = "",
  likeButton = false,
  isSelected,
}: Props) => {
  return (
    <div className={`flex w-full ${isPopular ? "pl-2" : ""}`}>
      <div className="flex flex-col w-full bg-white rounded-lg cursor-pointer">
        <div className="flex xl:min-w-[343px] w-full min-w-[300px] rounded-tr-lg rounded-tl-lg min-h-[215px] ">
          <div className="xl:min-w-[343px] w-full min-w-[300px] rounded-tr-lg rounded-tl-lg h-[215px] ">
            <LandingPropertyCarousel
              slidesToShow={1}
              isCenter={true}
              onClick={()=>{
                isSelected(listing);
              }}
              data={listing?.propertyPhotos}
              className="xl:min-w-[343px] w-full min-w-[300px] rounded-tr-lg rounded-tl-lg h-[215px] "
            />
          </div>
        </div>
        <div
          onClick={() => {
            isSelected(listing);
          }}
        >
          {isPopular && (
            <div className="relative">
              <div className="absolute -left-2 -top-4">
                <img src={LandingPopular} alt="" />
              </div>
            </div>
          )}
          <div className={`flex flex-col py-4 px-6`}>
            {likeButton && (
              <div className="relative">
                <div className="absolute right-0 top-0">
                  <div className="flex justify-center items-center rounded-full p-3 border-[#E8E6F9] border-1">
                    <IconWrapper name="heart" width={24} height={24} />
                  </div>
                </div>
              </div>
            )}
            <div className="flex flex-col justify-between w-full h-[204px]">
              <div className="flex flex-col w-full">
                <div className="text-[#7065F0] font-jakarta text-[24px]">
                  <span className="font-extrabold">
                    ${listing?.listingListPrice?.toLocaleString()}
                  </span>
                  {priceExtra.length > 0 && (
                    <span className="text-[16px] text-[#000929] opacity-50 font-medium">
                      {priceExtra}
                    </span>
                  )}
                </div>
                <div className="flex font-jakarta font-bold text-[24px] text-[#000929] cursor-pointer">
                  {listing?.listingAddress.streetLine +
                    " " +
                    listing?.listingAddress.secondary}
                </div>
                <div className="flex mt-2 font-jakarta font-medium text-[16px] text-[#000929] opacity-50">
                  {listing?.listingAddress.city +
                    ", " +
                    listing?.listingAddress.state +
                    " " +
                    listing?.listingAddress.zipcode}
                </div>
              </div>
              <div className="flex flex-col w-full">
                <div className="flex w-full h-0.5 bg-[#F0EFFB] mt-[22px] mb-4"></div>
                <div className="flex w-full gap-4">
                  <div className="flex gap-2 text-3.5 font-jakarta font-medium text-[#6C727F]">
                    <IconWrapper width={20} name="bed-new" />
                    {listing?.propertyBedrooms}{" "}
                    <span className="md:inline hidden">Beds</span>
                  </div>
                  <div className="flex gap-2 text-3.5 font-jakarta font-medium text-[#6C727F]">
                    <IconWrapper width={20} name="bath-new" stroke="#7065F0" />
                    {listing?.propertyBathrooms}{" "}
                    <span className="md:inline hidden">Baths</span>
                  </div>
                  {listing?.propertyLotSqft &&
                    listing?.propertyLotSqft.length > 0 && (
                      <div className="flex gap-2 text-3.5 font-jakarta font-medium text-[#6C727F]">
                        <IconWrapper width={20} name="house-space-new" />
                        {listing?.propertyLotSqft} SqFt
                      </div>
                    )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ListingCard;
