import { useState } from "react";
import Typography from "@/components/baseComponents/Typography";
import { LuEye } from "react-icons/lu";
import { LuEyeOff } from "react-icons/lu";
import { Button } from "@/components/baseComponents/Button";
import Logo from "@/assets/images/google_logo.svg";
import { Link, useNavigate } from "react-router-dom";
import MainLogo from "@/assets/images/logo_black.svg";
import MLogo from "@/assets/images/logon.png";
import TopImg from "@/assets/images/landing-login.png";
import CenterPropertyImg from "@/assets/images/landing-property.png";
import validation from "@/shared/services/validation";

const LandingRegister = () => {
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [errorEmail, setErrorEmail] = useState<boolean>(false);
  const [errorMessagePassword, setErrorMessagePassword] = useState<string>("");
  const navigate = useNavigate();

  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");

  const changeEmail = (value: string) => {
    setEmail(value);
    setErrorEmail(validation.IsInvalidEmail(value));
  };

  const changePassword = (value: string) => {
    setPassword(value);
    if (!validation.emptyPassword(value)) {
      setErrorMessagePassword("This field is required");
      return true;
    }
    if (!validation.containsNumber(value)) {
      setErrorMessagePassword("Should contain at least a number");
      return true;
    }
    if (!validation.containsUppercase(value)) {
      setErrorMessagePassword("Should contain at least a uppercase character");
      return true;
    }
    if (!validation.containsSpecial(value)) {
      setErrorMessagePassword("Should contain at least a special character");
      return true;
    }
    if (!validation.minLength(value)) {
      setErrorMessagePassword("Should be at least 8 characters");
      return true;
    }
    setErrorMessagePassword("");
    return false;
  };

  return (
    <>
      <div className="flex flex-col min-h-screen">
        <div className="flex h-full bg-[#F7F7FD]">
          <div className="flex flex-col lg:w-1/2 w-full h-full lg:px-0 px-6 bg-white">
            <div className="flex z-30 w-full bg-white lg:px-[60px] py-[30px] border-b-2 border-grey">
              <div className="flex w-full justify-between h-11 items-center">
                <Link to={"/landing"}>
                  <img src={MainLogo} alt="Logo" className=" h-9" />
                </Link>
              </div>
            </div>
            <div className="flex h-full justify-center items-center z-10 py-12">
              <div className="flex flex-col lg:w-[352px] md:w-[400px] w-full">
                <div className="flex flex-col gap-2">
                  <Typography
                    variant="h1"
                    color="black"
                    className="text-[32px] font-bold font-jakarta lg:text-start text-center"
                  >
                    Welcome
                  </Typography>
                  <Typography
                    variant="input-label"
                    className="text-[16px] text-gray-400 font-medium font-jakarta lg:text-start text-center"
                  >
                    Sign up to find your next home
                  </Typography>
                </div>
                <div className="flex mt-8">
                  <div className="flex items-center">
                    <label className="flex items-center gap-2">
                      <input
                        type="checkbox"
                        className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600 font-jakarta font-semibold"
                        // checked={rememberMyEmailAddress}
                        // onChange={() => setRememberMyEmailAddress(!rememberMyEmailAddress)}
                      />
                      <Typography
                        variant="caption"
                        className="text-black font-medium font-jakarta text-[14px]"
                      >
                        I am a property manager
                      </Typography>
                    </label>
                  </div>
                </div>
                <div className="flex flex-col my-8 gap-4">
                  <div className="relative">
                    <div className="flex flex-col gap-2 w-full">
                      <Typography
                        variant="caption"
                        className="text-[#000929] font-medium font-jakarta"
                      >
                        First Name
                      </Typography>
                      <input
                        type="firstName"
                        name="firstName"
                        id=""
                        className={`w-full text-[16px] font-jakarta py-3 px-4 !bg-[#F7F7FD] border-1 border-[#E0DEF7] rounded-lg`}
                        placeholder={"First Name"}
                        onChange={(e) => {
                          console.log(e);
                        }}
                      />
                    </div>
                  </div>

                  <div className="relative">
                    <div className="flex flex-col gap-2 w-full">
                      <Typography
                        variant="caption"
                        className="text-[#000929] font-medium text-[14px] font-jakarta"
                      >
                        Last Name
                      </Typography>
                      <input
                        type="LastName"
                        name="LastName"
                        id=""
                        className={`w-full text-[16px] font-jakarta py-3 px-4 !bg-[#F7F7FD] border-1 border-[#E0DEF7] rounded-lg`}
                        placeholder={"Last Name"}
                        onChange={(e) => {
                          console.log(e);
                        }}
                      />
                    </div>
                  </div>

                  <div className="relative">
                    <div className="flex flex-col gap-2 w-full">
                      <Typography
                        variant="caption"
                        className="text-[#000929] text-[14px] font-medium font-jakarta"
                      >
                        Email
                      </Typography>
                      <input
                        type="email"
                        name="email"
                        id=""
                        className={`w-full text-[16px] font-jakarta py-3 px-4 !bg-[#F7F7FD] border-1 border-[#E0DEF7] rounded-lg
                      ${
                        errorEmail
                          ? "outline outline-1 rounded outline-[#E01010]"
                          : ""
                      }
                      `}
                        placeholder={"hi@example.com"}
                        value={email}
                        onChange={(e) => changeEmail(e.target.value)}
                      />
                      <div className="relative">
                        {errorEmail && (
                          <Typography
                            variant="caption"
                            className="text-[#E01010] absolute text-[10px] -mt-2 font-jakarta"
                          >
                            Invalid Email Address
                          </Typography>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="relative">
                    <div className="col-span-1 relative">
                      <div className="flex flex-col gap-2 w-full">
                        <Typography
                          variant="caption"
                          className="text-[#000929] text-[14px] font-medium font-jakarta"
                        >
                          Password
                        </Typography>
                        <div className="relative w-full">
                          <input
                            name="email"
                            id=""
                            className={`w-full py-3 px-4 text-[16px] !bg-[#F7F7FD] border-1 border-[#E0DEF7] rounded-lg
                      ${
                        errorMessagePassword
                          ? "outline outline-1 rounded outline-[#E01010]"
                          : ""
                      }
                      `}
                            type={`${showPassword ? "text" : "password"}`}
                            value={password}
                            placeholder="Enter password"
                            onChange={(e) => changePassword(e.target.value)}
                          />

                          <button
                            type="button"
                            onClick={() => setShowPassword(!showPassword)}
                            className="absolute inset-y-0 end-0 flex items-center pe-3"
                          >
                            {!showPassword && <LuEye />}
                            {showPassword && <LuEyeOff />}
                          </button>
                        </div>
                        <div className="relative">
                          {errorMessagePassword !== "" && (
                            <Typography
                              variant="caption"
                              className="text-[#E01010] text-[10px] absolute -mt-2"
                            >
                              {errorMessagePassword}
                            </Typography>
                          )}
                        </div>
                        <div className="flex w-full justify-between">
                          <Typography
                            variant="caption"
                            className="text-[14px] cursor-pointer text-[#6C727F] font-jakarta"
                            onClick={() => {}}
                          >
                            Must be at least 8 characters.
                          </Typography>
                          <Typography
                            variant="caption"
                            className="text-[14px] text-[#7065F0] cursor-pointer hover:text-primary font-jakarta"
                            onClick={() => {}}
                          >
                            Forgot your password?
                          </Typography>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="flex flex-col w-ful gap-4">
                  <Button
                    className={`w-full !h-full py-3 text-white bg-[#7065F0] font-jakarta font-bold text-[16px]`}
                    variant="text"
                    onClick={() => {}}
                  >
                    Sign up
                  </Button>
                  <Button
                    color="inherit"
                    className="w-full !h-full border-1 border-[#D4D4D8] mb-4  py-3 flex justify-center items-center"
                  >
                    <div className="flex gap-2.5 font-jakarta font-bold text-[16px]">
                      <img src={Logo} alt="Logo" className="" />
                      Sign up with Google
                    </div>
                  </Button>
                </div>
                <div className="flex mt-4 w-full justify-center items-center">
                  <Typography
                    variant="input-label"
                    className=" text-[14px] text-[#6C727F] font-medium font-jakarta"
                  >
                    Already have an account?
                  </Typography>
                  <Typography
                    variant="h1"
                    color="black"
                    className="whitespace-nowrap text-[14px] font-bold font-jakarta cursor-pointer"
                    onClick={() => {
                      navigate("/landing/login");
                    }}
                  >
                    &nbsp; Login
                  </Typography>
                </div>
              </div>
            </div>
          </div>
          <div className="lg:block lg:absolute hidden top-0 right-0 w-1/2 h-full">
            <div className="relative">
              <div className="absolute">
                <img src={TopImg} alt="" />
              </div>
              <div className="absolute top-[90px] left-[10px]">
                <img src={CenterPropertyImg} alt="" />
              </div>
              <div className="absolute top-[690px] left-[90px]">
                <div className="flex flex-col gap-4 max-w-[450px]">
                  <Typography
                    variant="h1"
                    color="black"
                    className="flex items-center justify-start gap-2 text-[14px] font-medium font-jakarta"
                  >
                    Powered by <img src={MLogo} width={20} height={20} alt="" />
                  </Typography>
                  <Typography className="text-[12px] text-[#6C727F] font-medium font-jakarta leading-2">
                    You agree to RealtyView’s{" "}
                    <span className="text-[#7065F0] cursor-pointer">
                      Terms of Use & Privacy Policy.
                    </span>{" "}
                    You don't need to consent as a condition of renting any
                    property, or buying any other goods or services.
                    Message/data rates may apply.
                  </Typography>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default LandingRegister;
