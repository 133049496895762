type Props = {
    width: number;
    height: number;
    stroke?: string;
    className?: string;
};

const IconCheckCircle = (props: Props) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 17 16" fill="none" {...props}>
            <path
                d="M6.4987 7.9987L7.83203 9.33203L10.4987 6.66536M15.1654 7.9987C15.1654 11.6806 12.1806 14.6654 8.4987 14.6654C4.8168 14.6654 1.83203 11.6806 1.83203 7.9987C1.83203 4.3168 4.8168 1.33203 8.4987 1.33203C12.1806 1.33203 15.1654 4.3168 15.1654 7.9987Z"
                stroke={props.stroke ?? "#4F46E5"}
                strokeWidth="1.2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};

export default IconCheckCircle;
