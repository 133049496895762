import { IMyListing, MyListingPhoto } from "@/shared/interfaces/interfaces";
import Typography from "@/components/baseComponents/Typography";
import IconWrapper from "@/components/baseComponents/IconWrapper";
import { XMarkIcon } from "@heroicons/react/24/outline";
import {
  SortableContainer,
  SortableContainerProps,
  SortableElement,
  SortableElementProps,
} from "react-sortable-hoc";
import { useEffect, useState } from "react";

type IProps = {
  data?: IMyListing;
  onAddPhoto: Function;
  onRemovePhoto: Function;
  onChangePhotoOrder: Function;
  onDragChangePhotoOrder: Function;
};
const Photos = (props: IProps) => {
  const handleOndragOver = (event: any) => {
    event.preventDefault();
  };

  const SortableItem: React.ComponentClass<
    SortableElementProps & { value: any },
    any
  > = SortableElement(({ value }: { value: any }, index: number) => (
    <img
      key={index}
      src={value.isFile ? URL.createObjectURL(value.file) : value.file}
      className="aspect-square rounded-md transition duration-150 ease-in-out"
    />
  ));

  const SortableList: React.ComponentClass<
    SortableContainerProps & { items: MyListingPhoto[] },
    any
  > = SortableContainer(({ items }: { items: MyListingPhoto[] }) => {
    const [captions, setCaptions] = useState<string[]>([])

    const defineCaptions = () => {
      let captions: string[] = []
      items.forEach((ele) => {
        captions.push(ele.caption);
      })
      setCaptions(captions)
    }

    const handleCaptionChange = (e: any, index:number) => {
      const { value } = e.target;
      const tempValue = value ? value : '';
      setCaptions({ ...captions, [index]: tempValue })
      let newItem = items[index];
      newItem.caption = tempValue;
      // items[index] = newItem;
    }

    useEffect(() => {
      defineCaptions();
    }, [])

    return (
      <div className="grid max-sm:grid-cols-2 grid-cols-3 gap-[15px]">
        {items &&
          items.length > 0 &&
          items.map((photo: MyListingPhoto, index: number) => (
            <div className="flex flex-col gap-[1px] relative">
              <button
                className="absolute top-1 right-1 bg-white hover:bg-white rounded-full p-1"
                onClick={() => props.onRemovePhoto(index)}
              >
                <XMarkIcon
                  className="h-3 w-3 text-gray-500"
                  aria-hidden="true"
                />
              </button>
              <SortableItem key={`item-${index}`} index={index} value={photo} />
              <input
                type="text"
                value={captions[index]}
                placeholder="caption..."
                className="p-0 m-0 border-none text-12"
                onChange={(value) => {
                  handleCaptionChange(value,index)
                }}

              />
              {/* <div className="flex justify-between items-center">
                <Button
                  className="bg-transparent hover:bg-transparent"
                  onClick={() => props.onChangePhotoOrder(false, photo.order)}
                >
                  <IconWrapper name="arrow-backward" width={5} />
                </Button>
                <Typography variant="caption" color="secondary">
                  {photo.order} / {props.data?.propertyPhotos.length}
                </Typography>
                <Button
                  className="bg-transparent hover:bg-transparent"
                  onClick={() => props.onChangePhotoOrder(true, photo.order)}
                >
                  <IconWrapper name="arrow-forward" width={5} />
                </Button>
              </div> */}
            </div>
          ))}
      </div>
    );
  });

  const onSortEnd = ({ oldIndex, newIndex }: any) => {
    const photoBeingDragged = props.data?.propertyPhotos[oldIndex];
    const photoBeingSwitched = props.data?.propertyPhotos[newIndex];
    props.onDragChangePhotoOrder(
      photoBeingDragged.order,
      photoBeingSwitched.order
    );
  };

  return (
    <div className="my-[50px]">
      <div className="px-4 md:px-8">
        <Typography variant="h3" color="primary">
          Property Photos
        </Typography>
        <label
          htmlFor="propertyPhotos"
          className="flex flex-col items-center w-full bg-[#F1F5F9] rounded-md cursor-pointer mt-[25px] py-8 gap-6"
          onDragOver={(e) => handleOndragOver(e)}
          onDrop={(e) => props.onAddPhoto(e, true)}
        >
          <IconWrapper name="image" width={44} />

          <div className="text-center flex flex-col items-center">
            <Typography
              variant="body"
              className="text-center font-medium text-gray-500"
            >
              Drop photos here or click to{" "}
              <span className="font-bold">upload</span>
            </Typography>
            <Typography
              variant="medium-text"
              color="secondary"
              className="text-center w-[250px] mt-1"
            >
              Click the photo to add caption and change the order by dragging
              the photos. <br />
              Max. 100 MB
            </Typography>
            <div className="flex items-center gap-2 bg-white hover:bg-white border-1 border-border px-4 py-1 rounded-full mt-6">
              <Typography
                variant="body"
                color="primary"
                className="whitespace-nowrap"
              >
                Upload
              </Typography>
            </div>
          </div>
          <input
            type="file"
            accept="image/*"
            id="propertyPhotos"
            name="propertyPhotos"
            multiple
            hidden
            onChange={(event) => {
              props.onAddPhoto(event);
            }}
          />
        </label>

        <div className="mt-[25px]">
          <SortableList
            axis="xy"
            items={props.data?.propertyPhotos as MyListingPhoto[]}
            onSortEnd={onSortEnd}
            helperClass="z-30"
          />

          {/* <div className="flex items-center h-[120px]">
            <label
              htmlFor="propertyPhotos"
              className="flex justify-center items-center gap-2 bg-white hover:bg-white h-full w-full p-0 border border-2 cursor-pointer rounded-md"
            >
              <IconWrapper name="image" width={11} height={11} />
              <Typography
                variant="medium-text"
                color="primary"
                className="whitespace-nowrap"
              >
                Add Photo
              </Typography>
            </label>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default Photos;
