import Typography from "@/components/baseComponents/Typography"
import { Dialog, Transition } from "@headlessui/react"
import { Fragment } from "react"
import PropertyPhotoPlaceholder from "@/assets/images/listing_image_placeholder.png";
import IconWrapper from "@/components/baseComponents/IconWrapper";
import { XMarkIcon } from "@heroicons/react/24/outline";

interface IProps {
    open: boolean
    onOpenModal: Function
}

const DetailsModal = ({
    open,
    onOpenModal,
}: IProps) => {

    const StatusBadge = ({ status }: { status: string }) => {
        let statusBadgeClassnames = "bg-[#B5E2C4] text-[#6DA172]"

        if (status === "In Danger") {
            statusBadgeClassnames = "bg-[#CCD01C40] bg-opacity-25 text-[#3C3C3CA6]"
        } else if (status === "Off Track") {
            statusBadgeClassnames = "bg-[#C84156] bg-opacity-25 text-[#C84156]"
        }

        let statusBadge = <Typography variant="caption" className={`font-semibold uppercase  rounded-md px-2 py-1 ${statusBadgeClassnames}`}>{status}</Typography>

        return statusBadge
    }

    return (
        <Transition.Root show={open} as={Fragment}>
            <Dialog as="div" className="relative z-30" onClose={() => onOpenModal(false)}>
                <div className="fixed inset-0 flex items-center justify-center z-50">
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-150"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <Dialog.Panel className="rounded-lg max-w-[512px] w-full bg-white px-5 py-10 ">
                            <div className="flex flex-col gap-5">
                                <div className="flex justify-between">
                                    <Typography variant="h3" color="primary">⚠️ Task Due</Typography>
                                    <XMarkIcon className="h-5 w-5 text-gray-400 cursor-pointer" aria-hidden="true" onClick={() => onOpenModal(false)}/>
                                </div>
                                <div className="flex flex-col gap-2">
                                    <div className="grid grid-cols-5 items-center gap-3">
                                        <img src={PropertyPhotoPlaceholder} className="aspect-square rounded-md" />
                                        <Typography variant="body" color="primary" className="col-span-3">7520 Miami View Dr. North Bay Village, FL 33141</Typography>
                                    </div>
                                    <div className="flex items-center gap-1">
                                        <Typography variant="medium-text" color="primary">The status of Kerr to Minguez is now </Typography> <StatusBadge status="In Danger" />
                                    </div>
                                </div>
                                <div className="flex flex-col">
                                    <Typography variant="caption" color="primary" className="ml-[75%]">Due in 24 hours</Typography>
                                    <div className="relative w-full bg-gray-300 rounded-full h-1.5">
                                        <div className={`absolute bg-red-300 h-1.5 rounded-full`} style={{ width: "100%" }}></div>
                                        <div className={`absolute bg-yellow-200 h-1.5 rounded-full`} style={{ width: "75%" }}></div>
                                        <div className={`absolute bg-indigo-600 h-1.5 rounded-full`} style={{ width: "50%" }}></div>
                                    </div>
                                </div>
                                <div className="flex">
                                    <Typography variant="medium-text" color="primary" className="flex-1">Target Closing Date: Sept. 15, 2023</Typography>
                                    <Typography variant="medium-text" color="primary" className="flex-1">With Delay: Sept. 18, 2023</Typography>
                                </div>
                                <div className="flex items-center gap-1">
                                    <Typography variant="medium-text" color="primary">Please upload</Typography>
                                    <IconWrapper name="transaction-note" />
                                    <Typography variant="medium-text" color="secondary">Sales Agreement</Typography>
                                    <Typography variant="medium-text" color="primary" className="font-semibold">as soon as possible to prevent delays.</Typography>
                                </div>
                                <label className="flex flex-col items-center gap-5 border-dashed border-2 border-gray-200 rounded-md cursor-pointer p-5 mx-10">
                                    <IconWrapper name="upload-cloud" iconClassName="bg-indigo-500 rounded-full p-2" width={40} height={40} />
                                    <Typography variant="body" color="primary">Drag & drop files here or</Typography>
                                    <div className="flex gap-3 items-center bg-gray-100 rounded-md px-5 py-2">
                                        <IconWrapper name="upload" width={16} /> <Typography variant="body" color="primary">Browse files</Typography>
                                    </div>
                                    <input type="file" hidden />
                                </label>
                            </div>
                        </Dialog.Panel>
                    </Transition.Child>
                </div>
            </Dialog>
        </Transition.Root>
    )
}
export default DetailsModal;