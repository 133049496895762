import { Link, useNavigate } from "react-router-dom";
import Logo from "@/assets/images/logo_black.svg";
import IconWrapper from "@/components/baseComponents/IconWrapper";
import { Button } from "@/components/baseComponents/Button";
import { useState } from "react";

const Landingheader = () => {
  const navigate = useNavigate();
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  return (
    <div className="flex w-full bg-white md:px-[60px] px-6 py-[30px] border-b-2 border-grey">
      <div className="flex w-full justify-between h-11 items-center">
        <Link to={"/landing"}>
          <img src={Logo} alt="Logo" className=" h-9" />
        </Link>
        <div className="gap-4 flex items-center">
          <div className="flex" onClick={toggleMenu}>
            <IconWrapper
              name="humberger"
              className="md:hidden flex"
              width={24}
              height={24}
            />
          </div>
          {menuOpen && (
            <div className="absolute top-[60px] right-[20px] bg-white shadow-lg rounded-lg p-4 flex flex-col space-y-2 md:hidden z-30">
              <Button
                color="inherit"
                className="w-28 border-1 !h-full border-[#D4D4D8] py-3 justify-center items-center font-jakarta font-bold text-[16px]"
                onClick={() => {
                  navigate("/landing/login");
                  setMenuOpen(false);
                }}
              >
                Login
              </Button>
              <Button
                className="py-3 !h-full text-white w-28 bg-[#7065F0] font-jakarta font-bold text-[16px]"
                variant="text"
                onClick={() => {
                  navigate("/landing/register");
                  setMenuOpen(false);
                }}
              >
                Sign up
              </Button>
            </div>
          )}
          <Button
            color="inherit"
            className="w-28 border-1 !h-full border-[#D4D4D8] py-3 justify-center items-center font-jakarta font-bold text-[16px] hidden md:flex"
            onClick={() => {
              navigate("/landing/login");
            }}
          >
            Login
          </Button>
          <Button
            className={`py-3 !h-full text-white w-28 bg-[#7065F0] font-jakarta font-bold text-[16px] hidden md:flex`}
            variant="text"
            onClick={() => {
              navigate("/landing/register");
            }}
          >
            Sign up
          </Button>
        </div>
      </div>
    </div>
  );
};

export default Landingheader;
