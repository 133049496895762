import Typography from '@/components/baseComponents/Typography'
import TextField from "@/components/baseComponents/TextField"
import Select from '@/components/baseComponents/Select'

type ISelectFieldProps = {
  label: string;
  name: string;
  options: any;
  matchSetting: any;
  handleSelectField: Function;
}

const SelectField = (props: ISelectFieldProps) => {
  const { label, name, options, matchSetting, handleSelectField } = props
  const validOptions = options.filter((item: any) => {
    var valid = true
    Object.entries(matchSetting).map(([key, value]) => {
      if (item.value === value && key !== name) {
        valid = false
      }
      return
    })
    return valid
  })
  validOptions.unshift({label: '', value: ''})
  return (
    <div className='relative pt-4 pb-4'>
      <Typography variant='caption' className='text-secondary mb-4'>{label}</Typography>
      <div className='flex justify-between items-center'>
        <div className='w-[45%]'>
          <TextField className='mt-2 text-secondary' inputClassName='caption text-secondary' name={name} value={name} disabled />
        </div>
        <Typography variant='body' className='mt-2'>{'='}</Typography>
        <div className='w-[45%]'>
          <Select
            className='w-full mt-2'
            options={validOptions}
            value={validOptions?.filter((item: any) => item.value === matchSetting[name]).length > 0 ? validOptions.filter((item: any) => item.value === matchSetting[name])[0] : {label: '', value: ''}}
            name={name}
            onChange={(e) => handleSelectField(name, e.value)}
          />
        </div>
      </div>
    </div>
  )
}

type IProps = {
  formFields: Array<string>;
  matchSetting: any;
  handleMatchSetting: Function;
}

const MatchSetting = (props: IProps) => {
  const { formFields, matchSetting, handleMatchSetting } = props
  const fieldOptions = formFields?.map(field => {
    return {
      label: field,
      value: field
    }
  })
  const listingSetting = [
    {label: 'Listing Address1', name: 'listingAddress1'},
    {label: 'Listing Address2', name: 'listingAddress2'},
    {label: 'Listing City', name: 'listingCity'},
    {label: 'Listing State', name: 'listingState'},
    {label: 'Listing Zip', name: 'listingZip'},
  ]
  const buyersSetting = [
    {label: 'Primary Buyer First Name', name: 'primaryBuyerFirstName'},
    {label: 'Primary Buyer Last Name', name: 'primaryBuyerLastName'},
    {label: 'Primary Buyer Company Name', name: 'primaryBuyerCompanyName'},
    {label: 'Primary Buyer Phone Number', name: 'primaryBuyerPhoneNumber'},
    {label: 'Primary Buyer Email Address', name: 'primaryBuyerEmailAddress'},
    {label: 'Primary Buyer Current Address', name: 'primaryBuyerCurrentAddress'},
    {label: 'Primary Buyer Mailing Address', name: 'primaryBuyerMailingAddress'},
    {label: 'Secondary Buyer First Name', name: 'secondaryBuyerFirstName'},
    {label: 'Secondary Buyer Last Name', name: 'secondaryBuyerLastName'},
    {label: 'Secondary Buyer Phone Number', name: 'secondaryBuyerPhoneNumber'},
    {label: 'Secondary Buyer Email Address', name: 'secondaryBuyerEmailAddress'},
  ]
  const mortgageSetting = [
    {label: 'Mortgage Held By', name: 'mortgageHeldBy'},
    {label: 'Mortgage Type', name: 'mortgageType'},
    {label: 'Mortgage Period', name: 'mortgagePeriod'},
    {label: 'Payment Due Date', name: 'mortgagePaymentDueDate'},
    {label: 'Interest Rate', name: 'interestRate'},
    {label: 'Monthly Payment', name: 'monthlyPayment'},
    {label: 'Proposed Closing Date', name: 'mortgageProposedClosingDate'},
    {label: 'Proposed Formal Contact Date', name: 'mortgageProposedFormalContactDate'},
    {label: 'Mortgage Due (days from formal contract)', name: 'mortgageDays'},
  ]
  const loanOfficerSetting = [
    {label: 'Loan Officer First Name', name: 'loanFirstName'},
    {label: 'Loan Officer Last Name', name: 'loanLastName'},
    {label: 'Loan Officer Phone Number', name: 'loanPhoneNumber'},
    {label: 'Loan Officer Email Address', name: 'loanEmailAddress'},
    {label: 'Loan Officer Company', name: 'loanCompany'},
    {label: 'Loan Officer Company Address', name: 'loanCompanyAddress'},
  ]
  const termsSetting = [
    {label: 'Offer Amount($)', name: 'offerAmount'},
    {label: 'Earnest Money Deposit($)', name: 'earnestMoneyDeposit'},
    {label: 'Down Payment (Includes EDM)($)', name: 'downPayment'},
    {label: 'Subject to Mortgage($)', name: 'subjectToMortgage'},
    {label: 'Seller Concession($)', name: 'sellerConcession'},
    {label: 'Cash on Closing($)', name: 'cashOnClosing'},
    {label: 'Proposed CLosing Location', name: 'proposedClosingLocation'},
    {label: 'Proposed Closing Date', name: 'proposedClosingDate'},
    {label: 'Personal Property Inclusions', name: 'personalPropertyInclusions'},
    {label: 'Personal Property Exclusions', name: 'personalPropertyExclusions'},
    {label: 'Note To Listing Agent', name: 'noteToListingAgent'},
  ]
  const legalSetting = [
    {label: 'Buyer\'s Attorney First Name', name: 'buyerAttorneyFirstName'},
    {label: 'Buyer\'s Attorney Last Name', name: 'buyerAttorneyLastName'},
    {label: 'Buyer\'s Attorney Phone Number', name: 'buyerAttorneyPhoneNumber'},
    {label: 'Buyer\'s Attorney Email Address', name: 'buyerAttorneyEmailAddress'},
    {label: 'Buyer\'s Attorney Company', name: 'buyerAttorneyCompany'},
    {label: 'Buyer\'s Attorney Address', name: 'buyerAttorneyAddress'},
    {label: 'Seller\'s Attorney First Name', name: 'sellerAttorneyFirstName'},
    {label: 'Seller\'s Attorney Last Name', name: 'sellerAttorneyLastName'},
    {label: 'Seller\'s Attorney Phone Number', name: 'sellerAttorneyPhoneNumber'},
    {label: 'Seller\'s Attorney Email Address', name: 'sellerAttorneyEmailAddress'},
    {label: 'Seller\'s Attorney Company', name: 'sellerAttorneyCompany'},
    {label: 'Seller\'s Attorney Address', name: 'sellerAttorneyAddress'},
  ]
  return (
    <div className='mt-2'>
      <div className='pt-8 pb-4'>
        <div className='mt-4 mb-4'>
          <Typography variant='h3' className='text-primary'>General</Typography>
          <Typography variant='caption' className='text-secondary'>Select offer document to be generated </Typography>
        </div>
        <SelectField
          label='Date'
          name='offerDate'
          options={fieldOptions}
          matchSetting={matchSetting}
          handleSelectField={handleMatchSetting}
        />
      </div>
      <div className='pt-4 pb-4'>
        <div className='mt-4 mb-4'>
          <Typography variant='h3' className='text-primary'>Listing</Typography>
          <Typography variant='caption' className='text-secondary'>The subject listing that the offer is being made on.</Typography>
        </div>
        {listingSetting.map((listing: any) => {
          return (
            <SelectField
              key={listing.name}
              label={listing.label}
              name={listing.name}
              options={fieldOptions}
              matchSetting={matchSetting}
              handleSelectField={handleMatchSetting}
            />
          )
        })}
      </div>
      <div className='pt-4 pb-4'>
        <div className='mt-4 mb-4'>
          <Typography variant='h3' className='text-primary'>Buyers</Typography>
          <Typography variant='caption' className='text-secondary'>Buyers section</Typography>
        </div>
        {buyersSetting.map((item: any) => {
          return (
            <SelectField
              key={item.name}
              label={item.label}
              name={item.name}
              options={fieldOptions}
              matchSetting={matchSetting}
              handleSelectField={handleMatchSetting}
            />
          )
        })}
      </div>
      <div className='pt-4 pb-4'>
        <div className='mt-4 mb-4'>
          <Typography variant='h3' className='text-primary'>Mortgage</Typography>
          <Typography variant='caption' className='text-secondary'>Mortgage section</Typography>
        </div>
        {mortgageSetting.map((item: any) => {
          return (
            <SelectField
              key={item.name}
              label={item.label}
              name={item.name}
              options={fieldOptions}
              matchSetting={matchSetting}
              handleSelectField={handleMatchSetting}
            />
          )
        })}
      </div>
      <div className='pt-4 pb-4'>
        <div className='mt-4 mb-4'>
          <Typography variant='h3' className='text-primary'>Loan Officer</Typography>
          <Typography variant='caption' className='text-secondary'>Loan Officer section</Typography>
        </div>
        {loanOfficerSetting.map((item: any) => {
          return (
            <SelectField
              key={item.name}
              label={item.label}
              name={item.name}
              options={fieldOptions}
              matchSetting={matchSetting}
              handleSelectField={handleMatchSetting}
            />
          )
        })}
      </div>
      <div className='pt-4 pb-4'>
        <div className='mt-4 mb-4'>
          <Typography variant='h3' className='text-primary'>Terms and Conditions</Typography>
          <Typography variant='caption' className='text-secondary'>Terms and Conditions section</Typography>
        </div>
        {termsSetting.map((item: any) => {
          return (
            <SelectField
              key={item.name}
              label={item.label}
              name={item.name}
              options={fieldOptions}
              matchSetting={matchSetting}
              handleSelectField={handleMatchSetting}
            />
          )
        })}
      </div>
      <div className='pt-4 pb-4'>
        <div className='mt-4 mb-4'>
          <Typography variant='h3' className='text-primary'>Legal/Title</Typography>
          <Typography variant='caption' className='text-secondary'>Legal/Title section</Typography>
        </div>
        {legalSetting.map((item: any) => {
          return (
            <SelectField
              key={item.name}
              label={item.label}
              name={item.name}
              options={fieldOptions}
              matchSetting={matchSetting}
              handleSelectField={handleMatchSetting}
            />
          )
        })}
      </div>
    </div>
    
  )
}

export default MatchSetting