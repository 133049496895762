type Props = {
  width: number;
  height: number;
  stroke?: string;
  className?: string;
};

const IconMessage = (props: Props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 20 21"
      fill="none"
      {...props}
    >
      <path
        d="M7.72531 14.226C7.72531 18.1796 10.9418 21.3961 14.8954 21.3961C16.0168 21.3961 17.1242 21.1329 18.1189 20.6317C18.8126 21.2533 19.6498 21.4325 20.3841 21.4325C20.844 21.4325 21.264 21.3625 21.5839 21.2862C21.81 21.2323 21.9745 21.0489 22.0032 20.8186C22.0319 20.589 21.9185 20.3706 21.7134 20.2628C20.8839 19.8274 20.4359 19.1862 20.2455 18.8502C21.4376 17.5062 22.0655 15.9137 22.0655 14.2267C22.0655 10.2731 18.849 7.05664 14.8947 7.05664C10.9404 7.05664 7.72461 10.2731 7.72461 14.2267L7.72531 14.226ZM21.18 14.226C21.18 15.7779 20.5626 17.2451 19.3936 18.4673L19.1836 18.6871L19.3145 18.9643C19.3691 19.098 19.71 19.8659 20.5395 20.5435C19.878 20.5694 19.101 20.4287 18.5347 19.798L18.2589 19.5446L17.9803 19.7021C17.0444 20.2313 15.9776 20.5106 14.8947 20.5106C11.4297 20.5106 8.61011 17.6917 8.61011 14.226C8.61011 10.7603 11.429 7.94144 14.8947 7.94144C18.3604 7.94144 21.18 10.7603 21.18 14.226Z"
        fill="#969696"
      />
      <path
        d="M1.3373 14.4167L1.3492 14.4202C2.0002 14.6029 3.5248 15.0306 4.8387 13.9449L4.8646 13.9232C5.5513 14.3047 6.2898 14.5574 7.1116 14.6932C7.1361 14.6974 7.1606 14.6995 7.1844 14.6995C7.3972 14.6995 7.5848 14.5455 7.6205 14.3292C7.6604 14.0877 7.4973 13.8602 7.2558 13.8203C6.4277 13.6831 5.7284 13.4255 5.0543 13.009L4.7848 12.8424L4.2745 13.2617C3.6473 13.7804 2.9473 13.8231 2.3698 13.744C3.1713 13.1616 3.6648 12.4672 3.6907 12.4301L3.8769 12.1641L3.6039 11.8078L3.5843 11.784C2.5077 10.5653 1.9491 9.0008 2.0121 7.3782C2.0772 5.701 2.7912 4.1491 4.0232 3.0088C5.2552 1.8685 6.8575 1.277 8.5347 1.3414C11.4733 1.4548 13.9107 3.5471 14.463 6.4304C14.5092 6.6705 14.7402 6.828 14.981 6.7818C15.2211 6.7356 15.3786 6.5039 15.3324 6.2638C15.0328 4.7 14.2026 3.2594 12.9944 2.2087C11.768 1.1419 10.1958 0.519596 8.5683 0.456596C6.6545 0.383096 4.8268 1.0586 3.4212 2.3592C2.0156 3.6598 1.2008 5.4308 1.1266 7.3439C1.058 9.1121 1.6362 10.8194 2.7576 12.1802C2.4783 12.5057 1.9358 13.0601 1.2463 13.3891C1.037 13.4885 0.914502 13.702 0.934802 13.9337C0.955102 14.1654 1.1126 14.3551 1.3359 14.4167H1.3373Z"
        fill="#969696"
      />
      <path
        d="M5.13618 5.78643C5.19428 5.80533 5.23978 5.81933 6.87918 5.81933C7.69818 5.81933 8.91548 5.81583 10.7313 5.80743C10.9756 5.80603 11.173 5.60723 11.1716 5.36293C11.1702 5.11933 10.9728 4.92193 10.7292 4.92193C10.7292 4.92193 10.7278 4.92193 10.7271 4.92193C8.70268 4.93173 5.62058 4.94013 5.32588 4.92543C5.11798 4.89883 4.91498 5.02343 4.84918 5.22993C4.77498 5.46303 4.90308 5.71223 5.13618 5.78643Z"
        fill="#969696"
      />
      <path
        d="M5.18822 7.57175H9.47152C9.71582 7.57175 9.91462 7.37365 9.91462 7.12865C9.91462 6.88365 9.71652 6.68555 9.47152 6.68555H5.18822C4.94392 6.68555 4.74512 6.88365 4.74512 7.12865C4.74512 7.37365 4.94322 7.57175 5.18822 7.57175Z"
        fill="#969696"
      />
      <path
        d="M5.27123 9.22214H8.11252C8.35682 9.22214 8.55562 9.02404 8.55562 8.77904C8.55562 8.53404 8.35752 8.33594 8.11252 8.33594H5.27123C5.02693 8.33594 4.82812 8.53404 4.82812 8.77904C4.82812 9.02404 5.02623 9.22214 5.27123 9.22214Z"
        fill="#969696"
      />
      <path
        d="M13.8639 11.4828C13.7428 11.4569 13.5993 11.4331 13.4446 11.4478H13.4355C12.5808 11.4478 11.8101 11.9665 11.4664 12.7764C11.2844 13.2041 11.2242 13.6605 11.2865 14.1316C11.3432 14.5579 11.5161 14.9611 11.8304 15.4014C12.0894 15.764 12.4037 16.0727 12.6767 16.3275C12.9469 16.5795 13.2283 16.8224 13.4999 17.0576L13.6798 17.213C13.9759 17.4692 14.2692 17.7177 14.5688 17.9704C14.6983 18.0796 14.8467 18.1335 14.9951 18.1335C15.1435 18.1335 15.2912 18.0796 15.4207 17.9718C15.6097 17.8143 15.8008 17.6526 16.0052 17.4762L16.1249 17.3733C16.3846 17.15 16.6527 16.9183 16.9152 16.6866C17.4367 16.2253 17.9757 15.7486 18.3579 15.0948C18.7023 14.5047 18.8045 13.8761 18.661 13.2258C18.479 12.4012 17.982 11.8265 17.2246 11.5647C16.54 11.3281 15.8729 11.4359 15.2947 11.8776C15.182 11.9637 15.0854 12.0589 14.9993 12.1527C14.6724 11.7999 14.2916 11.5752 13.8653 11.4849L13.8639 11.4828ZM15.0168 13.2993L15.2485 13.1369C15.3234 13.0844 15.4102 13.0207 15.4928 12.9262L15.5348 12.8779C15.6342 12.7638 15.728 12.656 15.8295 12.579C16.1704 12.3186 16.5309 12.2605 16.9327 12.3991C17.4038 12.5622 17.6775 12.8849 17.7944 13.4148C17.8896 13.8467 17.8231 14.2492 17.5914 14.6461C17.2806 15.1774 16.8172 15.5876 16.3265 16.0216C16.0689 16.2498 15.8029 16.4787 15.5453 16.7006L15.4256 16.8042C15.2765 16.933 15.1344 17.0541 14.9951 17.171C14.7473 16.9617 14.5037 16.7552 14.2594 16.5431L14.0781 16.3863C13.8121 16.1567 13.5377 15.9194 13.2794 15.6786C13.026 15.4427 12.7628 15.183 12.5493 14.8855C12.3225 14.5677 12.1993 14.2912 12.1629 14.0147C12.1216 13.7025 12.1608 13.4022 12.2798 13.1222C12.4842 12.6413 12.9357 12.3333 13.4341 12.3333C13.439 12.3333 13.4446 12.3333 13.4495 12.3333C13.4523 12.3333 13.4551 12.3333 13.4586 12.3333C13.4796 12.3333 13.5006 12.3326 13.5223 12.3298C13.5643 12.3249 13.6448 12.3417 13.6791 12.3487C13.9416 12.4047 14.1726 12.5503 14.3861 12.7946C14.4008 12.8114 14.4148 12.8282 14.4288 12.845C14.5107 12.9423 14.6129 13.0641 14.7725 13.1572L15.0175 13.2993H15.0168Z"
        fill="#969696"
      />
    </svg>
  );
};

export default IconMessage;
