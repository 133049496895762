import { TextareaHTMLAttributes, useState } from "react";
import classNames from "classnames";
import { removeFirstDuplicateClassNames } from "@/shared/config/constants";

type Props = TextareaHTMLAttributes<HTMLTextAreaElement> & {
  textareaClassName?: string;
  onClear?: VoidFunction;
};

const Textarea = ({
  className,
  value,
  textareaClassName,
  onChange,
  onClear,
  ...other
}: Props) => {
  const [inValue, setInValue] = useState("");
  const modifiedTextAreaClassName = removeFirstDuplicateClassNames(
    "w-full p-4 bg-[#F1F5F9] rounded-sm border-0 " +
    textareaClassName
  )

  return (
    <div className={classNames("relative w-full", className)}>
      <textarea
        className={classNames(
          modifiedTextAreaClassName,
          {
            ["!bg-[#EAEEF2]"]: value ? true : false,
            ["pr-10"]: value ? !!value : !!inValue,
          }
        )}
        value={value ?? inValue}
        onChange={onChange ? onChange : (ev) => setInValue(ev.target.value)}
        {...other}
      />
    </div>
  );
};

export default Textarea;
