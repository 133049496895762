type Props = {
    width: number;
    height: number;
    stroke?: string;
    className?: string;
};

const IconAlertTriangle = (props: Props) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25 24" fill="none" {...props}>
            <path
                d="M12.5017 9.00023V13.0002M12.5017 17.0002H12.5117M11.117 3.89195L2.89214 18.0986C2.43594 18.8866 2.20783 19.2806 2.24155 19.6039C2.27095 19.886 2.41872 20.1423 2.64808 20.309C2.91104 20.5002 3.3663 20.5002 4.27682 20.5002H20.7266C21.6371 20.5002 22.0924 20.5002 22.3553 20.309C22.5847 20.1423 22.7325 19.886 22.7619 19.6039C22.7956 19.2806 22.5675 18.8866 22.1113 18.0986L13.8864 3.89195C13.4318 3.10679 13.2045 2.71421 12.908 2.58235C12.6494 2.46734 12.3541 2.46734 12.0954 2.58235C11.7989 2.71421 11.5716 3.10679 11.117 3.89195Z"
                stroke={props.stroke ?? "#C2410C"}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};

export default IconAlertTriangle;