type Props = {
  width: number;
  height: number;
  stroke?: string;
  className?: string;
};

const IconUserCheck = (props: Props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 21" fill="none" {...props}>
      <path d="M1.66663 18C1.66656 16.717 2.03674 15.4612 2.73275 14.3833C3.42876 13.3054 4.42102 12.4513 5.59046 11.9234C6.7599 11.3955 8.05682 11.2163 9.32559 11.4073C10.5944 11.5983 11.7811 12.1513 12.7433 13" stroke="#1F2937" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M8.33329 11.3333C10.6345 11.3333 12.5 9.46785 12.5 7.16667C12.5 4.86548 10.6345 3 8.33329 3C6.03211 3 4.16663 4.86548 4.16663 7.16667C4.16663 9.46785 6.03211 11.3333 8.33329 11.3333Z" stroke="#1F2937" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M13.3334 16.3337L15 18.0003L18.3334 14.667" stroke="#1F2937" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};

export default IconUserCheck;
