import Typography from '@/components/baseComponents/Typography'
import { XMarkIcon } from '@heroicons/react/24/outline'

interface IProps {
	setOpenConfirm: Function
	confirmDelete: Function
}

const ConfirmDelete = (props: IProps) => {
	const { setOpenConfirm, confirmDelete } = props
	return (
		<div className='bg-[#00000040] w-full h-screen flex items-center justify-center fixed top-0 left-0' onClick={()=>setOpenConfirm(false)}>
			<div className='rounded-lg max-w-[435px] w-full bg-white p-3'>
				<div>
					<div className='flex justify-end w-full text-secondary hover:text-primary cursor-pointer'>
						<XMarkIcon className="h-6 w-6" aria-hidden="true" onClick={()=>setOpenConfirm(false)} />
					</div>
					<div className='w-full flex justify-center mt-[10px]'>
						<Typography variant='button2'>Are you sure you want to archive this document?</Typography>
					</div>
					<div className='flex justify-center mt-[20px] mb-[10px]'>
						<div onClick={() => confirmDelete()}  className='bg-[#C18193] hover:bg-[#B17183] mr-4 w-[146px] h-[40px] rounded flex items-center justify-center cursor-pointer'>
							<Typography variant='button2' className='text-[#B32F43]'>Archive document</Typography>
						</div>
						<div onClick={()=>setOpenConfirm(false)} className='bg-[#B5E2C4] hover:bg-[#B17183] w-[146px] h-[40px] rounded flex items-center justify-center cursor-pointer'>
							<Typography variant='button2' className='text-[#6DA172]'>Keep document</Typography>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default ConfirmDelete