import React, { Fragment, useEffect, useState } from "react";
import { Button } from "@/components/baseComponents/Button";
import IconWrapper from "@/components/baseComponents/IconWrapper";
import Typography from "@/components/baseComponents/Typography";
import CalendarWeeklyView from "@/components/mainComponents/CalendarWeeklyView";
import TimeSelector from "@/components/mainComponents/TimeSelector";
import { Dialog, Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { format } from "date-fns";
import Scrollbars from "react-custom-scrollbars";
import {
  CreateShowingDto,
  IMyListing
} from "@/shared/interfaces/interfaces";
import Select from "@/components/baseComponents/Select";
import validation from "@/shared/services/validation";
import { useAppDispatch, useAppSelector } from "@/redux/hooks";
import { getUser } from "@/redux/user/userSlice";
import TextField from "@/components/baseComponents/TextField";
import { createNewShowing } from "@/redux/showing/showingSlice";
import { toast } from "react-toastify";
import { notify } from "@/shared/services/notify";
import { fetchAllClientsByUserId, getClients } from "@/redux/lead/leadSlice";
import { useDebounce } from "use-debounce";

type IProps = {
  data?: IMyListing;
  open: boolean;
  changeState: React.Dispatch<React.SetStateAction<boolean>>;
  date: { date: Date; setDate: React.Dispatch<React.SetStateAction<Date>> };
  footer?: any;
  closeAll?: React.Dispatch<React.SetStateAction<boolean>>;
};

const ScheduleDrawer = (props: IProps) => {
  // Redux hooks
  const dispatch = useAppDispatch();
  const user = useAppSelector(getUser);
  const clients = useAppSelector(getClients);

  const [addNew, setAddNew] = React.useState(false);
  const [closeRequestMessage, setCloseRequestMessage] = React.useState(false);

  //   State for form validation
  const [errorClientFirstName, setErrorClientFirstName] = React.useState<boolean>(false);
  const [errorClientLastName, setErrorClientLastName] = React.useState<boolean>(false);
  const [errorClientEmailAddress, setErrorClientEmailAddress] = React.useState<boolean>(false);
  const [errorClientPhoneNumber, setErrorClientPhoneNumber] = React.useState<boolean>(false);

  // State for if the form has already been submitted??
  const [requestedFlag, setRequestedFlag] = React.useState<boolean>(false); // temporarily to fix build errors

  // client options
  const [clientOptions, setClientOptions] = React.useState<any>([]);

  // state to store and display selected client
  const [selectedClient, setSelectedClient] = React.useState<any>(null);

  const initialValues: CreateShowingDto = {
    email: user.email,
    data: {
      client: "",
      clientFirstName: props.data?.client?.firstName ?? "",
      clientLastName: props.data?.client?.lastName ?? "",
      clientCompanyName: props.data?.client?.companyName ?? "",
      clientPhoneNumber: props.data?.client?.phoneNumber ?? "",
      clientEmailAddress: props.data?.client?.email ?? "",
      isClient: props.data?.client?.isClient === 1 ? true : false,
      status: "Pending",
      listing: props.data?._id ?? "",
      dateTime: new Date(),
    },
    userId: user._id,
  };

  const [clientSearchTerm, setClientSearchTerm] = useState<string>("");
  const [debouncedClientSearchedTerm] = useDebounce(clientSearchTerm, 1000);
  const [values, setValues] = React.useState<CreateShowingDto>(initialValues);

  const fetchClients = (searchTerm?: string) => {
    dispatch(
      fetchAllClientsByUserId({
        query: searchTerm || "",
        type: "",
        userId: user._id,
        relationship: "",
      })
    );
  }

  const onInputChangeAutoComplete = (searchTerm: string) => {
    setClientSearchTerm(searchTerm);
  };

  useEffect(() => {
    setClientOptions([
      { value: null, label: <Typography variant="body" color="primary">Add New</Typography> },
      ...clients.map((item: any) => {
        return {
          value: item,
          label: (
            <div className="flex gap-2">
              <Typography
                variant="body"
                color="primary"
              >
                {item.firstName +
                  " " +
                  item.lastName}
              </Typography>

              <button className="bg-blue-100 text-blue-600 px-1 py-[1px] rounded-[6px] text-[12px]">
                {item.isClient ? "Client" : "Lead"}
              </button>
            </div>
          ),
        };
      }),
    ]);
  }, [clients]);

  useEffect(() => {
    fetchClients(debouncedClientSearchedTerm);
  }, [debouncedClientSearchedTerm])

  useEffect(() => {
    fetchClients();
  }, []);

  const onChangeDate = (date: any) => {
    props.date.setDate(date);
  };

  const createShowing = () => {
    if (props.data?._id !== "") {
      if (selectedTime) {
        if (!props.data?._id) return;

        if (selectedClient?.value === null && (values?.data?.clientFirstName || values?.data?.clientLastName)) {
          setErrorClientFirstName(validation.IsEmptyString(values.data.clientFirstName));
          setErrorClientLastName(validation.IsEmptyString(values.data.clientLastName));

          if (validation.IsEmptyString(values.data.clientFirstName) || validation.IsEmptyString(values.data.clientLastName)) return;
        } else {
          setErrorClientFirstName(false);
          setErrorClientLastName(false);
        }

        let loadingToast = toast.loading("Creating your request");
        dispatch(createNewShowing(values)).then((res: any) => {
          if (res.payload.success) {
            toast.update(loadingToast, {
              render: res.payload.message,
              type: "success",
              isLoading: false,
              autoClose: 3000,
            });
            setValues(initialValues);
            setRequestedFlag(true);
            setAddNew(false);
          } else {
            toast.update(loadingToast, {
              render: "There was an error creating your request. Please try again.",
              type: "error",
              isLoading: false,
              autoClose: 3000,
            });
          }
        });
      } else {
        notify(false, "Please select a time");
      }
    }
  };

  const handleInputChange = (e: any) => {
    const { name, value } = e.target;
    let tempValue = value;

    if (name === "clientPhoneNumber") {
      const formattedPhoneNumber = validation.phoneNumberAutoFormat(value);

      if (
        name === "clientPhoneNumber" &&
        formattedPhoneNumber.length > 0 &&
        formattedPhoneNumber.length < 12
      )
        setErrorClientPhoneNumber(true);
      else setErrorClientPhoneNumber(false);

      setValues({
        ...values,
        data: {
          ...values.data,
          [name]: formattedPhoneNumber
        },
      });
    } else {
      setValues({
        ...values,
        data: {
          ...values.data,
          [name]: tempValue,
        },
      });
    }

    switch (name) {
      // case "clientFirstName":
      //   setErrorClientFirstName(validation.IsEmptyString(tempValue));
      //   break;
      // case "clientLastName":
      //   setErrorClientLastName(validation.IsEmptyString(tempValue));
      //   break;
      case "clientEmailAddress":
        setErrorClientEmailAddress(
          validation.IsInvalidEmail(tempValue) && tempValue.length > 0
        );
        break;
    }
  };

  const handleSelectChange = (
    value: any,
    name: string,
    isMulti: boolean = false
  ) => {
    let tempValue = value;
    if (isMulti) {
      tempValue = value.map((item: any) => {
        return item.value;
      });
    } else if (name === "client") {
      tempValue = value?.value?._id ?? "";
    }

    if (tempValue && name === "client") {
      const client = value.value;
      setSelectedClient({ value: client, label: `${client.firstName} ${client.lastName}` });
      setValues({
        ...values,
        data: {
          ...values.data,
          [name]: tempValue,
          clientFirstName: client.firstName ?? "",
          clientLastName: client.lastName ?? "",
          clientCompanyName: client.companyName ?? "",
          clientPhoneNumber: client.phoneNumber ?? "",
          clientEmailAddress: client.email ?? "",
          isClient: client.isClient ?? false,
        },
      });

      setErrorClientFirstName(false);
      setErrorClientLastName(false);
      setErrorClientEmailAddress(false);
      setErrorClientPhoneNumber(false);

      setAddNew(false);
    } else {
      setAddNew(!addNew);
      setValues(initialValues);
      setSelectedClient({ value: null, label: "Add New" });
    }
  };

  const [availabilityTime, setAvailabilityTime] = React.useState<any>([]);
  const [selectedTime, setSelectedTime] = React.useState("");

  useEffect(() => {
    if (availabilityTime.length === 0) {
      setSelectedTime("");
    }
  }, [availabilityTime]);

  useEffect(() => {
    setValues({
      ...values,
      data: {
        ...values.data,
        listing: props.data?._id ?? "",
        dateTime: new Date(format(props.date.date, "MM/dd/yyyy ") + selectedTime),
        status: "Pending"
      }
    });
  }, [selectedTime]);

  // const handleSelectActivation = () => {
  //   const addNewSelectUID = document.querySelector(".select-id-addnew");
  //   if (addNewSelectUID) {
  //     const input = addNewSelectUID.querySelector("input");
  //     if (input) {
  //       input.focus();
  //     }
  //   }
  // };

  return (
    <div>
      <Transition.Root show={props.open} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 z-40"
          onClose={() => {
            props.changeState(false);
          }}
        >
          <div className="fixed inset-0" />
          <div className="fixed inset-0 overflow-hidden">
            <div className="absolute inset-0 overflow-hidden">
              <div className="!pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-0 sm:pl-16">
                <Transition.Child
                  as={Fragment}
                  enter="transform transition ease-in-out duration-500 sm:duration-300"
                  enterFrom="translate-x-full"
                  enterTo="translate-x-0"
                  leave="transform transition ease-in-out duration-500 sm:duration-300"
                  leaveFrom="translate-x-0"
                  leaveTo="translate-x-full"
                >
                  <Dialog.Panel className="pointer-events-auto relative w-[600px]">
                    <div className="absolute z-50 max-sm:right-0 md:left-0 md:-translate-x-full flex md:flex-col gap-3 md:gap-6 pr-3 py-2">
                      <span
                        className="flex items-center p-2 bg-gray-50 rounded-full cursor-pointer"
                        onClick={() => props.changeState(false)}
                      >
                        <IconWrapper name="back" />
                      </span>
                      {props.closeAll && (
                        <button
                          type="button"
                          className="relative rounded-full bg-gray-50 p-2 text-gray-800 hover:text-[#C84156] focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                          onClick={() =>
                            props.closeAll && props.closeAll(false)
                          }
                        >
                          <span className="absolute -inset-2.5" />
                          <span className="sr-only">Close panel</span>
                          <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                        </button>
                      )}
                    </div>
                    <div className="flex h-full flex-col bg-white shadow-xl w-full">
                      <div className="relative w-full h-fit bg-white z-40 py-5 shadow-sm">
                        <div className="px-4 sm:px-6 w-full bg-white">
                          <div className="flex items-start justify-between">
                            <Dialog.Title className="text-[25px] font-medium truncate w-full flex flex-col gap-4">
                              <div>
                                <Typography variant="h1" color="primary">
                                  Schedule Showing
                                </Typography>
                                <Typography color="indigo">
                                  {props.data?.isPublic === 1
                                    ? `${props.data?.listingAddress.streetLine} ${props.data?.listingUnit || props.data?.listingAddress.secondary} ${props.data?.listingAddress.city}, 
                                        ${props.data?.listingAddress.state} ${props.data?.listingAddress.zipcode}`
                                    : `${props.data?.listingAddress.city}, ${props.data?.listingAddress.state} ${props.data?.listingAddress.zipcode}`}
                                </Typography>
                              </div>
                            </Dialog.Title>
                          </div>
                        </div>
                      </div>
                      <Scrollbars>
                        <div className="p-2 md:p-4 !px-6 space-y-5">
                          <div className="flex flex-col">
                            <div className="flex flex-col gap-4">
                              <Typography
                                variant="body"
                                color="primary"
                                className=" whitespace-nowrap pr-5 pt-2 font-bold"
                              >
                                {format(props.date.date, "EEEE, MMMM d, yyyy")}{" "}
                                {selectedTime && "at: " + selectedTime}
                              </Typography>
                              <div className="border shadow rounded-lg p-2 py-4 md:p-3">
                                <CalendarWeeklyView
                                  onSelectDate={onChangeDate}
                                />
                              </div>
                            </div>
                            <div>
                              <TimeSelector
                                data={props.data}
                                date={props.date.date}
                                availability={availabilityTime}
                                setAvalablity={setAvailabilityTime}
                                onSelectTime={setSelectedTime}
                              />
                            </div>
                          </div>

                          {/* Relationship */}
                          {/* currently set to false to display the UI, should be set to the appropriate value */}

                          <div className="my-[30px]">
                            <div className="flex flex-col gap-[15px] mt-[25px] py-3">
                              <div className="flex flex-col gap-2 col-span-2 sm:col-span-1">
                                <Typography
                                  variant="input-label"
                                  color="secondary"
                                >
                                  Client
                                </Typography>
                                <div className="flex items-center w-full gap-3">
                                  <div className="select-id-addnew w-full">
                                    <Select
                                      options={clientOptions}
                                      value={selectedClient}
                                      name="client"
                                      placeholder="Select"
                                      filterOption={() => true}
                                      onChange={(value) => handleSelectChange(value, "client")}
                                      onInputChange={(value) => onInputChangeAutoComplete(value)}
                                    />
                                  </div>
                                  {/* <Button variant="outlined">
                                    <Typography
                                      variant="button1"
                                      className="whitespace-nowrap"
                                      onClick={() => {
                                        setAddNew(!addNew);
                                        setValues(initialValues);
                                        setSelectedClient("");
                                      }}
                                    >
                                      Add New
                                    </Typography>
                                  </Button> */}
                                </div>
                              </div>
                            </div>

                            {/* add new client section */}
                            {addNew && (
                              <>
                                <div className="grid grid-cols-1 gap-4 py-3">
                                  <div className="flex flex-col gap-[6px]">
                                    <Typography
                                      variant="input-label"
                                      color="secondary"
                                      className="whitespace-nowrap"
                                    >
                                      Client type
                                    </Typography>
                                    <div className="flex gap-9 select-none">
                                      {["Lead", "Client"].map((type, index) => (
                                        <div
                                          key={index}
                                          className="flex items-center"
                                        >
                                          <input
                                            id={`type__${index}`}
                                            name="sent-method"
                                            type="radio"
                                            checked={
                                              index ===
                                              (values.data.isClient ? 1 : 0)
                                            }
                                            className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                                            onChange={() => {
                                              setValues({
                                                ...values,
                                                data: {
                                                  ...values.data,
                                                  isClient:
                                                    index === 1 ? true : false,
                                                },
                                              });
                                            }}
                                          />
                                          <label
                                            htmlFor={`type__${index}`}
                                            className="ml-1 block"
                                          >
                                            <Typography variant="page-menu">
                                              {type}
                                            </Typography>
                                          </label>
                                        </div>
                                      ))}
                                    </div>
                                  </div>
                                </div>
                                <div className="grid grid-cols-2 gap-4 py-3 relative">
                                  <div className="flex flex-col col-span-2 sm:col-span-1 gap-2">
                                    <Typography variant="input-label" color="secondary">
                                      First Name
                                    </Typography>
                                    <TextField
                                      name="clientFirstName"
                                      className={`w-full ${errorClientFirstName
                                        ? "outline outline-1 rounded outline-rose-600"
                                        : ""
                                        }`}
                                      value={values.data?.clientFirstName}
                                      onChange={(e) => handleInputChange(e)}
                                    />
                                    {errorClientFirstName && (
                                      <Typography
                                        variant="caption"
                                        className="text-rose-600 absolute -bottom-2"
                                      >
                                        This field is required
                                      </Typography>
                                    )}
                                  </div>
                                  <div className="flex flex-col col-span-2 sm:col-span-1 gap-2">
                                    <Typography variant="input-label" color="secondary">
                                      Last Name
                                    </Typography>
                                    <TextField
                                      className={`w-full ${errorClientLastName
                                        ? "outline outline-1 rounded outline-rose-600"
                                        : ""
                                        }`}
                                      name="clientLastName"
                                      value={values.data?.clientLastName}
                                      onChange={(e) => handleInputChange(e)}
                                    />
                                    {errorClientLastName && (
                                      <Typography
                                        variant="caption"
                                        className="text-rose-600 absolute -bottom-2"
                                      >
                                        This field is required
                                      </Typography>
                                    )}
                                  </div>
                                </div>

                                <div className="grid grid-cols-2 gap-4 py-3 relative">
                                  <div className="col-span-2 sm:col-span-1 flex flex-col gap-2">
                                    <Typography variant="input-label" color="secondary">
                                      Phone Number
                                    </Typography>
                                    <TextField
                                      className={`w-full ${errorClientPhoneNumber
                                        ? "outline outline-1 rounded outline-rose-600"
                                        : ""
                                        }`}
                                      maxLength={12}
                                      name="clientPhoneNumber"
                                      value={values.data?.clientPhoneNumber}
                                      onChange={(e) => handleInputChange(e)}
                                    />
                                    {errorClientPhoneNumber && (
                                      <Typography
                                        variant="caption"
                                        className="text-rose-600 absolute -bottom-2"
                                      >
                                        Please enter a valid phone number
                                      </Typography>
                                    )}
                                  </div>
                                  <div className="col-span-2 sm:col-span-1 flex flex-col gap-2">
                                    <Typography variant="input-label" color="secondary">
                                      Email Address
                                    </Typography>
                                    <TextField
                                      className={`w-full ${errorClientEmailAddress
                                        ? "outline outline-1 rounded outline-rose-600"
                                        : ""
                                        }`}
                                      name="clientEmailAddress"
                                      value={values.data?.clientEmailAddress}
                                      onChange={(e) => handleInputChange(e)}
                                    />
                                    {errorClientEmailAddress && (
                                      <Typography
                                        variant="caption"
                                        className="text-rose-600 absolute -bottom-2"
                                      >
                                        Invalid Email Address
                                      </Typography>
                                    )}
                                  </div>
                                </div>
                                <div className="flex flex-col py-3 w-full">
                                  <Typography variant="input-label" color="secondary">
                                    Company Name
                                  </Typography>
                                  <TextField
                                    placeholder=""
                                    name="clientCompanyName"
                                    value={values.data?.clientCompanyName}
                                    onChange={(e) => handleInputChange(e)}
                                  />
                                </div>
                              </>
                            )}
                          </div>

                          {/* schedule detail */}
                          {/* <div className="!mt-[12px] p-3 bg-gray-50 space-y-3 rounded-[5px]">
                            <div className="flex items-center gap-3 md:gap-12">
                              <Typography
                                variant="body"
                                color="secondary"
                                className="w-[100px] md:min-w-[145px]"
                              >
                                Showing Date
                              </Typography>
                              <Typography
                                variant="body"
                                color="primary"
                                className="text-center whitespace-nowrap font-medium"
                              >
                                {format(props.date.date, "EEEE, MMMM d, yyyy")}
                              </Typography>
                            </div>
                            <div className="flex items-center gap-3 md:gap-12">
                              <Typography
                                variant="body"
                                color="secondary"
                                className="w-[100px] md:min-w-[145px]"
                              >
                                Showing Time
                              </Typography>
                              <div>
                                {selectedTime ? (
                                  <span className=" text-primary font-medium text-[14px]">
                                    {selectedTime}
                                  </span>
                                ) : (
                                  <span className=" text-indigo-500 font-medium text-[14px]">
                                    Select Time
                                  </span>
                                )}
                              </div>
                            </div>
                            <div className="flex items-start gap-3 md:gap-12">
                              <Typography
                                variant="body"
                                color="secondary"
                                className="min-w-[100px] md:min-w-[145px]"
                              >
                                Client
                              </Typography>

                              {selectedClient !== null &&
                                selectedClient.firstName !== undefined ? (
                                <div className="bg-white border border-[#E5E7EB] p-2 rounded-8 flex w-full justify-between items-start">
                                  <div>
                                    <div className="flex items-center gap-1">
                                      <Typography
                                        variant="body"
                                        color="primary"
                                        className="!font-bold"
                                      >
                                        {selectedClient.firstName +
                                          " " +
                                          selectedClient.lastName}
                                      </Typography>

                                      <button className="bg-blue-100 text-blue-600 px-1 py-[1px] rounded-[6px] text-[12px]">
                                        {selectedClient.isClient
                                          ? "Client"
                                          : "Seller"}
                                      </button>
                                    </div>
                                    <div className="max-sm:flex hidden items-center gap-1">
                                      {selectedClient.clientCompanyName && (
                                        <div>
                                          <Typography
                                            variant="body"
                                            color="primary"
                                            className=""
                                          >
                                            {selectedClient.clientCompanyName ??
                                              ""}
                                          </Typography>
                                        </div>
                                      )}
                                    </div>
                                    <Typography variant="body" color="primary">
                                      {selectedClient.phoneNumber}
                                    </Typography>
                                    <Typography variant="body" color="primary">
                                      {selectedClient.email}
                                    </Typography>
                                  </div>
                                  <div className="hidden md:flex items-center gap-1">
                                    {selectedClient.companyName && (
                                      <div>
                                        <Typography
                                          variant="body"
                                          color="primary"
                                          className=""
                                        >
                                          {selectedClient.companyName}
                                        </Typography>
                                      </div>
                                    )}
                                  </div>
                                </div>
                              ) : (
                                <div>
                                  <Typography variant="body" color="secondary">
                                    <span
                                      className=" cursor-pointer hover:opacity-70"
                                      onClick={() => {
                                        setAddNew(!addNew);
                                        setValues(initialValues);
                                        setSelectedClient("");
                                      }}
                                    >
                                      Add New
                                    </span>{" "}
                                    or{" "}
                                    <span
                                      className=" cursor-pointer hover:opacity-70"
                                      onClick={handleSelectActivation}
                                    >
                                      Search Existing Client
                                    </span>
                                  </Typography>
                                </div>
                              )}
                            </div>
                          </div> */}

                          {/* Showing detail */}
                          <div className=" text-justify">
                            <div className="py-3">
                              <Typography variant="h4" color="primary">
                                Showings Details
                              </Typography>
                            </div>
                            <div className="p-3 max-sm:!pt-[10px] bg-gray-50 space-y-[20px] rounded-lg">
                              <div className="flex flex-col space-y-[20px]">
                                <div>
                                  <Typography
                                    variant="caption"
                                    color="secondary"
                                  >
                                    Showing Instructions
                                  </Typography>
                                  <Typography variant="body" color="primary">
                                    {props.data?.showingInstuction || "None"}
                                  </Typography>
                                </div>
                                <div className="flex flex-col">
                                  <Typography
                                    variant="caption"
                                    color="secondary"
                                  >
                                    Showing Remarks
                                  </Typography>
                                  <Typography variant="body" color="primary">
                                    {props.data?.showingRemarks || "None"}
                                  </Typography>
                                </div>
                              </div>

                              <div className="grid grid-cols-2 gap-5">
                                <div className="flex flex-col gap-1 py-[10px] ">
                                  <Typography
                                    variant="caption"
                                    color="secondary"
                                  >
                                    Lockbox or Keypad
                                  </Typography>
                                  <Typography variant="body" color="primary">
                                    {props.data?.showingLockboxOrKeypad ||
                                      "None"}
                                  </Typography>
                                </div>
                                <div className="flex flex-col gap-1 py-[10px] ">
                                  <Typography
                                    variant="caption"
                                    color="secondary"
                                  >
                                    Access Code
                                  </Typography>
                                  <Typography variant="body" color="primary">
                                    {props.data?.showingAccessCode || "None"}
                                  </Typography>
                                </div>
                                <div className="flex flex-col gap-1 py-[10px] ">
                                  <Typography
                                    variant="caption"
                                    color="secondary"
                                  >
                                    Occupancy Status
                                  </Typography>
                                  <Typography variant="body" color="primary">
                                    {props.data?.listingOccupancyStatus ||
                                      "None"}
                                  </Typography>
                                </div>
                                <div className="flex flex-col gap-1 py-[10px] ">
                                  <Typography
                                    variant="caption"
                                    color="secondary"
                                  >
                                    Require Agency Disclosure
                                  </Typography>
                                  <Typography variant="body" color="primary">
                                    {props.data
                                      ?.showingRequireAgencyDisclosure ||
                                      "None"}
                                  </Typography>
                                </div>
                              </div>
                            </div>
                          </div>

                          {/* request showing button */}
                          <div>
                            <Button
                              variant="contained"
                              color="primary"
                              className="w-full"
                              onClick={() => createShowing()}
                            >
                              <Typography variant="button1">
                                Request Showing
                              </Typography>
                            </Button>
                          </div>

                          {requestedFlag && (
                            <div
                              className={`p-3 rounded-[8px] flex items-start gap-5 bg-green-50 ${closeRequestMessage ? "hidden" : "block"}`}
                            >
                              <div>
                                <Typography
                                  variant="body"
                                  color="primary"
                                  className="text-justfiy break-words"
                                >
                                  Your request to show{" "}
                                  <span>
                                    {`${props.data?.listingAddress.streetLine} ${props.data?.listingUnit || props.data?.listingAddress.secondary} ${props.data?.listingAddress.city}, 
                                    ${props.data?.listingAddress.state} ${props.data?.listingAddress.zipcode}`}
                                  </span>{" "}
                                  at{" "}
                                  <span>
                                    {availabilityTime[selectedTime]} {" "}
                                    {format(
                                      new Date(props.date.date),
                                      "MMMM do"
                                    )}
                                  </span>{" "}
                                  has been received by{" "}
                                  <span>
                                    {props.data?.owner?.agent.firstName +
                                      " " +
                                      props.data?.owner?.agent.lastName}
                                  </span>
                                  .
                                </Typography>
                                <Typography
                                  variant="body"
                                  color="primary"
                                  className="mt-[25px] text-justfiy"
                                >
                                  You will be notified via email and your
                                  <span>
                                    {" "}
                                    RealtyView{" "}
                                  </span>{" "}
                                  dashboard when a response is entered. Please
                                  allow some time for a response but always
                                  follow up if necessary.
                                </Typography>
                              </div>
                              <button
                                onClick={() => setCloseRequestMessage(true)}
                              >
                                <IconWrapper name="close" />
                              </button>
                            </div>
                          )}

                          {/* Drawer footer */}
                          <div className="py-5">{props.footer}</div>
                        </div>
                      </Scrollbars>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </div>
  );
};

export default ScheduleDrawer;