// React import
import { Fragment, useEffect, useState } from 'react'

// Component imports
import Table from '@/components/baseComponents/table'
import Typography from '@/components/baseComponents/Typography'
import { Button } from '@/components/baseComponents/Button'
import Drawer from './Drawer'
import View from './View'
import Filter from "@/components/mainComponents/Filter"
import Sort from "@/components/mainComponents/Sort"

// Redux imports
import { getClientsByUserId, deleteClients, unarchiveClients } from '@/redux/client/clientSlice'
import { convertToClientLeads, setLeadRating, setLeadStatus } from "@/redux/lead/leadSlice"
import { getUser } from "@/redux/user/userSlice"
import { useAppSelector, useAppDispatch } from "@/redux/hooks"

// Service imports
import { notify } from "@/shared/services/notify"

// Icon imports
import { XMarkIcon } from "@heroicons/react/24/outline"

// Interface imports
import {
  ConvertToClientDto,
  DeleteClientsDto,
  GetClientsDto,
  SetLeadStatusDto,
  SetRatingDto,
} from "@/shared/interfaces/interfaces"

// Image imports
import RecycleBin from '@/assets/images/recylebin.svg'
import { Menu, Transition } from '@headlessui/react'
import IconWrapper from '@/components/baseComponents/IconWrapper'

let tabs = [
  { name: "All" },
  { name: "Buyer" },
  { name: "Seller" },
];

let sortOptions = [
  { label: "Name", value: "Name" },
  { label: "Email", value: "Email" },
  { label: "Phone", value: "Phone" },
  { label: "Lead Type", value: "Lead Type" },
  { label: "Status", value: "Status" },
  { label: "Rating", value: "Rating" },
];

const Client = () => {
  // Redux hooks
  const dispatch = useAppDispatch();
  const user = useAppSelector(getUser);

  // Data states
  const [clients, setClients] = useState<Array<any>>([]);
  const [viewData, setViewdata] = useState<any>(null);
  const [editData, setEditData] = useState<any>(null);
  const [selectedPeople, setSelectedPeople] = useState<Array<any>>([]);
  const [selectedLead, setSelectedLead] = useState<any>({});

  // UI states
  const [currentTab, setCurrentTab] = useState<string>("All");
  const [open, setOpen] = useState<boolean>(false);
  const [openView, setOpenView] = useState<boolean>(false);
  const [openConfirm, setOpenConfirm] = useState<boolean>(false);

  // Search and sort states
  const [keyword, setKeyword] = useState<string>("");
  const [sortType, setSortType] = useState<string>("Descending");
  const [sortField, setSortField] = useState<string>("Name");

  // Pagination states
  const [totalCount, setTotalCount] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [recordsPerPage, setRecordsPerPage] = useState<number>(25);

  // Define the function to set the current page
  const onSetPage = (value: number) => {
    setCurrentPage(value);
  };

  // Define the function to open the drawer
  const openDrawer = () => {
    setEditData(null);
    setViewdata(null);
    setOpen(true);
  };

  // Define the function to handle row click
  const onClickRow = (value: any) => {
    setOpenView(true);
    setViewdata(value);
  };

  // Define the function to edit a lead
  const editLead = (value: any) => {
    setEditData(value);
    setOpen(true);
  };

  // Define the function to change the selected people
  const changeSelectedPeople = (value: any) => {
    setSelectedPeople(value);
  };

  // Define the function to open the delete confirmation modal
  const deleteOpenModal = () => {
    if (selectedPeople.length > 0) {
      setOpenConfirm(true);
    } else {
      notify(false, "Please select one or more clients that you wish to archive.");
    }
  };

  // Define the function to confirm deletion
  const deleteConfirm = () => {
    let ids: Array<string> = [];
    if (selectedPeople.length === 0 && selectedLead) {
      ids = [selectedLead._id];
    } else {
      ids = selectedPeople.map((lead: any) => {
        return lead._id;
      });
    }
    const data: DeleteClientsDto = {
      ids: ids,
      userId: user._id,
      search: {
        userId: user._id,
        keyword: keyword,
        sortType: sortType,
        sortField: sortField,
        recordsPerPage: recordsPerPage,
        currentPage: currentPage,
        status: currentTab
      },
    };
    dispatch(deleteClients(data)).then((res) => {
      try {
        setTotalCount(res.payload.totalCount);
        notify(res.payload.success, res.payload.message);
      } catch (e) {
        notify(false, "Something went wrong.");
      }
    });
  };

  // Define the function to confirm unarchiving
  const unarchiveConfirm = () => {
    let ids: Array<string> = [];
    if (selectedPeople.length === 0 && selectedLead) {
      ids = [selectedLead._id];
    } else {
      ids = selectedPeople.map((lead: any) => {
        return lead._id
      })
    }
    const data: DeleteClientsDto = {
      ids: ids,
      userId: user._id,
      search: {
        userId: user._id,
        keyword: keyword,
        sortType: sortType,
        sortField: sortField,
        recordsPerPage: recordsPerPage,
        currentPage: currentPage,
        status: currentTab
      }
    }
    dispatch(unarchiveClients(data)).then((res) => {
      try {
        setTotalCount(res.payload.totalCount)
        notify(res.payload.success, res.payload.message)
      } catch (e) {
        notify(false, 'Something went wrong.')
      }
    })
  };

  // Define the function to change the keyword
  const changeKeyword = (keyword: string) => {
    setKeyword(keyword);
  };

  // Define the function to change the sort type
  const changeSortType = (value: string) => {
    setSortType(value);
  };

  // Define the function to change the sort field
  const changeSortField = (value: any) => {
    setSortField(value.value);
  };

  // Define the function to filter clients
  const filterClients = () => {
    const data: GetClientsDto = {
      userId: user._id,
      keyword: keyword,
      sortType: sortType,
      sortField: sortField,
      recordsPerPage: recordsPerPage,
      currentPage: currentPage,
      status: currentTab
    };
    dispatch(getClientsByUserId(data)).then((res) => {
      try {
        setClients(res.payload.clients);
        setTotalCount(res.payload.totalCount);
      } catch (e) {
        notify(false, "Something went wrong.");
      }
    });
  };

  // Define the function to archive a client
  const archive = async (item: any) => {
    setSelectedLead(item);
    setOpenConfirm(true);
    setOpenView(false);
  };

  // Define the function to convert a lead to a client
  const convertToClient = async (item: any, toClient: boolean) => {
    const data: ConvertToClientDto = {
      leadId: item._id,
      toClient: toClient,
      search: {
        userId: user._id,
        keyword: keyword,
        sortType: sortType,
        sortField: sortField,
        recordsPerPage: recordsPerPage,
        currentPage: currentPage,
        status: currentTab
      },
    };
    dispatch(convertToClientLeads(data)).then((res: any) => {
      try {
        setTotalCount(res.payload.totalCount);
        filterData();
        notify(res.payload.success, "A client has been converted to lead successfully!");
      } catch (e) {
        notify(false, "Something went wrong.");
      }
    });
    setOpenView(false);
  };

  // Define the function to set the rating
  const setRating = async (item: any, value: string) => {
    const data: SetRatingDto = {
      leadId: item._id,
      rating: value,
      search: {
        userId: user._id,
        keyword: keyword,
        sortType: sortType,
        sortField: sortField,
        recordsPerPage: recordsPerPage,
        currentPage: currentPage,
        status: currentTab
      },
    };
    dispatch(setLeadRating(data)).then((res) => {
      try {
        setTotalCount(res.payload.totalCount);
        notify(res.payload.success, res.payload.message);
        filterClients();
      } catch (e) {
        notify(false, "Something went wrong.");
      }
    });
  };

  // Define the function to set the status
  const setStatus = async (item: any, value: string) => {
    const data: SetLeadStatusDto = {
      leadId: item._id,
      leadStatus: value,
      search: {
        userId: user._id,
        keyword: keyword,
        sortType: sortType,
        sortField: sortField,
        recordsPerPage: recordsPerPage,
        currentPage: currentPage,
        status: currentTab
      },
    };
    dispatch(setLeadStatus(data)).then((res) => {
      try {
        setTotalCount(res.payload.totalCount);
        filterClients();
        notify(res.payload.success, res.payload.message);
      } catch (e) {
        notify(false, "Something went wrong.");
      }
    });
  };

  // Define the function to filter data
  const filterData = () => {
    const data: GetClientsDto = {
      userId: user._id,
      keyword: keyword,
      sortType: sortType,
      sortField: sortField,
      recordsPerPage: recordsPerPage,
      currentPage: currentPage,
      status: currentTab
    };
    dispatch(getClientsByUserId(data)).then((res) => {
      try {
        setTotalCount(res.payload.totalCount);
      } catch (e) {
        notify(false, "Something went wrong.");
      }
    });
  };

  // Use an effect to fetch clients when the page or tab changes
  useEffect(() => {
    setRecordsPerPage(25);
    const data: GetClientsDto = {
      userId: user._id,
      keyword: keyword,
      sortType: sortType,
      sortField: sortField,
      recordsPerPage: recordsPerPage,
      currentPage: currentPage,
      status: currentTab
    };
    dispatch(getClientsByUserId(data)).then((res) => {
      try {
        setClients(res.payload.clients)
        setTotalCount(res.payload.totalCount);
      } catch (e) {
        notify(false, "Something went wrong.");
      }
    });
  }, [currentPage, currentTab, totalCount]);

  useEffect(() => {
    setViewdata(clients.find((client) => client?._id === viewData?._id));
  }, [clients]);

  useEffect(() => {
    if (open && openView) {
      setOpenView(false);
    }

    if (!open) {
      if (viewData) {
        setOpenView(true);
      }
    }
  }, [open]);

  return (
    <div className="px-6 pt-10 lg:pb-24 md:pb-60 sm:pb-60 max-sm:pb-60">
      {openConfirm && (
        <div
          className="bg-[#00000040] w-full h-screen flex items-center justify-center fixed top-0 left-0 z-30"
          onClick={() => setOpenConfirm(false)}
        >
          <div className="rounded-lg max-w-[435px] w-full bg-white p-3">
            <div>
              <div className="flex justify-end w-full text-secondary hover:text-primary cursor-pointer">
                <XMarkIcon
                  className="h-6 w-6"
                  aria-hidden="true"
                  onClick={() => setOpenConfirm(false)}
                />
              </div>
              <div className="w-full flex justify-center mt-[10px]">
                <Typography variant="button2">
                  Are you sure you want to {selectedPeople.length === 0 && selectedLead.isArchived === 1 ? 'unarchive' : 'archive'}{" "}
                  {selectedPeople.length > 1 ? "these clients" : "this lead"}?
                </Typography>
              </div>
              <div className="flex justify-center mt-[20px] mb-[10px]">
                {selectedPeople.length === 0 && selectedLead.isArchived === 1 ? (
                  <div className='bg-success hover:bg-success w-[146px] h-[40px] rounded flex items-center justify-center cursor-pointer' onClick={() => unarchiveConfirm()}>
                    <Typography variant='button2' className='text-[#6DA172]'>Unarchive</Typography>
                  </div>
                ) : (
                  <div className='bg-[#C18193] hover:bg-[#B17183] w-[146px] h-[40px] rounded flex items-center justify-center cursor-pointer' onClick={() => deleteConfirm()}>
                    <Typography variant='button2' className='text-[#B32F43]'>Archive</Typography>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
      <Drawer
        open={open}
        changeState={setOpen}
        data={editData}
        keyword={keyword}
        sortType={sortType}
        sortField={sortField}
        currentPage={currentPage}
        recordsPerPage={recordsPerPage}
        setTotalCount={setTotalCount}
        setCurrentPage={setCurrentPage}
      />
      <View
        open={openView}
        changeState={setOpenView}
        data={viewData}
        convertToClient={convertToClient}
        archive={archive}
        editLead={editLead}
      />
      <div className="flex justify-between items-center">
        <div className="flex items-center gap-2">
          <Typography variant="h2" color="primary">
            Clients
          </Typography>
          <Menu>
            <div className="relative w-full ">
              <Menu.Button className="relative w-fit flex gap-1 cursor-default rounded-full text-left">
                <div className="cursor-pointer text-[#9CA3AF] flex items-center px-[8px] py-[4px] gap-[4px] w-fit">
                  <Typography
                    variant="body"
                    color="secondary"
                  >
                    {currentTab === "Archived" ? "All Type" : `${currentTab} ${currentTab === "All" ? "Type" : ""}`}
                  </Typography>
                  <IconWrapper
                    name="arrow-down"
                    width={16}
                    innerStroke="#6B7280"
                  />
                </div>
              </Menu.Button>
              <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <Menu.Items className="z-10 absolute top- origin-top-right left-0  min-h-60 w-fit min-w-[120px] overflow-hidden bg-white rounded-8 text-base shadow-lg">
                  {tabs.map((tab, index) => (
                    <Menu.Item as={Fragment} key={index}>
                      {({ active }) => (
                        <div
                          key={index}
                          className="flex flex-col items-start text-primary text-14"
                        >
                          <button
                            className={` p-3 w-full text-start ${active ? "bg-[#F3F4F6]" : "bg-white"
                              }`}
                            onClick={() => {
                              setCurrentTab(tab.name);
                            }}
                          >
                            {tab.name}
                          </button>
                        </div>
                      )}
                    </Menu.Item>
                  ))}
                </Menu.Items>
              </Transition>
            </div>
          </Menu>
        </div>
        <div className="flex items-center gap-3">
          {selectedPeople.length > 0 && (
            <img
              src={RecycleBin}
              alt="Archive"
              className="cursor-pointer"
              onClick={() => deleteOpenModal()}
            />
          )}
          <Button variant="contained" color="secondary" size="small" className="flex items-center gap-2 !py-0" onClick={() => setCurrentTab("Archived")}>
            <IconWrapper name="archive" innerStroke="#6B7280" width={16} />
            <Typography variant="button1" className="lg:block sm:hidden max-sm:hidden">Archived</Typography>
          </Button>
          <Filter
            changeKeyword={changeKeyword}
            keyword={keyword}
            filterLeads={filterClients}
          />
          <Sort
            options={sortOptions}
            sortType={sortType}
            sortField={sortField}
            changeSortField={changeSortField}
            changeSortType={changeSortType}
            filterLeads={filterClients}
          />
          <Button onClick={() => openDrawer()} className="max-sm:hidden">
            <Typography variant="button1">Add Client</Typography>
          </Button>
        </div>
      </div>
      <div className="md:hidden sm:block mt-6">
        <Button onClick={() => openDrawer()} className="w-full">
          <Typography variant="button1">Add Client</Typography>
        </Button>
      </div>
      <div className="mt-6">
        <Table
          data={clients}
          archive={archive}
          convertToClient={convertToClient}
          setRating={setRating}
          setStatus={setStatus}
          onClickRow={onClickRow}
          editLead={editLead}
          changeSelectedPeople={changeSelectedPeople}
          totalPage={Math.ceil(totalCount / recordsPerPage)}
          totalCount={totalCount}
          currentPage={currentPage}
          recordsPerpage={recordsPerPage}
          onSetPage={onSetPage}
        />
      </div>
    </div>
  );
};

export default Client