import axios from './axios'

export const updateViewedStatus = async (notificationId: string, viewedStatus: boolean) => {
    const data = {
        id: notificationId,
        viewed: viewedStatus,
    };
    const response = await axios.put('notification/update-viewed', data);
    return response.data;
}

export const deleteNotification = async (notificationId: string, userId: string) => {
    const data = {
        ids: [notificationId],
        userId: userId,
    };
    const response = await axios.put('notification/delete', data);
    return response.data;
}