type Props = {
  width: number;
  height: number;
  stroke?: string;
  className?: string;
};

const IconImage = (props: Props) => {
  return (
    <svg
      viewBox="0 0 11 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M8.53998 0H1.53998C1.14215 0 0.760623 0.158035 0.479318 0.43934C0.198013 0.720645 0.039978 1.10218 0.039978 1.5V8.5C0.039978 8.89783 0.198013 9.27936 0.479318 9.56066C0.760623 9.84197 1.14215 10 1.53998 10H8.53998C8.62222 9.99885 8.7042 9.99049 8.78498 9.975L8.93498 9.94H8.99498L9.17998 9.87L9.24498 9.835C9.29498 9.805 9.34998 9.78 9.39998 9.745C9.46674 9.69588 9.53021 9.64243 9.58998 9.585L9.62498 9.54C9.67407 9.49026 9.7192 9.43676 9.75998 9.38L9.80498 9.315C9.8399 9.25933 9.87001 9.20079 9.89498 9.14C9.9087 9.11601 9.92041 9.09092 9.92998 9.065C9.95498 9.005 9.96998 8.94 9.98998 8.875V8.8C10.0183 8.7023 10.0351 8.60161 10.04 8.5V1.5C10.04 1.10218 9.88194 0.720645 9.60064 0.43934C9.31933 0.158035 8.9378 0 8.53998 0ZM1.53998 9C1.40737 9 1.28019 8.94732 1.18642 8.85355C1.09266 8.75979 1.03998 8.63261 1.03998 8.5V6.345L2.68498 4.695C2.73146 4.64814 2.78676 4.61094 2.84769 4.58555C2.90862 4.56017 2.97397 4.5471 3.03998 4.5471C3.10598 4.5471 3.17134 4.56017 3.23227 4.58555C3.2932 4.61094 3.3485 4.64814 3.39498 4.695L7.69498 9H1.53998ZM9.03998 8.5C9.03951 8.56165 9.02764 8.62267 9.00498 8.68C8.99355 8.70437 8.98017 8.72778 8.96498 8.75C8.9516 8.77117 8.93655 8.79123 8.91998 8.81L6.24498 6.135L6.68498 5.695C6.73146 5.64814 6.78676 5.61094 6.84769 5.58555C6.90862 5.56017 6.97397 5.5471 7.03998 5.5471C7.10598 5.5471 7.17134 5.56017 7.23227 5.58555C7.2932 5.61094 7.3485 5.64814 7.39498 5.695L9.03998 7.345V8.5ZM9.03998 5.93L8.09998 5C7.81384 4.7285 7.43443 4.57714 7.03998 4.57714C6.64553 4.57714 6.26612 4.7285 5.97998 5L5.53998 5.44L4.09998 4C3.81384 3.7285 3.43443 3.57714 3.03998 3.57714C2.64553 3.57714 2.26612 3.7285 1.97998 4L1.03998 4.93V1.5C1.03998 1.36739 1.09266 1.24021 1.18642 1.14645C1.28019 1.05268 1.40737 1 1.53998 1H8.53998C8.67259 1 8.79976 1.05268 8.89353 1.14645C8.9873 1.24021 9.03998 1.36739 9.03998 1.5V5.93Z"
        fill="#6C757D"
      />
    </svg>
  );
};

export default IconImage;
