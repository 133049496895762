import AdvancedTable from "@/components/baseComponents/AdvancedTable";
import Typography from "@/components/baseComponents/Typography";
import { Button } from "@/components/baseComponents/Button";
import { useAppDispatch, useAppSelector } from "@/redux/hooks";
import { getCrawlerList, getCrawlers, getJobs, getJobsList, getTotalJobs, sendCampaign, streamData, syncContacts } from "@/redux/scrapeHero/scrapeHeroSlice";
import { IScrapeHeroJob } from "@/shared/interfaces/scrapeHero/IScrapeHeroJob";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { format, intervalToDuration, isValid } from "date-fns";
import Filter from "./Filter";
import { IScrapeHeroImport } from "@/shared/interfaces/scrapeHero/IScrapeHeroImport";
import Label from "@/components/baseComponents/Label";
import { notify } from "@/shared/services/notify";
import { AiOutlineUndo } from "react-icons/ai";
import IconWrapper from "@/components/baseComponents/IconWrapper";

const ScrapeHero = () => {

    const tableFields = [
        {
            name: "",
            type: "checkbox",
            slug: "jobId",
            class_name: "text-left !p-[8px]",
        },
        {
            name: "Crawler",
            type: "text",
            slug: "crawlerName",
            class_name: "px-6 text-left !py-[20px] min-w-[100px] pl-[40px]",
        },
        {
            name: "Pages | Records",
            type: "text",
            slug: "pagesCrawled",
            class_name: "px-0 !py-[20px] max-w-[130px] [&>p]:min-w-full [&>p]:flex [&>p]:justify-center",
        },
        {
            name: "Start Time",
            type: "text",
            slug: "startTime",
            class_name: "px-6 text-left !py-[20px] min-w-[100px] pl-[40px]",
        },
        {
            name: "Job Time",
            type: "text",
            slug: "jobTime",
            class_name: "px-6 text-left !py-[20px] min-w-[100px] pl-[40px]",
        },
        {
            name: "Job ID",
            type: "text",
            slug: "jobId",
            class_name: "px-6 text-left !py-[20px] min-w-[100px] pl-[40px]",
        },
        {
            name: "Imported",
            type: "text",
            slug: "recordsImported",
            class_name: "px-0 !py-[20px] min-w-[100px] [&>p]:min-w-full [&>p]:flex [&>p]:justify-center",
        },
        { name: "", type: "text", slug: "action", class_name: "" },
    ]

    const dispatch = useAppDispatch();
    const crawlers = useAppSelector(getCrawlers);
    const jobs = useAppSelector(getJobs);
    const totalJobs = useAppSelector(getTotalJobs);
    const [loading, setLoading] = useState<boolean>(true);
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [recordsPerPage, setRecordsPerPage] = useState<number>(10);
    const [tableData, setTableData] = useState<Array<Object>>([]);

    const [filterData, setFilterData] = useState<any>({});
    const [selectedJobs, setSelectedJobs] = useState<IScrapeHeroJob[]>([]);
    const [triggerResetSelectedRows, setTriggerResetSelectedRows] = useState<Date>(new Date());

    const onApplyFilter = (_filterData: any) => {
        setFilterData(_filterData);
        setCurrentPage(1);
    }

    const onGetCrawlerList = () => {
        dispatch(getCrawlerList()).then(response => {
            if (response.payload && response.payload.success) {
                onGetJobsList();
            }
        });
    }

    const onGetJobsList = () => {
        dispatch(getJobsList({
            filterByCrawler: filterData?.crawler?.value,
            filterByStartTimeFrom: filterData?.startTimeFrom,
            filterByStartTimeTo: filterData?.startTimeTo,
            forImportOnly: filterData.forImportOnly,
            forCampaignOnly: filterData.forCampaignOnly,
            recordsPerPage,
            currentPage
        })).then(response => {
            if (response.payload && response.payload.success) {
                setTriggerResetSelectedRows(new Date());
                setSelectedJobs([]);
                setLoading(false);
            }
        })
    }

    const importListings = (jobs: IScrapeHeroImport[]) => {
        setLoading(true);
        let loadingToast = toast.loading(`Importing records...`);

        dispatch(streamData(jobs)).then((response) => {
            if (response.payload && response.payload.success) {
                toast.update(loadingToast, {
                    type: 'success',
                    render: `Listing records successfully imported!`,
                    progress: 0,
                    isLoading: false,
                    autoClose: 3000
                });
            } else {
                toast.update(loadingToast, {
                    type: 'error',
                    render: `Import failed! Please try again.`,
                    progress: 0,
                    isLoading: false,
                    autoClose: 3000
                });
            }
            onGetCrawlerList();
        });
    }

    const onImport = (job: IScrapeHeroJob) => {
        importListings([{
            crawlerSlug: job.crawlerSlug,
            jobId: job.jobId,
            datasets: job.datasets
        }]);
    }

    const onImportAllSelected = () => {
        importListings(selectedJobs.filter(job => !job.isImported));
    }

    const sendEmailCampaign = (jobs: IScrapeHeroJob[]) => {
        setLoading(true);
        let sendCampaignToast = toast.loading(`Sending campaigns...`);

        dispatch(sendCampaign(jobs.map(job => job.jobId))).then((response) => {
            if (response.payload && response.payload.success) {
                toast.update(sendCampaignToast, {
                    type: 'success',
                    render: `Email Campaigns successfully sent!`,
                    progress: 0,
                    isLoading: false,
                    autoClose: 3000
                });
            } else {
                toast.update(sendCampaignToast, {
                    type: 'error',
                    render: `Sending campaign failed! Please try again.`,
                    progress: 0,
                    isLoading: false,
                    autoClose: 3000
                });
            }
            onGetCrawlerList();
        })
    }

    const onSendCampaign = (job: IScrapeHeroJob) => {
        sendEmailCampaign([job]);
    }

    const onSendCampaignAllSelected = () => {
        sendEmailCampaign(selectedJobs.filter(job => job.isImported))
    }

    const onSelectRow = (jobs: IScrapeHeroJob[]) => {
        setSelectedJobs(jobs);
    };

    const renderTableActions = (job: IScrapeHeroJob, isLoading: boolean) => {
        return (
            <div className="flex-wrap space-y-1">
                <Button
                    variant="outlined"
                    color={`${job.isImported ? `blue` : `default`}`}
                    size="small"
                    className="w-[150px] relative disabled:!cursor-not-allowed disabled:!opacity-50"
                    disabled={isLoading}
                    onMouseUp={(event) => event.currentTarget.disabled = true}
                    onMouseDown={() => onImport(job)}
                >
                    <Typography variant="button2" className="mt-[1px]">{job.isImported ? `Re-import` : `Import`}</Typography>
                    {job.isImported && <AiOutlineUndo className={`text-14 absolute left-2`} />}
                </Button>
                <Button
                    variant="outlined"
                    color={`${job.isCampaignSent ? `blue` : `default`}`}
                    size="small"
                    className="w-[150px] relative disabled:cursor-not-allowed disabled:!opacity-50"
                    disabled={isLoading || !job.isImported}
                    onMouseUp={(event) => event.currentTarget.disabled = true}
                    onMouseDown={() => onSendCampaign(job)}
                >
                    <Typography variant="button2" className="mt-[1px]">{job.isCampaignSent ? `Follow Up` : `Send Campaign`}</Typography>
                    {job.isCampaignSent && <IconWrapper width={14} name="envelope" className="absolute left-1 [&>path]:!stroke-blue-500" />}
                </Button>
            </div>
        )
    }

    const formatTableData = (isLoading: boolean) => {
        let result: Array<Object> = [];

        if (jobs && jobs.length > 0) {
            jobs.map((job: IScrapeHeroJob) => {
                let startDate, startTime, jobTime;
                if (isValid(new Date(job.startTime))) {
                    startDate = format(new Date(job.startTime), "MM/dd/yyyy");
                    startTime = format(new Date(job.startTime), "hh:mm:ss a");
                    if (isValid(new Date(job.endTime))) {
                        jobTime = intervalToDuration({ start: new Date(job.startTime), end: new Date(job.endTime) });
                    }
                }
                result.push({
                    ...job,
                    pagesCrawled: (
                        <span className="flex justify-center gap-2 w-full">
                            <Typography variant="table-row-content" color="tertiary" className="border-r-1 border-gray-400 px-2">{job.pagesCrawled}</Typography> 
                            <Label variant="contained" color="info" size="small" className="rounded-md font-semibold" title={job.records} />
                        </span>
                    ),
                    startTime: (
                        <span className="flex flex-col">
                            <Typography variant="table-row-content" color="primary">{startDate}</Typography>
                            <Typography variant="table-row-content" color="primary">{startTime}</Typography>
                        </span>
                    ),
                    recordsImported: <Label variant="contained" color="info" size="small" className="rounded-md font-semibold" title={job.recordsImported} />,
                    jobTime: `${jobTime?.minutes}m ${jobTime?.seconds}s`,
                    action: renderTableActions(job, isLoading)
                });
            })
        }

        setTableData(result);
    }

    const onSyncContacts = () => {
        setLoading(true);
        dispatch(syncContacts()).then(response => {
            if (response.payload && response.payload.success) {
                notify(true, "Contacts successfully synced to SendGrid list.");
            } else {
                notify(false, "Sync failed. Please try again or contact support");
            }
            setLoading(false);
        })
    }

    const onSetTablePage = (value: number) => {
        setCurrentPage(value);
    };

    useEffect(() => {
        onGetCrawlerList();
        setRecordsPerPage(10);
    }, [])

    useEffect(() => {
        formatTableData(loading);
    }, [jobs, loading])

    useEffect(() => {
        dispatch(getJobsList({
            filterByCrawler: filterData?.crawler?.value,
            filterByStartTimeFrom: filterData?.startTimeFrom,
            filterByStartTimeTo: filterData?.startTimeTo,
            forImportOnly: filterData.forImportOnly,
            forCampaignOnly: filterData.forCampaignOnly,
            recordsPerPage,
            currentPage
        }))
    }, [currentPage, filterData])

    return (
        <div className="px-6 pt-10 pb-[25vh]">
            <div className="flex flex-col gap-6">
                <div className="flex justify-between items-center">
                    <div className="flex items-center md:gap-3">
                        <Typography
                            variant="h2"
                            color="primary"
                            className="whitespace-nowrap"
                        >
                            Scrape Hero Jobs
                        </Typography>
                    </div>

                    <div className="flex gap-2 items-center">
                        <Filter
                            crawlers={crawlers}
                            onApplyFilter={onApplyFilter}
                        />
                        <Button 
                            variant="contained"
                            className="disabled:cursor-not-allowed"
                            disabled={selectedJobs.filter(job => !job.isImported).length === 0 || loading} 
                            onClick={() => onImportAllSelected()}
                        >
                            <Typography variant="button1">
                                Import Selected Jobs
                            </Typography>
                        </Button>
                        <Button 
                            variant="contained"
                            className="disabled:cursor-not-allowed"
                            disabled={selectedJobs.filter(job => job.isImported).length === 0 || loading} 
                            onClick={() => onSendCampaignAllSelected()}
                        >
                            <Typography variant="button1">
                                Send Campaign Selected Jobs
                            </Typography>
                        </Button>
                        <Button 
                            variant="contained"
                            className="disabled:cursor-not-allowed"
                            disabled={loading} 
                            onClick={() => onSyncContacts()}
                        >
                            <Typography variant="button1">
                                Sync Contacts to SendGrid
                            </Typography>
                        </Button>
                    </div>
                </div>
            </div>

            <div className="max-sm:pt-3 pt-8">
                <AdvancedTable
                    minCellWidth={100}
                    data={tableData}
                    fields={tableFields}
                    totalCount={totalJobs}
                    currentPage={currentPage}
                    recordsPerpage={recordsPerPage}
                    totalPage={Math.ceil(totalJobs / recordsPerPage)}
                    onSetPage={onSetTablePage}
                    onSelectRow={onSelectRow}
                    triggerResetSelectedRows={triggerResetSelectedRows}
                    class_name="custom-table table items-center"
                />
            </div>
        </div>
    );
};

export default ScrapeHero;