import React, { Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/24/outline';
import Typography from "@/components/baseComponents/Typography";
import Scrollbars from 'react-custom-scrollbars';
import { ListingAdvancedSearchDto } from '@/shared/interfaces/interfaces';
import Locations from './components/Locations';
import PropertyDetails from './components/PropertyDetails';
import ListingDetails from './components/ListingDetails';
import Expenses from './components/Expenses';
import Others from './components/Others';
import { Button } from '@/components/baseComponents/Button';

const tabs = [
	{ name: 'All' },
	{ name: 'Locations' },
	{ name: 'Property' },
	{ name: 'Listing' },
	{ name: 'Expenses' },
	{ name: 'Other' },
];

// function classNames(...classes: any) {
// 	return classes.filter(Boolean).join(' ')
// }

type IProps = {
	open: boolean
	data?: any
	value?: ListingAdvancedSearchDto
	setValue?: Function
	openDrawer: Function
	handleAdvancedSearch?: Function
	keyword?: string
	sortType?: string
	sortField?: string
	handleClearQueries: () => void
	handleSearch: () => void
}

const AdvancedSearch = (props: IProps) => {
	const [currentTab, setCurrentTab] = React.useState<string>('All');
	const [showResetButton, setShowResetButton] = React.useState<boolean>(true)

	const handleSelectChange = (value: any, name: string, isMulti?: boolean) => {
		setShowResetButton(true)
		if (props.value && props.setValue) {
			if (isMulti) {
				var tmpValue = value.map((item: any) => {
					return item.value;
				});
				props.setValue({
					...props.value,
					[name]: tmpValue
				});
			} else {
				var tmpValue = value.value;
				props.setValue({
					...props.value,
					[name]: tmpValue
				});
			}
		}
	};


	const handleInputChange = (value: any, name: string) => {
		setShowResetButton(true)
		if (props.value && props.setValue) {
			props.setValue({
				...props.value,
				[name]: value
			});
		}
	}

	return (
		<Transition.Root show={props.open} as={Fragment}>
			<Dialog
				as="div"
				className="relative z-30 "
				onClose={() => {
					props.openDrawer(false);
				}
				}
			>
				<div className="fixed inset-0 overflow-hidden"
					style={{ backgroundColor: 'rgba(0,0,0,0.2)' }}
				>
					<div className="absolute inset-0 overflow-hidden">
						<div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full md:pl-10 pl-0 sm:pl-16">
							<Transition.Child
								as={Fragment}
								enter="transform transition ease-in-out duration-500 sm:duration-300"
								enterFrom="translate-x-full"
								enterTo="translate-x-0"
								leave="transform transition ease-in-out duration-500 sm:duration-300"
								leaveFrom="translate-x-0"
								leaveTo="translate-x-full"
							>
								<Dialog.Panel className="pointer-events-auto h-screen w-full min-w-[414px] sm:w-[414px] md:w-[600px] overflow-y-auto hide-scrollbar ">
									<div className="flex bg-white w-full h-screen flex-col gap-3  overflow-y-auto hide-scrollbar">
										<div className='fixed w-full bg-white z-30'>
											<div className="w-full bg-white">
												<div className="flex flex-col w-full gap-3 px-8 pt-6 border-b shadow">
													<div className="flex items-center">
														<div className="flex gap-4 flex-col sm:flex-row w-full justify-between mr-1">
															<Dialog.Title className="flex items-start py-1">
																<Typography color="primary" className='my-0 text-[28px] font-bold'>Advanced Search</Typography>
															</Dialog.Title>
															<div className="flex items-center gap-3">
																<div className="flex gap-4 w-full justify-start sm:justify-end md:mt-0">
																	{
																		showResetButton && (
																			<Button
																				onClick={() => props.handleClearQueries()}
																				variant="text"
																				className={`bg-white border border-1 border-[#E5E7EB] px-4`}
																			>
																				<Typography variant="button1" color="primary">Clear</Typography>
																			</Button>
																		)
																	}
																	<Button onClick={() => props.handleSearch()}>
																		<Typography variant="button1">
																			Search
																		</Typography>
																	</Button>
																</div>
															</div>
														</div>
														<Button variant="icon" className="group" onClick={() => props.openDrawer(false)}>
															<XMarkIcon className="h-6 w-6 text-black group-hover:[&>path]:!stroke-indigo-600" aria-hidden="true" />
														</Button>
													</div>
													<div className='flex w-full mt-4'>
														<nav className="-mb-px flex w-full  py-3" aria-label="Tabs">
															<div className="overflow-x-auto">
																<div className="overflow-x-scroll">
																	<div className='flex justify-between items-end w-full gap-3'>
																		{tabs.map((tab, index) => (
																			<div
																				key={index}
																				className={`cursor-pointer rounded-lg px-4 py-[6px] hover:bg-gray-200 ${tab.name === currentTab ? 'bg-gray-200' : ''}`}
																				onClick={() => setCurrentTab(tab.name)}
																			>
																				<Typography variant="body">{tab.name}</Typography>
																			</div>
																		))}
																	</div>
																</div>
															</div>
														</nav>
													</div>
												</div>
											</div>
										</div>
										<Scrollbars autoHide>
											<div className="flex h-full flex-col bg-white sm:mt-[132px] mt-[180px] ">

												<div className="flex flex-col gap-8 py-8 px-8">
													{(currentTab === 'All' || currentTab === 'Locations') && (
														<Locations
															value={props.value}
															setValue={props.setValue}
														/>
													)}
													{(currentTab === 'All' || currentTab === 'Property') && (
														<PropertyDetails
															value={props.value}
															setValue={props.setValue}
															handleInputChange={handleInputChange}
															handleSelectChange={handleSelectChange}
														/>
													)}
													{(currentTab === 'All' || currentTab === 'Listing') && (
														<ListingDetails
															value={props.value}
															setValue={props.setValue}
															handleInputChange={handleInputChange}
															handleSelectChange={handleSelectChange}
														/>
													)}
													{(currentTab === 'All' || currentTab === 'Expenses') && (
														<Expenses
															value={props.value}
															setValue={props.setValue}
															handleInputChange={handleInputChange}
														/>
													)}
													{(currentTab === 'All' || currentTab === 'Other') && (
														<Others
															value={props.value}
															setValue={props.setValue}
															handleInputChange={handleInputChange}
															handleSelectChange={handleSelectChange}
														/>
													)}
												</div>
											</div>
										</Scrollbars>
									</div>
								</Dialog.Panel>
							</Transition.Child>
						</div>
					</div>
				</div>
			</Dialog>
		</Transition.Root>
	)
}

export default AdvancedSearch