type Props = {
    width: number;
    height: number;
    stroke?: string;
    className?: string
};

const IconTransactions = (props: Props) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" {...props}>
            <path d="M16 22H18C18.5304 22 19.0391 21.7893 19.4142 21.4142C19.7893 21.0391 20 20.5304 20 20V7L15 2H6C5.46957 2 4.96086 2.21071 4.58579 2.58579C4.21071 2.96086 4 3.46957 4 4V7" stroke="#A3A9BA" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M14 2V6C14 6.53043 14.2107 7.03914 14.5858 7.41421C14.9609 7.78929 15.4696 8 16 8H20" stroke="#A3A9BA" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M3.99998 11.5C3.38898 12.0456 2.89577 12.7102 2.55053 13.4531C2.20529 14.1959 2.0153 15.0014 1.99218 15.8203C1.96905 16.6391 2.11329 17.4541 2.41606 18.2152C2.71883 18.9764 3.17376 19.6678 3.75299 20.247C4.33223 20.8262 5.02359 21.2812 5.78475 21.5839C6.54591 21.8867 7.36086 22.0309 8.1797 22.0078C8.99854 21.9847 9.80404 21.7947 10.5469 21.4495C11.2898 21.1042 11.9544 20.611 12.5 20" stroke="#A3A9BA" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M14 16C14 12.7 11.3 10 8 10V16H14Z" stroke="#A3A9BA" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    );
};

export default IconTransactions;
