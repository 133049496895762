type Props = {
  width: number;
  height: number;
  stroke?: string;
  className?: string;
};

const IconHeating = (props: Props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 20 21"
      fill="none"
      {...props}
    >
      <path
        d="M9.16667 6.875C10.8333 4.375 7.50001 4.375 9.16667 1.875"
        stroke="#6B7280"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.9167 6.875C14.5833 4.375 11.25 4.375 12.9167 1.875"
        stroke="#6B7280"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5 8.53906H5.00833"
        stroke="#6B7280"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5 11.875H5.00833"
        stroke="#6B7280"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.33325 13.5404V10.207"
        stroke="#6B7280"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.6667 13.5404V10.207"
        stroke="#6B7280"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15 13.5404V10.207"
        stroke="#6B7280"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.6667 5.20703C17.1088 5.20703 17.5327 5.38263 17.8453 5.69519C18.1578 6.00775 18.3334 6.43167 18.3334 6.8737V15.207C18.3334 15.6491 18.1578 16.073 17.8453 16.3855C17.5327 16.6981 17.1088 16.8737 16.6667 16.8737H3.33341C2.89139 16.8737 2.46746 16.6981 2.1549 16.3855C1.84234 16.073 1.66675 15.6491 1.66675 15.207V6.8737C1.66675 6.43167 1.84234 6.00775 2.1549 5.69519C2.46746 5.38263 2.89139 5.20703 3.33341 5.20703H5.83341"
        stroke="#6B7280"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.16675 16.875V18.5417"
        stroke="#6B7280"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.8333 16.875V18.5417"
        stroke="#6B7280"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default IconHeating;
