import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import { useNavigate, useOutletContext } from "react-router-dom";
import Typography from "@/components/baseComponents/Typography";

import { Button } from "@/components/baseComponents/Button";
import Select from "@/components/baseComponents/Select";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { useAppSelector } from "@/redux/hooks";
import {
  getUser,
} from "@/redux/user/userSlice";
import {
  ListingAdvancedSearchDto,
} from "@/shared/interfaces/interfaces";
import AdvancedSearch from "./AdvancedSearch";
import { getTime } from "date-fns";
import Expansive, { ExpansiveRef } from "./Expansive";
import Company from "./Company";
import Team from "./Team";
import Saved from "./Saved";


const showTypeList = [
  {
    value: 'activeOnly',
    label: 'Active only'
  },
  {
    value: '!activeOnly',
    label: 'Include Unavailable'
  }
]


const tabsDropdown = [
  { label: "Expansive Search", value: "/listings" },
  { label: "Company Listings", value: "/listings/company" },
  // { label: "Team Listings", value: "/listings/team" },
  { label: "Bookmarked", value: "/listings/saved" },
]

const SearchListings = () => {
  const navigate = useNavigate();
  const user = useAppSelector(getUser);

  const [openView, setOpenView] = React.useState<boolean>(false);

  // const [change, setChange] = React.useState<boolean>(false)

  const [openConfirm, setOpenConfirm] = React.useState<boolean>(false);
  const [open, setOpen] = React.useState<boolean>(false); // advanced serach drawer
  const [isSearched, setIsSearched] = React.useState<boolean>(false);

  const [recordsPerPage, setRecordsPerPage] = React.useState<number>(25);
  const [currentPage, setCurrentPage] = React.useState<number>(1);

  const [activeOnly, setActiveOnly] = React.useState<boolean>(true);
  const initialQuery: ListingAdvancedSearchDto = {
    user: user._id,
    address: "",
    additionalAddresses: [""],
    propertyType: "",
    propertySubType: "",
    propertyBedrooms: "",
    propertyBathrooms: "",
    listingListPriceMin: "",
    listingListPriceMax: "",
    companyOnly: false,
    teamOnly: false,
    recordsPerPage: recordsPerPage,
    currentPage: currentPage,
    status: activeOnly ? "Active" : "",
  };
  const [selectedTab, setSelectedTab] = React.useState(tabsDropdown[0])
  const [value, setValue] =
    React.useState<ListingAdvancedSearchDto>(initialQuery);
  const outletData = useOutletContext() as Array<any>;
  const isOpen = outletData[1] as boolean;
  const expansiveRef = useRef<ExpansiveRef>(null);
  const targetRef = useRef<HTMLDivElement>(null);
  const [width, setWidth] = useState<number>(0)
  const handleSearch = () => {
    if (expansiveRef.current) {
      expansiveRef.current.handleSearch();
    }
  };

  const handleClearQueries = () => {
    if (expansiveRef.current) {
      expansiveRef.current.handleClearQueries();
    }
  };


  const updateDimensions = () => {
    if (targetRef.current) {
      const { width } = targetRef.current.getBoundingClientRect();
      setWidth(width);
    }
  };

  useEffect(() => {
    setTimeout(() => {
      updateDimensions();
    }, 500);
  }, [isOpen])

  useLayoutEffect(() => {
    updateDimensions();
    const handleResize = () => {
      updateDimensions();
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  return (
    <div className="px-6 pt-10 pb-[30vh]" ref={targetRef}>
      <AdvancedSearch
        open={open}
        openDrawer={setOpen}
        value={value}
        setValue={setValue}
        handleClearQueries={handleClearQueries}
        handleSearch={handleSearch}
      />
      {openConfirm && (
        <div
          className="bg-[#00000040] w-full h-screen flex items-center justify-center fixed top-0 left-0"
          onClick={() => setOpenConfirm(false)}
        >
          <div className="rounded-lg max-w-[435px] w-full bg-white p-3">
            <div>
              <div className="flex justify-end w-full text-secondary hover:text-primary cursor-pointer">
                <XMarkIcon
                  className="h-6 w-6"
                  aria-hidden="true"
                  onClick={() => setOpenConfirm(false)}
                />
              </div>
              <div className="w-full flex justify-center mt-[10px]">
                <Typography variant="button2">
                  Are you sure you want to archive this listing?
                </Typography>
              </div>
              <div className="flex justify-center mt-[20px] mb-[10px]">
                <div className="bg-[#C18193] hover:bg-[#B17183] mr-4 w-[146px] h-[40px] rounded flex items-center justify-center cursor-pointer">
                  <Typography variant="button2" className="text-[#B32F43]">
                    Archive Listing
                  </Typography>
                </div>
                <div className="bg-[#B5E2C4] hover:bg-[#B17183] w-[146px] h-[40px] rounded flex items-center justify-center cursor-pointer">
                  <Typography variant="button2" className="text-[#6DA172]">
                    Keep Listing
                  </Typography>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {/* header */}
      <div className={`flex  gap-2 ${isSearched ? 'flex-col md:flex-row' : ''}`}>
        <div className="flex flex-wrap w-full gap-y-2 justify-between items-center">
          <Select
            className={`cursor-pointer !px-0 !border-0 !bg-transparent color-primary`}
            placeholder="Select"
            isSearchable={false}
            options={tabsDropdown}
            name={`tab-${getTime(new Date())}`}
            value={selectedTab}
            onChange={(value) => {
              setSelectedTab(value);
              navigate(value.value);
            }}
            labelClassName="text-[22px] !px-0 pl-1 font-bold"
            valueClassName="!px-0"
            iconClassName="w-7 h-7 p-1"
          />
          <div className="flex gap-3">
            {isSearched && (
              <>
                <div className={` flex justify-end items-center gap-1 ${width < 700 ? 'hidden' : 'md:block md:flex lg:block lg:flex hidden'}`}>
                  <div className="flex">
                    <Typography className="text-[#9CA3AF]">Show:</Typography>
                  </div>
                  <div className="flex gap-2">
                    <div
                      className={`flex items-center gap-2 px-[10px] py-[6px] cursor-pointer border-[#D1D5DB]  border-1 border rounded ${activeOnly ? 'bg-[#F4F4F5]' : '#F9FAFB'}`}
                      onClick={() => setActiveOnly(true)}
                    >
                      <Typography
                        variant="caption"
                        className={`${activeOnly ? "text-primary" : "text-[#6B7280]"
                          }`}
                      >
                        Active Only
                      </Typography>
                    </div>
                    <div
                      className={`"flex items-center gap-1 px-[10px] py-[6px] cursor-pointer border-[#D1D5DB] border-1 border rounded ${!activeOnly ? 'bg-[#F4F4F5]' : '#F9FAFB'}`}
                      onClick={() => setActiveOnly(false)}
                    >
                      <Typography
                        variant="caption"
                        className={`${!activeOnly ? "text-primary" : "text-[#6B7280]"
                          }`}
                      >
                        Include Unavailable
                      </Typography>
                    </div>
                  </div>
                </div>
                <div className={`flex ${width < 700? '' : 'md:hidden'}`}>
                  <Select
                    className={`cursor-pointer !px-0 !border-[#D1D5DB] !border-1 border rounded !bg-[#F4F4F5]`}
                    placeholder="Select"
                    options={showTypeList}
                    name={`showType-${getTime(new Date())}`}
                    value={activeOnly ? showTypeList[0] : showTypeList[1]}
                    onChange={(value) => {
                      if (value.value == "activeOnly") {
                        setActiveOnly(true)
                      } else {
                        setActiveOnly(false)
                      }
                    }}
                    labelClassName="text-[14px] !px-0"
                    valueClassName="!px-0 ml-2"
                  />
                </div>
              </>
            )}
          </div>
        </div>
        <Button className="whitespace-nowrap" onClick={() => setOpen(true)}>
          <Typography variant="button1">Advanced Search</Typography>
        </Button>
      </div>
      {/* content */}
      {selectedTab.value == "/listings" && <Expansive
        value={value}
        setValue={setValue}
        isSearched={isSearched}
        setIsSearched={setIsSearched}
        activeOnly={activeOnly}
        initialQuery={initialQuery}
        user={user}
        setOpenConfirm={setOpenConfirm}
        open={open}
        setOpen={setOpen}
        openView={openView}
        setOpenView={setOpenView}
        recordsPerPage={recordsPerPage}
        setRecordsPerPage={setRecordsPerPage}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        width={width}
        ref={expansiveRef}
      />}
      {selectedTab.value == '/listings/company' && <Company open={open} setOpen={setOpen} />}
      {selectedTab.value == '/listings/team' && <Team />}
      {selectedTab.value == '/listings/saved' && <Saved />}
    </div>
  );
};

export default SearchListings;
