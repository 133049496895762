// React import
import { useState } from "react";

// Router import
import { useNavigate } from "react-router-dom";

// Component imports
import Typography from "@/components/baseComponents/Typography";
import TextField from "@/components/baseComponents/TextField";
import { Button } from "@/components/baseComponents/Button";

// Redux imports
import { useAppDispatch } from "@/redux/hooks";
import { signIn } from "@/redux/user/userSlice";

// Interface imports
import { LogInDto } from "@/shared/interfaces/interfaces";

// Service imports
import { notify } from "@/shared/services/notify";

// Config imports
import { AUTH } from "@/shared/config/constants";

// Cookie imports
import { useCookies } from "react-cookie";

// Image imports
import Logo from "@/assets/images/logo_black.svg";
import IconWrapper from "@/components/baseComponents/IconWrapper";

const Login = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [password, setPassword] = useState<string>("");
  const [showPassword, setShowPassword] = useState<boolean>(false);

  const [cookies, setCookie] = useCookies([
    "rememberMyEmailAddress",
    "rememberedEmailAddress",
  ]);
  const [email, setEmail] = useState<string>(
    (cookies.rememberMyEmailAddress &&
      cookies.rememberedEmailAddress &&
      cookies.rememberedEmailAddress) ||
      ""
  );
  const [rememberMyEmailAddress, setRememberMyEmailAddress] = useState<boolean>(
    cookies.rememberMyEmailAddress || false
  );
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const goSignup = () => {
    navigate("/auth/register");
  };
  const goFogotPassword = () => {
    navigate("/auth/forgot-password");
  };

  const logIn = () => {
    if (email !== "" && password !== "") {
      setIsLoading(true);

      let data: LogInDto = {
        email: email.toLocaleLowerCase(),
        password: password,
      };
      dispatch(signIn(data)).then((res) => {
        try {
          if (res.payload.success) {
            setCookie("rememberMyEmailAddress", rememberMyEmailAddress, {
              path: "/",
              expires: new Date(Date.now() + 24 * 60 * 60 * 1000),
            });

            if (rememberMyEmailAddress) {
              setCookie("rememberedEmailAddress", email, {
                path: "/",
                expires: new Date(Date.now() + 24 * 60 * 60 * 1000),
              });
            } else {
              setCookie("rememberedEmailAddress", "", {
                path: "/",
                expires: new Date(Date.now() + 24 * 60 * 60 * 1000),
              });
            }
            const userType: AUTH = res.payload.user.userType as AUTH;
            const requestURL =
              window.innerWidth <= 640
                ? "/dashboard"
                : window.localStorage.getItem("requestURL");
            const urlItems = requestURL?.split("/");
            window.localStorage.removeItem("requestURL");
            if (
              requestURL &&
              urlItems &&
              urlItems.length > 1 &&
              ((urlItems[1] === "admin" && userType === AUTH.ADMIN) ||
                urlItems[1] !== "admin")
            ) {
              navigate(requestURL);
            } else {
              if (userType === AUTH.ADMIN) {
                navigate("/admin");
              } else {
                navigate("/");
              }
            }
          }
          notify(res.payload.success, res.payload.message);
        } catch (e) {
          console.log(e);
          notify(false, "Something went wrong");
        }

        setTimeout(() => {
          setIsLoading(false);
        }, 500);
      });
    }
  };

  return (
    <div className="flex items-start py-20 px-4 sm:px-6 bg-white min-h-screen w-full select-none">
      <div className="w-full flex flex-col gap-4">
        <div className=" self-center">
          <img
            src={Logo}
            alt="logo"
            className="  max-w-[197px] sm:max-w-[263px] flex"
          />
        </div>
        <div className="flex justify-center">
          <div className=" pt-5 sm:max-w-[600px] w-full space-y-2">
            <div className=" space-y-2">
              <Typography variant="h1" className="text-center leading-[141%]">
                Sign In
              </Typography>
              <Typography
                variant="body"
                color="indigo"
                className="text-button-primary hover:text-button-primary-hover cursor-pointer mt-[10px] text-center mb-[20px]"
                onClick={() => goSignup()}
              >
                Don't have an account? Sign up.
              </Typography>
            </div>

            <form
              className="space-y-4"
              onSubmit={(event) => {
                event.preventDefault();
              }}
            >
              <div className=" py-3 space-y-2">
                <Typography variant="caption" className="text-secondary ">
                  Email Address
                </Typography>
                <TextField
                  className="w-full"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
                <label className="flex items-center">
                  <input
                    tabIndex={-1}
                    type="checkbox"
                    checked={rememberMyEmailAddress}
                    className="rounded border-gray-300 border-2 text-indigo-600 focus:ring-indigo-600 mr-2"
                    onChange={() => setRememberMyEmailAddress(!rememberMyEmailAddress)}
                  />
                  <Typography variant="medium-text" color="primary">
                    Remember my Email Address
                  </Typography>
                </label>
              </div>
              <div className=" py-3 space-y-2">
                <Typography variant="caption" className="text-secondary ">
                  Password
                </Typography>
                <TextField
                  className="w-full"
                  type={showPassword ? 'text' : 'password'}
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  endAdornment={
                    <div className="cursor-pointer" onClick={() => setShowPassword(!showPassword)} tabIndex={-1}>
                      <IconWrapper name="eye" className={showPassword ? `[&>svg>path]:!stroke-indigo-600` : ``} width={20} />
                    </div>
                  }
                />
                <Typography
                  variant="caption"
                  className="text-secondary  text-[10px] cursor-pointer hover:text-primary"
                  onClick={() => goFogotPassword()}
                >
                  Forgot your password?
                </Typography>
              </div>
              <Button
                className={`w-full text-15 ${
                  isLoading
                    ? `!border-indigo-200 !bg-indigo-300 pointer-events-none`
                    : ``
                }`}
                onClick={() => logIn()}
              >
                Sign In
              </Button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
