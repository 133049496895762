import Typography from "@/components/baseComponents/Typography";

type IProps = {
  data: any;
};

const InfoSection = (props: IProps) => {
  return (
    <div className="">
      <div className="bg-[#F9FAFB] p-[12px] rounded-[5px] space-y-[20px]">
        <div className="space-y-[10px]">
          <div className="flex flex-col pb-[10px]">
            <Typography variant="caption" color="secondary">
              Showing Instructions
            </Typography>
            <Typography variant="body" color="primary">
              {props.data?.listing?.showingInstuction
                ? props.data.listing.showingInstuction
                : "These are showing instructions...."}
            </Typography>
          </div>
          <div className="flex flex-col py-[10px]">
            <Typography variant="caption" color="secondary">
              Showing Remarks
            </Typography>
            <Typography variant="body" color="primary">
              {props.data.listing.showingRemarks
                ? props.data.listing.showingRemarks
                : " These are showing remarks...."}
            </Typography>
          </div>
        </div>
        <div className="grid grid-cols-2 gap-3">
          <div className="flex flex-col gap-1 py-[10px]">
            <Typography variant="caption" color="secondary">
              Lockbox or Keypad
            </Typography>
            <Typography variant="body" color="primary" className="truncate">
              {props.data?.listing?.showingLockboxOrKeypad || "None"}
            </Typography>
          </div>
          <div className="flex flex-col gap-1 py-[10px]">
            <Typography variant="caption" color="secondary">
              Access Code
            </Typography>
            <Typography variant="body" color="primary" className="truncate">
              {props.data?.listing?.showingAccessCode || "None"}
            </Typography>
          </div>
          <div className="flex flex-col gap-1 py-[10px]">
            <Typography variant="caption" color="secondary">
              Occupancy Status
            </Typography>
            <Typography variant="body" color="primary" className="truncate">
              {props.data?.listing?.showingOccupanyStatus || "None"}
            </Typography>
          </div>
          <div className="flex flex-col gap-1 py-[10px]">
            <Typography variant="caption" color="secondary">
              Require Agency Disclosure
            </Typography>
            <Typography variant="body" color="primary" className="truncate">
              {props.data?.listing?.showingRequireAgencyDisclosure || "None"}
            </Typography>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InfoSection;
