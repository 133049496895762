type Props = {
    width: number;
    height: number;
    stroke?: string;
    innerstroke?: string;
    className?: string;
};

const IconVerifiedFilled = (props: Props) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 33 32" {...props} fill="none" >
            <path
                d="M24.3695 6.66598C24.6441 7.33001 25.1711 7.85783 25.8347 8.1334L28.1616 9.09727C28.8257 9.37235 29.3533 9.89996 29.6283 10.564C29.9034 11.2281 29.9034 11.9743 29.6283 12.6384L28.6652 14.9637C28.39 15.6281 28.3896 16.375 28.6661 17.0391L29.6275 19.3637C29.7639 19.6926 29.8341 20.0452 29.8342 20.4012C29.8342 20.7573 29.7641 21.1099 29.6279 21.4388C29.4916 21.7678 29.2919 22.0667 29.0401 22.3184C28.7883 22.5701 28.4893 22.7698 28.1603 22.9059L25.8351 23.8691C25.1711 24.1437 24.6433 24.6707 24.3677 25.3343L23.4039 27.6613C23.1288 28.3254 22.6012 28.853 21.9372 29.1281C21.2731 29.4032 20.527 29.4032 19.8629 29.1281L17.5377 28.1649C16.8736 27.8905 16.1277 27.8911 15.4641 28.1665L13.1372 29.129C12.4735 29.4034 11.728 29.4032 11.0645 29.1283C10.4009 28.8535 9.87364 28.3265 9.59839 27.6631L8.63426 25.3354C8.3597 24.6714 7.83269 24.1436 7.1691 23.868L4.84219 22.9041C4.17841 22.6292 3.65098 22.1019 3.37581 21.4382C3.10065 20.7745 3.10027 20.0287 3.37477 19.3647L4.33792 17.0394C4.61231 16.3753 4.61175 15.6294 4.33636 14.9657L3.3746 12.637C3.23824 12.3081 3.16803 11.9555 3.16797 11.5995C3.16791 11.2434 3.23801 10.8908 3.37427 10.5619C3.51052 10.2329 3.71025 9.93404 3.96206 9.68231C4.21386 9.43057 4.5128 9.23093 4.84179 9.09477L7.16703 8.13159C7.83046 7.85726 8.35795 7.3309 8.63371 6.66804L9.59754 4.34105C9.87261 3.67696 10.4002 3.14935 11.0643 2.87427C11.7283 2.5992 12.4745 2.5992 13.1385 2.87427L15.4638 3.83746C16.1278 4.11185 16.8737 4.11129 17.5374 3.8359L19.8653 2.87577C20.5292 2.60085 21.2752 2.6009 21.9391 2.87592C22.6031 3.15094 23.1306 3.67841 23.4057 4.34232L24.3698 6.67001L24.3695 6.66598Z"
                fill={props.innerstroke ?? "#4F46E5"}
            />
            <path
                d="M12.5013 16.0013L15.168 18.668L21.168 12.668M24.3695 6.66598C24.6441 7.33001 25.1711 7.85783 25.8347 8.1334L28.1616 9.09727C28.8257 9.37235 29.3533 9.89996 29.6283 10.564C29.9034 11.2281 29.9034 11.9743 29.6283 12.6384L28.6652 14.9637C28.39 15.6281 28.3896 16.375 28.6661 17.0391L29.6275 19.3637C29.7639 19.6926 29.8341 20.0452 29.8342 20.4012C29.8342 20.7573 29.7641 21.1099 29.6279 21.4388C29.4916 21.7678 29.2919 22.0667 29.0401 22.3184C28.7883 22.5701 28.4893 22.7698 28.1603 22.9059L25.8351 23.8691C25.1711 24.1437 24.6433 24.6707 24.3677 25.3343L23.4039 27.6613C23.1288 28.3254 22.6012 28.853 21.9372 29.1281C21.2731 29.4032 20.527 29.4032 19.8629 29.1281L17.5377 28.1649C16.8736 27.8905 16.1277 27.8911 15.4641 28.1665L13.1372 29.129C12.4735 29.4034 11.728 29.4032 11.0645 29.1283C10.4009 28.8535 9.87364 28.3265 9.59839 27.6631L8.63426 25.3354C8.3597 24.6714 7.83269 24.1436 7.1691 23.868L4.84219 22.9041C4.17841 22.6292 3.65098 22.1019 3.37581 21.4382C3.10065 20.7745 3.10027 20.0287 3.37477 19.3647L4.33792 17.0394C4.61231 16.3753 4.61175 15.6294 4.33636 14.9657L3.3746 12.637C3.23824 12.3081 3.16803 11.9555 3.16797 11.5995C3.16791 11.2434 3.23801 10.8908 3.37427 10.5619C3.51052 10.2329 3.71025 9.93404 3.96206 9.68231C4.21386 9.43057 4.5128 9.23093 4.84179 9.09477L7.16703 8.13159C7.83046 7.85726 8.35795 7.3309 8.63371 6.66804L9.59754 4.34105C9.87261 3.67696 10.4002 3.14935 11.0643 2.87427C11.7283 2.5992 12.4745 2.5992 13.1385 2.87427L15.4638 3.83746C16.1278 4.11185 16.8737 4.11129 17.5374 3.8359L19.8653 2.87577C20.5292 2.60085 21.2752 2.6009 21.9391 2.87592C22.6031 3.15094 23.1306 3.67841 23.4057 4.34232L24.3698 6.67001L24.3695 6.66598Z"
                stroke={props.stroke ?? "white"}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};

export default IconVerifiedFilled;