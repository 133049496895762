// React and Router imports
import React from "react";
import { useLocation, useNavigate } from "react-router-dom";

// Component imports
import AdvancedTable from "@/components/baseComponents/AdvancedTable";
import Typography from "@/components/baseComponents/Typography";
import Filter from "@/components/mainComponents/Filter";
import SortAdvanced from "@/components/mainComponents/SortAdvanced";
import ActionDrawer from "./ActionDrawer";
import ViewDrawer from "./ViewDrawer";

// Redux imports
import {
  getUser,
  updateMyInfo,
  updateMyVendorInfo,
} from "@/redux/user/userSlice";
import { getAgents, getAgentsByUserId } from "@/redux/agent/agentSlice";
import { useAppSelector, useAppDispatch } from "@/redux/hooks";

// Interface imports
import {
  GetAgentsDto,
  UpdateMyInfoDto,
  IVendor,
  UpdateMyVendorInfoDto,
  IMyInfo,
} from "@/shared/interfaces/interfaces";

// Service imports
import { notify } from "@/shared/services/notify";

// Icon imports
import { MdEdit, MdDelete } from "react-icons/md";
import { XMarkIcon } from "@heroicons/react/24/outline";

// Image imports
import icon_graph from "@/assets/icons/graph.png";
import icon_star_connected from "@/assets/icons/star-in-contact.png";
import icon_star_unconnected from "@/assets/icons/star-uncontacted.png";
import icon_star_priority from "@/assets/icons/star-high-priority.png";
import defaultAvatar from "@/assets/images/default_avatar.jpg";
import emptyImage from "@/assets/images/empty.png";
import defaultCoverPhoto from "@/assets/images/default_cover_image.jpg";

// Base component imports
import { Button } from "@/components/baseComponents/Button";

const tabs = [
  { name: "All", value: "all" },
  { name: "Favorite", value: "favorite" },
  { name: "My Brokerage", value: "company" },
  { name: "My Profile", value: "myprofile" },
];

const TableFields = [
  {
    name: "",
    type: "custom_image",
    slug: "avatarURL",
    class_name: "py-[16px] pr-2",
    image_size: "w-[3.125rem] h-[3.125rem] max-w-none",
  },
  {
    name: "Status",
    type: "custom_image",
    slug: "iconGraph",
    class_name: "py-[16px] px-2",
    image_size: "w-[14px] m-auto",
  },
  {
    name: "",
    type: "favoriteAction",
    slug: "favoriteAction",
    class_name: "py-[16px] px-2 pr-4",
  },
  {
    name: "Name",
    type: "text",
    slug: "name",
    class_name: "py-[16px] text-left px-2",
  },
  {
    name: "Phone",
    type: "text",
    slug: "mobileNumber",
    class_name: "py-[16px] text-left px-2",
  },
  {
    name: "Email",
    type: "text",
    slug: "contactEmail",
    class_name: "py-[16px] text-left px-2",
  },
  {
    name: "Member ID",
    type: "text",
    slug: "memberID",
    class_name: "py-[16px] text-left px-2",
  },
  {
    name: "Company",
    type: "text",
    slug: "companyName",
    class_name: "py-[16px] text-left px-2 w-10",
  },
];

const SortFieldOptions = [
  { value: "firstName", label: "Name" },
  { value: "mobileNumber", label: "Phone" },
  { value: "contactEmail", label: "Email" },
  { value: "memberID", label: "Member ID" },
];

function classNames(...classes: any) {
  return classes.filter(Boolean).join(" ");
}

const Agent = () => {
  // Redux hooks
  const dispatch = useAppDispatch();
  const agents = useAppSelector(getAgents);
  const user = useAppSelector(getUser);

  // User info
  const userInfo = user.isVendor ? user.vendor : user.agent;

  // Navigation hooks
  const navigate = useNavigate();
  const location = useLocation();

  // Tab values
  const tabValue = location.pathname.split("/")[2];
  const curTab = tabs.filter((tab) => tab.value === tabValue);
  let currentTab = "All";

  if (curTab && curTab.length > 0) {
    currentTab = curTab[0].name;
  }

  const initialSearchData: IMyInfo = {
    // General Details
    prefix: "",
    firstName: "",
    middleName: "",
    lastName: "",
    mobileNumber: "",
    officeNumber: "",
    contactEmail: "",
    avatarURL: "",

    // License Details
    licenseNumber: "",
    licenseState: "",
    licenseType: "",
    licenseDate: undefined,
    licenseExpiration: undefined,

    //Associations
    localAssociations: [],
    stateAssociations: [],
    mlsAssociations: [],

    // Serviced Areas
    serviceAreas: [],

    // Social Profile
    instagram: "",
    facebook: "",
    tiktok: "",
    linkedin: "",
    youtube: "",

    // Professional Profiles
    zillow: "",
    homes: "",
    realtor: "",
    ratemyagent: "",
  };

  // Drawer states
  const [openActionDrawer, setActionDrawer] = React.useState<boolean>(false);
  const [openViewDrawer, setOpenViewDrawer] = React.useState<boolean>(false);
  const [openConfirm, setOpenConfirm] = React.useState<boolean>(false);

  // Data states
  const [filteredData, setFilteredData] = React.useState<Array<any>>([]);
  const [selectedRowData, setSelectedRowData] =
    React.useState<IMyInfo>(initialSearchData);
  const [searchData, setSearchData] =
    React.useState<IMyInfo>(initialSearchData);

  // Search and sort states
  const [keyword, setKeyword] = React.useState<string>("");
  const [sortType, setSortType] = React.useState<string>("Descending");
  const [sortField, setSortField] = React.useState<string>("memberID");

  // Pagination states
  const [totalCount, setTotalCount] = React.useState<number>(0);
  const [currentPage, setCurrentPage] = React.useState<number>(1);
  const [recordsPerPage, setRecordsPerPage] = React.useState<number>(25);

  const changeTab = (value: string) => {
    navigate("/agents/" + value);
  };

  const prepareData = (agents: any) => {
    if (!agents) {
      return [];
    }

    return agents.map((agent: any) => {
      return {
        id: agent._id ?? "",
        name: (agent.firstName ?? "") + " " + agent.lastName ?? "",
        firstName: agent.firstName ?? "",
        lastName: agent.lastName ?? "",
        avatarURL:
          agent.avatarURL && agent.avatarURL !== ""
            ? agent.avatarURL
            : defaultAvatar,
        coverPhotoURL:
          agent.coverPhotoURL && agent.coverPhotoURL !== ""
            ? agent.coverPhotoURL
            : defaultCoverPhoto,
        description: agent.description ?? "",
        favorite:
          userInfo?.favorites &&
            userInfo.favorites.length > 0 &&
            userInfo.favorites.includes(agent._id)
            ? "favorite"
            : "colleague",
        isActive: "active",
        mobileNumber: agent.mobileNumber ?? "",
        contactEmail: agent.contactEmail ?? "",
        instagram: agent.instagram ?? "",
        facebook: agent.facebook ?? "",
        tiktok: agent.tiktok ?? "",
        linkedin: agent.linkedin ?? "",
        youtube: agent.youtube ?? "",
        zillow: agent.zillow ?? "",
        homes: agent.homes ?? "",
        realtor: agent.realtor ?? "",
        ratemyagent: agent.ratemyagent ?? "",
        memberID: agent.memberID ?? "",
        companyName: agent.company?.businessName ?? "",
        company: agent.company
          ? {
            companyId: agent.company.companyId ?? "",
            businessName: agent.company.businessName ?? "",
            businessAddress1: agent.company.businessAddress1 ?? "",
            businessAddress2: agent.company.businessAddress2 ?? "",
            businessCity: agent.company.businessCity ?? "",
            state: agent.company.state ?? "",
            businessZip: agent.company.businessZip ?? "",
            county: agent.company.county ?? "",
            businessLogo:
              agent.company.businessLogo && agent.company.businessLogo !== ""
                ? agent.company.businessLogo
                : emptyImage,
            isProfile: agent.company.isProfile ?? "",
            principalBrokerFirstName:
              agent.company.principalBrokerFirstName ?? "",
            principalBrokerLastName:
              agent.company.principalBrokerLastName ?? "",
            principalBrokerEmail: agent.company.principalBrokerEmail ?? "",
            principalBrokerPhone: agent.company.principalBrokerPhone ?? "",
          }
          : undefined,
        licenseNumber: agent.licenseNumber ?? "",
        licenseState: agent.licenseState ?? "",
        licenseType: agent.licenseType ?? "",
        licenseDate: agent.licenseDate ?? undefined,
        licenseExpiration: agent.licenseExpiration ?? undefined,
        localAssociations: agent.localAssociations ?? [],
        stateAssociations: agent.stateAssociations ?? [],
        mlsAssociations: agent.mlsAssociations ?? [],
        serviceAreas: agent.serviceAreas ?? [],
      };
    });
  };

  const onSetPage = (value: number) => {
    setCurrentPage(value);
  };

  const onClickRow = (value: any) => {
    if (!openConfirm) {
      setOpenViewDrawer(true);
    }
    setSelectedRowData(value);
  };

  const clickMyProfileTab = () => {
    if (userInfo) {
      setSelectedRowData(userInfo);
      setOpenViewDrawer(true);
    }
  };

  const changeKeyword = (keyword: string) => {
    setKeyword(keyword);
  };

  const changeSortType = (value: string) => {
    setSortType(value);
  };

  const changeSortField = (value: string) => {
    setSortField(value);
  };

  const filterData = () => {
    if (
      searchData.currentTab &&
      searchData.currentTab.tab !== "" &&
      searchData.currentTab.id === ""
    ) {
      setFilteredData([]);
      setCurrentPage(1);
      setTotalCount(0);
      return;
    }

    const data: GetAgentsDto = {
      userId: user._id,
      keyword: keyword,
      sortType: sortType,
      sortField: sortField,
      recordsPerPage: recordsPerPage,
      currentPage: currentPage,
      searchData: searchData,
    };
    dispatch(getAgentsByUserId(data)).then((res) => {
      try {
        setTotalCount(res.payload.totalCount);
      } catch (e) {
        notify(false, "Something went wrong.");
      }
    });
  };

  const editRow = (data: any) => {
    console.log("editdata", data);
  };

  const deleteRow = (data: any) => {
    console.log("deletedata", data);
    setOpenConfirm(true);
  };

  const changeFavorite = (agentId: string, type: string) => {
    if (agentId === undefined || agentId === "") {
      return;
    }

    if (type === "Favorite" && !userInfo?.favorites?.includes(agentId)) {
      const favorites: Array<string> = userInfo?.favorites
        ? [...userInfo.favorites]
        : [];
      favorites.push(agentId);

      if (user.isVendor) {
        const updatedInfo: IVendor = {
          ...userInfo,
          favorites: favorites,
        };

        const updateData: UpdateMyVendorInfoDto = {
          email: user.email,
          data: updatedInfo,
        };
        dispatch(updateMyVendorInfo(updateData)).then((res) => {
          try {
            console.log(res);
            // notify(res.payload.success, res.payload.message)
          } catch (e) {
            notify(false, "Something went wrong.");
          }
        });
      } else {
        const updatedInfo: IMyInfo = {
          ...userInfo,
          favorites: favorites,
        };

        const updateData: UpdateMyInfoDto = {
          email: user.email,
          data: updatedInfo,
        };
        dispatch(updateMyInfo(updateData)).then((res) => {
          try {
            console.log(res);
            // notify(res.payload.success, res.payload.message)
          } catch (e) {
            notify(false, "Something went wrong.");
          }
        });
      }
    } else if (type === "Colleague" && userInfo?.favorites?.includes(agentId)) {
      const favorites: Array<string> = userInfo?.favorites
        ? [...userInfo.favorites]
        : [];
      const index = favorites.indexOf(agentId);
      if (index !== -1) {
        favorites.splice(index, 1);
      }

      const updatedInfo: IMyInfo = {
        ...userInfo,
        favorites: favorites,
      };

      const updateData: UpdateMyInfoDto = {
        email: user.email,
        data: updatedInfo,
      };
      dispatch(updateMyInfo(updateData)).then((res) => {
        try {
          console.log(res);
          // notify(res.payload.success, res.payload.message)
        } catch (e) {
          notify(false, "Something went wrong.");
        }
      });
    }
  };

  const advancedSearch = (values: IMyInfo) => {
    setCurrentPage(1);
    setSearchData({
      ...searchData,
      ...values,
    });
  };

  const makeTableData = (data: Array<object>) => {
    const res: Array<object> = [];
    data !== undefined &&
      data.map((item: any) => {
        const new_item = JSON.parse(JSON.stringify(item));

        new_item.iconGraph = icon_graph;

        if (new_item.favorite === "activeDeal") {
          new_item.iconStar = icon_star_connected;
        } else if (new_item.favorite === "favorite") {
          new_item.iconStar = icon_star_priority;
        } else {
          new_item.iconStar = icon_star_unconnected;
        }

        new_item.favoriteAction = [
          {
            name: "Colleague",
            image: (
              <img
                src={icon_star_unconnected}
                className="w-[1rem] h-[1rem] max-w-none"
              />
            ),
            icon: <MdEdit className="my-3" />,
            color: "black",
          },
          {
            name: "Active Deal",
            image: (
              <img
                src={icon_star_connected}
                className="w-[1rem] h-[1rem] max-w-none"
              />
            ),
            icon: <MdDelete className="text-[#C77E90] mt-1" />,
            color: "#C77E90",
          },
          {
            name: "Favorite",
            image: (
              <img
                src={icon_star_priority}
                className="w-[1rem] h-[1rem] max-w-none"
              />
            ),
            icon: <MdDelete className="text-[#C77E90] mt-1" />,
            color: "#C77E90",
          },
        ];

        new_item.action = [
          { name: "Edit", icon: <MdEdit className="mt-1" />, color: "black" },
          {
            name: "Archive",
            icon: <MdDelete className="text-[#C77E90] mt-1" />,
            color: "#C77E90",
          },
        ];

        res.push(new_item);
      });
    return res;
  };

  React.useEffect(() => {
    setRecordsPerPage(25);
  }, []);

  React.useEffect(() => {
    const dataList = prepareData(agents);
    const tableData = makeTableData(dataList);
    setFilteredData(tableData);
  }, [agents]);

  React.useEffect(() => {
    if (currentTab === "Favorite") {
      setSearchData({
        ...searchData,
        currentTab: {
          tab: "favorites",
          id: userInfo?.favorites ?? [],
        },
      });
    } else {
      const dataList = prepareData(agents);
      const tableData = makeTableData(dataList);
      setFilteredData(tableData);
    }
  }, [user]);

  React.useEffect(() => {
    if (openActionDrawer) {
      setActionDrawer(false);
    }
  }, [filteredData]);

  React.useEffect(() => {
    setCurrentPage(1);
    if (currentTab === "My Profile") {
      clickMyProfileTab();
    } else if (currentTab === "Favorite") {
      setSearchData({
        ...searchData,
        currentTab: {
          tab: "favorites",
          id: userInfo?.favorites ?? [],
        },
      });
    } else if (currentTab === "My Brokerage") {
      setSearchData({
        ...searchData,
        currentTab: {
          tab: "company",
          id: userInfo?.company ?? "",
        },
      });
    } else if (currentTab === "My Team") {
      setSearchData({
        ...searchData,
        currentTab: {
          tab: "team",
          id: userInfo?.team ?? "",
        },
      });
    } else {
      setSearchData({
        ...searchData,
        currentTab: {
          tab: "",
          id: "",
        },
      });
    }
  }, [currentTab, setSearchData]);

  React.useEffect(() => {
    filterData();
  }, [currentPage, searchData, currentTab]);

  React.useEffect(() => {
    if (!openViewDrawer && currentTab === "My Profile") {
      changeTab("all");
    }
  }, [openViewDrawer]);

  return (
    <div>
      {(openActionDrawer || openViewDrawer) && (
        <div className="!bg-[#00000040] h-screen w-full fixed top-0 left-0 z-20"></div>
      )}
      <ActionDrawer
        open={openActionDrawer}
        changeState={setActionDrawer}
        advancedSearch={advancedSearch}
      />
      <ViewDrawer
        open={openViewDrawer}
        changeViewDrawer={setOpenViewDrawer}
        data={selectedRowData}
      />
      {openConfirm && (
        <div
          className="bg-[#00000040] w-full h-screen flex items-center justify-center fixed top-0 left-0"
          onClick={() => setOpenConfirm(false)}
        >
          <div className="rounded-lg max-w-[435px] w-full bg-white p-3">
            <div>
              <div className="flex justify-end w-full text-secondary hover:text-primary cursor-pointer">
                <XMarkIcon
                  className="h-6 w-6"
                  aria-hidden="true"
                  onClick={() => setOpenConfirm(false)}
                />
              </div>
              <div className="w-full flex justify-center mt-[10px]">
                <Typography variant="button2">
                  Are you sure you want to archive this listing?
                </Typography>
              </div>
              <div className="flex justify-center mt-[20px] mb-[10px]">
                <div className="bg-[#C18193] hover:bg-[#B17183] mr-4 w-[146px] h-[40px] rounded flex items-center justify-center cursor-pointer">
                  <Typography variant="button2" className="text-[#B32F43]">
                    Archive Listing
                  </Typography>
                </div>
                <div className="bg-[#B5E2C4] hover:bg-[#B17183] w-[146px] h-[40px] rounded flex items-center justify-center cursor-pointer">
                  <Typography variant="button2" className="text-[#6DA172]">
                    Keep Listing
                  </Typography>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      <div className="px-6 pt-10 lg:pb-24 md:pb-60 sm:pb-60 max-sm:pb-60">
        <div className="flex flex-col gap-2">
          <div className="flex justify-between items-center">
            <Typography variant="h2" color="primary">
              Colleagues
            </Typography>
            <Button onClick={() => setActionDrawer(true)}>
              <Typography variant="button2">Search</Typography>
            </Button>
          </div>
          <nav className="flex justify-between items-center" aria-label="Tabs">
            <div className="flex items-center gap-3">
              {tabs.map((tab, index) => (
                <div
                  key={index}
                  className={classNames(
                    tab.name === currentTab
                      ? "border-indigo-500 text-indigo-600"
                      : "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700",
                    "whitespace-nowrap border-b-2 p-1 cursor-pointer"
                  )}
                  aria-current={tab.name === currentTab ? "page" : undefined}
                  onClick={() => changeTab(tab.value)}
                >
                  <Typography variant="page-menu">{tab.name}</Typography>
                </div>
              ))}
            </div>
            <div className="flex gap-5">
              <Filter
                changeKeyword={changeKeyword}
                keyword={keyword}
                filterLeads={filterData}
              />
              <SortAdvanced
                sortFieldOptions={SortFieldOptions}
                sortType={sortType}
                sortField={sortField}
                changeSortField={changeSortField}
                changeSortType={changeSortType}
                filterData={filterData}
              />
            </div>
          </nav>
        </div>
      </div>
      <div className="px-8 pb-8">
        <AdvancedTable
          minCellWidth={100}
          class_name="table custom-table"
          data={filteredData}
          fields={TableFields}
          onClickRow={onClickRow}
          totalPage={Math.ceil(totalCount / recordsPerPage)}
          totalCount={totalCount}
          currentPage={currentPage}
          recordsPerpage={recordsPerPage}
          onSetPage={onSetPage}
          editRow={editRow}
          deleteRow={deleteRow}
          changeFavorite={changeFavorite}
          emptyStateProps={{
            heading: "No records found",
            content:
              "Encourage everyone to join your Company or build a Team together.",
          }}
        />
      </div>
    </div>
  );
};

export default Agent;
