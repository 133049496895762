import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import type { RootState } from "../store";
import axios from "@/shared/services/axios";
import { IMyListing } from "@/shared/interfaces/interfaces";
import { IDashboardPreferences } from "@/shared/interfaces/dashboardPreferences/IDashboardPreferences";

interface DashboardState {
  dashboardPreferences: IDashboardPreferences;
  listings: Array<IMyListing>;
}

const initialState: DashboardState = {
  dashboardPreferences: {
    locations: [],
    relationships: [], 
    isAllLeads: false, 
    isAllClients: false,
    fetch: 1,
    limit: 25
  } as IDashboardPreferences,
  listings: []
};

export const dashboardSlice = createSlice({
  name: "dashboard",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getEvents.fulfilled, (state, action) => {
      if (action.payload.success) {
        state.dashboardPreferences = action.payload.data.dashboardPreferences;
        state.listings = action.payload.data.listings;
      }
    });
  }
});

export const dashboardActions = dashboardSlice.actions;

export const getListings = (state: RootState) => state.dashboard.listings;

export const getDashboardPreferencesState = (state: RootState) => state.dashboard.dashboardPreferences

export default dashboardSlice.reducer;

export const getEvents = createAsyncThunk(
  "dashboard/events",
  async (payload: IDashboardPreferences) => {
    const response = await axios.post("dashboard/events", payload);
    return response.data;
  }
);