import { useEffect, useState } from "react";
import Logo from "@/assets/images/logo_black.svg";
import Typography from "@/components/baseComponents/Typography";
import TextField from "@/components/baseComponents/TextField";
import { Button } from "@/components/baseComponents/Button";
import { useNavigate } from "react-router-dom";
import { useAppDispatch } from "@/redux/hooks";
import validation from "@/shared/services/validation";
import { resetPassword } from "@/redux/user/userSlice";
import { ResetPasswordDto } from "@/shared/interfaces/interfaces";
import { notify } from "@/shared/services/notify";
import IconWrapper from "@/components/baseComponents/IconWrapper";

const ResetPassword = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [code, setCode] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [confirm, setConfirm] = useState<string>("");
  const [errorConfirm, setErrorConfirm] = useState<boolean>(false);
  const [errorMessagePassword, setErrorMessagePassword] = useState<string>("");

  // State and setters for show password and show confirm password
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState<boolean>(false);

  const goLogin = () => {
    navigate("/auth/login");
  };

  const changePassword = (value: string) => {
    setPassword(value);
    if (!validation.emptyPassword(value)) {
      setErrorMessagePassword("This field is required");
      return;
    }
    if (!validation.containsNumber(value)) {
      setErrorMessagePassword("Should contain at least a number");
      return;
    }
    if (!validation.containsUppercase(value)) {
      setErrorMessagePassword("Should contain at least a uppercase character");
      return;
    }
    if (!validation.containsSpecial(value)) {
      setErrorMessagePassword("Should contain at least a sepcial character");
      return;
    }
    if (!validation.minLength(value)) {
      setErrorMessagePassword("Should be at least 8 characters");
      return;
    }
    setErrorMessagePassword("");
  };

  const resetPasswordFunc = () => {
    changePassword(password);
    if (
      !validation.IsDifferentString(password, confirm) &&
      !errorMessagePassword
    ) {
      let data: ResetPasswordDto = {
        code: code,
        password: password,
      };
      dispatch(resetPassword(data)).then((res) => {
        notify(res.payload.success, res.payload.message);
        if (res.payload.success) navigate("/auth/login");
      });
    }
  };

  useEffect(() => {
    if (password !== "" && confirm !== "") setErrorConfirm(validation.IsDifferentString(password, confirm));
  }, [password, confirm])
  
  return (
    <div className="flex items-start py-20 px-4 sm:px-6 w-full bg-white min-h-screen select-none">
      <div className="w-full flex flex-col space-y-4 gap-4">
        <div className=" self-center">
          <img
            src={Logo}
            alt="logo"
            className="  max-w-[197px] sm:max-w-[263px] flex"
          />
        </div>

        <div className=" self-center lg:px-6 max-w-[536px] space-y-4 w-full">
          <div className="flex flex-col items-center">
            <Typography variant="h1" className="text-center">
              Reset Password
            </Typography>
            <Typography
              variant="body"
              className="max-w-[400px] text-primary font-normal cursor-pointer text-center"
            >
              Your unique reset code has been sent to the email you have
              provided.
            </Typography>
          </div>

          <div className=" py-3">
            <Typography variant="caption" className="text-secondary">
              Code
            </Typography>
            <TextField
              className="w-full mb-[5px]"
              autoComplete="off"
              value={code}
              onChange={(e) => setCode(e.target.value)}
            />
          </div>

          <div className=" py-3">
            <Typography variant="caption" className="text-secondary">
              Password
            </Typography>
            <TextField
              className={`w-full ${
                errorMessagePassword !== ""
                  ? "outline outline-1 rounded outline-[#E01010]"
                  : ""
              }`}
              type={showPassword ? "text" : "password"}
              autoComplete="new-password"
              value={password}
              onChange={(e) => changePassword(e.target.value)}
              endAdornment={
                <div className="cursor-pointer" onClick={() => setShowPassword(!showPassword)} tabIndex={-1}>
                  <IconWrapper name="eye" className={showPassword ? `[&>svg>path]:!stroke-indigo-600` : ``} width={20} />
                </div>
              }
            />
            {errorMessagePassword !== "" && (
              <Typography
                variant="caption"
                className="text-[#E01010] absolute mt-[2px]"
              >
                {errorMessagePassword}
              </Typography>
            )}
          </div>

          <div className=" py-3">
            <Typography variant="caption" className="text-secondary">
              Confirm Password
            </Typography>
            <TextField
              className={`w-full ${
                errorConfirm
                  ? "outline outline-1 rounded outline-[#E01010]"
                  : ""
              }`}
              type={showConfirmPassword ? "text" : "password"}
              autoComplete="new-password"
              value={confirm}
              onChange={(e) => setConfirm(e.target.value)}
              endAdornment={
                <div className="cursor-pointer" onClick={() => setShowConfirmPassword(!showConfirmPassword)} tabIndex={-1}>
                  <IconWrapper name="eye" className={showConfirmPassword ? `[&>svg>path]:!stroke-indigo-600` : ``} width={20} />
                </div>
              }
            />
            {errorConfirm && (
              <Typography
                variant="caption"
                className="text-[#E01010] absolute mt-[2px]"
              >
                Passwords must match!
              </Typography>
            )}
          </div>

          <Button
            className="w-full text-15"
            onClick={() => resetPasswordFunc()}
          >
            Reset
          </Button>
          <Typography
            variant="body"
            className="text-button-primary font-normal hover:text-button-primary-hover cursor-pointer pt-2 text-center "
            onClick={() => goLogin()}
          >
            Return to Sign In
          </Typography>
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;
