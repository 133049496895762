import { BiArrowBack } from 'react-icons/bi';
import { IoArrowForward } from 'react-icons/io5';
import PaginationItem from "@/components/mainComponents/Landing/FooterItem";

interface PaginationProps {
  totalItems: number;
  currentPage: number;
  itemsPerPage: number;
  setCurrentPage: (page: number) => void;
}

const LandingPagination = ({ totalItems, currentPage, itemsPerPage, setCurrentPage }: PaginationProps) => {
  const totalPages = Math.ceil(totalItems / itemsPerPage);

  const getPaginationItems = () => {
    const items = [];
    const maxPagesToShow = 5;
    const halfMaxPagesToShow = Math.floor(maxPagesToShow / 2);

    let startPage = Math.max(1, currentPage - halfMaxPagesToShow);
    let endPage = Math.min(totalPages, currentPage + halfMaxPagesToShow);

    if (currentPage - halfMaxPagesToShow < 1) {
      endPage = Math.min(totalPages, endPage + (1 - (currentPage - halfMaxPagesToShow)));
    }

    if (currentPage + halfMaxPagesToShow > totalPages) {
      startPage = Math.max(1, startPage - ((currentPage + halfMaxPagesToShow) - totalPages));
    }

    for (let i = startPage; i <= endPage; i++) {
      items.push(
        <PaginationItem
          key={i}
          onClick={() => setCurrentPage(i)}
          selected={i === currentPage}
          title={i.toString()}
        />
      );
    }

    if (startPage > 1) {
      items.unshift(<div key="start-dots" className="flex">...</div>);
      items.unshift(
        <PaginationItem
          key={1}
          onClick={() => setCurrentPage(1)}
          selected={1 === currentPage}
          title={"1"}
        />
      );
    }

    if (endPage < totalPages) {
      items.push(<div key="end-dots" className="flex">...</div>);
      items.push(
        <PaginationItem
          key={totalPages}
          onClick={() => setCurrentPage(totalPages)}
          selected={totalPages === currentPage}
          title={totalPages.toString()}
        />
      );
    }

    return items;
  };

  return (
    <div className="flex pb-24 w-full justify-center items-center gap-4">
      <BiArrowBack onClick={() => currentPage > 1 && setCurrentPage(currentPage - 1)} />
      {getPaginationItems()}
      <IoArrowForward onClick={() => currentPage < totalPages && setCurrentPage(currentPage + 1)} />
    </div>
  );
};

export default LandingPagination;