// Import necessary modules
import { Outlet } from "react-router-dom";
import AuthImg from "@/assets/images/auth_image.png";
import Scrollbars from "react-custom-scrollbars";
import { IntercomProvider } from "react-use-intercom";
import Typography from "../baseComponents/Typography";

// Define the AuthLayout component
const AuthLayout = () => {
  // Render the component
  return (
    <IntercomProvider autoBoot appId={import.meta.env.VITE_INTERCOM_APP_ID}>
      <div className="lg:grid grid-rows-6 lg:grid-rows-none grid-cols-1 lg:grid-cols-12 bg-[#eaeef2] min-h-screen">
        <div className=" hidden sm:flex gap-4 lg:hidden pl-6  lg:pl-[96px]  lg:gap-[64px] h-full max-h-[300px]">
          <div className=" min-w-[60%] flex flex-col justify-center space-y-2">
            <div className="heading">
              <Typography
                color="primary"
                className=" leading-[110%] text-[28px] lg:text-[36px] font-normal"
                variant="h1"
                children="Get Better"
              />
              <Typography
                color="primary"
                className=" leading-[110%] text-[28px] lg:text-[36px] font-normal"
                variant="h1"
                children="Real Estate Management"
              />
              <Typography
                color="primary"
                className=" leading-[110%] text-[28px] lg:text-[36px] font-normal"
                variant="h1"
                children="with RealtyView"
              />
            </div>
            <Typography
              className=" !font-normal text-secondary"
              variant="h4"
              children="All in One platform for managing your day to day tasks."
            />
          </div>
          <div className="h-full pt-10">
            <img
              src={AuthImg}
              alt="authImg"
              className=" min-w-[500px] h-full origin-top-left object-cover object-left-top"
            />
          </div>
        </div>

        <div className=" flex-1 row-span-full col-span-full lg:col-span-5 ">
          <Scrollbars autoHide className="min-h-screen">
            <div className="min-h-full ">
              <Outlet />
            </div>
          </Scrollbars>
        </div>

        <div className=" relative hidden lg:flex items-start col-span-7 pl-10  lg:pl-[96px] flex-col gap-10 lg:gap-[64px] h-screen">
          <div className="pt-[64px] 2xl:pt-[200px] min-w-[60%] flex flex-col justify-center space-y-2">
            <div className="heading">
              <Typography
                color="primary"
                className=" leading-[110%] text-[28px] lg:text-[36px] font-normal"
                variant="h1"
                children="Get Better"
              />
              <Typography
                color="primary"
                className=" leading-[110%] text-[28px] lg:text-[36px] font-normal"
                variant="h1"
                children="Real Estate Management"
              />
              <Typography
                color="primary"
                className=" leading-[110%] text-[28px] lg:text-[36px] font-normal"
                variant="h1"
                children="with RealtyView"
              />
            </div>
            <Typography
              className=" !font-normal text-secondary"
              variant="h4"
              children="All in One platform for managing your day to day tasks."
            />
          </div>
          <div className="h-full">
            <img
              src={AuthImg}
              alt="authImg"
              className=" min-w-[1021px] h-full origin-top-left object-cover object-left-top"
            />
          </div>
        </div>
      </div>
    </IntercomProvider>
  );
};

// Export the AuthLayout component
export default AuthLayout;
