import React from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { getUser } from '@/redux/user/userSlice'
import { useAppSelector, useAppDispatch } from '@/redux/hooks'
import { notify } from '@/shared/services/notify'
import { DeleteDocumentsDto, GetDocumentsDto, IDocument } from '@/shared/interfaces/interfaces'
import { MdEdit, MdDelete } from 'react-icons/md'
import { getDocuments, getDocumentsFromDB, deleteDocuments } from '@/redux/document/documentSlice'
import AdminTable from '@/components/baseComponents/AdminTable'
import Typography from '@/components/baseComponents/Typography'
import Filter from "@/components/mainComponents/Filter"
import SortAdvanced from "@/components/mainComponents/SortAdvanced"
import { Button } from '@/components/baseComponents/Button'
import ConfirmDelete from './ConfirmDelete'

const tabs = [
  { name: "All", value: '' },
  { name: "Published", value: 'published' },
  { name: "Drafts", value: 'draft' },
  { name: "Achieved", value: 'achieved' },
];

const TableFields = [
  { name: 'Organization', type: 'text', slug: 'organization', class_name: 'text-left text-gray-900 text-sm font-semibold py-3.5' },
  { name: 'Document Name', type: 'text', slug: 'name', class_name: 'text-left text-gray-900 text-sm font-semibold py-3.5' },
  { name: 'Status', type: 'icon', slug: 'status', class_name: 'text-left text-gray-900 text-sm font-semibold py-3.5' },
  { name: '', type: "action", slug: 'action', class_name: 'text-left text-gray-900 text-sm font-semibold py-3.5 pl-4 pr-3' }
]

const SortFieldOptions = [
  { value: "name", label: "Document Name" },
  { value: "organization", label: "Organization" },
  { value: "status", label: "Status" },
  { value: "updatedAt", label: "Publich Date" },
];

function classNames(...classes: any) {
  return classes.filter(Boolean).join(" ");
}

const Documents = () => {
  const dispatch = useAppDispatch()
  const documents = useAppSelector(getDocuments)
  const user = useAppSelector(getUser)
  const location = useLocation()
  const searchParams = new URLSearchParams(location.search);
  const paramValue = searchParams.get('tab');
  const currentTab = paramValue ?? ''
  const navigate = useNavigate()

  const defaultValue: IDocument = {
    name: '',
    organization: '',
    file: '',
    matchJson: '',
    status: '',
  }

  const [openConfirm, setOpenConfirm] = React.useState<boolean>(false)
  const [data, setData] = React.useState<Array<any>>([])
  const [totalCount, setTotalCount] = React.useState<number>(0)
  const [currentPage, setCurrentPage] = React.useState<number>(1)
  const [recordsPerPage, setRecordsPerPage] = React.useState<number>(10)
  const [keyword, setKeyword] = React.useState<string>('')
  const [sortType, setSortType] = React.useState<string>("Descending")
  const [sortField, setSortField] = React.useState<string>('name')
  const [selectedRowData, setSelectedRowData] = React.useState<IDocument>(defaultValue)

  const search: GetDocumentsDto = {
    userId: user._id,
    keyword: keyword,
    sortType: sortType,
    sortField: sortField,
    recordsPerPage: recordsPerPage,
    currentPage: currentPage,
    status: currentTab
  }

  const changeCurrentTab = (tab: string) => {
    navigate(location.pathname + '?tab=' + tab)
  }

  const onSetPage = (value: number) => {
    setCurrentPage(value)
  }

  const onClickRow = (value: any) => {
    console.log(value)
  }

  const createDocument = () => {
    navigate(location.pathname.replace(/\/+$/, '') + '/create', { state: { search: search } })
  }

  const editRow = (data: IDocument) => {
    const validData: IDocument = {
      _id: data._id,
      name: data.name,
      organization: data.organization,
      file: data.file,
      matchJson: data.matchJson,
      status: data.status,
    }
    navigate(location.pathname.replace(/\/+$/, '') + '/create', { state: { document: validData, search: search } })
  }

  const deleteRow = (data: any) => {
    setSelectedRowData(data)
    setOpenConfirm(true)
  }

  const confirmDelete = () => {
    if (!(selectedRowData._id) || selectedRowData._id === '') {
      notify(false, 'Something went wrong')
      return;
    }

    const data: DeleteDocumentsDto = {
      ids: [selectedRowData._id],
      search: search
    }

    dispatch(deleteDocuments(data)).then((res) => {
      try {
        notify(res.payload.success, res.payload.message)
      } catch (e) {
        notify(false, 'Something went wrong.')
      }
    })
  }

  const fetchData = () => {
    dispatch(getDocumentsFromDB(search)).then((res) => {
      try {
        setTotalCount(res.payload.totalCount)
      } catch (e) {
        notify(false, 'Something went wrong.')
      }
    })
  }

  const makeTableData = (data: Array<object>) => {
    const res: Array<object> = [];

    data !== undefined && data.map((item: any) => {
      const newItem = JSON.parse(JSON.stringify(item))

      newItem.action = [
        { name: 'Edit', icon: <MdEdit className="mt-1" />, color: 'black' },
        { name: 'Archive', icon: <MdDelete className="text-[#C77E90] mt-1" />, color: '#C77E90' }
      ]

      res.push(newItem)
    })
    return res
  }

  const changeKeyword = (keyword: string) => {
    setKeyword(keyword);
  };

  const changeSortType = (value: string) => {
    setSortType(value);
  };

  const changeSortField = (value: string) => {
    setSortField(value);
  };

  React.useEffect(() => {
    setRecordsPerPage(10)
  }, []);

  React.useEffect(() => {
    const tableData = makeTableData(documents)
    setData(tableData)
  }, [documents]);

  React.useEffect(() => {
    fetchData()
  }, [currentPage, currentTab])

  return (
    <div>
      {openConfirm && <ConfirmDelete setOpenConfirm={setOpenConfirm} confirmDelete={confirmDelete} />}
      <div className="p-8">
        <div className="flex flex-col gap-2">
          <div className="flex justify-between items-center">
            <Typography
              variant="h2"
              color="primary"
            >
              Offer Documents
            </Typography>
            <Button onClick={() => createDocument()}>
              <Typography variant="button2">Add New Document</Typography>
            </Button>
          </div>
          <nav
            className="flex justify-between items-center"
            aria-label="Tabs"
          >
            <div className="flex items-center gap-3">
              {tabs.map((tab, index) => (
                <div
                  key={index}
                  className={classNames(
                    tab.value === currentTab
                      ? "border-indigo-500 text-indigo-600"
                      : "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700",
                    "whitespace-nowrap border-b-2 p-1 cursor-pointer"
                  )}
                  aria-current={tab.value === currentTab ? "page" : undefined}
                  onClick={() => changeCurrentTab(tab.value)}
                >
                  <Typography variant="page-menu">{tab.name}</Typography>
                </div>
              ))}
            </div>
            <div className="flex gap-5">
              <Filter
                changeKeyword={changeKeyword}
                keyword={keyword}
                filterLeads={fetchData}
              />
              <SortAdvanced
                sortFieldOptions={SortFieldOptions}
                sortType={sortType}
                sortField={sortField}
                changeSortField={changeSortField}
                changeSortType={changeSortType}
                filterData={fetchData}
              />
            </div>
          </nav>
        </div>
      </div>
      <div className="px-8 pb-8">
        {data?.length > 0 ? (
          <AdminTable
            minCellWidth={100}
            class_name='table pb-12'
            data={data}
            fields={TableFields}
            onClickRow={onClickRow}
            totalPage={Math.ceil(totalCount / recordsPerPage)}
            totalCount={totalCount}
            currentPage={currentPage}
            recordsPerpage={recordsPerPage}
            onSetPage={onSetPage}
            editRow={editRow}
            deleteRow={deleteRow}
          />
        ) : (
          <div className='text-center bg-white py-16'>
            <Typography variant='body'>No data</Typography>
          </div>
        )}
      </div>
    </div>
  )
}

export default Documents
