// Define the type for the component props
type Props = {
    width: number;
    height: number;
    stroke?: string;
    className?: string;
};

// Define the IconCredential component
const IconCredential = (props: Props) => {
    // Return the SVG for the icon
    return (
        <svg viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <g id="key-round_16">
                <path id="Vector" d="M1.83325 12.0008V14.0008C1.83325 14.4008 2.09992 14.6674 2.49992 14.6674H5.16659V12.6674H7.16658V10.6674H8.49992L9.43325 9.73409C10.3598 10.0569 11.3685 10.0556 12.2942 9.73059C13.22 9.40556 14.0081 8.77598 14.5295 7.94484C15.0509 7.1137 15.2749 6.13021 15.1647 5.15525C15.0545 4.18029 14.6167 3.27159 13.9229 2.5778C13.2291 1.88402 12.3204 1.44621 11.3454 1.33602C10.3705 1.22582 9.38698 1.44975 8.55583 1.97119C7.72469 2.49262 7.09511 3.28067 6.77008 4.20644C6.44506 5.1322 6.44382 6.14087 6.76658 7.06742L1.83325 12.0008Z" stroke="#1F2937" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
                <path id="Vector_2" d="M11.5001 5.33464C11.6842 5.33464 11.8334 5.1854 11.8334 5.0013C11.8334 4.81721 11.6842 4.66797 11.5001 4.66797C11.316 4.66797 11.1667 4.81721 11.1667 5.0013C11.1667 5.1854 11.316 5.33464 11.5001 5.33464Z" stroke="#1F2937" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            </g>
        </svg>
    );
};

// Export the IconCredential component
export default IconCredential;