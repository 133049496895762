// Import necessary modules
import { useRef, useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import Scrollbars from "react-custom-scrollbars";
import { IntercomProvider } from "react-use-intercom";
import { useAppSelector } from "@/redux/hooks";
import { getUser } from "@/redux/user/userSlice";

const MainLandingLayout = () => {
  const scrollbars = useRef<any>(null);
  const user = useAppSelector(getUser);
  const [myWindow, setMyWindow] = useState<any>(window as any);

  useEffect(() => {
    setTimeout(() => {
      if (
        myWindow.isUserHelpReady === undefined ||
        myWindow.isUserHelpReady === false
      ) {
        setTimeout(() => {
          setMyWindow(window as any);
        }, 5000);
      } else {
        if (myWindow.isUserHelpReady === true) {
          myWindow.UserHelpSetName(
            user?.agent?.firstName + " " + user?.agent?.lastName
          );
          myWindow.UserHelpSetEmail(user?.agent?.contactEmail);
        }
      }
    }, 5000);
  }, []);

  // Render the component
  return (
    <IntercomProvider
      autoBoot
      apiBase="https://api-iam.intercom.io"
      appId={import.meta.env.VITE_INTERCOM_APP_ID}
      autoBootProps={{
        userId: user?.agent?._id,
        createdAt: user?.agent?.createdAt,
        name: user?.agent?.firstName + " " + user?.agent?.lastName,
        email: user?.agent?.contactEmail,
        phone: user?.agent?.mobileNumber,
        avatar: {
          type: "avatar",
          imageUrl: user?.agent?.avatarURL,
        },
        company: {
          companyId: user?.agent?.company?._id,
          name: user?.agent?.company?.businessName,
        },
      }}
    >
      <Scrollbars ref={scrollbars} autoHide className="min-h-screen">
        <Outlet />
      </Scrollbars>
    </IntercomProvider>
  );
};

export default MainLandingLayout;
