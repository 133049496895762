import { ButtonHTMLAttributes } from "react";
import classNames from "classnames";

// ----------------------------------------------------------------------
export type Props = ButtonHTMLAttributes<HTMLButtonElement> & {
  color?:
    | "inherit"
    | "default"
    | "primary"
    | "secondary"
    | "success"
    | "warning"
    | "danger"
    | "danger-nobg"
    | "blue"
    | "";
  variant?: "contained" | "outlined" | "text" | "icon";
  size?: "medium" | "small" | "";
};

const Button = ({
  className,
  color = "primary",
  variant = "contained",
  size = "medium",
  children,
  ...other
}: Props) => {
  return (
    <button
      className={classNames(
        "flex items-center justify-center gap-1 rounded-lg disabled:bg-transparent disabled:text-gray-300 disabled:border-gray-300 cursor-pointer transition-all duration-300 px-4 py-2 !h-10",
        {
          ["bg-white text-primary border-gray-300 border-1"]
            : color === "default" && variant === "contained",

          ["bg-button-primary border-indigo-500 hover:border-indigo-400 hover:bg-indigo-500 text-white border-[1px] flex items-center justify-center"]
            : color === "primary" && variant === "contained",

          ["bg-[#16A34A] border-[#22C55E] hover:bg-button-success-hover text-white hover:text-white border-[1px] flex items-center justify-center"]
            : color === "success" && variant === "contained",

          [" text-red-600 hover:text-red-700 !border-none border-[1px] flex items-center justify-center"]
            : color === "danger-nobg",

          ["bg-red-600 hover:bg-red-500 !text-white !border-none border-[1px] flex items-center justify-center"]
            : color === "danger" && variant === "contained",

          ["bg-gray-100 border-gray-200 border-2 hover:bg-gray-200 text-secondary"]
            : color === "secondary" && variant === "contained",

          ["border-1 !bg-gray-50 !text-gray-500 !border-gray-200 hover:!bg-gray-100"]
            : (color === "default") && variant === "outlined",

          ["border-1 !text-primary !border-border"]
            : color === "primary" && variant === "outlined",

          ["border-1 !bg-blue-50 !text-blue-500 !border-blue-200 hover:!bg-blue-100"]
            : color === "blue" && variant === "outlined",

          ["disabled:bg-transparent disabled:text-secondary"]
            : color === "primary" && variant === "text",

          ["disabled:bg-transparent bg-transparent"]
            : color === "",

          ["!px-1"]
            : variant === "icon",

          ["!h-6 !px-2"]
            : size === "small",
        },
        className
      )}
      {...other}
    >
      {children}
    </button>
  );
};

export default Button;
