// Import necessary components
import DashboardSimple from './DashboardSimple';

// Define the Home component
const Home = () => {
    // Render the component
    return (
        <div className="grid grid-cols-3 gap-x-8 gap-y-12 px-8 py-12">
            <DashboardSimple />
        </div>
    );
}

// Export the Home component
export default Home;