import React from 'react'
import { useLocation } from 'react-router-dom'
import AdvancedTable from '@/components/baseComponents/AdvancedTable'
import Typography from '@/components/baseComponents/Typography'

import icon_graph from '@/assets/icons/graph.png';
import icon_star_connected from '@/assets/icons/star-in-contact.png';
import icon_star_unconnected from '@/assets/icons/star-uncontacted.png';
import icon_star_priority from '@/assets/icons/star-high-priority.png';

import defaulAvatar from '@/assets/images/default_avatar.jpg'
import emptyImage from '@/assets/images/empty.png'
import defaultCoverPhoto from '@/assets/images/default_agent_cover_photo.jpg'

import { Button } from '@/components/baseComponents/Button'
import { getUser, updateMyInfo, updateMyVendorInfo } from '@/redux/user/userSlice'
import { useAppSelector, useAppDispatch } from '@/redux/hooks'
import { notify } from '@/shared/services/notify'

import { IMyInfo, GetVendorsDto, UpdateMyInfoDto, UpdateMyVendorInfoDto } from '@/shared/interfaces/interfaces'
import Filter from '@/components/mainComponents/Filter'
import SortAdvanced from '@/components/mainComponents/SortAdvanced'
import { MdEdit, MdDelete } from 'react-icons/md'
import { XMarkIcon } from '@heroicons/react/24/outline'
import ActionDrawer from './ActionDrawer'
import ViewDrawer from "./ViewDrawer"
import { getVendors, getVendorsByUserId } from '@/redux/vendor/vendorSlice';
import { IVendor } from '@/shared/interfaces/interfaces'

// const tabs = [
//     { name: 'All', value: 'all' },
//     // { name: 'Favorite', value: 'favorite' },
//     // { name: 'My Brokerage', value: 'company' },
//     // { name: 'My Team', value: 'team' },
//     // { name: 'My Profile', value: 'myprofile' },
// ];

const TableFields = [
    {name:'', type:'custom_image', slug:'avatarURL', class_name:'pr-2', image_size: 'w-[3.125rem] h-[3.125rem] max-w-none'},
    {name:'Status', type:'custom_image', slug:'iconGraph', class_name:'px-2', image_size: 'w-[14px] m-auto'},
    {name:'', type:'favoriteAction', slug:'favoriteAction', class_name:'px-2 pr-4'},
    {name:'Name',type:'text', slug:'name', class_name:'text-left px-2 py-[16px]'},
    {name:'Phone', type:'text', slug:'mobileNumber', class_name:'text-left px-2 py-[16px]'},
    {name:'Email', type:'text', slug:'contactEmail', class_name:'text-left px-2 py-[16px]'},
    {name:'Member ID', type:'text', slug:'memberID', class_name:'text-left px-2 py-[16px]'},
    {name:'Company',type:'text', slug:'companyName', class_name:'text-left px-2 py-[16px]'},
    // {name:'', type:"action", slug:'action', class_name:'text-left px-2 py-[16px] pl-4 pr-3'}
];

const SortFieldOptions = [
    { value: 'firstName', label: 'Name' },
    { value: 'mobileNumber', label: 'Phone' },
    { value: 'contactEmail', label: 'Email' },
    { value: 'memberID', label: 'Member ID' },
];

// function classNames(...classes: any) {
//     return classes.filter(Boolean).join(' ')
// }

const Vendor = () => {
    const dispatch = useAppDispatch();
    const vendors = useAppSelector(getVendors);
    const user = useAppSelector(getUser)
    const userInfo = user.isVendor ? user.vendor : user.agent
    // const navigate = useNavigate()
    const location = useLocation()
    const tabValue = decodeURIComponent(location.pathname.split('/')[2])

    const initialSearchData: IVendor = {
        // General Details
        prefix: '',
        firstName: '',
        middleName: '',
        lastName: '',
        mobileNumber: '',
        officeNumber: '',
        contactEmail: '',
        avatarURL: '',
        // License Details
        licenseNumber: '',
        licenseState: '',
        licenseType: '',
        licenseDate: undefined,
        licenseExpiration: undefined,
        //Associations
        localAssociations: [],
        stateAssociations: [],
        mlsAssociations: [],
        // Serviced Areas
        serviceAreas: [],
        // Social Profile
        instagram: '',
        facebook: '',
        tiktok: '',
        linkedin: '',
        youtube: '',
        // Professional Profiles
        zillow: '',
        homes: '',
        realtor: '',
        ratemyagent: '',
    }
    
    const [openActionDrawer, setActionDrawer] = React.useState<boolean>(false)
    const [openViewDrawer, setOpenViewDrawer] = React.useState<boolean>(false)
    const [openConfirm, setOpenConfirm] = React.useState<boolean>(false)
    const [filteredData, setFilteredData] = React.useState<Array<any>>([])
    const [keyword, setKeyword] =  React.useState<string>('')
    const [sortType, setSortType] =  React.useState<string>('Descending')
    const [sortField, setSortField] =  React.useState<string>('memberID')
    const [totalCount, setTotalCount] = React.useState<number>(0)
    const [currentPage, setCurrentPage] = React.useState<number>(1)
    const [recordsPerPage, setRecordsPerPage] = React.useState<number>(25)
    const [selectedRowData, setSelectedRowData] = React.useState<IVendor>(initialSearchData)
    const [searchData, setSearchData] = React.useState<IVendor>(initialSearchData)
    
    // const changeTab = (value: string) =>{
    //     navigate('/vendor/'+ value)
    // }

    const prepareData = (vendors: any) => {
        if (!vendors) {
            return []
        }

        return vendors.map((vendor: any) => {
            return {
                id: vendor._id ?? '',
                name: (vendor.firstName ?? '') + ' ' + vendor.lastName ?? '',
                firstName: vendor.firstName ?? '',
                lastName: vendor.lastName ?? '',
                avatarURL: (vendor.avatarURL && vendor.avatarURL !== '') ? vendor.avatarURL : defaulAvatar,
                coverPhotoURL: (vendor.coverPhotoURL && vendor.coverPhotoURL !== '') ? vendor.coverPhotoURL : defaultCoverPhoto,
                description: vendor.description ?? '',
                favorite: (userInfo?.favorites && userInfo.favorites.length > 0 && userInfo.favorites.includes(vendor._id)) ? 'favorite' : 'colleague',
                isActive: 'active',
                mobileNumber: vendor.mobileNumber ?? '',
                contactEmail: vendor.contactEmail ?? '',
                instagram: vendor.instagram ?? '',
                facebook: vendor.facebook ?? '',
                tiktok: vendor.tiktok ?? '',
                linkedin: vendor.linkedin ?? '',
                youtube: vendor.youtube ?? '',
                zillow: vendor.zillow ?? '',
                homes: vendor.homes ?? '',
                realtor: vendor.realtor ?? '',
                ratemyagent: vendor.ratemyagent ?? '',
                memberID: vendor.memberID ?? '',
                companyName: vendor.company?.businessName ?? '',
                company: vendor.company ? {
                    companyId: vendor.company.companyId ?? '',
                    businessName: vendor.company.businessName ?? '',
                    businessAddress1: vendor.company.businessAddress1 ?? '',
                    businessAddress2: vendor.company.businessAddress2 ?? '',
                    businessCity: vendor.company.businessCity ?? '',
                    state: vendor.company.state ?? '',
                    businessZip: vendor.company.businessZip ?? '',
                    county: vendor.company.county ?? '',
                    businessLogo: (vendor.company.businessLogo && vendor.company.businessLogo !== '') ? vendor.company.businessLogo : emptyImage,
                    isProfile: vendor.company.isProfile ?? '',
                    principalBrokerFirstName: vendor.company.principalBrokerFirstName ?? '',
                    principalBrokerLastName: vendor.company.principalBrokerLastName ?? '',
                    principalBrokerEmail: vendor.company.principalBrokerEmail ?? '',
                    principalBrokerPhone: vendor.company.principalBrokerPhone ?? '',
                } : undefined,
                licenseNumber: vendor.licenseNumber ?? '',
                licenseState: vendor.licenseState ?? "",
                licenseType: vendor.licenseType ?? '',
                licenseDate: vendor.licenseDate ?? undefined,
                licenseExpiration: vendor.licenseExpiration ?? undefined,
                localAssociations: vendor.localAssociations ?? [],
                stateAssociations: vendor.stateAssociations ?? [],
                mlsAssociations: vendor.mlsAssociations ?? [],
                serviceAreas: vendor.serviceAreas ?? [],
            }
        })
    }

    const onSetPage = (value:number) =>{
        setCurrentPage(value)
    }
    
    const onClickRow = (value: any) => {
        if (!openConfirm) {
            setOpenViewDrawer(true);
        }
        setSelectedRowData(value);
    }

    // const clickMyProfileTab = () => {
    //     if (userInfo) {
    //         setSelectedRowData(userInfo)
    //         setOpenViewDrawer(true)
    //     }
    // }
    
    const changeKeyword = (keyword: string) =>{
        setKeyword(keyword)
    }

    const changeSortType = (value:string) =>{
        setSortType(value)
    }

    const changeSortField = (value:string) =>{
        setSortField(value)
    }

    const filterData = () =>{
        const data: GetVendorsDto = {
            userId: user._id,
            keyword: keyword,
            sortType: sortType,
            sortField: sortField,
            recordsPerPage: recordsPerPage,
            currentPage: currentPage,
            searchData: searchData,
            vendorType: tabValue
        }
        dispatch(getVendorsByUserId(data)).then((res)=>{
            try {
                setTotalCount(res.payload.totalCount)
            } catch (e) {
                notify(false, 'Something went wrong.')
            }
        })
    }

    const editRow = (data:any) => {
        console.log('editdata', data);
    }

    const deleteRow = (data:any) => {
        console.log('deletedata', data);
        setOpenConfirm(true)
    }

    const changeFovorite = (vendorId: string, type: string) => {
        if (vendorId === undefined || vendorId === '') {
            return
        }

        if (type === 'Favorite' && !userInfo?.favorites?.includes(vendorId)) {
            const favorites: Array<string> = userInfo?.favorites ? [...userInfo.favorites] : []
            favorites.push(vendorId)
            
            if (user.isVendor) {
                const updatedInfo: IVendor = {
                    ...userInfo,
                    favorites: favorites
                }
    
                const updateData: UpdateMyVendorInfoDto = {
                    email: user.email,
                    data: updatedInfo
                }
                dispatch(updateMyVendorInfo(updateData)).then((res: any) => {
                    try {
                        console.log(res)
                        // notify(res.payload.success, res.payload.message)
                    } catch (e) {
                        notify(false, 'Something went wrong.')
                    }
                })
            } else {
                const updatedInfo: IMyInfo = {
                    ...userInfo,
                    favorites: favorites
                }
    
                const updateData: UpdateMyInfoDto = {
                    email: user.email,
                    data: updatedInfo
                }
                dispatch(updateMyInfo(updateData)).then((res) => {
                    try {
                        console.log(res)
                        // notify(res.payload.success, res.payload.message)
                    } catch (e) {
                        notify(false, 'Something went wrong.')
                    }
                })
            }
        } else if (type === 'Colleague' && userInfo?.favorites?.includes(vendorId)) {
            const favorites: Array<string> = userInfo?.favorites ? [...userInfo.favorites] : []
            const index = favorites.indexOf(vendorId)
            if (index !== -1) {
                favorites.splice(index, 1)
            }
            
            const updatedInfo: IVendor = {
                ...userInfo,
                favorites: favorites
            }

            const updateData: UpdateMyInfoDto = {
                email: user.email,
                data: updatedInfo
            }
            dispatch(updateMyInfo(updateData)).then((res) => {
                try {
                    console.log(res)
                    // notify(res.payload.success, res.payload.message)
                } catch (e) {
                    notify(false, 'Something went wrong.')
                }
            })
        }
    }

    const advancedSearch = (values: IVendor) => {
        setCurrentPage(1)
        setSearchData({
            ...searchData,
            ...values
        })
    }
    
    const makeTableData = (data: Array<object>) => {
        const res: Array<object> = [];
        data !== undefined && data.map((item:any) => {
            const new_item = JSON.parse(JSON.stringify(item));
            
            new_item.iconGraph = icon_graph;
            
            if (new_item.favorite === 'activeDeal') {
                new_item.iconStar = icon_star_connected;
            } else if (new_item.favorite === 'favorite') {
                new_item.iconStar = icon_star_priority;
            } else {
                new_item.iconStar = icon_star_unconnected;
            }

            new_item.favoriteAction = [
                {name:'Colleague', image: <img src={icon_star_unconnected} className='w-[1rem] h-[1rem] max-w-none' />, icon:<MdEdit className="my-3"/>, color:'black'},
                {name:'Active Deal', image: <img src={icon_star_connected} className='w-[1rem] h-[1rem] max-w-none'/>, icon:<MdDelete className="text-[#C77E90] mt-1"/>, color:'#C77E90'},
                {name:'Favorite', image: <img src={icon_star_priority} className='w-[1rem] h-[1rem] max-w-none'/>, icon:<MdDelete className="text-[#C77E90] mt-1"/>, color:'#C77E90'}
            ]

            new_item.action = [
                {name:'Edit', icon:<MdEdit className="mt-1"/>, color:'black'},
                {name:'Archive', icon:<MdDelete className="text-[#C77E90] mt-1"/>, color:'#C77E90'}
            ];

            res.push(new_item);
        })
        return res;
    }

    React.useEffect(() => {
        setRecordsPerPage(25)
    }, []);

    React.useEffect(() => {
        const dataList = prepareData(vendors)
        const tableData = makeTableData(dataList)
        setFilteredData(tableData)
    }, [vendors, user]);

    React.useEffect(() => {
        if (openActionDrawer) {
            setActionDrawer(false)
        }
    }, [filteredData])

    React.useEffect(() => {
        setCurrentPage(1)
    }, [tabValue])

    React.useEffect(()=>{
        filterData()
    }, [currentPage, searchData, tabValue]);
    
    return (
        <div>
            {(openActionDrawer || openViewDrawer) && <div className='!bg-[#00000040] h-screen w-full fixed top-0 left-0 z-10'></div>}
            <ActionDrawer open={openActionDrawer} changeState={setActionDrawer} advancedSearch={advancedSearch}/>
            <ViewDrawer open={openViewDrawer} changeViewDrawer={setOpenViewDrawer} data={selectedRowData}/>
            {openConfirm && 
            <div className='bg-[#00000040] w-full h-screen flex items-center justify-center fixed top-0 left-0' onClick={()=>setOpenConfirm(false)}>
                <div className='rounded-lg max-w-[435px] w-full bg-white p-3'>
                    <div>
                        <div className='flex justify-end w-full text-secondary hover:text-primary cursor-pointer'>
                        <XMarkIcon className="h-6 w-6" aria-hidden="true" onClick={()=>setOpenConfirm(false)} />
                        </div>
                        <div className='w-full flex justify-center mt-[10px]'>
                            <Typography variant='button2'>Are you sure you want to archive this listing?</Typography>
                        </div>
                        <div className='flex justify-center mt-[20px] mb-[10px]'>
                            <div className='bg-[#C18193] hover:bg-[#B17183] mr-4 w-[146px] h-[40px] rounded flex items-center justify-center cursor-pointer'>
                                <Typography variant='button2' className='text-[#B32F43]'>Archive Listing</Typography>
                            </div>
                            <div className='bg-[#B5E2C4] hover:bg-[#B17183] w-[146px] h-[40px] rounded flex items-center justify-center cursor-pointer'>
                                <Typography variant='button2' className='text-[#6DA172]'>Keep Listing</Typography>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>}
            <div className="p-8">
                <div className="flex flex-col gap-4">
                    <div className='flex justify-between items-center'>
                        <Typography variant='h2' color='primary'>Vendors</Typography>
                        <Button onClick={() => setActionDrawer(true)}>
                            <Typography variant='button2'>Search</Typography>
                        </Button>
                    </div>
                    <nav className="flex justify-between items-center" aria-label="Tabs">
                        <div className='flex items-center gap-3'>
                            {/* {tabs.map((tab, index) => (
                                    <div
                                        key={index}
                                        className={classNames(
                                            tab.name === tabValue
                                                ? 'border-indigo-500 text-indigo-600'
                                                : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700',
                                            'whitespace-nowrap border-b-2 p-1 cursor-pointer'
                                        )}
                                        aria-current={tab.name === tabValue ? 'page' : undefined}
                                        onClick={() => changeTab(tab.value)}
                                    >
                                        <Typography variant='page-menu'>{tab.name}</Typography>
                                    </div>
                                ))} */}
                        </div>
                        <div className='flex gap-5'>
                            <Filter changeKeyword={changeKeyword} keyword={keyword} filterLeads={filterData} />
                            <SortAdvanced sortFieldOptions={SortFieldOptions} sortType={sortType} sortField={sortField} changeSortField={changeSortField} changeSortType={changeSortType} filterData={filterData} />
                        </div>
                    </nav>
                </div>
            </div>
            <div className="px-8 pb-8">
                <AdvancedTable
                    minCellWidth={100}
                    class_name='table custom-table'
                    data={filteredData}
                    fields={TableFields}
                    onClickRow={onClickRow}
                    totalPage={Math.ceil(totalCount / recordsPerPage)}
                    totalCount={totalCount}
                    currentPage={currentPage}
                    recordsPerpage={recordsPerPage}
                    onSetPage={onSetPage}
                    editRow={editRow}
                    deleteRow={deleteRow}
                    changeFavorite={changeFovorite}
                    emptyStateProps={{
                        heading: "No records found",
                        content:
                            "Encourage everyone to join your Company or build a Team together.",
                    }}
                />
            </div>
        </div>

    )
}

export default Vendor