type Props = {
  width: number;
  height: number;
  stroke?: string;
  className?: string;
};

const IconShare = (props: Props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      viewBox="0 0 16 17"
      fill="none"
    >
      <g clipPath="url(#clip0_4448_24456)">
        <path
          d="M12 5.53906C13.1046 5.53906 14 4.64363 14 3.53906C14 2.43449 13.1046 1.53906 12 1.53906C10.8954 1.53906 10 2.43449 10 3.53906C10 4.64363 10.8954 5.53906 12 5.53906Z"
          stroke="#4F46E5"
          strokeWidth="1.2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M4 10.207C5.10457 10.207 6 9.3116 6 8.20703C6 7.10246 5.10457 6.20703 4 6.20703C2.89543 6.20703 2 7.10246 2 8.20703C2 9.3116 2.89543 10.207 4 10.207Z"
          stroke="#4F46E5"
          strokeWidth="1.2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M12 14.875C13.1046 14.875 14 13.9796 14 12.875C14 11.7704 13.1046 10.875 12 10.875C10.8954 10.875 10 11.7704 10 12.875C10 13.9796 10.8954 14.875 12 14.875Z"
          stroke="#4F46E5"
          strokeWidth="1.2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M5.72656 9.21484L10.2799 11.8682"
          stroke="#4F46E5"
          strokeWidth="1.2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M10.2732 4.54688L5.72656 7.20021"
          stroke="#4F46E5"
          strokeWidth="1.2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_4448_24456">
          <rect
            width="16"
            height="16"
            fill="white"
            transform="translate(0 0.207031)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default IconShare;
