// Import necessary modules
import { useRef, useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import Header from "./bars/Header";
import Sidebar from "./bars/Sidebar/index";
import Scrollbars from "react-custom-scrollbars";
import { IntercomProvider } from "react-use-intercom";
import { useAppSelector } from "@/redux/hooks";
import { getUser } from "@/redux/user/userSlice";

// Define the MainLayout component
const MainLayout = () => {
  // Define state and ref
  const scrollbars = useRef<any>(null);
  const user = useAppSelector(getUser);
  const [open, setOpen] = useState<boolean>(true);
  const [myWindow, setMyWindow] = useState<any>(window as any);

  // Define the useEffect hook to set the user's name and email for UserHelp
  useEffect(() => {
    setTimeout(() => {
      if (myWindow.isUserHelpReady === undefined || myWindow.isUserHelpReady === false) {
        setTimeout(() => {
          setMyWindow(window as any);
        }, 5000);
      } else {
        if (myWindow.isUserHelpReady === true) {
          myWindow.UserHelpSetName(user?.agent?.firstName + " " + user?.agent?.lastName);
          myWindow.UserHelpSetEmail(user?.agent?.contactEmail);
        }
      }
    }, 5000);
  }, []);

  const [disableSearch, setDisableSearch] = useState(false);

  // Render the component
  return (
    <IntercomProvider
      autoBoot
      apiBase="https://api-iam.intercom.io"
      appId={import.meta.env.VITE_INTERCOM_APP_ID}
      autoBootProps={{
        userId: user?.agent?._id,
        createdAt: user?.agent?.createdAt,
        name: user?.agent?.firstName + " " + user?.agent?.lastName,
        email: user?.agent?.contactEmail,
        phone: user?.agent?.mobileNumber,
        avatar: {
          type: "avatar",
          imageUrl: user?.agent?.avatarURL,
        },
        company: {
          companyId: user?.agent?.company?._id,
          name: user?.agent?.company?.businessName,
        },
      }}
    >
      <div className="flex h-full relative">
        <div
          className={`bg-[#00000040] h-screen w-full top-0 left-0 z-20 md:hidden ${open ? `md:!hidden sm:!hidden max-sm:!hidden` : `md:!fixed sm:!fixed max-sm:!fixed`}`}
          onClick={() => setOpen(!open)}
        ></div>
        <div onClick={() => setDisableSearch(true)}>
          <Sidebar scrollbars={scrollbars} isLoggedIn={true} open={open} changeOpen={setOpen} />
        </div>
        <div
          className={`w-full relative ml-auto max-md:w-screen transition-all duration-600 min-h-full left-[calc(100vw-100%)] ${
            open ? `lg:w-[75vw] lg2:w-[80vw] md:w-[70vw] sm:w-full ` : `lg:w-[95vw] lg2:w-[95vw] md:w-[92vw] sm:w-full `
          } `}
        >
          <Header open={open} changeOpen={setOpen} scrollbars={scrollbars} disableSearch={disableSearch} setDisabledSearch={setDisableSearch} />
          <div className="pt-[60px] h-full fixed w-[100vw] md:w-[calc(100vw-30vw)] lg:w-[calc(100vw-25vw)] xl:w-[calc(100vw-20vw)]">
            <Scrollbars ref={scrollbars} autoHide className="min-h-screen">
              <Outlet context={[scrollbars, open]} />
            </Scrollbars>
          </div>
        </div>
      </div>
    </IntercomProvider>
  );
};

// Export the MainLayout component
export default MainLayout;
