import { useEffect, useRef, useState } from "react";
import { NumericFormat } from "react-number-format";
import Typography from "@/components/baseComponents/Typography";
import Select from "@/components/baseComponents/Select";
import { ListingAdvancedSearchDto } from '@/shared/interfaces/interfaces'
import { useAppSelector, useAppDispatch } from '@/redux/hooks'
import { getAmenities, getKeywords, fetchAmenities, fetchKeywords } from "@/redux/lead/leadSlice";


const propertyTypeOptions = [
  { value: 'Single Family Residence', label: 'Single Family Residence' },
  { value: 'Multi Family Residence', label: 'Multi Family Residence' },
  { value: 'Apartment', label: 'Apartment' },
  { value: 'Townhouse', label: 'Townhouse' },
  { value: 'Land', label: 'Land' },
  { value: 'Mobile', label: 'Mobile' },
  { value: 'Commercial', label: 'Commercial' },
  { value: 'Other', label: 'Other' },
];

const propertySubTypeList = [
  {
    parent: 'Single Family Residence', contents: [
      { value: 'Cabin', label: 'Cabin' },
      { value: 'Manufactured Home', label: 'Manufactured Home' },
      { value: 'Manufactured On Land', label: 'Manufactured On Land' },
      { value: 'Mobile Home', label: 'Mobile Home' },
      { value: 'Own Your Own', label: 'Own Your Own' },
      { value: 'Single Family Residence', label: 'Single Family Residence' },
      { value: 'Mixed Use', label: 'Mixed Use' },
    ]
  },
  {
    parent: 'Multi Family Residence', contents: [
      { value: 'Duplex', label: 'Duplex' },
      { value: 'Manufactured Home', label: 'Manufactured Home' },
      { value: 'Manufactured On Land', label: 'Manufactured On Land' },
      { value: 'Mobile Home', label: 'Mobile Home' },
      { value: 'Own Your Own', label: 'Own Your Own' },
      { value: 'Quadruplex', label: 'Quadruplex' },
      { value: 'Triplex', label: 'Triplex' },
    ]
  },
  {
    parent: 'Apartment', contents: [
      { value: 'Apartment', label: 'Apartment' },
      { value: 'Condominium', label: 'Condominium' },
      { value: 'Own Your Own', label: 'Own Your Own' },
      { value: 'Stock Cooperative', label: 'Stock Cooperative' },
    ]
  },
  {
    parent: 'Townhouse', contents: [
      { value: 'Own Your Own', label: 'Own Your Own' },
      { value: 'Townhouse', label: 'Townhouse' },
    ]
  },
  {
    parent: 'Land', contents: [
      { value: 'Farm (PropertySubType)', label: 'Farm (PropertySubType)' },
      { value: 'Own Your Own', label: 'Own Your Own' },
      { value: 'Ranch', label: 'Ranch' },
      { value: 'Unimproved Land', label: 'Unimproved Land' },
    ]
  },
  {
    parent: 'Mobile', contents: [
      { value: 'Mobile Home', label: 'Mobile Home' },
      { value: 'Own Your Own', label: 'Own Your Own' },
    ]
  },
  {
    parent: 'Commercial', contents: [
      { value: 'Own Your Own', label: 'Own Your Own' },
      { value: 'Stock Cooperative', label: 'Stock Cooperative' },
      { value: 'Business (PropertySubType)', label: 'Business (PropertySubType)' },
      { value: 'Hotel-Motel', label: 'Hotel-Motel' },
      { value: 'Industrial (PropertySubType)', label: 'Industrial (PropertySubType)' },
      { value: 'Mixed Use', label: 'Mixed Use' },
      { value: 'Multi Family', label: 'Multi Family' },
      { value: 'Office (PropertySubType)', label: 'Office (PropertySubType)' },
      { value: 'Retail (PropertySubType)', label: 'Retail (PropertySubType)' },
      { value: 'Warehouse (PropertySubType)', label: 'Warehouse (PropertySubType)' },
    ]
  },
  {
    parent: 'Other', contents: [
      { value: 'Boat Slip', label: 'Boat Slip' },
      { value: 'Cabin', label: 'Cabin' },
      { value: 'Deeded Parking', label: 'Deeded Parking' },
      { value: 'Farm (PropertySubType)', label: 'Farm (PropertySubType)' },
      { value: 'Manufactured Home', label: 'Manufactured Home' },
      { value: 'Manufactured On Land', label: 'Manufactured On Land' },
      { value: 'Own Your Own', label: 'Own Your Own' },
      { value: 'Ranch', label: 'Ranch' },
      { value: 'Timeshare', label: 'Timeshare' },
      { value: 'Agriculture (PropertySubType)', label: 'Agriculture (PropertySubType)' },
      { value: 'Business (PropertySubType)', label: 'Business (PropertySubType)' },
    ]
  },
];

const viewOptions = [
  { value: "Bay", label: "Bay" },
  { value: "Beach", label: "Beach" },
  { value: "Bridge(s)", label: "Bridge(s)" },
  { value: "Canal", label: "Canal" },
  { value: "Canyon", label: "Canyon" },
  { value: "City", label: "City" },
  { value: "City Lights", label: "City Lights" },
  { value: "Creek/Stream", label: "Creek/Stream" },
  { value: "Desert", label: "Desert" },
  { value: "Downtown", label: "Downtown" },
  { value: "Forest", label: "Forest" },
  { value: "Garden", label: "Garden" },
  { value: "Golf Course", label: "Golf Course" },
  { value: "Hills", label: "Hills" },
  { value: "Lake", label: "Lake" },
  { value: "Marina", label: "Marina" },
  { value: "Meadow", label: "Meadow" },
  { value: "Mountain(s)", label: "Mountain(s)" },
  { value: "Neighborhood", label: "Neighborhood" },
  { value: "None", label: "None" },
  { value: "Ocean", label: "Ocean" },
  { value: "Orchard", label: "Orchard" },
  { value: "Other", label: "Other" },
  { value: "Panoramic", label: "Panoramic" },
  { value: "Park/Greenbelt", label: "Park/Greenbelt" },
  { value: "Pasture", label: "Pasture" },
  { value: "Pond", label: "Pond" },
  { value: "Pool", label: "Pool" },
  { value: "Ridge", label: "Ridge" },
  { value: "River", label: "River" },
  { value: "Rural", label: "Rural" },
  { value: "See Remarks", label: "See Remarks" },
  { value: "Skyline", label: "Skyline" },
  { value: "Territorial", label: "Territorial" },
  { value: "Trees/Woods", label: "Trees/Woods" },
  { value: "Valley", label: "Valley" },
  { value: "Vineyard", label: "Vineyard" },
  { value: "Water", label: "Water" },
];

type IProps = {
  value?: ListingAdvancedSearchDto;
  setValue?: Function;
  handleInputChange?: Function;
  handleSelectChange?: Function;
}

interface FeatureCheckbox {
  name: string;
  value?: string;
  title: string;
}

const PropertyDetails = (props: IProps) => {
  const dispatch = useAppDispatch();
  const checkbox = useRef();
  const amenities = useAppSelector(getAmenities);
  const keywords = useAppSelector(getKeywords);

  const [searchKeyword, setSearchKeyword] = useState('');
  const [searchAmenities, setSearchAmenities] = useState('');
  const [propertySubTypeOptions, setPropertySubTypeOptions] = useState<Array<any>>([]);
  const [selectAllProperty, setSelectAllPropery] = useState(true)
  const [propertyChanged, setPropertyChanged] = useState(false)

  const handleSelectChange = (value: any, name: string, isMulti?: boolean) => {
    props.handleSelectChange && props.handleSelectChange(value, name, isMulti);
  }

  const handleInputChange = (value: string, name: string) => {
    props.handleInputChange && props.handleInputChange(value, name);
  }

  const onChangePropertyType = (value: any) => {
    handleSelectChange({}, 'propertySubType');
    handleSelectChange(value, 'propertyType');
    propertySubTypeList.map(item => {
      if (item.parent === value.value) {
        setPropertySubTypeOptions(item.contents);
      }
    });
  };

  const onChangeAutoComplete = (value: any, name: string) => {
    if (name === 'propertyKeywords') {
      setSearchKeyword(value)
      dispatch(fetchKeywords({ query: value }))
    } else if (name === 'propertyAmenities') {
      setSearchAmenities(value)
      dispatch(fetchAmenities({ query: value }))
    }
  }

  useEffect(() => {
    if (props.value?.propertyType && props.value?.propertyType !== '') {
      propertySubTypeList.map(item => {
        if (item.parent === props.value?.propertyType) {
          setPropertySubTypeOptions(item.contents);
        }
      });
    }
  }, [props.value?.propertyType])

  const propertyFeatures: FeatureCheckbox[] = [
    {
      title: 'Parking',
      name: 'propertyParking',
      value: props.value?.propertyParking
    },
    {
      title: 'Garage',
      name: 'propertyGarage',
      value: props.value?.propertyGarage

    },
    {
      title: 'Cooling',
      name: 'propertyCooling',
      value: props.value?.propertyCooling
    },
    {
      title: 'Heating',
      name: 'propertyHeating',
      value: props.value?.propertyHeating
    },
    {
      title: 'Pool',
      name: 'propertyPool',
      value: props.value?.propertyPool
    },
        {
      title: 'is Attached?',
      name: 'propertyAttached',
      value: props.value?.propertyAttached
    },
        {
      title: 'New Contruction',
      name: 'propertyNewConstruction',
      value: props.value?.propertyNewConstruction
    },
  ]

  useEffect(() => {
    if (props.value) {
      console.log("props.value  ", props.value)
      setPropertyChanged(!propertyChanged)
    }
  }, [props.value])

  const handleCheckboxChange = (value: string, name: string): boolean => {
    if (value == "Yes" || value == 'No') {
      setSelectAllPropery(false);
    }
    if (props.setValue && props.value) {
      props.setValue({
        ...props.value,
        [name]: value == "" ? '' : value == 'Yes' ? 'Yes' : 'No'
      })
    }
    return false;
  }

  useEffect(() => {
    if (selectAllProperty) {
      if (props.setValue) {
        props.setValue({
          ...props.value,
          propertyParking: "",
          propertyGarage: "",
          propertyHeating: "",
          propertyCooling: "",
          propertyPool: "",
          propertyAttached: "",
          propertyNewConstruction: ""
        })
      }
    }
  }, [selectAllProperty])

  return (
    <div>
      <Typography variant="h3" color="primary">Property Details</Typography>
      <div className="grid sm:grid-cols-2 grid-cols-1 gap-3 mt-[15px]">
        <div className="col-span-2 md:col-span-1">
          <Typography variant="caption" color="secondary" className=''>Property Type</Typography>
          <Select
            options={propertyTypeOptions}
            name='propertyType'
            value={{ value: props.value?.propertyType, label: props.value?.propertyType }}
            onChange={(value) => onChangePropertyType(value)}
          />
        </div>
        <div className="col-span-2 md:col-span-1">
          <Typography variant="caption" color="secondary" className=''>Property Sub-Type</Typography>
          <Select
            options={propertySubTypeOptions}
            name='propertySubType'
            value={{ value: props.value?.propertySubType, label: props.value?.propertySubType }}
            onChange={(value) => { props.handleSelectChange && props.handleSelectChange(value, 'propertySubType') }}
          />
        </div>
      </div>

      <div className="grid sm:grid-cols-2 grid-cols-1 gap-3 mt-[15px]">
        <div className="col-span-2 md:col-span-1">
          <Typography variant="caption" color="secondary" className=''>Home SqFt (Minimum)</Typography>
          <NumericFormat
            // suffix='+'
            thousandSeparator=","
            name="propertyHomeSqftMin"
            autoComplete='off'
            value={props.value?.propertyHomeSqftMin ?? ''}
            onChange={(e) => handleInputChange(e.target.value, "propertyHomeSqftMin")}
            className={`body rounded-5 h-10 text-primary p-4 outline-none w-full placeholder:caption  border-1 border-transparent focus:border-netural-dark ${props.value?.propertyHomeSqftMin ? 'bg-[#E5E7EB]' : 'bg-[#F1F5F9]'}`}
          />
          {/* <TextField placeholder='' name="home_sqft" className={`w-full`} onChange={(e) => handleInputChange(e.target.value, 'propertyHomeSqftMin')} /> */}
        </div>
        <div className="col-span-2 md:col-span-1">
          <Typography variant="caption" color="secondary" className=''>Lot SqFt  (Minimum)</Typography>
          <NumericFormat
            // suffix='+'
            thousandSeparator=","
            name="propertyLotSqftMin"
            autoComplete='off'
            value={props.value?.propertyLotSqftMin ?? ''}
            onChange={(e) => handleInputChange(e.target.value, "propertyLotSqftMin")}
            className={`body rounded-5 h-10 text-primary p-4 outline-none w-full placeholder:caption  border-1 border-transparent focus:border-netural-dark ${props.value?.propertyLotSqftMin ? 'bg-[#E5E7EB]' : 'bg-[#F1F5F9]'}`}
          />
          {/* <TextField placeholder='' name="lot_sqft" className={`w-full`} onChange={(e) => handleInputChange(e.target.value, 'propertyLotSqftMin')} /> */}
        </div>
      </div>
      <div className="grid sm:grid-cols-2 grid-cols-1 gap-3 mt-[15px]">
        <div className="col-span-2 md:col-span-1">
          <Typography variant="caption" color="secondary" className=''>Stories (Minimum)</Typography>
          <NumericFormat
            // suffix='+'
            thousandSeparator=","
            name="propertyStories"
            autoComplete='off'
            value={props.value?.propertyStories ?? ''}
            onChange={(e) => handleInputChange(e.target.value, "propertyStories")}
            className={`body rounded-5 h-10 text-primary p-4 outline-none w-full placeholder:caption  border-1 border-transparent focus:border-netural-dark ${props.value?.propertyStories ? 'bg-[#E5E7EB]' : 'bg-[#F1F5F9]'}`}
          />
          {/* <TextField placeholder='' name="stories" className={`w-full`} onChange={(e) => handleInputChange(e.target.value, 'propertyStories')} /> */}
        </div>
      </div>

      <div className="flex flex-col pt-6 pb-3">
        <Typography variant="h3" color="primary">Property Features</Typography>
        <div className="grid sm:grid-cols-2 grid-cols-1 py-3 flex gap-4">
          <div className="col-span-2 md:col-span-1 flex flex-col gap-2">
            <Typography variant="caption" color="secondary" className=''>Bedrooms (Minimum)</Typography>
            <NumericFormat
              // suffix='+'
              thousandSeparator=","
              name="propertyBedrooms"
              autoComplete='off'
              value={props.value?.propertyBedrooms ?? ''}
              onChange={(e) => handleInputChange(e.target.value + '', "propertyBedrooms")}
              className={`body rounded-5 h-10 text-primary p-4 outline-none w-full placeholder:caption  border-1 border-transparent focus:border-netural-dark ${props.value?.propertyBedrooms ? 'bg-[#E5E7EB]' : 'bg-[#F1F5F9]'}`}
            />
          </div>
          <div className="col-span-2 md:col-span-1 flex flex-col gap-2">
            <Typography variant="caption" color="secondary" className=''>Bathrooms (Minimum)</Typography>
            <NumericFormat
              // suffix='+'
              thousandSeparator=","
              name="propertyBathrooms"
              autoComplete='off'
              value={props.value?.propertyBathrooms ?? ''}
              onChange={(e) => handleInputChange(e.target.value, "propertyBathrooms")}
              className={`body rounded-5 h-10 text-primary p-4 outline-none w-full placeholder:caption  border-1 border-transparent focus:border-netural-dark ${props.value?.propertyBathrooms ? 'bg-[#E5E7EB]' : 'bg-[#F1F5F9]'}`}
            />
          </div>
        </div>
        <div className="flex flex-col w-full ">
          <div className="flex py-3 gap-2 items-center">
            <input
              type="checkbox"
              className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
              ref={checkbox as any}
              checked={selectAllProperty}
              onChange={() => { setSelectAllPropery(!selectAllProperty) }}
            />
            <Typography className="text-[14px]">All Property Features</Typography>
          </div>
          <div className="flex w-full">
            <div className="grid sm:grid-cols-2 grid-cols-1 w-full gap-4">
              {
                propertyFeatures.map((ele, index: number) =>
                  <div className="flex flex-col w-full gap-2 py-3" key={index}>
                    <Typography className="text-[14px] text-[#9CA3AF]">{ele.title}</Typography>
                    <div className="flex gap-6">
                      <div className="flex gap-2 items-center">
                        <input
                          type="checkbox"
                          name={ele.name}
                          className="h-4 w-4 rounded-full border-gray-300 text-indigo-600 focus:ring-indigo-600"
                          ref={checkbox as any}
                          checked={selectAllProperty || (ele.value == "" ? true : false)}
                          onChange={() => handleCheckboxChange("", ele.name)}
                        />
                        <Typography className={`text-[14px] text-[#9CA3AF] pr-2 ${(ele.value == "") ? 'text-primary' : 'text-[text-[#9CA3AF]]'}`}>All</Typography>
                      </div>
                      <div className="flex gap-2 items-center">
                        <input
                          type="checkbox"
                          name={ele.name}
                          className="h-4 w-4 rounded-full border-gray-300 text-indigo-600 focus:ring-indigo-600"
                          ref={checkbox as any}
                          checked={!selectAllProperty && (ele.value == "Yes" ? true : false)}
                          onChange={() => handleCheckboxChange("Yes", ele.name)}
                        />
                        <Typography className={`text-[14px] text-[#9CA3AF] pr-2 ${ele.value == "Yes" ? 'text-primary' : 'text-[text-[#9CA3AF]]'}`}>Yes</Typography>
                      </div>
                      <div className="flex gap-2 items-center">
                        <input
                          type="checkbox"
                          name={ele.name}
                          className="h-4 w-4 rounded-full border-gray-300 text-indigo-600 focus:ring-indigo-600"
                          ref={checkbox as any}
                          checked={!selectAllProperty && (ele.value == "No" ? true : false)}
                          onChange={() => handleCheckboxChange("No", ele.name)}
                        />
                        <Typography className={`text-[14px] text-[#9CA3AF] pr-2 ${ele.value == "No" ? 'text-primary' : 'text-[text-[#9CA3AF]]'}`}>No</Typography>
                      </div>
                    </div>
                  </div>

                )
              }
              {/* {
              */}
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-col pt-6 pb-3">
        <Typography variant="h3" color="primary">Community & Neighborhood</Typography>
        <div className="grid grid-cols-1 gap-3 mt-[15px]">
          <div className="col-span-1 md:col-span-1">
            <Typography variant="caption" color="secondary" className=''>Views</Typography>
            <Select
              isMulti={true}
              options={viewOptions}
              name='propertyViews'
              value={props.value?.propertyViews && props.value?.propertyViews.map((item: any) => {
                return {
                  value: item,
                  label: item,
                };
              })}
              onChange={(e) => handleSelectChange(e, 'propertyViews', true)}
            />
          </div>
        </div>
        <div className="grid grid-cols-1 mt-[15px]">
          <div className="col-span-1">
            <Typography variant="caption" color="secondary">Amenities</Typography>
            <Select
              options={[
                { value: searchAmenities, label: searchAmenities },
                ...(amenities
                  ? amenities.map((item: any) => {
                    return { value: item.name, label: item.name };
                  })
                  : []),
              ]}
              name="propertyAmenities"
              isMulti={true}
              value={(props.value?.propertyAmenities && props.value?.propertyAmenities.length > 0)
                ? props.value.propertyAmenities.map((item: any) => {
                  return {
                    value: item,
                    label: item,
                  };
                })
                : ([])
              }
              onChange={(value) =>
                handleSelectChange(value, "propertyAmenities", true)
              }
              onInputChange={(value) =>
                onChangeAutoComplete(value, "propertyAmenities")
              }
            />
          </div>
        </div>
        <div className="grid grid-cols-1 mt-[15px]">
          <div className="col-span-1">
            <Typography variant="caption" color="secondary">Keywords</Typography>
            <Select
              options={[
                { value: searchKeyword, label: searchKeyword },
                ...(keywords
                  ? keywords.map((item: any) => {
                    return { value: item.name, label: item.name };
                  })
                  : []),
              ]}
              name="propertyKeywords"
              isMulti={true}
              value={(props.value?.propertyKeywords && props.value?.propertyKeywords.length > 0)
                ? props.value.propertyKeywords.map((item: any) => {
                  return {
                    value: item,
                    label: item,
                  };
                })
                : ([])
              }
              onChange={(value) =>
                handleSelectChange(value, "propertyKeywords", true)
              }
              onInputChange={(value) =>
                onChangeAutoComplete(value, "propertyKeywords")
              }
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default PropertyDetails;