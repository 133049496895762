import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "@/shared/services/axios";
import { RootState } from "../store";
import { IScrapeHeroJob } from "@/shared/interfaces/scrapeHero/IScrapeHeroJob";
import { IScrapeHeroJobTable } from "@/shared/interfaces/scrapeHero/IScrapeHeroJobTable";
import { IScrapeHeroImport } from "@/shared/interfaces/scrapeHero/IScrapeHeroImport";
import { IScrapeHeroCrawler } from "@/shared/interfaces/scrapeHero/IScrapeHeroCrawler";

interface ScrapeHeroState {
    crawlers: Array<IScrapeHeroCrawler>;
    jobs: Array<IScrapeHeroJob>;
    totalJobs: number;
}

const initialState: ScrapeHeroState = {
    crawlers: [],
    jobs: [],
    totalJobs: 0
};

export const scrapeHeroSlice = createSlice({
    name: "scrapeHero",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getJobsList.fulfilled, (state, action) => {
            if (action.payload.success) {
                state.crawlers = action.payload.crawlers;
                state.jobs = action.payload.data;
                state.totalJobs = action.payload.totalJobs;
            }
        });
    },
});

export default scrapeHeroSlice.reducer;

export const scrapeHeroActions = scrapeHeroSlice.actions;

export const getCrawlers = (state: RootState) => state.scrapeHero.crawlers;
export const getJobs = (state: RootState) => state.scrapeHero.jobs;
export const getTotalJobs = (state: RootState) => state.scrapeHero.totalJobs;

export const getCrawlerList = createAsyncThunk(
    "scrapeHero/getCrawlerList",
    async (_) => {
        const response = await axios.get("scrapeHero/getCrawlerList");
        return response.data;
    }
);

export const syncContacts = createAsyncThunk(
    "scrapeHero/syncContacts",
    async (_) => {
        const response = await axios.get("scrapeHero/syncContacts");
        return response.data;
    }
);

export const getJobsList = createAsyncThunk(
    "scrapeHero/getJobs",
    async (payload: IScrapeHeroJobTable) => {
        const response = await axios.post("scrapeHero/getJobs", payload);
        return response.data;
    }
);

export const streamData = createAsyncThunk(
    "scrapeHero/streamData",
    async (jobs: IScrapeHeroImport[]) => {
        const response = await axios.post("scrapeHero/streamData", { jobs });
        return response.data;
    }
);

export const sendCampaign = createAsyncThunk(
    "scrapeHero/sendCampaign",
    async (jobIds: number[]) => {
        const response = await axios.post("scrapeHero/sendCampaign", { jobIds });
        return response.data;
    }
);