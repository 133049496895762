type Props = {
  width: number;
  height: number;
  stroke?: string;
  className?: string;
};

const IconMoney = (props: Props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 21" fill="none" {...props}>
      <g opacity="0.6">
        <path d="M9.98875 3.29125V2.25H10.2925V3.26V3.72926L10.7608 3.759C12.8293 3.89034 14.2085 4.93617 14.614 6.35375H13.7568C13.34 5.1949 12.2512 4.47437 10.8413 4.33614L10.2925 4.28233V4.83375V9.58625V9.97325L10.6671 10.0703L11.5696 10.304L11.5702 10.3042C12.7843 10.6172 13.609 11.0359 14.1286 11.5615C14.632 12.0707 14.89 12.7216 14.89 13.605C14.89 14.6322 14.5064 15.4702 13.8216 16.0841C13.1283 16.7056 12.0923 17.126 10.7532 17.2315L10.2925 17.2679V17.73V18.75H9.98875V17.73V17.2655L9.52547 17.2313C7.35696 17.0717 5.97025 15.9776 5.58995 14.4762H6.44828C6.86997 15.6585 7.99423 16.4079 9.41682 16.6184L9.99 16.7032V16.1237V11.085V10.7005L9.61834 10.6018L8.85584 10.3993L8.85584 10.3992L8.85152 10.3981C7.85882 10.1439 7.1225 9.72629 6.63796 9.19914C6.15998 8.67911 5.90125 8.02559 5.90125 7.23625C5.90125 5.45395 7.30001 4.05438 9.54765 3.78777L9.98875 3.73545V3.29125ZM7.39458 8.77059C7.81557 9.19568 8.40826 9.4874 9.11584 9.66591C9.11606 9.66596 9.11628 9.66602 9.1165 9.66607L9.3652 9.7295L9.98875 9.8885V9.245V4.87625V4.28371L9.40465 4.38337C7.86671 4.64578 6.73875 5.6709 6.73875 7.16125C6.73875 7.78515 6.96326 8.33507 7.39458 8.77059ZM11.3492 11.0363L11.3488 11.0362L10.9138 10.9274L10.2925 10.7721V11.4125V16.1562V16.6983L10.8328 16.6546C11.7665 16.5792 12.5671 16.2891 13.1424 15.7815C13.7276 15.2651 14.0425 14.5549 14.0425 13.7325C14.0425 13.0458 13.8227 12.4566 13.3334 11.9899C12.8648 11.5431 12.1889 11.247 11.3492 11.0363Z" fill="#3C3C3C" stroke="#8E9CB2" />
      </g>
    </svg>
  );
};

export default IconMoney;
