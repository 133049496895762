type Props = {
  width: number;
  height: number;
  stroke?: string;
  className?: string;
};

const IconParking = (props: Props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 20 21"
      fill="none"
      {...props}
    >
      <path
        d="M15.8333 2.70703H4.16667C3.24619 2.70703 2.5 3.45322 2.5 4.3737V16.0404C2.5 16.9608 3.24619 17.707 4.16667 17.707H15.8333C16.7538 17.707 17.5 16.9608 17.5 16.0404V4.3737C17.5 3.45322 16.7538 2.70703 15.8333 2.70703Z"
        stroke="#6B7280"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.5 14.3724V6.03906H10.8333C11.4964 6.03906 12.1323 6.30245 12.6011 6.7713C13.0699 7.24014 13.3333 7.87602 13.3333 8.53906C13.3333 9.2021 13.0699 9.83799 12.6011 10.3068C12.1323 10.7757 11.4964 11.0391 10.8333 11.0391H7.5"
        stroke="#6B7280"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default IconParking;
