import { Fragment, useState } from 'react';
import classNames from 'classnames';
import { Combobox, Transition } from '@headlessui/react';
import Icon from '../IconWrapper';
import TextField from '../TextField';
import Typography from '../Typography';
import { Button } from '../Button';
import Scrollbars from 'react-custom-scrollbars';
import { getTime } from 'date-fns';
import IconWrapper from '../IconWrapper';

type Props = {
    cities: Array<any>;
    addresses: Array<any>;
    value: any;
    placeholder?: string;
    className?: string;
    inputClassName?: string;
    loading?: boolean;
    disabled?: boolean;
    onChange: (value: any) => void;
    onAllChange: (value: any) => void;
    onClear?: VoidFunction;
};

const AdvancedAddressSearch = ({
    cities = [],
    addresses = [],
    value,
    placeholder,
    className,
    inputClassName,
    loading = false,
    disabled = false,
    onChange,
    onAllChange,
    onClear,
}: Props) => {

    const [query, setQuery] = useState('');

    const changeInput = (event: any) => {
        event.target.autocomplete = "disable-autocomplete"
        setQuery(event.target.value)
        onChange(event.target.value)
    }

    const changeValue = (value: any) => {
        setQuery(value)
        onAllChange(value)
    }

    return (
        <div className={classNames(className)}>
            <Combobox disabled={disabled} value={value} onChange={(e) => changeValue(e)}>
                {({ open }) => (
                    <div className="">
                        <Combobox.Input
                            as={TextField}
                            inputClassName={"aria-expanded:rounded-b-none " + inputClassName + (className?.includes('bg-white') ? ' bg-white' : '')}
                            displayValue={value}
                            onChange={(event) => changeInput(event)}
                            placeholder={placeholder}
                            name={`search-${getTime(new Date())}`}
                            autoComplete="disabled-autocomplete"
                            {...(open && query.length > 0
                                && {
                                endAdornment: (
                                    <Button variant="icon" color="inherit" onClick={onClear}>
                                        <Icon name="close" />
                                    </Button>
                                ),
                            })
                            }
                        />

                        <Transition
                            show={open && query.length > 0}
                            as={Fragment}
                            leave="transition-all ease-in duration-300"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                            enterFrom="opacity-0"
                            enterTo="opacity-100"
                            afterLeave={() => setQuery('')}
                        >
                            <Combobox.Options className="absolute left-0 z-10 w-full overflow-hidden transition-opacity bg-white rounded-b-5 border-1 border-netural-dark border-t-netural shadow-lg">
                                {loading ? (
                                    <div className="flex items-center justify-center gap-2 py-4">
                                        <IconWrapper name="spinner" className="w-5 h-5 text-gray-200 animate-spin dark:text-gray-600 fill-indigo-600" />
                                        <Typography variant="body" color="secondary" className="mt-0.5">
                                            Loading...
                                        </Typography>
                                    </div>
                                ) : (
                                    <Scrollbars className="min-h-[240px]">
                                        {cities.length === 0 && addresses.length === 0 && query !== '' ? (
                                            <Typography variant="medium-text" color="secondary" className="py-20 text-center">
                                                <strong>Location not Found</strong> <br /> Try making your input let specific
                                            </Typography>
                                        ) : (
                                            <>
                                                {cities.length > 0 && (
                                                    <Combobox.Label>
                                                        <Typography variant="medium-text" color="secondary" className="font-semibold px-4 mt-[25px]">City</Typography>
                                                    </Combobox.Label>
                                                )}
                                                {cities.map((option, index) => (
                                                    <Combobox.Option
                                                        key={index}
                                                        className={({ active }) =>
                                                            classNames(
                                                                'body text-primary relative flex cursor-default select-none py-2 px-4 hover:bg-neutral-100 items-end',
                                                                {
                                                                    ['bg-netural']: active,
                                                                }
                                                            )
                                                        }
                                                        value={option._id}
                                                        onClick={() => changeValue(option._id)}
                                                    >
                                                        {option._id}
                                                    </Combobox.Option>
                                                ))}
                                                {addresses.length > 0 && (
                                                    <Combobox.Label>
                                                        <Typography variant="medium-text" color="secondary" className="font-semibold px-4 mt-[25px]">Address</Typography>
                                                    </Combobox.Label>
                                                )}
                                                {addresses.map((option, index) => (
                                                    <Combobox.Option
                                                        key={index}
                                                        className={({ active }) =>
                                                            classNames(
                                                                'body text-primary relative flex cursor-default select-none py-2 px-4 hover:bg-neutral-100 items-end',
                                                                {
                                                                    ['bg-netural']: active,
                                                                }
                                                            )
                                                        }
                                                        value={option.fullAddress}
                                                        onClick={() => changeValue(option.fullAddress)}
                                                    >

                                                        {option.fullAddress}
                                                    </Combobox.Option>
                                                ))}
                                            </>
                                        )}
                                    </Scrollbars>
                                )}
                            </Combobox.Options>
                        </Transition>
                    </div>
                )}
            </Combobox>
        </div>
    );
};

export default AdvancedAddressSearch;
