import { Button } from "@/components/baseComponents/Button";
import IconWrapper from "@/components/baseComponents/IconWrapper";
import Typography from "@/components/baseComponents/Typography";
import { addDays, eachDayOfInterval, getWeek, setWeek, startOfWeek } from "date-fns";
import { useEffect, useState } from "react";

type IProps = {
  onSelectDate: Function;
};

const WEEKS = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];

const CalendarWeeklyView = ({ onSelectDate }: IProps) => {
  const [weekNumber, setWeekNumber] = useState(getWeek(new Date()));
  const [startDate, setStartDate] = useState(setWeek(new Date(), weekNumber));
  const [endDate, setEndDate] = useState(addDays(startDate, 6));
  const [intervals, setIntervals] = useState<Date[]>([]);
  const [selectedDate, setSelectedDate] = useState<Date>(new Date());

  const onNext = () => {
    setWeekNumber(weekNumber + 1);
  };

  const onPrevious = () => {
    setWeekNumber(weekNumber - 1);
  };

  useEffect(() => {
    setStartDate(setWeek(startOfWeek(new Date()), weekNumber));
  }, [weekNumber]);

  useEffect(() => {
    setEndDate(addDays(startDate, 6));
  }, [startDate]);

  useEffect(() => {
    setIntervals(
      eachDayOfInterval({
        start: startDate,
        end: endDate,
      })
    );
  }, [endDate]);

  return (
    <div className="relative flex justify-between items-center w-full px-4">
      <Button
        variant="icon"
        className="hover:bg-gray-100 !p-0 md:!p-2 max-sm:absolute max-sm:left-0 translate-x-[-50%]"
        onClick={onPrevious}
      >
        <IconWrapper name="arrow-backward" />
      </Button>
      <div className="flex flex-col w-full max-sm:px-1">
        <div className="grid grid-cols-7 gap-1 items-center  w-full">
          {WEEKS.map((day, index) => (
            <Typography
              variant="body"
              color="primary"
              className="text-center py-2 md:w-10"
              key={index}
            >
              {day.substring(0, 3)}
            </Typography>
          ))}
        </div>
        <div className="grid grid-cols-7 gap-1 items-center w-full">
          {intervals.map((day, index) => (
            <button
              key={index}
              className={`border-0 rounded-lg text-center py-2 w-10 text-primary disabled:text-tertiary cursor-pointer disabled:cursor-not-allowed ${
                selectedDate.getDate() === day.getDate()
                  ? "!bg-indigo-600 !text-white"
                  : ""
              }`}
              disabled={day < new Date()}
              onClick={() => {
                onSelectDate(day);
                setSelectedDate(day);
              }}
            >
              {day.getDate()}
            </button>
          ))}
        </div>
      </div>
      <Button
        variant="icon"
        className="hover:bg-gray-100 !p-0 md:!p-2 max-sm:absolute max-sm:right-0 translate-x-[50%]"
        onClick={onNext}
      >
        <IconWrapper name="arrow-forward" />
      </Button>
    </div>
  );
};

export default CalendarWeeklyView;
