// Define the type for the component props
type Props = {
    width: number;
    height: number;
    stroke?: string;
    className?: string;
};

// Define the IconLock component
const IconLock = (props: Props) => {
    // Return the SVG for the icon
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="none" {...props}>
            <path d="M5.83333 8.33464V5.83464C5.83333 4.72957 6.27232 3.66976 7.05372 2.88836C7.83512 2.10696 8.89493 1.66797 10 1.66797C11.1051 1.66797 12.1649 2.10696 12.9463 2.88836C13.7277 3.66976 14.1667 4.72957 14.1667 5.83464V8.33464M10.8333 13.3346C10.8333 13.7949 10.4602 14.168 10 14.168C9.53976 14.168 9.16667 13.7949 9.16667 13.3346C9.16667 12.8744 9.53976 12.5013 10 12.5013C10.4602 12.5013 10.8333 12.8744 10.8333 13.3346ZM4.16667 8.33464H15.8333C16.7538 8.33464 17.5 9.08083 17.5 10.0013V16.668C17.5 17.5884 16.7538 18.3346 15.8333 18.3346H4.16667C3.24619 18.3346 2.5 17.5884 2.5 16.668V10.0013C2.5 9.08083 3.24619 8.33464 4.16667 8.33464Z" stroke="#8E9CB2" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        </svg>

    );
};

// Export the IconLock component
export default IconLock;